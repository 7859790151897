import React, { useState } from "react";
import {
  Grid,
  Container,
  Typography,
  Paper,
  TextField,
  Button,
  AppBar,
  Toolbar,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Box,
  Drawer,
} from "@mui/material";
import { AddLesson } from "./AddLesson";
import { AllLessons } from "./AllLessons";

export const LessonPlans = () => {
  const [active, setActive] = useState("All");

  return (
    <>
      <Container disableGutters={true}>
        <Box sx={{ mt: 12, width: "100%" }}>
          <Grid
            container
            justifyContent="start"
            spacing={2}
            display="flex"
            sx={{
              justifyContent: { xs: "center", md: "flex-end" },
              marginTop: { xs: 2, sm: 4, lg: 6 },
            }}
          >
            <Grid item>
              <Button
                color="warning"
                variant="contained"
                onClick={() => setActive("Add")}
              >
                <Typography style={{ fontWeight: "semibold" }}>
                  ADD LESSONPLAN
                </Typography>
              </Button>
            </Grid>
            <Grid item>
              <Button
                color="warning"
                variant="contained"
                onClick={() => setActive("All")}
              >
                <Typography style={{ fontWeight: "semibold" }}>
                  All Lesson Plans
                </Typography>
              </Button>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            marginTop={4}
            marginBottom={4}
            display="flex"
            justifyContent="center"
            sx={{ marginLeft: { lg: 36 } }}
          >
            <Paper
              elevation={2}
              sx={{
                minWidth: { xs: 350, sm: 600, md: 880, lg: 850, xl: 1200 },
                maxWidth: { xs: 590, sm: 890, md: 1100 },
                height: { xs: 850 },
              }}
              style={{ overflow: "auto" }}
            >
              <Container>{active === "Add" && <AddLesson />}</Container>
              <Container>{active === "All" && <AllLessons />}</Container>
            </Paper>
          </Grid>
        </Box>
      </Container>
    </>
  );
};
