import React, { useState, useEffect } from "react";
import {
  collection,
  query,
  onSnapshot,
  updateDoc,
  doc,
  deleteDoc,
  setDoc,
} from "firebase/firestore";
import { db } from "../../../../firebase/config";
import {
  Container,
  Grid,
  Typography,
  Checkbox,
  Paper,
  TextField,
  Button,
  Modal,
  Toolbar,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Box,
  Drawer,
  Divider,
  alertClasses,
} from "@mui/material";
import { useAuthContext } from "../../../../hooks/useAuthContext";
import { useParams } from "react-router-dom";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import AddIcon from "@mui/icons-material/Add";
import { PgColors } from "../../../../configs/colors";

export const EditCompetition = ({ path }: any) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [currentData, setCurrentData] = useState<any>(null);
  const [competition, setCompetition] = useState("");
  const [date, setDate] = useState("");
  const [location, setLocation] = useState("");
  const [dVault1, setDVault1] = useState("");
  const [eVault1, setEVault1] = useState("");
  const [nVault1, setNVault1] = useState("");
  const [tVault1, setTVault1] = useState("");
  const [dVault2, setDVault2] = useState("");
  const [eVault2, setEVault2] = useState("");
  const [nVault2, setNVault2] = useState("");
  const [tVault2, setTVault2] = useState("");
  const [pVault, setPVault] = useState("");
  const [dBars, setDBars] = useState("");
  const [eBars, setEBars] = useState("");
  const [nBars, setNBars] = useState("");
  const [tBars, setTBars] = useState("");
  const [pBars, setPBars] = useState("");
  const [dBeam, setDBeam] = useState("");
  const [eBeam, setEBeam] = useState("");
  const [nBeam, setNBeam] = useState("");
  const [tBeam, setTBeam] = useState("");
  const [pBeam, setPBeam] = useState("");
  const [dFloor, setDFloor] = useState("");
  const [eFloor, setEFloor] = useState("");
  const [nFloor, setNFloor] = useState("");
  const [tFloor, setTFloor] = useState("");
  const [pFloor, setPFloor] = useState("");
  const [tTotal, setTTotal] = useState("");
  const [pTotal, setPTotal] = useState("");
  const [evaluation, setEvaluation] = useState("");

  useEffect(() => {
    if (open) {
      const unsubscribe = onSnapshot(doc(db, path), (snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.data();
          setCurrentData(data);
          // Set the initial values for TextFields
          setCompetition(data.competition || "");
          setDate(data.date || "");
          setLocation(data.location || "");
          setDVault1(data.dVault1 || "");
          setEVault1(data.eVault1 || "");
          setNVault1(data.nVault1 || "");
          setTVault1(data.tVault1 || "");
          setDVault2(data.dVualt2 || "");
          setEVault2(data.eVault2 || "");
          setNVault2(data.nVault2 || "");
          setTVault2(data.tVault2 || "");
          setPVault(data.pVault || "");
          setDBars(data.dBars || "");
          setEBars(data.eBars || "");
          setNBars(data.nBars || "");
          setTBars(data.tBars || "");
          setPBars(data.pBars || "");
          setDBeam(data.dBeam || "");
          setEBeam(data.eBeam || "");
          setNBeam(data.nBeam || "");
          setTBeam(data.tBeam || "");
          setPBeam(data.pBeam || "");
          setDFloor(data.dFloor || "");
          setEFloor(data.eFloor || "");
          setNFloor(data.nFloor || "");
          setTFloor(data.tFloor || "");
          setPFloor(data.pFloor || "");
          setTTotal(data.tTotal || "");
          setPTotal(data.pTotal || "");
          setEvaluation(data.evaluation || "");
        }
      });

      return () => unsubscribe();
    }
  }, [open, path]);

  const handleUpdate = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    try {
      if (competition) {
        await updateDoc(doc(db, path), {
          competition: competition,
        });
      }
      if (date) {
        await updateDoc(doc(db, path), {
          date: date,
        });
      }
      if (location) {
        await updateDoc(doc(db, path), {
          location: location,
        });
      }
      if (dVault1) {
        await updateDoc(doc(db, path), {
          dVault1: dVault1,
        });
      }
      if (eVault1) {
        await updateDoc(doc(db, path), {
          eVault1: eVault1,
        });
      }
      if (nVault1) {
        await updateDoc(doc(db, path), {
          nVault1: nVault1,
        });
      }
      if (tVault1) {
        await updateDoc(doc(db, path), {
          tVault1: tVault1,
        });
      }
      if (dVault2) {
        await updateDoc(doc(db, path), {
          dVault2: dVault2,
        });
      }
      if (eVault2) {
        await updateDoc(doc(db, path), {
          eVault2: eVault2,
        });
      }
      if (nVault2) {
        await updateDoc(doc(db, path), {
          nVault2: nVault2,
        });
      }
      if (tVault2) {
        await updateDoc(doc(db, path), {
          eVault2: tVault2,
        });
      }
      if (pVault) {
        await updateDoc(doc(db, path), {
          pVault: pVault,
        });
      }
      if (dBars) {
        await updateDoc(doc(db, path), {
          dBars: dBars,
        });
      }
      if (eBars) {
        await updateDoc(doc(db, path), {
          eBars: eBars,
        });
      }
      if (nBars) {
        await updateDoc(doc(db, path), {
          nBars: nBars,
        });
      }
      if (tBars) {
        await updateDoc(doc(db, path), {
          tBars: tBars,
        });
      }
      if (pBars) {
        await updateDoc(doc(db, path), {
          pBars: pBars,
        });
      }
      if (dBeam) {
        await updateDoc(doc(db, path), {
          dBeam: dBeam,
        });
      }
      if (eBeam) {
        await updateDoc(doc(db, path), {
          eBeam: eBeam,
        });
      }
      if (nBeam) {
        await updateDoc(doc(db, path), {
          nBeam: nBeam,
        });
      }
      if (tBeam) {
        await updateDoc(doc(db, path), {
          tBeam: tBeam,
        });
      }
      if (pBeam) {
        await updateDoc(doc(db, path), {
          pBeam: pBeam,
        });
      }
      if (dFloor) {
        await updateDoc(doc(db, path), {
          dFloor: dFloor,
        });
      }
      if (eFloor) {
        await updateDoc(doc(db, path), {
          eFloor: eFloor,
        });
      }
      if (nFloor) {
        await updateDoc(doc(db, path), {
          nFloor: nFloor,
        });
      }
      if (tFloor) {
        await updateDoc(doc(db, path), {
          tFloor: tFloor,
        });
      }
      if (pFloor) {
        await updateDoc(doc(db, path), {
          pBars: pFloor,
        });
      }
      if (tTotal) {
        await updateDoc(doc(db, path), {
          tTotal: tTotal,
        });
      }
      if (pTotal) {
        await updateDoc(doc(db, path), {
          pTotal: pTotal,
        });
      }
      if (evaluation) {
        await updateDoc(doc(db, path), {
          evaluation: evaluation,
        });
      }
      setCompetition("");
      setDate("");
      setLocation("");
      setDVault1("");
      setEVault1("");
      setNVault1("");
      setTVault1("");
      setDVault2("");
      setEVault2("");
      setNVault2("");
      setTVault2("");
      setPVault("");
      setDBars("");
      setEBars("");
      setNBars("");
      setTBars("");
      setPBars("");
      setDBeam("");
      setEBeam("");
      setNBeam("");
      setTBeam("");
      setPBeam("");
      setDFloor("");
      setEFloor("");
      setNFloor("");
      setTFloor("");
      setPFloor("");
      setTTotal("");
      setPTotal("");
      setEvaluation("");
      setOpen(false);
    } catch (err) {
      alert(err);
    }
  };

  const handleDelete = async () => {
    const ref = doc(db, path);
    await deleteDoc(ref);
  };

  return (
    <>
      <Button
        variant="contained"
        style={{
          backgroundColor: PgColors.redpg[200],
        }}
        onClick={handleOpen}
      >
        <EditOutlinedIcon />
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        style={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
        }}
      >
        <Box
          sx={{
            marginTop: { xs: 1, md: 8 },
            width: { xs: 350, sm: 580, md: 880, xl: 1500 },
            maxHeight: { xs: 800, sm: 800, md: 700, lg: 700 },
            bgcolor: "background.paper",
            border: "4px solid #9f5b5a",
            boxShadow: 24,
            borderRadius: 5,
            p: 4,
            overflow: "auto",
          }}
        >
          <form>
            <Container>
              <Grid container>
                <Grid item xs={10} md={11}></Grid>
                <Grid
                  item
                  xs={2}
                  md={1}
                  mb={1}
                  display="flex"
                  justifyContent="flex-end"
                >
                  <Button
                    variant="contained"
                    sx={{ heigth: 35 }}
                    onClick={handleClose}
                    style={{
                      backgroundColor: PgColors.redpg[200],
                    }}
                  >
                    <CloseOutlinedIcon />
                  </Button>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} md={4}>
                  <TextField
                    label="Wedstrijd"
                    style={{
                      color: PgColors.bluepg[200],
                    }}
                    variant="outlined"
                    fullWidth
                    value={competition}
                    onChange={(event) => setCompetition(event.target.value)}
                  />
                </Grid>
                <Grid item xs={7} md={4}>
                  <TextField
                    label="Locatie"
                    style={{
                      color: PgColors.bluepg[200],
                    }}
                    variant="outlined"
                    fullWidth
                    value={location}
                    onChange={(event) => setLocation(event.target.value)}
                  />
                </Grid>
                <Grid item xs={5} md={4}>
                  <TextField
                    label="Datum"
                    style={{
                      color: PgColors.bluepg[200],
                    }}
                    variant="outlined"
                    fullWidth
                    value={date}
                    onChange={(event) => setDate(event.target.value)}
                  />
                </Grid>
              </Grid>
              <Grid container mt={1} display={{ xs: "none", md: "flex" }}>
                <Grid item md={2}></Grid>
                <Grid item md={1}>
                  <Paper
                    style={{ backgroundColor: PgColors.redpg[200], height: 35 }}
                  >
                    <Typography
                      variant="subtitle1"
                      style={{ color: "white" }}
                      sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                      align="center"
                    >
                      Sprong 1
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item md={1}>
                  <Paper
                    style={{ backgroundColor: PgColors.redpg[200], height: 35 }}
                  >
                    <Typography
                      variant="subtitle1"
                      style={{ color: "white" }}
                      sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                      align="center"
                    >
                      Sprong 2
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item md={2}>
                  <Paper
                    style={{ backgroundColor: PgColors.redpg[200], height: 35 }}
                  >
                    <Typography
                      variant="subtitle1"
                      style={{ color: "white" }}
                      sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                      align="center"
                    >
                      Brug
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item md={2}>
                  <Paper
                    style={{ backgroundColor: PgColors.redpg[200], height: 35 }}
                  >
                    <Typography
                      variant="subtitle1"
                      style={{ color: "white" }}
                      sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                      align="center"
                    >
                      Balk
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item md={2}>
                  <Paper
                    style={{ backgroundColor: PgColors.redpg[200], height: 35 }}
                  >
                    <Typography
                      variant="subtitle1"
                      style={{ color: "white" }}
                      sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                      align="center"
                    >
                      Vloer
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item md={2}>
                  <Paper
                    style={{ backgroundColor: PgColors.redpg[200], height: 35 }}
                  >
                    <Typography
                      variant="subtitle1"
                      style={{ color: "white" }}
                      sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                      align="center"
                    >
                      Totaal
                    </Typography>
                  </Paper>
                </Grid>
              </Grid>
              <Grid container mt={2} display={{ xs: "none", md: "flex" }}>
                <Grid item md={2}>
                  <Paper
                    style={{ backgroundColor: PgColors.redpg[200], height: 55 }}
                  >
                    <Typography
                      variant="subtitle1"
                      style={{ color: "white", fontSize: 20 }}
                      align="center"
                    >
                      D-Score
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item md={1}>
                  <TextField
                    style={{
                      color: PgColors.bluepg[200],
                    }}
                    variant="outlined"
                    fullWidth
                    value={dVault1}
                    onChange={(event) => setDVault1(event.target.value)}
                  />
                </Grid>
                <Grid item md={1}>
                  <TextField
                    style={{
                      color: PgColors.bluepg[200],
                    }}
                    variant="outlined"
                    fullWidth
                    value={dVault2}
                    onChange={(event) => setDVault2(event.target.value)}
                  />
                </Grid>
                <Grid item md={2}>
                  <TextField
                    style={{
                      color: PgColors.bluepg[200],
                    }}
                    variant="outlined"
                    fullWidth
                    value={dBars}
                    onChange={(event) => setDBars(event.target.value)}
                  />
                </Grid>
                <Grid item md={2}>
                  <TextField
                    style={{
                      color: PgColors.bluepg[200],
                    }}
                    variant="outlined"
                    fullWidth
                    value={dBeam}
                    onChange={(event) => setDBeam(event.target.value)}
                  />
                </Grid>
                <Grid item md={2}>
                  <TextField
                    style={{
                      color: PgColors.bluepg[200],
                    }}
                    variant="outlined"
                    fullWidth
                    value={dFloor}
                    onChange={(event) => setDFloor(event.target.value)}
                  />
                </Grid>
              </Grid>
              <Grid container mt={2} display={{ xs: "none", md: "flex" }}>
                <Grid item md={2}>
                  <Paper
                    style={{ backgroundColor: PgColors.redpg[200], height: 55 }}
                  >
                    <Typography
                      variant="subtitle1"
                      style={{ color: "white", fontSize: 20 }}
                      align="center"
                    >
                      E-Score
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item md={1}>
                  <TextField
                    style={{
                      color: PgColors.bluepg[200],
                    }}
                    variant="outlined"
                    fullWidth
                    value={eVault1}
                    onChange={(event) => setEVault1(event.target.value)}
                  />
                </Grid>
                <Grid item md={1}>
                  <TextField
                    style={{
                      color: PgColors.bluepg[200],
                    }}
                    variant="outlined"
                    fullWidth
                    value={eVault2}
                    onChange={(event) => setEVault2(event.target.value)}
                  />
                </Grid>
                <Grid item md={2}>
                  <TextField
                    style={{
                      color: PgColors.bluepg[200],
                    }}
                    variant="outlined"
                    fullWidth
                    value={eBars}
                    onChange={(event) => setEBars(event.target.value)}
                  />
                </Grid>
                <Grid item md={2}>
                  <TextField
                    style={{
                      color: PgColors.bluepg[200],
                    }}
                    variant="outlined"
                    fullWidth
                    value={eBeam}
                    onChange={(event) => setEBeam(event.target.value)}
                  />
                </Grid>
                <Grid item md={2}>
                  <TextField
                    style={{
                      color: PgColors.bluepg[200],
                    }}
                    variant="outlined"
                    fullWidth
                    value={eFloor}
                    onChange={(event) => setEFloor(event.target.value)}
                  />
                </Grid>
              </Grid>
              <Grid container mt={2} display={{ xs: "none", md: "flex" }}>
                <Grid item md={2}>
                  <Paper
                    style={{ backgroundColor: PgColors.redpg[200], height: 55 }}
                  >
                    <Typography
                      variant="subtitle1"
                      style={{ color: "white", fontSize: 20 }}
                      align="center"
                    >
                      Neutraal
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item md={1}>
                  <TextField
                    style={{
                      color: PgColors.bluepg[200],
                    }}
                    variant="outlined"
                    fullWidth
                    value={nVault1}
                    onChange={(event) => setNVault1(event.target.value)}
                  />
                </Grid>
                <Grid item md={1}>
                  <TextField
                    style={{
                      color: PgColors.bluepg[200],
                    }}
                    variant="outlined"
                    fullWidth
                    value={nVault2}
                    onChange={(event) => setNVault2(event.target.value)}
                  />
                </Grid>
                <Grid item md={2}>
                  <TextField
                    style={{
                      color: PgColors.bluepg[200],
                    }}
                    variant="outlined"
                    fullWidth
                    value={nBars}
                    onChange={(event) => setNBars(event.target.value)}
                  />
                </Grid>
                <Grid item md={2}>
                  <TextField
                    style={{
                      color: PgColors.bluepg[200],
                    }}
                    variant="outlined"
                    fullWidth
                    value={nBeam}
                    onChange={(event) => setNBeam(event.target.value)}
                  />
                </Grid>
                <Grid item md={2}>
                  <TextField
                    style={{
                      color: PgColors.bluepg[200],
                    }}
                    variant="outlined"
                    fullWidth
                    value={nFloor}
                    onChange={(event) => setNFloor(event.target.value)}
                  />
                </Grid>
              </Grid>
              <Grid container mt={2} display={{ xs: "none", md: "flex" }}>
                <Grid item md={2}>
                  <Paper
                    style={{ backgroundColor: PgColors.redpg[200], height: 55 }}
                  >
                    <Typography
                      variant="subtitle1"
                      style={{ color: "white", fontSize: 20 }}
                      align="center"
                    >
                      Totaal
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item md={1}>
                  <TextField
                    style={{
                      color: PgColors.bluepg[200],
                    }}
                    variant="outlined"
                    fullWidth
                    value={tVault1}
                    onChange={(event) => setTVault1(event.target.value)}
                  />
                </Grid>
                <Grid item md={1}>
                  <TextField
                    style={{
                      color: PgColors.bluepg[200],
                    }}
                    variant="outlined"
                    fullWidth
                    value={tVault2}
                    onChange={(event) => setTVault2(event.target.value)}
                  />
                </Grid>
                <Grid item md={2}>
                  <TextField
                    style={{
                      color: PgColors.bluepg[200],
                    }}
                    variant="outlined"
                    fullWidth
                    value={tBars}
                    onChange={(event) => setTBars(event.target.value)}
                  />
                </Grid>
                <Grid item md={2}>
                  <TextField
                    style={{
                      color: PgColors.bluepg[200],
                    }}
                    variant="outlined"
                    fullWidth
                    value={tBeam}
                    onChange={(event) => setTBeam(event.target.value)}
                  />
                </Grid>
                <Grid item md={2}>
                  <TextField
                    style={{
                      color: PgColors.bluepg[200],
                    }}
                    variant="outlined"
                    fullWidth
                    value={tFloor}
                    onChange={(event) => setTFloor(event.target.value)}
                  />
                </Grid>
                <Grid item md={2}>
                  <TextField
                    style={{
                      color: PgColors.bluepg[200],
                    }}
                    variant="outlined"
                    fullWidth
                    value={tTotal}
                    onChange={(event) => setTTotal(event.target.value)}
                  />
                </Grid>
              </Grid>
              <Grid container mt={2} display={{ xs: "none", md: "flex" }}>
                <Grid item md={2}>
                  <Paper
                    style={{ backgroundColor: PgColors.redpg[200], height: 55 }}
                  >
                    <Typography
                      variant="subtitle1"
                      style={{ color: "white", fontSize: 20 }}
                      align="center"
                    >
                      Plaats
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item md={2}>
                  <TextField
                    style={{
                      color: PgColors.bluepg[200],
                    }}
                    variant="outlined"
                    fullWidth
                    value={pVault}
                    onChange={(event) => setPVault(event.target.value)}
                  />
                </Grid>

                <Grid item md={2}>
                  <TextField
                    style={{
                      color: PgColors.bluepg[200],
                    }}
                    variant="outlined"
                    fullWidth
                    value={pBars}
                    onChange={(event) => setPBars(event.target.value)}
                  />
                </Grid>
                <Grid item md={2}>
                  <TextField
                    style={{
                      color: PgColors.bluepg[200],
                    }}
                    variant="outlined"
                    fullWidth
                    value={pBeam}
                    onChange={(event) => setPBeam(event.target.value)}
                  />
                </Grid>
                <Grid item md={2}>
                  <TextField
                    style={{
                      color: PgColors.bluepg[200],
                    }}
                    variant="outlined"
                    fullWidth
                    value={pFloor}
                    onChange={(event) => setPFloor(event.target.value)}
                  />
                </Grid>
                <Grid item md={2}>
                  <TextField
                    style={{
                      color: PgColors.bluepg[200],
                    }}
                    variant="outlined"
                    fullWidth
                    value={pTotal}
                    onChange={(event) => setPTotal(event.target.value)}
                  />
                </Grid>
              </Grid>
              <Grid container mt={2} display={{ xs: "none", md: "flex" }}>
                <Grid item md={2}>
                  <Paper
                    style={{ backgroundColor: PgColors.redpg[200], height: 55 }}
                  >
                    <Typography
                      variant="subtitle1"
                      style={{ color: "white", fontSize: 20 }}
                      align="center"
                    >
                      Evaluatie
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item md={8}>
                  <TextField
                    style={{
                      color: PgColors.bluepg[200],
                    }}
                    variant="outlined"
                    fullWidth
                    value={evaluation}
                    onChange={(event) => setEvaluation(event.target.value)}
                  />
                </Grid>
                <Grid item md={1} justifyContent="end" display="flex">
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: PgColors.bluepg[200],
                    }}
                    size="large"
                    onClick={(
                      e: React.MouseEvent<HTMLButtonElement, MouseEvent>
                    ) => handleUpdate(e)}
                    sx={{ width: 75, height: 55 }}
                  >
                    <AddIcon />
                  </Button>
                </Grid>
                <Grid item md={1} mb={2}>
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: PgColors.bluepg[200],
                    }}
                    size="large"
                    onClick={() => handleDelete()}
                    sx={{ width: 75, height: 55 }}
                  >
                    <DeleteOutlinedIcon />
                  </Button>
                </Grid>
              </Grid>
              <Grid container display={{ xs: "flex", md: "none" }}>
                <Grid item xs={3}>
                  <Paper
                    style={{ backgroundColor: PgColors.redpg[200], height: 25 }}
                  >
                    <Typography
                      variant="subtitle1"
                      style={{ color: "white" }}
                      sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                      align="center"
                    >
                      D Sprong 1
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item xs={3}>
                  <Paper
                    style={{ backgroundColor: PgColors.redpg[200], height: 25 }}
                  >
                    <Typography
                      variant="subtitle1"
                      style={{ color: "white" }}
                      sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                      align="center"
                    >
                      E Sprong 1
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item xs={3}>
                  <Paper
                    style={{ backgroundColor: PgColors.redpg[200], height: 25 }}
                  >
                    <Typography
                      variant="subtitle1"
                      style={{ color: "white" }}
                      sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                      align="center"
                    >
                      T Sprong 1
                    </Typography>
                  </Paper>
                </Grid>
              </Grid>
              <Grid container display={{ xs: "flex", md: "none" }}>
                <Grid item xs={3}>
                  <TextField
                    style={{
                      color: PgColors.bluepg[200],
                    }}
                    variant="outlined"
                    fullWidth
                    value={dVault1}
                    onChange={(event) => setDVault1(event.target.value)}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    style={{
                      color: PgColors.bluepg[200],
                    }}
                    variant="outlined"
                    fullWidth
                    value={eVault1}
                    onChange={(event) => setEVault1(event.target.value)}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    style={{
                      color: PgColors.bluepg[200],
                    }}
                    variant="outlined"
                    fullWidth
                    value={tVault1}
                    onChange={(event) => setTVault1(event.target.value)}
                  />
                </Grid>
              </Grid>
              <Grid container display={{ xs: "flex", md: "none" }}>
                <Grid item xs={3}>
                  <Paper
                    style={{ backgroundColor: PgColors.redpg[200], height: 25 }}
                  >
                    <Typography
                      variant="subtitle1"
                      style={{ color: "white" }}
                      sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                      align="center"
                    >
                      D Sprong 2
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item xs={3}>
                  <Paper
                    style={{ backgroundColor: PgColors.redpg[200], height: 25 }}
                  >
                    <Typography
                      variant="subtitle1"
                      style={{ color: "white" }}
                      sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                      align="center"
                    >
                      E Sprong 2
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item xs={3}>
                  <Paper
                    style={{ backgroundColor: PgColors.redpg[200], height: 25 }}
                  >
                    <Typography
                      variant="subtitle1"
                      style={{ color: "white" }}
                      sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                      align="center"
                    >
                      T Sprong 2
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item xs={3}>
                  <Paper
                    style={{ backgroundColor: PgColors.redpg[200], height: 25 }}
                  >
                    <Typography
                      variant="subtitle1"
                      style={{ color: "white" }}
                      sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                      align="center"
                    >
                      P Sprong
                    </Typography>
                  </Paper>
                </Grid>
              </Grid>
              <Grid container display={{ xs: "flex", md: "none" }}>
                <Grid item xs={3}>
                  <TextField
                    style={{
                      color: PgColors.bluepg[200],
                    }}
                    variant="outlined"
                    fullWidth
                    value={dVault2}
                    onChange={(event) => setDVault2(event.target.value)}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    style={{
                      color: PgColors.bluepg[200],
                    }}
                    variant="outlined"
                    fullWidth
                    value={eVault2}
                    onChange={(event) => setEVault2(event.target.value)}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    style={{
                      color: PgColors.bluepg[200],
                    }}
                    variant="outlined"
                    fullWidth
                    value={tVault2}
                    onChange={(event) => setTVault2(event.target.value)}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    style={{
                      color: PgColors.bluepg[200],
                    }}
                    variant="outlined"
                    fullWidth
                    value={pVault}
                    onChange={(event) => setPVault(event.target.value)}
                  />
                </Grid>
              </Grid>
              <Grid container display={{ xs: "flex", md: "none" }}>
                <Grid item xs={3}>
                  <Paper
                    style={{ backgroundColor: PgColors.redpg[200], height: 25 }}
                  >
                    <Typography
                      variant="subtitle1"
                      style={{ color: "white" }}
                      sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                      align="center"
                    >
                      D Brug
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item xs={3}>
                  <Paper
                    style={{ backgroundColor: PgColors.redpg[200], height: 25 }}
                  >
                    <Typography
                      variant="subtitle1"
                      style={{ color: "white" }}
                      sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                      align="center"
                    >
                      E Brug
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item xs={3}>
                  <Paper
                    style={{ backgroundColor: PgColors.redpg[200], height: 25 }}
                  >
                    <Typography
                      variant="subtitle1"
                      style={{ color: "white" }}
                      sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                      align="center"
                    >
                      T Brug
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item xs={3}>
                  <Paper
                    style={{ backgroundColor: PgColors.redpg[200], height: 25 }}
                  >
                    <Typography
                      variant="subtitle1"
                      style={{ color: "white" }}
                      sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                      align="center"
                    >
                      P Brug
                    </Typography>
                  </Paper>
                </Grid>
              </Grid>
              <Grid container display={{ xs: "flex", md: "none" }}>
                <Grid item xs={3}>
                  <TextField
                    style={{
                      color: PgColors.bluepg[200],
                    }}
                    variant="outlined"
                    fullWidth
                    value={dBars}
                    onChange={(event) => setDBars(event.target.value)}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    style={{
                      color: PgColors.bluepg[200],
                    }}
                    variant="outlined"
                    fullWidth
                    value={eBars}
                    onChange={(event) => setEBars(event.target.value)}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    style={{
                      color: PgColors.bluepg[200],
                    }}
                    variant="outlined"
                    fullWidth
                    value={tBars}
                    onChange={(event) => setTBars(event.target.value)}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    style={{
                      color: PgColors.bluepg[200],
                    }}
                    variant="outlined"
                    fullWidth
                    value={pBars}
                    onChange={(event) => setPBars(event.target.value)}
                  />
                </Grid>
              </Grid>
              <Grid container display={{ xs: "flex", md: "none" }}>
                <Grid item xs={3}>
                  <Paper
                    style={{ backgroundColor: PgColors.redpg[200], height: 25 }}
                  >
                    <Typography
                      variant="subtitle1"
                      style={{ color: "white" }}
                      sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                      align="center"
                    >
                      D Balk
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item xs={3}>
                  <Paper
                    style={{ backgroundColor: PgColors.redpg[200], height: 25 }}
                  >
                    <Typography
                      variant="subtitle1"
                      style={{ color: "white" }}
                      sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                      align="center"
                    >
                      E Balk
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item xs={3}>
                  <Paper
                    style={{ backgroundColor: PgColors.redpg[200], height: 25 }}
                  >
                    <Typography
                      variant="subtitle1"
                      style={{ color: "white" }}
                      sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                      align="center"
                    >
                      T Balk
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item xs={3}>
                  <Paper
                    style={{ backgroundColor: PgColors.redpg[200], height: 25 }}
                  >
                    <Typography
                      variant="subtitle1"
                      style={{ color: "white" }}
                      sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                      align="center"
                    >
                      P Balk
                    </Typography>
                  </Paper>
                </Grid>
              </Grid>
              <Grid container display={{ xs: "flex", md: "none" }}>
                <Grid item xs={3}>
                  <TextField
                    style={{
                      color: PgColors.bluepg[200],
                    }}
                    variant="outlined"
                    fullWidth
                    value={dBeam}
                    onChange={(event) => setDBeam(event.target.value)}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    style={{
                      color: PgColors.bluepg[200],
                    }}
                    variant="outlined"
                    fullWidth
                    value={eBeam}
                    onChange={(event) => setEBeam(event.target.value)}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    style={{
                      color: PgColors.bluepg[200],
                    }}
                    variant="outlined"
                    fullWidth
                    value={tBeam}
                    onChange={(event) => setTBeam(event.target.value)}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    style={{
                      color: PgColors.bluepg[200],
                    }}
                    variant="outlined"
                    fullWidth
                    value={pBeam}
                    onChange={(event) => setPBeam(event.target.value)}
                  />
                </Grid>
              </Grid>
              <Grid container display={{ xs: "flex", md: "none" }}>
                <Grid item xs={3}>
                  <Paper
                    style={{ backgroundColor: PgColors.redpg[200], height: 25 }}
                  >
                    <Typography
                      variant="subtitle1"
                      style={{ color: "white" }}
                      sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                      align="center"
                    >
                      D Vloer
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item xs={3}>
                  <Paper
                    style={{ backgroundColor: PgColors.redpg[200], height: 25 }}
                  >
                    <Typography
                      variant="subtitle1"
                      style={{ color: "white" }}
                      sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                      align="center"
                    >
                      E Vloer
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item xs={3}>
                  <Paper
                    style={{ backgroundColor: PgColors.redpg[200], height: 25 }}
                  >
                    <Typography
                      variant="subtitle1"
                      style={{ color: "white" }}
                      sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                      align="center"
                    >
                      T Vloer
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item xs={3}>
                  <Paper
                    style={{ backgroundColor: PgColors.redpg[200], height: 25 }}
                  >
                    <Typography
                      variant="subtitle1"
                      style={{ color: "white" }}
                      sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                      align="center"
                    >
                      P Vloer
                    </Typography>
                  </Paper>
                </Grid>
              </Grid>
              <Grid container display={{ xs: "flex", md: "none" }}>
                <Grid item xs={3}>
                  <TextField
                    style={{
                      color: PgColors.bluepg[200],
                    }}
                    variant="outlined"
                    fullWidth
                    value={dFloor}
                    onChange={(event) => setDFloor(event.target.value)}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    style={{
                      color: PgColors.bluepg[200],
                    }}
                    variant="outlined"
                    fullWidth
                    value={eFloor}
                    onChange={(event) => setEFloor(event.target.value)}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    style={{
                      color: PgColors.bluepg[200],
                    }}
                    variant="outlined"
                    fullWidth
                    value={tFloor}
                    onChange={(event) => setTFloor(event.target.value)}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    style={{
                      color: PgColors.bluepg[200],
                    }}
                    variant="outlined"
                    fullWidth
                    value={pFloor}
                    onChange={(event) => setPFloor(event.target.value)}
                  />
                </Grid>
              </Grid>
              <Grid container display={{ xs: "flex", md: "none" }}>
                <Grid item xs={12}>
                  <Paper
                    style={{ backgroundColor: PgColors.redpg[200], height: 25 }}
                  >
                    <Typography
                      variant="subtitle1"
                      style={{ color: "white" }}
                      sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                      align="center"
                    >
                      Evaluatie
                    </Typography>
                  </Paper>
                </Grid>
              </Grid>
              <Grid container display={{ xs: "flex", md: "none" }}>
                <Grid item xs={12}>
                  <TextField
                    style={{
                      color: PgColors.bluepg[200],
                    }}
                    variant="outlined"
                    fullWidth
                    value={evaluation}
                    onChange={(event) => setEvaluation(event.target.value)}
                  />
                </Grid>
              </Grid>
              <Grid container display={{ xs: "flex", md: "none" }}>
                <Grid item xs={3}>
                  <Paper
                    style={{ backgroundColor: PgColors.redpg[200], height: 25 }}
                  >
                    <Typography
                      variant="subtitle1"
                      style={{ color: "white" }}
                      sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                      align="center"
                    >
                      Totaal
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item xs={3}>
                  <Paper
                    style={{ backgroundColor: PgColors.redpg[200], height: 25 }}
                  >
                    <Typography
                      variant="subtitle1"
                      style={{ color: "white" }}
                      sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                      align="center"
                    >
                      Plaats
                    </Typography>
                  </Paper>
                </Grid>
              </Grid>

              <Grid container display={{ xs: "flex", md: "none" }}>
                <Grid item xs={3}>
                  <TextField
                    style={{
                      color: PgColors.bluepg[200],
                    }}
                    variant="outlined"
                    fullWidth
                    value={tTotal}
                    onChange={(event) => setTTotal(event.target.value)}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    style={{
                      color: PgColors.bluepg[200],
                    }}
                    variant="outlined"
                    fullWidth
                    value={pTotal}
                    onChange={(event) => setPTotal(event.target.value)}
                  />
                </Grid>
                <Grid item xs={2}></Grid>
                <Grid item xs={1} justifyContent="end" display="flex">
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: PgColors.bluepg[200],
                    }}
                    size="large"
                    onClick={(
                      e: React.MouseEvent<HTMLButtonElement, MouseEvent>
                    ) => handleUpdate(e)}
                    sx={{ width: 75, height: 55 }}
                  >
                    <AddIcon />
                  </Button>
                </Grid>
                <Grid item xs={1} mb={2}>
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: PgColors.bluepg[200],
                    }}
                    size="large"
                    onClick={() => handleDelete()}
                    sx={{ width: 75, height: 55 }}
                  >
                    <DeleteOutlinedIcon />
                  </Button>
                </Grid>
              </Grid>
            </Container>
          </form>
        </Box>
      </Modal>
    </>
  );
};
