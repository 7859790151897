import React, { useState } from "react";
import {
  collection,
  addDoc,
  serverTimestamp,
  doc,
  deleteDoc,
  setDoc,
} from "firebase/firestore";
import { db } from "../../../../firebase/config";
import {
  Container,
  Grid,
  Typography,
  Paper,
  TextField,
  Button,
  Modal,
  Toolbar,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Box,
  Drawer,
} from "@mui/material";
import { useAuthContext } from "../../../../hooks/useAuthContext";
import { useParams } from "react-router-dom";
import { PgColors } from "../../../../configs/colors";

export const AddExercise3A = () => {
  const [answer, setAnswer] = useState("");
  const { user } = useAuthContext();
  const { id } = useParams();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const ref = collection(db, `IntroExercises/${user.uid}/UserExercise3A`);

    await addDoc(ref, {
      answer: answer,
      uid: user.uid,
      createdAt: serverTimestamp(),
    });
    setAnswer("");
  };

  return (
    <>
      <Container>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} alignItems="flex-end">
            <Grid item md={10} xs={12}>
              <TextField
                size="small"
                color="warning"
                variant="outlined"
                fullWidth
                multiline
                rows={2}
                value={answer}
                onChange={(event) => setAnswer(event.target.value)}
              />
            </Grid>

            <Grid item md={2} xs={12}>
              <Button
                style={{
                  backgroundColor: PgColors.bluepg[200],
                }}
                variant="contained"
                type="submit"
                size="large"
                sx={{ height: { xs: 30, md: 55, lg: 55 } }}
              >
                <Typography variant="subtitle1">Voeg Toe</Typography>
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </>
  );
};

export const AddExercise3B = () => {
  const [answer1, setAnswer1] = useState("");
  const [answer2, setAnswer2] = useState("");
  const { user } = useAuthContext();
  const { id } = useParams();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const ref = collection(db, `IntroExercises/${user.uid}/UserExercise3B`);

    await addDoc(ref, {
      answer1: answer1,
      answer2: answer2,
      uid: user.uid,
      createdAt: serverTimestamp(),
    });
    setAnswer1("");
    setAnswer2("");
  };

  return (
    <>
      <Container>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} alignItems="flex-end">
            <Grid item xs={12} mt={2} sx={{ mb: { xs: -1 } }}>
              <Typography
                variant="subtitle1"
                style={{ color: PgColors.bluepg[400] }}
                sx={{
                  fontSize: { xs: 10, md: 14, lg: 18 },
                  fontWeight: { md: 400 },
                }}
                align="left"
              >
                Tegenslag 1:
              </Typography>
            </Grid>
            <Grid item md={10} xs={12}>
              <TextField
                InputLabelProps={{
                  sx: {
                    fontSize: { xs: 10, md: 14, lg: 16 },
                  },
                }}
                size="small"
                label="Bijv: Als het op school druk is verlies ik motivatie"
                color="warning"
                variant="outlined"
                fullWidth
                multiline
                rows={2}
                value={answer1}
                onChange={(event) => setAnswer1(event.target.value)}
              />
            </Grid>
            <Grid item xs={12} mt={2} mb={-1}>
              <Typography
                variant="subtitle1"
                style={{ color: PgColors.bluepg[400] }}
                sx={{
                  fontSize: { xs: 10, md: 14, lg: 18 },
                  fontWeight: { md: 400 },
                }}
                align="left"
              >
                Strategie 1:
              </Typography>
            </Grid>
            <Grid item md={10} xs={12}>
              <TextField
                InputLabelProps={{
                  sx: {
                    fontSize: { xs: 10, md: 14, lg: 16 },
                  },
                }}
                size="small"
                label="Bijv: Ik bespreek dit met mijn trainer en we gaan op zoek naar een oplossing"
                color="warning"
                variant="outlined"
                fullWidth
                multiline
                rows={2}
                value={answer2}
                onChange={(event) => setAnswer2(event.target.value)}
              />
            </Grid>

            <Grid item md={2} xs={12}>
              <Button
                style={{
                  backgroundColor: PgColors.bluepg[200],
                }}
                variant="contained"
                type="submit"
                size="large"
                sx={{ height: { xs: 30, md: 55, lg: 55 } }}
              >
                <Typography variant="subtitle1">Voeg Toe</Typography>
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </>
  );
};

export const AddExercise3C = () => {
  const [answer1, setAnswer1] = useState("");
  const [answer2, setAnswer2] = useState("");
  const { user } = useAuthContext();
  const { id } = useParams();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const ref = collection(db, `IntroExercises/${user.uid}/UserExercise3C`);

    await addDoc(ref, {
      answer1: answer1,
      answer2: answer2,
      uid: user.uid,
      createdAt: serverTimestamp(),
    });
    setAnswer1("");
    setAnswer2("");
  };

  return (
    <>
      <Container>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} alignItems="flex-end">
            <Grid item xs={12} mt={2} mb={-2}>
              <Typography
                variant="subtitle1"
                style={{ color: PgColors.bluepg[400] }}
                sx={{
                  fontSize: { xs: 10, md: 14, lg: 18 },
                  fontWeight: { md: 400 },
                }}
                align="left"
              >
                Tegenslag 2:
              </Typography>
            </Grid>
            <Grid item md={10} xs={12}>
              <TextField
                size="small"
                color="warning"
                variant="outlined"
                fullWidth
                multiline
                rows={2}
                value={answer1}
                onChange={(event) => setAnswer1(event.target.value)}
              />
            </Grid>
            <Grid item xs={12} mt={2} mb={-2}>
              <Typography
                variant="subtitle1"
                style={{ color: PgColors.bluepg[400] }}
                sx={{
                  fontSize: { xs: 10, md: 14, lg: 18 },
                  fontWeight: { md: 400 },
                }}
                align="left"
              >
                Strategie 2:
              </Typography>
            </Grid>
            <Grid item md={10} xs={12}>
              <TextField
                size="small"
                color="warning"
                variant="outlined"
                fullWidth
                multiline
                rows={2}
                value={answer2}
                onChange={(event) => setAnswer2(event.target.value)}
              />
            </Grid>

            <Grid item md={2} xs={12}>
              <Button
                style={{
                  backgroundColor: PgColors.bluepg[200],
                }}
                variant="contained"
                type="submit"
                size="large"
                sx={{ height: { xs: 30, md: 55, lg: 55 } }}
              >
                <Typography variant="subtitle1">Voeg Toe</Typography>
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </>
  );
};

export const AddExercise3D = () => {
  const [answer1, setAnswer1] = useState("");
  const [answer2, setAnswer2] = useState("");
  const { user } = useAuthContext();
  const { id } = useParams();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const ref = collection(db, `IntroExercises/${user.uid}/UserExercise3D`);

    await addDoc(ref, {
      answer1: answer1,
      answer2: answer2,
      uid: user.uid,
      createdAt: serverTimestamp(),
    });
    setAnswer1("");
    setAnswer2("");
  };

  return (
    <>
      <Container>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} alignItems="flex-end">
            <Grid item xs={12} mt={2} mb={-2}>
              <Typography
                variant="subtitle1"
                style={{ color: PgColors.bluepg[400] }}
                sx={{
                  fontSize: { xs: 10, md: 14, lg: 18 },
                  fontWeight: { md: 400 },
                }}
                align="left"
              >
                Tegenslag 3:
              </Typography>
            </Grid>
            <Grid item md={10} xs={12}>
              <TextField
                size="small"
                color="warning"
                variant="outlined"
                fullWidth
                multiline
                rows={2}
                value={answer1}
                onChange={(event) => setAnswer1(event.target.value)}
              />
            </Grid>
            <Grid item xs={12} mt={2} mb={-2}>
              <Typography
                variant="subtitle1"
                style={{ color: PgColors.bluepg[400] }}
                sx={{
                  fontSize: { xs: 10, md: 14, lg: 18 },
                  fontWeight: { md: 400 },
                }}
                align="left"
              >
                Strategie 3:
              </Typography>
            </Grid>
            <Grid item md={10} xs={12}>
              <TextField
                size="small"
                color="warning"
                variant="outlined"
                fullWidth
                multiline
                rows={2}
                value={answer2}
                onChange={(event) => setAnswer2(event.target.value)}
              />
            </Grid>

            <Grid item md={2} xs={12}>
              <Button
                style={{
                  backgroundColor: PgColors.bluepg[200],
                }}
                variant="contained"
                type="submit"
                size="large"
                sx={{ height: { xs: 30, md: 55, lg: 55 } }}
              >
                <Typography variant="subtitle1">Voeg Toe</Typography>
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </>
  );
};
