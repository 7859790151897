import React, { useState } from "react";
import {
  collection,
  addDoc,
  serverTimestamp,
  updateDoc,
  DocumentReference,
  doc,
  deleteDoc,
  setDoc,
} from "firebase/firestore";
import {
  Grid,
  Container,
  Typography,
  Paper,
  TextField,
  Button,
  CircularProgress,
} from "@mui/material";
import { getFunctions, httpsCallable } from "firebase/functions";
import { useAuthContext } from "../../hooks/useAuthContext";
import { db } from "../../firebase/config";
import { PgColors } from "../../configs/colors";

export const NewGymnasts = () => {
  const { user } = useAuthContext();
  const [firstname, setFirstname] = useState<string>("");
  const [lastname, setLastname] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [dateOfBirth, setDateOfBirth] = useState<string>("");
  const [ageCategory, setAgeCategory] = useState<string>("");
  const [level, setLevel] = useState<string>("");
  const [firstnameParent1, setFirstnameParent1] = useState<string>("");
  const [lastnameParent1, setLastnameParent1] = useState<string>("");
  const [emailParent1, setEmailParent1] = useState<string>("");
  const [firstnameParent2, setFirstnameParent2] = useState<string>("");
  const [lastnameParent2, setLastnameParent2] = useState<string>("");
  const [emailParent2, setEmailParent2] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [phoneParent1, setPhoneParent1] = useState<string>("");
  const [phoneParent2, setPhoneParent2] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [role, setRole] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const functions = getFunctions();
  const addGymnasts = httpsCallable(functions, "createUser");
  // const addGymnastToCollection = httpsCallable(functions, "addNewUserToCollection")

  const handleSubmit = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();

    setIsLoading(true);

    const ref = collection(db, "GymnastsByCoach", user.uid, "Gymnasts");

    await addDoc(ref, {
      firstname: firstname,
      lastname: lastname,
      email: email,
      dateOfBirth: dateOfBirth,
      ageCategory: ageCategory,
      level: level,
      phone: phone,
      firstnameParent1: firstnameParent1,
      lastnameParent1: lastnameParent1,
      emailParent1: emailParent1,
      phoneParent1: phoneParent1,
      firstnameParent2: firstnameParent2,
      lastnameParent2: lastnameParent2,
      emailParent2: emailParent2,
      phoneParent2: phoneParent2,
      password: password,
      coachId: user.uid,
      createdAt: serverTimestamp(),
      role: "Gymnast",
    });
    await addGymnasts({
      email: email,
      firstname: firstname,
      password: password,
    })
      .then(function (resp: any) {
        console.log(resp.data.result);
      })
      .catch();
    setFirstname("");
    setLastname("");
    setEmail("");
    setDateOfBirth("");
    setAgeCategory("");
    setLevel("");
    setFirstnameParent1("");
    setLastnameParent1("");
    setEmailParent1("");
    setFirstnameParent2("");
    setLastnameParent2("");
    setEmailParent2("");
    setPhone("");
    setPhoneParent1("");
    setPhoneParent2("");
    setPassword("");
    setRole("gymnast");
    setIsLoading(false);
  };

  const handleSubmit2 = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    setIsLoading(true);

    const ref = collection(db, "GymnastsByCoach", user.uid, "Gymnasts");

    try {
      // Create the gymnast document in the Firestore "Gymnasts" collection
      const gymnastDocRef = await addDoc(ref, {
        firstname: firstname,
        lastname: lastname,
        email: email,
        dateOfBirth: dateOfBirth,
        ageCategory: ageCategory,
        level: level,
        phone: phone,
        firstnameParent1: firstnameParent1,
        lastnameParent1: lastnameParent1,
        emailParent1: emailParent1,
        phoneParent1: phoneParent1,
        firstnameParent2: firstnameParent2,
        lastnameParent2: lastnameParent2,
        emailParent2: emailParent2,
        phoneParent2: phoneParent2,
        password: password,
        coachId: user.uid,
        createdAt: serverTimestamp(),
        role: "Gymnast",
      });

      // Create the gymnast as a new user in Firebase Authentication
      const addGymnastResult: any = await addGymnasts({
        email: email,
        firstname: firstname,
        password: password,
        coachId: user.uid,
      });

      console.log("After addGymnasts");

      console.log("addGymnastResult:", addGymnastResult.data);

      const userUID: string = addGymnastResult.data;
      console.log("After UID");
      console.log("User UID:", userUID);

      console.log("userUID.trim() !== '':", userUID?.trim() !== "");

      if (userUID && userUID.trim() !== "") {
        await updateDoc(gymnastDocRef, {
          gymnastId: userUID,
        });
        console.log("Successfully added new gymnast!");
      } else {
        console.error("Failed to get User UID from the result.");
      }
    } catch (error) {
      console.error("Error creating new gymnast:", error);
    }

    setFirstname("");
    setLastname("");
    setEmail("");
    setDateOfBirth("");
    setAgeCategory("");
    setLevel("");
    setFirstnameParent1("");
    setLastnameParent1("");
    setEmailParent1("");
    setFirstnameParent2("");
    setLastnameParent2("");
    setEmailParent2("");
    setPhone("");
    setPhoneParent1("");
    setPhoneParent2("");
    setPassword("");
    setRole("gymnast");
    setIsLoading(false);
  };

  return (
    <Container>
      <Grid
        container
        marginTop={4}
        display="flex"
        justifyContent="center"
        sx={{ marginLeft: { lg: 20 }, marginTop: { lg: 12 } }}
      >
        <Paper
          elevation={2}
          sx={{
            width: { xs: 350, sm: 600 },

            height: { xs: 850 },
          }}
        >
          <Container>
            <Grid container>
              <Grid item marginTop={6}>
                <Typography
                  variant="h6"
                  style={{ color: PgColors.redpg[500], fontWeight: "bold" }}
                >
                  Voeg nieuwe turnster toe
                </Typography>
              </Grid>
            </Grid>
            <form>
              <Grid container marginTop={4} spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    style={{
                      color: PgColors.bluepg[200],
                    }}
                    label="Voornaam"
                    fullWidth
                    type="text"
                    variant="outlined"
                    value={firstname}
                    onChange={(event) => setFirstname(event.target.value)}
                  ></TextField>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Achternaam"
                    fullWidth
                    type="text"
                    variant="outlined"
                    value={lastname}
                    onChange={(event) => setLastname(event.target.value)}
                  ></TextField>
                </Grid>
              </Grid>
              <Grid container mt={3}>
                <Grid item xs={12}>
                  <Typography variant="body1">
                    Dit is ook het email adres waarmee de turnster zelf kan
                    inloggen:
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Email"
                    fullWidth
                    type="text"
                    variant="outlined"
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                  ></TextField>
                </Grid>
              </Grid>
              <Grid container marginTop={2} spacing={1}>
                <Grid item xs={4}>
                  <TextField
                    label="Geboortejaar"
                    fullWidth
                    type="text"
                    variant="outlined"
                    value={dateOfBirth}
                    onChange={(event) => setDateOfBirth(event.target.value)}
                  ></TextField>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    label="Leeftijd Categorie"
                    fullWidth
                    type="text"
                    variant="outlined"
                    value={ageCategory}
                    onChange={(event) => setAgeCategory(event.target.value)}
                  ></TextField>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    label="Niveau"
                    fullWidth
                    type="text"
                    variant="outlined"
                    value={level}
                    onChange={(event) => setLevel(event.target.value)}
                  ></TextField>
                </Grid>
              </Grid>

              <Grid container marginTop={3}>
                <Grid item xs={12}>
                  <Typography variant="body1">
                    Bedenk het wachtwoord wat de turnster in moet vullen om in
                    te loggen:
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Wachtwoord"
                    fullWidth
                    type="password"
                    variant="outlined"
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                  ></TextField>
                </Grid>
              </Grid>
              <Grid container marginTop={4}>
                <Grid item md={4} marginLeft={4}>
                  <Button
                    style={{
                      backgroundColor: PgColors.bluepg[200],
                    }}
                    variant="contained"
                    size="large"
                    onClick={(
                      e: React.MouseEvent<HTMLButtonElement, MouseEvent>
                    ) => handleSubmit2(e)}
                  >
                    <Typography style={{ fontWeight: "semibold" }}>
                      ADD GYMNAST
                    </Typography>
                  </Button>
                  {isLoading && <CircularProgress />}
                </Grid>
              </Grid>
            </form>
          </Container>
        </Paper>
      </Grid>
    </Container>
  );
};
