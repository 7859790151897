import React, { useState } from "react";
import {
  collection,
  addDoc,
  serverTimestamp,
  doc,
  deleteDoc,
  setDoc,
} from "firebase/firestore";
import { db } from "../../../../firebase/config";
import {
  Container,
  Grid,
  Typography,
  Paper,
  TextField,
  Button,
  Modal,
  Toolbar,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Box,
  Drawer,
} from "@mui/material";
import { useAuthContext } from "../../../../hooks/useAuthContext";
import { useParams } from "react-router-dom";
import { PgColors } from "../../../../configs/colors";

export const AddExercise7A = () => {
  const [answer, setAnswer] = useState("");
  const { user } = useAuthContext();
  const { id } = useParams();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const ref = collection(db, `IntroExercises/${id}/UserExercise7A`);

    await addDoc(ref, {
      answer: answer,
      uid: user.uid,
      createdAt: serverTimestamp(),
    });
    setAnswer("");
  };

  return (
    <>
      <Container>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} alignItems="flex-end">
            <Grid item xs={12} mt={1} mb={-2}>
              <Typography
                variant="body1"
                sx={{
                  fontSize: { xs: 10, md: 12, lg: 16 },
                }}
                align="left"
              >
                Wat zijn alle dingen waar je invloed op hebt voor en tijdens een
                wedstrijd:
              </Typography>
            </Grid>
            <Grid item md={10} xs={12}>
              <TextField
                InputLabelProps={{
                  sx: {
                    fontSize: { xs: 10, md: 14, lg: 16 },
                  },
                }}
                size="small"
                label="Bijv: hoe vaak je traint of je eigen gedrag"
                color="warning"
                variant="outlined"
                fullWidth
                multiline
                rows={5}
                value={answer}
                onChange={(event) => setAnswer(event.target.value)}
              />
            </Grid>

            <Grid item md={2} xs={12}>
              <Button
                style={{
                  backgroundColor: PgColors.bluepg[200],
                }}
                variant="contained"
                type="submit"
                size="large"
                sx={{ height: { xs: 30, md: 55, lg: 55 } }}
              >
                <Typography variant="subtitle1">Voeg Toe</Typography>
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </>
  );
};

export const AddExercise7B = () => {
  const [answer, setAnswer] = useState("");
  const { user } = useAuthContext();
  const { id } = useParams();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const ref = collection(db, `IntroExercises/${id}/UserExercise7B`);

    await addDoc(ref, {
      answer: answer,
      uid: user.uid,
      createdAt: serverTimestamp(),
    });
    setAnswer("");
  };

  return (
    <>
      <Container>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} alignItems="flex-end">
            <Grid item xs={12} mt={1} mb={-2}>
              <Typography
                variant="body1"
                sx={{
                  fontSize: { xs: 10, md: 12, lg: 16 },
                }}
                align="left"
              >
                Wat zijn alle dingen waar je geen invloed op hebt voor en
                tijdens een wedstrijd:
              </Typography>
            </Grid>
            <Grid item md={10} xs={12}>
              <TextField
                InputLabelProps={{
                  sx: {
                    fontSize: { xs: 10, md: 14, lg: 16 },
                  },
                }}
                size="small"
                label="Bijv: Wat andere mensen vinden of zeggen"
                color="warning"
                variant="outlined"
                fullWidth
                multiline
                rows={5}
                value={answer}
                onChange={(event) => setAnswer(event.target.value)}
              />
            </Grid>

            <Grid item md={2} xs={12}>
              <Button
                style={{
                  backgroundColor: PgColors.bluepg[200],
                }}
                variant="contained"
                type="submit"
                size="large"
                sx={{ height: { xs: 30, md: 55, lg: 55 } }}
              >
                <Typography variant="subtitle1">Voeg Toe</Typography>
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </>
  );
};
