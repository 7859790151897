import React, { useState, useEffect } from "react";
import {
  Grid,
  Container,
  Typography,
  Divider,
  Button,
  Paper,
} from "@mui/material";
import { PgColors } from "../../../configs/colors";

export const Sup1Bars = () => {
  return (
    <>
      <Grid container mt={2} marginLeft={2}>
        <Grid item>
          <Typography variant="h6" sx={{ color: PgColors.redpg[500] }}>
            Brug
          </Typography>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item mt={0} xs={12}>
          <Paper
            elevation={2}
            sx={{ backgroundColor: PgColors.bluepg[100], width: "100%" }}
          >
            <Container>
              <Grid
                container
                marginTop={0}
                sx={{ borderBottom: 1, borderColor: PgColors.bluepg[200] }}
                display={{ xs: "none", md: "flex" }}
              >
                <Grid
                  item
                  md={1}
                  display={{ xs: "none", md: "flex" }}
                  sx={{ marginRight: { md: -4, lg: -8 } }}
                ></Grid>
                <Grid item xs={11} md={5}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontWeight: 500,
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    Samenstellingselement
                  </Typography>
                </Grid>
                <Grid item xs={1} md={1} sx={{ pl: { md: 2 } }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontWeight: 500,
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    D
                  </Typography>
                </Grid>

                <Grid item md={5} display={{ xs: "none", md: "flex" }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontWeight: 500,
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    Bonus
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{
                    mr: { md: -4 },
                  }}
                  display={{ xs: "none", md: "flex" }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontWeight: 500,
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    D
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                container
                marginTop={1}
                sx={{ borderBottom: 1, borderColor: PgColors.bluepg[200] }}
                display={{ xs: "none", md: "flex" }}
              >
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{ marginRight: { md: -4, lg: -8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    1
                  </Typography>
                </Grid>
                <Grid item xs={10} md={5}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    Zweefkip direct gevolgd door opzwaai min 90º - heupdraai
                    a.o. direct gevolgd door opzwaai zweefkip
                  </Typography>
                </Grid>
                <Grid item xs={1} md={1} sx={{ pl: { md: 2 } }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    1.00
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={5}
                  display={{ xs: "none", md: "flex" }}
                  sx={{ pr: { md: 2 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                      marginRight: { md: -2 },
                    }}
                    align="left"
                  >
                    SE 1 uitgevoerd met opzwaai 180º (max. afwijking 30º) (voor
                    heupdraai ao) óf SE 1 uitgevoerd als zweefkip direct gevogld
                    door opzwaai 180º met 1/2 draai direct gevolgd door zweefkip
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={1}
                  display={{ xs: "none", md: "flex" }}
                  sx={{ mr: { md: -4 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    0.30
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                container
                marginTop={1}
                sx={{ borderBottom: 1, borderColor: PgColors.bluepg[200] }}
                display={{ xs: "none", md: "flex" }}
              >
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{ marginRight: { md: -4, lg: -8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    2
                  </Typography>
                </Grid>
                <Grid item xs={10} md={5}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    Serie van 2 kleine cirkeldraaien tot min. 90º (mogen
                    dezelfde elementen zijn)
                  </Typography>
                </Grid>
                <Grid item xs={1} md={1} sx={{ pl: { md: 2 } }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    1.00
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={5}
                  sx={{ pr: { md: 2 } }}
                  display={{ xs: "none", md: "flex" }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    SE 2 uitgevoerd met 2 verschillende cirkeldraaien
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={1}
                  display={{ xs: "none", md: "flex" }}
                  sx={{ mr: { md: -4 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    0.30
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                container
                marginTop={1}
                sx={{ borderBottom: 1, borderColor: PgColors.bluepg[200] }}
                display={{ xs: "none", md: "flex" }}
              >
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{ marginRight: { md: -4, lg: -8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    3
                  </Typography>
                </Grid>
                <Grid item xs={10} md={5}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    Serie van 3 reuzendraaien ao (met draaien)
                  </Typography>
                </Grid>
                <Grid item xs={1} md={1} sx={{ pl: { md: 2 } }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    1.00
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={5}
                  sx={{ pr: { md: 2 } }}
                  display={{ xs: "none", md: "flex" }}
                ></Grid>
                <Grid item md={1} sx={{ mr: { md: -4 } }}></Grid>
              </Grid>

              <Grid
                container
                marginTop={1}
                sx={{ borderBottom: 1, borderColor: PgColors.bluepg[200] }}
                display={{ xs: "none", md: "flex" }}
              >
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{ marginRight: { md: -4, lg: -8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    4
                  </Typography>
                </Grid>
                <Grid item xs={10} md={5}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    Kleine cirkeldraai tot handstand (max. afwijking 30º)
                  </Typography>
                </Grid>
                <Grid item xs={1} md={1} sx={{ pl: { md: 2 } }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    0.50
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={5}
                  display={{ xs: "none", md: "flex" }}
                  sx={{ pr: { md: 2 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    Drie verschillende kleine cirkeldraaien tot handstand (max.
                    afwijking 30º)
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={1}
                  display={{ xs: "none", md: "flex" }}
                  sx={{ mr: { md: -4 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    0.30
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                container
                marginTop={1}
                sx={{ borderBottom: 1, borderColor: PgColors.bluepg[200] }}
                display={{ xs: "none", md: "flex" }}
              >
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{ marginRight: { md: -4, lg: -8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    5
                  </Typography>
                </Grid>
                <Grid item xs={10} md={5}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    Een element met lengte-as draai van min. 180º door of tot
                    handstand (zonder vlucht) (max. afwijking 45º)
                  </Typography>
                </Grid>
                <Grid item xs={1} md={1} sx={{ pl: { md: 2 } }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    0.50
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={5}
                  display={{ xs: "none", md: "flex" }}
                  sx={{ pr: { md: 2 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    SE 5 uitgevoerd binnen 30º
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={1}
                  display={{ xs: "none", md: "flex" }}
                  sx={{ mr: { md: -4 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    0.30
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                container
                marginTop={1}
                sx={{ borderBottom: 1, borderColor: PgColors.bluepg[200] }}
                display={{ xs: "none", md: "flex" }}
              >
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{ marginRight: { md: -4, lg: -8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    6
                  </Typography>
                </Grid>
                <Grid item xs={10} md={5}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    Afsprong: Streksalto ao vanuit kurbetinzet
                  </Typography>
                </Grid>
                <Grid item xs={1} md={1} sx={{ pl: { md: 2 } }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    0.50
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={5}
                  display={{ xs: "none", md: "flex" }}
                  sx={{ pr: { md: 2 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    Ipv SE 6: Afsprong: Dubbel salto ao vanuit kurbetinzet
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={1}
                  display={{ xs: "none", md: "flex" }}
                  sx={{ mr: { md: -4 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    0.30
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                container
                marginTop={1}
                display={{ xs: "none", md: "flex" }}
              >
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{ marginRight: { md: -4, lg: -8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    7
                  </Typography>
                </Grid>
                <Grid item xs={10} md={5}></Grid>
                <Grid item xs={1}></Grid>
                <Grid
                  item
                  md={5}
                  display={{ xs: "none", md: "flex" }}
                  sx={{ pr: { md: 2 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    Reus vo met open heupen in 4e kwartier
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={1}
                  display={{ xs: "none", md: "flex" }}
                  sx={{ mr: { md: -4 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    0.30
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                container
                mt={2}
                display={{ xs: "flex", md: "none" }}
                sx={{ borderBottom: 1, borderColor: PgColors.bluepg[200] }}
              >
                <Grid item xs={11} md={4} sx={{ marginRight: { md: 10 } }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontWeight: 500,
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    Samenstellingselement
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontWeight: 500,
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    D
                  </Typography>
                </Grid>
              </Grid>
              <Grid container mt={1} display={{ xs: "flex", md: "none" }}>
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{ marginRight: { md: -4, lg: -8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    1
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={10}
                  sx={{ paddingRight: { md: 0 }, marginRight: { md: 8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    Zweefkip direct gevolgd door opzwaai min 90º - heupdraai
                    a.o. direct gevolgd door opzwaai zweefkip
                  </Typography>
                </Grid>
                <Grid item xs={1} md={1} sx={{ marginRight: { md: 0 } }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    1.00
                  </Typography>
                </Grid>
              </Grid>
              <Grid container mt={1} display={{ xs: "flex", md: "none" }}>
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{ marginRight: { md: -4, lg: -8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    2
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={10}
                  sx={{ paddingRight: { md: 0 }, marginRight: { md: 8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    Serie van 2 kleine cirkeldraaien tot min. 90º (mogen
                    dezelfde elementen zijn)
                  </Typography>
                </Grid>
                <Grid item xs={1} md={1} sx={{ marginRight: { md: 0 } }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    1.00
                  </Typography>
                </Grid>
              </Grid>
              <Grid container mt={1} display={{ xs: "flex", md: "none" }}>
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{ marginRight: { md: -4, lg: -8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    3
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={10}
                  sx={{ paddingRight: { md: 0 }, marginRight: { md: 8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    Serie van 3 reuzendraaien ao (met draaien)
                  </Typography>
                </Grid>
                <Grid item xs={1} md={1} sx={{ marginRight: { md: 0 } }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    1.00
                  </Typography>
                </Grid>
              </Grid>
              <Grid container mt={1} display={{ xs: "flex", md: "none" }}>
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{ marginRight: { md: -4, lg: -8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    4
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={10}
                  sx={{ paddingRight: { md: 0 }, marginRight: { md: 8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    Kleine cirkeldraai tot handstand (max. afwijking 30º)
                  </Typography>
                </Grid>
                <Grid item xs={1} md={1} sx={{ marginRight: { md: 0 } }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    0.50
                  </Typography>
                </Grid>
              </Grid>
              <Grid container mt={1} display={{ xs: "flex", md: "none" }}>
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{ marginRight: { md: -4, lg: -8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    5
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={10}
                  sx={{ paddingRight: { md: 0 }, marginRight: { md: 8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    Een element met lengte-as draai van min. 180º door of tot
                    handstand (zonder vlucht) (max. afwijking 45º)
                  </Typography>
                </Grid>
                <Grid item xs={1} md={1} sx={{ marginRight: { md: 0 } }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    0.50
                  </Typography>
                </Grid>
              </Grid>
              <Grid container mt={1} display={{ xs: "flex", md: "none" }}>
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{ marginRight: { md: -4, lg: -8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    6
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={10}
                  sx={{ paddingRight: { md: 0 }, marginRight: { md: 8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    Afsprong: Streksalto ao vanuit kurbetinzet
                  </Typography>
                </Grid>
                <Grid item xs={1} md={1} sx={{ marginRight: { md: 0 } }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    0.50
                  </Typography>
                </Grid>
              </Grid>
              <Grid container mt={1} display={{ xs: "flex", md: "none" }}>
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{ marginRight: { md: -4, lg: -8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    7
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={10}
                  sx={{ paddingRight: { md: 0 }, marginRight: { md: 8 } }}
                ></Grid>
                <Grid item xs={1} md={1} sx={{ marginRight: { md: 0 } }}></Grid>
              </Grid>

              <Grid
                container
                mt={2}
                display={{ xs: "flex", md: "none" }}
                sx={{ borderBottom: 1, borderColor: PgColors.bluepg[200] }}
              >
                <Grid item xs={11} md={4} sx={{ marginRight: { md: 10 } }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontWeight: 500,
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    Bonus
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontWeight: 500,
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    D
                  </Typography>
                </Grid>
              </Grid>

              <Grid container mt={1} display={{ xs: "flex", md: "none" }}>
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{ marginRight: { md: -4, lg: -8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    1
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={10}
                  sx={{ paddingRight: { md: 0 }, marginRight: { md: 8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    SE 1 uitgevoerd met opzwaai 180º (max. afwijking 30º) (voor
                    heupdraai ao) óf SE 1 uitgevoerd als zweefkip direct gevogld
                    door opzwaai 180º met 1/2 draai direct gevolgd door zweefkip
                  </Typography>
                </Grid>
                <Grid item xs={1} md={1} sx={{ marginRight: { md: 0 } }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    0.30
                  </Typography>
                </Grid>
              </Grid>
              <Grid container mt={1} display={{ xs: "flex", md: "none" }}>
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{ marginRight: { md: -4, lg: -8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    2
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={10}
                  sx={{ paddingRight: { md: 0 }, marginRight: { md: 8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    SE 2 uitgevoerd met 2 verschillende cirkeldraaien
                  </Typography>
                </Grid>
                <Grid item xs={1} md={1} sx={{ marginRight: { md: 0 } }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    0.30
                  </Typography>
                </Grid>
              </Grid>
              <Grid container mt={1} display={{ xs: "flex", md: "none" }}>
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{ marginRight: { md: -4, lg: -8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    3
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={10}
                  sx={{ paddingRight: { md: 0 }, marginRight: { md: 8 } }}
                ></Grid>
                <Grid item xs={1} md={1} sx={{ marginRight: { md: 0 } }}></Grid>
              </Grid>
              <Grid container mt={1} display={{ xs: "flex", md: "none" }}>
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{ marginRight: { md: -4, lg: -8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    4
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={10}
                  sx={{ paddingRight: { md: 0 }, marginRight: { md: 8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    Drie verschillende kleine cirkeldraaien tot handstand (max.
                    afwijking 30º)
                  </Typography>
                </Grid>
                <Grid item xs={1} md={1} sx={{ marginRight: { md: 0 } }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    0.30
                  </Typography>
                </Grid>
              </Grid>
              <Grid container mt={1} display={{ xs: "flex", md: "none" }}>
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{ marginRight: { md: -4, lg: -8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    5
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={10}
                  sx={{ paddingRight: { md: 0 }, marginRight: { md: 8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    SE 5 uitgevoerd binnen 30º
                  </Typography>
                </Grid>
                <Grid item xs={1} md={1} sx={{ marginRight: { md: 0 } }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    0.30
                  </Typography>
                </Grid>
              </Grid>
              <Grid container mt={1} display={{ xs: "flex", md: "none" }}>
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{ marginRight: { md: -4, lg: -8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    6
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={10}
                  sx={{ paddingRight: { md: 0 }, marginRight: { md: 8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    Ipv SE 6: Afsprong: Dubbel salto ao vanuit kurbetinzet
                  </Typography>
                </Grid>
                <Grid item xs={1} md={1} sx={{ marginRight: { md: 0 } }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    0.30
                  </Typography>
                </Grid>
              </Grid>
              <Grid container mt={1} display={{ xs: "flex", md: "none" }}>
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{ marginRight: { md: -4, lg: -8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    7
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={10}
                  sx={{ paddingRight: { md: 0 }, marginRight: { md: 8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    Reus vo met open heupen in 4e kwartier
                  </Typography>
                </Grid>
                <Grid item xs={1} md={1} sx={{ marginRight: { md: 0 } }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    0.30
                  </Typography>
                </Grid>
              </Grid>
            </Container>
          </Paper>
        </Grid>
      </Grid>
      <Grid container marginLeft={2}>
        <Grid item>
          <Typography
            variant="subtitle1"
            sx={{
              color: PgColors.redpg[500],
              fontSize: { xs: 12, sm: 14, md: 16 },
            }}
            align="left"
          >
            *SE 2 / Bonus 2: Een serie van losom handstand (C) - losom 90º (B)
            zijn dezelfde elementen en mag voor SE 2 geturnd worden.
          </Typography>
        </Grid>
      </Grid>
      <Grid container marginLeft={2}>
        <Grid item>
          <Typography
            variant="subtitle1"
            sx={{
              color: PgColors.redpg[500],
              fontSize: { xs: 12, sm: 14, md: 16 },
            }}
            align="left"
          >
            *SE 4 mag gecombineerd worden met SE 2.
          </Typography>
        </Grid>
      </Grid>
      <Grid container marginLeft={2}>
        <Grid item>
          <Typography
            variant="subtitle1"
            sx={{
              color: PgColors.redpg[500],
              fontSize: { xs: 12, sm: 14, md: 16 },
            }}
            align="left"
          >
            *SE 5 / Bonus 5 mag gecombineerd worden met Bonus 1.
          </Typography>
        </Grid>
      </Grid>
      <Grid container marginLeft={2}>
        <Grid item>
          <Typography
            variant="subtitle1"
            sx={{
              color: PgColors.redpg[500],
              fontSize: { xs: 12, sm: 14, md: 16 },
            }}
            align="left"
          >
            *Extra dempingsmat toegestaan bij vluchtelementen en bij
            liggerwissels.
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};
