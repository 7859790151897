import React, { useState } from "react";
import {
  collection,
  addDoc,
  serverTimestamp,
  doc,
  setDoc,
} from "firebase/firestore";
import { db } from "../../../../firebase/config";
import AddIcon from "@mui/icons-material/Add";
import {
  Container,
  Grid,
  TextField,
  Button,
  Paper,
  Typography,
} from "@mui/material";
import { useAuthContext } from "../../../../hooks/useAuthContext";
import { useParams } from "react-router-dom";
import { PgColors } from "../../../../configs/colors";

export const AddCompetition2 = () => {
  const { id } = useParams();
  const { user } = useAuthContext();
  const [competition, setCompetition] = useState("");
  const [date, setDate] = useState("");
  const [location, setLocation] = useState("");
  const [dVault1, setDVault1] = useState("");
  const [eVault1, setEVault1] = useState("");
  const [nVault1, setNVault1] = useState("");
  const [tVault1, setTVault1] = useState("");
  const [dVault2, setDVault2] = useState("");
  const [eVault2, setEVault2] = useState("");
  const [nVault2, setNVault2] = useState("");
  const [tVault2, setTVault2] = useState("");
  const [pVault, setPVault] = useState("");
  const [dBars, setDBars] = useState("");
  const [eBars, setEBars] = useState("");
  const [nBars, setNBars] = useState("");
  const [tBars, setTBars] = useState("");
  const [pBars, setPBars] = useState("");
  const [dBeam, setDBeam] = useState("");
  const [eBeam, setEBeam] = useState("");
  const [nBeam, setNBeam] = useState("");
  const [tBeam, setTBeam] = useState("");
  const [pBeam, setPBeam] = useState("");
  const [dFloor, setDFloor] = useState("");
  const [eFloor, setEFloor] = useState("");
  const [nFloor, setNFloor] = useState("");
  const [tFloor, setTFloor] = useState("");
  const [pFloor, setPFloor] = useState("");
  const [tTotal, setTTotal] = useState("");
  const [pTotal, setPTotal] = useState("");
  const [evaluation, setEvaluation] = useState("");

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const ref = collection(db, `Competitions/${user.uid}/userCompetitions`);

    await addDoc(ref, {
      competition: competition,
      date: date,
      location: location,
      dVault1: dVault1,
      eVault1: eVault1,
      nVault1: nVault1,
      tVault1: tVault1,
      dVault2: dVault2,
      eVault2: eVault2,
      nVault2: nVault2,
      tVault2: tVault2,
      pVault: pVault,
      dBars: dBars,
      eBars: eBars,
      nBars: nBars,
      tBars: tBars,
      pBars: pBars,
      dBeam: dBeam,
      eBeam: eBeam,
      nBeam: nBeam,
      tBeam: tBeam,
      pBeam: pBeam,
      dFloor: dFloor,
      eFloor: eFloor,
      nFloor: nFloor,
      tFloor: tFloor,
      pFloor: pFloor,
      tTotal: tTotal,
      pTotal: pTotal,
      evaluation: evaluation,
      uid: user.uid,
      createdAt: serverTimestamp(),
    });
    setCompetition("");
    setDate("");
    setLocation("");
    setDVault1("");
    setEVault1("");
    setNVault1("");
    setTVault1("");
    setDVault2("");
    setEVault2("");
    setNVault2("");
    setTVault2("");
    setPVault("");
    setDBars("");
    setEBars("");
    setNBars("");
    setTBars("");
    setPBars("");
    setDBeam("");
    setEBeam("");
    setNBeam("");
    setTBeam("");
    setPBeam("");
    setDFloor("");
    setEFloor("");
    setNFloor("");
    setTFloor("");
    setPFloor("");
    setTTotal("");
    setPTotal("");
    setEvaluation("");
  };

  return (
    <>
      <Container>
        <form onSubmit={handleSubmit}>
          <Grid container>
            <Grid item xs={12} md={4}>
              <TextField
                label="Wedstrijd"
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                fullWidth
                value={competition}
                onChange={(event) => setCompetition(event.target.value)}
              />
            </Grid>
            <Grid item xs={7} md={4}>
              <TextField
                label="Locatie"
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                fullWidth
                value={location}
                onChange={(event) => setLocation(event.target.value)}
              />
            </Grid>
            <Grid item xs={5} md={4}>
              <TextField
                label="Datum"
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                fullWidth
                value={date}
                onChange={(event) => setDate(event.target.value)}
              />
            </Grid>
          </Grid>
          <Grid container mt={1} display={{ xs: "none", md: "flex" }}>
            <Grid item md={2}></Grid>
            <Grid item md={1}>
              <Paper
                style={{ backgroundColor: PgColors.redpg[200], height: 35 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                  align="center"
                >
                  Sprong 1
                </Typography>
              </Paper>
            </Grid>
            <Grid item md={1}>
              <Paper
                style={{ backgroundColor: PgColors.redpg[200], height: 35 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                  align="center"
                >
                  Sprong 2
                </Typography>
              </Paper>
            </Grid>
            <Grid item md={2}>
              <Paper
                style={{ backgroundColor: PgColors.redpg[200], height: 35 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                  align="center"
                >
                  Brug
                </Typography>
              </Paper>
            </Grid>
            <Grid item md={2}>
              <Paper
                style={{ backgroundColor: PgColors.redpg[200], height: 35 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                  align="center"
                >
                  Balk
                </Typography>
              </Paper>
            </Grid>
            <Grid item md={2}>
              <Paper
                style={{ backgroundColor: PgColors.redpg[200], height: 35 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                  align="center"
                >
                  Vloer
                </Typography>
              </Paper>
            </Grid>
            <Grid item md={2}>
              <Paper
                style={{ backgroundColor: PgColors.redpg[200], height: 35 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                  align="center"
                >
                  Totaal
                </Typography>
              </Paper>
            </Grid>
          </Grid>
          <Grid container mt={2} display={{ xs: "none", md: "flex" }}>
            <Grid item md={2}>
              <Paper
                style={{ backgroundColor: PgColors.redpg[200], height: 55 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white", fontSize: 20 }}
                  align="center"
                >
                  D-Score
                </Typography>
              </Paper>
            </Grid>
            <Grid item md={1}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                fullWidth
                value={dVault1}
                onChange={(event) => setDVault1(event.target.value)}
              />
            </Grid>
            <Grid item md={1}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                fullWidth
                value={dVault2}
                onChange={(event) => setDVault2(event.target.value)}
              />
            </Grid>
            <Grid item md={2}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                fullWidth
                value={dBars}
                onChange={(event) => setDBars(event.target.value)}
              />
            </Grid>
            <Grid item md={2}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                fullWidth
                value={dBeam}
                onChange={(event) => setDBeam(event.target.value)}
              />
            </Grid>
            <Grid item md={2}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                fullWidth
                value={dFloor}
                onChange={(event) => setDFloor(event.target.value)}
              />
            </Grid>
          </Grid>
          <Grid container mt={2} display={{ xs: "none", md: "flex" }}>
            <Grid item md={2}>
              <Paper
                style={{ backgroundColor: PgColors.redpg[200], height: 55 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white", fontSize: 20 }}
                  align="center"
                >
                  E-Score
                </Typography>
              </Paper>
            </Grid>
            <Grid item md={1}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                fullWidth
                value={eVault1}
                onChange={(event) => setEVault1(event.target.value)}
              />
            </Grid>
            <Grid item md={1}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                fullWidth
                value={eVault2}
                onChange={(event) => setEVault2(event.target.value)}
              />
            </Grid>
            <Grid item md={2}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                fullWidth
                value={eBars}
                onChange={(event) => setEBars(event.target.value)}
              />
            </Grid>
            <Grid item md={2}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                fullWidth
                value={eBeam}
                onChange={(event) => setEBeam(event.target.value)}
              />
            </Grid>
            <Grid item md={2}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                fullWidth
                value={eFloor}
                onChange={(event) => setEFloor(event.target.value)}
              />
            </Grid>
          </Grid>
          <Grid container mt={2} display={{ xs: "none", md: "flex" }}>
            <Grid item md={2}>
              <Paper
                style={{ backgroundColor: PgColors.redpg[200], height: 55 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white", fontSize: 20 }}
                  align="center"
                >
                  Neutraal
                </Typography>
              </Paper>
            </Grid>
            <Grid item md={1}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                fullWidth
                value={nVault1}
                onChange={(event) => setNVault1(event.target.value)}
              />
            </Grid>
            <Grid item md={1}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                fullWidth
                value={nVault2}
                onChange={(event) => setNVault2(event.target.value)}
              />
            </Grid>
            <Grid item md={2}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                fullWidth
                value={nBars}
                onChange={(event) => setNBars(event.target.value)}
              />
            </Grid>
            <Grid item md={2}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                fullWidth
                value={nBeam}
                onChange={(event) => setNBeam(event.target.value)}
              />
            </Grid>
            <Grid item md={2}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                fullWidth
                value={nFloor}
                onChange={(event) => setNFloor(event.target.value)}
              />
            </Grid>
          </Grid>
          <Grid container mt={2} display={{ xs: "none", md: "flex" }}>
            <Grid item md={2}>
              <Paper
                style={{ backgroundColor: PgColors.redpg[200], height: 55 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white", fontSize: 20 }}
                  align="center"
                >
                  Totaal
                </Typography>
              </Paper>
            </Grid>
            <Grid item md={1}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                fullWidth
                value={tVault1}
                onChange={(event) => setTVault1(event.target.value)}
              />
            </Grid>
            <Grid item md={1}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                fullWidth
                value={tVault2}
                onChange={(event) => setTVault2(event.target.value)}
              />
            </Grid>
            <Grid item md={2}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                fullWidth
                value={tBars}
                onChange={(event) => setTBars(event.target.value)}
              />
            </Grid>
            <Grid item md={2}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                fullWidth
                value={tBeam}
                onChange={(event) => setTBeam(event.target.value)}
              />
            </Grid>
            <Grid item md={2}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                fullWidth
                value={tFloor}
                onChange={(event) => setTFloor(event.target.value)}
              />
            </Grid>
            <Grid item md={2}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                fullWidth
                value={tTotal}
                onChange={(event) => setTTotal(event.target.value)}
              />
            </Grid>
          </Grid>
          <Grid container mt={2} display={{ xs: "none", md: "flex" }}>
            <Grid item md={2}>
              <Paper
                style={{ backgroundColor: PgColors.redpg[200], height: 55 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white", fontSize: 20 }}
                  align="center"
                >
                  Plaats
                </Typography>
              </Paper>
            </Grid>
            <Grid item md={2}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                fullWidth
                value={pVault}
                onChange={(event) => setPVault(event.target.value)}
              />
            </Grid>

            <Grid item md={2}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                fullWidth
                value={pBars}
                onChange={(event) => setPBars(event.target.value)}
              />
            </Grid>
            <Grid item md={2}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                fullWidth
                value={pBeam}
                onChange={(event) => setPBeam(event.target.value)}
              />
            </Grid>
            <Grid item md={2}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                fullWidth
                value={pFloor}
                onChange={(event) => setPFloor(event.target.value)}
              />
            </Grid>
            <Grid item md={2}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                fullWidth
                value={pTotal}
                onChange={(event) => setPTotal(event.target.value)}
              />
            </Grid>
          </Grid>
          <Grid container mt={2} display={{ xs: "none", md: "flex" }}>
            <Grid item md={2}>
              <Paper
                style={{ backgroundColor: PgColors.redpg[200], height: 55 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white", fontSize: 20 }}
                  align="center"
                >
                  Evaluatie
                </Typography>
              </Paper>
            </Grid>
            <Grid item md={9}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                fullWidth
                value={evaluation}
                onChange={(event) => setEvaluation(event.target.value)}
              />
            </Grid>
            <Grid item md={1} justifyContent="end" display="flex">
              <Button
                variant="contained"
                style={{
                  backgroundColor: PgColors.bluepg[200],
                }}
                size="large"
                type="submit"
                sx={{ height: 55 }}
              >
                <AddIcon />
              </Button>
            </Grid>
          </Grid>
          <Grid container display={{ xs: "flex", md: "none" }}>
            <Grid item xs={3}>
              <Paper
                style={{ backgroundColor: PgColors.redpg[200], height: 25 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                  align="center"
                >
                  D Vault 1
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={3}>
              <Paper
                style={{ backgroundColor: PgColors.redpg[200], height: 25 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                  align="center"
                >
                  E Vault 1
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={3}>
              <Paper
                style={{ backgroundColor: PgColors.redpg[200], height: 25 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                  align="center"
                >
                  T Vault 1
                </Typography>
              </Paper>
            </Grid>
          </Grid>
          <Grid container display={{ xs: "flex", md: "none" }}>
            <Grid item xs={3}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                fullWidth
                value={dVault1}
                onChange={(event) => setDVault1(event.target.value)}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                fullWidth
                value={eVault1}
                onChange={(event) => setEVault1(event.target.value)}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                fullWidth
                value={tVault1}
                onChange={(event) => setTVault1(event.target.value)}
              />
            </Grid>
          </Grid>
          <Grid container display={{ xs: "flex", md: "none" }}>
            <Grid item xs={3}>
              <Paper
                style={{ backgroundColor: PgColors.redpg[200], height: 25 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                  align="center"
                >
                  D Vault 2
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={3}>
              <Paper
                style={{ backgroundColor: PgColors.redpg[200], height: 25 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                  align="center"
                >
                  E Vault 2
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={3}>
              <Paper
                style={{ backgroundColor: PgColors.redpg[200], height: 25 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                  align="center"
                >
                  T Vault 2
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={3}>
              <Paper
                style={{ backgroundColor: PgColors.redpg[200], height: 25 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                  align="center"
                >
                  P Vault
                </Typography>
              </Paper>
            </Grid>
          </Grid>
          <Grid container display={{ xs: "flex", md: "none" }}>
            <Grid item xs={3}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                fullWidth
                value={dVault2}
                onChange={(event) => setDVault2(event.target.value)}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                fullWidth
                value={eVault2}
                onChange={(event) => setEVault2(event.target.value)}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                fullWidth
                value={tVault2}
                onChange={(event) => setTVault2(event.target.value)}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                fullWidth
                value={pVault}
                onChange={(event) => setPVault(event.target.value)}
              />
            </Grid>
          </Grid>
          <Grid container display={{ xs: "flex", md: "none" }}>
            <Grid item xs={3}>
              <Paper
                style={{ backgroundColor: PgColors.redpg[200], height: 25 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                  align="center"
                >
                  D Bars
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={3}>
              <Paper
                style={{ backgroundColor: PgColors.redpg[200], height: 25 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                  align="center"
                >
                  E Bars
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={3}>
              <Paper
                style={{ backgroundColor: PgColors.redpg[200], height: 25 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                  align="center"
                >
                  T Bars
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={3}>
              <Paper
                style={{ backgroundColor: PgColors.redpg[200], height: 25 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                  align="center"
                >
                  P Bars
                </Typography>
              </Paper>
            </Grid>
          </Grid>
          <Grid container display={{ xs: "flex", md: "none" }}>
            <Grid item xs={3}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                fullWidth
                value={dBars}
                onChange={(event) => setDBars(event.target.value)}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                fullWidth
                value={eBars}
                onChange={(event) => setEBars(event.target.value)}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                fullWidth
                value={tBars}
                onChange={(event) => setTBars(event.target.value)}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                fullWidth
                value={pBars}
                onChange={(event) => setPBars(event.target.value)}
              />
            </Grid>
          </Grid>
          <Grid container display={{ xs: "flex", md: "none" }}>
            <Grid item xs={3}>
              <Paper
                style={{ backgroundColor: PgColors.redpg[200], height: 25 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                  align="center"
                >
                  D Beam
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={3}>
              <Paper
                style={{ backgroundColor: PgColors.redpg[200], height: 25 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                  align="center"
                >
                  E Beam
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={3}>
              <Paper
                style={{ backgroundColor: PgColors.redpg[200], height: 25 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                  align="center"
                >
                  T Beam
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={3}>
              <Paper
                style={{ backgroundColor: PgColors.redpg[200], height: 25 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                  align="center"
                >
                  P Beam
                </Typography>
              </Paper>
            </Grid>
          </Grid>
          <Grid container display={{ xs: "flex", md: "none" }}>
            <Grid item xs={3}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                fullWidth
                value={dBeam}
                onChange={(event) => setDBeam(event.target.value)}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                fullWidth
                value={eBeam}
                onChange={(event) => setEBeam(event.target.value)}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                fullWidth
                value={tBeam}
                onChange={(event) => setTBeam(event.target.value)}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                fullWidth
                value={pBeam}
                onChange={(event) => setPBeam(event.target.value)}
              />
            </Grid>
          </Grid>
          <Grid container display={{ xs: "flex", md: "none" }}>
            <Grid item xs={3}>
              <Paper
                style={{ backgroundColor: PgColors.redpg[200], height: 25 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                  align="center"
                >
                  D Floor
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={3}>
              <Paper
                style={{ backgroundColor: PgColors.redpg[200], height: 25 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                  align="center"
                >
                  E Floor
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={3}>
              <Paper
                style={{ backgroundColor: PgColors.redpg[200], height: 25 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                  align="center"
                >
                  T Floor
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={3}>
              <Paper
                style={{ backgroundColor: PgColors.redpg[200], height: 25 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                  align="center"
                >
                  P Floor
                </Typography>
              </Paper>
            </Grid>
          </Grid>
          <Grid container display={{ xs: "flex", md: "none" }}>
            <Grid item xs={3}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                fullWidth
                value={dFloor}
                onChange={(event) => setDFloor(event.target.value)}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                fullWidth
                value={eFloor}
                onChange={(event) => setEFloor(event.target.value)}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                fullWidth
                value={tFloor}
                onChange={(event) => setTFloor(event.target.value)}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                fullWidth
                value={pFloor}
                onChange={(event) => setPFloor(event.target.value)}
              />
            </Grid>
          </Grid>
          <Grid container display={{ xs: "flex", md: "none" }}>
            <Grid item xs={12}>
              <Paper
                style={{ backgroundColor: PgColors.redpg[200], height: 25 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                  align="center"
                >
                  Evaluation
                </Typography>
              </Paper>
            </Grid>
          </Grid>
          <Grid container display={{ xs: "flex", md: "none" }}>
            <Grid item xs={12}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                fullWidth
                value={evaluation}
                onChange={(event) => setEvaluation(event.target.value)}
              />
            </Grid>
          </Grid>
          <Grid container display={{ xs: "flex", md: "none" }}>
            <Grid item xs={3}>
              <Paper
                style={{ backgroundColor: PgColors.redpg[200], height: 25 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                  align="center"
                >
                  Total AA
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={3}>
              <Paper
                style={{ backgroundColor: PgColors.redpg[200], height: 25 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                  align="center"
                >
                  Place AA
                </Typography>
              </Paper>
            </Grid>
          </Grid>

          <Grid container display={{ xs: "flex", md: "none" }}>
            <Grid item xs={3}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                fullWidth
                value={tTotal}
                onChange={(event) => setTTotal(event.target.value)}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                fullWidth
                value={pTotal}
                onChange={(event) => setPTotal(event.target.value)}
              />
            </Grid>
            <Grid item xs={3}></Grid>
            <Grid item xs={3} justifyContent="end" display="flex">
              <Button
                variant="contained"
                style={{
                  backgroundColor: PgColors.bluepg[200],
                }}
                size="large"
                type="submit"
                sx={{ height: 55 }}
              >
                <AddIcon />
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </>
  );
};
