import React, { useState, useEffect } from "react";
import { collection, query, onSnapshot, orderBy } from "firebase/firestore";
import {
  Grid,
  Container,
  Typography,
  Paper,
  Button,
  Box,
  CardMedia,
} from "@mui/material";
import { useAuthContext } from "../../../../hooks/useAuthContext";
import { useParams } from "react-router-dom";
import { db } from "../../../../firebase/config";
import { PgColors } from "../../../../configs/colors";
import { AddExercise7A, AddExercise7B } from "./AddExercise7";
import { ListExercise7A, ListExercise7B } from "./ListExercise7";
import { AddExercise8A } from "./AddExercise8";
import { ListExercise8A } from "./ListExercise8";

export const FocusPage = () => {
  const { authIsReady, user } = useAuthContext();
  const [exercise8AList, setExercise8AList] = useState([]);

  const { id } = useParams();

  useEffect(() => {
    const ref = query(
      collection(db, `IntroExercises/${user.uid}/UserExercise8A`),
      orderBy("createdAt", "desc")
    );

    const unsub = onSnapshot(ref, (snapshot) => {
      let results: any = [];
      snapshot.docs.forEach((doc) => {
        results.push({ ...doc.data(), id: doc.id });
      });
      setExercise8AList(results);
    });
    return () => unsub();
  }, []);

  return (
    <>
      <Grid container mt={2}>
        <Grid item xs={12}>
          <Paper
            style={{ backgroundColor: PgColors.redpg[400] }}
            sx={{ height: { xs: 45, md: 75 } }}
          >
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, sm: 14, md: 16, lg: 20 } }}
              align="center"
            >
              "Nothing will work unless you do"
            </Typography>

            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, sm: 14, md: 16, lg: 20 } }}
              align="center"
            >
              - John Wooden
            </Typography>
          </Paper>
        </Grid>
      </Grid>
      <Grid container mt={2}>
        <Grid item xs={12} mt={1}>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
            }}
            align="left"
          >
            Focus is de vaardigheid om je volledige aandacht te geven aan je
            taak, waarbij je afleidingen kunt negeren.
          </Typography>
        </Grid>
        <Grid item xs={12} mt={1}>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
            }}
            align="left"
          >
            Om je op iets te focussen, kost energie. Wanneer je tijd besteed in
            de training om te denken aan de fouten van de vorige training, gaat
            je energie daar naar toe.
          </Typography>
        </Grid>
        <Grid item xs={12} mt={0}>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
            }}
            align="left"
          >
            Dit is zonde want je focus is direct gelinkt aan je spier controle
            en je prestatie.
          </Typography>
        </Grid>
        <Grid item xs={12} mt={1}>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
            }}
            align="left"
          >
            Onproductieve focus - zoals je zorgen maken over de uitkomst of wat
            anderen van je denken - sleuprt enorme energie, wat er juist voor
            zorgt dat je slechter presteert. Je presteert dan minder, niet
            vanwege je vaardigheden, maar simpel omdat je lichaam moe is van het
            zorgen maken.
          </Typography>
        </Grid>
        <Grid item xs={12} mt={4}>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
            }}
            align="left"
          >
            Om te zorgen dat je kunt focussen terwijl je onder druk staat,
            bijvoorbeeld tijdens een wedstrijd, kan het helpen om bewust te zijn
            van waar je focus is.
          </Typography>
        </Grid>
        <Grid item xs={12} mt={1}>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
            }}
            align="left"
          >
            De aandachtscirkel laat de verschillende zones van focus zien. HOe
            verder naar buiten je focus zit, hoe groter de kans is dat je
            prestatie minder wordt.
          </Typography>
        </Grid>
        <Grid item xs={12} mt={2} mb={2} display="flex" justifyContent="center">
          <Box
            component="img"
            alt="AttentionCircle"
            src="/images/AttentionCircle.png"
            sx={{ maxHeight: { xs: 200, sm: 300, md: 500, lg: 525, xl: 600 } }}
          />
        </Grid>
        <Grid item xs={12} mt={2}>
          <Typography
            variant="subtitle1"
            style={{ color: PgColors.bluepg[400] }}
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
              fontWeight: { md: 500 },
            }}
            align="left"
          >
            Eerste cirkel (Ik en mijn taak)
          </Typography>
        </Grid>
        <Grid item xs={12} mt={0}>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
            }}
            align="left"
          >
            In deze cirkel is je focus volledig op je taak. Je bent aan niets
            anders aan het denken behalven je oefeningen en wat je moet doen.
          </Typography>
        </Grid>
        <Grid item xs={12} mt={2}>
          <Typography
            variant="subtitle1"
            style={{ color: PgColors.bluepg[400] }}
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
              fontWeight: { md: 500 },
            }}
            align="left"
          >
            Tweede cirkel (Directe afleidingen)
          </Typography>
        </Grid>
        <Grid item xs={12} mt={0}>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
            }}
            align="left"
          >
            In deze cirkel is je focus op directe afleidingen zoals het publiek,
            de juryleden, of de wedstrijd zelf. Je kunt dit in je voordeel
            gebruiken, zoals bijvoorbeeld de aanmoedigingen van het publiek of
            je mede-turn(st)ers. Maar, als je aandacht hier te lang bij is, kan
            je je niet meer focussen op je taak.
          </Typography>
        </Grid>
        <Grid item xs={12} mt={0}>
          <Typography
            variant="body1"
            style={{ color: PgColors.bluepg[200] }}
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
            }}
            align="left"
          >
            Als je focus te vaak in deze cirkel is kan je nog maar 90% van je
            kunnen bereiken.
          </Typography>
        </Grid>
        <Grid item xs={12} mt={2}>
          <Typography
            variant="subtitle1"
            style={{ color: PgColors.bluepg[400] }}
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
              fontWeight: { md: 500 },
            }}
            align="left"
          >
            Derde cirkel (Hoe het zou moeten gaan)
          </Typography>
        </Grid>
        <Grid item xs={12} mt={0}>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
            }}
            align="left"
          >
            In deze cirkel vergelijk je je huidig niveau met het niveau wat je
            vind dat je moet hebben. Door vaak deze vergelijking te maken, ben
            je telkens elke actie aan het beoordelen en of deze actie wel goed
            genoeg is.
          </Typography>
        </Grid>
        <Grid item xs={12} mt={0}>
          <Typography
            variant="body1"
            style={{ color: PgColors.bluepg[200] }}
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
            }}
            align="left"
          >
            Als je focus te vaak in deze cirkel is kan je nog maar 70% van je
            kunnen bereiken.
          </Typography>
        </Grid>
        <Grid item xs={12} mt={2}>
          <Typography
            variant="subtitle1"
            style={{ color: PgColors.bluepg[400] }}
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
              fontWeight: { md: 500 },
            }}
            align="left"
          >
            Vierde cirkel (Winnen / Verliezen)
          </Typography>
        </Grid>
        <Grid item xs={12} mt={0}>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
            }}
            align="left"
          >
            In deze cirkel is je focus op je prestatie. Ben je aan het winnen of
            juist aan het verliezen? In de vorige cirkels lag de focus nog op je
            eigen acties, in deze cirkel is je focus op de scores.
          </Typography>
        </Grid>
        <Grid item xs={12} mt={0}>
          <Typography
            variant="body1"
            style={{ color: PgColors.bluepg[200] }}
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
            }}
            align="left"
          >
            Als je focus in deze cirkel ligt kan je nog maar 40% van je kunnen
            bereiken.
          </Typography>
        </Grid>
        <Grid item xs={12} mt={2}>
          <Typography
            variant="subtitle1"
            style={{ color: PgColors.bluepg[400] }}
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
              fontWeight: { md: 500 },
            }}
            align="left"
          >
            Vijfde cirkel (Het gevolg van winnen of verliezen)
          </Typography>
        </Grid>
        <Grid item xs={12} mt={0}>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
            }}
            align="left"
          >
            In deze cirkel is je focus op het gevolg van je prestatie. 'Als ik
            een fout maak, kan ik niet doorstromen naar de volgende wedstrijd.'
            Of 'Als ik win, dan zijn mijn ouders trots op me.'
          </Typography>
        </Grid>
        <Grid item xs={12} mt={0}>
          <Typography
            variant="body1"
            style={{ color: PgColors.bluepg[200] }}
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
            }}
            align="left"
          >
            Als je focus in deze cirkel ligt kan je nog maar 30% van je kunnen
            bereiken.
          </Typography>
        </Grid>
        <Grid item xs={12} mt={2}>
          <Typography
            variant="subtitle1"
            style={{ color: PgColors.bluepg[400] }}
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
              fontWeight: { md: 500 },
            }}
            align="left"
          >
            Zesde cirkel (Wat doe ik hier?)
          </Typography>
        </Grid>
        <Grid item xs={12} mt={0}>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
            }}
            align="left"
          >
            In deze cirkel ben je gefocusd op de vraag: 'Wat doe ik hier?'. Je
            denkt dat je niks kunt, dat je niet op dit niveau wedstrijden moet
            doen, etc.
          </Typography>
        </Grid>
        <Grid item xs={12} mt={0}>
          <Typography
            variant="body1"
            style={{ color: PgColors.bluepg[200] }}
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
            }}
            align="left"
          >
            Als je focus in deze cirkel ligt kan je nog maar 10% van je kunnen
            bereiken.
          </Typography>
        </Grid>

        <Grid item xs={12} mt={2}>
          <Typography
            variant="subtitle1"
            style={{ color: PgColors.bluepg[400] }}
            sx={{
              fontSize: { xs: 16, md: 20, lg: 24 },
              fontWeight: { md: 500 },
            }}
            align="left"
          >
            Opdracht 8
          </Typography>
        </Grid>
      </Grid>

      <Grid container mt={2}>
        <Grid item xs={12} mt={1}>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
            }}
            align="left"
          >
            Denk terug aan je laatste wedstrijd. Verdeel de wedstrijd in
            stukjes, bijv. warming-up, inturnen, wachten bij de turn(st)ers,
            wachten op de jury bij het toestel, je oefening, etc. Schrijf voor
            elk deel op in welke cirkel je focus was.
          </Typography>
        </Grid>
      </Grid>
      <Grid container mt={2}>
        <AddExercise8A />
      </Grid>
      <Grid container mt={2} mb={2}>
        {exercise8AList && (
          <ListExercise8A
            exercises={exercise8AList}
            path={`IntroExercises/${id}/UserExercise8A/${id}`}
          />
        )}
      </Grid>
    </>
  );
};
