import React, { useState, useEffect } from "react";
import {
  collection,
  query,
  onSnapshot,
  updateDoc,
  doc,
  deleteDoc,
} from "firebase/firestore";
import {
  Grid,
  Container,
  Typography,
  Divider,
  Button,
  Paper,
} from "@mui/material";
import { Sup1Intro } from "./Sup1Intro";
import { Sup1Vault } from "./Sup1Vault";
import { Sup1Bars } from "./Sup1Bars";
import { Sup1Beam } from "./Sup1Beam";
import { Sup1Floor } from "./Sup1Floor";
import { PgColors } from "../../../configs/colors";

export const Sup1 = () => {
  return (
    <>
      <Container>
        <Sup1Intro />
        <Sup1Vault />
        <Sup1Bars />
        <Sup1Beam />
        <Sup1Floor />
      </Container>
    </>
  );
};
