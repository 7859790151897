import React, { useState, useEffect, FC } from "react";
import {
  Grid,
  Container,
  Typography,
  Card,
  CardMedia,
  CardContent,
  Box,
} from "@mui/material";
import { PgColors } from "../../../configs/colors";

type TextProps = {
  text: string;
};

export const Title: FC<TextProps> = ({ text }) => {
  return (
    <Typography
      variant="subtitle1"
      style={{ color: PgColors.bluepg[300], fontWeight: 500 }}
      align="left"
    >
      {text}
    </Typography>
  );
};

export const Groep4 = () => {
  return (
    <>
      <Grid container mt={2}>
        <Grid item>
          <Title
            text={
              "GROEP 4 – YURCHENKO MET/ZONDER 3/4 DRAAI - SALTO AO MET/ZONDER LA-DRAAI"
            }
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid
          item
          mt={0}
          xs={12}
          md={12}
          display="flex"
          justifyContent="center"
        >
          <Box sx={{ display: "flex" }}>
            <CardMedia
              sx={{
                maxWidth: { xs: 340, sm: 600, md: 900, lg: 1200 },
              }}
              component="img"
              image="../images/vaultEl/VaultGroup4.png"
              alt="symbol2"
            ></CardMedia>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
