import React, { useEffect, useState } from "react";
import {
  collection,
  query,
  onSnapshot,
  where,
  orderBy,
  addDoc,
  serverTimestamp,
  doc,
  setDoc,
  onSnapshotsInSync,
} from "firebase/firestore";
import {
  Grid,
  Container,
  Typography,
  Paper,
  Box,
  TextField,
  Button,
} from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridEventListener,
  MuiEvent,
} from "@mui/x-data-grid";
import { NavLink, Link } from "react-router-dom";
import { useAuthContext } from "../../hooks/useAuthContext";
import { db } from "../../firebase/config";
import { useNavigate } from "react-router-dom";
import { NewGymnastType } from "../../types/gymnast";
import { PgColors } from "../../configs/colors";

export const Gymnasts = () => {
  const { authIsReady, user } = useAuthContext();
  const [seasonsList, setSeasonsList] = useState([]);
  const [gymnastsList, setGymnastsList] = useState([]);
  const navigate = useNavigate();

  interface IProps {
    gymnast: NewGymnastType;
  }

  // useEffect(() => {
  //   const ref = query(collection(db, "GymnastsByCoach", user.uid, "Gymnasts"));

  //   const unsub = onSnapshot(ref, (snapshot) => {
  //     let results: any = [];
  //     snapshot.docs.forEach((doc) => {
  //       results.push({ ...doc.data(), id: doc.id });
  //     });
  //     setGymnastsList(results);
  //   });
  //   return () => unsub();
  // }, []);

  useEffect(() => {
    if (authIsReady && user) {
      const ref = query(
        collection(db, "GymnastsByCoach", user.uid, "Gymnasts")
      );

      const unsub = onSnapshot(ref, (snapshot) => {
        let results: any = [];
        snapshot.docs.forEach((doc) => {
          results.push({ ...doc.data(), id: doc.id });
        });
        setGymnastsList(results);
      });

      return () => unsub();
    }
  }, [authIsReady, user]);

  const columns: GridColDef[] = [
    { field: "firstname", headerName: "Voornaam", width: 110 },
    { field: "lastname", headerName: "Achternaam", width: 110 },
    { field: "dateOfBirth", headerName: "Geboortejaar", width: 130 },
    { field: "ageCategory", headerName: "Leeftijd Categorie", width: 130 },
    { field: "level", headerName: "Niveau", width: 70 },

    { field: "gymnastId", headerName: "Gymnast ID", width: 150 },
  ];

  const handleEvent: GridEventListener<"rowClick"> = (
    params: any,
    event: MuiEvent<React.MouseEvent<HTMLElement>>
  ) => {
    navigate(`${params.row.gymnastId}`);
  };

  if (!authIsReady) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <Container disableGutters={true}>
        <Grid container mt={10}>
          <Grid item xs={12} sx={{ marginLeft: { xs: 12, md: 16 } }}>
            <Button
              style={{ backgroundColor: PgColors.bluepg[300] }}
              variant="contained"
              component={Link}
              to="/dashboard/gymnasts/new"
            >
              <Typography style={{ fontWeight: "semibold" }}>
                ADD GYMNAST
              </Typography>
            </Button>
          </Grid>
        </Grid>
        <Box
          sx={{
            mt: { xs: 2, lg: 6 },
            marginLeft: { lg: 36, xl: 16 },
            width: "100%",
          }}
          justifyContent="center"
        >
          <Paper
            elevation={2}
            sx={{
              width: { xs: 350, sm: 580, md: 880, lg: 880, xl: 1200 },
            }}
            style={{ overflow: "auto" }}
          >
            <Container>
              <Grid container>
                <Grid item marginTop={6}>
                  <Typography
                    variant="h6"
                    style={{ color: "#a78782", fontWeight: "bold" }}
                  >
                    Gymnasts:
                  </Typography>
                </Grid>
              </Grid>
              <div style={{ height: 900, width: "100%" }}>
                <DataGrid
                  rows={gymnastsList}
                  columns={columns}
                  pageSize={25}
                  rowsPerPageOptions={[25]}
                  onRowClick={handleEvent}
                />
              </div>
            </Container>
          </Paper>
        </Box>
      </Container>
    </>
  );
};
