import React, { useState, useEffect } from "react";
import { Grid, Container } from "@mui/material";
import { Sup6Intro } from "./Sup6Intro";
import { Sup6Vault } from "./Sup6Vault";
import { Sup6Bars } from "./Sup6Bars";
import { Sup6Beam } from "./Sup6Beam";
import { Sup6Floor } from "./Sup6Floor";

export const Sup6 = () => {
  return (
    <>
      <Container>
        <Sup6Intro />
        <Sup6Vault />
        <Sup6Bars />
        <Sup6Beam />
        <Sup6Floor />
      </Container>
    </>
  );
};
