import React, { useState, useEffect } from "react";
import {
  collection,
  query,
  onSnapshot,
  addDoc,
  serverTimestamp,
  updateDoc,
  doc,
  deleteDoc,
} from "firebase/firestore";
import { Grid, Typography, Container, TextField, Button } from "@mui/material";
import {
  ref,
  getDownloadURL,
  uploadString,
  uploadBytes,
  uploadBytesResumable,
} from "firebase/storage";
import { db, storage } from "../../firebase/config";
import { useAuthContext } from "../../hooks/useAuthContext";
import { PgColors } from "../../configs/colors";

export const AddLesson = () => {
  const [progress, setProgress] = useState(0);
  const [fileUrl, setFileUrl] = useState(null);
  const [name, setName] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [wuContent, setWuContent] = useState("");
  const [conditioning, setConditioning] = useState("");
  const [vaultGoal, setVaultGoal] = useState("");
  const [vaultCond, setVaultCond] = useState("");
  const [vaultStations, setVaultStations] = useState("");
  const [barsGoal, setBarsGoal] = useState("");
  const [barsCond, setBarsCond] = useState("");
  const [barsStations, setBarsStations] = useState("");
  const [beamGoal, setBeamGoal] = useState("");
  const [beamCond, setBeamCond] = useState("");
  const [beamStations, setBeamStations] = useState("");
  const [floorGoal, setFloorGoal] = useState("");
  const [floorCond, setFloorCond] = useState("");
  const [floorStations, setFloorStations] = useState("");
  const [otherGoal, setOtherGoal] = useState("");
  const [otherCond, setOtherCond] = useState("");
  const [otherStations, setOtherStations] = useState("");
  const [groups, setGroups] = useState("");
  const [agenda, setAgenda] = useState("");

  const { user } = useAuthContext();
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const ref = collection(db, `LessonsByCoach/${user.uid}/Lessons`);

    await addDoc(ref, {
      name: name,
      date: date,
      time: time,
      wuContent: wuContent,
      conditioning: conditioning,
      vaultGoal: vaultGoal,
      vaultCond: vaultCond,
      vaultStations: vaultStations,
      barsGoal: barsGoal,
      barsCond: barsCond,
      barsStations: barsStations,
      beamGoal: beamGoal,
      beamCond: beamCond,
      beamStations: beamStations,
      floorGoal: floorGoal,
      floorCond: floorCond,
      floorStations: floorStations,
      otherGoal: otherGoal,
      otherCond: otherCond,
      otherStations: otherStations,
      groups: groups,
      agenda: agenda,
      uid: user.uid,
      createdAt: serverTimestamp(),
    });
    setName("");
    setDate("");
    setTime("");
    setWuContent("");
    setConditioning("");
    setVaultGoal("");
    setVaultCond("");
    setVaultStations("");
    setBarsGoal("");
    setBarsCond("");
    setBarsStations("");
    setBeamGoal("");
    setBeamCond("");
    setBeamStations("");
    setFloorGoal("");
    setFloorCond("");
    setFloorStations("");
    setOtherGoal("");
    setOtherCond("");
    setOtherStations("");
    setGroups("");
    setAgenda("");
  };

  return (
    <>
      <Container>
        <form onSubmit={handleSubmit}>
          <Grid container>
            <Grid item marginTop={6}>
              <Typography
                variant="h5"
                style={{ color: PgColors.redpg[200], fontWeight: "bold" }}
              >
                Lesson Plan
              </Typography>
            </Grid>
          </Grid>
          <Grid container marginTop={2}>
            <Grid item xs={12} md={4} marginTop={1}>
              <TextField
                label="Lesson Plan Name"
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                fullWidth
                value={name}
                onChange={(event) => setName(event.target.value)}
              />
            </Grid>

            <Grid item xs={6} md={4} marginTop={1}>
              <TextField
                label="Date"
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                fullWidth
                value={date}
                onChange={(event) => setDate(event.target.value)}
              />
            </Grid>
            <Grid item xs={6} md={4} marginTop={1}>
              <TextField
                label="Time"
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                fullWidth
                value={time}
                onChange={(event) => setTime(event.target.value)}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item marginTop={4} md={6}>
              <Typography
                variant="h6"
                style={{ color: PgColors.redpg[200], fontWeight: "bold" }}
              >
                Warming-up
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6} marginTop={1}>
              <TextField
                label="Content: e.g. run 5 laps"
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                multiline
                minRows={5}
                fullWidth
                value={wuContent}
                onChange={(event) => setWuContent(event.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6} marginTop={1}>
              <TextField
                label="Conditioning / Methodology / technique"
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                multiline
                minRows={5}
                fullWidth
                value={conditioning}
                onChange={(event) => setConditioning(event.target.value)}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item marginTop={4} md={6}>
              <Typography
                variant="h6"
                style={{ color: PgColors.redpg[200], fontWeight: "bold" }}
              >
                Set Up
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6} marginTop={1}>
              <TextField
                label="Groups"
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                multiline
                minRows={5}
                fullWidth
                value={groups}
                onChange={(event) => setGroups(event.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6} marginTop={1}>
              <TextField
                label="Time agenda"
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                multiline
                minRows={5}
                fullWidth
                value={agenda}
                onChange={(event) => setAgenda(event.target.value)}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item marginTop={4} md={6}>
              <Typography
                variant="h6"
                style={{ color: PgColors.redpg[200], fontWeight: "bold" }}
              >
                Vault
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12} marginTop={1}>
              <TextField
                label="Objective / Goal / Skill"
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                fullWidth
                value={vaultGoal}
                onChange={(event) => setVaultGoal(event.target.value)}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6} marginTop={1}>
              <TextField
                label="Content / Conditioning / Program"
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                multiline
                minRows={5}
                fullWidth
                value={vaultCond}
                onChange={(event) => setVaultCond(event.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6} marginTop={1}>
              <TextField
                label="Stations"
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                multiline
                minRows={5}
                fullWidth
                value={vaultStations}
                onChange={(event) => setVaultStations(event.target.value)}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item marginTop={4} md={6}>
              <Typography
                variant="h6"
                style={{ color: PgColors.redpg[200], fontWeight: "bold" }}
              >
                Bars
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12} marginTop={1}>
              <TextField
                label="Objective / Goal / Skill"
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                fullWidth
                value={barsGoal}
                onChange={(event) => setBarsGoal(event.target.value)}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6} marginTop={1}>
              <TextField
                label="Content / Conditioning / Program"
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                multiline
                minRows={5}
                fullWidth
                value={barsCond}
                onChange={(event) => setBarsCond(event.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6} marginTop={1}>
              <TextField
                label="Stations"
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                multiline
                minRows={5}
                fullWidth
                value={barsStations}
                onChange={(event) => setBarsStations(event.target.value)}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item marginTop={4} md={6}>
              <Typography
                variant="h6"
                style={{ color: PgColors.redpg[200], fontWeight: "bold" }}
              >
                Beam
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12} marginTop={1}>
              <TextField
                label="Objective / Goal / Skill"
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                fullWidth
                value={beamGoal}
                onChange={(event) => setBeamGoal(event.target.value)}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6} marginTop={1}>
              <TextField
                label="Content / Conditioning / Program"
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                multiline
                minRows={5}
                fullWidth
                value={beamCond}
                onChange={(event) => setBeamCond(event.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6} marginTop={1}>
              <TextField
                label="Stations"
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                multiline
                minRows={5}
                fullWidth
                value={beamStations}
                onChange={(event) => setBeamStations(event.target.value)}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item marginTop={4} md={6}>
              <Typography
                variant="h6"
                style={{ color: PgColors.redpg[200], fontWeight: "bold" }}
              >
                Floor
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12} marginTop={1}>
              <TextField
                label="Objective / Goal / Skill"
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                fullWidth
                value={floorGoal}
                onChange={(event) => setFloorGoal(event.target.value)}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6} marginTop={1}>
              <TextField
                label="Content / Conditioning / Program"
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                multiline
                minRows={5}
                fullWidth
                value={floorCond}
                onChange={(event) => setFloorCond(event.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6} marginTop={1}>
              <TextField
                label="Stations"
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                multiline
                minRows={5}
                fullWidth
                value={floorStations}
                onChange={(event) => setFloorStations(event.target.value)}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item marginTop={4} md={6}>
              <Typography
                variant="h6"
                style={{ color: PgColors.redpg[200], fontWeight: "bold" }}
              >
                Other
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12} marginTop={1}>
              <TextField
                label="Objective / Goal / Skill"
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                fullWidth
                value={otherGoal}
                onChange={(event) => setOtherGoal(event.target.value)}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6} marginTop={1}>
              <TextField
                label="Content / Conditioning / Program"
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                multiline
                minRows={5}
                fullWidth
                value={otherCond}
                onChange={(event) => setOtherCond(event.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6} marginTop={1}>
              <TextField
                label="Stations"
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                multiline
                minRows={5}
                fullWidth
                value={otherStations}
                onChange={(event) => setOtherStations(event.target.value)}
              />
            </Grid>
          </Grid>

          <Grid container mt={1} justifyContent="end" display="flex" mb={2}>
            <Grid item md={4} xs={12} justifyContent="end" display="flex">
              <Button
                style={{
                  backgroundColor: PgColors.bluepg[200],
                }}
                variant="contained"
                type="submit"
                size="large"
                sx={{ height: 55 }}
              >
                <Typography variant="subtitle1">
                  Add Lesson Plan to Library
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </>
  );
};
