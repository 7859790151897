import React, { useState, useEffect } from "react";
import { collection, query, onSnapshot, orderBy } from "firebase/firestore";
import {
  Grid,
  Container,
  Typography,
  Paper,
  Button,
  ButtonGroup,
} from "@mui/material";
import { useAuthContext } from "../../../../hooks/useAuthContext";
import { useParams } from "react-router-dom";
import { db } from "../../../../firebase/config";
import { PgColors } from "../../../../configs/colors";
import { Division2 } from "./Division2";
import { Division3 } from "./Division3";
import { Division4 } from "./Division4";
import { Division5 } from "./Division5";
import { Division6 } from "./Division6";

export const ChecklistPage = () => {
  const { id } = useParams();
  const [dScoresList, setDScoresList] = useState([]);
  const [active, setActive] = useState("Division2");
  const activeColor = PgColors.bluepg[400];

  return (
    <>
      <Grid container mt={2} sx={{ display: { xs: "none", sm: "flex" } }}>
        <Grid item xs={12}>
          <ButtonGroup variant="contained">
            <Button
              style={{
                backgroundColor:
                  active === "Division2" ? activeColor : PgColors.bluepg[200],
              }}
              variant="contained"
              onClick={() => setActive("Division2")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 14 } }}
              >
                Divisie 2
              </Typography>
            </Button>
            <Button
              style={{
                backgroundColor:
                  active === "Division3" ? activeColor : PgColors.bluepg[200],
              }}
              variant="contained"
              onClick={() => setActive("Division3")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 14 } }}
              >
                Divisie 3
              </Typography>
            </Button>
            <Button
              style={{
                backgroundColor:
                  active === "Division4" ? activeColor : PgColors.bluepg[200],
              }}
              variant="contained"
              onClick={() => setActive("Division4")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 14 } }}
              >
                Divisie 4
              </Typography>
            </Button>
            <Button
              style={{
                backgroundColor:
                  active === "Division5" ? activeColor : PgColors.bluepg[200],
              }}
              variant="contained"
              onClick={() => setActive("Division5")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 14 } }}
              >
                Divisie 5
              </Typography>
            </Button>
            <Button
              style={{
                backgroundColor:
                  active === "Division6" ? activeColor : PgColors.bluepg[200],
              }}
              variant="contained"
              onClick={() => setActive("Division6")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 14 } }}
              >
                Divisie 6
              </Typography>
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid>
      <Grid container mt={2} sx={{ display: { xs: "flex", sm: "none" } }}>
        <Grid item xs={12}>
          <ButtonGroup variant="contained">
            <Button
              style={{
                backgroundColor:
                  active === "Division2" ? activeColor : PgColors.bluepg[200],
              }}
              variant="contained"
              onClick={() => setActive("Division2")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 14 } }}
              >
                Divisie 2
              </Typography>
            </Button>
            <Button
              style={{
                backgroundColor:
                  active === "Division3" ? activeColor : PgColors.bluepg[200],
              }}
              variant="contained"
              onClick={() => setActive("Division3")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 14 } }}
              >
                Divisie 3
              </Typography>
            </Button>
            <Button
              style={{
                backgroundColor:
                  active === "Division4" ? activeColor : PgColors.bluepg[200],
              }}
              variant="contained"
              onClick={() => setActive("Division4")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 14 } }}
              >
                Divisie 4
              </Typography>
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid>
      <Grid container mt={1} sx={{ display: { xs: "flex", sm: "none" } }}>
        <Grid item xs={12}>
          <ButtonGroup variant="contained">
            <Button
              style={{
                backgroundColor:
                  active === "Division5" ? activeColor : PgColors.bluepg[200],
              }}
              variant="contained"
              onClick={() => setActive("Division5")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 14 } }}
              >
                Divisie 5
              </Typography>
            </Button>
            <Button
              style={{
                backgroundColor:
                  active === "Division6" ? activeColor : PgColors.bluepg[200],
              }}
              variant="contained"
              onClick={() => setActive("Division6")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 14 } }}
              >
                Divisie 6
              </Typography>
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid>
      <Grid container>{active === "Division2" && <Division2 />}</Grid>
      <Grid container>{active === "Division3" && <Division3 />}</Grid>
      <Grid container>{active === "Division4" && <Division4 />}</Grid>
      <Grid container>{active === "Division5" && <Division5 />}</Grid>
      <Grid container>{active === "Division6" && <Division6 />}</Grid>
    </>
  );
};
