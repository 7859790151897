import React, { useState, useEffect, FC } from "react";
import {
  Grid,
  Container,
  Typography,
  Card,
  CardMedia,
  CardContent,
  Box,
} from "@mui/material";
import { PgColors } from "../../../configs/colors";

type TextProps = {
  text: string;
};




export const Title: FC<TextProps> = ({ text }) => {
  return (
    <Typography
      variant="subtitle1"
      style={{ color: PgColors.bluepg[300] }}
      sx={{
        fontWeight: { xs: 500, md: 500 },
        fontSize: { xs: 12, md: 20 },
      }}
      align="left"
    >
      {text}
    </Typography>
  );
};

export const GroepTA = () => {
  return (
    <>
      <Grid container mt={2}>
        <Grid item>
          <Title text={"TOEGEVOEGDE A-ELEMENTEN (TA)"} />
        </Grid>
      </Grid>
      <Grid container sx={{ marginBottom: { xs: 4 } }}>
        <Grid
          item
          mt={0}
          xs={12}
          md={12}
          display="flex"
          justifyContent="center"
        >
          <Box
            sx={{
              display: "flex",
              //   border: "4px solid #9f5b5a",
              //   borderRadius: 5,
            }}
          >
            <CardMedia
              sx={{
                maxWidth: { xs: 340, sm: 600, md: 900, lg: 1200 },
              }}
              component="img"
              image="../images/beamEl/BeamGroupTAB.png"
              alt="symbol2"
            ></CardMedia>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
