import React, { useState } from "react";
import {
  Grid,
  Container,
  Typography,
  Modal,
  Paper,
  TextField,
  Button,
  Box,
  Drawer,
} from "@mui/material";
import {
  collection,
  addDoc,
  serverTimestamp,
  query,
  onSnapshot,
  updateDoc,
  doc,
  deleteDoc,
} from "firebase/firestore";
import { useAuthContext } from "../../hooks/useAuthContext";
import { db } from "../../firebase/config";
import { PgColors } from "../../configs/colors";

export const ModalInfo = ({ gymnast }: any) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [writer, setWriter] = useState("");
  const { user } = useAuthContext();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const ref = collection(db, `InfoByCoach/${user.uid}/Infos`);

    await addDoc(ref, {
      title: title,
      content: content,
      writer: writer,
      uid: user.uid,
      createdAt: serverTimestamp(),
    });
    setTitle("");
    setContent("");
    setWriter("");
  };

  return (
    <>
      <Grid item>
        <Button
          color="warning"
          variant="contained"
          onClick={handleOpen}
          style={{
            backgroundColor: PgColors.bluepg[400],
          }}
        >
          <Typography style={{ fontWeight: "semibold" }}>ADD Post</Typography>
        </Button>
      </Grid>
      <Modal
        open={open}
        onClose={handleClose}
        style={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
        }}
      >
        <Box
          marginTop={8}
          sx={{
            width: { xs: 350, sm: 580, md: 880, xl: 1500 },
            height: { xs: 800, md: 600 },
            bgcolor: "background.paper",
            border: "2px solid #6B3239",
            boxShadow: 24,
            borderRadius: 5,
            p: 4,
          }}
        >
          <Typography
            variant="h6"
            style={{ color: "#6B3239", fontWeight: "bold" }}
          >
            Enter Info
          </Typography>
          <form onSubmit={handleSubmit}>
            <Grid container>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Writer"
                  color="warning"
                  variant="outlined"
                  fullWidth
                  value={writer}
                  onChange={(event) => setWriter(event.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Title"
                  color="warning"
                  variant="outlined"
                  fullWidth
                  value={title}
                  onChange={(event) => setTitle(event.target.value)}
                />
              </Grid>
              <Grid item xs={12} mt={2}>
                <TextField
                  label="Content"
                  color="warning"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={10}
                  value={content}
                  onChange={(event) => setContent(event.target.value)}
                />
              </Grid>
              <Grid
                item
                mt={1}
                md={4}
                xs={12}
                justifyContent="start"
                display="flex"
              >
                <Button
                  color="warning"
                  variant="contained"
                  type="submit"
                  size="large"
                  sx={{ height: 55 }}
                >
                  <Typography variant="subtitle1">Add </Typography>
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Modal>
    </>
  );
};
