import React, { useState, useEffect, FC } from "react";
import {
  Grid,
  Container,
  Typography,
  Divider,
  Box,
  Button,
  Paper,
} from "@mui/material";
import { PgColors } from "../../../configs/colors";

type TextProps = {
  text: string;
};

export const ContentPaper: FC<TextProps> = ({ text }) => {
  return (
    <Typography
      variant="subtitle1"
      sx={{
        color: "white",
        fontSize: { xs: 12, sm: 14, md: 16 },
      }}
      align="left"
    >
      {text}
    </Typography>
  );
};

export const TitlePaper: FC<TextProps> = ({ text }) => {
  return (
    <Typography
      variant="subtitle1"
      style={{ fontWeight: 500 }}
      sx={{
        color: "white",
        fontSize: { xs: 12, sm: 14, md: 16 },
      }}
      align="left"
    >
      {text}
    </Typography>
  );
};

export const Content: FC<TextProps> = ({ text }) => {
  return (
    <Typography
      variant="subtitle1"
      style={{ color: PgColors.redpg[500] }}
      align="left"
    >
      {text}
    </Typography>
  );
};

export const Houdingen1 = () => {
  return (
    <>
      <Grid container mt={2} marginLeft={2}>
        <Grid item>
          <Typography variant="h6" style={{ color: PgColors.redpg[500] }}>
            Balk houdingen serie 1: supplement 1 en 2
          </Typography>
        </Grid>
      </Grid>
      <Grid container marginLeft={2}>
        <Grid item>
          <Content
            text={
              "*Uitgangspositie: Basishouding: voeten 5e positie links voor; armen laag. Tijdens de serie zijn de armen zijwaarts (2e positie) met de schouders laag, tenzij anders beschreven."
            }
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item mt={0} xs={12}>
          <Paper
            elevation={2}
            style={{ backgroundColor: PgColors.bluepg[100], width: "100%" }}
          >
            <Container>
              <Grid container>
                <Grid
                  item
                  xs={2}
                  md={1}
                  sx={{
                    marginRight: { xs: 0, md: -4 },
                  }}
                >
                  <TitlePaper text={"Maat"} />
                </Grid>
                <Grid
                  item
                  xs={2}
                  md={1}
                  sx={{ marginRight: { xs: 0, md: -4 } }}
                >
                  <TitlePaper text={"Tel"} />
                </Grid>
                <Grid item xs={8} sx={{ marginRight: { xs: 0, md: 8 } }}>
                  <TitlePaper
                    text={
                      "De turnster mag voorkeursbeen kiezen; tweezijdige elementen dienen li en re uitgevoerd te worden."
                    }
                  />
                </Grid>
                <Grid
                  item
                  sx={{ marginRight: { xs: 0, md: 0 } }}
                  display={{ xs: "none", md: "flex" }}
                >
                  <TitlePaper text={"Symbolen"} />
                </Grid>
              </Grid>
              <Divider></Divider>
              <Grid container>
                <Grid item xs={2} md={1} sx={{ marginRight: { md: -4 } }}>
                  <ContentPaper text={"1"} />
                </Grid>
                <Grid
                  item
                  xs={2}
                  md={1}
                  sx={{ marginRight: { md: -4 } }}
                ></Grid>
                <Grid item xs={8} sx={{ marginRight: { md: 8 } }}>
                  <ContentPaper
                    text={
                      "Komen tot tendu voor, re is het standbeen, li is het tendubeen"
                    }
                  />
                </Grid>
                <Grid
                  item
                  md={2}
                  sx={{
                    marginRight: { xs: 0, md: 0 },
                    marginBottom: { md: -10 },
                  }}
                  display={{ xs: "none", md: "flex" }}
                >
                  <Box
                    component="img"
                    alt="symbol2"
                    src="../images/Symbol2.png"
                    sx={{ maxHeight: { xs: 35, md: 100 } }}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid
                  item
                  xs={2}
                  md={1}
                  sx={{ marginRight: { md: -4 } }}
                ></Grid>
                <Grid item xs={2} md={1} sx={{ marginRight: { md: -4 } }}>
                  <ContentPaper text={"1-2"} />
                </Grid>
                <Grid item xs={8} sx={{ marginRight: { md: 8 } }}>
                  <ContentPaper
                    text={"Stap vw op links, beenzwaar vw met rechts"}
                  />
                </Grid>
                <Grid
                  item
                  md={2}
                  sx={{ marginRight: { xs: 0, md: 0 } }}
                  display={{ xs: "none", md: "flex" }}
                ></Grid>
              </Grid>
              <Grid container>
                <Grid
                  item
                  xs={2}
                  md={1}
                  sx={{ marginRight: { md: -4 } }}
                ></Grid>
                <Grid item xs={2} md={1} sx={{ marginRight: { md: -4 } }}>
                  <ContentPaper text={"3-4"} />
                </Grid>
                <Grid item xs={8} sx={{ marginRight: { md: 8 } }}>
                  <ContentPaper
                    text={
                      "Recther been zwaait door naar achteren tot standspagaat op li; armen aan de balk, schouderhoek volledig geopend"
                    }
                  />
                </Grid>
                <Grid
                  item
                  md={2}
                  sx={{ marginRight: { md: 0 } }}
                  display={{ xs: "none", md: "flex" }}
                ></Grid>
              </Grid>
              <Grid container sx={{ marginBottom: { md: -7 } }}>
                <Grid
                  item
                  xs={2}
                  md={1}
                  sx={{ marginRight: { md: -4 } }}
                ></Grid>
                <Grid item xs={2} md={1} sx={{ marginRight: { md: -4 } }}>
                  <ContentPaper text={"5-6"} />
                </Grid>
                <Grid item xs={8} sx={{ marginRight: { md: 8 } }}>
                  <ContentPaper
                    text={
                      "Opkomen tot passé parallel met het re been, li been in plié; armen schuin hoog zw; handpalmen draaien omhoog"
                    }
                  />
                </Grid>
                <Grid
                  item
                  md={2}
                  sx={{ marginRight: { md: 0 } }}
                  display={{ xs: "none", md: "flex" }}
                >
                  <Box
                    component="img"
                    alt="symbol2"
                    src="../images/balkhoudingen/Symbol3.png"
                    sx={{ maxHeight: { xs: 35, md: 100 } }}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid
                  item
                  xs={2}
                  md={1}
                  sx={{ marginRight: { md: -4 } }}
                ></Grid>
                <Grid item xs={2} md={1} sx={{ marginRight: { md: -4 } }}>
                  <ContentPaper text={"7-8"} />
                </Grid>
                <Grid item xs={8} sx={{ marginRight: { md: 8 } }}>
                  <ContentPaper
                    text={
                      "Re been uitstrekken (developpé) li naar relevé, armen schuin hoog zw; handpalmen draaien omhoog; Moment van balans is zichtbaar in relevé"
                    }
                  />
                </Grid>
                <Grid
                  item
                  md={2}
                  sx={{ marginRight: { md: 0 } }}
                  display={{ xs: "none", md: "flex" }}
                ></Grid>
              </Grid>
              <Divider></Divider>
              <Grid container sx={{ marginBottom: { md: -7 } }}>
                <Grid item xs={2} md={1} sx={{ marginRight: { md: -4 } }}>
                  <ContentPaper text={"2"} />
                </Grid>
                <Grid item xs={2} md={1} sx={{ marginRight: { md: -4 } }}>
                  <ContentPaper text={"1-2"} />
                </Grid>
                <Grid item xs={8} sx={{ marginRight: { md: 8 } }}>
                  <ContentPaper
                    text={
                      "Stap vw (vu relevé) op rechts naar plié, li in passé parallel; armen schuin hoog zw; handpalmen draaien omlaag"
                    }
                  />
                </Grid>
                <Grid
                  item
                  md={2}
                  sx={{ marginRight: { md: 0 } }}
                  display={{ xs: "none", md: "flex" }}
                >
                  <Box
                    component="img"
                    alt="symbol2"
                    src="../images/balkhoudingen/Symbol3.png"
                    sx={{ maxHeight: { xs: 35, md: 100 } }}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid
                  item
                  xs={2}
                  md={1}
                  sx={{ marginRight: { md: -4 } }}
                ></Grid>
                <Grid item xs={2} md={1} sx={{ marginRight: { md: -4 } }}>
                  <ContentPaper text={"3-4"} />
                </Grid>
                <Grid item xs={8} sx={{ marginRight: { md: 8 } }}>
                  <ContentPaper
                    text={
                      "Li been uitstrekken (developpé) rechts naar relevé; armen schuin hoog zw; handpalemn draaien omlaag"
                    }
                  />
                </Grid>
                <Grid
                  item
                  md={2}
                  sx={{ marginRight: { md: 0 } }}
                  display={{ xs: "none", md: "flex" }}
                ></Grid>
              </Grid>
              <Grid container sx={{ marginBottom: { md: -5 } }}>
                <Grid
                  item
                  xs={2}
                  md={1}
                  sx={{ marginRight: { md: -4 } }}
                ></Grid>
                <Grid item xs={2} md={1} sx={{ marginRight: { md: -4 } }}>
                  <ContentPaper text={"5-6"} />
                </Grid>
                <Grid item xs={8} sx={{ marginRight: { md: 8 } }}>
                  <ContentPaper
                    text={"Moment van balans is zichtbaar in relevé. "}
                  />
                </Grid>
                <Grid
                  item
                  md={2}
                  sx={{ marginRight: { md: 0 } }}
                  display={{ xs: "none", md: "flex" }}
                >
                  <Box
                    component="img"
                    alt="symbol2"
                    src="../images/balkhoudingen/Symbol4.png"
                    sx={{ maxHeight: { xs: 35, md: 65 } }}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid
                  item
                  xs={2}
                  md={1}
                  sx={{ marginRight: { md: -4 } }}
                ></Grid>
                <Grid item xs={2} md={1} sx={{ marginRight: { md: -4 } }}>
                  <ContentPaper text={"7-8"} />
                </Grid>
                <Grid item xs={8} sx={{ marginRight: { md: 8 } }}>
                  <ContentPaper
                    text={
                      "Komen (vu relevé) tot zweefstand (90º) op li, 1sec vasthouden, armen vrij "
                    }
                  />
                </Grid>
                <Grid
                  item
                  md={2}
                  sx={{ marginRight: { md: 0 } }}
                  display={{ xs: "none", md: "flex" }}
                ></Grid>
              </Grid>
              <Divider></Divider>
              <Grid container>
                <Grid item xs={2} md={1} sx={{ marginRight: { md: -4 } }}>
                  <ContentPaper text={"3"} />
                </Grid>
                <Grid item xs={2} md={1} sx={{ marginRight: { md: -4 } }}>
                  <ContentPaper text={"1-4"} />
                </Grid>
                <Grid item xs={8} sx={{ marginRight: { md: 8 } }}>
                  <ContentPaper
                    text={
                      "Vu de zweefstand met de handen de balk vastpakken, het li been naar hurkhouding en het re been met een ¼ draai li gestrekt zw op de balk plaatsen tot parallel op de balk, vervolgens romp rechtop en armen zw brengen. "
                    }
                  />
                </Grid>
                <Grid
                  item
                  md={2}
                  sx={{ marginRight: { md: 0 } }}
                  display={{ xs: "none", md: "flex" }}
                ></Grid>
              </Grid>
              <Grid container>
                <Grid
                  item
                  xs={2}
                  md={1}
                  sx={{ marginRight: { md: -4 } }}
                ></Grid>
                <Grid item xs={2} md={1} sx={{ marginRight: { md: -4 } }}>
                  <ContentPaper text={"5-6"} />
                </Grid>
                <Grid item xs={8} sx={{ marginRight: { md: 8 } }}>
                  <ContentPaper
                    text={
                      "Het re been gestrekt naar voren (ronde de jambe) en met een ¼ draai tot hurk/hoekhouding dwars op de balk, waarbij de li voet uitgedraaid op de balk eindigt "
                    }
                  />
                </Grid>
                <Grid
                  item
                  md={2}
                  sx={{ marginRight: { md: 0 } }}
                  display={{ xs: "none", md: "flex" }}
                >
                  <Box
                    component="img"
                    alt="symbol2"
                    src="../images/balkhoudingen/Symbol5.png"
                    sx={{ maxHeight: { xs: 35, md: 75 } }}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid
                  item
                  xs={2}
                  md={1}
                  sx={{ marginRight: { md: -4 } }}
                ></Grid>
                <Grid item xs={2} md={1} sx={{ marginRight: { md: -4 } }}>
                  <ContentPaper text={"7"} />
                </Grid>
                <Grid item xs={8} sx={{ marginRight: { md: 8 } }}>
                  <ContentPaper
                    text={
                      "Re voet voor neerzetten tot hurkzit in 5e positie, armen naar laag"
                    }
                  />
                </Grid>
                <Grid
                  item
                  md={2}
                  sx={{ marginRight: { md: 0 } }}
                  display={{ xs: "none", md: "flex" }}
                ></Grid>
              </Grid>
              <Grid container>
                <Grid
                  item
                  xs={2}
                  md={1}
                  sx={{ marginRight: { md: -4 } }}
                ></Grid>
                <Grid item xs={2} md={1} sx={{ marginRight: { md: -4 } }}>
                  <ContentPaper text={"8"} />
                </Grid>
                <Grid item xs={8} sx={{ marginRight: { md: 8 } }}>
                  <ContentPaper text={"½ draai in hurkzit, armen laag"} />
                </Grid>
                <Grid
                  item
                  md={2}
                  sx={{ marginRight: { md: 0 } }}
                  display={{ xs: "none", md: "flex" }}
                ></Grid>
              </Grid>
              <Divider></Divider>
              <Grid container sx={{ marginBottom: { md: -4 } }}>
                <Grid item xs={2} md={1} sx={{ marginRight: { md: -4 } }}>
                  <ContentPaper text={"4"} />
                </Grid>
                <Grid item xs={2} md={1} sx={{ marginRight: { md: -4 } }}>
                  <ContentPaper text={"1-2"} />
                </Grid>
                <Grid item xs={8} sx={{ marginRight: { md: 8 } }}>
                  <ContentPaper
                    text={
                      "Komen tot stand op rechts in relevé, in passé parallel (li been); armen hoog; handpalmen naar binnen; king geheven"
                    }
                  />
                </Grid>
                <Grid
                  item
                  md={2}
                  sx={{ marginRight: { md: 0 } }}
                  display={{ xs: "none", md: "flex" }}
                >
                  <Box
                    component="img"
                    alt="symbol2"
                    src="../images/balkhoudingen/Symbol6.png"
                    sx={{ maxHeight: { xs: 35, md: 75 } }}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid
                  item
                  xs={2}
                  md={1}
                  sx={{ marginRight: { md: -4 } }}
                ></Grid>
                <Grid item xs={2} md={1} sx={{ marginRight: { md: -4 } }}>
                  <ContentPaper text={"3"} />
                </Grid>
                <Grid item xs={8} sx={{ marginRight: { md: 8 } }}>
                  <ContentPaper text={"Hou vast"} />
                </Grid>
                <Grid
                  item
                  md={2}
                  sx={{ marginRight: { md: 0 } }}
                  display={{ xs: "none", md: "flex" }}
                ></Grid>
              </Grid>
              <Grid container>
                <Grid
                  item
                  xs={2}
                  md={1}
                  sx={{ marginRight: { md: -4 } }}
                ></Grid>
                <Grid item xs={2} md={1} sx={{ marginRight: { md: -4 } }}>
                  <ContentPaper text={"4"} />
                </Grid>
                <Grid item xs={8} sx={{ marginRight: { md: 8 } }}>
                  <ContentPaper text={"Stap vw op li, armen zw"} />
                </Grid>
                <Grid
                  item
                  md={2}
                  sx={{ marginRight: { md: 0 } }}
                  display={{ xs: "none", md: "flex" }}
                ></Grid>
              </Grid>
              <Grid container sx={{ marginBottom: { md: -4 } }}>
                <Grid
                  item
                  xs={2}
                  md={1}
                  sx={{ marginRight: { md: -4 } }}
                ></Grid>
                <Grid item xs={2} md={1} sx={{ marginRight: { md: -4 } }}>
                  <ContentPaper text={"5-6"} />
                </Grid>
                <Grid item xs={8} sx={{ marginRight: { md: 8 } }}>
                  <ContentPaper
                    text={
                      "Stand op li in relevé, passé parallel (re been); armen hoog; handpalmen naar binnen; kin geheven"
                    }
                  />
                </Grid>
                <Grid
                  item
                  md={2}
                  sx={{ marginRight: { md: 0 } }}
                  display={{ xs: "none", md: "flex" }}
                >
                  <Box
                    component="img"
                    alt="symbol2"
                    src="../images/balkhoudingen/Symbol6.png"
                    sx={{ maxHeight: { xs: 35, md: 75 } }}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid
                  item
                  xs={2}
                  md={1}
                  sx={{ marginRight: { md: -4 } }}
                ></Grid>
                <Grid item xs={2} md={1} sx={{ marginRight: { md: -4 } }}>
                  <ContentPaper text={"7"} />
                </Grid>
                <Grid item xs={8} sx={{ marginRight: { md: 8 } }}>
                  <ContentPaper text={"Hou vast"} />
                </Grid>
                <Grid
                  item
                  md={2}
                  sx={{ marginRight: { md: 0 } }}
                  display={{ xs: "none", md: "flex" }}
                ></Grid>
              </Grid>
              <Grid container>
                <Grid
                  item
                  xs={2}
                  md={1}
                  sx={{ marginRight: { md: -4 } }}
                ></Grid>
                <Grid item xs={2} md={1} sx={{ marginRight: { md: -4 } }}>
                  <ContentPaper text={"8"} />
                </Grid>
                <Grid item xs={8} sx={{ marginRight: { md: 8 } }}>
                  <ContentPaper text={"Stap vw op rechts, armen zw"} />
                </Grid>
                <Grid
                  item
                  md={2}
                  sx={{ marginRight: { md: 0 } }}
                  display={{ xs: "none", md: "flex" }}
                ></Grid>
              </Grid>
              <Divider></Divider>
              <Grid container sx={{ marginBottom: { md: -2 } }}>
                <Grid item xs={2} md={1} sx={{ marginRight: { md: -4 } }}>
                  <ContentPaper text={"5"} />
                </Grid>
                <Grid item xs={2} md={1} sx={{ marginRight: { md: -4 } }}>
                  <ContentPaper text={"1-2"} />
                </Grid>
                <Grid item xs={8} sx={{ marginRight: { md: 8 } }}>
                  <ContentPaper
                    text={
                      "Fouetédraai vw op re met arminzet via laag naar hoog naar arabesque op gestrekt standbeen; armen hoog stap aw op li, armen zw"
                    }
                  />
                </Grid>
                <Grid
                  item
                  md={2}
                  sx={{ marginRight: { md: 0 } }}
                  display={{ xs: "none", md: "flex" }}
                >
                  <Box
                    component="img"
                    alt="symbol2"
                    src="../images/balkhoudingen/Symbol7.png"
                    sx={{ maxHeight: { xs: 35, md: 65 } }}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid
                  item
                  xs={2}
                  md={1}
                  sx={{ marginRight: { md: -4 } }}
                ></Grid>
                <Grid item xs={2} md={1} sx={{ marginRight: { md: -4 } }}>
                  <ContentPaper text={"3"} />
                </Grid>
                <Grid item xs={8} sx={{ marginRight: { md: 8 } }}>
                  <ContentPaper text={"Re been vw opzwaaien"} />
                </Grid>
                <Grid
                  item
                  md={2}
                  sx={{ marginRight: { md: 0 } }}
                  display={{ xs: "none", md: "flex" }}
                ></Grid>
              </Grid>
              <Grid container>
                <Grid
                  item
                  xs={2}
                  md={1}
                  sx={{ marginRight: { md: -4 } }}
                ></Grid>
                <Grid item xs={2} md={1} sx={{ marginRight: { md: -4 } }}>
                  <ContentPaper text={"4"} />
                </Grid>
                <Grid item xs={8} sx={{ marginRight: { md: 8 } }}>
                  <ContentPaper
                    text={
                      "Re been via passé parallel uitstrekken naar arabesque (enveloppé) op gebogen standbeen linker arm via welbeweging naar voren, re arm naar schuin achter, handpalmen naar beneden"
                    }
                  />
                </Grid>
                <Grid
                  item
                  md={2}
                  sx={{ marginRight: { md: 0 } }}
                  display={{ xs: "none", md: "flex" }}
                >
                  <Box
                    component="img"
                    alt="symbol2"
                    src="../images/balkhoudingen/Symbol8.png"
                    sx={{ maxHeight: { xs: 35, md: 65 } }}
                  />
                </Grid>
              </Grid>
              <Grid container sx={{ marginBottom: { md: -5 } }}>
                <Grid
                  item
                  xs={2}
                  md={1}
                  sx={{ marginRight: { md: -4 } }}
                ></Grid>
                <Grid item xs={2} md={1} sx={{ marginRight: { md: -4 } }}>
                  <ContentPaper text={"5"} />
                </Grid>
                <Grid item xs={8} sx={{ marginRight: { md: 8 } }}>
                  <ContentPaper text={"Stap aw op rechts, armen zw"} />
                </Grid>
                <Grid
                  item
                  md={2}
                  sx={{ marginRight: { md: 0 } }}
                  display={{ xs: "none", md: "flex" }}
                >
                  <Box
                    component="img"
                    alt="symbol2"
                    src="../images/balkhoudingen/Symbol7.png"
                    sx={{ maxHeight: { xs: 35, md: 65 } }}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid
                  item
                  xs={2}
                  md={1}
                  sx={{ marginRight: { md: -4 } }}
                ></Grid>
                <Grid item xs={2} md={1} sx={{ marginRight: { md: -4 } }}>
                  <ContentPaper text={"6"} />
                </Grid>
                <Grid item xs={8} sx={{ marginRight: { md: 8 } }}>
                  <ContentPaper text={"li been vw opzwaaien"} />
                </Grid>
                <Grid
                  item
                  md={2}
                  sx={{ marginRight: { md: 0 } }}
                  display={{ xs: "none", md: "flex" }}
                ></Grid>
              </Grid>
              <Grid container>
                <Grid
                  item
                  xs={2}
                  md={1}
                  sx={{ marginRight: { md: -4 } }}
                ></Grid>
                <Grid item xs={2} md={1} sx={{ marginRight: { md: -4 } }}>
                  <ContentPaper text={"7"} />
                </Grid>
                <Grid item xs={8} sx={{ marginRight: { md: 8 } }}>
                  <ContentPaper
                    text={"Fouetédraai aw op rechts, arminzet via hoog naar zw"}
                  />
                </Grid>
                <Grid
                  item
                  md={2}
                  sx={{ marginRight: { md: 0 } }}
                  display={{ xs: "none", md: "flex" }}
                ></Grid>
              </Grid>
              <Grid container>
                <Grid
                  item
                  xs={2}
                  md={1}
                  sx={{ marginRight: { md: -4 } }}
                ></Grid>
                <Grid item xs={2} md={1} sx={{ marginRight: { md: -4 } }}>
                  <ContentPaper text={"8"} />
                </Grid>
                <Grid item xs={8} sx={{ marginRight: { md: 8 } }}>
                  <ContentPaper
                    text={"Li been voor aansluiten tot basishouding"}
                  />
                </Grid>
                <Grid
                  item
                  md={2}
                  sx={{ marginRight: { md: 0 } }}
                  display={{ xs: "none", md: "flex" }}
                ></Grid>
              </Grid>
            </Container>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};
