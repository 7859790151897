import React, { useState, useEffect } from "react";
import {
  collection,
  query,
  onSnapshot,
  updateDoc,
  doc,
  deleteDoc,
} from "firebase/firestore";
import {
  Grid,
  Container,
  Typography,
  Divider,
  Button,
  Paper,
} from "@mui/material";
import { Sup2Intro } from "./Sup2Intro";
import { Sup2Vault } from "./Sup2Vault";
import { Sup2Bars } from "./Sup2Bars";
import { Sup2Beam } from "./Sup2Beam";
import { Sup2Floor } from "./Sup2Floor";

export const Sup2 = () => {
  return (
    <>
      <Container>
        <Sup2Intro />
        <Sup2Vault />
        <Sup2Bars />
        <Sup2Beam />
        <Sup2Floor />
      </Container>
    </>
  );
};
