import React, { useState, useEffect } from "react";
import {
  collection,
  query,
  onSnapshot,
  updateDoc,
  getDoc,
  doc,
  deleteDoc,
} from "firebase/firestore";
import { db } from "../../firebase/config";
import { Grid, Container, Typography, Divider, Button } from "@mui/material";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { ProgramType } from "../../types/types";
import { Program } from "./Program";
import { auth } from "../../firebase/config";
import { AdminProgram } from "./AdminProgram";
import { UserProgram } from "./UserProgram";
import { getIdTokenResult, onAuthStateChanged } from "firebase/auth";

interface GymnastType {
  id: string;
  coachId: string;
}

export const ProgramComponent = () => {
  const [program, setProgram] = useState({});
  const [programG, setProgramG] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  const [gymnast, setGymnast] = useState<GymnastType>({ id: "", coachId: "" });
  const { authIsReady, user } = useAuthContext();
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const fetchProgramData = async () => {
      setIsLoading(true);
      const docSnap = await getDoc(
        doc(db, `ProgramsByCoach/${user.uid}/Programs/${id}`)
      );
      if (docSnap.exists()) {
        const newProgramObj = {
          id: docSnap.id,
          ...docSnap.data(),
        };
        setProgram(newProgramObj);
        setIsLoading(false);
      } else {
        //doc.data() will be undefined in this case
        console.log("No such document");
      }
    };
    fetchProgramData();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchGymnastData = async () => {
      setIsLoading(true);
      const docSnap = await getDoc(doc(db, `users/${user.uid}`));
      if (docSnap.exists()) {
        const data = docSnap.data();
        if (data && data.coachId) {
          const newGymnastObj = {
            id: docSnap.id,
            coachId: data.coachId,
            ...docSnap.data(),
          };
          setGymnast(newGymnastObj);
        } else {
          console.log("Coach ID not found in data");
        }
      } else {
        //doc.data() will be undefined in this case
        console.log("No such document");
      }
      setIsLoading(false);
    };
    fetchGymnastData();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  console.log(gymnast, "gymnast");

  useEffect(() => {
    const fetchProgramGData = async () => {
      if (gymnast.coachId) {
        setIsLoading(true);
        const docSnap = await getDoc(
          doc(db, `ProgramsByCoach/${gymnast.coachId}/Programs/${id}`)
        );
        if (docSnap.exists()) {
          const newProgramObj = {
            id: docSnap.id,
            ...docSnap.data(),
          };
          setProgramG(newProgramObj);
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document");
        }
        setIsLoading(false);
      }
    };
    fetchProgramGData();
  }, [gymnast.coachId, id]);

  onAuthStateChanged(auth, (user) => {
    if (user) {
      getIdTokenResult(user, true).then((idTokenResult) => {
        setIsAdmin(idTokenResult.claims.admin);
      });
    }
  });

  if (!authIsReady) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <Grid container>
        <Grid item>
          {isAdmin ? (
            <AdminProgram program={program} programG={programG} />
          ) : (
            <UserProgram program={program} programG={programG} />
          )}
        </Grid>
      </Grid>
    </>
  );

  // return (
  //   <>
  //     <Container>
  //       <Program program={program} programG={programG} />
  //     </Container>
  //   </>
  // );
};
