import React, { useState, useEffect } from "react";
import { collection, query, onSnapshot, orderBy } from "firebase/firestore";
import {
  Grid,
  Container,
  Typography,
  Paper,
  Button,
  ButtonGroup,
} from "@mui/material";
import { useAuthContext } from "../../../../hooks/useAuthContext";
import { useParams } from "react-router-dom";
import { db } from "../../../../firebase/config";
import { PgColors } from "../../../../configs/colors";
import { FlikBeam } from "./FlikBeam";
import { ArabierBeam } from "./ArabierBeam";
import { WisselspagaatBeam } from "./WisselspagaatBeam";
import { Pirouette90 } from "./Pirouette90Floor";
import { Wisselspagaat } from "./WisselspagaatFloor";

export const FloorSkills = () => {
  const [active, setActive] = useState("Pir90");
  const activeColor = PgColors.bluepg[400];

  return (
    <>
      <Grid container mt={2}>
        <Grid item xs={12} display="flex" justifyContent="center">
          <ButtonGroup variant="contained">
            <Button
              style={{
                backgroundColor:
                  active === "Pir90" ? activeColor : PgColors.bluepg[200],
              }}
              variant="contained"
              onClick={() => setActive("Pir90")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 10, md: 14 } }}
              >
                Pirouette 90º
              </Typography>
            </Button>
            <Button
              style={{
                backgroundColor:
                  active === "Wissel" ? activeColor : PgColors.bluepg[200],
              }}
              variant="contained"
              onClick={() => setActive("Wissel")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 10, md: 14 } }}
              >
                Wisselspagaat
              </Typography>
            </Button>
            <Button
              style={{
                backgroundColor:
                  active === "Arabier" ? activeColor : PgColors.bluepg[200],
              }}
              variant="contained"
              onClick={() => setActive("Arabier")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 10, md: 14 } }}
              >
                Arabier
              </Typography>
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid>
      <Grid container>{active === "Pir90" && <Pirouette90 />}</Grid>
      <Grid container>{active === "Wissel" && <Wisselspagaat />}</Grid>

      <Grid container>{active === "Arabier" && <ArabierBeam />}</Grid>
    </>
  );
};
