import React, { useState, useEffect } from "react";
import {
  Grid,
  Container,
  Typography,
  Divider,
  Button,
  Paper,
} from "@mui/material";
import { PgColors } from "../../../configs/colors";

export const Sup1Beam = () => {
  return (
    <>
      <Grid container mt={2} marginLeft={2}>
        <Grid item>
          <Typography variant="h6" sx={{ color: PgColors.redpg[500] }}>
            Balk
          </Typography>
        </Grid>
      </Grid>
      <Grid container marginLeft={2}>
        <Grid item>
          <Typography
            variant="subtitle1"
            sx={{
              color: PgColors.redpg[500],
              fontSize: { xs: 12, sm: 14, md: 16 },
            }}
            align="left"
          >
            *SE 1 t/m 5 en bonussen 1, 3, 4, 5 en 7 moeten op de balk geturnd
            worden.
          </Typography>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item mt={0} xs={12}>
          <Paper
            elevation={2}
            sx={{ backgroundColor: PgColors.bluepg[100], width: "100%" }}
          >
            <Container>
              <Grid
                container
                marginTop={0}
                sx={{ borderBottom: 1, borderColor: PgColors.bluepg[200] }}
                display={{ xs: "none", md: "flex" }}
              >
                <Grid
                  item
                  md={1}
                  display={{ xs: "none", md: "flex" }}
                  sx={{ marginRight: { md: -4, lg: -8 } }}
                ></Grid>
                <Grid item xs={11} md={5}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontWeight: 500,
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    Samenstellingselement
                  </Typography>
                </Grid>
                <Grid item xs={1} md={1} sx={{ pl: { md: 2 } }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontWeight: 500,
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    D
                  </Typography>
                </Grid>

                <Grid item md={5} display={{ xs: "none", md: "flex" }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontWeight: 500,
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    Bonus
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{
                    mr: { md: -4 },
                  }}
                  display={{ xs: "none", md: "flex" }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontWeight: 500,
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    D
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                container
                marginTop={1}
                sx={{ borderBottom: 1, borderColor: PgColors.bluepg[200] }}
                display={{ xs: "none", md: "flex" }}
              >
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{ marginRight: { md: -4, lg: -8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    1
                  </Typography>
                </Grid>
                <Grid item xs={10} md={5}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    Verb van min 2 versch gymnastische sprongen, waarvan één
                    wisselspagaat en beide sprongen met 180º beenspreiding
                  </Typography>
                </Grid>
                <Grid item xs={1} md={1} sx={{ pl: { md: 2 } }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    1.00
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={5}
                  display={{ xs: "none", md: "flex" }}
                  sx={{ pr: { md: 2 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                      marginRight: { md: -2 },
                    }}
                    align="left"
                  >
                    3e gymnastische sprong min B en in verb met SE 1
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={1}
                  display={{ xs: "none", md: "flex" }}
                  sx={{ mr: { md: -4 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    0.30
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                container
                marginTop={1}
                sx={{ borderBottom: 1, borderColor: PgColors.bluepg[200] }}
                display={{ xs: "none", md: "flex" }}
              >
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{ marginRight: { md: -4, lg: -8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    2
                  </Typography>
                </Grid>
                <Grid item xs={10} md={5}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    Voorgeschreven balk houdingen serie 1
                  </Typography>
                </Grid>
                <Grid item xs={1} md={1} sx={{ pl: { md: 2 } }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    1.00
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={5}
                  sx={{ pr: { md: 2 } }}
                  display={{ xs: "none", md: "flex" }}
                ></Grid>
                <Grid
                  item
                  md={1}
                  display={{ xs: "none", md: "flex" }}
                  sx={{ mr: { md: -4 } }}
                ></Grid>
              </Grid>

              <Grid
                container
                marginTop={1}
                sx={{ borderBottom: 1, borderColor: PgColors.bluepg[200] }}
                display={{ xs: "none", md: "flex" }}
              >
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{ marginRight: { md: -4, lg: -8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    3
                  </Typography>
                </Grid>
                <Grid item xs={10} md={5}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    Acroserie van boogje ao - flifklak óf radslag - flikflak
                  </Typography>
                </Grid>
                <Grid item xs={1} md={1} sx={{ pl: { md: 2 } }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    1.00
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={5}
                  sx={{ pr: { md: 2 } }}
                  display={{ xs: "none", md: "flex" }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    Ipv SE 3: flikflak - flikflak of acroserie rebounding
                    confirm FIG
                  </Typography>
                </Grid>
                <Grid item md={1} sx={{ mr: { md: -4 } }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    0.30
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                container
                marginTop={1}
                sx={{ borderBottom: 1, borderColor: PgColors.bluepg[200] }}
                display={{ xs: "none", md: "flex" }}
              >
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{ marginRight: { md: -4, lg: -8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    4
                  </Typography>
                </Grid>
                <Grid item xs={10} md={5}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    Arabier óf boogje vo
                  </Typography>
                </Grid>
                <Grid item xs={1} md={1} sx={{ pl: { md: 2 } }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    0.50
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={5}
                  display={{ xs: "none", md: "flex" }}
                  sx={{ pr: { md: 2 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    Ipv SE 4: Arabier-kaats
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={1}
                  display={{ xs: "none", md: "flex" }}
                  sx={{ mr: { md: -4 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    0.30
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                container
                marginTop={1}
                sx={{ borderBottom: 1, borderColor: PgColors.bluepg[200] }}
                display={{ xs: "none", md: "flex" }}
              >
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{ marginRight: { md: -4, lg: -8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    5
                  </Typography>
                </Grid>
                <Grid item xs={10} md={5}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    (Min) 1/1 pirouette - vrije been in passé
                  </Typography>
                </Grid>
                <Grid item xs={1} md={1} sx={{ pl: { md: 2 } }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    0.50
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={5}
                  display={{ xs: "none", md: "flex" }}
                  sx={{ pr: { md: 2 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    Een 2e versch draai uit groep 3 uit een andere lijn
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={1}
                  display={{ xs: "none", md: "flex" }}
                  sx={{ mr: { md: -4 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    0.30
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                container
                marginTop={1}
                display={{ xs: "none", md: "flex" }}
                sx={{ borderBottom: 1, borderColor: PgColors.bluepg[200] }}
              >
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{ marginRight: { md: -4, lg: -8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    6
                  </Typography>
                </Grid>
                <Grid item xs={10} md={5}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    Afsprong: een verb van arabier of flikflak - salto ao
                  </Typography>
                </Grid>
                <Grid item xs={1} md={1} sx={{ pl: { md: 2 } }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    0.50
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={5}
                  display={{ xs: "none", md: "flex" }}
                  sx={{ pr: { md: 2 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    Afsprong SE 6 uitgeveord als salto ao gestrekt
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={1}
                  display={{ xs: "none", md: "flex" }}
                  sx={{ mr: { md: -4 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    0.30
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                marginTop={1}
                display={{ xs: "none", md: "flex" }}
              >
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{ marginRight: { md: -4, lg: -8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    7
                  </Typography>
                </Grid>
                <Grid item xs={10} md={5}></Grid>
                <Grid item xs={1} md={1} sx={{ pl: { md: 2 } }}></Grid>
                <Grid
                  item
                  md={5}
                  display={{ xs: "none", md: "flex" }}
                  sx={{ pr: { md: 2 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    Handstand heffen vu spreidhoeksteun met gestrekte armen
                    (geen opsprong)
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={1}
                  display={{ xs: "none", md: "flex" }}
                  sx={{ mr: { md: -4 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    0.30
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                container
                mt={2}
                display={{ xs: "flex", md: "none" }}
                sx={{ borderBottom: 1, borderColor: PgColors.bluepg[200] }}
              >
                <Grid item xs={11} md={4} sx={{ marginRight: { md: 10 } }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontWeight: 500,
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    Samenstellingselement
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontWeight: 500,
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    D
                  </Typography>
                </Grid>
              </Grid>
              <Grid container mt={1} display={{ xs: "flex", md: "none" }}>
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{ marginRight: { md: -4, lg: -8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    1
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={10}
                  sx={{ paddingRight: { md: 0 }, marginRight: { md: 8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    Verb van min 2 versch gymnastische sprongen, waarvan één
                    wisselspagaat en beide sprongen met 180º beenspreiding
                  </Typography>
                </Grid>
                <Grid item xs={1} md={1} sx={{ marginRight: { md: 0 } }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    1.00
                  </Typography>
                </Grid>
              </Grid>
              <Grid container mt={1} display={{ xs: "flex", md: "none" }}>
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{ marginRight: { md: -4, lg: -8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    2
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={10}
                  sx={{ paddingRight: { md: 0 }, marginRight: { md: 8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    Voorgeschreven balk houdingen serie 1
                  </Typography>
                </Grid>
                <Grid item xs={1} md={1} sx={{ marginRight: { md: 0 } }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    1.00
                  </Typography>
                </Grid>
              </Grid>
              <Grid container mt={1} display={{ xs: "flex", md: "none" }}>
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{ marginRight: { md: -4, lg: -8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    3
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={10}
                  sx={{ paddingRight: { md: 0 }, marginRight: { md: 8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    Acroserie van boogje ao - flifklak óf radslag - flikflak
                  </Typography>
                </Grid>
                <Grid item xs={1} md={1} sx={{ marginRight: { md: 0 } }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    1.00
                  </Typography>
                </Grid>
              </Grid>
              <Grid container mt={1} display={{ xs: "flex", md: "none" }}>
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{ marginRight: { md: -4, lg: -8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    4
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={10}
                  sx={{ paddingRight: { md: 0 }, marginRight: { md: 8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    Arabier óf boogje vo
                  </Typography>
                </Grid>
                <Grid item xs={1} md={1} sx={{ marginRight: { md: 0 } }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    0.50
                  </Typography>
                </Grid>
              </Grid>
              <Grid container mt={1} display={{ xs: "flex", md: "none" }}>
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{ marginRight: { md: -4, lg: -8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    5
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={10}
                  sx={{ paddingRight: { md: 0 }, marginRight: { md: 8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    (Min) 1/1 pirouette - vrije been in passé
                  </Typography>
                </Grid>
                <Grid item xs={1} md={1} sx={{ marginRight: { md: 0 } }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    0.50
                  </Typography>
                </Grid>
              </Grid>
              <Grid container mt={1} display={{ xs: "flex", md: "none" }}>
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{ marginRight: { md: -4, lg: -8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    6
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={10}
                  sx={{ paddingRight: { md: 0 }, marginRight: { md: 8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    Afsprong: een verb van arabier of flikflak - salto ao
                  </Typography>
                </Grid>
                <Grid item xs={1} md={1} sx={{ marginRight: { md: 0 } }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    0.50
                  </Typography>
                </Grid>
              </Grid>
              <Grid container mt={1} display={{ xs: "flex", md: "none" }}>
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{ marginRight: { md: -4, lg: -8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    7
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={10}
                  sx={{ paddingRight: { md: 0 }, marginRight: { md: 8 } }}
                ></Grid>
                <Grid item xs={1} md={1} sx={{ marginRight: { md: 0 } }}></Grid>
              </Grid>

              <Grid
                container
                mt={2}
                display={{ xs: "flex", md: "none" }}
                sx={{ borderBottom: 1, borderColor: PgColors.redpg[200] }}
              >
                <Grid item xs={11} md={4} sx={{ marginRight: { md: 10 } }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontWeight: 500,
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    Bonus
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontWeight: 500,
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    D
                  </Typography>
                </Grid>
              </Grid>

              <Grid container mt={1} display={{ xs: "flex", md: "none" }}>
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{ marginRight: { md: -4, lg: -8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    1
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={10}
                  sx={{ paddingRight: { md: 0 }, marginRight: { md: 8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    3e gymnastische sprong min B en in verb met SE 1
                  </Typography>
                </Grid>
                <Grid item xs={1} md={1} sx={{ marginRight: { md: 0 } }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    0.30
                  </Typography>
                </Grid>
              </Grid>
              <Grid container mt={1} display={{ xs: "flex", md: "none" }}>
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{ marginRight: { md: -4, lg: -8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    2
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={10}
                  sx={{ paddingRight: { md: 0 }, marginRight: { md: 8 } }}
                ></Grid>
                <Grid item xs={1} md={1} sx={{ marginRight: { md: 0 } }}></Grid>
              </Grid>
              <Grid container mt={1} display={{ xs: "flex", md: "none" }}>
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{ marginRight: { md: -4, lg: -8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    3
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={10}
                  sx={{ paddingRight: { md: 0 }, marginRight: { md: 8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    Ipv SE 3: flikflak - flikflak of acroserie rebounding
                    confirm FIG
                  </Typography>
                </Grid>
                <Grid item xs={1} md={1} sx={{ marginRight: { md: 0 } }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    0.30
                  </Typography>
                </Grid>
              </Grid>
              <Grid container mt={1} display={{ xs: "flex", md: "none" }}>
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{ marginRight: { md: -4, lg: -8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    4
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={10}
                  sx={{ paddingRight: { md: 0 }, marginRight: { md: 8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    Ipv SE 4: Arabier-kaats
                  </Typography>
                </Grid>
                <Grid item xs={1} md={1} sx={{ marginRight: { md: 0 } }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    0.30
                  </Typography>
                </Grid>
              </Grid>
              <Grid container mt={1} display={{ xs: "flex", md: "none" }}>
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{ marginRight: { md: -4, lg: -8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    5
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={10}
                  sx={{ paddingRight: { md: 0 }, marginRight: { md: 8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    Een 2e versch draai uit groep 3 uit een andere lijn
                  </Typography>
                </Grid>
                <Grid item xs={1} md={1} sx={{ marginRight: { md: 0 } }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    0.30
                  </Typography>
                </Grid>
              </Grid>
              <Grid container mt={1} display={{ xs: "flex", md: "none" }}>
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{ marginRight: { md: -4, lg: -8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    6
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={10}
                  sx={{ paddingRight: { md: 0 }, marginRight: { md: 8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    Afsprong SE 6 uitgeveord als salto ao gestrekt
                  </Typography>
                </Grid>
                <Grid item xs={1} md={1} sx={{ marginRight: { md: 0 } }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    0.30
                  </Typography>
                </Grid>
              </Grid>
              <Grid container mt={1} display={{ xs: "flex", md: "none" }}>
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{ marginRight: { md: -4, lg: -8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    7
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={10}
                  sx={{ paddingRight: { md: 0 }, marginRight: { md: 8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    Handstand heffen vu spreidhoeksteun met gestrekte armen
                    (geen opsprong)
                  </Typography>
                </Grid>
                <Grid item xs={1} md={1} sx={{ marginRight: { md: 0 } }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    0.30
                  </Typography>
                </Grid>
              </Grid>
            </Container>
          </Paper>
        </Grid>
      </Grid>
      <Grid container marginLeft={2}>
        <Grid item>
          <Typography
            variant="subtitle1"
            sx={{
              color: PgColors.redpg[500],
              fontSize: { xs: 12, sm: 14, md: 16 },
            }}
            align="left"
          >
            *De elementen uit de acroserie moeten verbonden zijn. Voorbeelden
            van bonus 3 staan in NTS Reglement artikel 12.8.1
          </Typography>
        </Grid>
      </Grid>
      <Grid container marginLeft={2}>
        <Grid item>
          <Typography
            variant="subtitle1"
            sx={{
              color: PgColors.redpg[500],
              fontSize: { xs: 12, sm: 14, md: 16 },
            }}
            align="left"
          >
            *SE 4 kan niet worden voldaan in combinatie met SE 6.
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};
