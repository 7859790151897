import React, { useState, useEffect } from "react";
import { collection, query, onSnapshot, orderBy } from "firebase/firestore";
import {
  Grid,
  Container,
  Typography,
  Paper,
  Button,
  Box,
  CardMedia,
} from "@mui/material";
import { useAuthContext } from "../../../../hooks/useAuthContext";
import { useParams } from "react-router-dom";
import { db } from "../../../../firebase/config";
import { PgColors } from "../../../../configs/colors";
import { AddExercise8A } from "./AddExercise8";
import { ListExercise8A } from "./ListExercise8";
import { AddExercise9A, AddExercise9B, AddExercise9C } from "./AddExercise9";
import {
  ListExercise9A,
  ListExercise9B,
  ListExercise9C,
} from "./ListExercise9";

export const PressurePage = () => {
  const { authIsReady, user } = useAuthContext();
  const [exercise9AList, setExercise9AList] = useState([]);
  const [exercise9BList, setExercise9BList] = useState([]);
  const [exercise9CList, setExercise9CList] = useState([]);

  const { id } = useParams();

  useEffect(() => {
    const ref = query(
      collection(db, `IntroExercises/${user.uid}/UserExercise9A`),
      orderBy("createdAt", "desc")
    );

    const unsub = onSnapshot(ref, (snapshot) => {
      let results: any = [];
      snapshot.docs.forEach((doc) => {
        results.push({ ...doc.data(), id: doc.id });
      });
      setExercise9AList(results);
    });
    return () => unsub();
  }, []);

  useEffect(() => {
    const ref = query(
      collection(db, `IntroExercises/${user.uid}/UserExercise9B`),
      orderBy("createdAt", "desc")
    );

    const unsub = onSnapshot(ref, (snapshot) => {
      let results: any = [];
      snapshot.docs.forEach((doc) => {
        results.push({ ...doc.data(), id: doc.id });
      });
      setExercise9BList(results);
    });
    return () => unsub();
  }, []);

  useEffect(() => {
    const ref = query(
      collection(db, `IntroExercises/${user.uid}/UserExercise9C`),
      orderBy("createdAt", "desc")
    );

    const unsub = onSnapshot(ref, (snapshot) => {
      let results: any = [];
      snapshot.docs.forEach((doc) => {
        results.push({ ...doc.data(), id: doc.id });
      });
      setExercise9CList(results);
    });
    return () => unsub();
  }, []);

  return (
    <>
      <Grid container mt={2}>
        <Grid item xs={12}>
          <Paper
            style={{ backgroundColor: PgColors.redpg[400] }}
            sx={{ height: { xs: 45, md: 75 } }}
          >
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, sm: 14, md: 16, lg: 20 } }}
              align="center"
            >
              "Concentration is a fine antidote to anxiety"
            </Typography>

            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, sm: 14, md: 16, lg: 20 } }}
              align="center"
            >
              - Jack Nicklaus
            </Typography>
          </Paper>
        </Grid>
      </Grid>
      <Grid container mt={2}>
        <Grid item xs={12} mt={1}>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
            }}
            align="left"
          >
            Tijdens wedstrijden en misschien zelfs wel in de training kan het
            zijn dat je druk of spanning voelt. Je kan dan zelfs denken dat
            spanning onderdeel is van de sport. Het zit toch net anders!
          </Typography>
        </Grid>
        <Grid item xs={12} mt={1}>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
            }}
            align="left"
          >
            Situaties zijn op zichzelf niet spannend. Hoe jij kijkt naar deze
            situaties zorgt voor een gevoel van spanning. Of je spanning voelt
            is ook direct gerelateerd aan waar je je op focust.
          </Typography>
        </Grid>
        <Grid item xs={12} mt={1}>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
            }}
            align="left"
          >
            Wanneer je focust op de mogelijke restultaten, zal je waarschijnlijk
            spanning voelen en je ademhaling zal ongemerkt versnellen. Gelukkig
            werkt het ook andersom. Wanneer je de focus legt op de taak zelf 'Ik
            moet mijn schouders boven de balk plaatsen.' zal je minder spanning
            voelen en je ademhaling meer onder controle blijven.
          </Typography>
        </Grid>

        <Grid item xs={12} mt={1}>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
            }}
            align="left"
          >
            Je kan oefenen om je focus te krijgen, daar waar het nodig is. Je
            kan je hersenen trainen om te focussen op effectieve dingen om je
            lichaam/geest te helpen ontspannen en zo je prestaties te
            verbeteren.
          </Typography>
        </Grid>

        <Grid item xs={12} mt={2}>
          <Typography
            variant="subtitle1"
            style={{ color: PgColors.bluepg[400] }}
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
              fontWeight: { md: 500 },
            }}
            align="left"
          >
            Focus op het proces in plaats van het resultaat
          </Typography>
        </Grid>
        <Grid item xs={12} mt={0}>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
            }}
            align="left"
          >
            Probeer vooral na te denken over 'hoe' je iets gaat doen. Tijdens
            een wedstrijd kan je bijvoorbeeld je oefening visualeren en tijdens
            een training kan je denken aan een specifieke beweging die je lijf
            moet maken om de skill te laten lukken.
          </Typography>
        </Grid>
        <Grid item xs={12} mt={2}>
          <Typography
            variant="subtitle1"
            style={{ color: PgColors.bluepg[400] }}
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
              fontWeight: { md: 500 },
            }}
            align="left"
          >
            Oefen een diepe en langzame ademhaling
          </Typography>
        </Grid>
        <Grid item xs={12} mt={0}>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
            }}
            align="left"
          >
            Als je je focus op je ademnhaling kunt leggen, kan je je spieren
            ontspannen en negatieve gedachten makkelijker negeren. Probeer
            bijvoorbeeld box-breathing:
          </Typography>
        </Grid>
        <Grid item xs={12} mt={0}>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
            }}
            align="left"
          >
            - Stap 1: Adem in, tel langzaam tot 4. Voel de lucht je longen
            ingaan.
          </Typography>
        </Grid>
        <Grid item xs={12} mt={0}>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
            }}
            align="left"
          >
            - Stap 2: Hou vast voor 4 tellen. Probeer niet in of uit te ademen.
          </Typography>
        </Grid>
        <Grid item xs={12} mt={0}>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
            }}
            align="left"
          >
            - Stap 3: Adem langzaam uit voor 4 tellen.
          </Typography>
        </Grid>
        <Grid item xs={12} mt={0}>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
            }}
            align="left"
          >
            - Stap 4: Hou weer vast voor 4 tellen.
          </Typography>
        </Grid>
        <Grid item xs={12} mt={0}>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
            }}
            align="left"
          >
            - Stap 5: Herhaal
          </Typography>
        </Grid>

        <Grid item xs={12} mt={2}>
          <Typography
            variant="subtitle1"
            style={{ color: PgColors.bluepg[400] }}
            sx={{
              fontSize: { xs: 16, md: 20, lg: 24 },
              fontWeight: { md: 500 },
            }}
            align="left"
          >
            Opdracht 9
          </Typography>
        </Grid>
      </Grid>

      <Grid container mt={2}>
        <Grid item xs={12} mt={1}>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
            }}
            align="left"
          >
            Vul onderstaande tekstvelden in zodat je een strategie kunt maken om
            om te gaan met spanning.
          </Typography>
        </Grid>
      </Grid>
      <Grid container mt={2}>
        <AddExercise9A />
      </Grid>
      <Grid container mt={2}>
        <AddExercise9B />
      </Grid>
      <Grid container mt={2}>
        <AddExercise9C />
      </Grid>
      <Grid container mt={2} mb={2}>
        {exercise9AList && (
          <ListExercise9A
            exercises={exercise9AList}
            path={`IntroExercises/${id}/UserExercise9A/${id}`}
          />
        )}
      </Grid>
      <Grid container mt={0} mb={2}>
        {exercise9BList && (
          <ListExercise9B
            exercises={exercise9BList}
            path={`IntroExercises/${id}/UserExercise9B/${id}`}
          />
        )}
      </Grid>
      <Grid container mt={0} mb={2}>
        {exercise9CList && (
          <ListExercise9C
            exercises={exercise9CList}
            path={`IntroExercises/${id}/UserExercise9C/${id}`}
          />
        )}
      </Grid>
    </>
  );
};
