import React, { useState, useEffect } from "react";
import { Grid, Container } from "@mui/material";
import { SupGIntro } from "./SupGIntro";
import { SupGSE } from "./SupGSE";
import { SupGVW } from "./SupGVW";

export const SupG = () => {
  return (
    <>
      <Container>
        <SupGIntro />
        <SupGSE />
        <SupGVW />
      </Container>
    </>
  );
};
