import React, { useState, useEffect } from "react";
import { collection, query, onSnapshot, orderBy } from "firebase/firestore";
import {
  Grid,
  Container,
  Typography,
  Paper,
  Button,
  ButtonGroup,
} from "@mui/material";
import { useAuthContext } from "../../../../hooks/useAuthContext";

import { useParams } from "react-router-dom";
import { db } from "../../../../firebase/config";
import { PgColors } from "../../../../configs/colors";
import { IntroPage } from "./IntroPage";
import { GoalsHeadPage } from "./GoalsHeadPage";
import { MindsetPage } from "./MindsetPage";
import { MotivationPage } from "./MotivationPage";
import { GrowingMotivationPage } from "./GrowingMotivationPage";
import { ConfidencePage } from "./ConfidencePage";
import { FocusPage } from "./FocusPage";
import { PressurePage } from "./PressurePage";
import { VisualizationPage } from "./VisualizationPage";

export const WorkbookPage = () => {
  const [active, setActive] = useState("Intro");
  const activeColor = PgColors.bluepg[400];

  return (
    <>
      <Grid
        container
        mt={2}
        spacing={1}
        sx={{ display: { xs: "none", xl: "flex" } }}
      >
        <Grid
          item
          xs={12}
          // sm={2}
          // md={2}
          // xl={1}
          display="flex"
          justifyContent="center"
        >
          <ButtonGroup variant="contained">
            <Button
              style={{
                backgroundColor:
                  active === "Intro" ? activeColor : PgColors.bluepg[200],
              }}
              onClick={() => setActive("Intro")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 10, sm: 12, md: 14 } }}
              >
                Intro
              </Typography>
            </Button>
            <Button
              style={{
                backgroundColor:
                  active === "Goals" ? activeColor : PgColors.bluepg[200],
              }}
              onClick={() => setActive("Goals")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 10, sm: 12, md: 14 } }}
              >
                Goals
              </Typography>
            </Button>
            <Button
              style={{
                backgroundColor:
                  active === "Mind" ? activeColor : PgColors.bluepg[200],
              }}
              onClick={() => setActive("Mind")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 10, sm: 12, md: 14 } }}
              >
                Mindset
              </Typography>
            </Button>
            <Button
              style={{
                backgroundColor:
                  active === "Motivation" ? activeColor : PgColors.bluepg[200],
              }}
              onClick={() => setActive("Motivation")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 10, sm: 12, md: 14 } }}
              >
                Motivation
              </Typography>
            </Button>
            <Button
              style={{
                backgroundColor:
                  active === "Growing" ? activeColor : PgColors.bluepg[200],
              }}
              onClick={() => setActive("Growing")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 10, sm: 12, md: 14 } }}
              >
                Growing Motivation
              </Typography>
            </Button>
            <Button
              style={{
                backgroundColor:
                  active === "Confidence" ? activeColor : PgColors.bluepg[200],
              }}
              onClick={() => setActive("Confidence")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 10, sm: 12, md: 14 } }}
              >
                Confidence
              </Typography>
            </Button>
            <Button
              style={{
                backgroundColor:
                  active === "Focus" ? activeColor : PgColors.bluepg[200],
              }}
              onClick={() => setActive("Focus")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 10, sm: 12, md: 14 } }}
              >
                Focus
              </Typography>
            </Button>
            <Button
              style={{
                backgroundColor:
                  active === "Pressure" ? activeColor : PgColors.bluepg[200],
              }}
              onClick={() => setActive("Pressure")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 10, sm: 12, md: 14 } }}
              >
                Pressure
              </Typography>
            </Button>
            <Button
              style={{
                backgroundColor:
                  active === "Visualization"
                    ? activeColor
                    : PgColors.bluepg[200],
              }}
              onClick={() => setActive("Visualization")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 10, sm: 11, md: 14 } }}
              >
                Visualization
              </Typography>
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid>
      <Grid
        container
        mt={2}
        spacing={1}
        sx={{ display: { xs: "none", sm: "flex", xl: "none" } }}
      >
        <Grid
          item
          xs={12}
          // sm={2}
          // md={2}
          // xl={1}
          display="flex"
          justifyContent="center"
        >
          <ButtonGroup variant="contained">
            <Button
              style={{
                backgroundColor:
                  active === "Intro" ? activeColor : PgColors.bluepg[200],
              }}
              onClick={() => setActive("Intro")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 10, sm: 12, md: 14 } }}
              >
                Intro
              </Typography>
            </Button>
            <Button
              style={{
                backgroundColor:
                  active === "Goals" ? activeColor : PgColors.bluepg[200],
              }}
              onClick={() => setActive("Goals")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 10, sm: 12, md: 14 } }}
              >
                Goals
              </Typography>
            </Button>
            <Button
              style={{
                backgroundColor:
                  active === "Mind" ? activeColor : PgColors.bluepg[200],
              }}
              onClick={() => setActive("Mind")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 10, sm: 12, md: 14 } }}
              >
                Mindset
              </Typography>
            </Button>
            <Button
              style={{
                backgroundColor:
                  active === "Motivation" ? activeColor : PgColors.bluepg[200],
              }}
              onClick={() => setActive("Motivation")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 10, sm: 12, md: 14 } }}
              >
                Motivation
              </Typography>
            </Button>
            <Button
              style={{
                backgroundColor:
                  active === "Growing" ? activeColor : PgColors.bluepg[200],
              }}
              onClick={() => setActive("Growing")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 10, sm: 12, md: 14 } }}
              >
                Growing Motivation
              </Typography>
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid>
      <Grid
        container
        mt={0}
        spacing={1}
        sx={{ display: { xs: "none", sm: "flex", xl: "none" } }}
      >
        <Grid
          item
          xs={12}
          // sm={2}
          // md={2}
          // xl={1}
          display="flex"
          justifyContent="center"
        >
          <ButtonGroup variant="contained">
            <Button
              style={{
                backgroundColor:
                  active === "Confidence" ? activeColor : PgColors.bluepg[200],
              }}
              onClick={() => setActive("Confidence")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 10, sm: 12, md: 14 } }}
              >
                Confidence
              </Typography>
            </Button>
            <Button
              style={{
                backgroundColor:
                  active === "Focus" ? activeColor : PgColors.bluepg[200],
              }}
              onClick={() => setActive("Focus")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 10, sm: 12, md: 14 } }}
              >
                Focus
              </Typography>
            </Button>
            <Button
              style={{
                backgroundColor:
                  active === "Pressure" ? activeColor : PgColors.bluepg[200],
              }}
              onClick={() => setActive("Pressure")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 10, sm: 12, md: 14 } }}
              >
                Pressure
              </Typography>
            </Button>
            <Button
              style={{
                backgroundColor:
                  active === "Visualization"
                    ? activeColor
                    : PgColors.bluepg[200],
              }}
              onClick={() => setActive("Visualization")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 10, sm: 11, md: 14 } }}
              >
                Visualization
              </Typography>
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid>
      <Grid
        container
        mt={2}
        spacing={1}
        sx={{ display: { xs: "flex", sm: "none" } }}
      >
        <Grid item xs={12} display="flex" justifyContent="center">
          <ButtonGroup variant="contained">
            <Button
              style={{
                backgroundColor:
                  active === "Intro" ? activeColor : PgColors.bluepg[200],
              }}
              onClick={() => setActive("Intro")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 10, sm: 12, md: 14 } }}
              >
                Intro
              </Typography>
            </Button>
            <Button
              style={{
                backgroundColor:
                  active === "Goals" ? activeColor : PgColors.bluepg[200],
              }}
              onClick={() => setActive("Goals")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 10, sm: 12, md: 14 } }}
              >
                Goals
              </Typography>
            </Button>
            <Button
              style={{
                backgroundColor:
                  active === "Mind" ? activeColor : PgColors.bluepg[200],
              }}
              onClick={() => setActive("Mind")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 10, sm: 12, md: 14 } }}
              >
                Mindset
              </Typography>
            </Button>
            <Button
              style={{
                backgroundColor:
                  active === "Motivation" ? activeColor : PgColors.bluepg[200],
              }}
              onClick={() => setActive("Motivation")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 10, sm: 12, md: 14 } }}
              >
                Motivation
              </Typography>
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid>
      <Grid
        container
        mt={0}
        spacing={1}
        sx={{ display: { xs: "flex", sm: "none" } }}
      >
        <Grid item xs={12} display="flex" justifyContent="center">
          <ButtonGroup variant="contained">
            <Button
              style={{
                backgroundColor:
                  active === "Growing" ? activeColor : PgColors.bluepg[200],
              }}
              onClick={() => setActive("Growing")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 10, sm: 12, md: 14 } }}
              >
                Growing Motivation
              </Typography>
            </Button>
            <Button
              style={{
                backgroundColor:
                  active === "Confidence" ? activeColor : PgColors.bluepg[200],
              }}
              onClick={() => setActive("Confidence")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 10, sm: 12, md: 14 } }}
              >
                Confidence
              </Typography>
            </Button>
            <Button
              style={{
                backgroundColor:
                  active === "Focus" ? activeColor : PgColors.bluepg[200],
              }}
              onClick={() => setActive("Focus")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 10, sm: 12, md: 14 } }}
              >
                Focus
              </Typography>
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid>
      <Grid
        container
        mt={0}
        spacing={1}
        sx={{ display: { xs: "flex", sm: "none" } }}
      >
        <Grid item xs={12} display="flex" justifyContent="center">
          <ButtonGroup variant="contained">
            <Button
              style={{
                backgroundColor:
                  active === "Pressure" ? activeColor : PgColors.bluepg[200],
              }}
              onClick={() => setActive("Pressure")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 10, sm: 12, md: 14 } }}
              >
                Pressure
              </Typography>
            </Button>
            <Button
              style={{
                backgroundColor:
                  active === "Visualization"
                    ? activeColor
                    : PgColors.bluepg[200],
              }}
              onClick={() => setActive("Visualization")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 10, sm: 11, md: 14 } }}
              >
                Visualization
              </Typography>
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid>
      <Grid container>{active === "Intro" && <IntroPage />}</Grid>
      <Grid container>{active === "Goals" && <GoalsHeadPage />}</Grid>
      <Grid container>{active === "Mind" && <MindsetPage />}</Grid>
      <Grid container>{active === "Motivation" && <MotivationPage />}</Grid>
      <Grid container>{active === "Growing" && <GrowingMotivationPage />}</Grid>
      <Grid container>{active === "Confidence" && <ConfidencePage />}</Grid>
      <Grid container>{active === "Focus" && <FocusPage />}</Grid>
      <Grid container>{active === "Pressure" && <PressurePage />}</Grid>
      <Grid container>
        {active === "Visualization" && <VisualizationPage />}
      </Grid>
    </>
  );
};
