import React, { useState, useEffect, FC } from "react";
import {
  Grid,
  Container,
  Typography,
  Divider,
  Button,
  Paper,
} from "@mui/material";
import { PgColors } from "../../../configs/colors";

type TextProps = {
  text: string;
};

export const ContentPaper: FC<TextProps> = ({ text }) => {
  return (
    <Typography
      variant="subtitle1"
      sx={{ color: PgColors.white, fontSize: { xs: 12, sm: 14, md: 16 } }}
      align="left"
    >
      {text}
    </Typography>
  );
};

export const TitlePaper: FC<TextProps> = ({ text }) => {
  return (
    <Typography
      variant="subtitle1"
      sx={{
        color: PgColors.redpg[500],
        fontWeight: 500,
        fontSize: { xs: 12, sm: 14, md: 16 },
      }}
      align="left"
    >
      {text}
    </Typography>
  );
};

export const Content: FC<TextProps> = ({ text }) => {
  return (
    <Typography
      variant="subtitle1"
      sx={{ color: PgColors.redpg[500], fontSize: { xs: 12, sm: 14, md: 16 } }}
      align="left"
    >
      {text}
    </Typography>
  );
};

export const SupFIntro = () => {
  return (
    <>
      <Grid container mt={2} marginLeft={2}>
        <Grid item>
          <Typography variant="h5" style={{ color: PgColors.redpg[500] }}>
            Sup F - Jeugd divisie 4 & Junior divisie 5 & Senior divisie 6
          </Typography>
        </Grid>
      </Grid>

      <Grid container display="flex" justifyContent="center">
        <Grid item xs={12}>
          <Paper
            elevation={2}
            style={{ backgroundColor: PgColors.bluepg[100], width: "100%" }}
          >
            <Container>
              <Grid container>
                <Grid item xs={12} md={4}>
                  <TitlePaper text={"D Score"} />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} md={4}>
                  <TitlePaper text={"Moeilijkheidswaarde"} />
                </Grid>
                <Grid item xs={12} md={8}>
                  <ContentPaper text={"7 hoogste waarde elementen"} />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item md={4}></Grid>
                <Grid item md={8}>
                  <ContentPaper text={"TA(0.1) - A(0.1) - B(0.2)*"} />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item md={4}></Grid>
                <Grid item md={8}>
                  <ContentPaper
                    text={"Min 2 acro elementen en min 3 dans elementen"}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={7} md={4}>
                  <TitlePaper text={"Samenstellingseisen"} />
                </Grid>
                <Grid item xs={5} md={8}>
                  <ContentPaper text={"Max 2.00"} />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={6} md={4}>
                  <TitlePaper text={"Verbindingswaarde"} />
                </Grid>
                <Grid item xs={6} md={8}>
                  <ContentPaper text={"0.10 per keer"} />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={5} md={4}>
                  <TitlePaper text={"Afsprong bonus"} />
                </Grid>
                <Grid item xs={7} md={8}>
                  <ContentPaper text={"0.20 (brug, balk, vloer)"} />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={7} md={4}>
                  <TitlePaper text={"E Score"} />
                </Grid>
                <Grid item xs={5} md={8}>
                  <ContentPaper text={"Max 10.00"} />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={7} md={4}>
                  <TitlePaper text={"Technische Uitvoering"} />
                </Grid>
                <Grid item xs={5} md={8}>
                  <ContentPaper text={"Uitvoering"} />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item md={4}></Grid>
                <Grid item md={8}>
                  <ContentPaper
                    text={"Artistieke presentatie op balk en vloer"}
                  />
                </Grid>
              </Grid>
            </Container>
          </Paper>
        </Grid>
      </Grid>
      <Grid container marginLeft={2}>
        <Grid item>
          <Content
            text={
              "*C, D, E, F, G en H elementen mogen geturnd worden voor MW, VW en SE, maar worden geteld als B elementen"
            }
          />
        </Grid>
      </Grid>
    </>
  );
};
