import React, { useState, useEffect } from "react";
import { collection, query, onSnapshot, orderBy } from "firebase/firestore";
import {
  Grid,
  Container,
  Typography,
  Paper,
  Button,
  ButtonGroup,
} from "@mui/material";
import { useAuthContext } from "../../../../hooks/useAuthContext";
import { useParams } from "react-router-dom";
import { db } from "../../../../firebase/config";
import { PgColors } from "../../../../configs/colors";
import { BarsSkills } from "./BarsSkills";
import { VaultSkills } from "./VaultSkills";
import { BeamSkills } from "./BeamSkills";
import { FloorSkills } from "./FloorSkills";
import { HBarSkills } from "./HBarSkills";

export const SkillsPage = () => {
  const [active, setActive] = useState("Vault");
  const activeColor = PgColors.bluepg[400];

  return (
    <>
      <Grid container mt={2} sx={{ display: { xs: "none", md: "flex" } }}>
        <Grid item xs={12} display="flex" justifyContent="center">
          <ButtonGroup variant="contained">
            <Button
              style={{
                backgroundColor:
                  active === "Vault" ? activeColor : PgColors.bluepg[200],
              }}
              variant="contained"
              onClick={() => setActive("Vault")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 12, md: 14 } }}
              >
                Sprong
              </Typography>
            </Button>
            <Button
              style={{
                backgroundColor:
                  active === "Bars" ? activeColor : PgColors.bluepg[200],
              }}
              variant="contained"
              onClick={() => setActive("Bars")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 12, md: 14 } }}
              >
                Brug
              </Typography>
            </Button>
            <Button
              style={{
                backgroundColor:
                  active === "Beam" ? activeColor : PgColors.bluepg[200],
              }}
              variant="contained"
              onClick={() => setActive("Beam")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 12, md: 14 } }}
              >
                Balk
              </Typography>
            </Button>
            <Button
              style={{
                backgroundColor:
                  active === "Floor" ? activeColor : PgColors.bluepg[200],
              }}
              variant="contained"
              onClick={() => setActive("Floor")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 12, md: 14 } }}
              >
                Vloer
              </Typography>
            </Button>
            <Button
              style={{
                backgroundColor:
                  active === "Voltige" ? activeColor : PgColors.bluepg[200],
              }}
              variant="contained"
              onClick={() => setActive("Voltige")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 12, md: 14 } }}
              >
                Voltige
              </Typography>
            </Button>
            <Button
              style={{
                backgroundColor:
                  active === "Ringen" ? activeColor : PgColors.bluepg[200],
              }}
              variant="contained"
              onClick={() => setActive("Ringen")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 12, md: 14 } }}
              >
                Ringen
              </Typography>
            </Button>
            <Button
              style={{
                backgroundColor:
                  active === "HBrug" ? activeColor : PgColors.bluepg[200],
              }}
              variant="contained"
              onClick={() => setActive("HBrug")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 12, md: 14 } }}
              >
                HBrug
              </Typography>
            </Button>
            <Button
              style={{
                backgroundColor:
                  active === "Rek" ? activeColor : PgColors.bluepg[200],
              }}
              variant="contained"
              onClick={() => setActive("Rek")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 12, md: 14 } }}
              >
                Rek
              </Typography>
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid>
      <Grid
        container
        mt={2}
        sx={{ display: { xs: "none", sm: "flex", md: "none" } }}
      >
        <Grid item xs={12} display="flex" justifyContent="center">
          <ButtonGroup variant="contained">
            <Button
              style={{
                backgroundColor:
                  active === "Vault" ? activeColor : PgColors.bluepg[200],
              }}
              variant="contained"
              onClick={() => setActive("Vault")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 12, md: 14 } }}
              >
                VT
              </Typography>
            </Button>
            <Button
              style={{
                backgroundColor:
                  active === "Bars" ? activeColor : PgColors.bluepg[200],
              }}
              variant="contained"
              onClick={() => setActive("Bars")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 12, md: 14 } }}
              >
                UB
              </Typography>
            </Button>
            <Button
              style={{
                backgroundColor:
                  active === "Beam" ? activeColor : PgColors.bluepg[200],
              }}
              variant="contained"
              onClick={() => setActive("Beam")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 12, md: 14 } }}
              >
                BM
              </Typography>
            </Button>
            <Button
              style={{
                backgroundColor:
                  active === "Floor" ? activeColor : PgColors.bluepg[200],
              }}
              variant="contained"
              onClick={() => setActive("Floor")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 12, md: 14 } }}
              >
                FX
              </Typography>
            </Button>
            <Button
              style={{
                backgroundColor:
                  active === "Voltige" ? activeColor : PgColors.bluepg[200],
              }}
              variant="contained"
              onClick={() => setActive("Voltige")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 12, md: 14 } }}
              >
                PH
              </Typography>
            </Button>
            <Button
              style={{
                backgroundColor:
                  active === "Ringen" ? activeColor : PgColors.bluepg[200],
              }}
              variant="contained"
              onClick={() => setActive("Ringen")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 12, md: 14 } }}
              >
                SR
              </Typography>
            </Button>
            <Button
              style={{
                backgroundColor:
                  active === "HBrug" ? activeColor : PgColors.bluepg[200],
              }}
              variant="contained"
              onClick={() => setActive("HBrug")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 12, md: 14 } }}
              >
                PB
              </Typography>
            </Button>
            <Button
              style={{
                backgroundColor:
                  active === "Rek" ? activeColor : PgColors.bluepg[200],
              }}
              variant="contained"
              onClick={() => setActive("Rek")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 12, md: 14 } }}
              >
                HB
              </Typography>
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid>
      <Grid
        container
        mt={2}
        sx={{ display: { xs: "flex", sm: "none", md: "none" } }}
      >
        <Grid item xs={12} display="flex" justifyContent="center">
          <ButtonGroup variant="contained">
            <Button
              style={{
                backgroundColor:
                  active === "Vault" ? activeColor : PgColors.bluepg[200],
              }}
              variant="contained"
              onClick={() => setActive("Vault")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 12, md: 14 } }}
              >
                VT
              </Typography>
            </Button>
            <Button
              style={{
                backgroundColor:
                  active === "Bars" ? activeColor : PgColors.bluepg[200],
              }}
              variant="contained"
              onClick={() => setActive("Bars")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 12, md: 14 } }}
              >
                UB
              </Typography>
            </Button>
            <Button
              style={{
                backgroundColor:
                  active === "Beam" ? activeColor : PgColors.bluepg[200],
              }}
              variant="contained"
              onClick={() => setActive("Beam")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 12, md: 14 } }}
              >
                BM
              </Typography>
            </Button>
            <Button
              style={{
                backgroundColor:
                  active === "Floor" ? activeColor : PgColors.bluepg[200],
              }}
              variant="contained"
              onClick={() => setActive("Floor")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 12, md: 14 } }}
              >
                FX
              </Typography>
            </Button>
          </ButtonGroup>
        </Grid>
        <Grid item xs={12} display="flex" justifyContent="center" mt={1}>
          <ButtonGroup variant="contained">
            <Button
              style={{
                backgroundColor:
                  active === "Voltige" ? activeColor : PgColors.bluepg[200],
              }}
              variant="contained"
              onClick={() => setActive("Voltige")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 12, md: 14 } }}
              >
                PH
              </Typography>
            </Button>
            <Button
              style={{
                backgroundColor:
                  active === "Ringen" ? activeColor : PgColors.bluepg[200],
              }}
              variant="contained"
              onClick={() => setActive("Ringen")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 12, md: 14 } }}
              >
                SR
              </Typography>
            </Button>
            <Button
              style={{
                backgroundColor:
                  active === "HBrug" ? activeColor : PgColors.bluepg[200],
              }}
              variant="contained"
              onClick={() => setActive("HBrug")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 12, md: 14 } }}
              >
                PB
              </Typography>
            </Button>
            <Button
              style={{
                backgroundColor:
                  active === "Rek" ? activeColor : PgColors.bluepg[200],
              }}
              variant="contained"
              onClick={() => setActive("Rek")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 12, md: 14 } }}
              >
                HB
              </Typography>
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid>

      <Grid container>{active === "Bars" && <BarsSkills />}</Grid>
      <Grid container>{active === "Vault" && <VaultSkills />}</Grid>
      <Grid container>{active === "Beam" && <BeamSkills />}</Grid>
      <Grid container>{active === "Floor" && <FloorSkills />}</Grid>
      <Grid container>{active === "Rek" && <HBarSkills />}</Grid>
    </>
  );
};
