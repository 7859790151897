import React, { useState, useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import {
  Grid,
  AppBar,
  Container,
  Typography,
  Paper,
  TextField,
  Button,
  Divider,
  Toolbar,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Box,
  Drawer,
  Collapse,
} from "@mui/material";
import { NavLink, Link } from "react-router-dom";
import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import IconButton from "@mui/material/IconButton";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useLogout } from "../../hooks/useLogout";
import { UserList } from "./UserList";
import LibraryBooksOutlinedIcon from "@mui/icons-material/LibraryBooksOutlined";
import SportsMmaOutlinedIcon from "@mui/icons-material/SportsMmaOutlined";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import TrendingUpOutlinedIcon from "@mui/icons-material/TrendingUpOutlined";
import { PgColors } from "../../configs/colors";
import { auth } from "../../firebase/config";
import { getIdTokenResult, onAuthStateChanged } from "firebase/auth";
import { AdminHeader } from "./AdminHeader";
import { UserHeader } from "./UserHeader";

export const Header = () => {
  const { logout } = useLogout();
  // const { user, adminUser } = useAuthContext();
  const { authIsReady, user } = useAuthContext();
  const [isAdmin, setIsAdmin] = useState(false);

  onAuthStateChanged(auth, (user) => {
    if (user) {
      getIdTokenResult(user, true).then((idTokenResult) => {
        setIsAdmin(idTokenResult.claims.admin);
      });
    }
  });

  if (!authIsReady) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <Grid container>
        <Grid item display={{ xs: "flex", lg: "none" }}>
          {isAdmin ? <AdminHeader /> : <UserHeader />}
        </Grid>
      </Grid>
    </>
  );
};
