import React, { useEffect, useState } from "react";
import { db } from "../../firebase/config";
import {
  collection,
  orderBy,
  addDoc,
  serverTimestamp,
  query,
  onSnapshot,
  updateDoc,
  doc,
  setDoc,
  deleteDoc,
} from "firebase/firestore";
import {
  Grid,
  IconButton,
  Container,
  Typography,
  Paper,
  TextField,
  Button,
  AppBar,
  Toolbar,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Box,
  Drawer,
} from "@mui/material";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { useAuthContext } from "../../hooks/useAuthContext";
import AddIcon from "@mui/icons-material/Add";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { PgColors } from "../../configs/colors";

export const Comments = ({ path1, path2 }: any) => {
  const [comments, setComments] = useState([]);
  const [name, setName] = useState("");
  const [comment, setComment] = useState("");
  const { user } = useAuthContext();

  // const handleUpdate = async (
  //   e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  // ) => {
  //   e.preventDefault();

  //   await setDoc(doc(db, path1, user.uid), {
  //     name: name,
  //     comment: comment,
  //     uid: user.uid,
  //     createdAt: serverTimestamp(),
  //   });
  //   setName("");
  //   setComment("");
  // };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const ref = collection(db, path1);

    await addDoc(ref, {
      name: name,
      comment: comment,
      uid: user.uid,
      createdAt: serverTimestamp(),
    });
    setName("");
    setComment("");
  };

  useEffect(() => {
    const ref = query(collection(db, path1), orderBy("createdAt", "desc"));

    const unsub = onSnapshot(ref, (snapshot) => {
      let results: any = [];
      snapshot.docs.forEach((doc) => {
        results.push({ ...doc.data(), id: doc.id });
      });
      setComments(results);
    });
    return () => unsub();
  }, []);

  const handleDelete = async (comment: any) => {
    const ref = doc(db, `${path1}/${comment.id}`);
    await deleteDoc(ref);
  };

  return (
    <>
      <Container>
        <form onSubmit={handleSubmit}>
          <Grid container sx={{ display: { xs: "none", md: "flex" } }}>
            <Grid item xs={3}>
              <TextField
                InputLabelProps={{
                  sx: {
                    fontSize: { xs: 10, md: 14, lg: 16 },
                  },
                }}
                label="Name"
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                fullWidth
                size="small"
                value={name}
                onChange={(event) => setName(event.target.value)}
              />
            </Grid>
            <Grid item xs={8}>
              <TextField
                label="Comment"
                InputLabelProps={{
                  sx: {
                    fontSize: { xs: 10, md: 14, lg: 16 },
                  },
                }}
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                fullWidth
                size="small"
                value={comment}
                onChange={(event) => setComment(event.target.value)}
              />
            </Grid>
            <Grid item xs={1}>
              <Button
                variant="contained"
                size="large"
                type="submit"
                sx={{
                  height: 40,
                  backgroundColor: PgColors.bluepg[200],
                }}
              >
                <AddIcon />
              </Button>
            </Grid>
          </Grid>
          <Grid container sx={{ display: { xs: "flex", md: "none" } }} mb={1}>
            <Grid item xs={11}>
              <TextField
                label="Name"
                InputLabelProps={{
                  sx: {
                    fontSize: { xs: 10, md: 14, lg: 16 },
                  },
                }}
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                size="small"
                fullWidth
                value={name}
                onChange={(event) => setName(event.target.value)}
              />
            </Grid>
            <Grid item xs={11}>
              <TextField
                label="Comment"
                InputLabelProps={{
                  sx: {
                    fontSize: { xs: 10, md: 14, lg: 16 },
                  },
                }}
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                multiline
                rows={3}
                fullWidth
                size="small"
                value={comment}
                onChange={(event) => setComment(event.target.value)}
              />
            </Grid>
            <Grid item xs={1} alignContent="end">
              <IconButton
                type="submit"
                sx={{
                  height: 30,
                  widht: 30,
                  color: PgColors.bluepg[200],
                }}
              >
                <AddIcon />
              </IconButton>
            </Grid>
          </Grid>
        </form>
      </Container>
      <Grid container>
        {comments.map((comment: any) => (
          <Grid item key={comment.id} xs={12}>
            <Paper
              style={{
                width: "100%",
                backgroundColor: PgColors.bluepg[100],
              }}
            >
              <Grid container>
                <Grid item xs={3} ml={3}>
                  <Typography
                    variant="subtitle1"
                    style={{
                      color: "white",
                    }}
                    sx={{
                      fontSize: { xs: 12, md: 16 },
                    }}
                  >
                    {comment.name}
                  </Typography>
                </Grid>
                <Grid item xs={7} sx={{ ml: { xs: -2, sm: 0 } }}>
                  <Typography
                    variant="subtitle1"
                    style={{
                      color: "white",
                      whiteSpace: "pre-line",
                    }}
                    sx={{
                      fontSize: { xs: 12, md: 16 },
                    }}
                  >
                    {comment.comment}
                  </Typography>
                </Grid>
                <Grid item xs={1} sx={{ mr: { xs: 2, sm: 0 }, ml: { md: 5 } }}>
                  <IconButton
                    sx={{
                      color: PgColors.bluepg[200],
                    }}
                    onClick={() => handleDelete(comment)}
                  >
                    <DeleteOutlinedIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </>
  );
};
