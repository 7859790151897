import React, { useEffect, useState } from "react";
import {
  Grid,
  Container,
  Typography,
  Paper,
  TextField,
  Button,
  IconButton,
  AppBar,
  Toolbar,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Box,
  Drawer,
} from "@mui/material";
import {
  collection,
  orderBy,
  addDoc,
  serverTimestamp,
  query,
  onSnapshot,
  updateDoc,
  doc,
  deleteDoc,
} from "firebase/firestore";
import { useAuthContext } from "../../hooks/useAuthContext";
import { db } from "../../firebase/config";
import { PgColors } from "../../configs/colors";
import { Likes } from "./Likes";
import { LikesG } from "./LikesG";
import { info } from "console";
import { Comments } from "./Comments";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { CommentsG } from "./CommentsG";

export const Feed = ({ path, gymnast }: any) => {
  const [infoList, setInfoList] = useState([]);
  const [infoGList, setInfoGList] = useState([]);
  const [comment, setComment] = useState([]);
  const [name, setName] = useState([]);
  const { user } = useAuthContext();

  useEffect(() => {
    const ref = query(collection(db, path), orderBy("createdAt", "desc"));

    const unsub = onSnapshot(ref, (snapshot) => {
      let results: any = [];
      snapshot.docs.forEach((doc) => {
        results.push({ ...doc.data(), id: doc.id });
      });
      setInfoList(results);
    });
    return () => unsub();
  }, []);

  useEffect(() => {
    if (gymnast.coachId) {
      const ref = query(
        collection(db, `InfoByCoach/${gymnast.coachId}/Infos`),
        orderBy("createdAt", "desc")
      );

      const unsub = onSnapshot(ref, (snapshot) => {
        let results: any = [];
        snapshot.docs.forEach((doc) => {
          results.push({ ...doc.data(), id: doc.id });
        });
        setInfoGList(results);
      });

      return () => unsub();
    }
  }, [gymnast.coachId]);

  const handleDelete = async (post: any) => {
    const ref = doc(db, `InfoByCoach/${user.uid}/Infos/${post.id}`);
    await deleteDoc(ref);
  };

  return (
    <>
      {infoList.map((info: any) => (
        <Container>
          <Paper elevation={3} sx={{ marginLeft: { lg: 36 } }}>
            <Grid
              container
              key={info.id}
              id={info.id}
              mt={2}
              mb={2}
              alignItems="center"
            >
              <Grid item xs={12} md={5} ml={2}>
                <Typography
                  variant="h6"
                  style={{
                    color: "#2F5E6D",
                    fontSize: 20,
                    fontWeight: 450,
                    whiteSpace: "pre-line",
                  }}
                >
                  {info.writer}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} ml={2}>
                <Typography
                  variant="h6"
                  style={{
                    color: "#2F5E6D",
                    fontSize: 20,
                    fontWeight: 450,
                    whiteSpace: "pre-line",
                  }}
                >
                  {info.title}
                </Typography>
              </Grid>
              <Paper
                style={{ width: "100%", backgroundColor: PgColors.bluepg[200] }}
              >
                <Grid item xs={12} ml={2}>
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white", whiteSpace: "pre-line" }}
                  >
                    {info.content}
                  </Typography>
                </Grid>
              </Paper>

              <Likes
                path1={`InfoByCoach/${user.uid}/Infos/${info.id}/Likes`}
                path2={`InfoByCoach/${gymnast.coachId}/Infos/${info.id}/Likes`}
              />
              <Grid item xs={1}>
                <IconButton
                  sx={{
                    color: PgColors.bluepg[200],
                  }}
                  onClick={() => handleDelete(info)}
                >
                  <DeleteOutlinedIcon />
                </IconButton>
              </Grid>

              <Comments
                path1={`InfoByCoach/${user.uid}/Infos/${info.id}/Comments`}
                path2={`InfoByCoach/${gymnast.coachId}/Infos/${info.id}/Comments`}
              />
            </Grid>
          </Paper>
        </Container>
      ))}
      {infoGList.map((info: any) => (
        <Container>
          <Paper elevation={3} sx={{ marginLeft: { lg: 36 } }}>
            <Grid
              container
              key={info.id}
              id={info.id}
              mt={2}
              mb={2}
              alignItems="center"
            >
              <Grid item xs={12} md={5} ml={2}>
                <Typography
                  variant="h6"
                  style={{
                    color: PgColors.bluepg[200],
                    fontSize: 20,
                    fontWeight: 450,
                    whiteSpace: "pre-line",
                  }}
                >
                  {info.writer}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} ml={2}>
                <Typography
                  variant="h6"
                  style={{
                    color: PgColors.bluepg[200],
                    fontSize: 20,
                    fontWeight: 450,
                    whiteSpace: "pre-line",
                  }}
                >
                  {info.title}
                </Typography>
              </Grid>
              <Paper
                style={{
                  width: "100%",
                  backgroundColor: PgColors.bluepg[200],
                }}
              >
                <Grid item xs={12} ml={2}>
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white", whiteSpace: "pre-line" }}
                  >
                    {info.content}
                  </Typography>
                </Grid>
              </Paper>
              <LikesG
                path1={`InfoByCoach/${user.uid}/Infos/${info.id}/Likes`}
                path2={`InfoByCoach/${gymnast.coachId}/Infos/${info.id}/Likes`}
              />
              <CommentsG
                path1={`InfoByCoach/${user.uid}/Infos/${info.id}/Comments`}
                path2={`InfoByCoach/${gymnast.coachId}/Infos/${info.id}/Comments`}
              />
            </Grid>
          </Paper>
        </Container>
      ))}
    </>
  );
};
