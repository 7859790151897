import React, { useState } from "react";
import {
  collection,
  addDoc,
  serverTimestamp,
  doc,
  deleteDoc,
  setDoc,
} from "firebase/firestore";
import { db } from "../../../../firebase/config";
import {
  Container,
  Grid,
  Typography,
  Paper,
  TextField,
  Button,
  Modal,
  Toolbar,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Box,
  Drawer,
} from "@mui/material";
import { useAuthContext } from "../../../../hooks/useAuthContext";
import { useParams } from "react-router-dom";
import { PgColors } from "../../../../configs/colors";

export const AddExercise2C1 = () => {
  const [answer, setAnswer] = useState("");
  const { user } = useAuthContext();
  const { id } = useParams();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const ref = collection(db, `IntroExercises/${id}/UserExercise2C1`);

    await addDoc(ref, {
      answer: answer,
      uid: user.uid,
      createdAt: serverTimestamp(),
    });
    setAnswer("");
  };

  return (
    <>
      <Container>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} alignItems="flex-end">
            <Grid item xs={12} mt={2}>
              <Typography
                variant="subtitle1"
                style={{ color: PgColors.bluepg[400] }}
                sx={{
                  fontSize: { xs: 10, md: 14, lg: 18 },
                  fontWeight: { md: 400 },
                }}
                align="left"
              >
                Prestatie doel 1:
              </Typography>
            </Grid>
            <Grid item md={10} xs={12} sx={{ mt: { xs: -1, lg: -2 } }}>
              <TextField
                InputLabelProps={{
                  sx: {
                    fontSize: { xs: 10, md: 14, lg: 16 },
                  },
                }}
                size="small"
                label="Bijv: Vloeroefening turnen met een D-score van 3.90 of hoger op de 2e VW"
                color="warning"
                variant="outlined"
                fullWidth
                multiline
                rows={2}
                value={answer}
                onChange={(event) => setAnswer(event.target.value)}
              />
            </Grid>

            <Grid item md={2} xs={12}>
              <Button
                style={{
                  backgroundColor: PgColors.bluepg[200],
                }}
                variant="contained"
                type="submit"
                size="large"
                sx={{ height: { xs: 30, md: 55, lg: 55 } }}
              >
                <Typography variant="subtitle1">Voeg Toe</Typography>
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
      s
    </>
  );
};

export const AddExercise2C2 = () => {
  const [answer, setAnswer] = useState("");
  const { user } = useAuthContext();
  const { id } = useParams();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const ref = collection(db, `IntroExercises/${id}/UserExercise2C2`);

    await addDoc(ref, {
      answer: answer,
      uid: user.uid,
      createdAt: serverTimestamp(),
    });
    setAnswer("");
  };

  return (
    <>
      <Container>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} alignItems="flex-end">
            <Grid item xs={12} mt={2}>
              <Typography
                variant="subtitle1"
                style={{ color: PgColors.bluepg[400] }}
                sx={{
                  fontSize: { xs: 10, md: 14, lg: 18 },
                  fontWeight: { md: 400 },
                }}
                align="left"
              >
                Prestatie doel 2:
              </Typography>
            </Grid>
            <Grid item md={10} xs={12} sx={{ mt: { xs: -1, lg: -2 } }}>
              <TextField
                InputLabelProps={{
                  sx: {
                    fontSize: { xs: 10, md: 14, lg: 16 },
                  },
                }}
                size="small"
                label="Bijv: Brug oefening turnen zonder stopjes op de 1e VW"
                color="warning"
                variant="outlined"
                fullWidth
                multiline
                rows={2}
                value={answer}
                onChange={(event) => setAnswer(event.target.value)}
              />
            </Grid>

            <Grid item md={2} xs={12}>
              <Button
                style={{
                  backgroundColor: PgColors.bluepg[200],
                }}
                variant="contained"
                type="submit"
                size="large"
                sx={{ height: { xs: 30, md: 55, lg: 55 } }}
              >
                <Typography variant="subtitle1">Voeg Toe</Typography>
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </>
  );
};

export const AddExercise2C3 = () => {
  const [answer, setAnswer] = useState("");
  const { user } = useAuthContext();
  const { id } = useParams();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const ref = collection(db, `IntroExercises/${id}/UserExercise2C3`);

    await addDoc(ref, {
      answer: answer,
      uid: user.uid,
      createdAt: serverTimestamp(),
    });
    setAnswer("");
  };

  return (
    <>
      <Container>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} alignItems="flex-end">
            <Grid item xs={12} mt={2}>
              <Typography
                variant="subtitle1"
                style={{ color: PgColors.bluepg[400] }}
                sx={{
                  fontSize: { xs: 10, md: 14, lg: 18 },
                  fontWeight: { md: 400 },
                }}
                align="left"
              >
                Prestatie doel 3:
              </Typography>
            </Grid>
            <Grid item md={10} xs={12} sx={{ mt: { xs: -1, lg: -2 } }}>
              <TextField
                InputLabelProps={{
                  sx: {
                    fontSize: { xs: 10, md: 14, lg: 16 },
                  },
                }}
                size="small"
                label="Bijv: Arabier turnen op de wedstrijd balk voor de 1e VW"
                color="warning"
                variant="outlined"
                fullWidth
                multiline
                rows={2}
                value={answer}
                onChange={(event) => setAnswer(event.target.value)}
              />
            </Grid>

            <Grid item md={2} xs={12}>
              <Button
                style={{
                  backgroundColor: PgColors.bluepg[200],
                }}
                variant="contained"
                type="submit"
                size="large"
                sx={{ height: { xs: 30, md: 55, lg: 55 } }}
              >
                <Typography variant="subtitle1">Voeg Toe</Typography>
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </>
  );
};
