import React, { useState } from "react";
import {
  Grid,
  Container,
  Typography,
  Paper,
  TextField,
  Button,
  AppBar,
  Toolbar,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Box,
  Drawer,
} from "@mui/material";
import { AddProgram } from "./AddProgram";
import { AllPrograms } from "./AllPrograms";
import { ProgramComponent } from "./ProgramDetails";
import { PgColors } from "../../configs/colors";
import { useAuthContext } from "../../hooks/useAuthContext";
import { getIdTokenResult, onAuthStateChanged } from "firebase/auth";
import { auth } from "../../firebase/config";

export const Programs = () => {
  const [active, setActive] = useState("All");
  const { authIsReady, user } = useAuthContext();
  const [isAdmin, setIsAdmin] = useState(false);

  onAuthStateChanged(auth, (user) => {
    if (user) {
      getIdTokenResult(user, true).then((idTokenResult) => {
        setIsAdmin(idTokenResult.claims.admin);
      });
    }
  });

  if (!authIsReady) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <Container>
        <Grid
          container
          display="flex"
          sx={{
            justifyContent: { xs: "center", md: "flex-end" },
            marginTop: { xs: 2, sm: 4, lg: 6 },
          }}
          spacing={2}
        >
          {isAdmin && (
            <Grid item display="flex" xs={4} md={4}>
              <Button
                style={{
                  backgroundColor: PgColors.bluepg[400],
                }}
                variant="contained"
                onClick={() => setActive("Add")}
              >
                <Typography style={{ fontWeight: "semibold" }}>
                  ADD PROGRAM
                </Typography>
              </Button>
            </Grid>
          )}

          <Grid item display="flex" xs={4} md={4}>
            <Button
              style={{
                backgroundColor: PgColors.bluepg[400],
              }}
              variant="contained"
              onClick={() => setActive("All")}
            >
              <Typography style={{ fontWeight: "semibold" }}>
                All Programs
              </Typography>
            </Button>
          </Grid>
        </Grid>
        <Grid
          item
          display="flex"
          justifyContent="center"
          xs={12}
          marginTop={4}
          marginBottom={4}
          sx={{ marginLeft: { lg: 36 } }}
        >
          <Paper
            elevation={2}
            sx={{
              minWidth: { xs: 350, sm: 600, md: 880, lg: 850, xl: 1200 },
              maxWidth: { xs: 590, sm: 890, md: 1100 },
              minHeight: { xs: 850 },
            }}
          >
            {active === "Add" && <AddProgram />}
            {active === "All" && <AllPrograms />}
          </Paper>
        </Grid>
      </Container>
    </>
  );
};
