import React, { useState, useEffect } from "react";
import {
  collection,
  query,
  onSnapshot,
  updateDoc,
  getDoc,
  doc,
  deleteDoc,
} from "firebase/firestore";
import { db } from "../../firebase/config";
import {
  Grid,
  Container,
  Typography,
  Divider,
  Button,
  Box,
  Paper,
} from "@mui/material";
import { ProgramType } from "../../types/types";
import { NavLink, Link } from "react-router-dom";
import { ProgramPDF } from "./ProgramPDF";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { useNavigate } from "react-router-dom";
import { PgColors } from "../../configs/colors";
import { EditProgram } from "./EditProgram";
import { useAuthContext } from "../../hooks/useAuthContext";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { getIdTokenResult, onAuthStateChanged } from "firebase/auth";
import { auth } from "../../firebase/config";

export const AdminProgram = ({ program, programG }: any) => {
  const navigate = useNavigate();
  const { authIsReady, user } = useAuthContext();
  const [isAdmin, setIsAdmin] = useState(false);

  const handleDelete = async (dScore: any) => {
    const ref = doc(db, `ProgramsByCoach/${user.uid}/Programs/${program.id}`);
    await deleteDoc(ref);
    navigate("/dashboard/programs");
  };

  return (
    <>
      <Container disableGutters={true}>
        <Box sx={{ width: "100%" }}>
          <Grid
            container
            justifyContent="start"
            sx={{ marginTop: { xs: 2, lg: 4 }, marginLeft: { lg: 36 } }}
            spacing={2}
          >
            <Grid item>
              <Button
                style={{
                  backgroundColor: PgColors.bluepg[400],
                }}
                variant="contained"
                onClick={(e) => navigate("/dashboard/programs")}
              >
                All Programs
              </Button>
            </Grid>
          </Grid>
          <Grid
            container
            marginTop={4}
            marginBottom={8}
            sx={{ marginLeft: { lg: 16 } }}
            display="flex"
            justifyContent="center"
          >
            <Paper
              elevation={2}
              sx={{
                minWidth: { xs: 350, sm: 600, md: 880, lg: 850, xl: 1200 },
                maxWidth: { xs: 590, sm: 890, md: 1100 },
                minHeight: 850,
              }}
            >
              <Container>
                <Grid container>
                  <Grid container mt={2}>
                    <Grid item xs={6} sm={8} md={10}></Grid>
                    <Grid item xs={3} sm={2} md={1}>
                      <EditProgram
                        path={`ProgramsByCoach/${user.uid}/Programs/${program.id}`}
                      />
                    </Grid>
                    <Grid item xs={3} sm={2} md={1}>
                      <Button
                        variant="contained"
                        style={{
                          backgroundColor: PgColors.redpg[200],
                        }}
                        size="large"
                        onClick={() => handleDelete(program)}
                        sx={{ width: 75, height: 35 }}
                      >
                        <DeleteOutlinedIcon />
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid item marginTop={6}>
                    <Typography
                      variant="h6"
                      sx={{ fontSize: { xs: 14, md: 20 } }}
                      style={{ color: PgColors.redpg[200], fontWeight: "bold" }}
                    >
                      Program Name
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item md={12}>
                    <Typography
                      variant="subtitle1"
                      style={{
                        color: PgColors.bluepg[200],

                        fontWeight: 450,
                      }}
                      sx={{ fontSize: { xs: 14, md: 20 } }}
                    >
                      {program.name}
                      {programG.name}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item marginTop={4}>
                    <Typography
                      variant="h6"
                      style={{ color: PgColors.redpg[200], fontWeight: "bold" }}
                      sx={{ fontSize: { xs: 14, md: 20 } }}
                    >
                      Apparatus
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item md={12}>
                    <Typography
                      variant="subtitle1"
                      style={{
                        color: PgColors.bluepg[200],

                        fontWeight: 450,
                      }}
                      sx={{ fontSize: { xs: 14, md: 20 } }}
                    >
                      {program.apparatus}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item marginTop={4}>
                    <Typography
                      variant="h6"
                      style={{ color: PgColors.redpg[200], fontWeight: "bold" }}
                      sx={{ fontSize: { xs: 14, md: 20 } }}
                    >
                      Program
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item md={12} mb={4}>
                    <Typography
                      variant="subtitle1"
                      style={{
                        color: PgColors.bluepg[200],

                        fontWeight: 450,
                        whiteSpace: "pre-line",
                      }}
                      sx={{ fontSize: { xs: 14, md: 20 } }}
                    >
                      {program.program}
                    </Typography>
                  </Grid>
                </Grid>
              </Container>
            </Paper>
          </Grid>
        </Box>
      </Container>
    </>
  );
};
