import React, { useState, useEffect } from "react";
import {
  collection,
  query,
  onSnapshot,
  updateDoc,
  getDoc,
  doc,
  deleteDoc,
} from "firebase/firestore";
import { db } from "../../firebase/config";
import { Grid, Container, Typography, Divider, Button } from "@mui/material";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { LessonType } from "../../types/types";
import { Lesson } from "./Lesson";

// import { Program } from "./Program";

export const LessonComponent = () => {
  const { user } = useAuthContext();
  const [lessonsList, setLessonsList] = useState([]);
  const [lesson, setLesson] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    const fetchLessonData = async () => {
      setIsLoading(true);
      const docSnap = await getDoc(
        doc(db, `LessonsByCoach/${user.uid}/Lessons/${id}`)
      );
      if (docSnap.exists()) {
        const newLessonObj = {
          id: docSnap.id,
          ...docSnap.data(),
        };
        setLesson(newLessonObj);
        setIsLoading(false);
      } else {
        //doc.data() will be undefined in this case
        console.log("No such document");
      }
    };
    fetchLessonData();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Container>
        {Object.keys(lesson) && Object.keys(lesson).length ? (
          //   <Program program={program} />
          <Lesson lesson={lesson} />
        ) : null}
      </Container>
    </>
  );
};
