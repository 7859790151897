import React, { useState, useEffect } from "react";
import { collection, query, onSnapshot, orderBy } from "firebase/firestore";
import {
  Grid,
  Container,
  Typography,
  Paper,
  Button,
  Box,
  CardMedia,
} from "@mui/material";
import { useAuthContext } from "../../../../hooks/useAuthContext";
import { useParams } from "react-router-dom";
import { db } from "../../../../firebase/config";
import { PgColors } from "../../../../configs/colors";
import { AddExercise2A, AddExercise2B } from "./AddExercise2";
import { ListExercise2A } from "./ListExercise2A";
import { ListExercise2B } from "./ListExercise2B";
import {
  AddExercise2C1,
  AddExercise2C2,
  AddExercise2C3,
} from "./AddExercise2C";
import {
  ListExercise2C1,
  ListExercise2C2,
  ListExercise2C3,
} from "./ListExercise2C";
import {
  AddExercise2D1A,
  AddExercise2D1B,
  AddExercise2D1C,
  AddExercise2D2A,
  AddExercise2D2B,
  AddExercise2D2C,
  AddExercise2D3A,
  AddExercise2D3B,
  AddExercise2D3C,
} from "./AddExercise2D";
import {
  ListExercise2D1A,
  ListExercise2D1B,
  ListExercise2D1C,
  ListExercise2D2A,
  ListExercise2D2B,
  ListExercise2D2C,
  ListExercise2D3A,
  ListExercise2D3B,
  ListExercise2D3C,
} from "./ListExercise2D";

export const GoalsPage = () => {
  const { authIsReady, user } = useAuthContext();
  const [exercise2AList, setExercise2AList] = useState([]);
  const [exercise2BList, setExercise2BList] = useState([]);
  const [exercise2C1List, setExercise2C1List] = useState([]);
  const [exercise2C2List, setExercise2C2List] = useState([]);
  const [exercise2C3List, setExercise2C3List] = useState([]);
  const [exercise2D1AList, setExercise2D1AList] = useState([]);
  const [exercise2D1BList, setExercise2D1BList] = useState([]);
  const [exercise2D1CList, setExercise2D1CList] = useState([]);
  const [exercise2D2AList, setExercise2D2AList] = useState([]);
  const [exercise2D2BList, setExercise2D2BList] = useState([]);
  const [exercise2D2CList, setExercise2D2CList] = useState([]);
  const [exercise2D3AList, setExercise2D3AList] = useState([]);
  const [exercise2D3BList, setExercise2D3BList] = useState([]);
  const [exercise2D3CList, setExercise2D3CList] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    const ref = query(
      collection(db, `IntroExercises/${id}/UserExercise2A`),
      orderBy("createdAt", "desc")
    );

    const unsub = onSnapshot(ref, (snapshot) => {
      let results: any = [];
      snapshot.docs.forEach((doc) => {
        results.push({ ...doc.data(), id: doc.id });
      });
      setExercise2AList(results);
    });
    return () => unsub();
  }, []);

  useEffect(() => {
    const ref = query(
      collection(db, `IntroExercises/${id}/UserExercise2B`),
      orderBy("createdAt", "desc")
    );

    const unsub = onSnapshot(ref, (snapshot) => {
      let results: any = [];
      snapshot.docs.forEach((doc) => {
        results.push({ ...doc.data(), id: doc.id });
      });
      setExercise2BList(results);
    });
    return () => unsub();
  }, []);

  useEffect(() => {
    const ref = query(
      collection(db, `IntroExercises/${id}/UserExercise2C1`),
      orderBy("createdAt", "desc")
    );

    const unsub = onSnapshot(ref, (snapshot) => {
      let results: any = [];
      snapshot.docs.forEach((doc) => {
        results.push({ ...doc.data(), id: doc.id });
      });
      setExercise2C1List(results);
    });
    return () => unsub();
  }, []);

  useEffect(() => {
    const ref = query(
      collection(db, `IntroExercises/${id}/UserExercise2C2`),
      orderBy("createdAt", "desc")
    );

    const unsub = onSnapshot(ref, (snapshot) => {
      let results: any = [];
      snapshot.docs.forEach((doc) => {
        results.push({ ...doc.data(), id: doc.id });
      });
      setExercise2C2List(results);
    });
    return () => unsub();
  }, []);

  useEffect(() => {
    const ref = query(
      collection(db, `IntroExercises/${id}/UserExercise2C3`),
      orderBy("createdAt", "desc")
    );

    const unsub = onSnapshot(ref, (snapshot) => {
      let results: any = [];
      snapshot.docs.forEach((doc) => {
        results.push({ ...doc.data(), id: doc.id });
      });
      setExercise2C3List(results);
    });
    return () => unsub();
  }, []);

  useEffect(() => {
    const ref = query(
      collection(db, `IntroExercises/${id}/UserExercise2D1A`),
      orderBy("createdAt", "desc")
    );

    const unsub = onSnapshot(ref, (snapshot) => {
      let results: any = [];
      snapshot.docs.forEach((doc) => {
        results.push({ ...doc.data(), id: doc.id });
      });
      setExercise2D1AList(results);
    });
    return () => unsub();
  }, []);

  useEffect(() => {
    const ref = query(
      collection(db, `IntroExercises/${id}/UserExercise2D1B`),
      orderBy("createdAt", "desc")
    );

    const unsub = onSnapshot(ref, (snapshot) => {
      let results: any = [];
      snapshot.docs.forEach((doc) => {
        results.push({ ...doc.data(), id: doc.id });
      });
      setExercise2D1BList(results);
    });
    return () => unsub();
  }, []);

  useEffect(() => {
    const ref = query(
      collection(db, `IntroExercises/${id}/UserExercise2D1C`),
      orderBy("createdAt", "desc")
    );

    const unsub = onSnapshot(ref, (snapshot) => {
      let results: any = [];
      snapshot.docs.forEach((doc) => {
        results.push({ ...doc.data(), id: doc.id });
      });
      setExercise2D1CList(results);
    });
    return () => unsub();
  }, []);

  useEffect(() => {
    const ref = query(
      collection(db, `IntroExercises/${id}/UserExercise2D2A`),
      orderBy("createdAt", "desc")
    );

    const unsub = onSnapshot(ref, (snapshot) => {
      let results: any = [];
      snapshot.docs.forEach((doc) => {
        results.push({ ...doc.data(), id: doc.id });
      });
      setExercise2D2AList(results);
    });
    return () => unsub();
  }, []);

  useEffect(() => {
    const ref = query(
      collection(db, `IntroExercises/${id}/UserExercise2D2B`),
      orderBy("createdAt", "desc")
    );

    const unsub = onSnapshot(ref, (snapshot) => {
      let results: any = [];
      snapshot.docs.forEach((doc) => {
        results.push({ ...doc.data(), id: doc.id });
      });
      setExercise2D2BList(results);
    });
    return () => unsub();
  }, []);

  useEffect(() => {
    const ref = query(
      collection(db, `IntroExercises/${id}/UserExercise2D2C`),
      orderBy("createdAt", "desc")
    );

    const unsub = onSnapshot(ref, (snapshot) => {
      let results: any = [];
      snapshot.docs.forEach((doc) => {
        results.push({ ...doc.data(), id: doc.id });
      });
      setExercise2D2CList(results);
    });
    return () => unsub();
  }, []);

  useEffect(() => {
    const ref = query(
      collection(db, `IntroExercises/${id}/UserExercise2D3A`),
      orderBy("createdAt", "desc")
    );

    const unsub = onSnapshot(ref, (snapshot) => {
      let results: any = [];
      snapshot.docs.forEach((doc) => {
        results.push({ ...doc.data(), id: doc.id });
      });
      setExercise2D3AList(results);
    });
    return () => unsub();
  }, []);

  useEffect(() => {
    const ref = query(
      collection(db, `IntroExercises/${id}/UserExercise2D3B`),
      orderBy("createdAt", "desc")
    );

    const unsub = onSnapshot(ref, (snapshot) => {
      let results: any = [];
      snapshot.docs.forEach((doc) => {
        results.push({ ...doc.data(), id: doc.id });
      });
      setExercise2D3BList(results);
    });
    return () => unsub();
  }, []);

  useEffect(() => {
    const ref = query(
      collection(db, `IntroExercises/${id}/UserExercise2D3C`),
      orderBy("createdAt", "desc")
    );

    const unsub = onSnapshot(ref, (snapshot) => {
      let results: any = [];
      snapshot.docs.forEach((doc) => {
        results.push({ ...doc.data(), id: doc.id });
      });
      setExercise2D3CList(results);
    });
    return () => unsub();
  }, []);

  return (
    <>
      <Grid container mt={2}>
        <Grid item xs={12}>
          <Paper
            style={{ backgroundColor: PgColors.redpg[400] }}
            sx={{ height: { xs: 45, md: 70 } }}
          >
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, sm: 14, md: 16, lg: 20 } }}
              align="center"
            >
              "It's not the will to win that matters - everyone has that. It's
              the will to prepare to win that matters" - Paul "Bear" Bryant
            </Typography>
          </Paper>
        </Grid>
      </Grid>
      <Grid container mt={2}>
        <Grid item xs={12}>
          <Typography
            variant="subtitle1"
            style={{ color: PgColors.bluepg[400] }}
            sx={{
              fontSize: { xs: 16, md: 20, lg: 24 },
              fontWeight: { md: 500 },
            }}
            align="left"
          >
            Soorten Doelen
          </Typography>
        </Grid>

        <Grid item xs={12} mt={1}>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
            }}
            align="left"
          >
            Er zijn drie soorten doelen:
          </Typography>
        </Grid>
        <Grid item xs={12} mt={1}>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
            }}
            align="left"
          >
            - Proces doelen: Dit zijn doelen voor taken of het proces van
            presteren. Bijvoorbeeld: Elke training 5 reuzen aan de losse stok
            turnen. Proces doelen zijn 100% binnen je eigen controle.
          </Typography>
        </Grid>
        <Grid item xs={12} mt={1}>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
            }}
            align="left"
          >
            - Prestatie doelen: Deze doelen focussen op het bereiken van
            prestaties onafhankelijk van anderen. Bijvoorbeeld een D-score van
            3.80 halen voor je vloeroefening tijdens de 1e voorwedstrijd. Dit
            soort doelen heb je deels zelf controle over.
          </Typography>
        </Grid>
        <Grid item xs={12} mt={1}>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
            }}
            align="left"
          >
            - Resultaat doelen: Met deze doelen leg je focus op het resultaat.
            Zoals winnen of verliezen, beter presteren dan een ander,
            doorstromen naar een finale, etc. Je hebt weinig controle over deze
            doelen omdat het ook afhankelijk is van de prestaties van anderen.
          </Typography>
        </Grid>
      </Grid>
      <Grid container mt={2}>
        <Grid item xs={12} mt={1}>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
            }}
            align="left"
          >
            Proces, prestatie en resultaatdoelen zijn met elkaar verbonden.
            Wanneer je eerst je proces doelen behaalt is de kans groter dat je
            ook je prestatie doelen haalt. Wanneer je dan je prestatie doelen
            haalt, is de kans weer groter dat je je resultaat doelen haalt.
          </Typography>
        </Grid>
      </Grid>
      <Grid container mt={2}>
        <Grid
          item
          mt={0}
          xs={12}
          md={12}
          display="flex"
          justifyContent="center"
        >
          <Box sx={{ display: "flex" }}>
            <CardMedia
              // sx={{
              //   maxWidth: { xs: 150, sm: 150, md: 200, lg: 200 },
              // }}
              component="img"
              image="/images/TypeOfGoals.png"
              alt="TypeOfGoals"
            ></CardMedia>
          </Box>
        </Grid>
      </Grid>
      <Grid container mt={2}>
        <Grid item xs={12} mt={1}>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
            }}
            align="left"
          >
            Natuurlijk is het ook mogelijk om proces doelen op te stellen zonder
            dat je een restultaat doel wilt halen. Als dat het geval is kan je
            een deel van de volgende oefeningen overslaan.
          </Typography>
        </Grid>
        <Grid item xs={12} mt={2}>
          <Typography
            variant="subtitle1"
            style={{ color: PgColors.bluepg[400] }}
            sx={{
              fontSize: { xs: 16, md: 20, lg: 24 },
              fontWeight: { md: 500 },
            }}
            align="left"
          >
            Opdracht 2
          </Typography>
        </Grid>
        <Grid item xs={12} mt={1}>
          <Typography
            variant="subtitle1"
            style={{ color: PgColors.bluepg[400] }}
            sx={{
              fontSize: { xs: 12, md: 16, lg: 20 },
              fontWeight: { md: 400 },
            }}
            align="left"
          >
            Stap 1
          </Typography>
        </Grid>
        <Grid item xs={12} mt={1}>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
            }}
            align="left"
          >
            Wat is je resultaat doel voor dit seizoen / komende periode?
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <AddExercise2A />
      </Grid>
      <Grid container mt={4} mb={2}>
        {exercise2AList && (
          <ListExercise2A
            exercises={exercise2AList}
            path={`IntroExercises/${id}/UserExercise2A/${id}`}
          />
        )}
      </Grid>
      <Grid container mt={2}>
        <Grid item xs={12} mt={1}>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
            }}
            align="left"
          >
            Wat heb je nodig om bovenstaand doel te halen? Waar moet je nog aan
            werken? Je kan meerdere dingen toevoegen.
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <AddExercise2B />
      </Grid>
      <Grid container mt={4} mb={2}>
        {exercise2BList && (
          <ListExercise2B
            exercises={exercise2BList}
            path={`IntroExercises/${id}/UserExercise2B/${id}`}
          />
        )}
      </Grid>
      <Grid container mt={2}>
        <Grid item xs={12}>
          <Typography
            variant="subtitle1"
            style={{ color: PgColors.bluepg[400] }}
            sx={{
              fontSize: { xs: 12, md: 16, lg: 20 },
              fontWeight: { md: 400 },
            }}
            align="left"
          >
            Stap 2
          </Typography>
        </Grid>
        <Grid item xs={12} mt={1}>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
            }}
            align="left"
          >
            Zoals eerder beschreven zul je waarschijnlijk dingen moeten
            verbeteren om je resultaat doel te halen. Dit zijn je prestatie
            doelen. Dit zijn skills, kwaliteiten of stappen die je dichter bij
            je resultaat doel brengen.
          </Typography>
        </Grid>
        <Grid item xs={12} mt={1}>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
            }}
            align="left"
          >
            Vul in onderstaande opdracht je proces doel(en) in:
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <AddExercise2C1 />
      </Grid>
      <Grid container>
        <AddExercise2C2 />
      </Grid>
      <Grid container>
        <AddExercise2C3 />
      </Grid>

      <Grid container mt={2}>
        <Grid item xs={12}>
          <Typography
            variant="subtitle1"
            style={{ color: PgColors.bluepg[400] }}
            sx={{
              fontSize: { xs: 12, md: 16, lg: 20 },
              fontWeight: { md: 400 },
            }}
            align="left"
          >
            Stap 3
          </Typography>
        </Grid>

        <Grid item xs={12} mt={1}>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
            }}
            align="left"
          >
            Scrijf voor elke prestatie doel 1 tot 3 process doelen op. Dit zijn
            acties die je vandaag of de volgende training al kunt doen. En door
            deze taken te doen dan kan je uiteindelijk je prestatie doel halen.
          </Typography>
        </Grid>
      </Grid>
      <Grid container mt={2} mb={2}>
        {exercise2C1List && (
          <ListExercise2C1
            exercises={exercise2C1List}
            path={`IntroExercises/${id}/UserExercise2C1${id}`}
          />
        )}
      </Grid>
      <Grid container>{exercise2C1List && <AddExercise2D1A />}</Grid>
      <Grid container>{exercise2C1List && <AddExercise2D1B />}</Grid>
      <Grid container>{exercise2C1List && <AddExercise2D1C />}</Grid>
      <Grid container mt={2}>
        {exercise2D1AList && (
          <ListExercise2D1A
            exercises={exercise2D1AList}
            path={`IntroExercises/${id}/UserExercise2D1A${id}`}
          />
        )}
      </Grid>
      <Grid container mt={2}>
        {exercise2D1BList && (
          <ListExercise2D1B
            exercises={exercise2D1BList}
            path={`IntroExercises/${id}/UserExercise2D1B${id}`}
          />
        )}
      </Grid>
      <Grid container mt={2}>
        {exercise2D1CList && (
          <ListExercise2D1C
            exercises={exercise2D1CList}
            path={`IntroExercises/${id}/UserExercise2D1C${id}`}
          />
        )}
      </Grid>
      <Grid container mt={4} mb={2}>
        {exercise2C2List && (
          <ListExercise2C2
            exercises={exercise2C2List}
            path={`IntroExercises/${id}/UserExercise2C2${id}`}
          />
        )}
      </Grid>
      <Grid container>{exercise2C2List && <AddExercise2D2A />}</Grid>
      <Grid container>{exercise2C2List && <AddExercise2D2B />}</Grid>
      <Grid container>{exercise2C2List && <AddExercise2D2C />}</Grid>
      <Grid container mt={2}>
        {exercise2D2AList && (
          <ListExercise2D2A
            exercises={exercise2D2AList}
            path={`IntroExercises/${id}/UserExercise2D2A${id}`}
          />
        )}
      </Grid>
      <Grid container mt={2}>
        {exercise2D2BList && (
          <ListExercise2D2B
            exercises={exercise2D2BList}
            path={`IntroExercises/${id}/UserExercise2D2B${id}`}
          />
        )}
      </Grid>
      <Grid container mt={2}>
        {exercise2D2CList && (
          <ListExercise2D2C
            exercises={exercise2D2CList}
            path={`IntroExercises/${id}/UserExercise2D2C${id}`}
          />
        )}
      </Grid>
      <Grid container mt={4} mb={2}>
        {exercise2C3List && (
          <ListExercise2C3
            exercises={exercise2C3List}
            path={`IntroExercises/${id}/UserExercise2C3${id}`}
          />
        )}
      </Grid>
      <Grid container>{exercise2C3List && <AddExercise2D3A />}</Grid>
      <Grid container>{exercise2C3List && <AddExercise2D3B />}</Grid>
      <Grid container>{exercise2C3List && <AddExercise2D3C />}</Grid>
      <Grid container mt={2}>
        {exercise2D3AList && (
          <ListExercise2D3A
            exercises={exercise2D3AList}
            path={`IntroExercises/${id}/UserExercise2D3A${id}`}
          />
        )}
      </Grid>
      <Grid container mt={2}>
        {exercise2D3BList && (
          <ListExercise2D3B
            exercises={exercise2D3BList}
            path={`IntroExercises/${id}/UserExercise2D3B${id}`}
          />
        )}
      </Grid>
      <Grid container mt={2} mb={2}>
        {exercise2D3CList && (
          <ListExercise2D3C
            exercises={exercise2D3CList}
            path={`IntroExercises/${id}/UserExercise2D3C${id}`}
          />
        )}
      </Grid>
    </>
  );
};
