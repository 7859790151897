import React, { useState, useEffect } from "react";
import {
  collection,
  query,
  orderBy,
  onSnapshot,
  updateDoc,
  doc,
  deleteDoc,
} from "firebase/firestore";
import { db } from "../../../../firebase/config";
import {
  Container,
  Grid,
  Typography,
  Checkbox,
  Paper,
  TextField,
  Button,
  Modal,
  Toolbar,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Box,
  Drawer,
  Divider,
} from "@mui/material";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { useAuthContext } from "../../../../hooks/useAuthContext";
import { useParams } from "react-router-dom";
import { PgColors } from "../../../../configs/colors";

export const ListExercise1A = ({ exercises }: any) => {
  const { id } = useParams();
  const { authIsReady, user } = useAuthContext();
  const [exercise1AList, setExercise1AList] = useState([]);

  //   useEffect(() => {
  //     const ref = query(
  //       collection(db, `IntroExercises/${user.uid}/UserExercise1A`),
  //       orderBy("createdAt", "desc")
  //     );

  //     const unsub = onSnapshot(ref, (snapshot) => {
  //       let results: any = [];
  //       snapshot.docs.forEach((doc) => {
  //         results.push({ ...doc.data(), id: doc.id });
  //       });
  //       setExercise1AList(results);
  //     });
  //     return () => unsub();
  //   }, []);

  const handleDelete = async (exercise: any) => {
    const ref = doc(
      db,
      `IntroExercises/${user.uid}/UserExercise1A/${exercise.id}`
    );
    await deleteDoc(ref);
  };

  return (
    <>
      <Container>
        {exercises.map((exercise: any) => (
          <>
            <Grid container alignItems="flex-end">
              <Grid
                item
                key={exercise.id}
                alignItems="center"
                // display={{ xs: "none", md: "flex" }}
                xs={12}
                sm={10}
              >
                <Paper
                  style={{
                    width: "100%",
                    backgroundColor: PgColors.bluepg[200],
                  }}
                >
                  <Grid item xs={12} ml={2}>
                    <Typography
                      variant="subtitle1"
                      style={{ color: "white", whiteSpace: "pre-line" }}
                    >
                      {exercise.step1A}
                    </Typography>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={1} display={{ xs: "none", md: "flex" }}></Grid>
              <Grid item xs={1} sx={{ mt: { xs: 1, md: 0 } }}>
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: PgColors.bluepg[200],
                  }}
                  sx={{ minWidth: 20 }}
                  onClick={() => handleDelete(exercise)}
                >
                  <DeleteOutlinedIcon />
                </Button>
              </Grid>
            </Grid>
          </>
        ))}
      </Container>
    </>
  );
};

export const ListExercise1B = ({ exercises }: any) => {
  const { id } = useParams();
  const { user } = useAuthContext();

  const handleDelete = async (exercise: any) => {
    const ref = doc(
      db,
      `IntroExercises/${user.uid}/UserExercise1B/${exercise.id}`
    );
    await deleteDoc(ref);
  };

  return (
    <>
      <Container>
        {exercises.map((exercise: any) => (
          <>
            <Grid container alignItems="flex-end">
              <Grid
                item
                key={exercise.id}
                alignItems="center"
                // display={{ xs: "none", md: "flex" }}
                xs={12}
                sm={10}
              >
                <Paper
                  style={{
                    width: "100%",
                    backgroundColor: PgColors.bluepg[200],
                  }}
                >
                  <Grid item xs={12} ml={2}>
                    <Typography
                      variant="subtitle1"
                      style={{ color: "white", whiteSpace: "pre-line" }}
                    >
                      {exercise.answer}
                    </Typography>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={1} display={{ xs: "none", md: "flex" }}></Grid>
              <Grid item xs={1} sx={{ mt: { xs: 1, md: 0 } }}>
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: PgColors.bluepg[200],
                  }}
                  sx={{ minWidth: 20 }}
                  onClick={() => handleDelete(exercise)}
                >
                  <DeleteOutlinedIcon />
                </Button>
              </Grid>
            </Grid>
          </>
        ))}
      </Container>
    </>
  );
};

export const ListExercise1C = ({ exercises }: any) => {
  const { id } = useParams();
  const { user } = useAuthContext();

  const handleDelete = async (exercise: any) => {
    const ref = doc(
      db,
      `IntroExercises/${user.uid}/UserExercise1C/${exercise.id}`
    );
    await deleteDoc(ref);
  };

  return (
    <>
      <Container>
        {exercises.map((exercise: any) => (
          <>
            <Grid container alignItems="flex-end">
              <Grid
                item
                key={exercise.id}
                alignItems="center"
                // display={{ xs: "none", md: "flex" }}
                xs={12}
                sm={10}
              >
                <Paper
                  style={{
                    width: "100%",
                    backgroundColor: PgColors.bluepg[200],
                  }}
                >
                  <Grid item xs={12} ml={2}>
                    <Typography
                      variant="subtitle1"
                      style={{ color: "white", whiteSpace: "pre-line" }}
                    >
                      {exercise.answer}
                    </Typography>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={1} display={{ xs: "none", md: "flex" }}></Grid>
              <Grid item xs={1} sx={{ mt: { xs: 1, md: 0 } }}>
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: PgColors.bluepg[200],
                  }}
                  sx={{ minWidth: 20 }}
                  onClick={() => handleDelete(exercise)}
                >
                  <DeleteOutlinedIcon />
                </Button>
              </Grid>
            </Grid>
          </>
        ))}
      </Container>
    </>
  );
};

export const ListExercise1D = ({ exercises }: any) => {
  const { id } = useParams();
  const { user } = useAuthContext();

  const handleDelete = async (exercise: any) => {
    const ref = doc(
      db,
      `IntroExercises/${user.uid}/UserExercise1D/${exercise.id}`
    );
    await deleteDoc(ref);
  };

  return (
    <>
      <Container>
        {exercises.map((exercise: any) => (
          <>
            <Grid container alignItems="flex-end">
              <Grid
                item
                key={exercise.id}
                alignItems="center"
                // display={{ xs: "none", md: "flex" }}
                xs={12}
                sm={10}
              >
                <Paper
                  style={{
                    width: "100%",
                    backgroundColor: PgColors.bluepg[200],
                  }}
                >
                  <Grid item xs={12} ml={2}>
                    <Typography
                      variant="subtitle1"
                      style={{ color: "white", whiteSpace: "pre-line" }}
                    >
                      {exercise.answer}
                    </Typography>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={1} display={{ xs: "none", md: "flex" }}></Grid>
              <Grid item xs={1} sx={{ mt: { xs: 1, md: 0 } }}>
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: PgColors.bluepg[200],
                  }}
                  sx={{ minWidth: 20 }}
                  onClick={() => handleDelete(exercise)}
                >
                  <DeleteOutlinedIcon />
                </Button>
              </Grid>
            </Grid>
          </>
        ))}
      </Container>
    </>
  );
};


export const ListExercise1E = ({ exercises }: any) => {
  const { id } = useParams();
  const { user } = useAuthContext();

  const handleDelete = async (exercise: any) => {
    const ref = doc(
      db,
      `IntroExercises/${user.uid}/UserExercise1E/${exercise.id}`
    );
    await deleteDoc(ref);
  };

  return (
    <>
      <Container>
        {exercises.map((exercise: any) => (
          <>
            <Grid container alignItems="flex-end">
              <Grid
                item
                key={exercise.id}
                alignItems="center"
                // display={{ xs: "none", md: "flex" }}
                xs={12}
              >
                <Paper
                  style={{
                    width: "100%",
                    backgroundColor: PgColors.greenpg[200],
                  }}
                >
                  <Grid item xs={12} ml={2}>
                    <Typography
                      variant="subtitle1"
                      style={{ color: "white", whiteSpace: "pre-line" }}
                    >
                      {exercise.answer}
                    </Typography>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </>
        ))}
      </Container>
    </>
  );
};

export const ListExercise1F = ({ exercises }: any) => {
  const { id } = useParams();
  const { user } = useAuthContext();

  const handleDelete = async (exercise: any) => {
    const ref = doc(
      db,
      `IntroExercises/${user.uid}/UserExercise1F/${exercise.id}`
    );
    await deleteDoc(ref);
  };

  return (
    <>
      <Container>
        {exercises.map((exercise: any) => (
          <>
            <Grid container alignItems="flex-end">
              <Grid
                item
                key={exercise.id}
                alignItems="center"
                // display={{ xs: "none", md: "flex" }}
                xs={12}
              >
                <Paper
                  style={{
                    width: "100%",
                    backgroundColor: PgColors.greenpg[200],
                  }}
                >
                  <Grid item xs={12} ml={2}>
                    <Typography
                      variant="subtitle1"
                      style={{ color: "white", whiteSpace: "pre-line" }}
                    >
                      {exercise.answer}
                    </Typography>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </>
        ))}
      </Container>
    </>
  );
};