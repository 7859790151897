import React, { useState, useEffect } from "react";
import {
  collection,
  query,
  onSnapshot,
  updateDoc,
  doc,
  deleteDoc,
} from "firebase/firestore";
import {
  Grid,
  Container,
  Typography,
  Divider,
  Button,
  Paper,
} from "@mui/material";
import { Sup1 } from "./Sup1/Sup1";
import { Sup2 } from "./Sup2/Sup2";
import { Sup3 } from "./Sup3/Sup3";
import { Sup4 } from "./Sup4/Sup4";
import { Sup5 } from "./Sup5/Sup5";
import { Sup6 } from "./Sup6/Sup6";
import { Sup7 } from "./Sup7/Sup7";
import { BalkHoudingen } from "./Balkhoudingen/BalkHoudingen";
import { PgColors } from "../../configs/colors";

export const VerplichteOefenstof = () => {
  const [active, setActive] = useState("S1");

  return (
    <>
      <Container>
        <Grid container mt={2} spacing={1}>
          <Grid item xs={3} md={1}>
            <Button
              style={{
                backgroundColor: PgColors.bluepg[200],
              }}
              variant="contained"
              onClick={() => setActive("S1")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 14 } }}
              >
                Sup 1
              </Typography>
            </Button>
          </Grid>
          <Grid item xs={3} md={1}>
            <Button
              style={{
                backgroundColor: PgColors.bluepg[200],
              }}
              variant="contained"
              onClick={() => setActive("S2")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 14 } }}
              >
                Sup 2
              </Typography>
            </Button>
          </Grid>
          <Grid item xs={3} md={1}>
            <Button
              style={{
                backgroundColor: PgColors.bluepg[200],
              }}
              variant="contained"
              onClick={() => setActive("S3")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 14 } }}
              >
                Sup 3
              </Typography>
            </Button>
          </Grid>
          <Grid item xs={3} md={1}>
            <Button
              style={{
                backgroundColor: PgColors.bluepg[200],
              }}
              variant="contained"
              onClick={() => setActive("S4")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 14 } }}
              >
                Sup 4
              </Typography>
            </Button>
          </Grid>
          <Grid item xs={3} md={1}>
            <Button
              style={{
                backgroundColor: PgColors.bluepg[200],
              }}
              variant="contained"
              onClick={() => setActive("S5")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 14 } }}
              >
                Sup 5
              </Typography>
            </Button>
          </Grid>
          <Grid item xs={3} md={1}>
            <Button
              style={{
                backgroundColor: PgColors.bluepg[200],
              }}
              variant="contained"
              onClick={() => setActive("S6")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 14 } }}
              >
                Sup 6
              </Typography>
            </Button>
          </Grid>
          <Grid item xs={3} md={1}>
            <Button
              style={{
                backgroundColor: PgColors.bluepg[200],
              }}
              variant="contained"
              onClick={() => setActive("S7")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 14 } }}
              >
                Sup 7
              </Typography>
            </Button>
          </Grid>
          <Grid item xs={3} md={3}>
            <Button
              style={{
                backgroundColor: PgColors.bluepg[200],
              }}
              variant="contained"
              onClick={() => setActive("Balk")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 14 } }}
              >
                Balk houdingen
              </Typography>
            </Button>
          </Grid>
        </Grid>
        {active === "S1" && <Sup1 />}
        {active === "S2" && <Sup2 />}
        {active === "S3" && <Sup3 />}
        {active === "S4" && <Sup4 />}
        {active === "S5" && <Sup5 />}
        {active === "S6" && <Sup6 />}
        {active === "S7" && <Sup7 />}
        {active === "Balk" && <BalkHoudingen />}
      </Container>
    </>
  );
};
