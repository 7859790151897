import React, { useState, useEffect } from "react";
import {
  collection,
  query,
  onSnapshot,
  orderBy,
  updateDoc,
  doc,
  where,
  deleteDoc,
} from "firebase/firestore";
import { db } from "../../firebase/config";
import { Grid, Container, Typography, Divider, Button } from "@mui/material";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useNavigate } from "react-router-dom";
import { PgColors } from "../../configs/colors";

export const Voltige = ({ path, gymnast }: any) => {
  const { user } = useAuthContext();
  const [programsList, setProgramsList] = useState([]);
  const [programsGList, setProgramsGList] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const ref = query(
      collection(db, path),
      orderBy("name", "asc"),
      where("apparatus", "==", "Voltige")
    );

    const unsub = onSnapshot(ref, (snapshot) => {
      let results: any = [];
      snapshot.docs.forEach((doc) => {
        results.push({ ...doc.data(), id: doc.id });
      });
      setProgramsList(results);
    });
    return () => unsub();
  }, []);

  useEffect(() => {
    if (gymnast.coachId) {
      const ref = query(
        collection(db, `ProgramsByCoach/${gymnast.coachId}/Programs`),
        orderBy("name", "asc"),
        where("apparatus", "==", "Voltige")
      );

      const unsub = onSnapshot(ref, (snapshot) => {
        let results: any = [];
        snapshot.docs.forEach((doc) => {
          results.push({ ...doc.data(), id: doc.id });
        });
        setProgramsGList(results);
      });

      return () => unsub();
    }
  }, [gymnast.coachId]);

  return (
    <>
      <Container>
        {programsList.map((program: any) => (
          <>
            <Grid container mt={2}>
              <Grid item>
                <Button
                  variant="text"
                  onClick={(e) => navigate(`${program.id}`)}
                >
                  <Typography
                    variant="h6"
                    style={{
                      color: PgColors.bluepg[200],
                      fontWeight: "400",
                    }}
                    sx={{
                      fontSize: {
                        xs: 12,
                        md: 20,
                      },
                    }}
                  >
                    {program.name}
                  </Typography>
                </Button>
              </Grid>
            </Grid>
            <Divider></Divider>
          </>
        ))}
        {programsGList.map((program: any) => (
          <>
            <Grid container mt={2}>
              <Grid item>
                <Button
                  variant="text"
                  onClick={(e) => navigate(`${program.id}`)}
                >
                  <Typography
                    variant="h6"
                    style={{
                      color: PgColors.bluepg[200],
                      fontWeight: "400",
                    }}
                    sx={{
                      fontSize: {
                        xs: 12,
                        md: 20,
                      },
                    }}
                  >
                    {program.name}
                  </Typography>
                </Button>
              </Grid>
            </Grid>
            <Divider></Divider>
          </>
        ))}
      </Container>
    </>
  );
};
