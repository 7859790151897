import React, { useState } from "react";
import {
  collection,
  addDoc,
  serverTimestamp,
  doc,
  setDoc,
} from "firebase/firestore";
import {
  Container,
  Typography,
  Paper,
  TextField,
  Button,
  Modal,
  Toolbar,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Box,
  Drawer,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { getFunctions, httpsCallable } from "firebase/functions";
import { useAuthContext } from "../../hooks/useAuthContext";
import { db } from "../../firebase/config";

export const AddCompetition = () => {
  const { user } = useAuthContext();
  const [name, setName] = useState("");
  const [competition, setCompetition] = useState("");
  const [date, setDate] = useState("");
  const [location, setLocation] = useState("");
  const [dVault1, setDVault1] = useState("");
  const [eVault1, setEVault1] = useState("");
  const [tVault1, setTVault1] = useState("");
  const [dVault2, setDVault2] = useState("");
  const [eVault2, setEVault2] = useState("");
  const [tVault2, setTVault2] = useState("");
  const [pVault, setPVault] = useState("");
  const [dBars, setDBars] = useState("");
  const [eBars, setEBars] = useState("");
  const [tBars, setTBars] = useState("");
  const [pBars, setPBars] = useState("");
  const [dBeam, setDBeam] = useState("");
  const [eBeam, setEBeam] = useState("");
  const [tBeam, setTBeam] = useState("");
  const [pBeam, setPBeam] = useState("");
  const [dFloor, setDFloor] = useState("");
  const [eFloor, setEFloor] = useState("");
  const [tFloor, setTFloor] = useState("");
  const [pFloor, setPFloor] = useState("");
  const [tTotal, setTTotal] = useState("");
  const [pTotal, setPTotal] = useState("");
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const style = {
    position: "abosulate" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(50%, 20%)",
    width: 1000,
    height: 600,
    bgcolor: "background.paper",
    border: "2px solid black",
    boxShadow: 24,
    borderRadius: "5px",
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const ref = collection(db, "CompetitionsByCoach", user.uid, "Competitions");

    await addDoc(ref, {
      name: name,
      competition: competition,
      date: date,
      location: location,
      dVault1: dVault1,
      eVault1: eVault1,
      tVault1: tVault1,
      dVault2: dVault2,
      eVault2: eVault2,
      tVault2: tVault2,
      pVault: pVault,
      dBars: dBars,
      eBars: eBars,
      tBars: tBars,
      pBars: pBars,
      dBeam: dBeam,
      eBeam: eBeam,
      tBeam: tBeam,
      pBeam: pBeam,
      dFloor: dFloor,
      eFloor: eFloor,
      tFloor: tFloor,
      pFloor: pFloor,
      tTotal: tTotal,
      pTotal: pTotal,
      uid: user.uid,
      createdAt: serverTimestamp(),
    });
    setName("");
    setCompetition("");
    setDate("");
    setLocation("");
    setDVault1("");
    setEVault1("");
    setTVault1("");
    setDVault2("");
    setEVault2("");
    setTVault2("");
    setPVault("");
    setDBars("");
    setEBars("");
    setTBars("");
    setPBars("");
    setDBeam("");
    setEBeam("");
    setTBeam("");
    setPBeam("");
    setDFloor("");
    setEFloor("");
    setTFloor("");
    setPFloor("");
    setTTotal("");
    setPTotal("");
  };

  return (
    <div>
      <Button
        variant="contained"
        color="warning"
        size="large"
        type="submit"
        onClick={handleOpen}
      >
        Add Competition
      </Button>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style} justifyContent="center">
          <form onSubmit={handleSubmit}>
            <Grid container justifyContent="center" marginTop={4}>
              <Grid md={4}>
                <TextField
                  label="Name Gymnast"
                  color="secondary"
                  variant="outlined"
                  fullWidth
                  value={name}
                  onChange={(event) => setName(event.target.value)}
                />
              </Grid>
              <Grid md={4}>
                <TextField
                  label="Competition"
                  color="secondary"
                  variant="outlined"
                  value={competition}
                  fullWidth
                  onChange={(event) => setCompetition(event.target.value)}
                />
              </Grid>
              <Grid md={2}>
                <TextField
                  label="Date"
                  color="secondary"
                  variant="outlined"
                  value={date}
                  fullWidth
                  onChange={(event) => setDate(event.target.value)}
                />
              </Grid>
            </Grid>
            <Grid container justifyContent="center" marginTop={2}>
              <Grid md={1}>
                <Paper
                  style={{
                    backgroundColor: "#c39e98",
                    height: 40,
                  }}
                >
                  <Typography
                    variant="h6"
                    style={{ color: "white" }}
                    align="center"
                  >
                    Vault 1
                  </Typography>
                </Paper>
              </Grid>
              <Grid md={1}>
                <Paper style={{ backgroundColor: "#c39e98", height: 40 }}>
                  <Typography
                    variant="h6"
                    style={{ color: "white" }}
                    align="center"
                  >
                    Vault 2
                  </Typography>
                </Paper>
              </Grid>
              <Grid md={2}>
                <Paper style={{ backgroundColor: "#c39e98", height: 40 }}>
                  <Typography
                    variant="h6"
                    style={{ color: "white" }}
                    align="center"
                  >
                    Bars
                  </Typography>
                </Paper>
              </Grid>
              <Grid md={2}>
                <Paper style={{ backgroundColor: "#c39e98", height: 40 }}>
                  <Typography
                    variant="h6"
                    style={{ color: "white" }}
                    align="center"
                  >
                    Beam
                  </Typography>
                </Paper>
              </Grid>
              <Grid md={2}>
                <Paper style={{ backgroundColor: "#c39e98", height: 40 }}>
                  <Typography
                    variant="h6"
                    style={{ color: "white" }}
                    align="center"
                  >
                    Floor
                  </Typography>
                </Paper>
              </Grid>
              <Grid md={2}>
                <Paper style={{ backgroundColor: "#c39e98", height: 40 }}>
                  <Typography
                    variant="h6"
                    style={{ color: "white" }}
                    align="center"
                  >
                    AA
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
            <Grid container justifyContent="center">
              <Grid md={1}>
                <TextField
                  label="D-score"
                  color="secondary"
                  variant="outlined"
                  fullWidth
                  value={dVault1}
                  onChange={(event) => setDVault1(event.target.value)}
                />
              </Grid>
              <Grid md={1}>
                <TextField
                  label="D-score"
                  color="secondary"
                  variant="outlined"
                  fullWidth
                  value={dVault2}
                  onChange={(event) => setDVault2(event.target.value)}
                />
              </Grid>
              <Grid md={2}>
                <TextField
                  label="D-score"
                  color="secondary"
                  variant="outlined"
                  fullWidth
                  value={dBars}
                  onChange={(event) => setDBars(event.target.value)}
                />
              </Grid>
              <Grid md={2}>
                <TextField
                  label="D-score"
                  color="secondary"
                  variant="outlined"
                  fullWidth
                  value={dBeam}
                  onChange={(event) => setDBeam(event.target.value)}
                />
              </Grid>
              <Grid md={2}>
                <TextField
                  label="D-score"
                  color="secondary"
                  variant="outlined"
                  fullWidth
                  value={dFloor}
                  onChange={(event) => setDFloor(event.target.value)}
                />
              </Grid>
              <Grid md={2}></Grid>
            </Grid>
            <Grid container justifyContent="center">
              <Grid md={1}>
                <TextField
                  label="E-score"
                  color="secondary"
                  variant="outlined"
                  fullWidth
                  value={eVault1}
                  onChange={(event) => setEVault1(event.target.value)}
                />
              </Grid>
              <Grid md={1}>
                <TextField
                  label="E-score"
                  color="secondary"
                  variant="outlined"
                  fullWidth
                  value={eVault2}
                  onChange={(event) => setEVault2(event.target.value)}
                />
              </Grid>
              <Grid md={2}>
                <TextField
                  label="E-score"
                  color="secondary"
                  variant="outlined"
                  fullWidth
                  value={eBars}
                  onChange={(event) => setEBars(event.target.value)}
                />
              </Grid>
              <Grid md={2}>
                <TextField
                  label="E-score"
                  color="secondary"
                  variant="outlined"
                  fullWidth
                  value={eBeam}
                  onChange={(event) => setEBeam(event.target.value)}
                />
              </Grid>
              <Grid md={2}>
                <TextField
                  label="E-score"
                  color="secondary"
                  variant="outlined"
                  fullWidth
                  value={eFloor}
                  onChange={(event) => setEFloor(event.target.value)}
                />
              </Grid>
              <Grid md={2}></Grid>
            </Grid>
            <Grid container justifyContent="center">
              <Grid md={1}>
                <TextField
                  label="Total"
                  color="secondary"
                  variant="outlined"
                  fullWidth
                  value={tVault1}
                  onChange={(event) => setTVault1(event.target.value)}
                />
              </Grid>
              <Grid md={1}>
                <TextField
                  label="Total"
                  color="secondary"
                  variant="outlined"
                  fullWidth
                  value={tVault2}
                  onChange={(event) => setTVault2(event.target.value)}
                />
              </Grid>
              <Grid md={2}>
                <TextField
                  label="Total"
                  color="secondary"
                  variant="outlined"
                  fullWidth
                  value={tBars}
                  onChange={(event) => setTBars(event.target.value)}
                />
              </Grid>
              <Grid md={2}>
                <TextField
                  label="Total"
                  color="secondary"
                  variant="outlined"
                  fullWidth
                  value={tBeam}
                  onChange={(event) => setTBeam(event.target.value)}
                />
              </Grid>
              <Grid md={2}>
                <TextField
                  label="Total"
                  color="secondary"
                  variant="outlined"
                  fullWidth
                  value={tFloor}
                  onChange={(event) => setTFloor(event.target.value)}
                />
              </Grid>
              <Grid md={2}>
                <TextField
                  label="Total"
                  color="secondary"
                  variant="outlined"
                  fullWidth
                  value={tTotal}
                  onChange={(event) => setTTotal(event.target.value)}
                />
              </Grid>
            </Grid>
            <Grid container justifyContent="center">
              <Grid md={2}>
                <TextField
                  label="Place"
                  color="secondary"
                  variant="outlined"
                  fullWidth
                  value={pVault}
                  onChange={(event) => setPVault(event.target.value)}
                />
              </Grid>
              <Grid md={2}>
                <TextField
                  label="Place"
                  color="secondary"
                  variant="outlined"
                  fullWidth
                  value={pBars}
                  onChange={(event) => setPBars(event.target.value)}
                />
              </Grid>
              <Grid md={2}>
                <TextField
                  label="Place"
                  color="secondary"
                  variant="outlined"
                  fullWidth
                  value={pBeam}
                  onChange={(event) => setPBeam(event.target.value)}
                />
              </Grid>
              <Grid md={2}>
                <TextField
                  label="Place"
                  color="secondary"
                  variant="outlined"
                  fullWidth
                  value={pFloor}
                  onChange={(event) => setPFloor(event.target.value)}
                />
              </Grid>
              <Grid md={2}>
                <TextField
                  label="Place"
                  color="secondary"
                  variant="outlined"
                  fullWidth
                  value={pTotal}
                  onChange={(event) => setPTotal(event.target.value)}
                />
              </Grid>
            </Grid>
            <Grid container justifyContent="center" mt={4}>
              <Grid md={3}>
                <Button
                  variant="contained"
                  color="secondary"
                  size="large"
                  type="submit"
                >
                  <Typography
                    variant="h6"
                    style={{ color: "white" }}
                    align="center"
                  >
                    Add Competition
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Modal>
    </div>
  );
};
