import React, { useState, useEffect } from "react";
import { Grid, Container } from "@mui/material";
import { Sup5Intro } from "./Sup5Intro";
import { Sup5Vault } from "./Sup5Vault";
import { Sup5Bars } from "./Sup5Bars";
import { Sup5Beam } from "./Sup5Beam";
import { Sup5Floor } from "./Sup5Floor";

export const Sup5 = () => {
  return (
    <>
      <Container>
        <Sup5Intro />
        <Sup5Vault />
        <Sup5Bars />
        <Sup5Beam />
        <Sup5Floor />
      </Container>
    </>
  );
};
