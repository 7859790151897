import { useState, useEffect } from "react";
import { auth } from "../firebase/config";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";
import { useAuthContext } from "./useAuthContext";
import {
  collection,
  addDoc,
  serverTimestamp,
  setDoc,
  doc,
} from "firebase/firestore";
import { db } from "../firebase/config";
import { useNavigate } from "react-router-dom";

export const useSignup = () => {
  const [error, setError] = useState("");
  const [isSignupSuccess, setIsSignupSuccess] = useState(false);
  const { dispatch } = useAuthContext();
  const navigate = useNavigate();

  useEffect(() => {
    // This effect runs when the isSignupSuccess state changes.
    if (isSignupSuccess) {
      // If signup was successful, display the message for a few seconds before navigating.
      const timer = setTimeout(() => {
        navigate("/login");
      }, 3000); // Adjust the delay as needed

      return () => clearTimeout(timer); // Cleanup the timer on component unmount
    }
  }, [isSignupSuccess, navigate]);

  const signup = (
    email: string,
    password: string,
    firstname: string,
    role: string
  ) => {
    setError("");
    createUserWithEmailAndPassword(auth, email, password)
      .then((res) => {
        dispatch({ type: "LOGIN", payload: res.user });
        const uid = res.user.uid;
        const data = {
          id: uid,
          email,
          firstname,
          role,
        };
        const ref = doc(db, "users", uid);

        setDoc(ref, data).then(() => {
          setIsSignupSuccess(true);
        });
        sendEmailVerification(res.user);
      })
      .catch((err) => {
        setError(err.message);
        setIsSignupSuccess(false);
      });

    navigate("/login");
  };

  return { error, signup, isSignupSuccess };
};
