import React, { useState } from "react";
import {
  collection,
  addDoc,
  serverTimestamp,
  doc,
  deleteDoc,
  setDoc,
} from "firebase/firestore";
import { db } from "../../../../firebase/config";
import {
  Container,
  Grid,
  Typography,
  Paper,
  TextField,
  Button,
  Modal,
  Toolbar,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Box,
  Drawer,
} from "@mui/material";
import { useAuthContext } from "../../../../hooks/useAuthContext";
import { useParams } from "react-router-dom";
import { PgColors } from "../../../../configs/colors";

export const AddExercise2D1A = () => {
  const [answer, setAnswer] = useState("");
  const { user } = useAuthContext();
  const { id } = useParams();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const ref = collection(db, `IntroExercises/${id}/UserExercise2D1A`);

    await addDoc(ref, {
      answer: answer,
      uid: user.uid,
      createdAt: serverTimestamp(),
    });
    setAnswer("");
  };

  return (
    <>
      <Container>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} alignItems="flex-end">
            <Grid item xs={12} mt={2} sx={{ mb: { xs: -2, md: -3 } }}>
              <Typography
                variant="subtitle1"
                style={{ color: PgColors.bluepg[400] }}
                sx={{
                  fontSize: { xs: 10, md: 14, lg: 18 },
                  fontWeight: { md: 400 },
                }}
                align="left"
              >
                Proces doel 1:
              </Typography>
            </Grid>
            <Grid item md={10} xs={12} sx={{ mt: { xs: 0.5 } }}>
              <TextField
                InputLabelProps={{
                  sx: {
                    fontSize: { xs: 10, md: 14, lg: 16 },
                  },
                }}
                size="small"
                label="Bijv: 2 trainingen per week skill X  5 tot 10 keer oefenen"
                color="warning"
                variant="outlined"
                fullWidth
                multiline
                rows={1}
                value={answer}
                onChange={(event) => setAnswer(event.target.value)}
              />
            </Grid>

            <Grid item md={2} xs={12}>
              <Button
                style={{
                  backgroundColor: PgColors.bluepg[200],
                }}
                variant="contained"
                type="submit"
                size="large"
                sx={{ height: { xs: 30, md: 55, lg: 55 } }}
              >
                <Typography variant="subtitle1">Voeg Toe</Typography>
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </>
  );
};

export const AddExercise2D1B = () => {
  const [answer, setAnswer] = useState("");
  const { user } = useAuthContext();
  const { id } = useParams();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const ref = collection(db, `IntroExercises/${id}/UserExercise2D1B`);

    await addDoc(ref, {
      answer: answer,
      uid: user.uid,
      createdAt: serverTimestamp(),
    });
    setAnswer("");
  };

  return (
    <>
      <Container>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} alignItems="flex-end">
            <Grid item xs={12} mt={2} sx={{ mb: { xs: -1.5, md: -3 } }}>
              <Typography
                variant="subtitle1"
                style={{ color: PgColors.bluepg[400] }}
                sx={{
                  fontSize: { xs: 10, md: 14, lg: 18 },
                  fontWeight: { md: 400 },
                }}
                align="left"
              >
                Proces doel 2:
              </Typography>
            </Grid>
            <Grid item md={10} xs={12}>
              <TextField
                InputLabelProps={{
                  sx: {
                    fontSize: { xs: 10, md: 14, lg: 16 },
                  },
                }}
                size="small"
                label="Bijv: 1 training per week combinatie van skill X & skill Y 7 keer oefenen"
                color="warning"
                variant="outlined"
                fullWidth
                multiline
                rows={1}
                value={answer}
                onChange={(event) => setAnswer(event.target.value)}
              />
            </Grid>

            <Grid item md={2} xs={12}>
              <Button
                style={{
                  backgroundColor: PgColors.bluepg[200],
                }}
                variant="contained"
                type="submit"
                size="large"
                sx={{ height: { xs: 30, md: 55, lg: 55 } }}
              >
                <Typography variant="subtitle1">Voeg Toe</Typography>
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </>
  );
};

export const AddExercise2D1C = () => {
  const [answer, setAnswer] = useState("");
  const { user } = useAuthContext();
  const { id } = useParams();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const ref = collection(db, `IntroExercises/${id}/UserExercise2D1C`);

    await addDoc(ref, {
      answer: answer,
      uid: user.uid,
      createdAt: serverTimestamp(),
    });
    setAnswer("");
  };

  return (
    <>
      <Container>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} alignItems="flex-end">
            <Grid item xs={12} mt={2} sx={{ mb: { xs: -1.5, md: -3 } }}>
              <Typography
                variant="subtitle1"
                style={{ color: PgColors.bluepg[400] }}
                sx={{
                  fontSize: { xs: 10, md: 14, lg: 18 },
                  fontWeight: { md: 400 },
                }}
                align="left"
              >
                Proces doel 3:
              </Typography>
            </Grid>
            <Grid item md={10} xs={12}>
              <TextField
                InputLabelProps={{
                  sx: {
                    fontSize: { xs: 10, md: 14, lg: 16 },
                  },
                }}
                size="small"
                label="Bijv: In een training een vloer oef turnen met een D-score van 3.90"
                color="warning"
                variant="outlined"
                fullWidth
                multiline
                rows={1}
                value={answer}
                onChange={(event) => setAnswer(event.target.value)}
              />
            </Grid>

            <Grid item md={2} xs={12}>
              <Button
                style={{
                  backgroundColor: PgColors.bluepg[200],
                }}
                variant="contained"
                type="submit"
                size="large"
                sx={{ height: { xs: 30, md: 55, lg: 55 } }}
              >
                <Typography variant="subtitle1">Voeg Toe</Typography>
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </>
  );
};

export const AddExercise2D2A = () => {
  const [answer, setAnswer] = useState("");
  const { user } = useAuthContext();
  const { id } = useParams();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const ref = collection(db, `IntroExercises/${id}/UserExercise2D2A`);

    await addDoc(ref, {
      answer: answer,
      uid: user.uid,
      createdAt: serverTimestamp(),
    });
    setAnswer("");
  };

  return (
    <>
      <Container>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} alignItems="flex-end">
            <Grid item xs={12} mt={2} sx={{ mb: { xs: -1.5, md: -3 } }}>
              <Typography
                variant="subtitle1"
                style={{ color: PgColors.bluepg[400] }}
                sx={{
                  fontSize: { xs: 10, md: 14, lg: 18 },
                  fontWeight: { md: 400 },
                }}
                align="left"
              >
                Proces doel 1:
              </Typography>
            </Grid>
            <Grid item md={10} xs={12}>
              <TextField
                size="small"
                color="warning"
                variant="outlined"
                fullWidth
                multiline
                rows={1}
                value={answer}
                onChange={(event) => setAnswer(event.target.value)}
              />
            </Grid>

            <Grid item md={2} xs={12}>
              <Button
                style={{
                  backgroundColor: PgColors.bluepg[200],
                }}
                variant="contained"
                type="submit"
                size="large"
                sx={{ height: { xs: 30, md: 55, lg: 55 } }}
              >
                <Typography variant="subtitle1">Voeg Toe</Typography>
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </>
  );
};

export const AddExercise2D2B = () => {
  const [answer, setAnswer] = useState("");
  const { user } = useAuthContext();
  const { id } = useParams();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const ref = collection(db, `IntroExercises/${id}/UserExercise2D2B`);

    await addDoc(ref, {
      answer: answer,
      uid: user.uid,
      createdAt: serverTimestamp(),
    });
    setAnswer("");
  };

  return (
    <>
      <Container>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} alignItems="flex-end">
            <Grid item xs={12} mt={2} sx={{ mb: { xs: -1.5, md: -3 } }}>
              <Typography
                variant="subtitle1"
                style={{ color: PgColors.bluepg[400] }}
                sx={{
                  fontSize: { xs: 10, md: 14, lg: 18 },
                  fontWeight: { md: 400 },
                }}
                align="left"
              >
                Proces doel 2:
              </Typography>
            </Grid>
            <Grid item md={10} xs={12}>
              <TextField
                size="small"
                color="warning"
                variant="outlined"
                fullWidth
                multiline
                rows={1}
                value={answer}
                onChange={(event) => setAnswer(event.target.value)}
              />
            </Grid>

            <Grid item md={2} xs={12}>
              <Button
                style={{
                  backgroundColor: PgColors.bluepg[200],
                }}
                variant="contained"
                type="submit"
                size="large"
                sx={{ height: { xs: 30, md: 55, lg: 55 } }}
              >
                <Typography variant="subtitle1">Voeg Toe</Typography>
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </>
  );
};

export const AddExercise2D2C = () => {
  const [answer, setAnswer] = useState("");
  const { user } = useAuthContext();
  const { id } = useParams();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const ref = collection(db, `IntroExercises/${id}/UserExercise2D2C`);

    await addDoc(ref, {
      answer: answer,
      uid: user.uid,
      createdAt: serverTimestamp(),
    });
    setAnswer("");
  };

  return (
    <>
      <Container>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} alignItems="flex-end">
            <Grid item xs={12} mt={2} sx={{ mb: { xs: -1.5, md: -3 } }}>
              <Typography
                variant="subtitle1"
                style={{ color: PgColors.bluepg[400] }}
                sx={{
                  fontSize: { xs: 10, md: 14, lg: 18 },
                  fontWeight: { md: 400 },
                }}
                align="left"
              >
                Proces doel 3:
              </Typography>
            </Grid>
            <Grid item md={10} xs={12}>
              <TextField
                size="small"
                color="warning"
                variant="outlined"
                fullWidth
                multiline
                rows={1}
                value={answer}
                onChange={(event) => setAnswer(event.target.value)}
              />
            </Grid>

            <Grid item md={2} xs={12}>
              <Button
                style={{
                  backgroundColor: PgColors.bluepg[200],
                }}
                variant="contained"
                type="submit"
                size="large"
                sx={{ height: { xs: 30, md: 55, lg: 55 } }}
              >
                <Typography variant="subtitle1">Voeg Toe</Typography>
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </>
  );
};

export const AddExercise2D3A = () => {
  const [answer, setAnswer] = useState("");
  const { user } = useAuthContext();
  const { id } = useParams();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const ref = collection(db, `IntroExercises/${id}/UserExercise2D3A`);

    await addDoc(ref, {
      answer: answer,
      uid: user.uid,
      createdAt: serverTimestamp(),
    });
    setAnswer("");
  };

  return (
    <>
      <Container>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} alignItems="flex-end">
            <Grid item xs={12} mt={2} sx={{ mb: { xs: -1.5, md: -3 } }}>
              <Typography
                variant="subtitle1"
                style={{ color: PgColors.bluepg[400] }}
                sx={{
                  fontSize: { xs: 10, md: 14, lg: 18 },
                  fontWeight: { md: 400 },
                }}
                align="left"
              >
                Proces doel 1:
              </Typography>
            </Grid>
            <Grid item md={10} xs={12}>
              <TextField
                size="small"
                color="warning"
                variant="outlined"
                fullWidth
                multiline
                rows={1}
                value={answer}
                onChange={(event) => setAnswer(event.target.value)}
              />
            </Grid>

            <Grid item md={2} xs={12}>
              <Button
                style={{
                  backgroundColor: PgColors.bluepg[200],
                }}
                variant="contained"
                type="submit"
                size="large"
                sx={{ height: { xs: 30, md: 55, lg: 55 } }}
              >
                <Typography variant="subtitle1">Voeg Toe</Typography>
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </>
  );
};

export const AddExercise2D3B = () => {
  const [answer, setAnswer] = useState("");
  const { user } = useAuthContext();
  const { id } = useParams();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const ref = collection(db, `IntroExercises/${id}/UserExercise2D3B`);

    await addDoc(ref, {
      answer: answer,
      uid: user.uid,
      createdAt: serverTimestamp(),
    });
    setAnswer("");
  };

  return (
    <>
      <Container>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} alignItems="flex-end">
            <Grid item xs={12} mt={2} sx={{ mb: { xs: -1.5, md: -3 } }}>
              <Typography
                variant="subtitle1"
                style={{ color: PgColors.bluepg[400] }}
                sx={{
                  fontSize: { xs: 10, md: 14, lg: 18 },
                  fontWeight: { md: 400 },
                }}
                align="left"
              >
                Proces doel 2:
              </Typography>
            </Grid>
            <Grid item md={10} xs={12}>
              <TextField
                size="small"
                color="warning"
                variant="outlined"
                fullWidth
                multiline
                rows={1}
                value={answer}
                onChange={(event) => setAnswer(event.target.value)}
              />
            </Grid>

            <Grid item md={2} xs={12}>
              <Button
                style={{
                  backgroundColor: PgColors.bluepg[200],
                }}
                variant="contained"
                type="submit"
                size="large"
                sx={{ height: { xs: 30, md: 55, lg: 55 } }}
              >
                <Typography variant="subtitle1">Voeg Toe</Typography>
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </>
  );
};

export const AddExercise2D3C = () => {
  const [answer, setAnswer] = useState("");
  const { user } = useAuthContext();
  const { id } = useParams();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const ref = collection(db, `IntroExercises/${id}/UserExercise2D3C`);

    await addDoc(ref, {
      answer: answer,
      uid: user.uid,
      createdAt: serverTimestamp(),
    });
    setAnswer("");
  };

  return (
    <>
      <Container>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} alignItems="flex-end">
            <Grid item xs={12} mt={2} sx={{ mb: { xs: -1.5, md: -3 } }}>
              <Typography
                variant="subtitle1"
                style={{ color: PgColors.bluepg[400] }}
                sx={{
                  fontSize: { xs: 10, md: 14, lg: 18 },
                  fontWeight: { md: 400 },
                }}
                align="left"
              >
                Proces doel 3:
              </Typography>
            </Grid>
            <Grid item md={10} xs={12}>
              <TextField
                size="small"
                color="warning"
                variant="outlined"
                fullWidth
                multiline
                rows={1}
                value={answer}
                onChange={(event) => setAnswer(event.target.value)}
              />
            </Grid>

            <Grid item md={2} xs={12}>
              <Button
                style={{
                  backgroundColor: PgColors.bluepg[200],
                }}
                variant="contained"
                type="submit"
                size="large"
                sx={{ height: { xs: 30, md: 55, lg: 55 } }}
              >
                <Typography variant="subtitle1">Voeg Toe</Typography>
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </>
  );
};
