import React, { useState, useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import {
  Grid,
  AppBar,
  Container,
  Typography,
  Paper,
  TextField,
  Button,
  Divider,
  Toolbar,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Box,
  Drawer,
  Collapse,
} from "@mui/material";
import { NavLink, Link } from "react-router-dom";
import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import IconButton from "@mui/material/IconButton";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useLogout } from "../../hooks/useLogout";
import { UserList } from "./UserList";
import LibraryBooksOutlinedIcon from "@mui/icons-material/LibraryBooksOutlined";
import SportsMmaOutlinedIcon from "@mui/icons-material/SportsMmaOutlined";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import TrendingUpOutlinedIcon from "@mui/icons-material/TrendingUpOutlined";
import { PgColors } from "../../configs/colors";
import { auth } from "../../firebase/config";

export const AdminHeader = () => {
  const { logout } = useLogout();
  // const { user, adminUser } = useAuthContext();
  const { user } = useAuthContext();

  return (
    <>
      <Box>
        <AppBar
          position="sticky"
          color="transparent"
          // style={{ height: 75, width: "100vh" }}
          sx={{
            height: 75,
            width: { xs: "100vh", md: "1300" },
          }}
        >
          <Container>
            <Grid alignItems="center">
              <Toolbar>
                <Grid item marginRight={1}>
                  <NavLink to="/dashboard/home">
                    <HomeOutlinedIcon sx={{ color: PgColors.bluepg[300] }} />
                  </NavLink>
                </Grid>
                <>
                  <Grid item marginRight={1}>
                    <NavLink to="/dashboard/seasonplans">
                      <CalendarTodayOutlinedIcon
                        sx={{ color: PgColors.bluepg[300] }}
                      />
                    </NavLink>
                  </Grid>
                  <Grid item marginRight={1}>
                    <NavLink to="/dashboard/lessonplans">
                      <LibraryBooksOutlinedIcon
                        sx={{ color: PgColors.bluepg[300] }}
                      />
                    </NavLink>
                  </Grid>
                  <Grid item marginRight={1}>
                    <NavLink to="/dashboard/gymnasts">
                      <ImportContactsIcon
                        sx={{ color: PgColors.bluepg[300] }}
                      />
                    </NavLink>
                  </Grid>
                </>
                <Grid item marginRight={1}>
                  <NavLink to="/dashboard/programs">
                    <SportsMmaOutlinedIcon
                      sx={{ color: PgColors.bluepg[300] }}
                    />
                  </NavLink>
                </Grid>
                <>
                  <Grid item marginRight={1}>
                    <NavLink to={`/dashboard/gymnasts/${user.uid}`}>
                      <TrendingUpOutlinedIcon
                        sx={{ color: PgColors.bluepg[300] }}
                      />
                    </NavLink>
                  </Grid>
                </>
                <Grid item marginRight={1}>
                  <NavLink to="/dashboard/regulations">
                    <ArticleOutlinedIcon sx={{ color: PgColors.bluepg[300] }} />
                  </NavLink>
                </Grid>

                <Grid item marginRight={1}>
                  <IconButton sx={{ color: "#2F5E6D" }} onClick={logout}>
                    <LogoutOutlinedIcon />
                  </IconButton>
                </Grid>
              </Toolbar>
            </Grid>
          </Container>
        </AppBar>
      </Box>
    </>
  );
};
