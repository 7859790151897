import React, { useState, useEffect } from "react";
import { Grid, Container } from "@mui/material";
import { Houdingen1 } from "./Houdingen1";
import { Houdingen2 } from "./Houdingen2";
import { Houdingen3 } from "./Houdingen3";

export const BalkHoudingen = () => {
  return (
    <>
      <Container>
        <Houdingen1 />
        <Houdingen2 />
        <Houdingen3 />
      </Container>
    </>
  );
};
