import React, { useState, useEffect } from "react";
import { Grid, Container } from "@mui/material";
import { Groep1 } from "./Groep1";
import { Groep2 } from "./Groep2";
import { Groep3 } from "./Groep3";
import { Groep4 } from "./Groep4";
import { Groep5 } from "./Groep5";
import { Groep6 } from "./Groep6";
import { GroepTA } from "./GroepTA";

export const Beam = () => {
  return (
    <>
      <Groep1 />

      <Groep2 />

      <Groep3 />

      <Groep4 />

      <Groep5 />

      <Groep6 />

      <GroepTA />
    </>
  );
};
