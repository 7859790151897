import React, { useState } from "react";
import {
  Grid,
  Container,
  Typography,
  Paper,
  TextField,
  Button,
  AppBar,
  Toolbar,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Box,
  Drawer,
} from "@mui/material";
import { AddSeason } from "./AddSeason";

import { PgColors } from "../../configs/colors";
import { useAuthContext } from "../../hooks/useAuthContext";
import { AllSeasons } from "./AllSeasons";

export const Seasons = () => {
  const [active, setActive] = useState("All");
  const { user, adminUser } = useAuthContext();

  return (
    <>
      <Container>
        <Grid
          container
          display="flex"
          sx={{
            justifyContent: { xs: "center", md: "flex-end" },
            marginTop: { xs: 2, sm: 4, lg: 6 },
          }}
          spacing={2}
        >
          <Grid item display="flex" xs={4} md={4}>
            <Button
              style={{
                backgroundColor: PgColors.bluepg[400],
              }}
              variant="contained"
              onClick={() => setActive("Add")}
            >
              <Typography style={{ fontWeight: "semibold" }}>
                ADD SEASONPLAN
              </Typography>
            </Button>
          </Grid>

          <Grid item display="flex" xs={4} md={4}>
            <Button
              style={{
                backgroundColor: PgColors.bluepg[400],
              }}
              variant="contained"
              onClick={() => setActive("All")}
            >
              <Typography style={{ fontWeight: "semibold" }}>
                All SEASONPLANS
              </Typography>
            </Button>
          </Grid>
        </Grid>
        <Grid
          item
          display="flex"
          justifyContent="center"
          xs={12}
          marginTop={4}
          marginBottom={4}
          sx={{ marginLeft: { lg: 36 } }}
        >
          <Paper
            elevation={2}
            sx={{
              minWidth: { xs: 350, sm: 600, md: 880, lg: 850, xl: 1200 },
              maxWidth: { xs: 590, sm: 890, md: 1100 },
              height: { xs: 850 },
            }}
            style={{ overflow: "auto" }}
          >
            {active === "Add" && <AddSeason />}
            {active === "All" && <AllSeasons />}
          </Paper>
        </Grid>
      </Container>
    </>
  );
};
