import React, { useEffect, useState } from "react";
import { db } from "../../firebase/config";
import {
  collection,
  orderBy,
  addDoc,
  serverTimestamp,
  query,
  onSnapshot,
  updateDoc,
  doc,
  setDoc,
  deleteDoc,
} from "firebase/firestore";
import {
  Grid,
  IconButton,
  Container,
  Typography,
  Paper,
  TextField,
  Button,
  AppBar,
  Toolbar,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Box,
  Drawer,
} from "@mui/material";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { useAuthContext } from "../../hooks/useAuthContext";

import { PgColors } from "../../configs/colors";

export const LikesG = ({ path1, path2 }: any) => {
  const [likes, setLikes] = useState([]);
  const [hasLiked, setHasLiked] = useState(false);
  const { user } = useAuthContext();

  const likePost2 = async (id: any) => {
    if (hasLiked) {
      await deleteDoc(doc(db, path2, user.uid));
    } else {
      await setDoc(doc(db, path2, user.uid), {
        uid: user.uid,
      });
    }
  };

  useEffect(() => {
    const ref = query(collection(db, path2));

    const unsub = onSnapshot(ref, (snapshot) => {
      let results: any = [];
      snapshot.docs.forEach((doc) => {
        results.push({ ...doc.data(), id: doc.id });
      });
      setLikes(results);
    });
    return () => unsub();
  }, []);

  useEffect(() => {
    const ref = query(collection(db, path2));

    const unsub = onSnapshot(ref, (snapshot) => {
      let results: any = [];
      snapshot.docs.forEach((doc) => {
        results.push({ ...doc.data(), id: doc.id });
      });
      setLikes(results);
    });
    return () => unsub();
  }, []);

  useEffect(() => {
    setHasLiked(likes.findIndex((like: any) => like.id === user.uid) !== -1);
  }, [likes]);

  return (
    <>
      <Grid container>
        <Grid item xs={2} md={1}>
          {hasLiked ? (
            <IconButton onClick={likePost2}>
              <FavoriteIcon sx={{ color: PgColors.bluepg[300] }} />
            </IconButton>
          ) : (
            <IconButton onClick={likePost2}>
              <FavoriteBorderOutlinedIcon
                sx={{ color: PgColors.bluepg[300] }}
              />
            </IconButton>
          )}
        </Grid>
        <Grid item xs={2} md={2} mt={1}>
          {likes.length > 0 && (
            <Typography fontWeight="bold">{likes.length} likes</Typography>
          )}
        </Grid>
      </Grid>
    </>
  );
};
