import React, { useState, useEffect } from "react";
import {
  collection,
  query,
  onSnapshot,
  updateDoc,
  doc,
  deleteDoc,
} from "firebase/firestore";
import {
  Grid,
  Container,
  Typography,
  Divider,
  Button,
  Paper,
} from "@mui/material";
import { Groep1 } from "./SprongeElementen/Groep1";
import { Sprong } from "./SprongeElementen/Sprong";
import { Brug } from "./BrugElementen/Brug";
import { Beam } from "./BalkElementen/Beam";
import { PgColors } from "../../configs/colors";

export const Elementen = () => {
  const [active, setActive] = useState("Sprong");

  return (
    <>
      <Container>
        <Grid container mt={2} spacing={1}>
          <Grid item>
            <Button
              style={{
                backgroundColor: PgColors.bluepg[200],
              }}
              variant="contained"
              onClick={() => setActive("Sprong")}
            >
              <Typography style={{ fontWeight: "semibold" }}>Sprong</Typography>
            </Button>
          </Grid>
          <Grid item>
            <Button
              style={{
                backgroundColor: PgColors.bluepg[200],
              }}
              variant="contained"
              onClick={() => setActive("Brug")}
            >
              <Typography style={{ fontWeight: "semibold" }}>Brug</Typography>
            </Button>
          </Grid>
          <Grid item>
            <Button
              style={{
                backgroundColor: PgColors.bluepg[200],
              }}
              variant="contained"
              onClick={() => setActive("Balk")}
            >
              <Typography style={{ fontWeight: "semibold" }}>Balk</Typography>
            </Button>
          </Grid>
          <Grid item>
            <Button
              style={{
                backgroundColor: PgColors.bluepg[200],
              }}
              variant="contained"
              onClick={() => setActive("Balk")}
            >
              <Typography style={{ fontWeight: "semibold" }}>Vloer</Typography>
            </Button>
          </Grid>
        </Grid>
        {active === "Sprong" && <Sprong />}
        {active === "Brug" && <Brug />}
        {active === "Balk" && <Beam />}
      </Container>
    </>
  );
};
