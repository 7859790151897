import React, { useState } from "react";
import {
  Grid,
  Container,
  Typography,
  Paper,
  TextField,
  Button,
  AppBar,
  Toolbar,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Box,
  Drawer,
} from "@mui/material";
import { DrawerDashboard } from "../components/home/DrawerDashboard";

export const Profile = () => {
  return (
    <>
      <div
        style={{
          backgroundColor: "#f1fff6",
        }}
      >
        <Container>
          <Grid container justifyContent="center" marginTop={10}>
            <Paper elevation={2} style={{ width: "100%" }}>
              <Container>
                <Grid container justifyContent="center">
                  <Grid item marginTop={2} justifyContent="center">
                    <Typography
                      variant="h3"
                      style={{ color: "#614e4b", fontWeight: 500 }}
                    >
                      Update Your Account
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item marginTop={10}>
                    <Typography variant="body1">Your name:</Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item md={5}>
                    <TextField
                      label="Firstname"
                      fullWidth
                      type="text"
                      variant="outlined"
                    ></TextField>
                  </Grid>
                  <Grid item md={7}>
                    <TextField
                      label="Lastname"
                      fullWidth
                      type="text"
                      variant="outlined"
                    ></TextField>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item marginTop={4}>
                    <Typography variant="body1">Your email address:</Typography>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item md={12}>
                    <TextField
                      label="Email"
                      fullWidth
                      type="email"
                      variant="outlined"
                    ></TextField>
                  </Grid>
                </Grid>
              </Container>
            </Paper>
          </Grid>
        </Container>
      </div>
    </>
  );
};
