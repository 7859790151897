import React, { useState, useEffect } from "react";
import { collection, query, onSnapshot, orderBy } from "firebase/firestore";
import {
  Grid,
  Container,
  Typography,
  Paper,
  Button,
  Box,
  CardMedia,
} from "@mui/material";
import { useAuthContext } from "../../../../hooks/useAuthContext";
import { useParams } from "react-router-dom";
import { db } from "../../../../firebase/config";
import { PgColors } from "../../../../configs/colors";
import {
  AddExercise3A,
  AddExercise3B,
  AddExercise3C,
  AddExercise3D,
} from "./AddExercise3";
import {
  ListExercise3A,
  ListExercise3B,
  ListExercise3C,
  ListExercise3D,
} from "./ListExercise3";

export const GoalCommitmentPage = () => {
  const { authIsReady, user } = useAuthContext();
  const [exercise3AList, setExercise3AList] = useState([]);
  const [exercise3BList, setExercise3BList] = useState([]);
  const [exercise3CList, setExercise3CList] = useState([]);
  const [exercise3DList, setExercise3DList] = useState([]);

  const { id } = useParams();

  useEffect(() => {
    const ref = query(
      collection(db, `IntroExercises/${user.uid}/UserExercise3A`),
      orderBy("createdAt", "desc")
    );

    const unsub = onSnapshot(ref, (snapshot) => {
      let results: any = [];
      snapshot.docs.forEach((doc) => {
        results.push({ ...doc.data(), id: doc.id });
      });
      setExercise3AList(results);
    });
    return () => unsub();
  }, []);

  useEffect(() => {
    const ref = query(
      collection(db, `IntroExercises/${user.uid}/UserExercise3B`),
      orderBy("createdAt", "desc")
    );

    const unsub = onSnapshot(ref, (snapshot) => {
      let results: any = [];
      snapshot.docs.forEach((doc) => {
        results.push({ ...doc.data(), id: doc.id });
      });
      setExercise3BList(results);
    });
    return () => unsub();
  }, []);

  useEffect(() => {
    const ref = query(
      collection(db, `IntroExercises/${user.uid}/UserExercise3C`),
      orderBy("createdAt", "desc")
    );

    const unsub = onSnapshot(ref, (snapshot) => {
      let results: any = [];
      snapshot.docs.forEach((doc) => {
        results.push({ ...doc.data(), id: doc.id });
      });
      setExercise3CList(results);
    });
    return () => unsub();
  }, []);

  useEffect(() => {
    const ref = query(
      collection(db, `IntroExercises/${user.uid}/UserExercise3D`),
      orderBy("createdAt", "desc")
    );

    const unsub = onSnapshot(ref, (snapshot) => {
      let results: any = [];
      snapshot.docs.forEach((doc) => {
        results.push({ ...doc.data(), id: doc.id });
      });
      setExercise3DList(results);
    });
    return () => unsub();
  }, []);

  return (
    <>
      <Grid container mt={2}>
        <Grid item xs={12}>
          <Paper
            style={{ backgroundColor: PgColors.redpg[400] }}
            sx={{ height: { xs: 45, md: 70 } }}
          >
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, sm: 14, md: 16, lg: 20 } }}
              align="center"
            >
              "It's not the will to win that matters - everyone has that. It's
              the will to prepare to win that matters" - Paul "Bear" Bryant
            </Typography>
          </Paper>
        </Grid>
      </Grid>
      <Grid container mt={2}>
        <Grid item xs={12}>
          <Typography
            variant="subtitle1"
            style={{ color: PgColors.bluepg[400] }}
            sx={{
              fontSize: { xs: 16, md: 20, lg: 24 },
              fontWeight: { md: 500 },
            }}
            align="left"
          >
            Opdracht 3
          </Typography>
        </Grid>

        <Grid item xs={12} mt={1}>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
            }}
            align="left"
          >
            Creëer voor jezelf een strategie om je te helpen met het navolgen
            van je doelen. Als je dan een periode wat minder motivatie hebt of
            het lastiger vindt om de progressie te zien dan kan dit je helpen om
            toch je doelen te halen.
          </Typography>
        </Grid>
        <Grid item xs={12} mt={1}>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
            }}
            align="left"
          >
            Voorbeelden zijn:
          </Typography>
        </Grid>
        <Grid item xs={12} mt={1}>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
            }}
            align="left"
          >
            - Plaats je doelen ergens waar je ze zelf vaak kunt zien
          </Typography>
        </Grid>
        <Grid item xs={12} mt={1}>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
            }}
            align="left"
          >
            - Bespreek je doelen met andere turn(st)ers
          </Typography>
        </Grid>
        <Grid item xs={12} mt={1}>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
            }}
            align="left"
          >
            - Bespreek je doelen met een trainer
          </Typography>
        </Grid>
      </Grid>

      <Grid container mt={2}>
        <Grid item xs={12} mt={1}>
          <Typography
            variant="subtitle1"
            style={{ color: PgColors.bluepg[400] }}
            sx={{
              fontSize: { xs: 12, md: 16, lg: 20 },
              fontWeight: { md: 400 },
            }}
            align="left"
          >
            Stap 1
          </Typography>
        </Grid>
        <Grid item xs={12} mt={1}>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
            }}
            align="left"
          >
            Schrijf je strategie(ën) op:
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <AddExercise3A />
      </Grid>
      <Grid container mt={4} mb={2}>
        {exercise3AList && (
          <ListExercise3A
            exercises={exercise3AList}
            path={`IntroExercises/${id}/UserExercise3A/${id}`}
          />
        )}
      </Grid>
      <Grid container mt={2}>
        <Grid item xs={12} mt={1}>
          <Typography
            variant="subtitle1"
            style={{ color: PgColors.bluepg[400] }}
            sx={{
              fontSize: { xs: 12, md: 16, lg: 20 },
              fontWeight: { md: 400 },
            }}
            align="left"
          >
            Stap 2
          </Typography>
        </Grid>
        <Grid item xs={12} mt={1}>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
            }}
            align="left"
          >
            Hoe ga je om met tegenslagen? Identificeer 1 tot 3 mogelijke
            tegenslagen die je progressie van je prestatie of resultaat doelen
            in de weg kunnen zitten. Bedenk per tegenslag ook strategie om deze
            te verhelpen.
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <AddExercise3B />
      </Grid>
      <Grid container mt={0} mb={2}>
        {exercise3BList && (
          <ListExercise3B
            exercises={exercise3BList}
            path={`IntroExercises/${id}/UserExercise3B/${id}`}
          />
        )}
      </Grid>
      <Grid container mt={2}>
        <AddExercise3C />
      </Grid>
      <Grid container mt={0} mb={2}>
        {exercise3CList && (
          <ListExercise3C
            exercises={exercise3CList}
            path={`IntroExercises/${id}/UserExercise3C/${id}`}
          />
        )}
      </Grid>
      <Grid container mt={2}>
        <AddExercise3D />
      </Grid>
      <Grid container mt={0} mb={2}>
        {exercise3DList && (
          <ListExercise3D
            exercises={exercise3DList}
            path={`IntroExercises/${id}/UserExercise3D/${id}`}
          />
        )}
      </Grid>
    </>
  );
};
