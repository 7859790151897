import React, { useState, useEffect } from "react";
import {
  collection,
  query,
  orderBy,
  onSnapshot,
  updateDoc,
  doc,
  deleteDoc,
} from "firebase/firestore";
import { db } from "../../firebase/config";
import { Grid, Container, Typography, Divider, Button } from "@mui/material";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useNavigate } from "react-router-dom";

export const AllLessons = () => {
  const { authIsReady, user } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [lessonList, setLessonList] = useState([]);
  const navigate = useNavigate();

  // useEffect(() => {
  //   const ref = query(collection(db, `LessonsByCoach/${user.uid}/Lessons`));

  //   const unsub = onSnapshot(ref, (snapshot) => {
  //     let results: any = [];
  //     snapshot.docs.forEach((doc) => {
  //       results.push({ ...doc.data(), id: doc.id });
  //     });
  //     setLessonList(results);
  //   });
  //   return () => unsub();
  // }, []);

  useEffect(() => {
    if (authIsReady && user) {
      const ref = query(
        collection(db, `LessonsByCoach/${user.uid}/Lessons`),
        orderBy("createdAt", "desc")
      );

      const unsub = onSnapshot(ref, (snapshot) => {
        let results: any = [];
        snapshot.docs.forEach((doc) => {
          results.push({ ...doc.data(), id: doc.id });
        });
        setLessonList(results);
      });

      return () => unsub();
    }
  }, [authIsReady, user]);

  const handleEvent = (
    params: any,
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    navigate(`LessonsByCoach/${user.uid}/Lessons/${params.id}`);
  };

  if (!authIsReady) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <Container>
        <Grid container>
          <Grid item marginTop={6}>
            <Typography
              variant="h6"
              style={{ color: "#6B3239", fontWeight: "bold" }}
            >
              All Lessons:
            </Typography>
          </Grid>
        </Grid>
        {lessonList.map((lesson: any) => (
          <>
            <Grid container mt={2}>
              <Grid item>
                <Button
                  variant="text"
                  onClick={(e) => navigate(`${lesson.id}`)}
                >
                  <Typography
                    variant="h6"
                    style={{
                      color: "#000000",
                      fontWeight: "400",
                      fontSize: 20,
                    }}
                  >
                    {lesson.name}
                  </Typography>
                </Button>
              </Grid>
            </Grid>
            {/* const Component =  */}
            <Divider></Divider>
          </>
        ))}
      </Container>
    </>
  );
};
