import React, { useState, useEffect } from "react";
import { Grid, Container } from "@mui/material";
import { SupBIntro } from "./SupBIntro";
import { SupBSE } from "./SupBSE";
import { SupBVW } from "./SupBVW";

export const SupB = () => {
  return (
    <>
      <Container>
        <SupBIntro />
        <SupBSE />
        <SupBVW />
      </Container>
    </>
  );
};
