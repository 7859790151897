import React, { useState, useEffect } from "react";
import { Grid, Container } from "@mui/material";
import { SupHIntro } from "./SupHIntro";
import { SupHSE } from "./SupHSE";
import { SupHVW } from "./SupHVW";

export const SupH = () => {
  return (
    <>
      <Container>
        <SupHIntro />
        <SupHSE />
        <SupHVW />
      </Container>
    </>
  );
};
