import React, { useEffect, useState } from "react";
import { collection, query, onSnapshot } from "firebase/firestore";
import {
  Grid,
  Container,
  Typography,
  Paper,
  Box,
  Divider,
} from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridEventListener,
  MuiEvent,
} from "@mui/x-data-grid";
import { NavLink } from "react-router-dom";
import { useAuthContext } from "../../hooks/useAuthContext";
import { db } from "../../firebase/config";
import { AddCompetition } from "../Gymnasts/AddCompetition";

export const Competition = () => {
  const { user } = useAuthContext();
  const [competitionsList, setCompetitionsList] = useState([]);

  useEffect(() => {
    const ref = query(
      collection(db, "CompetitionsByCoach", user.uid, "Competitions")
    );

    const unsub = onSnapshot(ref, (snapshot) => {
      let results: any = [];
      snapshot.docs.forEach((doc) => {
        results.push({ ...doc.data(), id: doc.id });
      });
      setCompetitionsList(results);
    });
    return () => unsub();
  }, []);

  const columns: GridColDef[] = [
    { field: "name", headerName: "Name", width: 150 },
    { field: "competition", headerName: "Competition", width: 180 },
    { field: "date", headerName: "Date", width: 100 },
    {
      field: "pTotal",
      headerName: "Place",
      width: 70,
      cellClassName: "super-app",
    },
    {
      field: "tTotal",
      headerName: "Total",
      width: 70,
      cellClassName: "super-app",
    },
    {
      field: "dVault1",
      headerName: "D V1",
      width: 70,
      cellClassName: "super-app-vault",
    },
    {
      field: "eVault1",
      headerName: "E V1",
      width: 70,
      cellClassName: "super-app-vault",
    },
    {
      field: "tVault1",
      headerName: "T Vault",
      width: 70,
      cellClassName: "super-app-vault",
    },
    {
      field: "dVault2",
      headerName: "D V2",
      width: 70,
      cellClassName: "super-app-vault",
    },
    {
      field: "eVault2",
      headerName: "E V2",
      width: 70,
      cellClassName: "super-app-vault",
    },
    {
      field: "tVault2",
      headerName: "T Vault",
      width: 70,
      cellClassName: "super-app-vault",
    },
    {
      field: "pVault",
      headerName: "Pl",
      width: 50,
      cellClassName: "super-app-vault",
    },
    {
      field: "dBars",
      headerName: "D Bars",
      width: 70,
      cellClassName: "super-app",
    },
    {
      field: "eBars",
      headerName: "E Bars",
      width: 70,
      cellClassName: "super-app",
    },
    {
      field: "tBars",
      headerName: "T Bars",
      width: 70,
      cellClassName: "super-app",
    },
    {
      field: "pBars",
      headerName: "Pl",
      width: 50,
      cellClassName: "super-app",
    },
    {
      field: "dBeam",
      headerName: "D Beam",
      width: 70,
      cellClassName: "super-app-vault",
    },
    {
      field: "eBeam",
      headerName: "E Beam",
      width: 70,
      cellClassName: "super-app-vault",
    },
    {
      field: "tBeam",
      headerName: "T Beam",
      width: 70,
      cellClassName: "super-app-vault",
    },
    {
      field: "pBeam",
      headerName: "Pl",
      width: 50,
      cellClassName: "super-app-vault",
    },
    {
      field: "dFloor",
      headerName: "D Floor",
      width: 70,
      cellClassName: "super-app",
    },
    {
      field: "eFloor",
      headerName: "E Floor",
      width: 70,
      cellClassName: "super-app",
    },
    {
      field: "tFloor",
      headerName: "T Floor",
      width: 70,
      cellClassName: "super-app",
    },
    {
      field: "pFloor",
      headerName: "Pl",
      width: 50,
      cellClassName: "super-app",
    },
  ];

  return (
    <>
      <Grid container marginTop={14} ml={4}>
        <Grid item>
          <AddCompetition />
        </Grid>
      </Grid>
      <Grid container mt={4} justifyContent="center" mx={4}>
        <Paper elevation={2} style={{ width: "100%", height: 1100 }}>
          <Container maxWidth={false}>
            <Grid container>
              <Grid item marginTop={6}>
                <Typography
                  variant="h6"
                  style={{ color: "#a78782", fontWeight: "bold" }}
                >
                  Competitions
                </Typography>
              </Grid>
            </Grid>
            <Box
              style={{ height: 750, width: "100%" }}
              sx={{
                "& .super-app": {
                  backgroundColor: "#D4B2A7",
                },
                "& .super-app-vault": {
                  backgroundColor: "#9FC6D1",
                },
              }}
            >
              <DataGrid
                rows={competitionsList}
                columns={columns}
                pageSize={50}
                rowsPerPageOptions={[14]}
              />
            </Box>
          </Container>
        </Paper>
      </Grid>
      <Divider></Divider>
    </>
  );
};
