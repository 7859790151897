import React, { useState, useEffect, FC } from "react";
import {
  Grid,
  Container,
  Typography,
  Divider,
  Box,
  Button,
  Paper,
} from "@mui/material";
import { PgColors } from "../../../configs/colors";

type TextProps = {
  text: string;
};

export const ContentPaper: FC<TextProps> = ({ text }) => {
  return (
    <Typography
      variant="subtitle1"
      sx={{
        color: "white",
        fontSize: { xs: 12, sm: 14, md: 16 },
      }}
      align="left"
    >
      {text}
    </Typography>
  );
};

export const TitlePaper: FC<TextProps> = ({ text }) => {
  return (
    <Typography
      variant="subtitle1"
      style={{ fontWeight: 500 }}
      sx={{
        color: "white",
        fontSize: { xs: 12, sm: 14, md: 16 },
      }}
      align="left"
    >
      {text}
    </Typography>
  );
};

export const Content: FC<TextProps> = ({ text }) => {
  return (
    <Typography
      variant="subtitle1"
      style={{ color: PgColors.redpg[500] }}
      align="left"
    >
      {text}
    </Typography>
  );
};

export const Houdingen2 = () => {
  return (
    <>
      <Grid container mt={2} marginLeft={2}>
        <Grid item>
          <Typography variant="h6" style={{ color: PgColors.redpg[500] }}>
            Balk houdingen serie 2: supplement 3 en 4
          </Typography>
        </Grid>
      </Grid>
      <Grid container marginLeft={2}>
        <Grid item>
          <Content
            text={
              "*Uitgangspositie: Basishouding: voeten 5e positie links voor; armen laag. Tijdens de serie zijn de armen zijwaarts (2e positie) met de schouders laag, tenzij anders beschreven."
            }
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item mt={0} md={12}>
          <Paper
            elevation={2}
            style={{ backgroundColor: PgColors.bluepg[100], width: "100%" }}
          >
            <Container>
              <Grid container>
                <Grid item xs={2} md={1} sx={{ marginRight: { md: -4 } }}>
                  <TitlePaper text={"Maat"} />
                </Grid>
                <Grid item xs={2} md={1} sx={{ marginRight: { md: -4 } }}>
                  <TitlePaper text={"Tel"} />
                </Grid>
                <Grid item xs={8} sx={{ marginRight: { md: 8 } }}>
                  <TitlePaper
                    text={
                      "De turnster mag voorkeursbeen kiezen; tweezijdige elementen dienen li en re uitgevoerd te worden."
                    }
                  />
                </Grid>
                <Grid
                  item
                  md={2}
                  sx={{ marginRight: { md: 0 } }}
                  display={{ xs: "none", md: "flex" }}
                >
                  <TitlePaper text={"Symbolen"} />
                </Grid>
              </Grid>
              <Divider></Divider>
              <Grid container sx={{ marginBottom: { md: -2 } }}>
                <Grid item xs={2} md={1} sx={{ marginRight: { md: -4 } }}>
                  <ContentPaper text={"1"} />
                </Grid>
                <Grid item xs={2} md={1} sx={{ marginRight: { md: -4 } }}>
                  <ContentPaper text={"1-2"} />
                </Grid>
                <Grid item xs={8} sx={{ marginRight: { md: 8 } }}>
                  <ContentPaper
                    text={
                      "Stap vw op li, beenzwaai voor horizontaal met re been; armen zwaaien via laag naar voor"
                    }
                  />
                </Grid>
                <Grid
                  item
                  md={2}
                  sx={{ marginRight: { md: 0 } }}
                  display={{ xs: "none", md: "flex" }}
                >
                  <Box
                    component="img"
                    alt="symbol2"
                    src="../images/balkhoudingen/Symbol9.png"
                    sx={{ maxHeight: { xs: 35, md: 65 } }}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid
                  item
                  xs={2}
                  md={1}
                  sx={{ marginRight: { md: -4 } }}
                ></Grid>
                <Grid item xs={2} md={1} sx={{ marginRight: { md: -4 } }}>
                  <ContentPaper text={"3-4"} />
                </Grid>
                <Grid item xs={8} sx={{ marginRight: { md: 8 } }}>
                  <ContentPaper
                    text={
                      "Met re beenzwaai achter horizontaal; armen gaan naar zw"
                    }
                  />
                </Grid>
                <Grid
                  item
                  md={2}
                  sx={{ marginRight: { md: 0 } }}
                  display={{ xs: "none", md: "flex" }}
                ></Grid>
              </Grid>
              <Grid container sx={{ marginBottom: { md: -2 } }}>
                <Grid
                  item
                  xs={2}
                  md={1}
                  sx={{ marginRight: { md: -4 } }}
                ></Grid>
                <Grid item xs={2} md={1} sx={{ marginRight: { md: -4 } }}>
                  <ContentPaper text={"5-6"} />
                </Grid>
                <Grid item xs={8} sx={{ marginRight: { md: 8 } }}>
                  <ContentPaper
                    text={
                      "Stap vw op re, beenzwaai voor horizontaal met li been; armen zwaaien via laag naar voor "
                    }
                  />
                </Grid>
                <Grid
                  item
                  md={2}
                  sx={{ marginRight: { md: 0 } }}
                  display={{ xs: "none", md: "flex" }}
                >
                  <Box
                    component="img"
                    alt="symbol2"
                    src="../images/balkhoudingen/Symbol9.png"
                    sx={{ maxHeight: { xs: 35, md: 65 } }}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid
                  item
                  xs={2}
                  md={1}
                  sx={{ marginRight: { md: -4 } }}
                ></Grid>
                <Grid item xs={2} md={1} sx={{ marginRight: { md: -4 } }}>
                  <ContentPaper text={"7-8"} />
                </Grid>
                <Grid item xs={8} sx={{ marginRight: { md: 8 } }}>
                  <ContentPaper
                    text={
                      "Met li beenzwaai achter horizontaal, armen gaan naar zw"
                    }
                  />
                </Grid>
                <Grid
                  item
                  md={2}
                  sx={{ marginRight: { md: 0 } }}
                  display={{ xs: "none", md: "flex" }}
                ></Grid>
              </Grid>
              <Divider></Divider>
              <Grid container sx={{ marginBottom: { md: -5 } }}>
                <Grid item xs={2} md={1} sx={{ marginRight: { md: -4 } }}>
                  <ContentPaper text={"2"} />
                </Grid>
                <Grid item xs={2} md={1} sx={{ marginRight: { md: -4 } }}>
                  <ContentPaper text={"1-2"} />
                </Grid>
                <Grid item xs={8} sx={{ marginRight: { md: 8 } }}>
                  <ContentPaper
                    text={"Stap vw op li, re been naar passé parallel"}
                  />
                </Grid>
                <Grid
                  item
                  md={2}
                  sx={{ marginRight: { md: 0 } }}
                  display={{ xs: "none", md: "flex" }}
                >
                  <Box
                    component="img"
                    alt="symbol2"
                    src="../images/balkhoudingen/Symbol10.png"
                    sx={{ maxHeight: { xs: 35, md: 65 } }}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid
                  item
                  xs={2}
                  md={1}
                  sx={{ marginRight: { md: -4 } }}
                ></Grid>
                <Grid item xs={2} md={1} sx={{ marginRight: { md: -4 } }}>
                  <ContentPaper text={"3-4"} />
                </Grid>
                <Grid item xs={8} sx={{ marginRight: { md: 8 } }}>
                  <ContentPaper
                    text={
                      "Re been strekt uit naar horizontaal voor (developpé), li voet naar relevé"
                    }
                  />
                </Grid>
                <Grid
                  item
                  md={2}
                  sx={{ marginRight: { md: 0 } }}
                  display={{ xs: "none", md: "flex" }}
                ></Grid>
              </Grid>
              <Grid container sx={{ marginBottom: { md: -5 } }}>
                <Grid
                  item
                  xs={2}
                  md={1}
                  sx={{ marginRight: { md: -4 } }}
                ></Grid>
                <Grid item xs={2} md={1} sx={{ marginRight: { md: -4 } }}>
                  <ContentPaper text={"5-6"} />
                </Grid>
                <Grid item xs={8} sx={{ marginRight: { md: 8 } }}>
                  <ContentPaper
                    text={
                      "Stap vu relevé vw op rechts, li been naar passé parallel"
                    }
                  />
                </Grid>
                <Grid
                  item
                  md={2}
                  sx={{ marginRight: { md: 0 } }}
                  display={{ xs: "none", md: "flex" }}
                >
                  <Box
                    component="img"
                    alt="symbol2"
                    src="../images/balkhoudingen/Symbol10.png"
                    sx={{ maxHeight: { xs: 35, md: 65 } }}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid
                  item
                  xs={2}
                  md={1}
                  sx={{ marginRight: { md: -4 } }}
                ></Grid>
                <Grid item xs={2} md={1} sx={{ marginRight: { md: -4 } }}>
                  <ContentPaper text={"7-8"} />
                </Grid>
                <Grid item xs={8} sx={{ marginRight: { md: 8 } }}>
                  <ContentPaper
                    text={
                      "Li been strekt uit naar horizontaal voor (developpé), recht voet naar relevé "
                    }
                  />
                </Grid>
                <Grid
                  item
                  md={2}
                  sx={{ marginRight: { md: 0 } }}
                  display={{ xs: "none", md: "flex" }}
                ></Grid>
              </Grid>
              <Divider></Divider>
              <Grid container sx={{ marginBottom: { md: -6 } }}>
                <Grid item xs={2} md={1} sx={{ marginRight: { md: -4 } }}>
                  <ContentPaper text={"3"} />
                </Grid>
                <Grid item xs={2} md={1} sx={{ marginRight: { md: -4 } }}>
                  <ContentPaper text={"1-2"} />
                </Grid>
                <Grid item xs={8} sx={{ marginRight: { md: 8 } }}>
                  <ContentPaper
                    text={
                      "Stap vu relevé op li, met ¼ draai re, re voet sluit aan tot gesloten parallel stand"
                    }
                  />
                </Grid>
                <Grid
                  item
                  md={2}
                  sx={{ marginRight: { md: 0 } }}
                  display={{ xs: "none", md: "flex" }}
                >
                  <Box
                    component="img"
                    alt="symbol2"
                    src="../images/balkhoudingen/Symbol11.png"
                    sx={{ maxHeight: { xs: 35, md: 65 } }}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid
                  item
                  xs={2}
                  md={1}
                  sx={{ marginRight: { md: -4 } }}
                ></Grid>
                <Grid item xs={2} md={1} sx={{ marginRight: { md: -4 } }}>
                  <ContentPaper text={"3-4"} />
                </Grid>
                <Grid item xs={8} sx={{ marginRight: { md: 8 } }}>
                  <ContentPaper
                    text={
                      "Romp maat een diepe buiging vo naar laag; armen met een vloeiende beweging via zw naar beneden; handpalmen naar elkaar toe"
                    }
                  />
                </Grid>
                <Grid
                  item
                  md={2}
                  sx={{ marginRight: { md: 0 } }}
                  display={{ xs: "none", md: "flex" }}
                ></Grid>
              </Grid>
              <Grid container sx={{ marginBottom: { md: -4 } }}>
                <Grid
                  item
                  xs={2}
                  md={1}
                  sx={{ marginRight: { md: -4 } }}
                ></Grid>
                <Grid item xs={2} md={1} sx={{ marginRight: { md: -4 } }}>
                  <ContentPaper text={"5-6"} />
                </Grid>
                <Grid item xs={8} sx={{ marginRight: { md: 8 } }}>
                  <ContentPaper
                    text={
                      "Met de romp terug rechtop komen; armen via voor naar rond hoog; handpalmen naar elkaar toe"
                    }
                  />
                </Grid>
                <Grid
                  item
                  md={2}
                  sx={{ marginRight: { md: 0 } }}
                  display={{ xs: "none", md: "flex" }}
                >
                  <Box
                    component="img"
                    alt="symbol2"
                    src="../images/balkhoudingen/Symbol12.png"
                    sx={{ maxHeight: { xs: 35, md: 75 } }}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid
                  item
                  xs={2}
                  md={1}
                  sx={{ marginRight: { md: -4 } }}
                ></Grid>
                <Grid item xs={2} md={1} sx={{ marginRight: { md: -4 } }}>
                  <ContentPaper text={"7-8"} />
                </Grid>
                <Grid item xs={8} sx={{ marginRight: { md: 8 } }}>
                  <ContentPaper
                    text={
                      "Buigign van het lichaam aw met hoofd achterover (cambré van bovenrug); armen rond hoog; handpalmen naar elkaar toe"
                    }
                  />
                </Grid>
                <Grid
                  item
                  md={2}
                  sx={{ marginRight: { md: 0 } }}
                  display={{ xs: "none", md: "flex" }}
                ></Grid>
              </Grid>
              <Divider></Divider>
              <Grid container>
                <Grid item xs={2} md={1} sx={{ marginRight: { md: -4 } }}>
                  <ContentPaper text={"4"} />
                </Grid>
                <Grid item xs={2} md={1} sx={{ marginRight: { md: -4 } }}>
                  <ContentPaper text={"1-2"} />
                </Grid>
                <Grid item xs={8} sx={{ marginRight: { md: 8 } }}>
                  <ContentPaper
                    text={
                      "Terug rechtop komen; armen rond hoog; handpalmen naar elkaar toe"
                    }
                  />
                </Grid>
                <Grid
                  item
                  md={2}
                  sx={{ marginRight: { md: 0 } }}
                  display={{ xs: "none", md: "flex" }}
                ></Grid>
              </Grid>
              <Grid container>
                <Grid
                  item
                  xs={2}
                  md={1}
                  sx={{ marginRight: { md: -4 } }}
                ></Grid>
                <Grid item xs={2} md={1} sx={{ marginRight: { md: -4 } }}>
                  <ContentPaper text={"3"} />
                </Grid>
                <Grid item xs={8} sx={{ marginRight: { md: 8 } }}>
                  <ContentPaper
                    text={
                      "¼ draai naar relevé; armen hoog; handpalmen naar elkaar toe"
                    }
                  />
                </Grid>
                <Grid
                  item
                  md={2}
                  sx={{ marginRight: { md: 0 } }}
                  display={{ xs: "none", md: "flex" }}
                ></Grid>
              </Grid>
              <Grid container sx={{ marginBottom: { md: -6 } }}>
                <Grid
                  item
                  xs={2}
                  md={1}
                  sx={{ marginRight: { md: -4 } }}
                ></Grid>
                <Grid item xs={2} md={1} sx={{ marginRight: { md: -4 } }}>
                  <ContentPaper text={"4"} />
                </Grid>
                <Grid item xs={8} sx={{ marginRight: { md: 8 } }}>
                  <ContentPaper text={"Komen tot hurkzit; armen laag"} />
                </Grid>
                <Grid
                  item
                  md={2}
                  sx={{ marginRight: { md: 0 } }}
                  display={{ xs: "none", md: "flex" }}
                >
                  <Box
                    component="img"
                    alt="symbol2"
                    src="../images/balkhoudingen/Symbol13.png"
                    sx={{ maxHeight: { xs: 35, md: 75 } }}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid
                  item
                  xs={2}
                  md={1}
                  sx={{ marginRight: { md: -4 } }}
                ></Grid>
                <Grid item xs={2} md={1} sx={{ marginRight: { md: -4 } }}>
                  <ContentPaper text={"5-6"} />
                </Grid>
                <Grid item xs={8} sx={{ marginRight: { md: 8 } }}>
                  <ContentPaper text={"¼ draai in hurkzit; armen laag"} />
                </Grid>
                <Grid
                  item
                  md={2}
                  sx={{ marginRight: { md: 0 } }}
                  display={{ xs: "none", md: "flex" }}
                ></Grid>
              </Grid>
              <Grid container>
                <Grid
                  item
                  xs={2}
                  md={1}
                  sx={{ marginRight: { md: -4 } }}
                ></Grid>
                <Grid item xs={2} md={1} sx={{ marginRight: { md: -4 } }}>
                  <ContentPaper text={"7-8"} />
                </Grid>
                <Grid item xs={8} sx={{ marginRight: { md: 8 } }}>
                  <ContentPaper
                    text={"Komen tot stand in 5e positie; armen laag"}
                  />
                </Grid>
                <Grid
                  item
                  md={2}
                  sx={{ marginRight: { md: 0 } }}
                  display={{ xs: "none", md: "flex" }}
                ></Grid>
              </Grid>

              <Divider></Divider>
              <Grid container>
                <Grid item xs={2} md={1} sx={{ marginRight: { md: -4 } }}>
                  <ContentPaper text={"5"} />
                </Grid>
                <Grid item xs={2} md={1} sx={{ marginRight: { md: -4 } }}>
                  <ContentPaper text={"1-8"} />
                </Grid>
                <Grid item xs={8} sx={{ marginRight: { md: 8 } }}>
                  <ContentPaper
                    text={"Zweefstand (90º), 1 sec vasthouden; armen vrij"}
                  />
                </Grid>
                <Grid
                  item
                  md={2}
                  sx={{ marginRight: { md: 0 } }}
                  display={{ xs: "none", md: "flex" }}
                >
                  <Box
                    component="img"
                    alt="symbol2"
                    src="../images/balkhoudingen/Symbol4.png"
                    sx={{ maxHeight: { xs: 35, md: 75 } }}
                  />
                </Grid>
              </Grid>
              <Divider></Divider>
              <Grid container sx={{ marginBottom: { md: -3 } }}>
                <Grid item xs={2} md={1} sx={{ marginRight: { md: -4 } }}>
                  <ContentPaper text={"6"} />
                </Grid>
                <Grid item xs={2} md={1} sx={{ marginRight: { md: -4 } }}>
                  <ContentPaper text={"1-3"} />
                </Grid>
                <Grid item xs={8} sx={{ marginRight: { md: 8 } }}>
                  <ContentPaper
                    text={
                      "3 stappen vw in hoge relevé met benen in plié; handen in de zij."
                    }
                  />
                </Grid>
                <Grid
                  item
                  md={2}
                  sx={{ marginRight: { md: 0 } }}
                  display={{ xs: "none", md: "flex" }}
                >
                  <Box
                    component="img"
                    alt="symbol2"
                    src="../images/balkhoudingen/Symbol14.png"
                    sx={{ maxHeight: { xs: 35, md: 75 } }}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid
                  item
                  xs={2}
                  md={1}
                  sx={{ marginRight: { md: -4 } }}
                ></Grid>
                <Grid item xs={2} md={1} sx={{ marginRight: { md: -4 } }}>
                  <ContentPaper text={"4"} />
                </Grid>
                <Grid item xs={8} sx={{ marginRight: { md: 8 } }}>
                  <ContentPaper
                    text={"Rechtop komen tot 5e positie re voet voor"}
                  />
                </Grid>
                <Grid
                  item
                  md={2}
                  sx={{ marginRight: { md: 0 } }}
                  display={{ xs: "none", md: "flex" }}
                ></Grid>
              </Grid>
              <Grid container sx={{ marginBottom: { md: -5 } }}>
                <Grid
                  item
                  xs={2}
                  md={1}
                  sx={{ marginRight: { md: -4 } }}
                ></Grid>
                <Grid item xs={2} md={1} sx={{ marginRight: { md: -4 } }}>
                  <ContentPaper text={"5"} />
                </Grid>
                <Grid item xs={8} sx={{ marginRight: { md: 8 } }}>
                  <ContentPaper text={"Beenzwaai zw met li been"} />
                </Grid>
                <Grid
                  item
                  md={2}
                  sx={{ marginRight: { md: 0 } }}
                  display={{ xs: "none", md: "flex" }}
                >
                  <Box
                    component="img"
                    alt="symbol2"
                    src="../images/balkhoudingen/Symbol15.png"
                    sx={{ maxHeight: { xs: 35, md: 75 } }}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid
                  item
                  xs={2}
                  md={1}
                  sx={{ marginRight: { md: -4 } }}
                ></Grid>
                <Grid item xs={2} md={1} sx={{ marginRight: { md: -4 } }}>
                  <ContentPaper text={"6"} />
                </Grid>
                <Grid item xs={8} sx={{ marginRight: { md: 8 } }}>
                  <ContentPaper
                    text={"Li voet voor aansluiten tot 5e positie"}
                  />
                </Grid>
                <Grid
                  item
                  md={2}
                  sx={{ marginRight: { md: 0 } }}
                  display={{ xs: "none", md: "flex" }}
                ></Grid>
              </Grid>
              <Grid container sx={{ marginBottom: { md: -5 } }}>
                <Grid
                  item
                  xs={2}
                  md={1}
                  sx={{ marginRight: { md: -4 } }}
                ></Grid>
                <Grid item xs={2} md={1} sx={{ marginRight: { md: -4 } }}>
                  <ContentPaper text={"7"} />
                </Grid>
                <Grid item xs={8} sx={{ marginRight: { md: 8 } }}>
                  <ContentPaper text={"Beenzwaai zw met re been"} />
                </Grid>
                <Grid
                  item
                  md={2}
                  sx={{ marginRight: { md: 0 } }}
                  display={{ xs: "none", md: "flex" }}
                >
                  <Box
                    component="img"
                    alt="symbol2"
                    src="../images/balkhoudingen/Symbol15.png"
                    sx={{ maxHeight: { xs: 35, md: 75 } }}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid
                  item
                  xs={2}
                  md={1}
                  sx={{ marginRight: { md: -4 } }}
                ></Grid>
                <Grid item xs={2} md={1} sx={{ marginRight: { md: -4 } }}>
                  <ContentPaper text={"8"} />
                </Grid>
                <Grid item xs={8} sx={{ marginRight: { md: 8 } }}>
                  <ContentPaper
                    text={"Re voet voor aansluiten tot 5e positie"}
                  />
                </Grid>
                <Grid
                  item
                  md={2}
                  sx={{ marginRight: { md: 0 } }}
                  display={{ xs: "none", md: "flex" }}
                ></Grid>
              </Grid>
            </Container>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};
