import React, { useState, useEffect } from "react";
import {
  Grid,
  Container,
  Typography,
  Paper,
  TextField,
  Button,
  AppBar,
  Toolbar,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Box,
  Drawer,
} from "@mui/material";
import { ModalInfo } from "./ModalInfo";
import {
  collection,
  orderBy,
  getDoc,
  addDoc,
  serverTimestamp,
  query,
  onSnapshot,
  updateDoc,
  doc,
  deleteDoc,
} from "firebase/firestore";
import { db } from "../../firebase/config";
import { useAuthContext } from "../../hooks/useAuthContext";
import { Info } from "./Info";
import { PgColors } from "../../configs/colors";
import { Feed } from "./Feed";

export const Home = () => {
  const [gymnast, setGymnast] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { authIsReady, user } = useAuthContext();
  const [infoGList, setInfoGList] = useState([]);
  const [infos, setInfos] = useState([]);
  const [infosG, setInfosG] = useState([]);
  const [comment, setComment] = useState([]);
  const [name, setName] = useState([]);

  // useEffect(() => {
  //   const fetchGymnastData = async () => {
  //     setIsLoading(true);
  //     const docSnap = await getDoc(doc(db, `users/${user.uid}`));
  //     if (docSnap.exists()) {
  //       const newGymnastObj = {
  //         id: docSnap.id,
  //         ...docSnap.data(),
  //       };
  //       setGymnast(newGymnastObj);
  //       setIsLoading(false);
  //     } else {
  //       //doc.data() will be undefined in this case
  //       console.log("No such document");
  //     }
  //   };
  //   fetchGymnastData();
  //   //eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    const fetchGymnastData = async () => {
      setIsLoading(true);

      if (authIsReady && user) {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        // console.log("user:", user);
        try {
          const docSnap = await getDoc(doc(db, `users/${user.uid}`));
          console.log("user after getDoc:", user.uid);
          if (docSnap.exists()) {
            const newGymnastObj = {
              id: docSnap.id,
              ...docSnap.data(),
            };
            setGymnast(newGymnastObj);
            setIsLoading(false);
          } else {
            //doc.data() will be undefined in this case
            console.log("No such document");
          }
        } catch (error) {
          console.error("Error fetching user document:", error);
        }
      }
    };

    fetchGymnastData();
  }, [authIsReady, user]);

  if (!authIsReady) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <Container>
        <Grid
          container
          display="flex"
          sx={{
            justifyContent: { xs: "center" },

            marginTop: { xs: 2, sm: 4, lg: 6 },
          }}
          spacing={2}
        >
          <Grid item display="flex" xs={4} md={4}>
            <ModalInfo gymnast={gymnast} />
          </Grid>
        </Grid>
        {/* <Grid
          item
          display="flex"
          justifyContent="center"
          xs={12}
          marginTop={4}
          sx={{ marginLeft: { lg: 36 } }}
        > */}
        {/* <Paper
            elevation={2}
            sx={{
              minWidth: { xs: 350, sm: 600, md: 880, lg: 850, xl: 1200 },
              maxWidth: { xs: 590, sm: 890, md: 1100 },
              height: { xs: 850 },
            }}
            style={{ overflow: "auto" }}
          > */}
        {gymnast ? (
          <Feed path={`InfoByCoach/${user.uid}/Infos`} gymnast={gymnast} />
        ) : (
          <p>Loading gymnast data...</p>
        )}
        {/* </Paper> */}
        {/* </Grid> */}
      </Container>
    </>
  );
};
