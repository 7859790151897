import React, { useState, useEffect } from "react";
import { Grid, Container } from "@mui/material";
import { SupDIntro } from "./SupDintro";
import { SupDSE } from "./SupDSE";
import { SupDVW } from "./SupDVW";

export const SupD = () => {
  return (
    <>
      <Container>
        <SupDIntro />
        <SupDSE />
        <SupDVW />
      </Container>
    </>
  );
};
