import React, { useState } from "react";
import {
  Grid,
  Container,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Paper,
  TextField,
  Button,
  Checkbox,
} from "@mui/material";
import { Header } from "../../components/landingPage/Header";
import { useSignup } from "../../hooks/useSignup";
import { getFunctions, httpsCallable } from "firebase/functions";
import { PgColors } from "../../configs/colors";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export const Terms = () => {
  const { t } = useTranslation();
  const [firstname, setFirstname] = useState<string>("");
  const [lastname, setLastname] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [role, setRole] = useState("Gymnast");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const { error, signup } = useSignup();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    signup(email, password, firstname, role);
    setFirstname("");
    setLastname("");
    setEmail("");
    setRole("Gymnast");
    setPassword("");
    setConfirmPassword("");
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setRole("Coach");
    } else {
      setRole("Gymnast");
    }
  };

  return (
    <>
      <Header />
      <div
        style={{
          backgroundColor: PgColors.bluepg[100],
          height: "100%",
        }}
      >
        <Container>
          <Grid container justifyContent="center">
            <Grid
              item
              md={10}
              sx={{ marginTop: { xs: 4, lg: 8, xl: 10 } }}
              marginBottom={20}
            >
              <Paper elevation={2}>
                <Container>
                  <Grid container>
                    <Grid item xs={12} mt={6}>
                      <Typography
                        variant="h4"
                        sx={{ color: PgColors.bluepg[300] }}
                        align="center"
                      >
                        Algemene Voorwaarden
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container mt={4}>
                    <Grid item xs={12}>
                      <Typography
                        variant="subtitle1"
                        sx={{ color: PgColors.bluepg[300], fontWeight: 500 }}
                      >
                        1. Definities
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container mt={2}>
                    <Grid item xs={12}>
                      <Typography
                        variant="subtitle1"
                        sx={{ color: PgColors.bluepg[300] }}
                      >
                        In deze Algemene Voorwaarden worden de volgende
                        begrippen met een hoofdletter gebruikt en hebben zij de
                        volgende betekenis:
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container mt={2}>
                    <Grid item xs={12} ml={2}>
                      <Typography
                        variant="subtitle1"
                        sx={{ color: PgColors.bluepg[300] }}
                      >
                        <strong>Online Coach Platform</strong>: De website en
                        online omgeving die toegankelijk is via
                        www.personalgymnastics.com, waar coaches van turnsters
                        zich kunnen abonneren en gebruik kunnen maken van de
                        aangeboden diensten.
                      </Typography>
                    </Grid>
                    <Grid item xs={12} ml={2} mt={1}>
                      <Typography
                        variant="subtitle1"
                        sx={{ color: PgColors.bluepg[300] }}
                      >
                        <strong>Coaches</strong>: Gebruikers die zich hebben
                        geabonneerd op het Online Coach Platform om toegang te
                        krijgen tot de aangeboden diensten.
                      </Typography>
                    </Grid>
                    <Grid item xs={12} ml={2} mt={1}>
                      <Typography
                        variant="subtitle1"
                        sx={{ color: PgColors.bluepg[300] }}
                      >
                        <strong>Turnsters</strong>: De sporters voor wie de
                        Coaches programma's, lesvoorbereidingen en andere
                        begeleiding op het gebied van turnen kunnen creëren en
                        beheren.
                      </Typography>
                    </Grid>
                    <Grid item xs={12} ml={2} mt={1}>
                      <Typography
                        variant="subtitle1"
                        sx={{ color: PgColors.bluepg[300] }}
                      >
                        <strong>Abonnement</strong>: Het maandelijkse abonnement
                        dat Coaches dienen te betalen om toegang te krijgen tot
                        het volledige Online Coach Platform en de bijbehorende
                        diensten. beheren.
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container mt={4}>
                    <Grid item xs={12}>
                      <Typography
                        variant="subtitle1"
                        sx={{ color: PgColors.bluepg[300], fontWeight: 500 }}
                      >
                        2. Toepasselijkheid
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container mt={2}>
                    <Grid item xs={12}>
                      <Typography
                        variant="subtitle1"
                        sx={{ color: PgColors.bluepg[300] }}
                      >
                        Deze Algemene Voorwaarden zijn van toepassing op het
                        gebruik van het Online Coach Platform door Coaches. Door
                        gebruik te maken van de diensten stemmen Coaches
                        automatisch in met deze voorwaarden.
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container mt={4}>
                    <Grid item xs={12}>
                      <Typography
                        variant="subtitle1"
                        sx={{ color: PgColors.bluepg[300], fontWeight: 500 }}
                      >
                        3. Abonnement en Betaling
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container mt={2}>
                    <Grid item xs={12} ml={2}>
                      <Typography
                        variant="subtitle1"
                        sx={{ color: PgColors.bluepg[300] }}
                      >
                        3.1 Coaches kunnen een maandelijks Abonnement afsluiten
                        voor €9,95 (negentig euro en vijfennegentig cent), dat
                        via automatische incasso wordt afgeschreven.
                      </Typography>
                    </Grid>
                    <Grid item xs={12} ml={2}>
                      <Typography
                        variant="subtitle1"
                        sx={{ color: PgColors.bluepg[300] }}
                      >
                        3.2 Betalingen worden maandelijks vooruit gefactureerd.
                        Coaches stemmen ermee in dat het abonnementsgeld
                        automatisch wordt afgeschreven van het opgegeven
                        bankrekeningnummer.
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container mt={4}>
                    <Grid item xs={12}>
                      <Typography
                        variant="subtitle1"
                        sx={{ color: PgColors.bluepg[300], fontWeight: 500 }}
                      >
                        4. Gebruik van het Online Coach Platform
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container mt={2}>
                    <Grid item xs={12} ml={2}>
                      <Typography
                        variant="subtitle1"
                        sx={{ color: PgColors.bluepg[300] }}
                      >
                        4.1 Het Online Coach Platform biedt Coaches de
                        mogelijkheid om Turnsters toe te voegen aan hun online
                        omgeving en hen te voorzien van programma's en
                        begeleiding.
                      </Typography>
                    </Grid>
                    <Grid item xs={12} ml={2}>
                      <Typography
                        variant="subtitle1"
                        sx={{ color: PgColors.bluepg[300] }}
                      >
                        4.2 Het gebruik van het Online Coach Platform is
                        persoonlijk en niet overdraagbaar. Coaches zijn
                        verantwoordelijk voor het vertrouwelijk houden van hun
                        inloggegevens en mogen deze niet delen met derden.
                      </Typography>
                    </Grid>
                    <Grid item xs={12} ml={2}>
                      <Typography
                        variant="subtitle1"
                        sx={{ color: PgColors.bluepg[300] }}
                      >
                        4.3 Coaches gaan ermee akkoord dat zij het platform
                        uitsluitend gebruiken voor legitieme doeleinden en zich
                        houden aan alle toepasselijke wetten en regelgeving.
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container mt={4}>
                    <Grid item xs={12}>
                      <Typography
                        variant="subtitle1"
                        sx={{ color: PgColors.bluepg[300], fontWeight: 500 }}
                      >
                        5. Verantwoordelijkheden van Coaches
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container mt={2}>
                    <Grid item xs={12} ml={2}>
                      <Typography
                        variant="subtitle1"
                        sx={{ color: PgColors.bluepg[300] }}
                      >
                        5.1 Coaches zijn verantwoordelijk voor de juistheid van
                        de informatie die zij verstrekken, inclusief gegevens
                        van Turnsters.
                      </Typography>
                    </Grid>
                    <Grid item xs={12} ml={2}>
                      <Typography
                        variant="subtitle1"
                        sx={{ color: PgColors.bluepg[300] }}
                      >
                        5.2 Coaches dienen de privacy van Turnsters te
                        respecteren en mogen geen persoonlijke informatie van
                        Turnsters delen zonder hun toestemming.
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container mt={4}>
                    <Grid item xs={12}>
                      <Typography
                        variant="subtitle1"
                        sx={{ color: PgColors.bluepg[300], fontWeight: 500 }}
                      >
                        6. Beëindiging
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container mt={2}>
                    <Grid item xs={12} ml={2}>
                      <Typography
                        variant="subtitle1"
                        sx={{ color: PgColors.bluepg[300] }}
                      >
                        6.1 Coaches kunnen hun Abonnement op elk moment opzeggen
                        door naar de website te gaan en de benodigde stappen te
                        volgen.
                      </Typography>
                    </Grid>
                    <Grid item xs={12} ml={2}>
                      <Typography
                        variant="subtitle1"
                        sx={{ color: PgColors.bluepg[300] }}
                      >
                        6.2 Het Online Coach Platform behoudt zich het recht
                        voor om een Coach de toegang tot het platform te
                        ontzeggen bij schending van deze Algemene Voorwaarden.
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container mt={4}>
                    <Grid item xs={12}>
                      <Typography
                        variant="subtitle1"
                        sx={{ color: PgColors.bluepg[300], fontWeight: 500 }}
                      >
                        7. Aansprakelijkheid
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container mt={2}>
                    <Grid item xs={12} ml={2}>
                      <Typography
                        variant="subtitle1"
                        sx={{ color: PgColors.bluepg[300] }}
                      >
                        7.1 Het gebruik van het Online Coach Platform is op
                        eigen risico. Het platform is niet verantwoordelijk voor
                        enige schade, verlies of letsel voortkomend uit het
                        gebruik van de diensten.
                      </Typography>
                    </Grid>
                    <Grid item xs={12} ml={2}>
                      <Typography
                        variant="subtitle1"
                        sx={{ color: PgColors.bluepg[300] }}
                      >
                        7.2 Het Online Coach Platform streeft naar een continue
                        beschikbaarheid, maar kan niet aansprakelijk worden
                        gesteld voor eventuele tijdelijke onderbrekingen in de
                        dienstverlening.
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container mt={4}>
                    <Grid item xs={12}>
                      <Typography
                        variant="subtitle1"
                        sx={{ color: PgColors.bluepg[300], fontWeight: 500 }}
                      >
                        8. Wijzigingen in de Voorwaarden
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container mt={2}>
                    <Grid item xs={12}>
                      <Typography
                        variant="subtitle1"
                        sx={{ color: PgColors.bluepg[300] }}
                      >
                        Het Online Coach Platform behoudt zich het recht voor om
                        deze Algemene Voorwaarden op elk moment te wijzigen.
                        Coaches worden op de hoogte gesteld van dergelijke
                        wijzigingen via e-mail.
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container mt={4}>
                    <Grid item xs={12}>
                      <Typography
                        variant="subtitle1"
                        sx={{ color: PgColors.bluepg[300], fontWeight: 500 }}
                      >
                        9. Toepasselijk recht
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container mt={2}>
                    <Grid item xs={12}>
                      <Typography
                        variant="subtitle1"
                        sx={{ color: PgColors.bluepg[300] }}
                      >
                        Op deze Algemene Voorwaarden is Nederlands recht van
                        toepassing.
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container mt={4}>
                    <Grid item xs={12}>
                      <Typography
                        variant="subtitle1"
                        sx={{ color: PgColors.bluepg[300], fontWeight: 500 }}
                      >
                        10. Contact
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container mt={2}>
                    <Grid item xs={12}>
                      <Typography
                        variant="subtitle1"
                        sx={{ color: PgColors.bluepg[300] }}
                      >
                        Voor vragen, opmerkingen of klachten kunnen Coaches
                        contact opnemen via info@personalgymnastics.com.
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container mt={4} pb={4}>
                    <Grid item xs={12}>
                      <Typography
                        variant="subtitle1"
                        sx={{ color: PgColors.bluepg[300] }}
                      >
                        Deze Algemene Voorwaarden zijn voor het laatst
                        bijgewerkt op 27/08/2023.
                      </Typography>
                    </Grid>
                  </Grid>
                </Container>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
};
