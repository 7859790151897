import React, { useState, useEffect } from "react";
import { collection, query, onSnapshot, orderBy } from "firebase/firestore";
import {
  Grid,
  Container,
  Typography,
  Paper,
  Button,
  ButtonGroup,
} from "@mui/material";
import { useAuthContext } from "../../../../hooks/useAuthContext";
import { useParams } from "react-router-dom";
import { db } from "../../../../firebase/config";
import { PgColors } from "../../../../configs/colors";
import { FlikBeam } from "./FlikBeam";
import { ArabierBeam } from "./ArabierBeam";
import { WisselspagaatBeam } from "./WisselspagaatBeam";
import { SaltoAo } from "./SaltoAoBeam";

export const BeamSkills = () => {
  const [active, setActive] = useState("Flik");
  const activeColor = PgColors.bluepg[400];

  return (
    <>
      <Grid container mt={2}>
        <Grid item xs={12} display="flex" justifyContent="center">
          <ButtonGroup variant="contained">
            <Button
              style={{
                backgroundColor:
                  active === "Flik" ? activeColor : PgColors.bluepg[200],
              }}
              variant="contained"
              onClick={() => setActive("Flik")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 10, md: 14 } }}
              >
                Flikflak
              </Typography>
            </Button>
            <Button
              style={{
                backgroundColor:
                  active === "SaltoAo" ? activeColor : PgColors.bluepg[200],
              }}
              variant="contained"
              onClick={() => setActive("SaltoAo")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 10, md: 14 } }}
              >
                Salto Ao
              </Typography>
            </Button>
            <Button
              style={{
                backgroundColor:
                  active === "Arabier" ? activeColor : PgColors.bluepg[200],
              }}
              variant="contained"
              onClick={() => setActive("Arabier")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 10, md: 14 } }}
              >
                Arabier
              </Typography>
            </Button>
            <Button
              style={{
                backgroundColor:
                  active === "Wissel" ? activeColor : PgColors.bluepg[200],
              }}
              variant="contained"
              onClick={() => setActive("Wissel")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 10, md: 14 } }}
              >
                Wisselspagaat
              </Typography>
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid>
      <Grid container>{active === "Flik" && <FlikBeam />}</Grid>
      <Grid container>{active === "SaltoAo" && <SaltoAo />}</Grid>
      <Grid container>{active === "Arabier" && <ArabierBeam />}</Grid>
      <Grid container>{active === "Wissel" && <WisselspagaatBeam />}</Grid>
    </>
  );
};
