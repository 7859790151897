import React, { useState, useEffect, FC } from "react";
import {
  Grid,
  Container,
  Typography,
  Divider,
  Button,
  Paper,
  Box,
} from "@mui/material";
import { PgColors } from "../../../configs/colors";

type TextProps = {
  text: string;
};

export const ContentPaper: FC<TextProps> = ({ text }) => {
  return (
    <Typography
      variant="subtitle1"
      sx={{
        color: "white",
        fontSize: { xs: 12, sm: 14, md: 16 },
      }}
      align="left"
    >
      {text}
    </Typography>
  );
};

export const TitlePaper: FC<TextProps> = ({ text }) => {
  return (
    <Typography
      variant="subtitle1"
      style={{ fontWeight: 500 }}
      sx={{
        color: "white",
        fontSize: { xs: 12, sm: 14, md: 16 },
      }}
      align="left"
    >
      {text}
    </Typography>
  );
};

export const Content: FC<TextProps> = ({ text }) => {
  return (
    <Typography
      variant="subtitle1"
      style={{ color: PgColors.redpg[500] }}
      align="left"
    >
      {text}
    </Typography>
  );
};

export const Houdingen3 = () => {
  return (
    <>
      <Grid container mt={2} marginLeft={2}>
        <Grid item>
          <Typography variant="h6" style={{ color: PgColors.redpg[500] }}>
            Balk houdingen serie 3: supplement 5, 6 en 7
          </Typography>
        </Grid>
      </Grid>
      <Grid container marginLeft={2}>
        <Grid item>
          <Content
            text={
              "*Uitgangspositie: Basishouding: voeten 5e positie links voor; armen laag. Tijdens de serie zijn de armen zijwaarts (2e positie) met de schouders laag, tenzij anders beschreven."
            }
          />
        </Grid>
      </Grid>
      <Grid container marginBottom={4}>
        <Grid item mt={0} md={12}>
          <Paper
            elevation={2}
            style={{ backgroundColor: PgColors.bluepg[100], width: "100%" }}
          >
            <Container>
              <Grid container>
                <Grid item xs={2} md={1} sx={{ marginRight: { md: -4 } }}>
                  <TitlePaper text={"Maat"} />
                </Grid>
                <Grid item xs={2} md={1} sx={{ marginRight: { md: -4 } }}>
                  <TitlePaper text={"Tel"} />
                </Grid>
                <Grid item xs={8} sx={{ marginRight: { md: 8 } }}>
                  <TitlePaper
                    text={
                      "De turnster mag voorkeursbeen kiezen; tweezijdige elementen dienen li en re uitgevoerd te worden."
                    }
                  />
                </Grid>
                <Grid
                  item
                  md={2}
                  sx={{ marginRight: { md: 0 } }}
                  display={{ xs: "none", md: "flex" }}
                >
                  <TitlePaper text={"Symbolen"} />
                </Grid>
              </Grid>
              <Divider></Divider>
              <Grid container sx={{ marginBottom: { md: -3 } }}>
                <Grid item xs={2} md={1} sx={{ marginRight: { md: -4 } }}>
                  <ContentPaper text={"1"} />
                </Grid>
                <Grid item xs={2} md={1} sx={{ marginRight: { md: -4 } }}>
                  <ContentPaper text={"1-2"} />
                </Grid>
                <Grid item xs={8} sx={{ marginRight: { md: 8 } }}>
                  <ContentPaper
                    text={
                      "2 stappen vw in relevé (li-re); armen vw; handpalmen naar elkaar toe"
                    }
                  />
                </Grid>
                <Grid
                  item
                  md={2}
                  sx={{ marginRight: { md: 0 } }}
                  display={{ xs: "none", md: "flex" }}
                >
                  <Box
                    component="img"
                    alt="symbol2"
                    src="../images/balkhoudingen/Symbol14.png"
                    sx={{ maxHeight: { xs: 35, md: 75 } }}
                  />
                </Grid>
              </Grid>
              <Grid container sx={{ marginBottom: { md: -7 } }}>
                <Grid
                  item
                  xs={2}
                  md={1}
                  sx={{ marginRight: { md: -4 } }}
                ></Grid>
                <Grid item xs={2} md={1} sx={{ marginRight: { md: -4 } }}>
                  <ContentPaper text={"3-4"} />
                </Grid>
                <Grid item xs={8} sx={{ marginRight: { md: 8 } }}>
                  <ContentPaper
                    text={"2 stappen vw in relevé (li-re); armen zw"}
                  />
                </Grid>
                <Grid
                  item
                  md={2}
                  sx={{ marginRight: { md: 0 } }}
                  display={{ xs: "none", md: "flex" }}
                >
                  <Box
                    component="img"
                    alt="symbol2"
                    src="../images/balkhoudingen/Symbol16.png"
                    sx={{ maxHeight: { xs: 35, md: 75 } }}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid
                  item
                  xs={2}
                  md={1}
                  sx={{ marginRight: { md: -4 } }}
                ></Grid>
                <Grid item xs={2} md={1} sx={{ marginRight: { md: -4 } }}>
                  <ContentPaper text={"5-6"} />
                </Grid>
                <Grid item xs={8} sx={{ marginRight: { md: 8 } }}>
                  <ContentPaper
                    text={"Stap vw op li in relevé, re been passé parallel"}
                  />
                </Grid>
                <Grid
                  item
                  md={2}
                  sx={{ marginRight: { md: 0 } }}
                  display={{ xs: "none", md: "flex" }}
                ></Grid>
              </Grid>
              <Grid container>
                <Grid
                  item
                  xs={2}
                  md={1}
                  sx={{ marginRight: { md: -4 } }}
                ></Grid>
                <Grid item xs={2} md={1} sx={{ marginRight: { md: -4 } }}>
                  <ContentPaper text={"7-8"} />
                </Grid>
                <Grid item xs={8} sx={{ marginRight: { md: 8 } }}>
                  <ContentPaper
                    text={"Stap vw op re in relevé, li been passé parallel"}
                  />
                </Grid>
                <Grid
                  item
                  md={2}
                  sx={{ marginRight: { md: 0 } }}
                  display={{ xs: "none", md: "flex" }}
                ></Grid>
              </Grid>
              <Divider></Divider>
              <Grid container sx={{ marginBottom: { md: -7 } }}>
                <Grid item xs={2} md={1} sx={{ marginRight: { md: -4 } }}>
                  <ContentPaper text={"2"} />
                </Grid>
                <Grid item xs={2} md={1} sx={{ marginRight: { md: -4 } }}>
                  <ContentPaper text={"1"} />
                </Grid>
                <Grid item xs={8} sx={{ marginRight: { md: 8 } }}>
                  <ContentPaper
                    text={
                      "Li voet voor neerzetten in relevé; armen hoog; handpalmen naar elkaar toe"
                    }
                  />
                </Grid>
                <Grid
                  item
                  md={2}
                  sx={{ marginRight: { md: 0 } }}
                  display={{ xs: "none", md: "flex" }}
                >
                  <Box
                    component="img"
                    alt="symbol2"
                    src="../images/balkhoudingen/Symbol17.png"
                    sx={{ maxHeight: { xs: 35, md: 75 } }}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid
                  item
                  xs={2}
                  md={1}
                  sx={{ marginRight: { md: -4 } }}
                ></Grid>
                <Grid item xs={2} md={1} sx={{ marginRight: { md: -4 } }}>
                  <ContentPaper text={"2"} />
                </Grid>
                <Grid item xs={8} sx={{ marginRight: { md: 8 } }}>
                  <ContentPaper
                    text={
                      "¼ draai re tot parallel stand; armen hoog; handpalmen naar elkaar toe"
                    }
                  />
                </Grid>
                <Grid
                  item
                  md={2}
                  sx={{ marginRight: { md: 0 } }}
                  display={{ xs: "none", md: "flex" }}
                ></Grid>
              </Grid>
              <Grid container sx={{ marginBottom: { md: 0 } }}>
                <Grid
                  item
                  xs={2}
                  md={1}
                  sx={{ marginRight: { md: -4 } }}
                ></Grid>
                <Grid item xs={2} md={1} sx={{ marginRight: { md: -4 } }}>
                  <ContentPaper text={"3-4"} />
                </Grid>
                <Grid item xs={8} sx={{ marginRight: { md: 8 } }}>
                  <ContentPaper
                    text={
                      "Naar tendu zijwaarts op recths, linker been is tendu been; armen zijwaarts"
                    }
                  />
                </Grid>
                <Grid
                  item
                  md={2}
                  sx={{ marginRight: { md: 0 } }}
                  display={{ xs: "none", md: "flex" }}
                ></Grid>
              </Grid>
              <Grid container sx={{ marginBottom: { md: -2 } }}>
                <Grid
                  item
                  xs={2}
                  md={1}
                  sx={{ marginRight: { md: -4 } }}
                ></Grid>
                <Grid item xs={2} md={1} sx={{ marginRight: { md: -4 } }}>
                  <ContentPaper text={"5-6"} />
                </Grid>
                <Grid item xs={8} sx={{ marginRight: { md: 8 } }}>
                  <ContentPaper
                    text={
                      "Buiging van de romp naar li (cambré); armen: li arm rond laag, re arm rond hoog; handpalmen naar elkaar toe"
                    }
                  />
                </Grid>
                <Grid
                  item
                  md={2}
                  sx={{ marginRight: { md: 0 } }}
                  display={{ xs: "none", md: "flex" }}
                >
                  <Box
                    component="img"
                    alt="symbol2"
                    src="../images/balkhoudingen/Symbol18.png"
                    sx={{ maxHeight: { xs: 35, md: 65 } }}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid
                  item
                  xs={2}
                  md={1}
                  sx={{ marginRight: { md: -4 } }}
                ></Grid>
                <Grid item xs={2} md={1} sx={{ marginRight: { md: -4 } }}>
                  <ContentPaper text={"7-8"} />
                </Grid>
                <Grid item xs={8} sx={{ marginRight: { md: 8 } }}>
                  <ContentPaper text={"Terug rechtop; armen zw"} />
                </Grid>
                <Grid
                  item
                  md={2}
                  sx={{ marginRight: { md: 0 } }}
                  display={{ xs: "none", md: "flex" }}
                ></Grid>
              </Grid>
              <Divider></Divider>
              <Grid container>
                <Grid item xs={2} md={1} sx={{ marginRight: { md: -4 } }}>
                  <ContentPaper text={"3"} />
                </Grid>
                <Grid item xs={2} md={1} sx={{ marginRight: { md: -4 } }}>
                  <ContentPaper text={"1"} />
                </Grid>
                <Grid item xs={8} sx={{ marginRight: { md: 8 } }}>
                  <ContentPaper
                    text={"Li been zw heffen; armen: handen in de zij plaatsen"}
                  />
                </Grid>
                <Grid
                  item
                  md={2}
                  sx={{ marginRight: { md: 0 } }}
                  display={{ xs: "none", md: "flex" }}
                ></Grid>
              </Grid>
              <Grid container>
                <Grid
                  item
                  xs={2}
                  md={1}
                  sx={{ marginRight: { md: -4 } }}
                ></Grid>
                <Grid item xs={2} md={1} sx={{ marginRight: { md: -4 } }}>
                  <ContentPaper text={"2-3"} />
                </Grid>
                <Grid item xs={8} sx={{ marginRight: { md: 8 } }}>
                  <ContentPaper
                    text={
                      "Stap zw op li, via plié naar tendu zw op li, re been is tendu been; armen: handen in de zij"
                    }
                  />
                </Grid>
                <Grid
                  item
                  md={2}
                  sx={{ marginRight: { md: 0 } }}
                  display={{ xs: "none", md: "flex" }}
                ></Grid>
              </Grid>
              <Grid container sx={{ marginBottom: { md: -4 } }}>
                <Grid
                  item
                  xs={2}
                  md={1}
                  sx={{ marginRight: { md: -4 } }}
                ></Grid>
                <Grid item xs={2} md={1} sx={{ marginRight: { md: -4 } }}>
                  <ContentPaper text={"4"} />
                </Grid>
                <Grid item xs={8} sx={{ marginRight: { md: 8 } }}>
                  <ContentPaper
                    text={
                      "Sluit het re been tot parallel stand; handen in de zij"
                    }
                  />
                </Grid>
                <Grid
                  item
                  md={2}
                  sx={{ marginRight: { md: 0 } }}
                  display={{ xs: "none", md: "flex" }}
                >
                  <Box
                    component="img"
                    alt="symbol2"
                    src="../images/balkhoudingen/Symbol19.png"
                    sx={{ maxHeight: { xs: 35, md: 55 } }}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid
                  item
                  xs={2}
                  md={1}
                  sx={{ marginRight: { md: -4 } }}
                ></Grid>
                <Grid item xs={2} md={1} sx={{ marginRight: { md: -4 } }}>
                  <ContentPaper text={"5"} />
                </Grid>
                <Grid item xs={8} sx={{ marginRight: { md: 8 } }}>
                  <ContentPaper
                    text={
                      "Naar tendu zw op re, li been is tendu been ;re hand in de zij, li arm via een welbeweging strekken naar schuin laag (wijzend naar tendu voet)"
                    }
                  />
                </Grid>
                <Grid
                  item
                  md={2}
                  sx={{ marginRight: { md: 0 } }}
                  display={{ xs: "none", md: "flex" }}
                ></Grid>
              </Grid>
              <Grid container>
                <Grid
                  item
                  xs={2}
                  md={1}
                  sx={{ marginRight: { md: -4 } }}
                ></Grid>
                <Grid item xs={2} md={1} sx={{ marginRight: { md: -4 } }}>
                  <ContentPaper text={"6"} />
                </Grid>
                <Grid item xs={8} sx={{ marginRight: { md: 8 } }}>
                  <ContentPaper
                    text={
                      "Sluit het re been tot parallel stand; re hand in de zij, li arm naar hoog"
                    }
                  />
                </Grid>
                <Grid
                  item
                  md={2}
                  sx={{ marginRight: { md: 0 } }}
                  display={{ xs: "none", md: "flex" }}
                ></Grid>
              </Grid>
              <Grid container>
                <Grid
                  item
                  xs={2}
                  md={1}
                  sx={{ marginRight: { md: -4 } }}
                ></Grid>
                <Grid item xs={2} md={1} sx={{ marginRight: { md: -4 } }}>
                  <ContentPaper text={"7"} />
                </Grid>
                <Grid item xs={8} sx={{ marginRight: { md: 8 } }}>
                  <ContentPaper
                    text={"Re arm omhoog (beide armen zijn nu hoog)"}
                  />
                </Grid>
                <Grid
                  item
                  md={2}
                  sx={{ marginRight: { md: 0 } }}
                  display={{ xs: "none", md: "flex" }}
                ></Grid>
              </Grid>
              <Grid container>
                <Grid
                  item
                  xs={2}
                  md={1}
                  sx={{ marginRight: { md: -4 } }}
                ></Grid>
                <Grid item xs={2} md={1} sx={{ marginRight: { md: -4 } }}>
                  <ContentPaper text={"8"} />
                </Grid>
                <Grid item xs={8} sx={{ marginRight: { md: 8 } }}>
                  <ContentPaper
                    text={
                      "¼ draai re naar tendu voor op li, re been is tendu been; armen zw"
                    }
                  />
                </Grid>
                <Grid
                  item
                  md={2}
                  sx={{ marginRight: { md: 0 } }}
                  display={{ xs: "none", md: "flex" }}
                ></Grid>
              </Grid>
              <Divider></Divider>
              <Grid container>
                <Grid item xs={2} md={1} sx={{ marginRight: { md: -4 } }}>
                  <ContentPaper text={"4"} />
                </Grid>
                <Grid item xs={2} md={1} sx={{ marginRight: { md: -4 } }}>
                  <ContentPaper text={"1-4"} />
                </Grid>
                <Grid item xs={8} sx={{ marginRight: { md: 8 } }}>
                  <ContentPaper text={"4 stappen aw in relevé (re-li-re-li)"} />
                </Grid>
                <Grid
                  item
                  md={2}
                  sx={{ marginRight: { md: 0 } }}
                  display={{ xs: "none", md: "flex" }}
                ></Grid>
              </Grid>
              <Grid container sx={{ marginBottom: { md: -6 } }}>
                <Grid
                  item
                  xs={2}
                  md={1}
                  sx={{ marginRight: { md: -4 } }}
                ></Grid>
                <Grid item xs={2} md={1} sx={{ marginRight: { md: -4 } }}>
                  <ContentPaper text={"5-6"} />
                </Grid>
                <Grid item xs={8} sx={{ marginRight: { md: 8 } }}>
                  <ContentPaper
                    text={"Komen tot hurkzit met re voet voor; armen laag"}
                  />
                </Grid>
                <Grid
                  item
                  md={2}
                  sx={{ marginRight: { md: 0 } }}
                  display={{ xs: "none", md: "flex" }}
                >
                  <Box
                    component="img"
                    alt="symbol2"
                    src="../images/balkhoudingen/Symbol13.png"
                    sx={{ maxHeight: { xs: 35, md: 75 } }}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid
                  item
                  xs={2}
                  md={1}
                  sx={{ marginRight: { md: -4 } }}
                ></Grid>
                <Grid item xs={2} md={1} sx={{ marginRight: { md: -4 } }}>
                  <ContentPaper text={"7-8"} />
                </Grid>
                <Grid item xs={8} sx={{ marginRight: { md: 8 } }}>
                  <ContentPaper text={"½ draai in hurkzit li, armen laag "} />
                </Grid>
                <Grid
                  item
                  md={2}
                  sx={{ marginRight: { md: 0 } }}
                  display={{ xs: "none", md: "flex" }}
                ></Grid>
              </Grid>
              <Grid container>
                <Grid
                  item
                  xs={2}
                  md={1}
                  sx={{ marginRight: { md: -4 } }}
                ></Grid>
                <Grid
                  item
                  xs={2}
                  md={1}
                  sx={{ marginRight: { md: -4 } }}
                ></Grid>
                <Grid item xs={8} sx={{ marginRight: { md: 8 } }}>
                  <ContentPaper
                    text={
                      "Komen tot stand in 5e positie li voet voor; armen laag"
                    }
                  />
                </Grid>
                <Grid
                  item
                  md={2}
                  sx={{ marginRight: { md: 0 } }}
                  display={{ xs: "none", md: "flex" }}
                ></Grid>
              </Grid>
            </Container>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};
