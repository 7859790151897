import React, { useState, useEffect } from "react";
import { collection, query, onSnapshot, orderBy } from "firebase/firestore";
import {
  Grid,
  Container,
  Typography,
  Paper,
  Button,
  Box,
  CardMedia,
} from "@mui/material";
import { useAuthContext } from "../../../../hooks/useAuthContext";
import { useParams } from "react-router-dom";
import { db } from "../../../../firebase/config";
import { PgColors } from "../../../../configs/colors";
import { AddExercise6A, AddExercise6B, AddExercise6C } from "./AddExercise6";
import {
  ListExercise6A,
  ListExercise6B,
  ListExercise6C,
} from "./ListExercise6";
import { AddExercise7A, AddExercise7B } from "./AddExercise7";
import { ListExercise7A, ListExercise7B } from "./ListExercise7";

export const ConfidencePage = () => {
  const { authIsReady, user } = useAuthContext();
  const [exercise7AList, setExercise7AList] = useState([]);
  const [exercise7BList, setExercise7BList] = useState([]);

  const { id } = useParams();

  useEffect(() => {
    const ref = query(
      collection(db, `IntroExercises/${user.uid}/UserExercise7A`),
      orderBy("createdAt", "desc")
    );

    const unsub = onSnapshot(ref, (snapshot) => {
      let results: any = [];
      snapshot.docs.forEach((doc) => {
        results.push({ ...doc.data(), id: doc.id });
      });
      setExercise7AList(results);
    });
    return () => unsub();
  }, []);

  useEffect(() => {
    const ref = query(
      collection(db, `IntroExercises/${user.uid}/UserExercise7B`),
      orderBy("createdAt", "desc")
    );

    const unsub = onSnapshot(ref, (snapshot) => {
      let results: any = [];
      snapshot.docs.forEach((doc) => {
        results.push({ ...doc.data(), id: doc.id });
      });
      setExercise7BList(results);
    });
    return () => unsub();
  }, []);

  return (
    <>
      <Grid container mt={2}>
        <Grid item xs={12}>
          <Paper
            style={{ backgroundColor: PgColors.redpg[400] }}
            sx={{ height: { xs: 45, md: 110 } }}
          >
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, sm: 14, md: 16, lg: 20 } }}
              align="center"
            >
              "Confidence comes not from always being right,
            </Typography>
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, sm: 14, md: 16, lg: 20 } }}
              align="center"
            >
              but from not fearing to be wrong."
            </Typography>

            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, sm: 14, md: 16, lg: 20 } }}
              align="center"
            >
              - Peter T. McIntrye
            </Typography>
          </Paper>
        </Grid>
      </Grid>
      <Grid container mt={2}>
        <Grid item xs={12} mt={1}>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
            }}
            align="left"
          >
            In sport, zelfvertrouwen kan worden gedefinieerd als het geloof dat
            je in je eigen skills en vaardigheden hebt, die je helpen om succes
            te behalen.
          </Typography>
        </Grid>
        <Grid item xs={12} mt={1}>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
            }}
            align="left"
          >
            Je presteert beter als je zelfvertrouwen voelt, maar je
            zelfvertrouwen groeit ook als je succes ervaart. Gelukkig ervaar je
            succes niet alleen als je wint, maar ook juist in de kleine stapjes
            en het proces.
          </Typography>
        </Grid>
        <Grid item xs={12} mt={0}>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
            }}
            align="left"
          >
            Bijvoorbeeld, wanneer je 3x achter elkaar een flikflak-salto sprint
            op vloer, dan neemt je zelfvertrouwen (in die skill) toe.
          </Typography>
        </Grid>

        <Grid item xs={12} mt={2}>
          <Typography
            variant="subtitle1"
            style={{ color: PgColors.bluepg[400] }}
            sx={{
              fontSize: { xs: 16, md: 20, lg: 24 },
              fontWeight: { md: 500 },
            }}
            align="left"
          >
            Opdracht 7
          </Typography>
        </Grid>
      </Grid>

      <Grid container mt={2}>
        <Grid item xs={12} mt={1}>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
            }}
            align="left"
          >
            Een belangrijke stap in meer zelf vertrouwen hebben is om je
            focussen op het proces (hoe je een resultaat wilt bereiken) in
            plaats van het resultaat zelf.
          </Typography>
        </Grid>
        <Grid item xs={12} mt={1}>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
            }}
            align="left"
          >
            Daarbij is het belangrijk om bewust te zijn van de dingen waar
            jezelf invloed op hebt, en de dingen waar je geen invloed op hebt
            los te laten.
          </Typography>
        </Grid>
      </Grid>
      <Grid container mt={1}>
        <AddExercise7A />
      </Grid>
      <Grid container mt={1}>
        <AddExercise7B />
      </Grid>

      <Grid container mt={4} mb={2}>
        {exercise7AList && (
          <ListExercise7A
            exercises={exercise7AList}
            path={`IntroExercises/${id}/UserExercise7A/${id}`}
          />
        )}
      </Grid>
      <Grid container mt={0} mb={2}>
        {exercise7BList && (
          <ListExercise7B
            exercises={exercise7BList}
            path={`IntroExercises/${id}/UserExercise7B/${id}`}
          />
        )}
      </Grid>
    </>
  );
};
