import React, { useState, useEffect } from "react";
import { collection, query, onSnapshot, orderBy } from "firebase/firestore";
import { Grid } from "@mui/material";
import { useAuthContext } from "../../../../hooks/useAuthContext";
import { useParams } from "react-router-dom";
import { db } from "../../../../firebase/config";
import { AddDScore } from "./AddDScore";
import { DScoresList } from "./DScoresList";

export const DScorePage = () => {
  const { id } = useParams();
  const [dScoresList, setDScoresList] = useState([]);

  useEffect(() => {
    const ref = query(
      collection(db, `DScore/${id}/userDScore`),
      orderBy("createdAt", "desc")
    );

    const unsub = onSnapshot(ref, (snapshot) => {
      let results: any = [];
      snapshot.docs.forEach((doc) => {
        results.push({ ...doc.data(), id: doc.id });
      });
      setDScoresList(results);
    });
    return () => unsub();
  }, []);

  return (
    <>
      <Grid container mt={2}>
        <AddDScore />
      </Grid>
      <Grid container mt={4}>
        {dScoresList && <DScoresList dScores={dScoresList} />}
      </Grid>
    </>
  );
};
