import React, { useState, useEffect } from "react";
import {
  collection,
  query,
  onSnapshot,
  updateDoc,
  doc,
  deleteDoc,
  setDoc,
} from "firebase/firestore";
import { db } from "../../firebase/config";
import {
  Container,
  Grid,
  Typography,
  Checkbox,
  Paper,
  TextField,
  Button,
  Modal,
  Toolbar,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Box,
  Drawer,
  Divider,
  alertClasses,
} from "@mui/material";
import { useParams } from "react-router-dom";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import AddIcon from "@mui/icons-material/Add";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { useAuthContext } from "../../hooks/useAuthContext";
import { PgColors } from "../../configs/colors";

export const EditProgram = ({ path }: any) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [currentData, setCurrentData] = useState<any>(null);
  const [name, setName] = useState("");
  const [apparatus, setApparatus] = useState("");
  const [program, setProgram] = useState("");

  useEffect(() => {
    if (open) {
      const unsubscribe = onSnapshot(doc(db, path), (snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.data();
          setCurrentData(data);
          // Set the initial values for TextFields
          setName(data.name || "");
          setApparatus(data.apparatus || "");
          setProgram(data.program || "");
        }
      });

      return () => unsubscribe();
    }
  }, [open, path]);

  const handleUpdate = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    try {
      if (name) {
        await updateDoc(doc(db, path), {
          name: name,
        });
      }
      if (apparatus) {
        await updateDoc(doc(db, path), {
          apparatus: apparatus,
        });
      }
      if (program) {
        await updateDoc(doc(db, path), {
          program: program,
        });
      }

      setName("");
      setApparatus("");
      setProgram("");

      setOpen(false);
    } catch (err) {
      alert(err);
    }
  };

  return (
    <>
      <Button
        variant="contained"
        sx={{ heigth: 35 }}
        onClick={handleOpen}
        style={{
          backgroundColor: PgColors.redpg[200],
        }}
      >
        <EditOutlinedIcon />
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        style={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
        }}
      >
        <Box
          sx={{
            marginTop: { xs: 1, md: 2 },
            width: { xs: 350, sm: 580, md: 880, xl: 1500 },
            minHeight: { xs: 600, md: 600 },
            maxHeight: { lg: 1300 },
            bgcolor: "background.paper",
            border: "4px solid #9f5b5a",
            boxShadow: 24,
            borderRadius: 5,
            p: 4,
            overflow: "auto",
          }}
        >
          <form>
            <Grid container>
              <Grid item xs={10} md={11}></Grid>
              <Grid item xs={1} md={1}>
                <Button
                  variant="contained"
                  sx={{ heigth: 35 }}
                  onClick={handleClose}
                  style={{
                    backgroundColor: PgColors.redpg[200],
                  }}
                >
                  <CloseOutlinedIcon />
                </Button>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item marginTop={6}>
                <Typography
                  variant="h6"
                  style={{ color: PgColors.redpg[200], fontWeight: "bold" }}
                >
                  Programma Naam
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item marginTop={1}>
                <Typography
                  variant="h6"
                  style={{ color: "#000000", fontWeight: "300", fontSize: 14 }}
                >
                  Voer de naam van het programma in. Deze naam zal ook te zien
                  zijn.
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} marginTop={1}>
                <TextField
                  label="Naam"
                  style={{
                    color: PgColors.bluepg[200],
                  }}
                  variant="outlined"
                  fullWidth
                  value={name}
                  onChange={(event) => setName(event.target.value)}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item marginTop={4}>
                <Typography
                  variant="h6"
                  style={{ color: PgColors.redpg[200], fontWeight: "bold" }}
                >
                  Toestel
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item md={12} marginTop={1}>
                <Typography
                  variant="h6"
                  style={{ color: "#000000", fontWeight: "300", fontSize: 14 }}
                >
                  Voor het toestel is. Vul in "Sprong", "Brug", "Balk", "Vloer"
                  of "Fysiek" en het programma wordt automatisch gesorteerd.
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} marginTop={1}>
                <TextField
                  label="Toestel / Fysiek"
                  style={{
                    color: PgColors.bluepg[200],
                  }}
                  variant="outlined"
                  fullWidth
                  value={apparatus}
                  onChange={(event) => setApparatus(event.target.value)}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item marginTop={4}>
                <Typography
                  variant="h6"
                  style={{ color: PgColors.redpg[200], fontWeight: "bold" }}
                >
                  Programma
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item md={12} marginTop={1}>
                <Typography
                  variant="h6"
                  style={{ color: "#000000", fontWeight: "300", fontSize: 14 }}
                >
                  Voer hier de inhoud van het programma in:
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} marginTop={1}>
                <TextField
                  label="Inhoud"
                  style={{
                    color: PgColors.bluepg[200],
                  }}
                  variant="outlined"
                  fullWidth
                  multiline
                  minRows={12}
                  value={program}
                  onChange={(event) => setProgram(event.target.value)}
                />
              </Grid>
            </Grid>
            <Grid container mt={1} justifyContent="end" display="flex">
              <Grid item md={4} xs={12} justifyContent="end" display="flex">
                <Button
                  style={{
                    backgroundColor: PgColors.bluepg[200],
                  }}
                  variant="contained"
                  onClick={(
                    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
                  ) => handleUpdate(e)}
                  size="large"
                  sx={{ height: 55 }}
                >
                  <Typography variant="subtitle1">Update</Typography>
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Modal>
    </>
  );
};
