import React, { useState, useEffect } from "react";
import {
  collection,
  query,
  onSnapshot,
  updateDoc,
  doc,
  deleteDoc,
  setDoc,
} from "firebase/firestore";
import {
  Grid,
  Container,
  Typography,
  Paper,
  Button,
  Checkbox,
} from "@mui/material";
import { useAuthContext } from "../../../../hooks/useAuthContext";
import { useParams } from "react-router-dom";
import { db } from "../../../../firebase/config";
import { PgColors } from "../../../../configs/colors";
import CheckIcon from "@mui/icons-material/Check";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";

type CheckboxState = {
  [key: string]: boolean;
};

export const Wippertje = () => {
  const { user } = useAuthContext();
  const { id } = useParams();
  const [checkboxes, setCheckboxes] = useState<CheckboxState>({});

  const updateCheckboxState = async (checkboxId: any, isChecked: boolean) => {
    await setDoc(
      doc(db, `Skills/Bars/Wippertje/${id}/checkboxStates/${checkboxId}`),
      {
        isChecked,
      }
    );
  };

  useEffect(() => {
    const ref = query(
      collection(db, `Skills/Bars/Wippertje/${id}/checkboxStates`)
    );

    const unsub = onSnapshot(ref, (snapshot) => {
      const checkboxStates: any = [];
      snapshot.docs.forEach((doc) => {
        checkboxStates[doc.id] = doc.data().isChecked;
      });
      setCheckboxes(checkboxStates);
    });
    return () => unsub();
  }, []);

  return (
    <>
      <Grid container mt={2}>
        <Grid item xs={9} md={6}>
          <Typography
            variant="subtitle1"
            style={{ color: PgColors.redpg[500] }}
            sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
            align="left"
          >
            Wippertje Drills
          </Typography>
        </Grid>
        <Grid item xs={1} md={1} display="flex" justifyContent="center">
          <CheckIcon
            sx={{ color: PgColors.redpg[500], fontSize: { xs: 16, md: 20 } }}
          />
        </Grid>
        <Grid item xs={1} md={1} display="flex" justifyContent="center">
          <ThumbUpIcon
            sx={{ color: PgColors.redpg[500], fontSize: { xs: 16, md: 20 } }}
          />
        </Grid>
        <Grid item xs={1} md={1} display="flex" justifyContent="center">
          <EmojiEventsIcon
            sx={{ color: PgColors.redpg[500], fontSize: { xs: 16, md: 20 } }}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={9} md={6}>
          <Paper
            style={{ backgroundColor: PgColors.redpg[50] }}
            sx={{ height: { xs: 45, md: 35 } }}
          >
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Ondersprong tot stand op verhoging 90cm
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId1"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId1"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId1"]: newCheckedState,
              });
              updateCheckboxState("checkboxId1", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId2"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId2"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId2"]: newCheckedState,
              });
              updateCheckboxState("checkboxId2", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId3"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId3"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId3"]: newCheckedState,
              });
              updateCheckboxState("checkboxId3", newCheckedState);
            }}
          />
        </Grid>
      </Grid>
      <Grid container sx={{ mt: { xs: 1, md: -1 } }}>
        <Grid item xs={9} md={6}>
          <Paper
            style={{ backgroundColor: PgColors.redpg[50] }}
            sx={{ height: { xs: 45, md: 35 } }}
          >
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Zolendraai
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId7"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId7"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId7"]: newCheckedState,
              });
              updateCheckboxState("checkboxId7", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId8"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId8"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId8"]: newCheckedState,
              });
              updateCheckboxState("checkboxId8", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId9"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId9"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId9"]: newCheckedState,
              });
              updateCheckboxState("checkboxId9", newCheckedState);
            }}
          />
        </Grid>
      </Grid>
      <Grid container sx={{ mt: { xs: 1, md: -1 } }}>
        <Grid item xs={9} md={6}>
          <Paper
            style={{ backgroundColor: PgColors.redpg[50] }}
            sx={{ height: { xs: 45, md: 35 } }}
          >
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Vrije onderzwaai tot stand op verhoging 30cm
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId13"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId13"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId13"]: newCheckedState,
              });
              updateCheckboxState("checkboxId13", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId14"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId14"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId14"]: newCheckedState,
              });
              updateCheckboxState("checkboxId14", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId15"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId15"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId15"]: newCheckedState,
              });
              updateCheckboxState("checkboxId15", newCheckedState);
            }}
          />
        </Grid>
      </Grid>
      <Grid container sx={{ mt: { xs: 1, md: -1 } }}>
        <Grid item xs={9} md={6}>
          <Paper
            style={{ backgroundColor: PgColors.redpg[50] }}
            sx={{ height: { xs: 45, md: 35 } }}
          >
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Sushinova tot buiklig
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId19"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId19"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId19"]: newCheckedState,
              });
              updateCheckboxState("checkboxId19", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId20"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId20"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId20"]: newCheckedState,
              });
              updateCheckboxState("checkboxId20", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId21"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId21"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId21"]: newCheckedState,
              });
              updateCheckboxState("checkboxId21", newCheckedState);
            }}
          />
        </Grid>
      </Grid>
      <Grid container sx={{ mt: { xs: 1, md: -1 } }}>
        <Grid item xs={9} md={6}>
          <Paper
            style={{ backgroundColor: PgColors.redpg[100] }}
            sx={{ height: { xs: 45, md: 35 } }}
          >
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Contrabeweging op schuinvlak
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId25"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId25"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId25"]: newCheckedState,
              });
              updateCheckboxState("checkboxId25", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId26"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId24"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId26"]: newCheckedState,
              });
              updateCheckboxState("checkboxId26", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId27"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId27"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId27"]: newCheckedState,
              });
              updateCheckboxState("checkboxId27", newCheckedState);
            }}
          />
        </Grid>
      </Grid>
      <Grid container sx={{ mt: { xs: 1, md: -1 } }}>
        <Grid item xs={9} md={6}>
          <Paper
            style={{ backgroundColor: PgColors.redpg[100] }}
            sx={{ height: { xs: 45, md: 35 } }}
          >
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Ondersprong - contra tot buiklig tot op 90cm
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId31"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId31"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId31"]: newCheckedState,
              });
              updateCheckboxState("checkboxId31", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId32"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId32"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId32"]: newCheckedState,
              });
              updateCheckboxState("checkboxId32", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId33"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId33"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId33"]: newCheckedState,
              });
              updateCheckboxState("checkboxId33", newCheckedState);
            }}
          />
        </Grid>
      </Grid>
      <Grid container sx={{ mt: { xs: 1, md: -1 } }}>
        <Grid item xs={9} md={6}>
          <Paper
            style={{ backgroundColor: PgColors.redpg[100] }}
            sx={{ height: { xs: 45, md: 35 } }}
          >
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Knipmessen gespreid - 5hh | 10hh | 15hh
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId37"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId37"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId37"]: newCheckedState,
              });
              updateCheckboxState("checkboxId37", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId38"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId38"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId38"]: newCheckedState,
              });
              updateCheckboxState("checkboxId38", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId39"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId39"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId39"]: newCheckedState,
              });
              updateCheckboxState("checkboxId39", newCheckedState);
            }}
          />
        </Grid>
      </Grid>
      <Grid container sx={{ mt: { xs: 1, md: -1 } }}>
        <Grid item xs={9} md={6}>
          <Paper
            style={{ backgroundColor: PgColors.redpg[200] }}
            sx={{ height: { xs: 45, md: 35 } }}
          >
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Wippertje - kip
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId43"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId43"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId43"]: newCheckedState,
              });
              updateCheckboxState("checkboxId43", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId44"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId44"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId44"]: newCheckedState,
              });
              updateCheckboxState("checkboxId44", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId45"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId45"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId45"]: newCheckedState,
              });
              updateCheckboxState("checkboxId45", newCheckedState);
            }}
          />
        </Grid>
      </Grid>
      <Grid container sx={{ mt: { xs: 1, md: -1 } }} mb={2}>
        <Grid item xs={9} md={6}>
          <Paper
            style={{ backgroundColor: PgColors.redpg[400] }}
            sx={{ height: { xs: 45, md: 35 } }}
          >
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Wippertje in oefening
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId46"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId46"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId46"]: newCheckedState,
              });
              updateCheckboxState("checkboxId46", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId47"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId47"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId47"]: newCheckedState,
              });
              updateCheckboxState("checkboxId47", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId48"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId48"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId48"]: newCheckedState,
              });
              updateCheckboxState("checkboxId48", newCheckedState);
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};
