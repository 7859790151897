import React, { useState, useEffect } from "react";
import {
  collection,
  query,
  onSnapshot,
  updateDoc,
  getDoc,
  doc,
  deleteDoc,
} from "firebase/firestore";
import { db } from "../../firebase/config";
import {
  Grid,
  Container,
  Typography,
  Divider,
  Button,
  Box,
  Paper,
} from "@mui/material";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { LessonType } from "../../types/types";
import { NavLink, Link } from "react-router-dom";
import { PgColors } from "../../configs/colors";
import { EditLesson } from "./EditLesson";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";

interface IProps {
  lesson: LessonType;
}

export const Lesson = ({ lesson }: IProps) => {
  const { authIsReady, user } = useAuthContext();
  const { id } = useParams();
  const navigate = useNavigate();

  const handleDelete = async (dScore: any) => {
    const ref = doc(db, `LessonsByCoach/${user.uid}/Lessons/${lesson.id}`);
    await deleteDoc(ref);
    navigate("/dashboard/lessonplans");
  };

  return (
    <>
      <Container disableGutters={true}>
        <Box sx={{ mt: 12, width: "100%" }}>
          <Paper
            elevation={2}
            sx={{ width: { sx: 300, md: 1400 }, height: { md: 850 } }}
            style={{ overflow: "auto" }}
          >
            <Container>
              <Grid container mt={2}>
                <Grid item xs={6} sm={8} md={10}></Grid>
                <Grid item xs={3} sm={2} md={1}>
                  <EditLesson
                    path={`LessonsByCoach/${user.uid}/Lessons/${lesson.id}`}
                  />
                </Grid>
                <Grid item xs={3} sm={2} md={1}>
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: PgColors.redpg[200],
                    }}
                    size="large"
                    onClick={() => handleDelete(lesson)}
                    sx={{ width: 75, height: 35 }}
                  >
                    <DeleteOutlinedIcon />
                  </Button>
                </Grid>
              </Grid>
              <Grid container>
                <Grid
                  item
                  display="flex"
                  xs={12}
                  md={4}
                  sx={{ marginTop: { xs: 2, md: -2 } }}
                >
                  <Typography
                    variant="h6"
                    style={{ color: PgColors.redpg[200], fontWeight: "bold" }}
                    sx={{ fontSize: { xs: 14, md: 20 } }}
                  >
                    Les Voorbereiding
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={6} md={4}>
                  <Typography
                    variant="subtitle1"
                    style={{
                      color: PgColors.bluepg[200],
                      fontWeight: 450,
                    }}
                    sx={{ fontSize: { xs: 14, md: 20 } }}
                  >
                    {lesson.name}
                  </Typography>
                </Grid>

                <Grid item xs={6} md={2}>
                  <Typography
                    variant="subtitle1"
                    style={{
                      color: PgColors.bluepg[200],
                      fontWeight: 450,
                    }}
                    sx={{ fontSize: { xs: 14, md: 20 } }}
                  >
                    {lesson.time}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container>
                <Grid item md={4} sx={{ marginTop: { xs: 2, md: 4 } }}>
                  <Typography
                    variant="h6"
                    style={{ color: PgColors.redpg[200], fontWeight: "bold" }}
                    sx={{ fontSize: { xs: 14, md: 20 } }}
                  >
                    Warming-up
                  </Typography>
                </Grid>
                <Grid item md={4} sx={{ marginTop: { xs: 2, md: 6 } }}>
                  <Typography
                    variant="h6"
                    style={{ color: PgColors.redpg[200], fontWeight: "bold" }}
                    sx={{ fontSize: { xs: 14, md: 20 } }}
                  >
                    Indeling
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} md={4}>
                  <Paper
                    style={{
                      backgroundColor: PgColors.bluepg[200],
                    }}
                  >
                    <Container>
                      <Typography
                        variant="subtitle1"
                        style={{
                          color: "white",

                          fontWeight: 450,
                          whiteSpace: "pre-line",
                        }}
                        sx={{ fontSize: { xs: 12, md: 16 } }}
                      >
                        {lesson.wuContent}
                      </Typography>
                    </Container>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Paper
                    style={{
                      backgroundColor: PgColors.bluepg[100],
                    }}
                  >
                    <Container>
                      <Typography
                        variant="subtitle1"
                        style={{
                          color: "white",

                          fontWeight: 450,
                          whiteSpace: "pre-line",
                        }}
                        sx={{ fontSize: { xs: 12, md: 16 } }}
                      >
                        {lesson.groups}
                      </Typography>
                    </Container>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Paper
                    style={{
                      backgroundColor: PgColors.bluepg[200],
                    }}
                  >
                    <Container>
                      <Typography
                        variant="subtitle1"
                        style={{
                          color: "white",

                          fontWeight: 450,
                          whiteSpace: "pre-line",
                        }}
                        sx={{ fontSize: { xs: 12, md: 16 } }}
                      >
                        {lesson.agenda}
                      </Typography>
                    </Container>
                  </Paper>
                </Grid>
              </Grid>

              <Grid container>
                <Grid item sx={{ marginTop: { xs: 2, md: 2 } }}>
                  <Typography
                    variant="h6"
                    style={{ color: PgColors.redpg[200], fontWeight: "bold" }}
                    sx={{ fontSize: { xs: 14, md: 20 } }}
                  >
                    Sprong
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item md={12}>
                  <Paper
                    style={{
                      backgroundColor: PgColors.redpg[200],
                    }}
                  >
                    <Container>
                      <Typography
                        variant="subtitle1"
                        style={{
                          color: "white",

                          fontWeight: 450,
                          whiteSpace: "pre-line",
                        }}
                        sx={{ fontSize: { xs: 12, md: 16 } }}
                      >
                        {lesson.vaultGoal}
                      </Typography>
                    </Container>
                  </Paper>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} md={6}>
                  <Paper
                    style={{
                      backgroundColor: PgColors.bluepg[200],
                    }}
                  >
                    <Container>
                      <Typography
                        variant="subtitle1"
                        style={{
                          color: "white",

                          fontWeight: 450,
                          whiteSpace: "pre-line",
                        }}
                        sx={{ fontSize: { xs: 12, md: 16 } }}
                      >
                        {lesson.vaultCond}
                      </Typography>
                    </Container>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Paper
                    style={{
                      backgroundColor: PgColors.bluepg[100],
                    }}
                  >
                    <Container>
                      <Typography
                        variant="subtitle1"
                        style={{
                          color: "white",

                          fontWeight: 450,
                          whiteSpace: "pre-line",
                        }}
                        sx={{ fontSize: { xs: 12, md: 16 } }}
                      >
                        {lesson.vaultStations}
                      </Typography>
                    </Container>
                  </Paper>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item sx={{ marginTop: { xs: 2, md: 2 } }}>
                  <Typography
                    variant="h6"
                    style={{ color: PgColors.redpg[200], fontWeight: "bold" }}
                    sx={{ fontSize: { xs: 14, md: 20 } }}
                  >
                    Brug
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item md={12}>
                  <Paper
                    style={{
                      backgroundColor: PgColors.redpg[200],
                    }}
                  >
                    <Container>
                      <Typography
                        variant="subtitle1"
                        style={{
                          color: "white",

                          fontWeight: 450,
                          whiteSpace: "pre-line",
                        }}
                        sx={{ fontSize: { xs: 12, md: 16 } }}
                      >
                        {lesson.barsGoal}
                      </Typography>
                    </Container>
                  </Paper>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} md={6}>
                  <Paper
                    style={{
                      backgroundColor: PgColors.bluepg[200],
                    }}
                  >
                    <Container>
                      <Typography
                        variant="subtitle1"
                        style={{
                          color: "white",

                          fontWeight: 450,
                          whiteSpace: "pre-line",
                        }}
                        sx={{ fontSize: { xs: 12, md: 16 } }}
                      >
                        {lesson.barsCond}
                      </Typography>
                    </Container>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Paper
                    style={{
                      backgroundColor: PgColors.bluepg[100],
                    }}
                  >
                    <Container>
                      <Typography
                        variant="subtitle1"
                        style={{
                          color: "white",

                          fontWeight: 450,
                          whiteSpace: "pre-line",
                        }}
                        sx={{ fontSize: { xs: 12, md: 16 } }}
                      >
                        {lesson.barsStations}
                      </Typography>
                    </Container>
                  </Paper>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item sx={{ marginTop: { xs: 2, md: 6 } }}>
                  <Typography
                    variant="h6"
                    style={{ color: PgColors.redpg[200], fontWeight: "bold" }}
                    sx={{ fontSize: { xs: 14, md: 20 } }}
                  >
                    Balk
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item md={12}>
                  <Paper
                    style={{
                      backgroundColor: PgColors.redpg[200],
                    }}
                  >
                    <Container>
                      <Typography
                        variant="subtitle1"
                        style={{
                          color: "white",

                          fontWeight: 450,
                          whiteSpace: "pre-line",
                        }}
                        sx={{ fontSize: { xs: 12, md: 16 } }}
                      >
                        {lesson.beamGoal}
                      </Typography>
                    </Container>
                  </Paper>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item md={6}>
                  <Paper
                    style={{
                      backgroundColor: PgColors.bluepg[200],
                    }}
                  >
                    <Container>
                      <Typography
                        variant="subtitle1"
                        style={{
                          color: "white",

                          fontWeight: 450,
                          whiteSpace: "pre-line",
                        }}
                        sx={{ fontSize: { xs: 12, md: 16 } }}
                      >
                        {lesson.beamCond}
                      </Typography>
                    </Container>
                  </Paper>
                </Grid>
                <Grid item md={6}>
                  <Paper
                    style={{
                      backgroundColor: PgColors.bluepg[100],
                    }}
                  >
                    <Container>
                      <Typography
                        variant="subtitle1"
                        style={{
                          color: "white",

                          fontWeight: 450,
                          whiteSpace: "pre-line",
                        }}
                        sx={{ fontSize: { xs: 12, md: 16 } }}
                      >
                        {lesson.beamStations}
                      </Typography>
                    </Container>
                  </Paper>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item sx={{ marginTop: { xs: 2, md: 6 } }}>
                  <Typography
                    variant="h6"
                    style={{ color: PgColors.redpg[200], fontWeight: "bold" }}
                    sx={{ fontSize: { xs: 14, md: 20 } }}
                  >
                    Vloer
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item md={12}>
                  <Paper
                    style={{
                      backgroundColor: PgColors.redpg[200],
                    }}
                  >
                    <Container>
                      <Typography
                        variant="subtitle1"
                        style={{
                          color: "white",

                          fontWeight: 450,
                          whiteSpace: "pre-line",
                        }}
                        sx={{ fontSize: { xs: 12, md: 16 } }}
                      >
                        {lesson.floorGoal}
                      </Typography>
                    </Container>
                  </Paper>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item md={6}>
                  <Paper
                    style={{
                      backgroundColor: PgColors.bluepg[200],
                    }}
                  >
                    <Container>
                      <Typography
                        variant="subtitle1"
                        style={{
                          color: "white",

                          fontWeight: 450,
                          whiteSpace: "pre-line",
                        }}
                        sx={{ fontSize: { xs: 12, md: 16 } }}
                      >
                        {lesson.floorCond}
                      </Typography>
                    </Container>
                  </Paper>
                </Grid>
                <Grid item md={6}>
                  <Paper
                    style={{
                      backgroundColor: PgColors.bluepg[100],
                    }}
                  >
                    <Container>
                      <Typography
                        variant="subtitle1"
                        style={{
                          color: "white",

                          fontWeight: 450,
                          whiteSpace: "pre-line",
                        }}
                        sx={{ fontSize: { xs: 12, md: 16 } }}
                      >
                        {lesson.floorStations}
                      </Typography>
                    </Container>
                  </Paper>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item sx={{ marginTop: { xs: 2, md: 6 } }}>
                  <Typography
                    variant="h6"
                    style={{ color: PgColors.redpg[200], fontWeight: "bold" }}
                    sx={{ fontSize: { xs: 14, md: 20 } }}
                  >
                    Overig
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item md={12}>
                  <Paper
                    style={{
                      backgroundColor: PgColors.redpg[200],
                    }}
                  >
                    <Container>
                      <Typography
                        variant="subtitle1"
                        style={{
                          color: "white",

                          fontWeight: 450,
                          whiteSpace: "pre-line",
                        }}
                        sx={{ fontSize: { xs: 12, md: 16 } }}
                      >
                        {lesson.otherGoal}
                      </Typography>
                    </Container>
                  </Paper>
                </Grid>
              </Grid>
              <Grid container marginBottom={4}>
                <Grid item md={6}>
                  <Paper
                    style={{
                      backgroundColor: PgColors.bluepg[200],
                    }}
                  >
                    <Container>
                      <Typography
                        variant="subtitle1"
                        style={{
                          color: "white",

                          fontWeight: 450,
                          whiteSpace: "pre-line",
                        }}
                        sx={{ fontSize: { xs: 12, md: 16 } }}
                      >
                        {lesson.otherCond}
                      </Typography>
                    </Container>
                  </Paper>
                </Grid>
                <Grid item md={6}>
                  <Paper
                    style={{
                      backgroundColor: PgColors.bluepg[100],
                    }}
                  >
                    <Container>
                      <Typography
                        variant="subtitle1"
                        style={{
                          color: "white",

                          fontWeight: 450,
                          whiteSpace: "pre-line",
                        }}
                        sx={{ fontSize: { xs: 12, md: 16 } }}
                      >
                        {lesson.otherStations}
                      </Typography>
                    </Container>
                  </Paper>
                </Grid>
              </Grid>
            </Container>
          </Paper>
        </Box>
      </Container>
    </>
  );
};
