import React, { useState, useEffect } from "react";
import { collection, query, onSnapshot, orderBy } from "firebase/firestore";
import {
  Grid,
  Container,
  Typography,
  Paper,
  Button,
  ButtonGroup,
} from "@mui/material";
import { useAuthContext } from "../../../../hooks/useAuthContext";
import { useParams } from "react-router-dom";
import { db } from "../../../../firebase/config";
import { PgColors } from "../../../../configs/colors";
import { Division6Vault } from "./Division6Vault";
import { Division6Bars } from "./Division6Bars";
import { Division6Beam } from "./Division6Beam";
import { Division6Floor } from "./Division6Floor";

export const Division6 = () => {
  const [active, setActive] = useState("Vault");
  const activeColor = PgColors.bluepg[400];

  return (
    <>
      <Grid container mt={2}>
        <Grid item xs={3}>
          <Paper
            style={{ backgroundColor: PgColors.redpg[50] }}
            sx={{ height: { xs: 20, md: 35 } }}
          >
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Jeugd H
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={3}>
          <Paper
            style={{ backgroundColor: PgColors.redpg[100] }}
            sx={{ height: { xs: 20, md: 35 } }}
          >
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Junior G
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={3}>
          <Paper
            style={{ backgroundColor: PgColors.redpg[200] }}
            sx={{ height: { xs: 20, md: 35 } }}
          >
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Senior F
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={3}>
          <Paper
            style={{ backgroundColor: PgColors.redpg[400] }}
            sx={{ height: { xs: 20, md: 35 } }}
          >
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Senior E
            </Typography>
          </Paper>
        </Grid>
      </Grid>
      <Grid container mt={2}>
        <Grid item xs={12}>
          <ButtonGroup variant="contained">
            <Button
              style={{
                backgroundColor:
                  active === "Vault" ? activeColor : PgColors.bluepg[200],
              }}
              variant="contained"
              onClick={() => setActive("Vault")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 10, md: 14 } }}
              >
                Sprong
              </Typography>
            </Button>
            <Button
              style={{
                backgroundColor:
                  active === "Bars" ? activeColor : PgColors.bluepg[200],
              }}
              variant="contained"
              onClick={() => setActive("Bars")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 10, md: 14 } }}
              >
                Brug
              </Typography>
            </Button>
            <Button
              style={{
                backgroundColor:
                  active === "Beam" ? activeColor : PgColors.bluepg[200],
              }}
              variant="contained"
              onClick={() => setActive("Beam")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 10, md: 14 } }}
              >
                Balk
              </Typography>
            </Button>
            <Button
              style={{
                backgroundColor:
                  active === "Floor" ? activeColor : PgColors.bluepg[200],
              }}
              variant="contained"
              onClick={() => setActive("Floor")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 10, md: 14 } }}
              >
                Vloer
              </Typography>
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid>
      <Grid container>{active === "Vault" && <Division6Vault />}</Grid>
      <Grid container>{active === "Bars" && <Division6Bars />}</Grid>
      <Grid container>{active === "Beam" && <Division6Beam />}</Grid>
      <Grid container>{active === "Floor" && <Division6Floor />}</Grid>
    </>
  );
};
