import React, { useState, useEffect } from "react";
import {
  collection,
  query,
  onSnapshot,
  orderBy,
  updateDoc,
  doc,
  deleteDoc,
} from "firebase/firestore";
import { db } from "../../../../firebase/config";
import { Grid } from "@mui/material";
import { AddGoal } from "./AddGoal";
import { GoalsList } from "./GoalsList";
import { useAuthContext } from "../../../../hooks/useAuthContext";
import { useParams } from "react-router-dom";
import { PgColors } from "../../../../configs/colors";

export const GoalsPage = () => {
  const { user } = useAuthContext();
  const [goalList, setGoalList] = useState([]);
  const { id } = useParams();

  if (user !== null) {
    console.log(user.uid);
  }

  useEffect(() => {
    const ref = query(
      collection(db, `Goals/${id}/userGoals`),
      orderBy("createdAt", "desc")
    );

    const unsub = onSnapshot(ref, (snapshot) => {
      let results: any = [];
      snapshot.docs.forEach((doc) => {
        results.push({ ...doc.data(), id: doc.id });
      });
      setGoalList(results);
    });
    return () => unsub();
  }, []);

  return (
    <>
      <Grid container mt={2}>
        <AddGoal />
      </Grid>
      <Grid container mt={4}>
        {goalList && (
          <GoalsList goals={goalList} path={`Goals/${id}/userGoals/${id}`} />
        )}
      </Grid>
      {/* <Modal></Modal> */}
    </>
  );
};
