import React, { useEffect, useState } from "react";
import { doc, getDoc } from "firebase/firestore";
import {
  Grid,
  Container,
  Typography,
  Paper,
  TextField,
  Button,
  ButtonGroup,
  Box,
  Drawer,
  Divider,
} from "@mui/material";
import { useAuthContext } from "../../../hooks/useAuthContext";
import { db } from "../../../firebase/config";
import { useParams } from "react-router-dom";
import { Name } from "./Name";
import { GoalsPage } from "./Goals/GoalsPage";
import { SkillTrackerPage } from "./SkillTracker/SkillTrackerPage";
import { CompetitionPage } from "./Competition/CompetitionPage";
import { DScorePage } from "./D-Score/DScorePage";
import { PgColors } from "../../../configs/colors";
import { ChecklistPage } from "./ChecklistCoach/ChecklistPage";
import { SkillsPage } from "./SkillsCoach/SkillsPage";
import { WorkbookPage } from "./WorkbookCoach/WorkbookPage";

export const GymnastPage = () => {
  const { authIsReady, user } = useAuthContext();
  const { id } = useParams();
  const [gymnast, setGymnast] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [active, setActive] = useState("Goals");
  const activeColor = PgColors.bluepg[200];

  if (user !== null) {
    console.log(user.uid);
  }

  useEffect(() => {
    const fetchGymnastData = async () => {
      setIsLoading(true);

      if (authIsReady && user) {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        // console.log("user:", user);
        try {
          const docSnap = await getDoc(doc(db, `users/${user.uid}`));
          console.log("user after getDoc:", user.uid);
          if (docSnap.exists()) {
            const newGymnastObj = {
              id: docSnap.id,
              ...docSnap.data(),
            };
            setGymnast(newGymnastObj);
            setIsLoading(false);
          } else {
            //doc.data() will be undefined in this case
            console.log("No such document");
          }
        } catch (error) {
          console.error("Error fetching user document:", error);
        }
      }
    };

    fetchGymnastData();
  }, [authIsReady, user]);

  console.log(gymnast, "gymnast");

  if (!authIsReady) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <Container disableGutters={true}>
        <Box
          sx={{
            mt: { xs: 2, lg: 6 },
            marginLeft: { lg: 36, xl: 16 },
            marginBottom: { xs: 4, md: 8 },
            width: "100%",
          }}
        >
          <Paper
            elevation={2}
            sx={{
              width: { xs: 350, sm: 580, md: 880, lg: 880, xl: 1200 },
              minHeight: { xs: 750, md: 850 },
            }}
          >
            <Container>
              <Name path={`GymnastsByCoach/${user.uid}/Gymnasts`} />
              <Grid container sx={{ display: { xs: "none", md: "flex" } }}>
                <Grid
                  item
                  sx={{ marginTop: { xs: 0, md: 2 } }}
                  xs={12}
                  justifyContent="center"
                  display="flex"
                >
                  <ButtonGroup variant="text">
                    <Button onClick={() => setActive("Goals")}>
                      <Typography
                        variant="subtitle1"
                        style={{
                          color:
                            active === "Goals"
                              ? activeColor
                              : PgColors.redpg[200],

                          fontWeight: 500,
                          fontSize: 14,
                        }}
                        sx={{
                          fontSize: { xs: 12, md: 14 },
                        }}
                      >
                        Goals
                      </Typography>
                    </Button>
                    <Button onClick={() => setActive("Checklist")}>
                      <Typography
                        variant="subtitle1"
                        style={{
                          color:
                            active === "Checklist"
                              ? activeColor
                              : PgColors.redpg[200],
                          fontWeight: 500,
                          fontSize: 14,
                        }}
                        sx={{
                          fontSize: { xs: 12, md: 14 },
                        }}
                      >
                        Checklist
                      </Typography>
                    </Button>
                    <Button onClick={() => setActive("Skills")}>
                      <Typography
                        variant="subtitle1"
                        style={{
                          color:
                            active === "Skills"
                              ? activeColor
                              : PgColors.redpg[200],
                          fontWeight: 500,
                          fontSize: 14,
                        }}
                        sx={{
                          fontSize: { xs: 12, md: 14 },
                        }}
                      >
                        Skills
                      </Typography>
                    </Button>
                    <Button onClick={() => setActive("Competitions")}>
                      <Typography
                        variant="subtitle1"
                        style={{
                          color:
                            active === "Competitions"
                              ? activeColor
                              : PgColors.redpg[200],
                          fontWeight: 500,
                          fontSize: 14,
                        }}
                        sx={{
                          fontSize: { xs: 12, md: 14 },
                        }}
                      >
                        Competition Scores
                      </Typography>
                    </Button>
                    <Button onClick={() => setActive("D-scores")}>
                      <Typography
                        variant="subtitle1"
                        style={{
                          color:
                            active === "D-scores"
                              ? activeColor
                              : PgColors.redpg[200],
                          fontWeight: 500,
                        }}
                        sx={{
                          fontSize: { xs: 12, md: 14 },
                        }}
                      >
                        D-scores
                      </Typography>
                    </Button>
                    <Button onClick={() => setActive("Workbook")}>
                      <Typography
                        variant="subtitle1"
                        style={{
                          color:
                            active === "Workbook"
                              ? activeColor
                              : PgColors.redpg[200],
                          fontWeight: 500,
                        }}
                        sx={{
                          fontSize: { xs: 12, md: 14 },
                        }}
                      >
                        Workbook
                      </Typography>
                    </Button>
                  </ButtonGroup>
                </Grid>
              </Grid>
              <Grid container sx={{ display: { xs: "flex", md: "none" } }}>
                <Grid
                  item
                  sx={{ marginTop: { xs: 0, md: 2 } }}
                  xs={12}
                  justifyContent="center"
                  display="flex"
                >
                  <ButtonGroup variant="text">
                    <Button onClick={() => setActive("Goals")}>
                      <Typography
                        variant="subtitle1"
                        style={{
                          color:
                            active === "Goals"
                              ? activeColor
                              : PgColors.redpg[200],

                          fontWeight: 500,
                        }}
                        sx={{
                          fontSize: { xs: 12, md: 14 },
                        }}
                      >
                        Goals
                      </Typography>
                    </Button>
                    <Button onClick={() => setActive("Checklist")}>
                      <Typography
                        variant="subtitle1"
                        style={{
                          color:
                            active === "Checklist"
                              ? activeColor
                              : PgColors.redpg[200],
                          fontWeight: 500,
                        }}
                        sx={{
                          fontSize: { xs: 12, md: 14 },
                        }}
                      >
                        Checklist
                      </Typography>
                    </Button>
                    <Button onClick={() => setActive("Skills")}>
                      <Typography
                        variant="subtitle1"
                        style={{
                          color:
                            active === "Skills"
                              ? activeColor
                              : PgColors.redpg[200],
                          fontWeight: 500,
                        }}
                        sx={{
                          fontSize: { xs: 12, md: 14 },
                        }}
                      >
                        Skills
                      </Typography>
                    </Button>
                  </ButtonGroup>
                </Grid>
              </Grid>
              <Grid container sx={{ display: { xs: "flex", md: "none" } }}>
                <Grid
                  item
                  sx={{ marginTop: { xs: 0, md: 2 } }}
                  xs={12}
                  justifyContent="center"
                  display="flex"
                >
                  <ButtonGroup variant="text">
                    <Button onClick={() => setActive("Competitions")}>
                      <Typography
                        variant="subtitle1"
                        style={{
                          color:
                            active === "Competitions"
                              ? activeColor
                              : PgColors.redpg[200],
                          fontWeight: 500,
                        }}
                        sx={{
                          fontSize: { xs: 12, md: 14 },
                        }}
                      >
                        Competition Scores
                      </Typography>
                    </Button>
                    <Button onClick={() => setActive("D-scores")}>
                      <Typography
                        variant="subtitle1"
                        style={{
                          color:
                            active === "D-scores"
                              ? activeColor
                              : PgColors.redpg[200],
                          fontWeight: 500,
                        }}
                        sx={{
                          fontSize: { xs: 12, md: 14 },
                        }}
                      >
                        D-scores
                      </Typography>
                    </Button>
                    <Button onClick={() => setActive("Workbook")}>
                      <Typography
                        variant="subtitle1"
                        style={{
                          color:
                            active === "Workbook"
                              ? activeColor
                              : PgColors.redpg[200],
                          fontWeight: 500,
                        }}
                        sx={{
                          fontSize: { xs: 12, md: 14 },
                        }}
                      >
                        Workbook
                      </Typography>
                    </Button>
                  </ButtonGroup>
                </Grid>
              </Grid>
              <Divider></Divider>
              {active === "Goals" && <GoalsPage />}
              {active === "Checklist" && <ChecklistPage />}
              {active === "Skills" && <SkillsPage />}
              {active === "Competitions" && <CompetitionPage />}
              {active === "D-scores" && <DScorePage />}
              {active === "Workbook" && <WorkbookPage />}
            </Container>
          </Paper>
        </Box>
      </Container>
    </>
  );
};
