import React, { useState, useEffect } from "react";
import {
  collection,
  query,
  onSnapshot,
  updateDoc,
  doc,
  deleteDoc,
} from "firebase/firestore";
import {
  Grid,
  Container,
  Typography,
  Divider,
  Button,
  Paper,
} from "@mui/material";
import { PgColors } from "../../../configs/colors";

export const Sup2Intro = () => {
  return (
    <>
      <Grid container mt={2} marginLeft={2}>
        <Grid item>
          <Typography variant="h5" style={{ color: PgColors.redpg[500] }}>
            Supplement niveau 2
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item mt={0} xs={12}>
          <Paper
            elevation={2}
            style={{ backgroundColor: PgColors.bluepg[100], width: "100%" }}
          >
            <Container>
              <Grid container marginTop={1}>
                <Grid item xs={12} md={3}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontWeight: 500,
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    D Score
                  </Typography>
                </Grid>
                <Grid item md={9}></Grid>
              </Grid>
              <Grid container marginTop={0}>
                <Grid item xs={12} md={4} lg={3}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontWeight: 500,
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    Moeilijkheidswaarde (MW)
                  </Typography>
                </Grid>
                <Grid item xs={12} md={8} lg={9}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.white,
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    6 hoogste waarde elementen, met keuze uit A (0.1), B (0.2),
                    C (0.3)*
                  </Typography>
                </Grid>
              </Grid>
              <Grid container marginTop={0}>
                <Grid item xs={12} md={4} lg={3}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontWeight: 500,
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    Samenstellingselement (SE)
                  </Typography>
                </Grid>
                <Grid item xs={12} md={8} lg={9}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.white,
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    0.50 of 1.00 per element
                  </Typography>
                </Grid>
              </Grid>
              <Grid container marginTop={0}>
                <Grid item xs={12} md={4} lg={3}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontWeight: 500,
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    Bonus (B)
                  </Typography>
                </Grid>
                <Grid item xs={12} md={8} lg={9}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.white,
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    0.30 per bonus / 0.50 bij sprong
                  </Typography>
                </Grid>
              </Grid>
            </Container>
          </Paper>
        </Grid>
      </Grid>
      <Grid container marginLeft={2}>
        <Grid item xs={12}>
          <Typography
            variant="subtitle1"
            sx={{
              color: PgColors.redpg[500],
              fontSize: { xs: 12, sm: 14, md: 16 },
            }}
            align="left"
          >
            *Het turnen van D elementen of hoger is niet toegestaan m.u.v.
            aerials op balk (worden als C elementen geteld).
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};
