import { AppBar, Toolbar, Typography, Grid, Container } from "@mui/material";
import { NavLink } from "react-router-dom";
import { PgColors } from "../../configs/colors";
import { useTranslation, Trans } from "react-i18next";

export const Header = () => {
  const { t } = useTranslation();

  return (
    <AppBar
      position="sticky"
      style={{
        height: 125,
        width: "100",
        backgroundColor: PgColors.bluepg[100],
      }}
      sx={{
        borderBottom: 2,

        borderColor: "#000000",
      }}
    >
      <Container maxWidth={"xl"}>
        <Grid alignItems="center" marginTop={3}>
          <Toolbar>
            <Grid item xs={1}>
              <NavLink
                className="nav-link"
                to="/"
                style={{ textDecoration: "none" }}
              >
                <Typography
                  variant="h5"
                  sx={{
                    color: PgColors.redpg[200],
                    fontSize: { xs: 20, md: 30, lg: 42 },
                  }}
                >
                  Personal Gymnastics
                </Typography>
              </NavLink>
            </Grid>
            <Grid item marginLeft="auto">
              <NavLink to="/signup" style={{ textDecoration: "none" }}>
                <Typography
                  variant="h6"
                  sx={{
                    color: PgColors.redpg[200],
                    fontSize: { xs: 16, md: 30, lg: 30 },
                  }}
                >
                  {t("sign up")}
                </Typography>
              </NavLink>
            </Grid>
            <Grid item marginLeft={4}>
              <NavLink
                className="nav-link"
                to="/login"
                style={{ textDecoration: "none" }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    color: PgColors.redpg[200],
                    fontSize: { xs: 16, md: 30, lg: 30 },
                  }}
                >
                  {t("login")}
                </Typography>
              </NavLink>
            </Grid>
          </Toolbar>
        </Grid>
      </Container>
    </AppBar>
  );
};
