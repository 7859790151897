import React, { useState, useEffect } from "react";
import {
  Grid,
  Container,
  Typography,
  Divider,
  Button,
  Paper,
} from "@mui/material";
import { PgColors } from "../../../configs/colors";

export const Sup6Beam = () => {
  return (
    <>
      <Grid container mt={2} marginLeft={2}>
        <Grid item>
          <Typography variant="h6" sx={{ color: PgColors.redpg[500] }}>
            Balk
          </Typography>
        </Grid>
      </Grid>
      <Grid container marginLeft={2}>
        <Grid item>
          <Typography
            variant="subtitle1"
            sx={{
              color: PgColors.redpg[500],
              fontSize: { xs: 12, sm: 14, md: 16 },
            }}
            align="left"
          >
            *SE en bonussen 1 t/m 5 moeten op de balk geturnd worden.
          </Typography>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item mt={0} xs={12}>
          <Paper
            elevation={2}
            sx={{ backgroundColor: PgColors.bluepg[100], width: "100%" }}
          >
            <Container>
              <Grid
                container
                marginTop={0}
                sx={{ borderBottom: 1, borderColor: PgColors.bluepg[200] }}
                display={{ xs: "none", md: "flex" }}
              >
                <Grid
                  item
                  md={1}
                  display={{ xs: "none", md: "flex" }}
                  sx={{ marginRight: { md: -4, lg: -8 } }}
                ></Grid>
                <Grid item xs={11} md={5}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontWeight: 500,
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    Samenstellingselement
                  </Typography>
                </Grid>
                <Grid item xs={1} md={1} sx={{ pl: { md: 2 } }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontWeight: 500,
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    D
                  </Typography>
                </Grid>

                <Grid item md={5} display={{ xs: "none", md: "flex" }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontWeight: 500,
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    Bonus
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{
                    mr: { md: -4 },
                  }}
                  display={{ xs: "none", md: "flex" }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontWeight: 500,
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    D
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                container
                marginTop={1}
                sx={{ borderBottom: 1, borderColor: PgColors.bluepg[200] }}
                display={{ xs: "none", md: "flex" }}
              >
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{ marginRight: { md: -4, lg: -8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    1
                  </Typography>
                </Grid>
                <Grid item xs={10} md={5}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    Eén gymn sprong met beenspreiding min TA
                  </Typography>
                </Grid>
                <Grid item xs={1} md={1} sx={{ pl: { md: 2 } }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    0.50
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={5}
                  display={{ xs: "none", md: "flex" }}
                  sx={{ pr: { md: 2 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                      marginRight: { md: -2 },
                    }}
                    align="left"
                  >
                    Een 2e gymn sprong min TA in verb met SE 1
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={1}
                  display={{ xs: "none", md: "flex" }}
                  sx={{ mr: { md: -4 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    0.30
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                container
                marginTop={1}
                sx={{ borderBottom: 1, borderColor: PgColors.bluepg[200] }}
                display={{ xs: "none", md: "flex" }}
              >
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{ marginRight: { md: -4, lg: -8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    2
                  </Typography>
                </Grid>
                <Grid item xs={10} md={5}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    Voorgeschreven balk houdingen serie 3
                  </Typography>
                </Grid>
                <Grid item xs={1} md={1} sx={{ pl: { md: 2 } }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    1.00
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={5}
                  sx={{ pr: { md: 2 } }}
                  display={{ xs: "none", md: "flex" }}
                ></Grid>
                <Grid
                  item
                  md={1}
                  display={{ xs: "none", md: "flex" }}
                  sx={{ mr: { md: -4 } }}
                ></Grid>
              </Grid>

              <Grid
                container
                marginTop={1}
                sx={{ borderBottom: 1, borderColor: PgColors.bluepg[200] }}
                display={{ xs: "none", md: "flex" }}
              >
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{ marginRight: { md: -4, lg: -8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    3
                  </Typography>
                </Grid>
                <Grid item xs={10} md={5}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    Acro element min TA
                  </Typography>
                </Grid>
                <Grid item xs={1} md={1} sx={{ pl: { md: 2 } }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    0.50
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={5}
                  sx={{ pr: { md: 2 } }}
                  display={{ xs: "none", md: "flex" }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    Ipv SE 3: Acro element min A
                  </Typography>
                </Grid>
                <Grid item md={1} sx={{ mr: { md: -4 } }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    0.30
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                container
                marginTop={1}
                sx={{ borderBottom: 1, borderColor: PgColors.bluepg[200] }}
                display={{ xs: "none", md: "flex" }}
              >
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{ marginRight: { md: -4, lg: -8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    4
                  </Typography>
                </Grid>
                <Grid item xs={10} md={5}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    Vluchtige handstand (max 45º afwijken)
                  </Typography>
                </Grid>
                <Grid item xs={1} md={1} sx={{ pl: { md: 2 } }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    0.50
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={5}
                  display={{ xs: "none", md: "flex" }}
                  sx={{ pr: { md: 2 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    SE 4 uitgevoerd binnen 30º
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={1}
                  display={{ xs: "none", md: "flex" }}
                  sx={{ mr: { md: -4 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    0.30
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                container
                marginTop={1}
                sx={{ borderBottom: 1, borderColor: PgColors.bluepg[200] }}
                display={{ xs: "none", md: "flex" }}
              >
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{ marginRight: { md: -4, lg: -8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    5
                  </Typography>
                </Grid>
                <Grid item xs={10} md={5}></Grid>
                <Grid item xs={1} md={1} sx={{ pl: { md: 2 } }}></Grid>
                <Grid
                  item
                  md={5}
                  display={{ xs: "none", md: "flex" }}
                  sx={{ pr: { md: 2 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    (Min) ½ pirouete - vrije been in passé
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={1}
                  display={{ xs: "none", md: "flex" }}
                  sx={{ mr: { md: -4 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    0.30
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                container
                marginTop={1}
                display={{ xs: "none", md: "flex" }}
              >
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{ marginRight: { md: -4, lg: -8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    6
                  </Typography>
                </Grid>
                <Grid item xs={10} md={5}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    Afsprong: Arabier
                  </Typography>
                </Grid>
                <Grid item xs={1} md={1} sx={{ pl: { md: 2 } }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    0.50
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={5}
                  display={{ xs: "none", md: "flex" }}
                  sx={{ pr: { md: 2 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    Ipv SE 6: Afpsrong: Overslag
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={1}
                  display={{ xs: "none", md: "flex" }}
                  sx={{ mr: { md: -4 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    0.30
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                container
                mt={2}
                display={{ xs: "flex", md: "none" }}
                sx={{ borderBottom: 1, borderColor: PgColors.bluepg[200] }}
              >
                <Grid item xs={11} md={4} sx={{ marginRight: { md: 10 } }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontWeight: 500,
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    Samenstellingselement
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontWeight: 500,
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    D
                  </Typography>
                </Grid>
              </Grid>
              <Grid container mt={1} display={{ xs: "flex", md: "none" }}>
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{ marginRight: { md: -4, lg: -8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    1
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={10}
                  sx={{ paddingRight: { md: 0 }, marginRight: { md: 8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    Eén gymn sprong met beenspreiding min TA
                  </Typography>
                </Grid>
                <Grid item xs={1} md={1} sx={{ marginRight: { md: 0 } }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    0.50
                  </Typography>
                </Grid>
              </Grid>
              <Grid container mt={1} display={{ xs: "flex", md: "none" }}>
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{ marginRight: { md: -4, lg: -8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    2
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={10}
                  sx={{ paddingRight: { md: 0 }, marginRight: { md: 8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    Voorgeschreven balk houdingen serie 3
                  </Typography>
                </Grid>
                <Grid item xs={1} md={1} sx={{ marginRight: { md: 0 } }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    1.00
                  </Typography>
                </Grid>
              </Grid>
              <Grid container mt={1} display={{ xs: "flex", md: "none" }}>
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{ marginRight: { md: -4, lg: -8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    3
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={10}
                  sx={{ paddingRight: { md: 0 }, marginRight: { md: 8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    Acro element min TA
                  </Typography>
                </Grid>
                <Grid item xs={1} md={1} sx={{ marginRight: { md: 0 } }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    0.50
                  </Typography>
                </Grid>
              </Grid>
              <Grid container mt={1} display={{ xs: "flex", md: "none" }}>
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{ marginRight: { md: -4, lg: -8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    4
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={10}
                  sx={{ paddingRight: { md: 0 }, marginRight: { md: 8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    Vluchtige handstand (max 45º afwijken)
                  </Typography>
                </Grid>
                <Grid item xs={1} md={1} sx={{ marginRight: { md: 0 } }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    0.50
                  </Typography>
                </Grid>
              </Grid>
              <Grid container mt={1} display={{ xs: "flex", md: "none" }}>
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{ marginRight: { md: -4, lg: -8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    5
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={10}
                  sx={{ paddingRight: { md: 0 }, marginRight: { md: 8 } }}
                ></Grid>
                <Grid item xs={1} md={1} sx={{ marginRight: { md: 0 } }}></Grid>
              </Grid>
              <Grid container mt={1} display={{ xs: "flex", md: "none" }}>
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{ marginRight: { md: -4, lg: -8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    6
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={10}
                  sx={{ paddingRight: { md: 0 }, marginRight: { md: 8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    Afsprong: Arabier
                  </Typography>
                </Grid>
                <Grid item xs={1} md={1} sx={{ marginRight: { md: 0 } }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    0.50
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                container
                mt={2}
                display={{ xs: "flex", md: "none" }}
                sx={{ borderBottom: 1, borderColor: PgColors.redpg[200] }}
              >
                <Grid item xs={11} md={4} sx={{ marginRight: { md: 10 } }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontWeight: 500,
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    Bonus
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontWeight: 500,
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    D
                  </Typography>
                </Grid>
              </Grid>

              <Grid container mt={1} display={{ xs: "flex", md: "none" }}>
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{ marginRight: { md: -4, lg: -8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    1
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={10}
                  sx={{ paddingRight: { md: 0 }, marginRight: { md: 8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    Een 2e gymn sprong min TA in verb met SE 1
                  </Typography>
                </Grid>
                <Grid item xs={1} md={1} sx={{ marginRight: { md: 0 } }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    0.30
                  </Typography>
                </Grid>
              </Grid>
              <Grid container mt={1} display={{ xs: "flex", md: "none" }}>
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{ marginRight: { md: -4, lg: -8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    2
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={10}
                  sx={{ paddingRight: { md: 0 }, marginRight: { md: 8 } }}
                ></Grid>
                <Grid item xs={1} md={1} sx={{ marginRight: { md: 0 } }}></Grid>
              </Grid>
              <Grid container mt={1} display={{ xs: "flex", md: "none" }}>
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{ marginRight: { md: -4, lg: -8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    3
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={10}
                  sx={{ paddingRight: { md: 0 }, marginRight: { md: 8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    Ipv SE 3: Acro element min A
                  </Typography>
                </Grid>
                <Grid item xs={1} md={1} sx={{ marginRight: { md: 0 } }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    0.30
                  </Typography>
                </Grid>
              </Grid>
              <Grid container mt={1} display={{ xs: "flex", md: "none" }}>
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{ marginRight: { md: -4, lg: -8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    4
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={10}
                  sx={{ paddingRight: { md: 0 }, marginRight: { md: 8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    SE 4 uitgevoerd binnen 30º 
                  </Typography>
                </Grid>
                <Grid item xs={1} md={1} sx={{ marginRight: { md: 0 } }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    0.30
                  </Typography>
                </Grid>
              </Grid>
              <Grid container mt={1} display={{ xs: "flex", md: "none" }}>
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{ marginRight: { md: -4, lg: -8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    5
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={10}
                  sx={{ paddingRight: { md: 0 }, marginRight: { md: 8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    (Min) ½ pirouete - vrije been in passé
                  </Typography>
                </Grid>
                <Grid item xs={1} md={1} sx={{ marginRight: { md: 0 } }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    0.30
                  </Typography>
                </Grid>
              </Grid>
              <Grid container mt={1} display={{ xs: "flex", md: "none" }}>
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{ marginRight: { md: -4, lg: -8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    6
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={10}
                  sx={{ paddingRight: { md: 0 }, marginRight: { md: 8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    Ipv SE 6: Afpsrong: Overslag
                  </Typography>
                </Grid>
                <Grid item xs={1} md={1} sx={{ marginRight: { md: 0 } }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    0.30
                  </Typography>
                </Grid>
              </Grid>
            </Container>
          </Paper>
        </Grid>
      </Grid>
      <Grid container marginLeft={2}>
        <Grid item>
          <Typography
            variant="subtitle1"
            sx={{
              color: PgColors.redpg[500],
              fontSize: { xs: 12, sm: 14, md: 16 },
            }}
            align="left"
          >
            *SE 3 en SE 4/Bonus 4 mogen NIET gecombineerd worden met één element
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};
