import React, { useState, useEffect } from "react";
import {
  collection,
  query,
  onSnapshot,
  updateDoc,
  where,
  getDoc,
  doc,
  deleteDoc,
} from "firebase/firestore";
import { db } from "../../firebase/config";
import { Grid, Container, Typography, Divider, Button } from "@mui/material";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useNavigate } from "react-router-dom";
// import { Fysiek } from "./Fysiek";
// import { Sprong } from "./Sprong";
// import { Brug } from "./Brug";
// import { Balk } from "./Balk";
// import { Vloer } from "./Vloer";
import { PgColors } from "../../configs/colors";

export const AllElements = () => {
  const { authIsReady, user } = useAuthContext();
  const [programsList, setProgramsList] = useState([]);
  const navigate = useNavigate();
  const [active, setActive] = useState("Sprong");
  const [gymnast, setGymnast] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const storedActiveComponent = localStorage.getItem("activeComponent");

    if (storedActiveComponent) {
      setActive(storedActiveComponent);
    }

    if (authIsReady && user) {
      const ref = query(collection(db, `ProgramsByCoach/${user.uid}/Programs`));

      const unsub = onSnapshot(ref, (snapshot) => {
        let results: any = [];
        snapshot.docs.forEach((doc) => {
          results.push({ ...doc.data(), id: doc.id });
        });
        setProgramsList(results);
      });
      return () => unsub();
    }
  }, [authIsReady, user]);

  const handleEvent = (
    params: any,
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    navigate(`ProgramsByCoach/${user.uid}/Programs/${params.id}`);
  };

  // useEffect(() => {
  //   const fetchGymnastData = async () => {
  //     setIsLoading(true);
  //     const docSnap = await getDoc(doc(db, `users/${user.uid}`));
  //     if (docSnap.exists()) {
  //       const newGymnastObj = {
  //         id: docSnap.id,
  //         ...docSnap.data(),
  //       };
  //       setGymnast(newGymnastObj);
  //       setIsLoading(false);
  //     } else {
  //       //doc.data() will be undefined in this case
  //       console.log("No such document");
  //     }
  //   };
  //   fetchGymnastData();
  //   //eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    const fetchGymnastData = async () => {
      setIsLoading(true);

      if (authIsReady && user) {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        // console.log("user:", user);
        try {
          const docSnap = await getDoc(doc(db, `users/${user.uid}`));
          console.log("user after getDoc:", user.uid);
          if (docSnap.exists()) {
            const newGymnastObj = {
              id: docSnap.id,
              ...docSnap.data(),
            };
            setGymnast(newGymnastObj);
            setIsLoading(false);
          } else {
            //doc.data() will be undefined in this case
            console.log("No such document");
          }
        } catch (error) {
          console.error("Error fetching user document:", error);
        }
      }
    };

    fetchGymnastData();
  }, [authIsReady, user]);

  const handleComponentClick = (componentName: string) => {
    setActive(componentName);
    localStorage.setItem("activeComponent", componentName);
  };

  if (!authIsReady) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <Container>
        <Grid container marginTop={6}>
          <Grid
            item
            marginTop={2}
            md={2}
            justifyContent="center"
            display="flex"
          >
            <Button onClick={() => handleComponentClick("Kip")}>
              <Typography
                variant="subtitle1"
                style={{
                  color: PgColors.redpg[200],
                  fontSize: 20,
                  fontWeight: 500,
                }}
              >
                Kip
              </Typography>
            </Button>
          </Grid>
          <Grid
            item
            marginTop={2}
            md={2}
            justifyContent="center"
            display="flex"
          >
            <Button onClick={() => handleComponentClick("Brug")}>
              <Typography
                variant="subtitle1"
                style={{
                  color: PgColors.redpg[200],
                  fontSize: 20,
                  fontWeight: 500,
                }}
              >
                Brug
              </Typography>
            </Button>
          </Grid>
          <Grid
            item
            marginTop={2}
            md={2}
            justifyContent="center"
            display="flex"
          >
            <Button onClick={() => handleComponentClick("Balk")}>
              <Typography
                variant="subtitle1"
                style={{
                  color: PgColors.redpg[200],
                  fontSize: 20,
                  fontWeight: 500,
                }}
              >
                Balk
              </Typography>
            </Button>
          </Grid>
          <Grid
            item
            marginTop={2}
            md={2}
            justifyContent="center"
            display="flex"
          >
            <Button onClick={() => handleComponentClick("Vloer")}>
              <Typography
                variant="subtitle1"
                style={{
                  color: PgColors.redpg[200],
                  fontSize: 20,
                  fontWeight: 500,
                }}
              >
                Vloer
              </Typography>
            </Button>
          </Grid>
          <Grid
            item
            marginTop={2}
            md={2}
            justifyContent="center"
            display="flex"
          >
            <Button onClick={() => handleComponentClick("Fysiek")}>
              <Typography
                variant="subtitle1"
                style={{
                  color: PgColors.redpg[200],
                  fontSize: 20,
                  fontWeight: 500,
                }}
              >
                Fysiek
              </Typography>
            </Button>
          </Grid>
        </Grid>

        {/* {active === "Fysiek" &&
          (gymnast ? (
            <Fysiek
              path={`ProgramsByCoach/${user.uid}/Programs`}
              gymnast={gymnast}
            />
          ) : (
            <p>Loading gymnast data...</p>
          ))}

        {active === "Sprong" && (
          <Sprong
            path={`ProgramsByCoach/${user.uid}/Programs`}
            gymnast={gymnast}
          />
        )}

        {active === "Brug" && (
          <Brug
            path={`ProgramsByCoach/${user.uid}/Programs`}
            gymnast={gymnast}
          />
        )}
        {active === "Balk" && (
          <Balk
            path={`ProgramsByCoach/${user.uid}/Programs`}
            gymnast={gymnast}
          />
        )}
        {active === "Vloer" && (
          <Vloer
            path={`ProgramsByCoach/${user.uid}/Programs`}
            gymnast={gymnast}
          />
        )} */}
      </Container>
    </>
  );
};
