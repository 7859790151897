import React, { useState, useEffect } from "react";
import {
  collection,
  query,
  onSnapshot,
  updateDoc,
  getDoc,
  doc,
  deleteDoc,
} from "firebase/firestore";
import { db } from "../../firebase/config";
import { Grid, Container, Typography, Divider, Button } from "@mui/material";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { SeasonType } from "../../types/types";
import { Season } from "./Season";

export const SeasonComponent = () => {
  const { user } = useAuthContext();
  const [seasonsList, setSeasonsList] = useState([]);
  const [season, setSeason] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    const fetchLessonData = async () => {
      setIsLoading(true);
      const docSnap = await getDoc(
        doc(db, `SeasonsByCoach/${user.uid}/Seasons/${id}`)
      );
      if (docSnap.exists()) {
        const newLessonObj = {
          id: docSnap.id,
          ...docSnap.data(),
        };
        setSeason(newLessonObj);
        setIsLoading(false);
      } else {
        //doc.data() will be undefined in this case
        console.log("No such document");
      }
    };
    fetchLessonData();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Container>
        {Object.keys(season) && Object.keys(season).length ? (
          <Season season={season} />
        ) : null}
      </Container>
    </>
  );
};
