import React, { useState, useEffect } from "react";
import {
  collection,
  query,
  onSnapshot,
  updateDoc,
  doc,
  deleteDoc,
} from "firebase/firestore";
import {
  Grid,
  Container,
  Typography,
  Divider,
  Button,
  Paper,
} from "@mui/material";
import { Sup3Intro } from "./Sup3Intro";
import { Sup3Vault } from "./Sup3Vault";
import { Sup3Bars } from "./Sup3Bars";
import { Sup3Beam } from "./Sup3Beam";
import { Sup3Floor } from "./Sup3Floor";

export const Sup3 = () => {
  return (
    <>
      <Container>
        <Sup3Intro />
        <Sup3Vault />
        <Sup3Bars />
        <Sup3Beam />
        <Sup3Floor />
      </Container>
    </>
  );
};
