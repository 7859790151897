import React, { useState, useEffect } from "react";
import {
  collection,
  addDoc,
  serverTimestamp,
  query,
  onSnapshot,
  updateDoc,
  doc,
  deleteDoc,
} from "firebase/firestore";
import { Grid, Typography, Container, TextField, Button } from "@mui/material";
import { useAuthContext } from "../../hooks/useAuthContext";
import { db } from "../../firebase/config";
import { PgColors } from "../../configs/colors";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

export const AddProgram = () => {
  const [name, setName] = useState("");
  const [apparatus, setApparatus] = useState("");
  const [program, setProgram] = useState("");
  const { user } = useAuthContext();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const ref = collection(db, `ProgramsByCoach/${user.uid}/Programs`);

    await addDoc(ref, {
      name: name,
      apparatus: apparatus,
      program: program,
      uid: user.uid,
      createdAt: serverTimestamp(),
    });
    setName("");
    setApparatus("");
    setProgram("");
  };

  return (
    <>
      <Container>
        <form onSubmit={handleSubmit}>
          <Grid container>
            <Grid item marginTop={6}>
              <Typography
                variant="h6"
                style={{ color: PgColors.redpg[200], fontWeight: "bold" }}
              >
                Programma Naam
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item marginTop={1}>
              <Typography
                variant="h6"
                style={{ color: "#000000", fontWeight: "300", fontSize: 14 }}
              >
                Voer de naam van het programma in. Deze naam zal ook te zien
                zijn.
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} marginTop={1}>
              <TextField
                label="Naam"
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                fullWidth
                value={name}
                onChange={(event) => setName(event.target.value)}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item marginTop={4}>
              <Typography
                variant="h6"
                style={{ color: PgColors.redpg[200], fontWeight: "bold" }}
              >
                Toestel
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item md={12} marginTop={1}>
              <Typography
                variant="h6"
                style={{ color: "#000000", fontWeight: "300", fontSize: 14 }}
              >
                Voor het toestel is:
              </Typography>
            </Grid>
          </Grid>
          {/* <Grid container spacing={2}>
            <Grid item xs={12} marginTop={1}>
              <TextField
                label="Toestel / Fysiek"
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                fullWidth
                value={apparatus}
                onChange={(event) => setApparatus(event.target.value)}
              />
            </Grid>
          </Grid> */}
          <Grid container spacing={2}>
            <Grid item md={4}>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel>Toestel / Fysiek</InputLabel>
                <Select
                  fullWidth
                  value={apparatus}
                  onChange={(event) => setApparatus(event.target.value)}
                  autoWidth
                  label="Age"
                >
                  <MenuItem value={"Sprong"}>Sprong</MenuItem>
                  <MenuItem value={"Brug"}>Dames Brug</MenuItem>
                  <MenuItem value={"Balk"}>Balk</MenuItem>
                  <MenuItem value={"Vloer"}>Vloer</MenuItem>
                  <MenuItem value={"HBrug"}>Heren Brug</MenuItem>
                  <MenuItem value={"Rek"}>Rek</MenuItem>
                  <MenuItem value={"Voltige"}>Voltige</MenuItem>
                  <MenuItem value={"Ringen"}>Ringen</MenuItem>
                  <MenuItem value={"Fysiek"}>Fysiek</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item marginTop={4}>
              <Typography
                variant="h6"
                style={{ color: PgColors.redpg[200], fontWeight: "bold" }}
              >
                Programma
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item md={12} marginTop={1}>
              <Typography
                variant="h6"
                style={{ color: "#000000", fontWeight: "300", fontSize: 14 }}
              >
                Voer hier de inhoud van het programma in:
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} marginTop={1}>
              <TextField
                label="Inhoud"
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                fullWidth
                multiline
                minRows={12}
                value={program}
                onChange={(event) => setProgram(event.target.value)}
              />
            </Grid>
          </Grid>
          <Grid container mt={1} justifyContent="end" display="flex">
            <Grid item md={4} xs={12} justifyContent="end" display="flex">
              <Button
                style={{
                  backgroundColor: PgColors.bluepg[200],
                }}
                variant="contained"
                type="submit"
                size="large"
                sx={{ height: 55 }}
              >
                <Typography variant="subtitle1">Voeg programma toe</Typography>
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </>
  );
};
