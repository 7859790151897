import React, { useState, useEffect } from "react";
import {
  collection,
  query,
  orderBy,
  onSnapshot,
  updateDoc,
  doc,
  deleteDoc,
} from "firebase/firestore";
import { db } from "../../../../firebase/config";
import {
  Container,
  Grid,
  Typography,
  Checkbox,
  Paper,
  TextField,
  Button,
  Modal,
  Toolbar,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Box,
  Drawer,
  Divider,
} from "@mui/material";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { useAuthContext } from "../../../../hooks/useAuthContext";
import { useParams } from "react-router-dom";
import { PgColors } from "../../../../configs/colors";

export const ListExercise6A = ({ exercises }: any) => {
  const { id } = useParams();
  const { user } = useAuthContext();

  const handleDelete = async (exercise: any) => {
    const ref = doc(db, `IntroExercises/${id}/UserExercise6A/${exercise.id}`);
    await deleteDoc(ref);
  };

  return (
    <>
      <Container>
        {exercises.map((exercise: any) => (
          <>
            <Grid container alignItems="flex-end">
              <Grid
                item
                key={exercise.id}
                alignItems="center"
                // display={{ xs: "none", md: "flex" }}
                xs={12}
                sm={10}
              >
                <Paper
                  style={{
                    width: "100%",
                    backgroundColor: PgColors.bluepg[200],
                  }}
                >
                  <Grid item xs={12} ml={2}>
                    <Typography
                      variant="subtitle1"
                      style={{
                        color: "white",
                        whiteSpace: "pre-line",
                      }}
                      sx={{
                        fontSize: { xs: 12, md: 16 },
                      }}
                    >
                      Wanneer ik merk dat mijn motivatie minder is omdat{" "}
                      {exercise.answer}
                    </Typography>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={1} display={{ xs: "none", md: "flex" }}></Grid>
              <Grid item xs={1} sx={{ mt: { xs: 1, md: 0 } }}>
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: PgColors.bluepg[200],
                  }}
                  sx={{ minWidth: 20 }}
                  onClick={() => handleDelete(exercise)}
                >
                  <DeleteOutlinedIcon />
                </Button>
              </Grid>
            </Grid>
          </>
        ))}
      </Container>
    </>
  );
};

export const ListExercise6B = ({ exercises }: any) => {
  const { id } = useParams();
  const { user } = useAuthContext();

  const handleDelete = async (exercise: any) => {
    const ref = doc(db, `IntroExercises/${id}/UserExercise6B/${exercise.id}`);
    await deleteDoc(ref);
  };

  return (
    <>
      <Container>
        {exercises.map((exercise: any) => (
          <>
            <Grid container alignItems="flex-end">
              <Grid
                item
                key={exercise.id}
                alignItems="center"
                // display={{ xs: "none", md: "flex" }}
                xs={12}
                sm={10}
              >
                <Paper
                  style={{
                    width: "100%",
                    backgroundColor: PgColors.bluepg[200],
                  }}
                >
                  <Grid item xs={12} ml={2}>
                    <Typography
                      variant="subtitle1"
                      style={{
                        color: "white",
                        whiteSpace: "pre-line",
                      }}
                      sx={{
                        fontSize: { xs: 12, md: 16 },
                      }}
                    >
                      Zal ik in plaats van {exercise.answer}
                    </Typography>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={1} display={{ xs: "none", md: "flex" }}></Grid>
              <Grid item xs={1} sx={{ mt: { xs: 1, md: 0 } }}>
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: PgColors.bluepg[200],
                  }}
                  sx={{ minWidth: 20 }}
                  onClick={() => handleDelete(exercise)}
                >
                  <DeleteOutlinedIcon />
                </Button>
              </Grid>
            </Grid>
          </>
        ))}
      </Container>
    </>
  );
};

export const ListExercise6C = ({ exercises }: any) => {
  const { id } = useParams();
  const { user } = useAuthContext();

  const handleDelete = async (exercise: any) => {
    const ref = doc(db, `IntroExercises/${id}/UserExercise6C/${exercise.id}`);
    await deleteDoc(ref);
  };

  return (
    <>
      <Container>
        {exercises.map((exercise: any) => (
          <>
            <Grid container alignItems="flex-end">
              <Grid
                item
                key={exercise.id}
                alignItems="center"
                // display={{ xs: "none", md: "flex" }}
                xs={12}
                sm={10}
              >
                <Paper
                  style={{
                    width: "100%",
                    backgroundColor: PgColors.bluepg[200],
                  }}
                >
                  <Grid item xs={12} ml={2}>
                    <Typography
                      variant="subtitle1"
                      style={{
                        color: "white",
                        whiteSpace: "pre-line",
                      }}
                      sx={{
                        fontSize: { xs: 12, md: 16 },
                      }}
                    >
                      Deze strategie gebruiken: {exercise.answer}
                    </Typography>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={1} display={{ xs: "none", md: "flex" }}></Grid>
              <Grid item xs={1} sx={{ mt: { xs: 1, md: 0 } }}>
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: PgColors.bluepg[200],
                  }}
                  sx={{ minWidth: 20 }}
                  onClick={() => handleDelete(exercise)}
                >
                  <DeleteOutlinedIcon />
                </Button>
              </Grid>
            </Grid>
          </>
        ))}
      </Container>
    </>
  );
};
