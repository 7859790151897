import React, { useState, useEffect } from "react";
import {
  collection,
  query,
  onSnapshot,
  updateDoc,
  doc,
  deleteDoc,
  setDoc,
} from "firebase/firestore";
import {
  Grid,
  Container,
  Typography,
  Paper,
  Button,
  Checkbox,
} from "@mui/material";
import { useAuthContext } from "../../../../hooks/useAuthContext";
import { useParams } from "react-router-dom";
import { db } from "../../../../firebase/config";
import { PgColors } from "../../../../configs/colors";
import CheckIcon from "@mui/icons-material/Check";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";

type CheckboxState = {
  [key: string]: boolean;
};

export const Spreidhandstand = () => {
  const { user } = useAuthContext();
  const { id } = useParams();
  const [checkboxes, setCheckboxes] = useState<CheckboxState>({});

  const updateCheckboxState = async (checkboxId: any, isChecked: boolean) => {
    await setDoc(
      doc(db, `Skills/Bars/Spreidh/${id}/checkboxStates/${checkboxId}`),
      {
        isChecked,
      }
    );
  };

  useEffect(() => {
    const ref = query(
      collection(db, `Skills/Bars/Spreidh/${id}/checkboxStates`)
    );

    const unsub = onSnapshot(ref, (snapshot) => {
      const checkboxStates: any = [];
      snapshot.docs.forEach((doc) => {
        checkboxStates[doc.id] = doc.data().isChecked;
      });
      setCheckboxes(checkboxStates);
    });
    return () => unsub();
  }, []);

  return (
    <>
      <Grid container mt={2}>
        <Grid item xs={9} md={6}>
          <Typography
            variant="subtitle1"
            style={{ color: PgColors.redpg[500] }}
            sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
            align="left"
          >
            Fysieke Voorbereiding
          </Typography>
        </Grid>
        <Grid item xs={1} md={1} display="flex" justifyContent="center">
          <CheckIcon
            sx={{ color: PgColors.redpg[500], fontSize: { xs: 16, md: 20 } }}
          />
        </Grid>
        <Grid item xs={1} md={1} display="flex" justifyContent="center">
          <ThumbUpIcon
            sx={{ color: PgColors.redpg[500], fontSize: { xs: 16, md: 20 } }}
          />
        </Grid>
        <Grid item xs={1} md={1} display="flex" justifyContent="center">
          <EmojiEventsIcon
            sx={{ color: PgColors.redpg[500], fontSize: { xs: 16, md: 20 } }}
          />
        </Grid>
      </Grid>

      <Grid container sx={{ mt: { xs: 1, md: 1 } }}>
        <Grid item xs={9} md={6}>
          <Paper
            style={{ backgroundColor: PgColors.bluepg[100] }}
            sx={{ height: { xs: 40, sm: 25, md: 60, lg: 35 } }}
          >
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Spreidhandstand heffen op onderarmen - 3hh | 5hh | 8hh
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId4"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId4"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId4"]: newCheckedState,
              });
              updateCheckboxState("checkboxId4", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId5"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId5"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId5"]: newCheckedState,
              });
              updateCheckboxState("checkboxId5", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId6"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId6"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId6"]: newCheckedState,
              });
              updateCheckboxState("checkboxId6", newCheckedState);
            }}
          />
        </Grid>
      </Grid>
      <Grid container sx={{ mt: { xs: 1, sm: -0.3, md: 0.3, lg: -1 } }}>
        <Grid item xs={9} md={6}>
          <Paper
            style={{ backgroundColor: PgColors.bluepg[100] }}
            sx={{ height: { xs: 65, sm: 45, md: 60, lg: 70 } }}
          >
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Ligsteun met voeten op slider, voeten naar handen brengen en terug
              - 5hh | 10hh | 15hh
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId40"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId40"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId40"]: newCheckedState,
              });
              updateCheckboxState("checkboxId40", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId41"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId41"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId41"]: newCheckedState,
              });
              updateCheckboxState("checkboxId41", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId42"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId42"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId42"]: newCheckedState,
              });
              updateCheckboxState("checkboxId42", newCheckedState);
            }}
          />
        </Grid>
      </Grid>

      <Grid container sx={{ mt: { xs: 1, sm: 0.3, md: 0.3, lg: 0.3 } }}>
        <Grid item xs={9} md={6}>
          <Paper
            style={{ backgroundColor: PgColors.bluepg[200] }}
            sx={{ height: { xs: 65, sm: 45, md: 60, lg: 70 } }}
          >
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Ligsteun met voeten op slider, voeten naar handen gespreid brengen
              en terug - 5hh | 10hh | 15hh
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId43"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId43"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId43"]: newCheckedState,
              });
              updateCheckboxState("checkboxId43", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId44"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId44"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId44"]: newCheckedState,
              });
              updateCheckboxState("checkboxId44", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId45"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId45"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId45"]: newCheckedState,
              });
              updateCheckboxState("checkboxId45", newCheckedState);
            }}
          />
        </Grid>
      </Grid>

      <Grid container sx={{ mt: { xs: 1, sm: 0.3, md: 0.3, lg: 0.3 } }}>
        <Grid item xs={9} md={6}>
          <Paper
            style={{ backgroundColor: PgColors.bluepg[200] }}
            sx={{ height: { xs: 85, sm: 65, md: 85, lg: 110 } }}
          >
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Ruglig op vloer met handen tegen rand van de mat. Alles moet plat
              op de vloer zijn, probeer vooral je onderrug ook plat te krijgen -
              10T | 20T | 30T
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId46"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId46"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId46"]: newCheckedState,
              });
              updateCheckboxState("checkboxId46", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId47"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId47"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId47"]: newCheckedState,
              });
              updateCheckboxState("checkboxId47", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId48"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId48"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId48"]: newCheckedState,
              });
              updateCheckboxState("checkboxId48", newCheckedState);
            }}
          />
        </Grid>
      </Grid>
      <Grid container sx={{ mt: { xs: 1, sm: 0.3, md: 0.3, lg: 0.3 } }}>
        <Grid item xs={9} md={6}>
          <Paper
            style={{ backgroundColor: PgColors.bluepg[200] }}
            sx={{ height: { xs: 40, sm: 25, md: 35, lg: 35 } }}
          >
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Spreidhandstand kaatsen in serie - 5hh | 10hh | 15hh
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId16"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId16"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId16"]: newCheckedState,
              });
              updateCheckboxState("checkboxId16", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId17"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId17"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId17"]: newCheckedState,
              });
              updateCheckboxState("checkboxId17", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId18"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId18"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId18"]: newCheckedState,
              });
              updateCheckboxState("checkboxId18", newCheckedState);
            }}
          />
        </Grid>
      </Grid>
      <Grid container sx={{ mt: { xs: 1, sm: -1, md: -1, lg: -1 } }}>
        <Grid item xs={9} md={6}>
          <Paper
            style={{ backgroundColor: PgColors.bluepg[200] }}
            sx={{ height: { xs: 85, sm: 65, md: 85, lg: 110 } }}
          >
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Buiklig op vloer met handen tegen rand van de mat. Alles moet plat
              op de vloer zijn en dan probeer je je ribben iets in te trekken -
              10T | 20T | 30T
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId49"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId49"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId49"]: newCheckedState,
              });
              updateCheckboxState("checkboxId49", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId50"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId50"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId50"]: newCheckedState,
              });
              updateCheckboxState("checkboxId50", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId51"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId51"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId51"]: newCheckedState,
              });
              updateCheckboxState("checkboxId51", newCheckedState);
            }}
          />
        </Grid>
      </Grid>
      <Grid container sx={{ mt: { xs: 1, sm: 0.3, md: 0.3, lg: 0.3 } }}>
        <Grid item xs={9} md={6}>
          <Paper
            style={{ backgroundColor: PgColors.bluepg[300] }}
            sx={{ height: { xs: 130, sm: 85, md: 140, lg: 140 } }}
          >
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Ruglig op vloer, liggen onder een plofmat. Lichaam moet plat zijn.
              Zwaai met rechte armen, actief een zware stok van laag op het
              plofmat naar hoog zodat je armen langs je oren zijn - 10hh | 20hh
              | 30hh
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId52"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId52"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId52"]: newCheckedState,
              });
              updateCheckboxState("checkboxId52", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId53"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId53"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId53"]: newCheckedState,
              });
              updateCheckboxState("checkboxId53", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId54"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId54"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId54"]: newCheckedState,
              });
              updateCheckboxState("checkboxId54", newCheckedState);
            }}
          />
        </Grid>
      </Grid>
      <Grid container sx={{ mt: { xs: 1, sm: 0.3, md: 0.3, lg: 0.3 } }}>
        <Grid item xs={9} md={6}>
          <Paper
            style={{ backgroundColor: PgColors.bluepg[300] }}
            sx={{ height: { xs: 65, sm: 45, md: 85, lg: 70 } }}
          >
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Tosti tegen muur of blok (voeten zijn los), heffen totdat voeten
              horizontaal zijn en weer terug - 5hh | 10hh | 15hh
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId55"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId55"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId55"]: newCheckedState,
              });
              updateCheckboxState("checkboxId55", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId56"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId56"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId56"]: newCheckedState,
              });
              updateCheckboxState("checkboxId56", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId57"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId57"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId57"]: newCheckedState,
              });
              updateCheckboxState("checkboxId57", newCheckedState);
            }}
          />
        </Grid>
      </Grid>

      <Grid container sx={{ mt: { xs: 1, sm: 0.3, md: 0.3, lg: 0.3 } }}>
        <Grid item xs={9} md={6}>
          <Paper
            style={{ backgroundColor: PgColors.bluepg[400] }}
            sx={{ height: { xs: 65, sm: 45, md: 85, lg: 110 } }}
          >
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Handstand op klos, voeten gespreid op twee blokken. Laatste stuk
              heffen naar handstand en terug - 3hh | 5hh | 8hh
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId19"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId19"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId19"]: newCheckedState,
              });
              updateCheckboxState("checkboxId19", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId20"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId20"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId20"]: newCheckedState,
              });
              updateCheckboxState("checkboxId20", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId21"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId21"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId21"]: newCheckedState,
              });
              updateCheckboxState("checkboxId21", newCheckedState);
            }}
          />
        </Grid>
      </Grid>

      <Grid container sx={{ mt: { xs: 1, sm: 0.3, md: 0.3, lg: 0.3 } }}>
        <Grid item xs={9} md={6}>
          <Paper
            style={{ backgroundColor: PgColors.bluepg[400] }}
            sx={{ height: { xs: 45, md: 35 } }}
          >
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Omgekeerde hang wandrek naar tosti - 3hh | 5hh | 8hh
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId25"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId25"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId25"]: newCheckedState,
              });
              updateCheckboxState("checkboxId25", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId26"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId26"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId26"]: newCheckedState,
              });
              updateCheckboxState("checkboxId26", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId27"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId27"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId27"]: newCheckedState,
              });
              updateCheckboxState("checkboxId27", newCheckedState);
            }}
          />
        </Grid>
      </Grid>

      <Grid container mt={2}>
        <Grid item xs={9} md={6}>
          <Typography
            variant="subtitle1"
            style={{ color: PgColors.redpg[500] }}
            sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
            align="left"
          >
            Spreidhandstand Drills
          </Typography>
        </Grid>
        <Grid item xs={1} md={1} display="flex" justifyContent="center">
          <CheckIcon
            sx={{ color: PgColors.redpg[500], fontSize: { xs: 16, md: 20 } }}
          />
        </Grid>
        <Grid item xs={1} md={1} display="flex" justifyContent="center">
          <ThumbUpIcon
            sx={{ color: PgColors.redpg[500], fontSize: { xs: 16, md: 20 } }}
          />
        </Grid>
        <Grid item xs={1} md={1} display="flex" justifyContent="center">
          <EmojiEventsIcon
            sx={{ color: PgColors.redpg[500], fontSize: { xs: 16, md: 20 } }}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={9} md={6}>
          <Paper
            style={{ backgroundColor: PgColors.bluepg[100] }}
            sx={{ height: { xs: 65, sm: 45, md: 60, lg: 70 } }}
          >
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Ruglig: van strek naar tosti en terug, terwijl bovenlijf plat op
              de grond blijft - 3hh | 5hh | 10hh
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId1"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId1"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId1"]: newCheckedState,
              });
              updateCheckboxState("checkboxId1", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId2"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId2"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId2"]: newCheckedState,
              });
              updateCheckboxState("checkboxId2", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId3"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId3"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId3"]: newCheckedState,
              });
              updateCheckboxState("checkboxId3", newCheckedState);
            }}
          />
        </Grid>
      </Grid>
      <Grid container sx={{ mt: { xs: 1, sm: 0.3, md: 0.3, lg: 0.3 } }}>
        <Grid item xs={9} md={6}>
          <Paper style={{ backgroundColor: PgColors.bluepg[100] }}>
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Spreidhandstand springen met buik naar muur - 3hh | 5hh | 8hh
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId7"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId7"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId7"]: newCheckedState,
              });
              updateCheckboxState("checkboxId7", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId8"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId8"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId8"]: newCheckedState,
              });
              updateCheckboxState("checkboxId8", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId9"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId9"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId9"]: newCheckedState,
              });
              updateCheckboxState("checkboxId9", newCheckedState);
            }}
          />
        </Grid>
      </Grid>
      <Grid container sx={{ mt: { xs: 1, sm: -0.8, md: 0.3, lg: 0.3 } }}>
        <Grid item xs={9} md={6}>
          <Paper style={{ backgroundColor: PgColors.bluepg[200] }}>
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Spreidhandstand klos vanuit kniezit - 3hh | 5hh | 8hh
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId10"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId10"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId10"]: newCheckedState,
              });
              updateCheckboxState("checkboxId10", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId11"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId11"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId11"]: newCheckedState,
              });
              updateCheckboxState("checkboxId11", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId12"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId12"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId12"]: newCheckedState,
              });
              updateCheckboxState("checkboxId12", newCheckedState);
            }}
          />
        </Grid>
      </Grid>

      <Grid container sx={{ mt: { xs: 1, sm: -0.8, md: 0.3, lg: -1 } }}>
        <Grid item xs={9} md={6}>
          <Paper style={{ backgroundColor: PgColors.bluepg[200] }}>
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Opspreiden brug, doorvallen - 1hh | 3hh | 5hh in één beurt
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId13"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId13"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId13"]: newCheckedState,
              });
              updateCheckboxState("checkboxId13", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId14"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId14"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId14"]: newCheckedState,
              });
              updateCheckboxState("checkboxId14", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId15"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId15"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId15"]: newCheckedState,
              });
              updateCheckboxState("checkboxId15", newCheckedState);
            }}
          />
        </Grid>
      </Grid>
      <Grid container sx={{ mt: { xs: 1, sm: -0.8, md: 0.3, lg: -1 } }}>
        <Grid item xs={9} md={6}>
          <Paper style={{ backgroundColor: PgColors.bluepg[300] }}>
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Spreidhandstand heffen vanuit ligsteun met voeten op - airtrack
              rol | fitness bal | sliders
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId22"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId22"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId22"]: newCheckedState,
              });
              updateCheckboxState("checkboxId22", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId23"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId23"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId23"]: newCheckedState,
              });
              updateCheckboxState("checkboxId23", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId24"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId24"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId24"]: newCheckedState,
              });
              updateCheckboxState("checkboxId24", newCheckedState);
            }}
          />
        </Grid>
      </Grid>
      <Grid container sx={{ mt: { xs: 1, sm: 0.3, md: 0.3, lg: 0.3 } }}>
        <Grid item xs={9} md={6}>
          <Paper style={{ backgroundColor: PgColors.bluepg[300] }}>
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Tosti brug, doorvallen - 1hh | 3hh | 5hh in één beurt
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId28"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId28"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId28"]: newCheckedState,
              });
              updateCheckboxState("checkboxId28", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId29"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId29"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId29"]: newCheckedState,
              });
              updateCheckboxState("checkboxId29", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId30"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId30"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId30"]: newCheckedState,
              });
              updateCheckboxState("checkboxId30", newCheckedState);
            }}
          />
        </Grid>
      </Grid>
      <Grid container sx={{ mt: { xs: 1, sm: -0.8, md: -1.8, lg: -1 } }}>
        <Grid item xs={9} md={6}>
          <Paper style={{ backgroundColor: PgColors.bluepg[300] }}>
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Spreidhandstand - 1hh | 3hh | 5hh in één beurt
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId31"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId31"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId31"]: newCheckedState,
              });
              updateCheckboxState("checkboxId31", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId32"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId32"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId32"]: newCheckedState,
              });
              updateCheckboxState("checkboxId32", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId33"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId33"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId33"]: newCheckedState,
              });
              updateCheckboxState("checkboxId33", newCheckedState);
            }}
          />
        </Grid>
      </Grid>
      <Grid container sx={{ mt: { xs: 1, sm: -0.8, md: -1.8, lg: -1 } }}>
        <Grid item xs={9} md={6}>
          <Paper style={{ backgroundColor: PgColors.bluepg[400] }}>
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Kip spreidhandstand - 1hh | 3hh | 5hh in één beurt
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId34"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId34"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId34"]: newCheckedState,
              });
              updateCheckboxState("checkboxId34", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId35"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId35"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId35"]: newCheckedState,
              });
              updateCheckboxState("checkboxId35", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId36"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId36"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId36"]: newCheckedState,
              });
              updateCheckboxState("checkboxId36", newCheckedState);
            }}
          />
        </Grid>
      </Grid>
      <Grid container mb={2} sx={{ mt: { xs: 1, sm: -0.8, md: -1.8, lg: -1 } }}>
        <Grid item xs={9} md={6}>
          <Paper style={{ backgroundColor: PgColors.bluepg[400] }}>
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Kip spreidhandstand in serie - 3hh | 5hh | 8hh
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId37"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId37"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId37"]: newCheckedState,
              });
              updateCheckboxState("checkboxId37", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId38"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId38"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId38"]: newCheckedState,
              });
              updateCheckboxState("checkboxId38", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId39"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId39"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId39"]: newCheckedState,
              });
              updateCheckboxState("checkboxId39", newCheckedState);
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};
