import React, { useState, useEffect } from "react";
import { collection, query, onSnapshot, orderBy } from "firebase/firestore";
import {
  Grid,
  Container,
  Typography,
  Paper,
  Button,
  Box,
  CardMedia,
} from "@mui/material";
import { useAuthContext } from "../../../../hooks/useAuthContext";
import { useParams } from "react-router-dom";
import { db } from "../../../../firebase/config";
import { PgColors } from "../../../../configs/colors";
import { AddExercise6A, AddExercise6B, AddExercise6C } from "./AddExercise6";
import {
  ListExercise6A,
  ListExercise6B,
  ListExercise6C,
} from "./ListExercise6";

export const GrowingMotivationPage = () => {
  const { authIsReady, user } = useAuthContext();
  const [exercise6AList, setExercise6AList] = useState([]);
  const [exercise6BList, setExercise6BList] = useState([]);
  const [exercise6CList, setExercise6CList] = useState([]);

  const { id } = useParams();

  useEffect(() => {
    const ref = query(
      collection(db, `IntroExercises/${id}/UserExercise6A`),
      orderBy("createdAt", "desc")
    );

    const unsub = onSnapshot(ref, (snapshot) => {
      let results: any = [];
      snapshot.docs.forEach((doc) => {
        results.push({ ...doc.data(), id: doc.id });
      });
      setExercise6AList(results);
    });
    return () => unsub();
  }, []);

  useEffect(() => {
    const ref = query(
      collection(db, `IntroExercises/${id}/UserExercise6B`),
      orderBy("createdAt", "desc")
    );

    const unsub = onSnapshot(ref, (snapshot) => {
      let results: any = [];
      snapshot.docs.forEach((doc) => {
        results.push({ ...doc.data(), id: doc.id });
      });
      setExercise6BList(results);
    });
    return () => unsub();
  }, []);

  useEffect(() => {
    const ref = query(
      collection(db, `IntroExercises/${id}/UserExercise6C`),
      orderBy("createdAt", "desc")
    );

    const unsub = onSnapshot(ref, (snapshot) => {
      let results: any = [];
      snapshot.docs.forEach((doc) => {
        results.push({ ...doc.data(), id: doc.id });
      });
      setExercise6CList(results);
    });
    return () => unsub();
  }, []);

  return (
    <>
      <Grid container mt={2}>
        <Grid item xs={12}>
          <Paper
            style={{ backgroundColor: PgColors.redpg[400] }}
            sx={{ height: { xs: 45, md: 110 } }}
          >
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, sm: 14, md: 16, lg: 20 } }}
              align="center"
            >
              "Never give up!
            </Typography>
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, sm: 14, md: 16, lg: 20 } }}
              align="center"
            >
              Failure and rejection are only the first steps to succeeding."
            </Typography>

            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, sm: 14, md: 16, lg: 20 } }}
              align="center"
            >
              - Jim Valvano
            </Typography>
          </Paper>
        </Grid>
      </Grid>
      <Grid container mt={2}>
        <Grid item xs={12} mt={1}>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
            }}
            align="left"
          >
            Er zijn meerdere stategieën die je kan gebruiken om je motivatie te
            laten groeien. Voorbeelden zijn:
          </Typography>
        </Grid>
        <Grid item xs={12} mt={2}>
          <Typography
            variant="subtitle1"
            style={{ color: PgColors.bluepg[400] }}
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
              fontWeight: { md: 500 },
            }}
            align="left"
          >
            Eerlijk zijn tegen jezelf
          </Typography>
        </Grid>
        <Grid item xs={12} mt={0}>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
            }}
            align="left"
          >
            Wat is je huidige motivatie level? Waarom doe je aan turnen? Wat
            zijn je doelen, welke skills moet je verbeteren, hoe is je mentale
            weerbaarheid, etc.
          </Typography>
        </Grid>
        <Grid item xs={12} mt={2}>
          <Typography
            variant="subtitle1"
            style={{ color: PgColors.bluepg[400] }}
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
              fontWeight: { md: 500 },
            }}
            align="left"
          >
            Jezelf herinneren aan waarom je aan turnen doet
          </Typography>
        </Grid>
        <Grid item xs={12} mt={0}>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
            }}
            align="left"
          >
            Een turnseizoen kan lang zijn en het is normaal om jezelf af te
            vragen waarom je jezelf door de fysieke en mentale uitdagingen wilt
            slepen.
          </Typography>
        </Grid>
        <Grid item xs={12} mt={0}>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
            }}
            align="left"
          >
            Denk in die periodes aan waarom je aan turnen doet.
          </Typography>
        </Grid>
        <Grid item xs={12} mt={2}>
          <Typography
            variant="subtitle1"
            style={{ color: PgColors.bluepg[400] }}
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
              fontWeight: { md: 500 },
            }}
            align="left"
          >
            Niet-oordelend zijn
          </Typography>
        </Grid>
        <Grid item xs={12} mt={0}>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
            }}
            align="left"
          >
            Probeer om wat liever voor jezelf te zijn wanneer je fouten maakt.
            Fouten zijn onderdeel van het leer proces en iedereen maakt ze.
            Niet-oordelend zijn betekent om te proberen jezelf niet met anderen
            te vergelijken.
          </Typography>
        </Grid>
        <Grid item xs={12} mt={2}>
          <Typography
            variant="subtitle1"
            style={{ color: PgColors.bluepg[400] }}
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
              fontWeight: { md: 500 },
            }}
            align="left"
          >
            Manieren vinden om succes te ervaren
          </Typography>
        </Grid>
        <Grid item xs={12} mt={0}>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
            }}
            align="left"
          >
            Wanneer je succesvol bent of het goed gaat, neemt je motivatie toe.
            Probeer jezelf daarom in situatie te stoppen waarin je succesvol
            kunt zijn. In plaats van een skill alleen op de wedstrijd balk te
            oefenen en er van af vallen omdat je bang bent of de skill nog niet
            helemaal beheerst, kun je beter eerst de skill op de lage balk
            oefenen. Of nog beter, de skill in kleine bijoefenigen of drills
            opdelen die je eerst kan beheersen.
          </Typography>
        </Grid>
        <Grid item xs={12} mt={2}>
          <Typography
            variant="subtitle1"
            style={{ color: PgColors.bluepg[400] }}
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
              fontWeight: { md: 500 },
            }}
            align="left"
          >
            Focus op taken en niet het resultaat
          </Typography>
        </Grid>
        <Grid item xs={12} mt={0}>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
            }}
            align="left"
          >
            Wanneer je de focus legt op de taken van het beter worden ipv alleen
            het resultaat - de dingen die binnen je eigen invloed liggen - zal
            je motivatie toe nemen. Denk hierbij bijvoorbeeld aan je proces
            doelen ipv je resultaat doel.
          </Typography>
        </Grid>
        <Grid item xs={12} mt={2}>
          <Typography
            variant="subtitle1"
            style={{ color: PgColors.bluepg[400] }}
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
              fontWeight: { md: 500 },
            }}
            align="left"
          >
            Doorgaan na je fouten
          </Typography>
        </Grid>
        <Grid item xs={12} mt={0}>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
            }}
            align="left"
          >
            Je zal fouten maken en wedstrijden verliezen in turnen, maar ook in
            het leven. De beste turn(st)er in de wereld zal ook dit ook elke dag
            ervaren. Het is belangrijk om een "Dory" (van Finding Nemo) te zijn
            wanneer je fouten maakt. Probeer van de fouten te leren en laat ze
            dan ook los.
          </Typography>
        </Grid>

        <Grid item xs={12} mt={2}>
          <Typography
            variant="subtitle1"
            style={{ color: PgColors.bluepg[400] }}
            sx={{
              fontSize: { xs: 16, md: 20, lg: 24 },
              fontWeight: { md: 500 },
            }}
            align="left"
          >
            Opdracht 6
          </Typography>
        </Grid>
      </Grid>

      <Grid container mt={2}>
        <Grid item xs={12} mt={1}>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
            }}
            align="left"
          >
            Vul onderstaande tekstvelden in zodat je een strategie kunt maken om
            je motivatie weer toe te laten nemen als dat nodig is.
          </Typography>
        </Grid>
      </Grid>
      <Grid container mt={1}>
        <AddExercise6A />
      </Grid>
      <Grid container mt={1}>
        <AddExercise6B />
      </Grid>
      <Grid container mt={1}>
        <AddExercise6C />
      </Grid>
      <Grid container mt={4} mb={2}>
        {exercise6AList && (
          <ListExercise6A
            exercises={exercise6AList}
            path={`IntroExercises/${id}/UserExercise6A/${id}`}
          />
        )}
      </Grid>
      <Grid container mt={0} mb={2}>
        {exercise6BList && (
          <ListExercise6B
            exercises={exercise6BList}
            path={`IntroExercises/${id}/UserExercise6B/${id}`}
          />
        )}
      </Grid>
      <Grid container mt={0} mb={2}>
        {exercise6CList && (
          <ListExercise6C
            exercises={exercise6CList}
            path={`IntroExercises/${id}/UserExercise6C/${id}`}
          />
        )}
      </Grid>
    </>
  );
};
