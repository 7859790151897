import React, { useState, useEffect } from "react";
import { collection, query, onSnapshot, orderBy } from "firebase/firestore";
import {
  Grid,
  Container,
  Typography,
  Paper,
  Button,
  ButtonGroup,
} from "@mui/material";
import { useAuthContext } from "../../../../hooks/useAuthContext";
import SvgIcon from "@mui/material/SvgIcon";
import { useParams } from "react-router-dom";
import { db } from "../../../../firebase/config";
import { PgColors } from "../../../../configs/colors";
import { VaultSkills } from "./VaultSkills";
import { BarsSkills } from "./BarsSkills";
import { BeamSkills } from "./BeamSkills";
import { FloorSkills } from "./FloorSkills";

export const SkillsPage = () => {
  const [active, setActive] = useState("Vault");
  const activeColor = PgColors.bluepg[400];

  return (
    <>
      <Grid container mt={2}>
        <Grid
          item
          xs={12}
          display={{ xs: "none", md: "flex" }}
          justifyContent="center"
        >
          <ButtonGroup variant="contained">
            <Button
              style={{
                backgroundColor:
                  active === "Vault" ? activeColor : PgColors.bluepg[200],
              }}
              variant="contained"
              onClick={() => setActive("Vault")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 12, md: 14 } }}
              >
                Sprong
              </Typography>
            </Button>
            <Button
              style={{
                backgroundColor:
                  active === "Bars" ? activeColor : PgColors.bluepg[200],
              }}
              variant="contained"
              onClick={() => setActive("Bars")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 12, md: 14 } }}
              >
                Brug
              </Typography>
            </Button>
            <Button
              style={{
                backgroundColor:
                  active === "Beam" ? activeColor : PgColors.bluepg[200],
              }}
              variant="contained"
              onClick={() => setActive("Beam")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 12, md: 14 } }}
              >
                Balk
              </Typography>
            </Button>
            <Button
              style={{
                backgroundColor:
                  active === "Floor" ? activeColor : PgColors.bluepg[200],
              }}
              variant="contained"
              onClick={() => setActive("Floor")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 12, md: 14 } }}
              >
                Vloer
              </Typography>
            </Button>
          </ButtonGroup>
        </Grid>
        <Grid
          item
          xs={12}
          display={{ xs: "flex", md: "none" }}
          justifyContent="center"
        >
          <ButtonGroup variant="contained">
            <Button
              style={{
                backgroundColor:
                  active === "Vault" ? activeColor : PgColors.bluepg[200],
              }}
              variant="contained"
              onClick={() => setActive("Vault")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 12, md: 14 } }}
              >
                Sprong
              </Typography>
            </Button>
            <Button
              style={{
                backgroundColor:
                  active === "Bars" ? activeColor : PgColors.bluepg[200],
              }}
              variant="contained"
              onClick={() => setActive("Bars")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 12, md: 14 } }}
              >
                Brug
              </Typography>
            </Button>
            <Button
              style={{
                backgroundColor:
                  active === "Beam" ? activeColor : PgColors.bluepg[200],
              }}
              variant="contained"
              onClick={() => setActive("Beam")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 12, md: 14 } }}
              >
                Balk
              </Typography>
            </Button>
            <Button
              style={{
                backgroundColor:
                  active === "Floor" ? activeColor : PgColors.bluepg[200],
              }}
              variant="contained"
              onClick={() => setActive("Floor")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 12, md: 14 } }}
              >
                Vloer
              </Typography>
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid>
      <Grid container>{active === "Vault" && <VaultSkills />}</Grid>
      <Grid container>{active === "Bars" && <BarsSkills />}</Grid>
      <Grid container>{active === "Beam" && <BeamSkills />}</Grid>
      <Grid container>{active === "Floor" && <FloorSkills />}</Grid>
    </>
  );
};
