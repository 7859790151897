import React, { useState, useEffect } from "react";
import { Grid, Container } from "@mui/material";
import { SupCIntro } from "./SupCIntro";
import { SupCSE } from "./SupCSE";
import { SupCVW } from "./SupCVW";

export const SupC = () => {
  return (
    <>
      <Container>
        <SupCIntro />
        <SupCSE />
        <SupCVW />
      </Container>
    </>
  );
};
