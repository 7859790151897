import React, { useState, useEffect } from "react";
import { NewGymnastType } from "../../../types/gymnast";
import { Grid, Typography } from "@mui/material";
import {
  collection,
  query,
  onSnapshot,
  where,
  orderBy,
  addDoc,
  serverTimestamp,
  doc,
  setDoc,
  onSnapshotsInSync,
} from "firebase/firestore";
import { useAuthContext } from "../../../hooks/useAuthContext";
import { db } from "../../../firebase/config";
import { PgColors } from "../../../configs/colors";

// interface IProps {
//   gymnast: NewGymnastType;
// }

export const Name2 = ({ path }: any) => {
  const { user } = useAuthContext();
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const ref = query(collection(db, "users"), where("id", "==", user.uid));

    const unsub = onSnapshot(ref, (snapshot) => {
      let results: any = [];
      snapshot.docs.forEach((doc) => {
        results.push({ ...doc.data(), id: doc.id });
      });
      setUsers(results);
    });
    return () => unsub();
  }, []);

  return (
    <>
      {users?.map((gymnast: any) => (
        <Grid container key={gymnast.id}>
          <Grid item marginTop={6}>
            <Typography
              variant="h6"
              style={{ color: PgColors.bluepg[200], fontWeight: "bold" }}
            >
              {gymnast.firstname}
            </Typography>
          </Grid>
        </Grid>
      ))}
    </>
  );
};
