import React, { useState, useEffect } from "react";
import {
  collection,
  query,
  onSnapshot,
  orderBy,
  updateDoc,
  doc,
  deleteDoc,
} from "firebase/firestore";
import { db } from "../../../../firebase/config";
import { Grid } from "@mui/material";
import { AddGoal2 } from "./AddGoal2";
import { GoalsList2 } from "./GoalsList2";
import { useAuthContext } from "../../../../hooks/useAuthContext";
import { useParams } from "react-router-dom";

export const Goals2Page = () => {
  const { authIsReady, user } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [goalList, setGoalList] = useState([]);
  const { id } = useParams();

  // if (user !== null) {
  //   console.log(user.uid);
  // }

  useEffect(() => {
    if (authIsReady && user) {
      const ref = query(
        collection(db, `Goals/${user.uid}/userGoals`),
        orderBy("createdAt", "desc")
      );

      const unsub = onSnapshot(ref, (snapshot) => {
        let results: any = [];
        snapshot.docs.forEach((doc) => {
          results.push({ ...doc.data(), id: doc.id });
        });
        setGoalList(results);
      });
      return () => unsub();
    }
  }, [authIsReady, user]);

  if (!authIsReady) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <Grid container mt={2}>
        <AddGoal2 />
      </Grid>
      <Grid container mt={4}>
        {goalList && (
          <GoalsList2 goals={goalList} path={`Goals/${id}/userGoals/${id}`} />
        )}
      </Grid>
      {/* <Modal></Modal> */}
    </>
  );
};
