import React, { useState, useEffect } from "react";
import { collection, query, onSnapshot, orderBy } from "firebase/firestore";
import {
  Grid,
  Container,
  Typography,
  Paper,
  Button,
  Box,
  CardMedia,
} from "@mui/material";
import { useAuthContext } from "../../../../hooks/useAuthContext";
import { useParams } from "react-router-dom";
import { db } from "../../../../firebase/config";
import { PgColors } from "../../../../configs/colors";
import { AddExercise5A } from "./AddExercise5";
import { ListExercise5A } from "./ListExercise5";

export const MotivationPage = () => {
  const { authIsReady, user } = useAuthContext();
  const [exercise5AList, setExercise5AList] = useState([]);

  const { id } = useParams();

  useEffect(() => {
    const ref = query(
      collection(db, `IntroExercises/${user.uid}/UserExercise5A`),
      orderBy("createdAt", "desc")
    );

    const unsub = onSnapshot(ref, (snapshot) => {
      let results: any = [];
      snapshot.docs.forEach((doc) => {
        results.push({ ...doc.data(), id: doc.id });
      });
      setExercise5AList(results);
    });
    return () => unsub();
  }, []);

  return (
    <>
      <Grid container mt={2}>
        <Grid item xs={12}>
          <Paper
            style={{ backgroundColor: PgColors.redpg[400] }}
            sx={{ height: { xs: 45, md: 110 } }}
          >
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, sm: 14, md: 16, lg: 20 } }}
              align="center"
            >
              "Don't be afraid of failure.
            </Typography>
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, sm: 14, md: 16, lg: 20 } }}
              align="center"
            >
              This is the way to succeed."
            </Typography>

            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, sm: 14, md: 16, lg: 20 } }}
              align="center"
            >
              - LeBron James
            </Typography>
          </Paper>
        </Grid>
      </Grid>
      <Grid container mt={2}>
        <Grid item xs={12} mt={1}>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
            }}
            align="left"
          >
            Er zijn twee soorten motivatie: interne en externe motivatie. Je
            hebt dit vast al eerder gehoord maar interne motivati komt vanuit
            jezelf en externe motivatie komt van invloeden buiten jezelf.
            Interne motivatie is vaak sterker en het type wat nodig is voor
            lange termijn succes.
          </Typography>
        </Grid>
        <Grid item xs={12} mt={2}>
          <Typography
            variant="subtitle1"
            style={{ color: PgColors.bluepg[400] }}
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
              fontWeight: { md: 500 },
            }}
            align="left"
          >
            Voorbeelden interne motivatie:
          </Typography>
        </Grid>
        <Grid item xs={12} mt={1}>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
            }}
            align="left"
          >
            - Plezier in de sport: Je vind de sport op zichzelf heel leuk
          </Typography>
        </Grid>
        <Grid item xs={12} mt={0}>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
            }}
            align="left"
          >
            - Plezier in het trainen en het trainen voor de sport
          </Typography>
        </Grid>
        <Grid item xs={12} mt={0}>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
            }}
            align="left"
          >
            - Plezier in het leren van de technische apsecten van de sport
          </Typography>
        </Grid>
        <Grid item xs={12} mt={0}>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
            }}
            align="left"
          >
            - Plezier in het doen van wedstrijden
          </Typography>
        </Grid>
        <Grid item xs={12} mt={0}>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
            }}
            align="left"
          >
            - De vaardigheid om gemotiveerd te blijven ondanks tegenslagen
          </Typography>
        </Grid>
        <Grid item xs={12} mt={2}>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
            }}
            align="left"
          >
            Het is heel normaal om ook tijden te hebben waarin je motivatie
            minder is en misschien zelf wel het plezier in turnen minder is.
            Niemand kan altijd 100% gemotiveerd zijn.
          </Typography>
        </Grid>
        <Grid item xs={12} mt={2}>
          <Typography
            variant="subtitle1"
            style={{ color: PgColors.bluepg[400] }}
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
              fontWeight: { md: 500 },
            }}
            align="left"
          >
            Voorbeelden externe motivatie:
          </Typography>
        </Grid>
        <Grid item xs={12} mt={0}>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
            }}
            align="left"
          >
            - Plezier in de sport door belongen, medailles en complimenten
          </Typography>
        </Grid>
        <Grid item xs={12} mt={0}>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
            }}
            align="left"
          >
            - De noodzaak voor steeds grotere beloningen om gemotiveerd te
            blijven
          </Typography>
        </Grid>
        <Grid item xs={12} mt={2}>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
            }}
            align="left"
          >
            Interne motivatie is dus nodig voor lange termijn succes, maar beide
            type motivatie hebben voordelen. Als je je motivatie maximaal wilt
            laten groeien, gebruik dan beide vormen. Doe de sport omdat je zelf
            veel plezier haalt uit turnen. Dan, wanneer je het nodig hebt,
            gebruik je externe beloningen om je motivatie (weer) te verhogen.
          </Typography>
        </Grid>

        <Grid item xs={12} mt={2}>
          <Typography
            variant="subtitle1"
            style={{ color: PgColors.bluepg[400] }}
            sx={{
              fontSize: { xs: 16, md: 20, lg: 24 },
              fontWeight: { md: 500 },
            }}
            align="left"
          >
            Opdracht 5
          </Typography>
        </Grid>
      </Grid>

      <Grid container mt={2}>
        <Grid item xs={12} mt={0}>
          <Typography
            variant="subtitle1"
            style={{ color: PgColors.bluepg[400] }}
            sx={{
              fontSize: { xs: 12, md: 16, lg: 20 },
              fontWeight: { md: 400 },
            }}
            align="left"
          >
            Stap 1
          </Typography>
        </Grid>
        <Grid item xs={12} mt={1}>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
            }}
            align="left"
          >
            Denk aan een tijd wanneer je wat minder gemotiveerd was om te
            turnen. Schrijf de mogelijke oorzaken hiervoor op:
          </Typography>
        </Grid>
        <Grid item xs={12} mt={1}>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
            }}
            align="left"
          >
            Mogelijke oorzak(en) kunnen bijvoorbeeld zijn:
          </Typography>
        </Grid>
        <Grid item xs={12} mt={1}>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
            }}
            align="left"
          >
            - Fysieke vermoeidheid: Het kan zijn dat je lichamelijk moe of
            uigeput bent.
          </Typography>
        </Grid>
        <Grid item xs={12} mt={0}>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
            }}
            align="left"
          >
            - Minder presteren: Misschien krijg je niet de resultaten die je
            wilt.
          </Typography>
        </Grid>
        <Grid item xs={12} mt={0}>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
            }}
            align="left"
          >
            - Te weinig slaap: te weinig uren slaap gehad.
          </Typography>
        </Grid>
        <Grid item xs={12} mt={0}>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
            }}
            align="left"
          >
            - Voeding: Eet je genoeg en de juiste voeding voor lichaam en je
            hoofd.
          </Typography>
        </Grid>
        <Grid item xs={12} mt={0}>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
            }}
            align="left"
          >
            - Algemene gezondheid: Ben je verkouden of ziek geweest?
          </Typography>
        </Grid>
        <Grid item xs={12} mt={0}>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
            }}
            align="left"
          >
            - Zelfvertrouwen: Heb je moeite gehad om te geloven dat je bepaalde
            skills of oefeningen kunt?
          </Typography>
        </Grid>
      </Grid>
      <Grid container mt={1}>
        <AddExercise5A />
      </Grid>
      <Grid container mt={4} mb={2}>
        {exercise5AList && (
          <ListExercise5A
            exercises={exercise5AList}
            path={`IntroExercises/${id}/UserExercise5A/${id}`}
          />
        )}
      </Grid>
      <Grid container mt={2}>
        <Grid item xs={12} mt={1}>
          <Typography
            variant="subtitle1"
            style={{ color: PgColors.bluepg[400] }}
            sx={{
              fontSize: { xs: 12, md: 16, lg: 20 },
              fontWeight: { md: 400 },
            }}
            align="left"
          >
            Stap 2
          </Typography>
        </Grid>
        <Grid item xs={12} mt={1} mb={2}>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
            }}
            align="left"
          >
            Om meer inzicht te krijgen in je eigen motivatie kan het interessant
            zijn om je motivatie per training bij te houden. Dit kan je doen
            door bijvoorbeeld een cijfer te geven op een schaal van 1-5 voor je
            motivatie per training. Als je merkt dat je in een periode wat vaker
            een 1 of een 2 scoort kan je reflecteren waarom dat zo is.
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};
