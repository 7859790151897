import React, { useState } from "react";
import {
  collection,
  addDoc,
  serverTimestamp,
  doc,
  deleteDoc,
  setDoc,
} from "firebase/firestore";
import { db } from "../../../../firebase/config";
import {
  Container,
  Grid,
  Typography,
  Paper,
  TextField,
  Button,
  Modal,
  Toolbar,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Box,
  Drawer,
} from "@mui/material";
import { useAuthContext } from "../../../../hooks/useAuthContext";
import { useParams } from "react-router-dom";
import { PgColors } from "../../../../configs/colors";

export const AddExercise6A = () => {
  const [answer, setAnswer] = useState("");
  const { user } = useAuthContext();
  const { id } = useParams();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const ref = collection(db, `IntroExercises/${id}/UserExercise6A`);

    await addDoc(ref, {
      answer: answer,
      uid: user.uid,
      createdAt: serverTimestamp(),
    });
    setAnswer("");
  };

  return (
    <>
      <Container>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} alignItems="flex-end">
            <Grid item xs={12} mt={1} sx={{ mb: { xs: -1, md: -2 } }}>
              <Typography
                variant="body1"
                sx={{
                  fontSize: { xs: 10, md: 12, lg: 16 },
                }}
                align="left"
              >
                Wanneer ik merk dat mijn motivatie minder is omdat ...
              </Typography>
            </Grid>
            <Grid item md={10} xs={12}>
              <TextField
                InputLabelProps={{
                  sx: {
                    fontSize: { xs: 10, md: 14, lg: 16 },
                  },
                }}
                size="small"
                label="Ik veel fouten heb gemaakt in de training en wedstrijd"
                color="warning"
                variant="outlined"
                fullWidth
                multiline
                rows={1}
                value={answer}
                onChange={(event) => setAnswer(event.target.value)}
              />
            </Grid>

            <Grid item md={2} xs={12}>
              <Button
                style={{
                  backgroundColor: PgColors.bluepg[200],
                }}
                variant="contained"
                type="submit"
                size="large"
                sx={{ height: { xs: 30, md: 55, lg: 55 } }}
              >
                <Typography variant="subtitle1">Voeg Toe</Typography>
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </>
  );
};

export const AddExercise6B = () => {
  const [answer, setAnswer] = useState("");
  const { user } = useAuthContext();
  const { id } = useParams();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const ref = collection(db, `IntroExercises/${id}/UserExercise6B`);

    await addDoc(ref, {
      answer: answer,
      uid: user.uid,
      createdAt: serverTimestamp(),
    });
    setAnswer("");
  };

  return (
    <>
      <Container>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} alignItems="flex-end">
            <Grid item xs={12} mt={1} sx={{ mb: { xs: -1, md: -2 } }}>
              <Typography
                variant="body1"
                sx={{
                  fontSize: { xs: 10, md: 12, lg: 16 },
                }}
                align="left"
              >
                Zal ik in plaats van ...
              </Typography>
            </Grid>
            <Grid item md={10} xs={12}>
              <TextField
                InputLabelProps={{
                  sx: {
                    fontSize: { xs: 10, md: 14, lg: 16 },
                  },
                }}
                size="small"
                label="Blijven hangen in deze fouten"
                color="warning"
                variant="outlined"
                fullWidth
                multiline
                rows={1}
                value={answer}
                onChange={(event) => setAnswer(event.target.value)}
              />
            </Grid>

            <Grid item md={2} xs={12}>
              <Button
                style={{
                  backgroundColor: PgColors.bluepg[200],
                }}
                variant="contained"
                type="submit"
                size="large"
                sx={{ height: { xs: 30, md: 55, lg: 55 } }}
              >
                <Typography variant="subtitle1">Voeg Toe</Typography>
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </>
  );
};

export const AddExercise6C = () => {
  const [answer, setAnswer] = useState("");
  const { user } = useAuthContext();
  const { id } = useParams();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const ref = collection(db, `IntroExercises/${id}/UserExercise6C`);

    await addDoc(ref, {
      answer: answer,
      uid: user.uid,
      createdAt: serverTimestamp(),
    });
    setAnswer("");
  };

  return (
    <>
      <Container>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} alignItems="flex-end">
            <Grid item xs={12} mt={1} sx={{ mb: { xs: -1, md: -2 } }}>
              <Typography
                variant="body1"
                sx={{
                  fontSize: { xs: 10, md: 12, lg: 16 },
                }}
                align="left"
              >
                Deze strategie gebruiken:
              </Typography>
            </Grid>
            <Grid item md={10} xs={12}>
              <TextField
                InputLabelProps={{
                  sx: {
                    fontSize: { xs: 10, md: 14, lg: 16 },
                  },
                }}
                size="small"
                label="Fouten laten gaan en een manier vinden om succes te ervaren"
                color="warning"
                variant="outlined"
                fullWidth
                multiline
                rows={1}
                value={answer}
                onChange={(event) => setAnswer(event.target.value)}
              />
            </Grid>

            <Grid item md={2} xs={12}>
              <Button
                style={{
                  backgroundColor: PgColors.bluepg[200],
                }}
                variant="contained"
                type="submit"
                size="large"
                sx={{ height: { xs: 30, md: 55, lg: 55 } }}
              >
                <Typography variant="subtitle1">Voeg Toe</Typography>
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </>
  );
};
