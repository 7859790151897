import React, { useState } from "react";
import {
  collection,
  addDoc,
  serverTimestamp,
  doc,
  deleteDoc,
  setDoc,
} from "firebase/firestore";
import { db } from "../../../../firebase/config";
import {
  Container,
  Grid,
  Typography,
  Paper,
  TextField,
  Button,
  Modal,
  Toolbar,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Box,
  Drawer,
} from "@mui/material";
import { useAuthContext } from "../../../../hooks/useAuthContext";
import { useParams } from "react-router-dom";
import { PgColors } from "../../../../configs/colors";

export const AddExercise9A = () => {
  const [answer, setAnswer] = useState("");
  const { user } = useAuthContext();
  const { id } = useParams();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const ref = collection(db, `IntroExercises/${id}/UserExercise9A`);

    await addDoc(ref, {
      answer: answer,
      uid: user.uid,
      createdAt: serverTimestamp(),
    });
    setAnswer("");
  };

  return (
    <>
      <Container>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} alignItems="flex-end">
            <Grid item xs={12} mt={1} mb={-2}>
              <Typography
                variant="body1"
                sx={{
                  fontSize: { xs: 10, md: 12, lg: 16 },
                }}
                align="left"
              >
                Wanneer ik merk dat ik veel spanning ervaar omdat ...
              </Typography>
            </Grid>
            <Grid item sm={10} md={10} xs={12}>
              <TextField
                InputLabelProps={{
                  sx: {
                    fontSize: { xs: 10, md: 14, lg: 16 },
                  },
                }}
                size="small"
                label="ik focus op het resultaat en de scores"
                color="warning"
                variant="outlined"
                fullWidth
                multiline
                rows={1}
                value={answer}
                onChange={(event) => setAnswer(event.target.value)}
              />
            </Grid>

            <Grid item sm={2} md={2} xs={12}>
              <Button
                style={{
                  backgroundColor: PgColors.bluepg[200],
                }}
                variant="contained"
                type="submit"
                size="large"
                sx={{ height: { xs: 30, sm: 40, md: 40, lg: 40 } }}
              >
                <Typography>+</Typography>
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </>
  );
};

export const AddExercise9B = () => {
  const [answer, setAnswer] = useState("");
  const { user } = useAuthContext();
  const { id } = useParams();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const ref = collection(db, `IntroExercises/${id}/UserExercise9B`);

    await addDoc(ref, {
      answer: answer,
      uid: user.uid,
      createdAt: serverTimestamp(),
    });
    setAnswer("");
  };

  return (
    <>
      <Container>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} alignItems="flex-end">
            <Grid item xs={12} mt={1} mb={-2}>
              <Typography
                variant="body1"
                sx={{
                  fontSize: { xs: 10, md: 12, lg: 16 },
                }}
                align="left"
              >
                Zal ik in plaats van ...
              </Typography>
            </Grid>
            <Grid item sm={10} md={10} xs={12}>
              <TextField
                InputLabelProps={{
                  sx: {
                    fontSize: { xs: 10, md: 14, lg: 16 },
                  },
                }}
                size="small"
                label="denken aan de dingen die mis kunnen gaan"
                color="warning"
                variant="outlined"
                fullWidth
                multiline
                rows={1}
                value={answer}
                onChange={(event) => setAnswer(event.target.value)}
              />
            </Grid>

            <Grid item sm={2} md={2} xs={12}>
              <Button
                style={{
                  backgroundColor: PgColors.bluepg[200],
                }}
                variant="contained"
                type="submit"
                size="large"
                sx={{ height: { xs: 30, sm: 40, md: 40, lg: 40 } }}
              >
                <Typography variant="subtitle1">+</Typography>
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </>
  );
};

export const AddExercise9C = () => {
  const [answer, setAnswer] = useState("");
  const { user } = useAuthContext();
  const { id } = useParams();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const ref = collection(db, `IntroExercises/${id}/UserExercise9C`);

    await addDoc(ref, {
      answer: answer,
      uid: user.uid,
      createdAt: serverTimestamp(),
    });
    setAnswer("");
  };

  return (
    <>
      <Container>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} alignItems="flex-end">
            <Grid item xs={12} mt={1} mb={-2}>
              <Typography
                variant="body1"
                sx={{
                  fontSize: { xs: 10, md: 12, lg: 16 },
                }}
                align="left"
              >
                Deze strategie gebruiken:
              </Typography>
            </Grid>
            <Grid item sm={10} xs={12}>
              <TextField
                InputLabelProps={{
                  sx: {
                    fontSize: { xs: 10, md: 14, lg: 16 },
                  },
                }}
                size="small"
                label="Box-breathing om rustig te worden en dan aan het 'hoe' denken"
                color="warning"
                variant="outlined"
                fullWidth
                multiline
                rows={1}
                value={answer}
                onChange={(event) => setAnswer(event.target.value)}
              />
            </Grid>

            <Grid item sm={2} xs={12}>
              <Button
                style={{
                  backgroundColor: PgColors.bluepg[200],
                }}
                variant="contained"
                type="submit"
                size="large"
                sx={{ height: { xs: 30, sm: 40, lg: 40 } }}
              >
                <Typography variant="subtitle1">+</Typography>
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </>
  );
};
