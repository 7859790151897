import React, { useState, useEffect } from "react";
import { Grid, Container } from "@mui/material";
import { SupAIntro } from "./SupAIntro";
import { SupASE } from "./SupASE";
import { SupAVW } from "./SupAVW";

export const SupA = () => {
  return (
    <>
      <Container>
        <SupAIntro />
        <SupASE />
        <SupAVW />
      </Container>
    </>
  );
};
