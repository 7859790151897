import React, { useState } from "react";
import {
  collection,
  addDoc,
  serverTimestamp,
  doc,
  setDoc,
} from "firebase/firestore";
import { db } from "../../../../firebase/config";
import {
  Container,
  Grid,
  Typography,
  Paper,
  TextField,
  Button,
  Modal,
  Toolbar,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Box,
  Drawer,
} from "@mui/material";
import { useAuthContext } from "../../../../hooks/useAuthContext";
import { useParams } from "react-router-dom";
import { PgColors } from "../../../../configs/colors";

export const AddGoal = () => {
  const [goal, setGoal] = useState("");
  const [apparatus, setApparatus] = useState("");
  const [date, setDate] = useState("");
  const { user } = useAuthContext();
  const { id } = useParams();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const ref = collection(db, `Goals/${id}/userGoals`);

    await addDoc(ref, {
      goal: goal,
      apparatus: apparatus,
      date: date,
      completed: false,
      uid: user.uid,
      createdAt: serverTimestamp(),
    });
    setGoal("");
    setApparatus("");
    setDate("");
  };

  return (
    <>
      <Container>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <TextField
                label="Goal/Doel"
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                fullWidth
                value={goal}
                onChange={(event) => setGoal(event.target.value)}
              />
            </Grid>
            <Grid item md={2} xs={12}>
              <TextField
                label="Toestel"
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                fullWidth
                value={apparatus}
                onChange={(event) => setApparatus(event.target.value)}
              />
            </Grid>
            <Grid item md={2} xs={12}>
              <TextField
                label="Datum"
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                fullWidth
                value={date}
                onChange={(event) => setDate(event.target.value)}
              />
            </Grid>
            <Grid item md={2} xs={12}>
              <Button
                style={{
                  backgroundColor: PgColors.bluepg[200],
                }}
                variant="contained"
                type="submit"
                size="large"
                sx={{ height: 55 }}
              >
                <Typography variant="subtitle1">Voeg Toe</Typography>
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </>
  );
};
