import React, { useState, useEffect } from "react";
import { collection, query, onSnapshot, orderBy } from "firebase/firestore";
import {
  Grid,
  Container,
  Typography,
  Paper,
  Button,
  ButtonGroup,
} from "@mui/material";
import { useAuthContext } from "../../../../hooks/useAuthContext";

import { useParams } from "react-router-dom";
import { db } from "../../../../firebase/config";
import { PgColors } from "../../../../configs/colors";
import { Division3Vault } from "./Division3Vault";
import { Division3Bars } from "./Division3Bars";
import { Division3Beam } from "./Division3Beam";
import { Division3Floor } from "./Division3Floor";
import { Division4Vault } from "./Division4Vault";
import { Division4Bars } from "./Division4Bars";
import { Division4Beam } from "./Division4Beam";
import { Division4Floor } from "./Division4Floor";
import { Division5Vault } from "./Division5Vault";
import { Division5Bars } from "./Division5Bars";
import { Division5Beam } from "./Division5Beam";
import { Division5Floor } from "./Division5Floor";

export const Division5 = () => {
  const [active, setActive] = useState("Vault");
  const activeColor = PgColors.bluepg[400];

  return (
    <>
      <Grid container mt={2}>
        <Grid item xs={3}>
          <Paper
            style={{ backgroundColor: PgColors.redpg[50] }}
            sx={{ height: { xs: 20, md: 35 } }}
          >
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Jeugd G
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={3}>
          <Paper
            style={{ backgroundColor: PgColors.redpg[100] }}
            sx={{ height: { xs: 20, md: 35 } }}
          >
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Junior F
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={3}>
          <Paper
            style={{ backgroundColor: PgColors.redpg[200] }}
            sx={{ height: { xs: 20, md: 35 } }}
          >
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Senior E
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={3}>
          <Paper
            style={{ backgroundColor: PgColors.redpg[400] }}
            sx={{ height: { xs: 20, md: 35 } }}
          >
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Senior D
            </Typography>
          </Paper>
        </Grid>
      </Grid>
      <Grid container mt={2}>
        <Grid item xs={12}>
          <ButtonGroup variant="contained">
            <Button
              style={{
                backgroundColor:
                  active === "Vault" ? activeColor : PgColors.bluepg[200],
              }}
              variant="contained"
              onClick={() => setActive("Vault")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 10, md: 14 } }}
              >
                Sprong
              </Typography>
            </Button>
            <Button
              style={{
                backgroundColor:
                  active === "Bars" ? activeColor : PgColors.bluepg[200],
              }}
              variant="contained"
              onClick={() => setActive("Bars")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 10, md: 14 } }}
              >
                Brug
              </Typography>
            </Button>
            <Button
              style={{
                backgroundColor:
                  active === "Beam" ? activeColor : PgColors.bluepg[200],
              }}
              variant="contained"
              onClick={() => setActive("Beam")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 10, md: 14 } }}
              >
                Balk
              </Typography>
            </Button>
            <Button
              style={{
                backgroundColor:
                  active === "Floor" ? activeColor : PgColors.bluepg[200],
              }}
              variant="contained"
              onClick={() => setActive("Floor")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 10, md: 14 } }}
              >
                Vloer
              </Typography>
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid>
      <Grid container>{active === "Vault" && <Division5Vault />}</Grid>
      <Grid container>{active === "Bars" && <Division5Bars />}</Grid>
      <Grid container>{active === "Beam" && <Division5Beam />}</Grid>
      <Grid container>{active === "Floor" && <Division5Floor />}</Grid>
    </>
  );
};
