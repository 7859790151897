import React, { useState, useEffect } from "react";
import { Grid, Container } from "@mui/material";
import { SupFIntro } from "./SupFIntro";
import { SupFSE } from "./SupFSE";
import { SupFVW } from "./SupFVW";

export const SupF = () => {
  return (
    <>
      <Container>
        <SupFIntro />
        <SupFSE />
        <SupFVW />
      </Container>
    </>
  );
};
