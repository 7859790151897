import React, { useState, useEffect } from "react";
import {
  collection,
  query,
  onSnapshot,
  updateDoc,
  doc,
  deleteDoc,
} from "firebase/firestore";
import {
  Grid,
  Container,
  Typography,
  Divider,
  Button,
  Paper,
} from "@mui/material";
import { SupA } from "./SupA/SupA";
import { SupB } from "./SupB/SupB";
import { SupC } from "./SupC/SupC";
import { SupD } from "./SupD/SupD";
import { SupE } from "./SupE/SupE";
import { SupF } from "./SupF/SupF";
import { SupH } from "./SupH/SupH";
import { SupG } from "./SupG/SupG";
import { PgColors } from "../../configs/colors";

export const KeuzeOefenstof = () => {
  const [active, setActive] = useState("SupA");

  return (
    <>
      <Container>
        <Grid container mt={2} spacing={1}>
          <Grid item xs={3} md={1}>
            <Button
              style={{
                backgroundColor: PgColors.bluepg[200],
              }}
              variant="contained"
              onClick={() => setActive("SupA")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 14 } }}
              >
                Sup A
              </Typography>
            </Button>
          </Grid>
          <Grid item xs={3} md={1}>
            <Button
              style={{
                backgroundColor: PgColors.bluepg[200],
              }}
              variant="contained"
              onClick={() => setActive("SupB")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 14 } }}
              >
                Sup B
              </Typography>
            </Button>
          </Grid>
          <Grid item xs={3} md={1}>
            <Button
              style={{
                backgroundColor: PgColors.bluepg[200],
              }}
              variant="contained"
              onClick={() => setActive("SupC")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 14 } }}
              >
                Sup C
              </Typography>
            </Button>
          </Grid>
          <Grid item xs={3} md={1}>
            <Button
              style={{
                backgroundColor: PgColors.bluepg[200],
              }}
              variant="contained"
              onClick={() => setActive("SupD")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 14 } }}
              >
                Sup D
              </Typography>
            </Button>
          </Grid>
          <Grid item xs={3} md={1}>
            <Button
              style={{
                backgroundColor: PgColors.bluepg[200],
              }}
              variant="contained"
              onClick={() => setActive("SupE")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 14 } }}
              >
                Sup E
              </Typography>
            </Button>
          </Grid>
          <Grid item xs={3} md={1}>
            <Button
              style={{
                backgroundColor: PgColors.bluepg[200],
              }}
              variant="contained"
              onClick={() => setActive("SupF")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 14 } }}
              >
                Sup F
              </Typography>
            </Button>
          </Grid>
          <Grid item xs={3} md={1}>
            <Button
              style={{
                backgroundColor: PgColors.bluepg[200],
              }}
              variant="contained"
              onClick={() => setActive("SupG")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 14 } }}
              >
                Sup G
              </Typography>
            </Button>
          </Grid>
          <Grid item xs={3} md={1}>
            <Button
              style={{
                backgroundColor: PgColors.bluepg[200],
              }}
              variant="contained"
              onClick={() => setActive("SupH")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 14 } }}
              >
                Sup H
              </Typography>
            </Button>
          </Grid>
        </Grid>
        {active === "SupA" && <SupA />}
        {active === "SupB" && <SupB />}
        {active === "SupC" && <SupC />}
        {active === "SupD" && <SupD />}
        {active === "SupE" && <SupE />}
        {active === "SupF" && <SupF />}
        {active === "SupG" && <SupG />}
        {active === "SupH" && <SupH />}
      </Container>
    </>
  );
};
