import React, { useState, useEffect } from "react";
import { collection, query, onSnapshot, orderBy } from "firebase/firestore";
import {
  Grid,
  Container,
  Typography,
  Paper,
  Button,
  ButtonGroup,
} from "@mui/material";
import { useAuthContext } from "../../../../hooks/useAuthContext";
import { useParams } from "react-router-dom";
import { db } from "../../../../firebase/config";
import { PgColors } from "../../../../configs/colors";
import { Wippertje } from "./Wippertje";
import { Spreidhandstand } from "./Spreidhandstand";
import { KipLaag } from "./KipLaag";
import { Losom } from "./Losom";
import { ReusAo } from "./ReusAo";
import { Overvliegen } from "./OvervliegenBars";
import { Zolenhandstand } from "./Zolenhandstand";

export const BarsSkills = () => {
  const [active, setActive] = useState("Wippertje");
  const activeColor = PgColors.bluepg[400];

  return (
    <>
      <Grid container mt={2} sx={{ display: { xs: "none", md: "flex" } }}>
        <Grid item xs={12} display="flex" justifyContent="center">
          <ButtonGroup variant="contained">
            <Button
              style={{
                backgroundColor:
                  active === "Wippertje" ? activeColor : PgColors.bluepg[200],
              }}
              variant="contained"
              onClick={() => setActive("Wippertje")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 10, md: 14 } }}
              >
                Wippertje
              </Typography>
            </Button>
            <Button
              style={{
                backgroundColor:
                  active === "Kip" ? activeColor : PgColors.bluepg[200],
              }}
              variant="contained"
              onClick={() => setActive("Kip")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 10, md: 14 } }}
              >
                Kip Laag
              </Typography>
            </Button>
            <Button
              style={{
                backgroundColor:
                  active === "Spreid" ? activeColor : PgColors.bluepg[200],
              }}
              variant="contained"
              onClick={() => setActive("Spreid")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 8, md: 14 } }}
              >
                Spreidhandstand
              </Typography>
            </Button>
            <Button
              style={{
                backgroundColor:
                  active === "Losom" ? activeColor : PgColors.bluepg[200],
              }}
              variant="contained"
              onClick={() => setActive("Losom")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 10, md: 14 } }}
              >
                Losom
              </Typography>
            </Button>
            <Button
              style={{
                backgroundColor:
                  active === "Zolen" ? activeColor : PgColors.bluepg[200],
              }}
              variant="contained"
              onClick={() => setActive("Zolen")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 10, md: 14 } }}
              >
                Zolenhandstand
              </Typography>
            </Button>
            <Button
              style={{
                backgroundColor:
                  active === "Reusao" ? activeColor : PgColors.bluepg[200],
              }}
              variant="contained"
              onClick={() => setActive("Reusao")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 10, md: 14 } }}
              >
                Reus ao
              </Typography>
            </Button>
            <Button
              style={{
                backgroundColor:
                  active === "Overvliegen" ? activeColor : PgColors.bluepg[200],
              }}
              variant="contained"
              onClick={() => setActive("Overvliegen")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 8, md: 14 } }}
              >
                Overvliegen
              </Typography>
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid>

      <Grid container mt={2} sx={{ display: { xs: "flex", md: "none" } }}>
        <Grid item xs={12} display="flex" justifyContent="center">
          <ButtonGroup variant="contained">
            <Button
              style={{
                backgroundColor:
                  active === "Wippertje" ? activeColor : PgColors.bluepg[200],
              }}
              variant="contained"
              onClick={() => setActive("Wippertje")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 10, md: 14 } }}
              >
                Wippertje
              </Typography>
            </Button>
            <Button
              style={{
                backgroundColor:
                  active === "Kip" ? activeColor : PgColors.bluepg[200],
              }}
              variant="contained"
              onClick={() => setActive("Kip")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 10, md: 14 } }}
              >
                Kip Laag
              </Typography>
            </Button>
            <Button
              style={{
                backgroundColor:
                  active === "Spreid" ? activeColor : PgColors.bluepg[200],
              }}
              variant="contained"
              onClick={() => setActive("Spreid")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 8, md: 14 } }}
              >
                Spreidhandstand
              </Typography>
            </Button>
            <Button
              style={{
                backgroundColor:
                  active === "Losom" ? activeColor : PgColors.bluepg[200],
              }}
              variant="contained"
              onClick={() => setActive("Losom")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 10, md: 14 } }}
              >
                Losom
              </Typography>
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid>

      <Grid container mt={1} sx={{ display: { xs: "flex", md: "none" } }}>
        <Grid item xs={12} display="flex" justifyContent="center">
          <ButtonGroup variant="contained">
            <Button
              style={{
                backgroundColor:
                  active === "Zolen" ? activeColor : PgColors.bluepg[200],
              }}
              variant="contained"
              onClick={() => setActive("Zolen")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 10, md: 14 } }}
              >
                Zolenhandstand
              </Typography>
            </Button>

            <Button
              style={{
                backgroundColor:
                  active === "Reusao" ? activeColor : PgColors.bluepg[200],
              }}
              variant="contained"
              onClick={() => setActive("Reusao")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 10, md: 14 } }}
              >
                Reus ao
              </Typography>
            </Button>
            <Button
              style={{
                backgroundColor:
                  active === "Overvliegen" ? activeColor : PgColors.bluepg[200],
              }}
              variant="contained"
              onClick={() => setActive("Overvliegen")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 8, md: 14 } }}
              >
                Overvliegen
              </Typography>
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid>
      <Grid container>{active === "Wippertje" && <Wippertje />}</Grid>
      <Grid container>{active === "Kip" && <KipLaag />}</Grid>
      <Grid container>{active === "Spreid" && <Spreidhandstand />}</Grid>
      <Grid container>{active === "Losom" && <Losom />}</Grid>
      <Grid container>{active === "Zolen" && <Zolenhandstand />}</Grid>
      <Grid container>{active === "Reusao" && <ReusAo />}</Grid>
      <Grid container>{active === "Overvliegen" && <Overvliegen />}</Grid>
    </>
  );
};
