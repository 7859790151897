import React, { useState, useEffect } from "react";
import {
  collection,
  query,
  onSnapshot,
  updateDoc,
  doc,
  deleteDoc,
  setDoc,
} from "firebase/firestore";
import {
  Grid,
  Container,
  Typography,
  Paper,
  Button,
  Checkbox,
} from "@mui/material";
import { useAuthContext } from "../../../../hooks/useAuthContext";
import { useParams } from "react-router-dom";
import { db } from "../../../../firebase/config";
import { PgColors } from "../../../../configs/colors";
import CheckIcon from "@mui/icons-material/Check";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";

type CheckboxState = {
  [key: string]: boolean;
};

export const KasamatsuVault = () => {
  const { user } = useAuthContext();
  const [checkboxes, setCheckboxes] = useState<CheckboxState>({});

  const updateCheckboxState = async (checkboxId: any, isChecked: boolean) => {
    await setDoc(
      doc(db, `Skills/Vault/Kasa/${user.uid}/checkboxStates/${checkboxId}`),
      {
        isChecked,
      }
    );
  };

  useEffect(() => {
    const ref = query(
      collection(db, `Skills/Vault/Kasa/${user.uid}/checkboxStates`)
    );

    const unsub = onSnapshot(ref, (snapshot) => {
      const checkboxStates: any = [];
      snapshot.docs.forEach((doc) => {
        checkboxStates[doc.id] = doc.data().isChecked;
      });
      setCheckboxes(checkboxStates);
    });
    return () => unsub();
  }, []);

  return (
    <>
      <Grid container mt={2}>
        <Grid item xs={9} md={6}>
          <Typography
            variant="subtitle1"
            style={{ color: PgColors.redpg[500] }}
            sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
            align="left"
          >
            Kasamatsu Drills
          </Typography>
        </Grid>
        <Grid item xs={1} md={1} display="flex" justifyContent="center">
          <CheckIcon
            sx={{ color: PgColors.redpg[500], fontSize: { xs: 16, md: 20 } }}
          />
        </Grid>
        <Grid item xs={1} md={1} display="flex" justifyContent="center">
          <ThumbUpIcon
            sx={{ color: PgColors.redpg[500], fontSize: { xs: 16, md: 20 } }}
          />
        </Grid>
        <Grid item xs={1} md={1} display="flex" justifyContent="center">
          <EmojiEventsIcon
            sx={{ color: PgColors.redpg[500], fontSize: { xs: 16, md: 20 } }}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={9} md={6}>
          <Paper
            style={{ backgroundColor: PgColors.redpg[50] }}
            sx={{ height: { xs: 65, md: 60 } }}
          >
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 14, lg: 18 } }}
              align="center"
            >
              Radslag tot kwart met 1 arm gebogen - tot zw stand | met 1/4
              afmaken | met 3/4 draai afmaken
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId1"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId1"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId1"]: newCheckedState,
              });
              updateCheckboxState("checkboxId1", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId2"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId2"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId2"]: newCheckedState,
              });
              updateCheckboxState("checkboxId2", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId3"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId3"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId3"]: newCheckedState,
              });
              updateCheckboxState("checkboxId3", newCheckedState);
            }}
          />
        </Grid>
      </Grid>
      <Grid container sx={{ mt: { xs: 1, md: 0.3 } }}>
        <Grid item xs={9} md={6}>
          <Paper
            style={{ backgroundColor: PgColors.redpg[50] }}
            sx={{ height: { xs: 45, md: 60 } }}
          >
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 14, lg: 18 } }}
              align="center"
            >
              Radslag vanaf verhoging (1 hand op vloer, 1 hand op de grond) tot
              zw en schouder optillen
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId4"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId4"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId4"]: newCheckedState,
              });
              updateCheckboxState("checkboxId4", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId5"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId5"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId5"]: newCheckedState,
              });
              updateCheckboxState("checkboxId5", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId6"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId6"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId6"]: newCheckedState,
              });
              updateCheckboxState("checkboxId6", newCheckedState);
            }}
          />
        </Grid>
      </Grid>
      <Grid container sx={{ mt: { xs: 1, md: 0.3 } }}>
        <Grid item xs={9} md={6}>
          <Paper
            style={{ backgroundColor: PgColors.redpg[50] }}
            sx={{ height: { xs: 45, md: 60 } }}
          >
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 14, lg: 18 } }}
              align="center"
            >
              Zw radslag met handen op schuine airtrack tot op 30cm - Tot zw
              stand | tot zw stand met doorrol
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId7"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId7"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId7"]: newCheckedState,
              });
              updateCheckboxState("checkboxId7", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId8"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId8"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId8"]: newCheckedState,
              });
              updateCheckboxState("checkboxId8", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId9"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId9"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId9"]: newCheckedState,
              });
              updateCheckboxState("checkboxId9", newCheckedState);
            }}
          />
        </Grid>
      </Grid>
      <Grid container sx={{ mt: { xs: 1, md: 0.3 } }}>
        <Grid item xs={9} md={6}>
          <Paper
            style={{ backgroundColor: PgColors.redpg[100] }}
            sx={{ height: { xs: 65, md: 60 } }}
          >
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 14, lg: 18 } }}
              align="center"
            >
              Zijwaartse radslag naar verhoging - tot stand | tot kniezit op
              hogere verh | tot stand op hogere verh
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId40"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId40"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId40"]: newCheckedState,
              });
              updateCheckboxState("checkboxId40", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId41"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId41"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId41"]: newCheckedState,
              });
              updateCheckboxState("checkboxId41", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId42"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId42"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId42"]: newCheckedState,
              });
              updateCheckboxState("checkboxId42", newCheckedState);
            }}
          />
        </Grid>
      </Grid>
      <Grid container sx={{ mt: { xs: 1, md: 0.3 } }}>
        <Grid item xs={9} md={6}>
          <Paper
            style={{ backgroundColor: PgColors.redpg[100] }}
            sx={{ height: { xs: 65, md: 60 } }}
          >
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 14, lg: 18 } }}
              align="center"
            >
              Zijwaartse radslag tot op verhoging - doorrollen naar rol vo (wel
              eerst zw inzetten)
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId43"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId43"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId43"]: newCheckedState,
              });
              updateCheckboxState("checkboxId43", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId44"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId44"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId44"]: newCheckedState,
              });
              updateCheckboxState("checkboxId44", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId45"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId45"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId45"]: newCheckedState,
              });
              updateCheckboxState("checkboxId45", newCheckedState);
            }}
          />
        </Grid>
      </Grid>
      <Grid container sx={{ mt: { xs: 1, md: 0.3 } }}>
        <Grid item xs={9} md={6}>
          <Paper
            style={{ backgroundColor: PgColors.redpg[200] }}
            sx={{ height: { xs: 65, md: 60 } }}
          >
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 14, lg: 18 } }}
              align="center"
            >
              Radslag schuine airtrack naar kuil met hulp - zw salto | zw salto
              met 1/4 draai | zw salto met 3/4 draai
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId46"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId46"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId46"]: newCheckedState,
              });
              updateCheckboxState("checkboxId46", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId47"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId47"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId47"]: newCheckedState,
              });
              updateCheckboxState("checkboxId47", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId48"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId48"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId48"]: newCheckedState,
              });
              updateCheckboxState("checkboxId48", newCheckedState);
            }}
          />
        </Grid>
      </Grid>
      <Grid container sx={{ mt: { xs: 1, md: 0.3 } }}>
        <Grid item xs={9} md={6}>
          <Paper
            style={{ backgroundColor: PgColors.redpg[400] }}
            sx={{ height: { xs: 65, md: 60 } }}
          >
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 14, lg: 18 } }}
              align="center"
            >
              Radslag schuine airtrack naar kuil - zw salto | zw salto met 1/4
              draai | zw salto met 3/4 draai
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId51"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId51"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId51"]: newCheckedState,
              });
              updateCheckboxState("checkboxId51", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId52"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId52"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId52"]: newCheckedState,
              });
              updateCheckboxState("checkboxId52", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId53"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId53"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId53"]: newCheckedState,
              });
              updateCheckboxState("checkboxId53", newCheckedState);
            }}
          />
        </Grid>
      </Grid>
      <Grid container sx={{ mt: { xs: 1, md: 0.3 }, mb: { xs: 2, md: 0 } }}>
        <Grid item xs={9} md={6}>
          <Paper
            style={{ backgroundColor: PgColors.redpg[400] }}
            sx={{ height: { xs: 65, md: 60 } }}
          >
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 14, lg: 18 } }}
              align="center"
            >
              Kasamatu pegasus naar kuil - zw salto | zw salto met 1/4 | zw
              salto met 3/4
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId60"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId60"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId60"]: newCheckedState,
              });
              updateCheckboxState("checkboxId60", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId61"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId61"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId61"]: newCheckedState,
              });
              updateCheckboxState("checkboxId61", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId62"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId62"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId62"]: newCheckedState,
              });
              updateCheckboxState("checkboxId62", newCheckedState);
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};
