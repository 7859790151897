import React, { useState, useEffect } from "react";
import { collection, query, onSnapshot, orderBy } from "firebase/firestore";
import {
  Grid,
  Container,
  Typography,
  Paper,
  Button,
  Box,
  CardMedia,
} from "@mui/material";
import { useAuthContext } from "../../../../hooks/useAuthContext";
import { useParams } from "react-router-dom";
import { db } from "../../../../firebase/config";
import { PgColors } from "../../../../configs/colors";
import {
  AddExercise4A,
  AddExercise4B,
  AddExercise4C,
  AddExercise4D,
  AddExercise4E,
  AddExercise4F,
  AddExercise4G,
  AddExercise4H,
} from "./AddExercise4";
import {
  ListExercise4A,
  ListExercise4B,
  ListExercise4C,
  ListExercise4D,
  ListExercise4E,
  ListExercise4F,
  ListExercise4G,
  ListExercise4H,
} from "./ListExercise4";

export const MindsetPage = () => {
  const { authIsReady, user } = useAuthContext();
  const [exercise4AList, setExercise4AList] = useState([]);
  const [exercise4BList, setExercise4BList] = useState([]);
  const [exercise4CList, setExercise4CList] = useState([]);
  const [exercise4DList, setExercise4DList] = useState([]);
  const [exercise4EList, setExercise4EList] = useState([]);
  const [exercise4FList, setExercise4FList] = useState([]);
  const [exercise4GList, setExercise4GList] = useState([]);
  const [exercise4HList, setExercise4HList] = useState([]);

  const { id } = useParams();

  useEffect(() => {
    const ref = query(
      collection(db, `IntroExercises/${user.uid}/UserExercise4A`),
      orderBy("createdAt", "desc")
    );

    const unsub = onSnapshot(ref, (snapshot) => {
      let results: any = [];
      snapshot.docs.forEach((doc) => {
        results.push({ ...doc.data(), id: doc.id });
      });
      setExercise4AList(results);
    });
    return () => unsub();
  }, []);

  useEffect(() => {
    const ref = query(
      collection(db, `IntroExercises/${user.uid}/UserExercise4B`),
      orderBy("createdAt", "desc")
    );

    const unsub = onSnapshot(ref, (snapshot) => {
      let results: any = [];
      snapshot.docs.forEach((doc) => {
        results.push({ ...doc.data(), id: doc.id });
      });
      setExercise4BList(results);
    });
    return () => unsub();
  }, []);

  useEffect(() => {
    const ref = query(
      collection(db, `IntroExercises/${user.uid}/UserExercise4C`),
      orderBy("createdAt", "desc")
    );

    const unsub = onSnapshot(ref, (snapshot) => {
      let results: any = [];
      snapshot.docs.forEach((doc) => {
        results.push({ ...doc.data(), id: doc.id });
      });
      setExercise4CList(results);
    });
    return () => unsub();
  }, []);

  useEffect(() => {
    const ref = query(
      collection(db, `IntroExercises/${user.uid}/UserExercise4D`),
      orderBy("createdAt", "desc")
    );

    const unsub = onSnapshot(ref, (snapshot) => {
      let results: any = [];
      snapshot.docs.forEach((doc) => {
        results.push({ ...doc.data(), id: doc.id });
      });
      setExercise4DList(results);
    });
    return () => unsub();
  }, []);

  useEffect(() => {
    const ref = query(
      collection(db, `IntroExercises/${user.uid}/UserExercise4E`),
      orderBy("createdAt", "desc")
    );

    const unsub = onSnapshot(ref, (snapshot) => {
      let results: any = [];
      snapshot.docs.forEach((doc) => {
        results.push({ ...doc.data(), id: doc.id });
      });
      setExercise4EList(results);
    });
    return () => unsub();
  }, []);

  useEffect(() => {
    const ref = query(
      collection(db, `IntroExercises/${user.uid}/UserExercise4F`),
      orderBy("createdAt", "desc")
    );

    const unsub = onSnapshot(ref, (snapshot) => {
      let results: any = [];
      snapshot.docs.forEach((doc) => {
        results.push({ ...doc.data(), id: doc.id });
      });
      setExercise4FList(results);
    });
    return () => unsub();
  }, []);

  useEffect(() => {
    const ref = query(
      collection(db, `IntroExercises/${user.uid}/UserExercise4G`),
      orderBy("createdAt", "desc")
    );

    const unsub = onSnapshot(ref, (snapshot) => {
      let results: any = [];
      snapshot.docs.forEach((doc) => {
        results.push({ ...doc.data(), id: doc.id });
      });
      setExercise4GList(results);
    });
    return () => unsub();
  }, []);

  useEffect(() => {
    const ref = query(
      collection(db, `IntroExercises/${user.uid}/UserExercise4H`),
      orderBy("createdAt", "desc")
    );

    const unsub = onSnapshot(ref, (snapshot) => {
      let results: any = [];
      snapshot.docs.forEach((doc) => {
        results.push({ ...doc.data(), id: doc.id });
      });
      setExercise4HList(results);
    });
    return () => unsub();
  }, []);

  return (
    <>
      <Grid container mt={2}>
        <Grid item xs={12}>
          <Paper
            style={{ backgroundColor: PgColors.redpg[400] }}
            sx={{ height: { xs: 45, md: 110 } }}
          >
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, sm: 14, md: 16, lg: 20 } }}
              align="center"
            >
              "You have to remember that the hard days are what make you
              stronger.
            </Typography>
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, sm: 14, md: 16, lg: 20 } }}
              align="center"
            >
              The bad days make you realize what a good day is.
            </Typography>
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, sm: 14, md: 16, lg: 20 } }}
              align="center"
            >
              If you never had any bad days, you would never have that sense of
              accomplishment!"
            </Typography>
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, sm: 14, md: 16, lg: 20 } }}
              align="center"
            >
              - Aly Raisman
            </Typography>
          </Paper>
        </Grid>
      </Grid>
      <Grid container mt={2}>
        <Grid item xs={12} mt={1}>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
            }}
            align="left"
          >
            Turn(st)ers zijn vaak niet zo goed in het stil staan en vieren van
            de goede momenten (in een training of in een wedstrijd). Maar als je
            alleen maar focust op de mindere prestaties en je geen aandacht
            geeft aan de goede prestaties, creëer je zelf een negatieve mindset.
            Wat juist weer voor een negatief effect heeft op je prestaties in de
            toekomst.
          </Typography>
        </Grid>
        <Grid item xs={12} mt={1}>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
            }}
            align="left"
          >
            Gelukkig kan je oefenen om gebruik te maken van een ideale mindset!
          </Typography>
        </Grid>
        <Grid item xs={12} mt={2}>
          <Typography
            variant="subtitle1"
            style={{ color: PgColors.bluepg[400] }}
            sx={{
              fontSize: { xs: 16, md: 20, lg: 24 },
              fontWeight: { md: 500 },
            }}
            align="left"
          >
            Opdracht 4
          </Typography>
        </Grid>
      </Grid>

      <Grid container mt={2}>
        <Grid item xs={12} mt={0}>
          <Typography
            variant="subtitle1"
            style={{ color: PgColors.bluepg[400] }}
            sx={{
              fontSize: { xs: 12, md: 16, lg: 20 },
              fontWeight: { md: 400 },
            }}
            align="left"
          >
            Stap 1
          </Typography>
        </Grid>
        <Grid item xs={12} mt={1}>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
            }}
            align="left"
          >
            Maak een lijst van je beste prestaties (in een wedstrijd of in een
            trainig):
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <AddExercise4A />
      </Grid>
      <Grid container mt={4} mb={2}>
        {exercise4AList && (
          <ListExercise4A
            exercises={exercise4AList}
            path={`IntroExercises/${id}/UserExercise4A/${id}`}
          />
        )}
      </Grid>
      <Grid container mt={2}>
        <Grid item xs={12} mt={1}>
          <Typography
            variant="subtitle1"
            style={{ color: PgColors.bluepg[400] }}
            sx={{
              fontSize: { xs: 12, md: 16, lg: 20 },
              fontWeight: { md: 400 },
            }}
            align="left"
          >
            Stap 2
          </Typography>
        </Grid>
        <Grid item xs={12} mt={1}>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
            }}
            align="left"
          >
            Kies één item van bovenstaande lijst en antwoord onderstaand vragen
            met dat item in gedachte.
          </Typography>
        </Grid>
      </Grid>
      <Grid container mt={2}>
        <AddExercise4B />
      </Grid>
      <Grid container mt={2} mb={2}>
        {exercise4BList && (
          <ListExercise4B
            exercises={exercise4BList}
            path={`IntroExercises/${id}/UserExercise4B/${id}`}
          />
        )}
      </Grid>
      <Grid container mt={2}>
        <AddExercise4C />
      </Grid>
      <Grid container mt={2} mb={2}>
        {exercise4CList && (
          <ListExercise4C
            exercises={exercise4CList}
            path={`IntroExercises/${id}/UserExercise4C/${id}`}
          />
        )}
      </Grid>
      <Grid container mt={2}>
        <AddExercise4D />
      </Grid>
      <Grid container mt={2} mb={2}>
        {exercise4DList && (
          <ListExercise4D
            exercises={exercise4DList}
            path={`IntroExercises/${id}/UserExercise4D/${id}`}
          />
        )}
      </Grid>
      <Grid container mt={2}>
        <AddExercise4E />
      </Grid>
      <Grid container mt={2} mb={2}>
        {exercise4EList && (
          <ListExercise4E
            exercises={exercise4EList}
            path={`IntroExercises/${id}/UserExercise4E/${id}`}
          />
        )}
      </Grid>
      <Grid container mt={2}>
        <Grid item xs={12} mt={1}>
          <Typography
            variant="subtitle1"
            style={{ color: PgColors.bluepg[400] }}
            sx={{
              fontSize: { xs: 12, md: 16, lg: 20 },
              fontWeight: { md: 400 },
            }}
            align="left"
          >
            Stap 3
          </Typography>
        </Grid>
        <Grid item xs={12} mt={1}>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
            }}
            align="left"
          >
            Kies één item van bovenstaande lijst in een wedstrijd setting en
            antwoord onderstaand vragen met dat item in gedachte. Het hoeft geen
            wedstrijd te zijn waar alles perfect ging, zolang er maar een moment
            was waar je trots was op je prestatie.
          </Typography>
        </Grid>
      </Grid>
      <Grid container mt={2}>
        <AddExercise4F />
      </Grid>
      <Grid container mt={2} mb={2}>
        {exercise4FList && (
          <ListExercise4F
            exercises={exercise4FList}
            path={`IntroExercises/${id}/UserExercise4F/${id}`}
          />
        )}
      </Grid>
      <Grid container mt={2}>
        <AddExercise4G />
      </Grid>
      <Grid container mt={2} mb={2}>
        {exercise4GList && (
          <ListExercise4G
            exercises={exercise4GList}
            path={`IntroExercises/${id}/UserExercise4G/${id}`}
          />
        )}
      </Grid>
      <Grid container mt={2}>
        <AddExercise4H />
      </Grid>
      <Grid container mt={2} mb={2}>
        {exercise4HList && (
          <ListExercise4H
            exercises={exercise4HList}
            path={`IntroExercises/${id}/UserExercise4H/${id}`}
          />
        )}
      </Grid>
    </>
  );
};
