import React, { useState, useEffect } from "react";
import { collection, query, onSnapshot, orderBy } from "firebase/firestore";
import {
  Grid,
  Container,
  Typography,
  Paper,
  Button,
  ButtonGroup,
} from "@mui/material";
import { useAuthContext } from "../../../../hooks/useAuthContext";
import { useParams } from "react-router-dom";
import { db } from "../../../../firebase/config";
import { PgColors } from "../../../../configs/colors";
import { ReusHalf } from "./ReusHalf";

export const HBarSkills = () => {
  const [active, setActive] = useState("Reus1/2");
  const activeColor = PgColors.bluepg[400];

  return (
    <>
      <Grid container mt={2} sx={{ display: { xs: "flex", md: "flex" } }}>
        <Grid item xs={12} display="flex" justifyContent="center">
          <ButtonGroup variant="contained">
            <Button
              style={{
                backgroundColor:
                  active === "Reus1/2" ? activeColor : PgColors.bluepg[200],
              }}
              variant="contained"
              onClick={() => setActive("Reus1/2")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 10, md: 14 } }}
              >
                Reus 1/2
              </Typography>
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid>
      <Grid container>{active === "Reus1/2" && <ReusHalf />}</Grid>

      {/* <Grid container>{active === "Wippertje" && <Wippertje />}</Grid>
      <Grid container>{active === "Kip" && <KipLaag />}</Grid>
      <Grid container>{active === "Spreid" && <Spreidhandstand />}</Grid>
      <Grid container>{active === "Zolen" && <Zolenhandstand />}</Grid>
      <Grid container>{active === "Losom" && <Losom />}</Grid>
      <Grid container>{active === "Reusao" && <ReusAo />}</Grid>
      <Grid container>{active === "Overvliegen" && <Overvliegen />}</Grid> */}
    </>
  );
};
