import React, { useState, useEffect } from "react";
import {
  collection,
  query,
  onSnapshot,
  updateDoc,
  getDoc,
  doc,
  deleteDoc,
} from "firebase/firestore";
import { db } from "../../firebase/config";
import {
  Grid,
  Container,
  Typography,
  Divider,
  Button,
  Box,
  Paper,
} from "@mui/material";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { SeasonType, TrainingType, AdditionalField } from "../../types/types";
import { NavLink, Link } from "react-router-dom";
import { PgColors } from "../../configs/colors";

interface IProps {
  season: SeasonType;
}

export const Season = ({ season }: IProps) => {
  return (
    <>
      <Container disableGutters={true}>
        <Box sx={{ mt: 12, width: "100%" }}>
          <Paper
            elevation={2}
            sx={{ width: { sx: 300, md: 1400 }, height: { md: 850 } }}
            style={{ overflow: "auto" }}
          >
            <Container>
              <Grid container>
                <Grid
                  item
                  display="flex"
                  xs={6}
                  md={4}
                  sx={{ marginTop: { xs: 2, md: 6 } }}
                >
                  <Typography
                    variant="h6"
                    style={{ color: PgColors.redpg[200], fontWeight: "bold" }}
                    sx={{ fontSize: { xs: 14, md: 20 } }}
                  >
                    Jaarplan
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={6} md={4}>
                  <Typography
                    variant="subtitle1"
                    style={{
                      color: PgColors.bluepg[200],
                      fontWeight: 450,
                    }}
                    sx={{ fontSize: { xs: 14, md: 20 } }}
                  >
                    {season.name}
                  </Typography>
                </Grid>
                <Grid item xs={6} md={4}>
                  <Typography
                    variant="subtitle1"
                    style={{
                      color: PgColors.bluepg[200],
                      fontWeight: 450,
                    }}
                    sx={{ fontSize: { xs: 14, md: 20 } }}
                  >
                    {season.group}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container mt={2}>
                {season.trainings &&
                  season.trainings.map(
                    (training: TrainingType, index: number) => (
                      <React.Fragment key={index}>
                        <Grid container mt={2}>
                          <Grid item xs={12} md={6}>
                            <Paper
                              style={{
                                backgroundColor: PgColors.bluepg[300],
                                height: 35,
                              }}
                            >
                              <Typography
                                variant="subtitle1"
                                style={{ color: "white", fontSize: 20 }}
                                align="center"
                              >
                                {training.date}
                              </Typography>
                            </Paper>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Paper
                              style={{
                                backgroundColor: PgColors.bluepg[300],
                                height: 35,
                              }}
                            >
                              <Typography
                                variant="subtitle1"
                                style={{ color: "white", fontSize: 20 }}
                                align="center"
                              >
                                {training.period}
                              </Typography>
                            </Paper>
                          </Grid>
                        </Grid>
                        {training.additionalFields &&
                          training.additionalFields.map(
                            (field: AdditionalField, fieldIndex: number) => (
                              <>
                                <Grid item xs={4} md={2} key={fieldIndex}>
                                  <Paper
                                    style={{
                                      backgroundColor: PgColors.bluepg[100],
                                    }}
                                    sx={{ height: { xs: 110, md: 150 } }}
                                  >
                                    <Grid container>
                                      <Typography
                                        variant="subtitle1"
                                        sx={{ fontSize: { xs: 10, md: 14 } }}
                                        style={{
                                          color: "white",

                                          fontWeight: "bold",
                                        }}
                                        align="center"
                                      >
                                        {field.title}:
                                      </Typography>
                                    </Grid>
                                    <Grid container>
                                      <Typography
                                        variant="subtitle1"
                                        sx={{ fontSize: { xs: 10, md: 14 } }}
                                        style={{
                                          color: "white",

                                          whiteSpace: "pre-line",
                                        }}
                                      >
                                        {field.content}
                                      </Typography>
                                    </Grid>
                                  </Paper>
                                </Grid>
                              </>
                            )
                          )}
                      </React.Fragment>
                    )
                  )}
              </Grid>
            </Container>
          </Paper>
        </Box>
      </Container>
    </>
  );
};
