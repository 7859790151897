import React, { useState, useEffect } from "react";
import {
  collection,
  query,
  onSnapshot,
  updateDoc,
  where,
  getDoc,
  doc,
  deleteDoc,
} from "firebase/firestore";
import { db } from "../../firebase/config";
import {
  Grid,
  Container,
  Typography,
  Divider,
  Button,
  ButtonGroup,
} from "@mui/material";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useNavigate } from "react-router-dom";
import { Fysiek } from "./Fysiek";
import { Sprong } from "./Sprong";
import { Brug } from "./Brug";
import { Balk } from "./Balk";
import { Vloer } from "./Vloer";
import { PgColors } from "../../configs/colors";
import { HerenBrug } from "./HerenBrug";
import { Rek } from "./Rek";
import { Ringen } from "./Ringen";
import { Voltige } from "./Voltige";

export const AllPrograms = () => {
  const { authIsReady, user } = useAuthContext();
  const [programsList, setProgramsList] = useState([]);
  const navigate = useNavigate();
  const [active, setActive] = useState("Sprong");
  const [gymnast, setGymnast] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const activeColor = PgColors.bluepg[200];

  useEffect(() => {
    const storedActiveComponent = localStorage.getItem("activeComponent");

    if (storedActiveComponent) {
      setActive(storedActiveComponent);
    }

    if (authIsReady && user) {
      const ref = query(collection(db, `ProgramsByCoach/${user.uid}/Programs`));

      const unsub = onSnapshot(ref, (snapshot) => {
        let results: any = [];
        snapshot.docs.forEach((doc) => {
          results.push({ ...doc.data(), id: doc.id });
        });
        setProgramsList(results);
      });
      return () => unsub();
    }
  }, [authIsReady, user]);

  const handleEvent = (
    params: any,
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    navigate(`ProgramsByCoach/${user.uid}/Programs/${params.id}`);
  };

  // useEffect(() => {
  //   const fetchGymnastData = async () => {
  //     setIsLoading(true);
  //     const docSnap = await getDoc(doc(db, `users/${user.uid}`));
  //     if (docSnap.exists()) {
  //       const newGymnastObj = {
  //         id: docSnap.id,
  //         ...docSnap.data(),
  //       };
  //       setGymnast(newGymnastObj);
  //       setIsLoading(false);
  //     } else {
  //       //doc.data() will be undefined in this case
  //       console.log("No such document");
  //     }
  //   };
  //   fetchGymnastData();
  //   //eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    const fetchGymnastData = async () => {
      setIsLoading(true);

      if (authIsReady && user) {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        // console.log("user:", user);
        try {
          const docSnap = await getDoc(doc(db, `users/${user.uid}`));
          console.log("user after getDoc:", user.uid);
          if (docSnap.exists()) {
            const newGymnastObj = {
              id: docSnap.id,
              ...docSnap.data(),
            };
            setGymnast(newGymnastObj);
            setIsLoading(false);
          } else {
            //doc.data() will be undefined in this case
            console.log("No such document");
          }
        } catch (error) {
          console.error("Error fetching user document:", error);
        }
      }
    };

    fetchGymnastData();
  }, [authIsReady, user]);

  const handleComponentClick = (componentName: string) => {
    setActive(componentName);
    localStorage.setItem("activeComponent", componentName);
  };

  if (!authIsReady) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <Container>
        <Grid container marginTop={6}>
          <Grid
            item
            marginTop={2}
            justifyContent="center"
            sx={{ display: { xs: "none", lg: "flex" } }}
          >
            <ButtonGroup variant="text">
              <Button onClick={() => handleComponentClick("Sprong")}>
                <Typography
                  variant="subtitle1"
                  style={{
                    color:
                      active === "Sprong" ? activeColor : PgColors.redpg[200],
                    fontSize: 20,
                    fontWeight: 500,
                  }}
                >
                  Sprong
                </Typography>
              </Button>
              <Button onClick={() => handleComponentClick("Brug")}>
                <Typography
                  variant="subtitle1"
                  style={{
                    color:
                      active === "Brug" ? activeColor : PgColors.redpg[200],
                    fontSize: 20,
                    fontWeight: 500,
                  }}
                >
                  Dames Brug
                </Typography>
              </Button>
              <Button onClick={() => handleComponentClick("Balk")}>
                <Typography
                  variant="subtitle1"
                  style={{
                    color:
                      active === "Balk" ? activeColor : PgColors.redpg[200],
                    fontSize: 20,
                    fontWeight: 500,
                  }}
                >
                  Balk
                </Typography>
              </Button>
              <Button onClick={() => handleComponentClick("Vloer")}>
                <Typography
                  variant="subtitle1"
                  style={{
                    color:
                      active === "Vloer" ? activeColor : PgColors.redpg[200],
                    fontSize: 20,
                    fontWeight: 500,
                  }}
                >
                  Vloer
                </Typography>
              </Button>
              <Button onClick={() => handleComponentClick("HBrug")}>
                <Typography
                  variant="subtitle1"
                  style={{
                    color:
                      active === "HBrug" ? activeColor : PgColors.redpg[200],
                    fontSize: 20,
                    fontWeight: 500,
                  }}
                >
                  Heren Brug
                </Typography>
              </Button>
              <Button onClick={() => handleComponentClick("Rek")}>
                <Typography
                  variant="subtitle1"
                  style={{
                    color: active === "Rek" ? activeColor : PgColors.redpg[200],
                    fontSize: 20,
                    fontWeight: 500,
                  }}
                >
                  Rek
                </Typography>
              </Button>
              <Button onClick={() => handleComponentClick("Ringen")}>
                <Typography
                  variant="subtitle1"
                  style={{
                    color:
                      active === "Ringen" ? activeColor : PgColors.redpg[200],
                    fontSize: 20,
                    fontWeight: 500,
                  }}
                >
                  Ringen
                </Typography>
              </Button>
              <Button onClick={() => handleComponentClick("Voltige")}>
                <Typography
                  variant="subtitle1"
                  style={{
                    color:
                      active === "Voltige" ? activeColor : PgColors.redpg[200],
                    fontSize: 20,
                    fontWeight: 500,
                  }}
                >
                  Voltige
                </Typography>
              </Button>
              <Button onClick={() => handleComponentClick("Fysiek")}>
                <Typography
                  variant="subtitle1"
                  style={{
                    color:
                      active === "Fysiek" ? activeColor : PgColors.redpg[200],
                    fontSize: 20,
                    fontWeight: 500,
                  }}
                >
                  Fysiek
                </Typography>
              </Button>
            </ButtonGroup>
          </Grid>
          <Grid
            item
            marginTop={2}
            justifyContent="center"
            sx={{ display: { xs: "flex", lg: "none" } }}
          >
            <ButtonGroup variant="text">
              <Button onClick={() => handleComponentClick("Sprong")}>
                <Typography
                  variant="subtitle1"
                  style={{
                    color:
                      active === "Sprong" ? activeColor : PgColors.redpg[200],

                    fontWeight: 500,
                  }}
                  sx={{
                    fontSize: { xs: 16, lg: 20 },
                  }}
                >
                  Sprong
                </Typography>
              </Button>
              <Button onClick={() => handleComponentClick("Brug")}>
                <Typography
                  variant="subtitle1"
                  style={{
                    color:
                      active === "Brug" ? activeColor : PgColors.redpg[200],

                    fontWeight: 500,
                  }}
                  sx={{
                    fontSize: { xs: 16, lg: 20 },
                  }}
                >
                  Dames Brug
                </Typography>
              </Button>
              <Button onClick={() => handleComponentClick("Balk")}>
                <Typography
                  variant="subtitle1"
                  style={{
                    color:
                      active === "Balk" ? activeColor : PgColors.redpg[200],

                    fontWeight: 500,
                  }}
                  sx={{
                    fontSize: { xs: 16, lg: 20 },
                  }}
                >
                  Balk
                </Typography>
              </Button>
              <Button onClick={() => handleComponentClick("Vloer")}>
                <Typography
                  variant="subtitle1"
                  style={{
                    color:
                      active === "Vloer" ? activeColor : PgColors.redpg[200],

                    fontWeight: 500,
                  }}
                  sx={{
                    fontSize: { xs: 16, lg: 20 },
                  }}
                >
                  Vloer
                </Typography>
              </Button>
            </ButtonGroup>
          </Grid>
          <Grid
            item
            marginTop={2}
            justifyContent="center"
            sx={{ display: { xs: "flex", lg: "none" } }}
          >
            <ButtonGroup variant="text">
              <Button onClick={() => handleComponentClick("HBrug")}>
                <Typography
                  variant="subtitle1"
                  style={{
                    color:
                      active === "HBrug" ? activeColor : PgColors.redpg[200],

                    fontWeight: 500,
                  }}
                  sx={{
                    fontSize: { xs: 16, lg: 20 },
                  }}
                >
                  Heren Brug
                </Typography>
              </Button>
              <Button onClick={() => handleComponentClick("Rek")}>
                <Typography
                  variant="subtitle1"
                  style={{
                    color: active === "Rek" ? activeColor : PgColors.redpg[200],

                    fontWeight: 500,
                  }}
                  sx={{
                    fontSize: { xs: 16, lg: 20 },
                  }}
                >
                  Rek
                </Typography>
              </Button>
              <Button onClick={() => handleComponentClick("Ringen")}>
                <Typography
                  variant="subtitle1"
                  style={{
                    color:
                      active === "Ringen" ? activeColor : PgColors.redpg[200],

                    fontWeight: 500,
                  }}
                  sx={{
                    fontSize: { xs: 16, lg: 20 },
                  }}
                >
                  Ringen
                </Typography>
              </Button>
              <Button onClick={() => handleComponentClick("Voltige")}>
                <Typography
                  variant="subtitle1"
                  style={{
                    color:
                      active === "Voltige" ? activeColor : PgColors.redpg[200],

                    fontWeight: 500,
                  }}
                  sx={{
                    fontSize: { xs: 16, lg: 20 },
                  }}
                >
                  Voltige
                </Typography>
              </Button>
              <Button onClick={() => handleComponentClick("Fysiek")}>
                <Typography
                  variant="subtitle1"
                  style={{
                    color:
                      active === "Fysiek" ? activeColor : PgColors.redpg[200],

                    fontWeight: 500,
                  }}
                  sx={{
                    fontSize: { xs: 16, lg: 20 },
                  }}
                >
                  Fysiek
                </Typography>
              </Button>
            </ButtonGroup>
          </Grid>
        </Grid>

        {active === "Fysiek" &&
          (gymnast ? (
            <Fysiek
              path={`ProgramsByCoach/${user.uid}/Programs`}
              gymnast={gymnast}
            />
          ) : (
            <p>Loading gymnast data...</p>
          ))}

        {active === "Sprong" && (
          <Sprong
            path={`ProgramsByCoach/${user.uid}/Programs`}
            gymnast={gymnast}
          />
        )}

        {active === "Brug" && (
          <Brug
            path={`ProgramsByCoach/${user.uid}/Programs`}
            gymnast={gymnast}
          />
        )}
        {active === "Balk" && (
          <Balk
            path={`ProgramsByCoach/${user.uid}/Programs`}
            gymnast={gymnast}
          />
        )}
        {active === "Vloer" && (
          <Vloer
            path={`ProgramsByCoach/${user.uid}/Programs`}
            gymnast={gymnast}
          />
        )}
        {active === "HBrug" && (
          <HerenBrug
            path={`ProgramsByCoach/${user.uid}/Programs`}
            gymnast={gymnast}
          />
        )}
        {active === "Rek" && (
          <Rek
            path={`ProgramsByCoach/${user.uid}/Programs`}
            gymnast={gymnast}
          />
        )}
        {active === "Ringen" && (
          <Ringen
            path={`ProgramsByCoach/${user.uid}/Programs`}
            gymnast={gymnast}
          />
        )}
        {active === "Voltige" && (
          <Voltige
            path={`ProgramsByCoach/${user.uid}/Programs`}
            gymnast={gymnast}
          />
        )}
      </Container>
    </>
  );
};
