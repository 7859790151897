import React, { useState, useEffect, FC } from "react";
import {
  Grid,
  Container,
  Typography,
  Card,
  CardMedia,
  CardContent,
  Box,
} from "@mui/material";
import { PgColors } from "../../../configs/colors";

type TextProps = {
  text: string;
};






export const Title: FC<TextProps> = ({ text }) => {
  return (
    <Typography
      variant="subtitle1"
      style={{ color: PgColors.bluepg[300] }}
      sx={{
        fontWeight: { xs: 500, md: 500 },
        fontSize: { xs: 12, md: 20 },
      }}
      align="left"
    >
      {text}
    </Typography>
  );
};

export const Groep2 = () => {
  return (
    <>
      <Grid container mt={2} sx={{ marginLeft: 0 }}>
        <Grid item>
          <Title text={"2.000 – GYMNASTISCHE SPRONGEN"} />
        </Grid>
      </Grid>
      <Grid
        container
        // sx={{ marginBottom: { xs: -164, sm: -265, md: -420 } }}
      >
        <Grid
          item
          mt={0}
          xs={12}
          md={12}
          display="flex"
          justifyContent="center"
        >
          <Box sx={{ display: "flex" }}>
            <CardMedia
              sx={{
                maxWidth: { xs: 340, sm: 600, md: 900, lg: 1200 },
              }}
              component="img"
              image="../images/beamEl/BeamGroup2B.png"
              alt="symbol2"
            ></CardMedia>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
