import React, { useState, useEffect } from "react";
import { collection, query, onSnapshot, orderBy } from "firebase/firestore";
import { Grid, Container, Typography, Paper, Button } from "@mui/material";
import { useAuthContext } from "../../../../hooks/useAuthContext";
import { useParams } from "react-router-dom";
import { db } from "../../../../firebase/config";
import { PgColors } from "../../../../configs/colors";
import {
  AddExercise1A,
  AddExercise1B,
  AddExercise1C,
  AddExercise1D,
  AddExercise1E,
  AddExercise1F,
} from "./AddExercise1";
import {
  ListExercise1A,
  ListExercise1B,
  ListExercise1C,
  ListExercise1D,
  ListExercise1E,
  ListExercise1F,
} from "./ListExercise1";
// import {
//   AddExercise1A,
//   AddExercise1B,
//   AddExercise1C,
//   AddExercise1D,
// } from "./AddExercise1";
// import {
//   ListExercise1A,
//   ListExercise1B,
//   ListExercise1C,
//   ListExercise1D,
// } from "./ListExercise1";

export const IntroPage = () => {
  const { authIsReady, user } = useAuthContext();

  const [exercise1AList, setExercise1AList] = useState([]);
  const [exercise1BList, setExercise1BList] = useState([]);
  const [exercise1CList, setExercise1CList] = useState([]);
  const [exercise1DList, setExercise1DList] = useState([]);
  const [exercise1EList, setExercise1EList] = useState([]);
  const [exercise1FList, setExercise1FList] = useState([]);

  const { id } = useParams();

  useEffect(() => {
    const ref = query(
      collection(db, `IntroExercises/${id}/UserExercise1A`),
      orderBy("createdAt", "desc")
    );

    const unsub = onSnapshot(ref, (snapshot) => {
      let results: any = [];
      snapshot.docs.forEach((doc) => {
        results.push({ ...doc.data(), id: doc.id });
      });
      setExercise1AList(results);
    });
    return () => unsub();
  }, []);

  useEffect(() => {
    const ref = query(
      collection(db, `IntroExercises/${id}/UserExercise1B`),
      orderBy("createdAt", "desc")
    );

    const unsub = onSnapshot(ref, (snapshot) => {
      let results: any = [];
      snapshot.docs.forEach((doc) => {
        results.push({ ...doc.data(), id: doc.id });
      });
      setExercise1BList(results);
    });
    return () => unsub();
  }, []);

  useEffect(() => {
    const ref = query(
      collection(db, `IntroExercises/${id}/UserExercise1C`),
      orderBy("createdAt", "desc")
    );

    const unsub = onSnapshot(ref, (snapshot) => {
      let results: any = [];
      snapshot.docs.forEach((doc) => {
        results.push({ ...doc.data(), id: doc.id });
      });
      setExercise1CList(results);
    });
    return () => unsub();
  }, []);

  useEffect(() => {
    const ref = query(
      collection(db, `IntroExercises/${id}/UserExercise1D`),
      orderBy("createdAt", "desc")
    );

    const unsub = onSnapshot(ref, (snapshot) => {
      let results: any = [];
      snapshot.docs.forEach((doc) => {
        results.push({ ...doc.data(), id: doc.id });
      });
      setExercise1DList(results);
    });
    return () => unsub();
  }, []);

  useEffect(() => {
    const ref = query(
      collection(db, `IntroExercises/${id}/UserExercise1E`),
      orderBy("createdAt", "desc")
    );

    const unsub = onSnapshot(ref, (snapshot) => {
      let results: any = [];
      snapshot.docs.forEach((doc) => {
        results.push({ ...doc.data(), id: doc.id });
      });
      setExercise1EList(results);
    });
    return () => unsub();
  }, []);

  useEffect(() => {
    const ref = query(
      collection(db, `IntroExercises/${id}/UserExercise1F`),
      orderBy("createdAt", "desc")
    );

    const unsub = onSnapshot(ref, (snapshot) => {
      let results: any = [];
      snapshot.docs.forEach((doc) => {
        results.push({ ...doc.data(), id: doc.id });
      });
      setExercise1FList(results);
    });
    return () => unsub();
  }, []);

  return (
    <>
      <Grid container mt={2}>
        <Grid item xs={12}>
          <Paper
            style={{ backgroundColor: PgColors.redpg[400] }}
            sx={{ height: { xs: 45, md: 35 } }}
          >
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, sm: 16, md: 16, lg: 20 } }}
              align="center"
            >
              "A trophy carries dust. Memories last forever." - Mary Lou retton
            </Typography>
          </Paper>
        </Grid>
      </Grid>
      <Grid container mt={2}>
        <Grid item xs={12}>
          <Typography
            variant="subtitle1"
            style={{ color: PgColors.bluepg[400] }}
            sx={{
              fontSize: { xs: 16, md: 20, lg: 24 },
              fontWeight: { md: 500 },
            }}
            align="left"
          >
            Opdracht 1
          </Typography>
        </Grid>
        <Grid item xs={12} mt={1}>
          <Typography
            variant="subtitle1"
            style={{ color: PgColors.bluepg[400] }}
            sx={{
              fontSize: { xs: 12, md: 16, lg: 20 },
              fontWeight: { md: 400 },
            }}
            align="left"
          >
            Stap 1
          </Typography>
        </Grid>
        <Grid item xs={12} mt={1}>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
            }}
            align="left"
          >
            Schrijf alle dingen op die de allerbeste turn(st)er zou doen volgens
            jou. Denk dan niet aan de 'skills', maar bijvoorbeeld aan dingen
            zoals 'niet opgeven', 'niet te lang stil staan bij fouten' of 'veel
            aandacht besteden aan kracht en bijoefeningen'
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <AddExercise1A />
      </Grid>
      <Grid container mt={4} mb={2}>
        {exercise1AList && (
          <ListExercise1A
            exercises={exercise1AList}
            path={`IntroExercises/${id}/UserExercise1A/${id}`}
          />
        )}
      </Grid>
      <Grid container mt={2}>
        <Grid item xs={12}>
          <Typography
            variant="subtitle1"
            style={{ color: PgColors.bluepg[400] }}
            sx={{
              fontSize: { xs: 12, md: 16, lg: 20 },
              fontWeight: { md: 400 },
            }}
            align="left"
          >
            Stap 2
          </Typography>
        </Grid>
        <Grid item xs={12} mt={1}>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
            }}
            align="left"
          >
            Wat zijn dingen van bovenstaande opdracht die je zelf kan doen?
            Schrijf deze op:
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <AddExercise1B />
      </Grid>
      <Grid container mt={4} mb={2}>
        {exercise1BList && (
          <ListExercise1B
            exercises={exercise1BList}
            path={`IntroExercises/${id}/UserExercise1B/${id}`}
          />
        )}
      </Grid>
      <Grid container>
        <AddExercise1E />
      </Grid>
      <Grid container mt={4} mb={2}>
        {exercise1EList && (
          <ListExercise1E
            exercises={exercise1EList}
            path={`IntroExercises/${id}/UserExercise1E/${id}`}
          />
        )}
      </Grid>
      <Grid container mt={2}>
        <Grid item xs={12}>
          <Typography
            variant="subtitle1"
            style={{ color: PgColors.bluepg[400] }}
            sx={{
              fontSize: { xs: 12, md: 16, lg: 20 },
              fontWeight: { md: 400 },
            }}
            align="left"
          >
            Stap 3
          </Typography>
        </Grid>
        <Grid item xs={12} mt={1}>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
            }}
            align="left"
          >
            Schijf alle dingen op die de aller slechtste turn(st)er zou doen:
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <AddExercise1C />
      </Grid>
      <Grid container mt={4} mb={2}>
        {exercise1CList && (
          <ListExercise1C
            exercises={exercise1CList}
            path={`IntroExercises/${id}/UserExercise1C/${id}`}
          />
        )}
      </Grid>
      <Grid container mt={2}>
        <Grid item xs={12}>
          <Typography
            variant="subtitle1"
            style={{ color: PgColors.bluepg[400] }}
            sx={{
              fontSize: { xs: 12, md: 16, lg: 20 },
              fontWeight: { md: 400 },
            }}
            align="left"
          >
            Stap 4
          </Typography>
        </Grid>
        <Grid item xs={12} mt={1}>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
            }}
            align="left"
          >
            Wat zijn dingen van bovenstaand opdracht die je misschien zelf wel
            doet (en waar je mogelijk mee wilt stoppen). Schrijf deze op:
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <AddExercise1D />
      </Grid>
      <Grid container mt={4} mb={2}>
        {exercise1DList && (
          <ListExercise1D
            exercises={exercise1DList}
            path={`IntroExercises/${id}/UserExercise1D/${id}`}
          />
        )}
      </Grid>
      <Grid container>
        <AddExercise1F />
      </Grid>
      <Grid container mt={2} mb={2}>
        {exercise1FList && (
          <ListExercise1F
            exercises={exercise1FList}
            path={`IntroExercises/${id}/UserExercise1F/${id}`}
          />
        )}
      </Grid>
    </>
  );
};
