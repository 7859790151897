import React, { useState } from "react";
import {
  Grid,
  Container,
  Alert,
  CardContent,
  Typography,
  Paper,
  TextField,
  Button,
  Checkbox,
} from "@mui/material";
import { Header } from "../../components/landingPage/Header";
import { useSignup } from "../../hooks/useSignup";
import { getFunctions, httpsCallable } from "firebase/functions";
import { PgColors } from "../../configs/colors";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export const Signup = () => {
  const { t } = useTranslation();
  const [firstname, setFirstname] = useState<string>("");
  const [lastname, setLastname] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [role, setRole] = useState("Gymnast");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const { error, signup, isSignupSuccess } = useSignup();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    signup(email, password, firstname, role);
    setFirstname("");
    setLastname("");
    setEmail("");
    setRole("Gymnast");
    setPassword("");
    setConfirmPassword("");
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setRole("Coach");
    } else {
      setRole("Gymnast");
    }
  };

  return (
    <>
      <Header />
      <div
        style={{
          backgroundColor: PgColors.bluepg[100],
          height: "100vh",
        }}
      >
        <Container>
          <Grid container justifyContent="center">
            <Grid
              item
              md={10}
              sx={{ marginTop: { xs: 4, lg: 8, xl: 10 } }}
              marginBottom={20}
            >
              <Paper elevation={2}>
                {isSignupSuccess && (
                  <Alert severity="success" sx={{ margin: 2 }}>
                    Het maken van een account is gelukt!
                  </Alert>
                )}
                <form onSubmit={handleSubmit}>
                  <Container>
                    <Grid container>
                      <Grid item xs={12} marginTop={6}>
                        <Typography
                          variant="h3"
                          style={{
                            color: PgColors.redpg[200],
                            fontWeight: 500,
                          }}
                          textAlign="center"
                          sx={{ fontSize: { xs: 36, md: 40, lg: 50 } }}
                        >
                          {t("SignUp.Title")}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item sx={{ marginTop: { xs: 4, md: 4 } }}>
                        <Typography variant="body1">
                          {t("SignUp.Name")}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <TextField
                          label="Voornaam"
                          style={{
                            color: PgColors.bluepg[200],
                          }}
                          fullWidth
                          type="text"
                          variant="outlined"
                          onChange={(event) => setFirstname(event.target.value)}
                          value={firstname}
                        ></TextField>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          label="Achternaam"
                          style={{
                            color: PgColors.bluepg[200],
                          }}
                          fullWidth
                          type="text"
                          variant="outlined"
                          onChange={(event) => setLastname(event.target.value)}
                          value={lastname}
                        ></TextField>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item marginTop={4}>
                        <Typography variant="body1">Uw email adres:</Typography>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={12}>
                        <TextField
                          label="Email"
                          style={{
                            color: PgColors.bluepg[200],
                          }}
                          fullWidth
                          type="email"
                          variant="outlined"
                          onChange={(event) => setEmail(event.target.value)}
                          value={email}
                        ></TextField>
                      </Grid>
                    </Grid>

                    <Grid container>
                      <Grid item mt={2}>
                        <Checkbox
                          sx={{
                            "& .MuiSvgIcon-root": {
                              fontSize: 36,
                              color: PgColors.redpg[200],
                            },
                          }}
                          onChange={handleChange}
                          checked={role === "Coach"}
                        />
                      </Grid>
                      <Grid item marginTop={2} pt={2}>
                        <Typography variant="body1">
                          Ik ben een Coach
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid container>
                      <Grid item marginTop={2}>
                        <Typography variant="body1">Wachtwoord:</Typography>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={12}>
                        <TextField
                          label="Wachtwoord"
                          style={{
                            color: PgColors.bluepg[200],
                          }}
                          fullWidth
                          type="password"
                          variant="outlined"
                          onChange={(event) => setPassword(event.target.value)}
                          value={password}
                        ></TextField>
                      </Grid>
                    </Grid>

                    <Grid container>
                      <Grid item xs={12} marginTop={4}>
                        <Button
                          type="submit"
                          variant="contained"
                          size="large"
                          style={{
                            backgroundColor: PgColors.redpg[200],
                          }}
                        >
                          Maak een account
                        </Button>
                      </Grid>
                    </Grid>
                    <Grid container mb={20}>
                      <Grid item xs={12} mb={20}>
                        <Typography variant="body1">
                          Door op "maak een acccount" te klikken ga je akkoord
                          met de algemene voorwaarden.
                        </Typography>
                      </Grid>
                    </Grid>
                  </Container>
                </form>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
};
