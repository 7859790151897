import React, { useState, useEffect, FC } from "react";
import {
  Grid,
  Container,
  Typography,
  Divider,
  Button,
  Paper,
} from "@mui/material";
import { PgColors } from "../../../configs/colors";

type TextProps = {
  text: string;
};

export const ContentPaper: FC<TextProps> = ({ text }) => {
  return (
    <Typography
      variant="subtitle1"
      sx={{ color: PgColors.white, fontSize: { xs: 12, sm: 14, md: 16 } }}
      align="left"
    >
      {text}
    </Typography>
  );
};

export const TitlePaper: FC<TextProps> = ({ text }) => {
  return (
    <Typography
      variant="subtitle1"
      sx={{
        color: PgColors.redpg[500],
        fontWeight: 500,
        fontSize: { xs: 12, sm: 14, md: 16 },
      }}
      align="left"
    >
      {text}
    </Typography>
  );
};

export const Content: FC<TextProps> = ({ text }) => {
  return (
    <Typography
      variant="subtitle1"
      sx={{ color: PgColors.redpg[500], fontSize: { xs: 12, sm: 14, md: 16 } }}
      align="left"
    >
      {text}
    </Typography>
  );
};

export const SupDSE = () => {
  return (
    <>
      <Grid container mt={2} marginLeft={2}>
        <Grid item>
          <Typography variant="h6" style={{ color: PgColors.redpg[500] }}>
            Samenstellingseisen
          </Typography>
        </Grid>
      </Grid>

      <Grid container display="flex" justifyContent="center">
        <Grid item xs={12}>
          <Paper
            elevation={2}
            style={{ backgroundColor: PgColors.bluepg[100], width: "100%" }}
          >
            <Container>
              <Grid container>
                <Grid item xs={12}>
                  <TitlePaper text={"Sprong - Meerkamp"} />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={1}>
                  <ContentPaper text={"1."} />
                </Grid>
                <Grid item xs={11}>
                  <ContentPaper text={"Er moet 1 sprong uitgevoerd worden."} />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12}>
                  <TitlePaper
                    text={
                      "Sprong - Toestelfinale en Kwalificatie toestelfinale"
                    }
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={1}>
                  <ContentPaper text={"1."} />
                </Grid>
                <Grid item xs={11}>
                  <ContentPaper
                    text={
                      "Twee verschillende sprongen. Mogen wel uit dezelfde spronggroep komen."
                    }
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={1}>
                  <ContentPaper text={"2."} />
                </Grid>
                <Grid item xs={11}>
                  <ContentPaper
                    text={
                      "Het eindcijfer is het gemiddelde van de 2 sprogen. 1e sprong geldt voor meerkamp."
                    }
                  />
                </Grid>
              </Grid>
              <Grid container mt={2}>
                <Grid item xs={12}>
                  <TitlePaper text={"Brug"} />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={1}>
                  <ContentPaper text={"1."} />
                </Grid>
                <Grid item xs={10}>
                  <ContentPaper text={"2 verschillende kippen"} />
                </Grid>
                <Grid item xs={1}>
                  <ContentPaper text={"0.50"} />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={1}>
                  <ContentPaper text={"2."} />
                </Grid>
                <Grid item xs={10} sx={{ paddingRight: { xs: 1 } }}>
                  <ContentPaper text={"Een gesloten opzwaai min 90º"} />
                </Grid>
                <Grid item xs={1}>
                  <ContentPaper text={"0.50"} />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={1}>
                  <ContentPaper text={"3."} />
                </Grid>
                <Grid item xs={10}>
                  <ContentPaper
                    text={
                      "2 versch elementen of één element min B uit groep 2, 3 , 4 of 5 (geen vlucht)"
                    }
                  />
                </Grid>
                <Grid item xs={1}>
                  <ContentPaper text={"0.50"} />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={1}>
                  <ContentPaper text={"4."} />
                </Grid>
                <Grid item xs={10}>
                  <ContentPaper
                    text={
                      "Element met lengteasdraai van min 180º in oefening of opsprong"
                    }
                  />
                </Grid>
                <Grid item xs={1}>
                  <ContentPaper text={"0.50"} />
                </Grid>
              </Grid>
              <Grid container mt={2}>
                <Grid item xs={12}>
                  <TitlePaper text={"Balk"} />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={1}>
                  <ContentPaper text={"1."} />
                </Grid>
                <Grid item xs={10}>
                  <ContentPaper
                    text={
                      "Verb van min 2 versch dans elementen, wv 1 sprong 180º beenspreiding"
                    }
                  />
                </Grid>
                <Grid item xs={1}>
                  <ContentPaper text={"0.50"} />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={1}>
                  <ContentPaper text={"2."} />
                </Grid>
                <Grid item xs={10}>
                  <ContentPaper text={"Draai uit groep 3 óf rol/flair*"} />
                </Grid>
                <Grid item xs={1}>
                  <ContentPaper text={"0.50"} />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={1}>
                  <ContentPaper text={"3."} />
                </Grid>
                <Grid item xs={10}>
                  <ContentPaper text={"Acro element aw óf min B waarde"} />
                </Grid>
                <Grid item xs={1}>
                  <ContentPaper text={"0.50"} />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={1}>
                  <ContentPaper text={"4."} />
                </Grid>
                <Grid item xs={10}>
                  <ContentPaper
                    text={
                      "2 versch acro elementen, wv min 1 door of tot handstand"
                    }
                  />
                </Grid>
                <Grid item xs={1}>
                  <ContentPaper text={"0.50"} />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12}>
                  <ContentPaper
                    text={
                      "*1.303, 1.304, 1.207, 1.308, 4.203, 4.204, 4.304, 4.105, 4.305, 4.206, 4.306, 4.307 kunnen SE 2 vervullen."
                    }
                  />
                </Grid>
              </Grid>
              <Grid container mt={2}>
                <Grid item xs={12}>
                  <TitlePaper text={"Vloer"} />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={1}>
                  <ContentPaper text={"1."} />
                </Grid>
                <Grid item xs={10} pr={1}>
                  <ContentPaper
                    text={
                      "Danspassage van 2 versch sprongen/huppen indirect verbonden, min 1 sprong met 180º beenspreiding"
                    }
                  />
                </Grid>
                <Grid item xs={1}>
                  <ContentPaper text={"0.50"} />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={1}>
                  <ContentPaper text={"2."} />
                </Grid>
                <Grid item xs={10}>
                  <ContentPaper
                    text={
                      "Streksalto vw óf aw óf salto met lengteas draai van min 180º"
                    }
                  />
                </Grid>
                <Grid item xs={1}>
                  <ContentPaper text={"0.50"} />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={1}>
                  <ContentPaper text={"3."} />
                </Grid>
                <Grid item xs={10}>
                  <ContentPaper text={"Salto vw"} />
                </Grid>
                <Grid item xs={1}>
                  <ContentPaper text={"0.50"} />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={1}>
                  <ContentPaper text={"4."} />
                </Grid>
                <Grid item xs={10}>
                  <ContentPaper text={"Salto aw"} />
                </Grid>
                <Grid item xs={1}>
                  <ContentPaper text={"0.50"} />
                </Grid>
              </Grid>
            </Container>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};
