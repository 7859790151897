import React, { useState, useEffect } from "react";
import {
  Grid,
  Container,
  Typography,
  Divider,
  Button,
  Paper,
} from "@mui/material";
import { PgColors } from "../../../configs/colors";

export const Sup2Floor = () => {
  return (
    <>
      <Grid container mt={2} marginLeft={2}>
        <Grid item>
          <Typography variant="h6" sx={{ color: PgColors.redpg[500] }}>
            Vloer
          </Typography>
        </Grid>
      </Grid>
      <Grid container marginLeft={2}>
        <Grid item>
          <Typography
            variant="subtitle1"
            sx={{
              color: PgColors.redpg[500],
              fontSize: { xs: 12, sm: 14, md: 16 },
            }}
            align="left"
          >
            *Met diagonale dempingsmat
          </Typography>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item mt={0} xs={12}>
          <Paper
            elevation={2}
            sx={{ backgroundColor: PgColors.bluepg[100], width: "100%" }}
          >
            <Container>
              <Grid
                container
                marginTop={0}
                sx={{ borderBottom: 1, borderColor: PgColors.bluepg[200] }}
                display={{ xs: "none", md: "flex" }}
              >
                <Grid
                  item
                  md={1}
                  display={{ xs: "none", md: "flex" }}
                  sx={{ marginRight: { md: -4, lg: -8 } }}
                ></Grid>
                <Grid item xs={11} md={5}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontWeight: 500,
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    Samenstellingselement
                  </Typography>
                </Grid>
                <Grid item xs={1} md={1} sx={{ pl: { md: 2 } }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontWeight: 500,
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    D
                  </Typography>
                </Grid>

                <Grid item md={5} display={{ xs: "none", md: "flex" }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontWeight: 500,
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    Bonus
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{
                    mr: { md: -4 },
                  }}
                  display={{ xs: "none", md: "flex" }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontWeight: 500,
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    D
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                container
                marginTop={1}
                sx={{ borderBottom: 1, borderColor: PgColors.bluepg[200] }}
                display={{ xs: "none", md: "flex" }}
              >
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{ marginRight: { md: -4, lg: -8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    1
                  </Typography>
                </Grid>
                <Grid item xs={10} md={5}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    Danspassage samengesteld uit 2 versch sprongen, direct of
                    indirect verb, waarvan min 1 wisselspagaat
                  </Typography>
                </Grid>
                <Grid item xs={1} md={1} sx={{ pl: { md: 2 } }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    1.00
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={5}
                  display={{ xs: "none", md: "flex" }}
                  sx={{ pr: { md: 2 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                      marginRight: { md: -2 },
                    }}
                    align="left"
                  >
                    Danspassage uitgevoerd met wisselspagaat links en
                    wisselspagaat rechts
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={1}
                  display={{ xs: "none", md: "flex" }}
                  sx={{ mr: { md: -4 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    0.30
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                container
                marginTop={1}
                sx={{ borderBottom: 1, borderColor: PgColors.bluepg[200] }}
                display={{ xs: "none", md: "flex" }}
              >
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{ marginRight: { md: -4, lg: -8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    2
                  </Typography>
                </Grid>
                <Grid item xs={10} md={5}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    Arabier - flikflak - flikflak - salto ao (flikflak mag
                    temposalto zijn)
                  </Typography>
                </Grid>
                <Grid item xs={1} md={1} sx={{ pl: { md: 2 } }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    1.00
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={5}
                  sx={{ pr: { md: 2 } }}
                  display={{ xs: "none", md: "flex" }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    SE 2 uitgevoerd met salto ao min B
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={1}
                  display={{ xs: "none", md: "flex" }}
                  sx={{ mr: { md: -4 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    0.30
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                container
                marginTop={1}
                sx={{ borderBottom: 1, borderColor: PgColors.bluepg[200] }}
                display={{ xs: "none", md: "flex" }}
              >
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{ marginRight: { md: -4, lg: -8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    3
                  </Typography>
                </Grid>
                <Grid item xs={10} md={5}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    Overslag - salto vo
                  </Typography>
                </Grid>
                <Grid item xs={1} md={1} sx={{ pl: { md: 2 } }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    1.00
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={5}
                  sx={{ pr: { md: 2 } }}
                  display={{ xs: "none", md: "flex" }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    SE 3 uitgevoerd als arolijn van min 3 versch elementen
                  </Typography>
                </Grid>
                <Grid item md={1} sx={{ mr: { md: -4 } }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    0.30
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                container
                marginTop={1}
                sx={{ borderBottom: 1, borderColor: PgColors.bluepg[200] }}
                display={{ xs: "none", md: "flex" }}
              >
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{ marginRight: { md: -4, lg: -8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    4
                  </Typography>
                </Grid>
                <Grid item xs={10} md={5}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    Aerial
                  </Typography>
                </Grid>
                <Grid item xs={1} md={1} sx={{ pl: { md: 2 } }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    0.50
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={5}
                  display={{ xs: "none", md: "flex" }}
                  sx={{ pr: { md: 2 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    Aerial uitgevoerd vu 1 stap
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={1}
                  display={{ xs: "none", md: "flex" }}
                  sx={{ mr: { md: -4 } }}
                >
                  {" "}
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    0.30
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                container
                marginTop={1}
                sx={{ borderBottom: 1, borderColor: PgColors.bluepg[200] }}
                display={{ xs: "none", md: "flex" }}
              >
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{ marginRight: { md: -4, lg: -8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    5
                  </Typography>
                </Grid>
                <Grid item xs={10} md={5}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    Stut tot handstand met (min) open ½ draai
                  </Typography>
                </Grid>
                <Grid item xs={1} md={1} sx={{ pl: { md: 2 } }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    0.50
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={5}
                  display={{ xs: "none", md: "flex" }}
                  sx={{ pr: { md: 2 } }}
                ></Grid>
                <Grid
                  item
                  md={1}
                  display={{ xs: "none", md: "flex" }}
                  sx={{ mr: { md: -4 } }}
                ></Grid>
              </Grid>

              <Grid
                container
                marginTop={1}
                sx={{ borderBottom: 1, borderColor: PgColors.bluepg[200] }}
                display={{ xs: "none", md: "flex" }}
              >
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{ marginRight: { md: -4, lg: -8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    6
                  </Typography>
                </Grid>
                <Grid item xs={10} md={5}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    (Min) 1½ pirouette (max. afwijking 45º)
                  </Typography>
                </Grid>
                <Grid item xs={1} md={1} sx={{ pl: { md: 2 } }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    0.50
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={5}
                  display={{ xs: "none", md: "flex" }}
                  sx={{ pr: { md: 2 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    SE 6: Een 2e pirouette uit een verschillende lijn min 1/1
                    draai (max. afwijking 45º)
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={1}
                  display={{ xs: "none", md: "flex" }}
                  sx={{ mr: { md: -4 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    0.30
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                container
                marginTop={1}
                display={{ xs: "none", md: "flex" }}
              >
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{ marginRight: { md: -4, lg: -8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    7
                  </Typography>
                </Grid>
                <Grid item xs={10} md={5}></Grid>
                <Grid item xs={1}></Grid>
                <Grid
                  item
                  md={5}
                  display={{ xs: "none", md: "flex" }}
                  sx={{ pr: { md: 2 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    (Endorol) Spreidhoeksteun met heffen tot handstand
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={1}
                  display={{ xs: "none", md: "flex" }}
                  sx={{ mr: { md: -4 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    0.30
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                mt={2}
                display={{ xs: "flex", md: "none" }}
                sx={{ borderBottom: 1, borderColor: PgColors.bluepg[200] }}
              >
                <Grid item xs={11} md={4} sx={{ marginRight: { md: 10 } }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontWeight: 500,
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    Samenstellingselement
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontWeight: 500,
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    D
                  </Typography>
                </Grid>
              </Grid>
              <Grid container mt={1} display={{ xs: "flex", md: "none" }}>
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{ marginRight: { md: -4, lg: -8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    1
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={10}
                  sx={{ paddingRight: { md: 0 }, marginRight: { md: 8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    Danspassage samengesteld uit 2 versch sprongen, direct of
                    indirect verb, waarvan min 1 wisselspagaat
                  </Typography>
                </Grid>
                <Grid item xs={1} md={1} sx={{ marginRight: { md: 0 } }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    1.00
                  </Typography>
                </Grid>
              </Grid>
              <Grid container mt={1} display={{ xs: "flex", md: "none" }}>
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{ marginRight: { md: -4, lg: -8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    2
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={10}
                  sx={{ paddingRight: { md: 0 }, marginRight: { md: 8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    Arabier - flikflak - flikflak - salto ao (flikflak mag
                    temposalto zijn)
                  </Typography>
                </Grid>
                <Grid item xs={1} md={1} sx={{ marginRight: { md: 0 } }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    1.00
                  </Typography>
                </Grid>
              </Grid>
              <Grid container mt={1} display={{ xs: "flex", md: "none" }}>
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{ marginRight: { md: -4, lg: -8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    3
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={10}
                  sx={{ paddingRight: { md: 0 }, marginRight: { md: 8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    Overslag - salto vo
                  </Typography>
                </Grid>
                <Grid item xs={1} md={1} sx={{ marginRight: { md: 0 } }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    1.00
                  </Typography>
                </Grid>
              </Grid>
              <Grid container mt={1} display={{ xs: "flex", md: "none" }}>
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{ marginRight: { md: -4, lg: -8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    4
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={10}
                  sx={{ paddingRight: { md: 0 }, marginRight: { md: 8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    Aerial
                  </Typography>
                </Grid>
                <Grid item xs={1} md={1} sx={{ marginRight: { md: 0 } }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    0.50
                  </Typography>
                </Grid>
              </Grid>
              <Grid container mt={1} display={{ xs: "flex", md: "none" }}>
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{ marginRight: { md: -4, lg: -8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    5
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={10}
                  sx={{ paddingRight: { md: 0 }, marginRight: { md: 8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    Stut tot handstand met (min) open ½ draai
                  </Typography>
                </Grid>
                <Grid item xs={1} md={1} sx={{ marginRight: { md: 0 } }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    0.50
                  </Typography>
                </Grid>
              </Grid>
              <Grid container mt={1} display={{ xs: "flex", md: "none" }}>
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{ marginRight: { md: -4, lg: -8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    6
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={10}
                  sx={{ paddingRight: { md: 0 }, marginRight: { md: 8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    (Min) 1½ pirouette (max. afwijking 45º)
                  </Typography>
                </Grid>
                <Grid item xs={1} md={1} sx={{ marginRight: { md: 0 } }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    0.50
                  </Typography>
                </Grid>
              </Grid>
              <Grid container mt={1} display={{ xs: "flex", md: "none" }}>
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{ marginRight: { md: -4, lg: -8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    7
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={10}
                  sx={{ paddingRight: { md: 0 }, marginRight: { md: 8 } }}
                ></Grid>
                <Grid item xs={1} md={1} sx={{ marginRight: { md: 0 } }}></Grid>
              </Grid>

              <Grid
                container
                mt={2}
                display={{ xs: "flex", md: "none" }}
                sx={{ borderBottom: 1, borderColor: PgColors.bluepg[200] }}
              >
                <Grid item xs={11} md={4} sx={{ marginRight: { md: 10 } }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontWeight: 500,
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    Bonus
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontWeight: 500,
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    D
                  </Typography>
                </Grid>
              </Grid>

              <Grid container mt={1} display={{ xs: "flex", md: "none" }}>
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{ marginRight: { md: -4, lg: -8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    1
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={10}
                  sx={{ paddingRight: { md: 0 }, marginRight: { md: 8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    Danspassage uitgevoerd met wisselspagaat links en
                    wisselspagaat rechts
                  </Typography>
                </Grid>
                <Grid item xs={1} md={1} sx={{ marginRight: { md: 0 } }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    0.30
                  </Typography>
                </Grid>
              </Grid>
              <Grid container mt={1} display={{ xs: "flex", md: "none" }}>
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{ marginRight: { md: -4, lg: -8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    2
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={10}
                  sx={{ paddingRight: { md: 0 }, marginRight: { md: 8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    SE 2 uitgevoerd met salto ao min B
                  </Typography>
                </Grid>
                <Grid item xs={1} md={1} sx={{ marginRight: { md: 0 } }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    0.30
                  </Typography>
                </Grid>
              </Grid>
              <Grid container mt={1} display={{ xs: "flex", md: "none" }}>
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{ marginRight: { md: -4, lg: -8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    3
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={10}
                  sx={{ paddingRight: { md: 0 }, marginRight: { md: 8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    SE 3 uitgevoerd als arolijn van min 3 versch elementen
                  </Typography>
                </Grid>
                <Grid item xs={1} md={1} sx={{ marginRight: { md: 0 } }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    0.30
                  </Typography>
                </Grid>
              </Grid>
              <Grid container mt={1} display={{ xs: "flex", md: "none" }}>
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{ marginRight: { md: -4, lg: -8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    4
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={10}
                  sx={{ paddingRight: { md: 0 }, marginRight: { md: 8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    Aerial uitgevoerd vu 1 stap
                  </Typography>
                </Grid>
                <Grid item xs={1} md={1} sx={{ marginRight: { md: 0 } }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    0.30
                  </Typography>
                </Grid>
              </Grid>
              <Grid container mt={1} display={{ xs: "flex", md: "none" }}>
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{ marginRight: { md: -4, lg: -8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    5
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={10}
                  sx={{ paddingRight: { md: 0 }, marginRight: { md: 8 } }}
                ></Grid>
                <Grid item xs={1} md={1} sx={{ marginRight: { md: 0 } }}></Grid>
              </Grid>
              <Grid container mt={1} display={{ xs: "flex", md: "none" }}>
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{ marginRight: { md: -4, lg: -8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    6
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={10}
                  sx={{ paddingRight: { md: 0 }, marginRight: { md: 8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    SE 6: Een 2e pirouette uit een verschillende lijn min 1/1
                    draai (max. afwijking 45º)
                  </Typography>
                </Grid>
                <Grid item xs={1} md={1} sx={{ marginRight: { md: 0 } }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    0.30
                  </Typography>
                </Grid>
              </Grid>
              <Grid container mt={1} display={{ xs: "flex", md: "none" }}>
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{ marginRight: { md: -4, lg: -8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    7
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={10}
                  sx={{ paddingRight: { md: 0 }, marginRight: { md: 8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    (Endorol) Spreidhoeksteun met heffen tot handstand
                  </Typography>
                </Grid>
                <Grid item xs={1} md={1} sx={{ marginRight: { md: 0 } }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    0.30
                  </Typography>
                </Grid>
              </Grid>
            </Container>
          </Paper>
        </Grid>
      </Grid>
      <Grid container marginLeft={2}>
        <Grid item>
          <Typography
            variant="subtitle1"
            sx={{
              color: PgColors.redpg[500],
              fontSize: { xs: 12, sm: 14, md: 16 },
            }}
            align="left"
          >
            *Een passage met wisselspagaat link en rechts wordt gezien als 2
            versch sprongen en voldoet aan SE 1. De wisselspagaat krijgt één
            keer MW.
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};
