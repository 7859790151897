import React, { useState, useEffect } from "react";
import { Grid, Container } from "@mui/material";
import { SupEIntro } from "./SupEIntro";
import { SupESE } from "./SupESE";
import { SupEVW } from "./SupEVW";

export const SupE = () => {
  return (
    <>
      <Container>
        <SupEIntro />
        <SupESE />
        <SupEVW />
      </Container>
    </>
  );
};
