import React, { useState, useEffect } from "react";
import {
  collection,
  query,
  onSnapshot,
  updateDoc,
  doc,
  deleteDoc,
  setDoc,
} from "firebase/firestore";
import { db } from "../../firebase/config";
import {
  Container,
  Grid,
  Typography,
  Checkbox,
  Paper,
  TextField,
  Button,
  Modal,
  Toolbar,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Box,
  Drawer,
  Divider,
  alertClasses,
} from "@mui/material";
import { useParams } from "react-router-dom";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import AddIcon from "@mui/icons-material/Add";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { useAuthContext } from "../../hooks/useAuthContext";
import { PgColors } from "../../configs/colors";

export const EditLesson = ({ path }: any) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [currentData, setCurrentData] = useState<any>(null);
  const [name, setName] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [wuContent, setWuContent] = useState("");
  const [conditioning, setConditioning] = useState("");
  const [vaultGoal, setVaultGoal] = useState("");
  const [vaultCond, setVaultCond] = useState("");
  const [vaultStations, setVaultStations] = useState("");
  const [barsGoal, setBarsGoal] = useState("");
  const [barsCond, setBarsCond] = useState("");
  const [barsStations, setBarsStations] = useState("");
  const [beamGoal, setBeamGoal] = useState("");
  const [beamCond, setBeamCond] = useState("");
  const [beamStations, setBeamStations] = useState("");
  const [floorGoal, setFloorGoal] = useState("");
  const [floorCond, setFloorCond] = useState("");
  const [floorStations, setFloorStations] = useState("");
  const [otherGoal, setOtherGoal] = useState("");
  const [otherCond, setOtherCond] = useState("");
  const [otherStations, setOtherStations] = useState("");
  const [groups, setGroups] = useState("");
  const [agenda, setAgenda] = useState("");

  useEffect(() => {
    if (open) {
      const unsubscribe = onSnapshot(doc(db, path), (snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.data();
          setCurrentData(data);
          // Set the initial values for TextFields
          setName(data.name || "");
          setDate(data.date || "");
          setTime(data.time || "");
          setWuContent(data.wuContent || "");
          setConditioning(data.conditioning || "");
          setVaultGoal(data.vaultGoal || "");
          setVaultCond(data.vaultCond || "");
          setVaultStations(data.vaultStations || "");
          setBarsGoal(data.barsGoal || "");
          setBarsCond(data.barsCond || "");
          setBarsStations(data.barsStations || "");
          setBeamGoal(data.beamGoal || "");
          setBeamCond(data.beamCond || "");
          setBeamStations(data.beamStations || "");
          setFloorGoal(data.floorGoal || "");
          setFloorCond(data.floorCond || "");
          setFloorStations(data.floorStations || "");
          setOtherGoal(data.otherGoal || "");
          setOtherCond(data.otherCond || "");
          setOtherStations(data.otherStations || "");
          setGroups(data.groups || "");
          setAgenda(data.agenda || "");
        }
      });

      return () => unsubscribe();
    }
  }, [open, path]);

  const handleUpdate = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    try {
      if (name) {
        await updateDoc(doc(db, path), {
          name: name,
        });
      }
      if (date) {
        await updateDoc(doc(db, path), {
          date: date,
        });
      }
      if (time) {
        await updateDoc(doc(db, path), {
          time: time,
        });
      }
      if (wuContent) {
        await updateDoc(doc(db, path), {
          wuContent: wuContent,
        });
      }
      if (conditioning) {
        await updateDoc(doc(db, path), {
          conditioning: conditioning,
        });
      }
      if (vaultGoal) {
        await updateDoc(doc(db, path), {
          vaultGoal: vaultGoal,
        });
      }
      if (vaultCond) {
        await updateDoc(doc(db, path), {
          vaultCond: vaultCond,
        });
      }
      if (vaultStations) {
        await updateDoc(doc(db, path), {
          vaultStations: vaultStations,
        });
      }
      if (barsGoal) {
        await updateDoc(doc(db, path), {
          barsGoal: barsGoal,
        });
      }
      if (barsCond) {
        await updateDoc(doc(db, path), {
          barsCond: barsCond,
        });
      }
      if (barsStations) {
        await updateDoc(doc(db, path), {
          barsStations: barsStations,
        });
      }
      if (beamGoal) {
        await updateDoc(doc(db, path), {
          beamGoal: beamGoal,
        });
      }
      if (beamCond) {
        await updateDoc(doc(db, path), {
          beamCond: beamCond,
        });
      }
      if (beamStations) {
        await updateDoc(doc(db, path), {
          beamStations: beamStations,
        });
      }
      if (floorGoal) {
        await updateDoc(doc(db, path), {
          floorGoal: floorGoal,
        });
      }
      if (floorCond) {
        await updateDoc(doc(db, path), {
          floorCond: floorCond,
        });
      }
      if (floorStations) {
        await updateDoc(doc(db, path), {
          floorStations: floorStations,
        });
      }
      if (otherGoal) {
        await updateDoc(doc(db, path), {
          otherGoal: otherGoal,
        });
      }
      if (otherCond) {
        await updateDoc(doc(db, path), {
          otherCond: otherCond,
        });
      }
      if (otherStations) {
        await updateDoc(doc(db, path), {
          otherStations: otherStations,
        });
      }
      if (groups) {
        await updateDoc(doc(db, path), {
          groups: groups,
        });
      }
      if (agenda) {
        await updateDoc(doc(db, path), {
          agenda: agenda,
        });
      }
      setName("");
      setDate("");
      setTime("");
      setWuContent("");
      setConditioning("");
      setVaultGoal("");
      setVaultCond("");
      setVaultStations("");
      setBarsGoal("");
      setBarsCond("");
      setBarsStations("");
      setBeamGoal("");
      setBeamCond("");
      setBeamStations("");
      setFloorGoal("");
      setFloorCond("");
      setFloorStations("");
      setOtherGoal("");
      setOtherCond("");
      setOtherStations("");
      setGroups("");
      setAgenda("");
      setOpen(false);
    } catch (err) {
      alert(err);
    }
  };

  return (
    <>
      <Button
        variant="contained"
        sx={{ heigth: 35 }}
        onClick={handleOpen}
        style={{
          backgroundColor: PgColors.redpg[200],
        }}
      >
        <EditOutlinedIcon />
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        style={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
        }}
      >
        <Box
          sx={{
            marginTop: { xs: 1, md: 2 },
            width: { xs: 350, sm: 580, md: 880, xl: 1500 },
            minHeight: { xs: 600, md: 600 },
            maxHeight: { lg: 1300 },
            bgcolor: "background.paper",
            border: "4px solid #9f5b5a",
            boxShadow: 24,
            borderRadius: 5,
            p: 4,
            overflow: "auto",
          }}
        >
          <form>
            <Grid container>
              <Grid item xs={10} md={11}></Grid>
              <Grid item xs={1} md={1}>
                <Button
                  variant="contained"
                  sx={{ heigth: 35 }}
                  onClick={handleClose}
                  style={{
                    backgroundColor: PgColors.redpg[200],
                  }}
                >
                  <CloseOutlinedIcon />
                </Button>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item marginTop={6}>
                <Typography
                  variant="h5"
                  style={{ color: PgColors.redpg[200], fontWeight: "bold" }}
                >
                  Lesson Plan
                </Typography>
              </Grid>
            </Grid>
            <Grid container marginTop={2}>
              <Grid item xs={12} md={4} marginTop={1}>
                <TextField
                  label="Lesson Plan Name"
                  style={{
                    color: PgColors.bluepg[200],
                  }}
                  variant="outlined"
                  fullWidth
                  value={name}
                  onChange={(event) => setName(event.target.value)}
                />
              </Grid>

              <Grid item xs={6} md={4} marginTop={1}>
                <TextField
                  label="Date"
                  style={{
                    color: PgColors.bluepg[200],
                  }}
                  variant="outlined"
                  fullWidth
                  value={date}
                  onChange={(event) => setDate(event.target.value)}
                />
              </Grid>
              <Grid item xs={6} md={4} marginTop={1}>
                <TextField
                  label="Time"
                  style={{
                    color: PgColors.bluepg[200],
                  }}
                  variant="outlined"
                  fullWidth
                  value={time}
                  onChange={(event) => setTime(event.target.value)}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item marginTop={4} md={6}>
                <Typography
                  variant="h6"
                  style={{ color: PgColors.redpg[200], fontWeight: "bold" }}
                >
                  Warming-up
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6} marginTop={1}>
                <TextField
                  label="Content: e.g. run 5 laps"
                  style={{
                    color: PgColors.bluepg[200],
                  }}
                  variant="outlined"
                  multiline
                  minRows={5}
                  fullWidth
                  value={wuContent}
                  onChange={(event) => setWuContent(event.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={6} marginTop={1}>
                <TextField
                  label="Conditioning / Methodology / technique"
                  style={{
                    color: PgColors.bluepg[200],
                  }}
                  variant="outlined"
                  multiline
                  minRows={5}
                  fullWidth
                  value={conditioning}
                  onChange={(event) => setConditioning(event.target.value)}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item marginTop={4} md={6}>
                <Typography
                  variant="h6"
                  style={{ color: PgColors.redpg[200], fontWeight: "bold" }}
                >
                  Set Up
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6} marginTop={1}>
                <TextField
                  label="Groups"
                  style={{
                    color: PgColors.bluepg[200],
                  }}
                  variant="outlined"
                  multiline
                  minRows={5}
                  fullWidth
                  value={groups}
                  onChange={(event) => setGroups(event.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={6} marginTop={1}>
                <TextField
                  label="Time agenda"
                  style={{
                    color: PgColors.bluepg[200],
                  }}
                  variant="outlined"
                  multiline
                  minRows={5}
                  fullWidth
                  value={agenda}
                  onChange={(event) => setAgenda(event.target.value)}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item marginTop={4} md={6}>
                <Typography
                  variant="h6"
                  style={{ color: PgColors.redpg[200], fontWeight: "bold" }}
                >
                  Vault
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={12} marginTop={1}>
                <TextField
                  label="Objective / Goal / Skill"
                  style={{
                    color: PgColors.bluepg[200],
                  }}
                  variant="outlined"
                  fullWidth
                  value={vaultGoal}
                  onChange={(event) => setVaultGoal(event.target.value)}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6} marginTop={1}>
                <TextField
                  label="Content / Conditioning / Program"
                  style={{
                    color: PgColors.bluepg[200],
                  }}
                  variant="outlined"
                  multiline
                  minRows={5}
                  fullWidth
                  value={vaultCond}
                  onChange={(event) => setVaultCond(event.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={6} marginTop={1}>
                <TextField
                  label="Stations"
                  style={{
                    color: PgColors.bluepg[200],
                  }}
                  variant="outlined"
                  multiline
                  minRows={5}
                  fullWidth
                  value={vaultStations}
                  onChange={(event) => setVaultStations(event.target.value)}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item marginTop={4} md={6}>
                <Typography
                  variant="h6"
                  style={{ color: PgColors.redpg[200], fontWeight: "bold" }}
                >
                  Bars
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={12} marginTop={1}>
                <TextField
                  label="Objective / Goal / Skill"
                  style={{
                    color: PgColors.bluepg[200],
                  }}
                  variant="outlined"
                  fullWidth
                  value={barsGoal}
                  onChange={(event) => setBarsGoal(event.target.value)}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6} marginTop={1}>
                <TextField
                  label="Content / Conditioning / Program"
                  style={{
                    color: PgColors.bluepg[200],
                  }}
                  variant="outlined"
                  multiline
                  minRows={5}
                  fullWidth
                  value={barsCond}
                  onChange={(event) => setBarsCond(event.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={6} marginTop={1}>
                <TextField
                  label="Stations"
                  style={{
                    color: PgColors.bluepg[200],
                  }}
                  variant="outlined"
                  multiline
                  minRows={5}
                  fullWidth
                  value={barsStations}
                  onChange={(event) => setBarsStations(event.target.value)}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item marginTop={4} md={6}>
                <Typography
                  variant="h6"
                  style={{ color: PgColors.redpg[200], fontWeight: "bold" }}
                >
                  Beam
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={12} marginTop={1}>
                <TextField
                  label="Objective / Goal / Skill"
                  style={{
                    color: PgColors.bluepg[200],
                  }}
                  variant="outlined"
                  fullWidth
                  value={beamGoal}
                  onChange={(event) => setBeamGoal(event.target.value)}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6} marginTop={1}>
                <TextField
                  label="Content / Conditioning / Program"
                  style={{
                    color: PgColors.bluepg[200],
                  }}
                  variant="outlined"
                  multiline
                  minRows={5}
                  fullWidth
                  value={beamCond}
                  onChange={(event) => setBeamCond(event.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={6} marginTop={1}>
                <TextField
                  label="Stations"
                  style={{
                    color: PgColors.bluepg[200],
                  }}
                  variant="outlined"
                  multiline
                  minRows={5}
                  fullWidth
                  value={beamStations}
                  onChange={(event) => setBeamStations(event.target.value)}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item marginTop={4} md={6}>
                <Typography
                  variant="h6"
                  style={{ color: PgColors.redpg[200], fontWeight: "bold" }}
                >
                  Floor
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={12} marginTop={1}>
                <TextField
                  label="Objective / Goal / Skill"
                  style={{
                    color: PgColors.bluepg[200],
                  }}
                  variant="outlined"
                  fullWidth
                  value={floorGoal}
                  onChange={(event) => setFloorGoal(event.target.value)}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6} marginTop={1}>
                <TextField
                  label="Content / Conditioning / Program"
                  style={{
                    color: PgColors.bluepg[200],
                  }}
                  variant="outlined"
                  multiline
                  minRows={5}
                  fullWidth
                  value={floorCond}
                  onChange={(event) => setFloorCond(event.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={6} marginTop={1}>
                <TextField
                  label="Stations"
                  style={{
                    color: PgColors.bluepg[200],
                  }}
                  variant="outlined"
                  multiline
                  minRows={5}
                  fullWidth
                  value={floorStations}
                  onChange={(event) => setFloorStations(event.target.value)}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item marginTop={4} md={6}>
                <Typography
                  variant="h6"
                  style={{ color: PgColors.redpg[200], fontWeight: "bold" }}
                >
                  Other
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={12} marginTop={1}>
                <TextField
                  label="Objective / Goal / Skill"
                  style={{
                    color: PgColors.bluepg[200],
                  }}
                  variant="outlined"
                  fullWidth
                  value={otherGoal}
                  onChange={(event) => setOtherGoal(event.target.value)}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6} marginTop={1}>
                <TextField
                  label="Content / Conditioning / Program"
                  style={{
                    color: PgColors.bluepg[200],
                  }}
                  variant="outlined"
                  multiline
                  minRows={5}
                  fullWidth
                  value={otherCond}
                  onChange={(event) => setOtherCond(event.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={6} marginTop={1}>
                <TextField
                  label="Stations"
                  style={{
                    color: PgColors.bluepg[200],
                  }}
                  variant="outlined"
                  multiline
                  minRows={5}
                  fullWidth
                  value={otherStations}
                  onChange={(event) => setOtherStations(event.target.value)}
                />
              </Grid>
            </Grid>

            <Grid container mt={1} justifyContent="end" display="flex" mb={2}>
              <Grid item md={4} xs={12} justifyContent="end" display="flex">
                <Button
                  style={{
                    backgroundColor: PgColors.bluepg[200],
                  }}
                  variant="contained"
                  onClick={(
                    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
                  ) => handleUpdate(e)}
                  size="large"
                  sx={{ height: 55 }}
                >
                  <Typography variant="subtitle1">Update</Typography>
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Modal>
    </>
  );
};
