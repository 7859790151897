import React, { useState, useEffect } from "react";
import { collection, query, onSnapshot, orderBy } from "firebase/firestore";
import {
  Grid,
  Container,
  Typography,
  Paper,
  Button,
  Box,
  CardMedia,
} from "@mui/material";
import { useAuthContext } from "../../../../hooks/useAuthContext";
import { useParams } from "react-router-dom";
import { db } from "../../../../firebase/config";
import { PgColors } from "../../../../configs/colors";
import { GoalsPage } from "./GoalsPage";
import { GoalCommitmentPage } from "./GoalCommitmentPage";

export const GoalsHeadPage = () => {
  const [active, setActive] = useState("Goals");
  const activeColor = PgColors.bluepg[400];

  return (
    <>
      <Grid container mt={2}>
        <Grid item xs={3} md={2} display="flex" justifyContent="center">
          <Button
            style={{
              backgroundColor:
                active === "Goals" ? activeColor : PgColors.bluepg[200],
            }}
            variant="contained"
            onClick={() => setActive("Goals")}
          >
            <Typography
              style={{ fontWeight: "semibold" }}
              sx={{ fontSize: { xs: 10, md: 14 } }}
            >
              Goal Setting
            </Typography>
          </Button>
        </Grid>
        <Grid item xs={3} md={2} display="flex" justifyContent="center">
          <Button
            style={{
              backgroundColor:
                active === "Commitment" ? activeColor : PgColors.bluepg[200],
            }}
            variant="contained"
            onClick={() => setActive("Commitment")}
          >
            <Typography
              style={{ fontWeight: "semibold" }}
              sx={{ fontSize: { xs: 10, md: 14 } }}
            >
              Goal Commitment
            </Typography>
          </Button>
        </Grid>
      </Grid>
      <Grid container>{active === "Goals" && <GoalsPage />}</Grid>
      <Grid container>{active === "Commitment" && <GoalCommitmentPage />}</Grid>
    </>
  );
};
