import React, { useState, useEffect } from "react";
import { Grid, Container } from "@mui/material";
import { Sup4Intro } from "./Sup4Intro";
import { Sup4Vault } from "./Sup4Vault";
import { Sup4Bars } from "./Sup4Bars";
import { Sup4Beam } from "./Sup4Beam";
import { Sup4Floor } from "./Sup4Floor";

export const Sup4 = () => {
  return (
    <>
      <Container>
        <Sup4Intro />
        <Sup4Vault />
        <Sup4Bars />
        <Sup4Beam />
        <Sup4Floor />
      </Container>
    </>
  );
};
