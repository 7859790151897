import React, { useState } from "react";
import {
  Grid,
  Container,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Paper,
  TextField,
  Button,
  Checkbox,
} from "@mui/material";
import {
  collection,
  query,
  onSnapshot,
  DocumentData,
  addDoc,
  getDoc,
  serverTimestamp,
  updateDoc,
  doc,
  deleteDoc,
} from "firebase/firestore";
import { Header } from "../../components/landingPage/Header";
import { useSignup } from "../../hooks/useSignup";
import { PgColors } from "../../configs/colors";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getFunctions, httpsCallable } from "firebase/functions";
import { useStripe } from "@stripe/react-stripe-js";
import { db } from "../../firebase/config";
import { useAuthContext } from "../../hooks/useAuthContext";

export const Subscribe = () => {
  const stripe = useStripe(); // Initialize the Stripe hook
  const { user } = useAuthContext();
  const navigate = useNavigate();

  // const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
  //   e.preventDefault();

  //   const ref = collection(db, `users/${user.uid}/checkout_sessions`);

  //   await addDoc(ref, {
  //     price: "price_1NeGmkAosbbWDTfD2WNWF3BH",
  //     success_url: window.location.origin,
  //     cancel_url: window.location.origin,
  //   });
  // };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    console.log("Clicked on button:", user.uid);

    const checkoutSessionsRef = collection(
      db,
      `users/${user.uid}/checkout_sessions`
    );

    try {
      // Create a new checkout session in the subcollection inside this user's document
      const newCheckoutSessionRef = await addDoc(checkoutSessionsRef, {
        price: "price_1NeGmkAosbbWDTfD2WNWF3BH",
        success_url: window.location.origin,
        cancel_url: window.location.origin,
      });

      // Fetch the session ID from the newly created document
      const checkoutSessionSnapshot = await getDoc(newCheckoutSessionRef);
      const checkoutSessionData =
        checkoutSessionSnapshot.data() as DocumentData;

      if (checkoutSessionData?.sessionId && stripe) {
        // Initiate Stripe Checkout
        const { error } = await stripe.redirectToCheckout({
          sessionId: checkoutSessionData.sessionId,
        });

        if (error) {
          console.error("Stripe error:", error);
        }
      }
    } catch (error) {
      console.error("Error creating checkout session:", error);
    }
  };

  return (
    <>
      <Header />
      <div
        style={{
          backgroundColor: PgColors.bluepg[100],
          height: "100vh",
        }}
      >
        <Container>
          <Grid container justifyContent="center">
            <Grid
              item
              md={10}
              sx={{ marginTop: { xs: 4, lg: 8, xl: 10 } }}
              marginBottom={20}
            >
              <Paper elevation={2}>
                <form onSubmit={handleSubmit}>
                  <Container>
                    <Grid container>
                      <Grid item xs={12} marginTop={4} marginBottom={20}>
                        <Button
                          variant="contained"
                          size="large"
                          type="submit"
                          style={{
                            backgroundColor: PgColors.redpg[200],
                          }}
                        >
                          Abboneer het Coach Plan
                        </Button>
                      </Grid>
                    </Grid>
                  </Container>
                </form>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
};
