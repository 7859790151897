import React, { useState, useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import {
  Grid,
  AppBar,
  Container,
  Typography,
  Paper,
  TextField,
  Button,
  Divider,
  Toolbar,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Box,
  Drawer,
  Collapse,
} from "@mui/material";
import { NavLink, Link } from "react-router-dom";
import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import IconButton from "@mui/material/IconButton";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useLogout } from "../../hooks/useLogout";
import { UserList } from "./UserList";
import LibraryBooksOutlinedIcon from "@mui/icons-material/LibraryBooksOutlined";
import SportsMmaOutlinedIcon from "@mui/icons-material/SportsMmaOutlined";
import TrendingUpOutlinedIcon from "@mui/icons-material/TrendingUpOutlined";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import { PgColors } from "../../configs/colors";
import {
  collection,
  query,
  onSnapshot,
  DocumentData,
  addDoc,
  getDoc,
  serverTimestamp,
  updateDoc,
  doc,
  deleteDoc,
} from "firebase/firestore";
import { db } from "../../firebase/config";
import { useStripe } from "@stripe/react-stripe-js";
import { auth } from "../../firebase/config";
import { getIdTokenResult, onAuthStateChanged } from "firebase/auth";
import { AdminDrawer } from "./AdminDrawer";

const drawerWidth = 300;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

interface CheckoutSessionData {
  error?: string;
  url?: string;
}

export const UserDrawer = () => {
  const stripe = useStripe(); // Initialize the Stripe hook
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const { logout } = useLogout();
  const { authIsReady, user, adminUser, premiumUser } = useAuthContext();

  const handleClick = () => {
    setOpen(!open);
  };

  const handleSubscribe2 = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();

    const docRef = collection(db, `users/${user?.uid}/checkout_sessions`);

    try {
      const newCheckoutSessionRef = await addDoc(docRef, {
        price: "price_1Nh9d4AosbbWDTfD3MjOfhSl",
        success_url: window.location.origin,
        cancel_url: window.location.origin,
      });

      const checkoutSessionRef = doc(
        db,
        `users/${user?.uid}/checkout_sessions/${newCheckoutSessionRef.id}`
      );

      const unsubscribe = onSnapshot(
        checkoutSessionRef,
        (snap) => {
          const checkoutSessionData = snap.data() as CheckoutSessionData;

          if (checkoutSessionData.error) {
            alert(`An error occured: ${checkoutSessionData.error}`);
          }
          if (checkoutSessionData.url) {
            window.location.assign(checkoutSessionData.url);
          }
        },
        (error) => {
          console.error("Snapshot error:", error);
        }
      );

      return () => {
        unsubscribe();
      };
    } catch (error) {
      console.error("Error creating checkout session:", error);
    }
  };

  if (!authIsReady) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <Box display="flex">
        <CssBaseline />
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
            },
          }}
          variant="permanent"
          anchor="left"
          PaperProps={{
            sx: {
              backgroundColor: PgColors.greenpg[100],
            },
          }}
        >
          <DrawerHeader>
            <Grid container justifyContent="center">
              <Grid item>
                <Typography
                  variant="h6"
                  align="center"
                  style={{
                    color: PgColors.bluepg[300],
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  Personal gymnastics
                </Typography>
              </Grid>
            </Grid>
          </DrawerHeader>

          <Grid container>
            <Grid item justifyContent="center" marginLeft={0} marginTop={2}>
              <NavLink to="/dashboard/home" style={{ textDecoration: "none" }}>
                <ListItem button onClick={handleClick}>
                  <ListItemIcon>
                    <HomeOutlinedIcon sx={{ color: PgColors.bluepg[300] }} />
                  </ListItemIcon>
                  <ListItemText
                    primary="Home"
                    primaryTypographyProps={{
                      fontFamily: "Poppins, sans-serif",
                      fontSize: 20,
                      fontWeight: 500,
                      letterSpacing: 0,
                      color: PgColors.bluepg[300],
                    }}
                  />
                </ListItem>
              </NavLink>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item justifyContent="center">
              <NavLink
                to="/dashboard/programs"
                style={{ textDecoration: "none" }}
              >
                <ListItem button onClick={handleClick}>
                  <ListItemIcon>
                    <SportsMmaOutlinedIcon sx={{ color: "#2F5E6D" }} />
                  </ListItemIcon>
                  <ListItemText
                    primary="Programs"
                    primaryTypographyProps={{
                      fontFamily: "Poppins, sans-serif",
                      fontSize: 20,
                      fontWeight: 500,
                      letterSpacing: 0,
                      color: "#2F5E6D",
                    }}
                  />
                </ListItem>
              </NavLink>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item justifyContent="center">
              <NavLink
                to="/dashboard/regulations"
                style={{ textDecoration: "none" }}
              >
                <ListItem button onClick={handleClick}>
                  <ListItemIcon>
                    <ArticleOutlinedIcon sx={{ color: "#2F5E6D" }} />
                  </ListItemIcon>
                  <ListItemText
                    primary="Regulations"
                    primaryTypographyProps={{
                      fontFamily: "Poppins, sans-serif",
                      fontSize: 20,
                      fontWeight: 500,
                      letterSpacing: 0,
                      color: "#2F5E6D",
                    }}
                  />
                </ListItem>
              </NavLink>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item justifyContent="center">
              <NavLink
                to={`/dashboard/gymnasts/${user.uid}`}
                style={{ textDecoration: "none" }}
              >
                <ListItem button onClick={handleClick}>
                  <ListItemIcon>
                    <TrendingUpOutlinedIcon sx={{ color: "#2F5E6D" }} />
                  </ListItemIcon>
                  <ListItemText
                    primary="Progress Page"
                    primaryTypographyProps={{
                      fontFamily: "Poppins, sans-serif",
                      fontSize: 20,
                      fontWeight: 500,
                      letterSpacing: 0,
                      color: "#2F5E6D",
                    }}
                  />
                </ListItem>
              </NavLink>
            </Grid>
          </Grid>

          <Grid container alignItems="flex-end" marginLeft={1}>
            <Grid item>
              <IconButton sx={{ color: "#2F5E6D" }} onClick={logout}>
                <LogoutOutlinedIcon />
              </IconButton>
            </Grid>
          </Grid>
          {/* <Grid marginTop={42} marginLeft={1}>
            <Grid>
              <Button
                variant="contained"
                size="large"
                type="submit"
                style={{
                  backgroundColor: PgColors.redpg[200],
                }}
                onClick={handleSubscribe2}
              >
                Abboneer op het Coach Plan
              </Button>
            </Grid>
          </Grid> */}
        </Drawer>
      </Box>
    </>
  );
};
