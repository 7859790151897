import React, { useState, useEffect } from "react";
import {
  collection,
  query,
  onSnapshot,
  updateDoc,
  doc,
  deleteDoc,
  setDoc,
} from "firebase/firestore";
import {
  Grid,
  Container,
  Typography,
  Paper,
  Button,
  Checkbox,
} from "@mui/material";
import { useAuthContext } from "../../../../hooks/useAuthContext";
import { useParams } from "react-router-dom";
import { db } from "../../../../firebase/config";
import { PgColors } from "../../../../configs/colors";
import CheckIcon from "@mui/icons-material/Check";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";

type CheckboxState = {
  [key: string]: boolean;
};

export const Division5Beam = () => {
  const { user } = useAuthContext();
  const [checkboxes, setCheckboxes] = useState<CheckboxState>({});

  const updateCheckboxState = async (checkboxId: any, isChecked: boolean) => {
    await setDoc(
      doc(
        db,
        `Checklist/Division5/Beam/${user.uid}/checkboxStates/${checkboxId}`
      ),
      {
        isChecked,
      }
    );
  };

  useEffect(() => {
    const ref = query(
      collection(db, `Checklist/Division5/Beam/${user.uid}/checkboxStates`)
    );

    const unsub = onSnapshot(ref, (snapshot) => {
      const checkboxStates: any = [];
      snapshot.docs.forEach((doc) => {
        checkboxStates[doc.id] = doc.data().isChecked;
      });
      setCheckboxes(checkboxStates);
    });
    return () => unsub();
  }, []);

  return (
    <>
      <Grid container mt={2}>
        <Grid item xs={6} md={3}>
          <Typography
            variant="subtitle1"
            style={{ color: PgColors.redpg[500] }}
            sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
            align="left"
          >
            Acro
          </Typography>
        </Grid>
        <Grid item xs={2} md={1} display="flex" justifyContent="center">
          <CheckIcon sx={{ color: PgColors.redpg[500] }} />
        </Grid>
        <Grid item xs={2} md={1} display="flex" justifyContent="center">
          <ThumbUpIcon sx={{ color: PgColors.redpg[500] }} />
        </Grid>
        <Grid item xs={2} md={1} display="flex" justifyContent="center">
          <EmojiEventsIcon sx={{ color: PgColors.redpg[500] }} />
        </Grid>
        <Grid item md={3} display={{ xs: "none", md: "flex" }}>
          <Typography
            variant="subtitle1"
            style={{ color: PgColors.redpg[500] }}
            sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
            align="left"
          >
            Gymnastisch
          </Typography>
        </Grid>
        <Grid
          item
          md={1}
          display={{ xs: "none", md: "flex" }}
          justifyContent="center"
        >
          <CheckIcon sx={{ color: PgColors.redpg[500] }} />
        </Grid>
        <Grid
          item
          md={1}
          display={{ xs: "none", md: "flex" }}
          justifyContent="center"
        >
          <ThumbUpIcon sx={{ color: PgColors.redpg[500] }} />
        </Grid>
        <Grid
          item
          md={1}
          display={{ xs: "none", md: "flex" }}
          justifyContent="center"
        >
          <EmojiEventsIcon sx={{ color: PgColors.redpg[500] }} />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={6} md={3}>
          <Paper style={{ backgroundColor: PgColors.redpg[50], height: 35 }}>
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Handstand 180º
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={2} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId1"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId1"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId1"]: newCheckedState,
              });
              updateCheckboxState("checkboxId1", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={2} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId2"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId2"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId2"]: newCheckedState,
              });
              updateCheckboxState("checkboxId2", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={2} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId3"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId3"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId3"]: newCheckedState,
              });
              updateCheckboxState("checkboxId3", newCheckedState);
            }}
          />
        </Grid>
        <Grid item md={3} display={{ xs: "none", md: "block" }}>
          <Paper style={{ backgroundColor: PgColors.redpg[50], height: 35 }}>
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Loopsprong 135º 
            </Typography>
          </Paper>
        </Grid>
        <Grid
          item
          md={1}
          mt={-1}
          display={{ xs: "none", md: "flex" }}
          justifyContent="center"
        >
          <Checkbox
            checked={checkboxes["checkboxId4"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId4"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId4"]: newCheckedState,
              });
              updateCheckboxState("checkboxId4", newCheckedState);
            }}
          />
        </Grid>
        <Grid
          item
          md={1}
          mt={-1}
          display={{ xs: "none", md: "flex" }}
          justifyContent="center"
        >
          <Checkbox
            checked={checkboxes["checkboxId5"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId5"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId5"]: newCheckedState,
              });
              updateCheckboxState("checkboxId5", newCheckedState);
            }}
          />
        </Grid>
        <Grid
          item
          md={1}
          mt={-1}
          display={{ xs: "none", md: "flex" }}
          justifyContent="center"
        >
          <Checkbox
            checked={checkboxes["checkboxId6"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId6"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId6"]: newCheckedState,
              });
              updateCheckboxState("checkboxId6", newCheckedState);
            }}
          />
        </Grid>
      </Grid>
      <Grid container mt={-1}>
        <Grid item xs={6} md={3}>
          <Paper style={{ backgroundColor: PgColors.redpg[100], height: 35 }}>
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Radslag
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={2} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId7"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId7"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId7"]: newCheckedState,
              });
              updateCheckboxState("checkboxId7", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={2} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId8"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId8"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId8"]: newCheckedState,
              });
              updateCheckboxState("checkboxId8", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={2} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId9"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId9"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId9"]: newCheckedState,
              });
              updateCheckboxState("checkboxId9", newCheckedState);
            }}
          />
        </Grid>
        <Grid item md={3} display={{ xs: "none", md: "block" }}>
          <Paper style={{ backgroundColor: PgColors.redpg[50], height: 35 }}>
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Fouetté draai
            </Typography>
          </Paper>
        </Grid>
        <Grid
          item
          md={1}
          mt={-1}
          display={{ xs: "none", md: "flex" }}
          justifyContent="center"
        >
          <Checkbox
            checked={checkboxes["checkboxId10"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId10"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId3"]: newCheckedState,
              });
              updateCheckboxState("checkboxId10", newCheckedState);
            }}
          />
        </Grid>
        <Grid
          item
          md={1}
          mt={-1}
          display={{ xs: "none", md: "flex" }}
          justifyContent="center"
        >
          <Checkbox
            checked={checkboxes["checkboxId11"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId11"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId11"]: newCheckedState,
              });
              updateCheckboxState("checkboxId11", newCheckedState);
            }}
          />
        </Grid>
        <Grid
          item
          md={1}
          mt={-1}
          display={{ xs: "none", md: "flex" }}
          justifyContent="center"
        >
          <Checkbox
            checked={checkboxes["checkboxId12"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId12"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId12"]: newCheckedState,
              });
              updateCheckboxState("checkboxId12", newCheckedState);
            }}
          />
        </Grid>
      </Grid>
      <Grid container mt={-1}>
        <Grid item xs={6} md={3}>
          <Paper style={{ backgroundColor: PgColors.redpg[100], height: 35 }}>
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Handstand 2sec
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={2} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId13"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId13"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId13"]: newCheckedState,
              });
              updateCheckboxState("checkboxId13", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={2} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId14"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId14"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId14"]: newCheckedState,
              });
              updateCheckboxState("checkboxId14", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={2} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId15"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId15"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId15"]: newCheckedState,
              });
              updateCheckboxState("checkboxId15", newCheckedState);
            }}
          />
        </Grid>
        <Grid item md={3} display={{ xs: "none", md: "block" }}>
          <Paper style={{ backgroundColor: PgColors.redpg[50], height: 35 }}>
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Spagaatsprong 135º
            </Typography>
          </Paper>
        </Grid>
        <Grid
          item
          md={1}
          mt={-1}
          display={{ xs: "none", md: "flex" }}
          justifyContent="center"
        >
          <Checkbox
            checked={checkboxes["checkboxId16"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId16"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId16"]: newCheckedState,
              });
              updateCheckboxState("checkboxId16", newCheckedState);
            }}
          />
        </Grid>
        <Grid
          item
          md={1}
          mt={-1}
          display={{ xs: "none", md: "flex" }}
          justifyContent="center"
        >
          <Checkbox
            checked={checkboxes["checkboxId17"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId17"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId17"]: newCheckedState,
              });
              updateCheckboxState("checkboxId17", newCheckedState);
            }}
          />
        </Grid>
        <Grid
          item
          md={1}
          mt={-1}
          display={{ xs: "none", md: "flex" }}
          justifyContent="center"
        >
          <Checkbox
            checked={checkboxes["checkboxId18"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId18"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId18"]: newCheckedState,
              });
              updateCheckboxState("checkboxId18", newCheckedState);
            }}
          />
        </Grid>
      </Grid>
      <Grid container mt={-1}>
        <Grid item xs={6} md={3}>
          <Paper style={{ backgroundColor: PgColors.redpg[100], height: 35 }}>
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Boogje ao
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={2} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId19"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId19"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId19"]: newCheckedState,
              });
              updateCheckboxState("checkboxId19", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={2} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId20"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId20"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId20"]: newCheckedState,
              });
              updateCheckboxState("checkboxId20", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={2} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId21"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId21"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId21"]: newCheckedState,
              });
              updateCheckboxState("checkboxId21", newCheckedState);
            }}
          />
        </Grid>
        <Grid item md={3} display={{ xs: "none", md: "block" }}>
          <Paper style={{ backgroundColor: PgColors.redpg[50], height: 35 }}>
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Sisonne 135º 
            </Typography>
          </Paper>
        </Grid>
        <Grid
          item
          md={1}
          mt={-1}
          display={{ xs: "none", md: "flex" }}
          justifyContent="center"
        >
          <Checkbox
            checked={checkboxes["checkboxId22"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId22"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId22"]: newCheckedState,
              });
              updateCheckboxState("checkboxId22", newCheckedState);
            }}
          />
        </Grid>
        <Grid
          item
          md={1}
          mt={-1}
          display={{ xs: "none", md: "flex" }}
          justifyContent="center"
        >
          <Checkbox
            checked={checkboxes["checkboxId23"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId23"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId23"]: newCheckedState,
              });
              updateCheckboxState("checkboxId23", newCheckedState);
            }}
          />
        </Grid>
        <Grid
          item
          md={1}
          mt={-1}
          display={{ xs: "none", md: "flex" }}
          justifyContent="center"
        >
          <Checkbox
            checked={checkboxes["checkboxId24"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId24"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId24"]: newCheckedState,
              });
              updateCheckboxState("checkboxId24", newCheckedState);
            }}
          />
        </Grid>
      </Grid>
      <Grid container mt={-1}>
        <Grid item xs={6} md={3}>
          <Paper style={{ backgroundColor: PgColors.redpg[100], height: 35 }}>
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Zijwaartse handstand
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={2} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId25"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId25"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId25"]: newCheckedState,
              });
              updateCheckboxState("checkboxId25", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={2} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId26"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId24"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId26"]: newCheckedState,
              });
              updateCheckboxState("checkboxId26", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={2} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId27"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId27"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId27"]: newCheckedState,
              });
              updateCheckboxState("checkboxId27", newCheckedState);
            }}
          />
        </Grid>
        <Grid item md={3} display={{ xs: "none", md: "block" }}>
          <Paper style={{ backgroundColor: PgColors.redpg[50], height: 35 }}>
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Kattesprong 
            </Typography>
          </Paper>
        </Grid>
        <Grid
          item
          md={1}
          mt={-1}
          display={{ xs: "none", md: "flex" }}
          justifyContent="center"
        >
          <Checkbox
            checked={checkboxes["checkboxId28"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId28"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId28"]: newCheckedState,
              });
              updateCheckboxState("checkboxId28", newCheckedState);
            }}
          />
        </Grid>
        <Grid
          item
          md={1}
          mt={-1}
          display={{ xs: "none", md: "flex" }}
          justifyContent="center"
        >
          <Checkbox
            checked={checkboxes["checkboxId29"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId29"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId29"]: newCheckedState,
              });
              updateCheckboxState("checkboxId29", newCheckedState);
            }}
          />
        </Grid>
        <Grid
          item
          md={1}
          mt={-1}
          display={{ xs: "none", md: "flex" }}
          justifyContent="center"
        >
          <Checkbox
            checked={checkboxes["checkboxId30"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId30"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId30"]: newCheckedState,
              });
              updateCheckboxState("checkboxId30", newCheckedState);
            }}
          />
        </Grid>
      </Grid>
      <Grid container mt={-1}>
        <Grid item xs={6} md={3}>
          <Paper style={{ backgroundColor: PgColors.redpg[200], height: 35 }}>
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Losse rol
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={2} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId31"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId31"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId31"]: newCheckedState,
              });
              updateCheckboxState("checkboxId31", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={2} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId32"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId32"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId32"]: newCheckedState,
              });
              updateCheckboxState("checkboxId32", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={2} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId33"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId33"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId33"]: newCheckedState,
              });
              updateCheckboxState("checkboxId33", newCheckedState);
            }}
          />
        </Grid>
        <Grid item md={3} display={{ xs: "none", md: "block" }}>
          <Paper style={{ backgroundColor: PgColors.redpg[50], height: 35 }}>
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Streksprong
            </Typography>
          </Paper>
        </Grid>
        <Grid
          item
          md={1}
          mt={-1}
          display={{ xs: "none", md: "flex" }}
          justifyContent="center"
        >
          <Checkbox
            checked={checkboxes["checkboxId34"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId34"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId34"]: newCheckedState,
              });
              updateCheckboxState("checkboxId34", newCheckedState);
            }}
          />
        </Grid>
        <Grid
          item
          md={1}
          mt={-1}
          display={{ xs: "none", md: "flex" }}
          justifyContent="center"
        >
          <Checkbox
            checked={checkboxes["checkboxId35"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId35"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId35"]: newCheckedState,
              });
              updateCheckboxState("checkboxId35", newCheckedState);
            }}
          />
        </Grid>
        <Grid
          item
          md={1}
          mt={-1}
          display={{ xs: "none", md: "flex" }}
          justifyContent="center"
        >
          <Checkbox
            checked={checkboxes["checkboxId36"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId36"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId36"]: newCheckedState,
              });
              updateCheckboxState("checkboxId36", newCheckedState);
            }}
          />
        </Grid>
      </Grid>
      <Grid container mt={-1}>
        <Grid item xs={6} md={3}>
          <Paper style={{ backgroundColor: PgColors.redpg[200], height: 35 }}>
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Handstanddoorrol
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={2} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId37"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId37"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId37"]: newCheckedState,
              });
              updateCheckboxState("checkboxId37", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={2} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId38"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId38"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId38"]: newCheckedState,
              });
              updateCheckboxState("checkboxId38", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={2} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId39"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId39"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId39"]: newCheckedState,
              });
              updateCheckboxState("checkboxId39", newCheckedState);
            }}
          />
        </Grid>
        <Grid item md={3} display={{ xs: "none", md: "block" }}>
          <Paper style={{ backgroundColor: PgColors.redpg[100], height: 35 }}>
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Hurksprong
            </Typography>
          </Paper>
        </Grid>
        <Grid
          item
          md={1}
          mt={-1}
          display={{ xs: "none", md: "flex" }}
          justifyContent="center"
        >
          <Checkbox
            checked={checkboxes["checkboxId40"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId40"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId40"]: newCheckedState,
              });
              updateCheckboxState("checkboxId40", newCheckedState);
            }}
          />
        </Grid>
        <Grid
          item
          md={1}
          mt={-1}
          display={{ xs: "none", md: "flex" }}
          justifyContent="center"
        >
          <Checkbox
            checked={checkboxes["checkboxId41"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId41"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId41"]: newCheckedState,
              });
              updateCheckboxState("checkboxId41", newCheckedState);
            }}
          />
        </Grid>
        <Grid
          item
          md={1}
          mt={-1}
          display={{ xs: "none", md: "flex" }}
          justifyContent="center"
        >
          <Checkbox
            checked={checkboxes["checkboxId42"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId42"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId42"]: newCheckedState,
              });
              updateCheckboxState("checkboxId42", newCheckedState);
            }}
          />
        </Grid>
      </Grid>
      <Grid container mt={-1}>
        <Grid item xs={6} md={3}>
          <Paper style={{ backgroundColor: PgColors.redpg[200], height: 35 }}>
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Rol ao
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={2} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId43"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId43"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId43"]: newCheckedState,
              });
              updateCheckboxState("checkboxId43", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={2} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId44"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId44"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId44"]: newCheckedState,
              });
              updateCheckboxState("checkboxId44", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={2} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId45"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId45"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId45"]: newCheckedState,
              });
              updateCheckboxState("checkboxId45", newCheckedState);
            }}
          />
        </Grid>
        <Grid item md={3} display={{ xs: "none", md: "block" }}>
          <Paper style={{ backgroundColor: PgColors.redpg[100], height: 35 }}>
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              1/1 pirouette
            </Typography>
          </Paper>
        </Grid>
        <Grid
          item
          md={1}
          mt={-1}
          display={{ xs: "none", md: "flex" }}
          justifyContent="center"
        >
          <Checkbox
            checked={checkboxes["checkboxId46"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId46"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId46"]: newCheckedState,
              });
              updateCheckboxState("checkboxId46", newCheckedState);
            }}
          />
        </Grid>
        <Grid
          item
          md={1}
          mt={-1}
          display={{ xs: "none", md: "flex" }}
          justifyContent="center"
        >
          <Checkbox
            checked={checkboxes["checkboxId47"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId47"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId47"]: newCheckedState,
              });
              updateCheckboxState("checkboxId47", newCheckedState);
            }}
          />
        </Grid>
        <Grid
          item
          md={1}
          mt={-1}
          display={{ xs: "none", md: "flex" }}
          justifyContent="center"
        >
          <Checkbox
            checked={checkboxes["checkboxId48"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId48"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId48"]: newCheckedState,
              });
              updateCheckboxState("checkboxId48", newCheckedState);
            }}
          />
        </Grid>
      </Grid>
      <Grid container mt={-1}>
        <Grid item xs={6} md={3}>
          <Paper style={{ backgroundColor: PgColors.redpg[400], height: 35 }}>
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Radslag - radslag
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={2} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId49"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId49"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId49"]: newCheckedState,
              });
              updateCheckboxState("checkboxId49", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={2} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId50"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId50"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId50"]: newCheckedState,
              });
              updateCheckboxState("checkboxId50", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={2} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId51"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId51"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId51"]: newCheckedState,
              });
              updateCheckboxState("checkboxId51", newCheckedState);
            }}
          />
        </Grid>
        <Grid item md={3} display={{ xs: "none", md: "block" }}>
          <Paper style={{ backgroundColor: PgColors.redpg[200], height: 35 }}>
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Spagaatsprong 180º
            </Typography>
          </Paper>
        </Grid>
        <Grid
          item
          md={1}
          mt={-1}
          display={{ xs: "none", md: "flex" }}
          justifyContent="center"
        >
          <Checkbox
            checked={checkboxes["checkboxId70"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId70"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId70"]: newCheckedState,
              });
              updateCheckboxState("checkboxId70", newCheckedState);
            }}
          />
        </Grid>
        <Grid
          item
          md={1}
          mt={-1}
          display={{ xs: "none", md: "flex" }}
          justifyContent="center"
        >
          <Checkbox
            checked={checkboxes["checkboxId71"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId71"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId71"]: newCheckedState,
              });
              updateCheckboxState("checkboxId71", newCheckedState);
            }}
          />
        </Grid>
        <Grid
          item
          md={1}
          mt={-1}
          display={{ xs: "none", md: "flex" }}
          justifyContent="center"
        >
          <Checkbox
            checked={checkboxes["checkboxId72"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId72"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId72"]: newCheckedState,
              });
              updateCheckboxState("checkboxId72", newCheckedState);
            }}
          />
        </Grid>
      </Grid>
      <Grid container mt={-1}>
        <Grid item xs={6} md={3}>
          <Paper style={{ backgroundColor: PgColors.redpg[400], height: 35 }}>
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Arabier
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={2} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId52"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId52"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId52"]: newCheckedState,
              });
              updateCheckboxState("checkboxId52", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={2} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId53"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId53"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId53"]: newCheckedState,
              });
              updateCheckboxState("checkboxId53", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={2} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId54"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId54"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId54"]: newCheckedState,
              });
              updateCheckboxState("checkboxId54", newCheckedState);
            }}
          />
        </Grid>
        <Grid item md={3} display={{ xs: "none", md: "block" }}>
          <Paper style={{ backgroundColor: PgColors.redpg[200], height: 35 }}>
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Sisonne 180º
            </Typography>
          </Paper>
        </Grid>
        <Grid
          item
          md={1}
          mt={-1}
          display={{ xs: "none", md: "flex" }}
          justifyContent="center"
        >
          <Checkbox
            checked={checkboxes["checkboxId73"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId73"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId73"]: newCheckedState,
              });
              updateCheckboxState("checkboxId73", newCheckedState);
            }}
          />
        </Grid>
        <Grid
          item
          md={1}
          mt={-1}
          display={{ xs: "none", md: "flex" }}
          justifyContent="center"
        >
          <Checkbox
            checked={checkboxes["checkboxId74"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId74"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId74"]: newCheckedState,
              });
              updateCheckboxState("checkboxId74", newCheckedState);
            }}
          />
        </Grid>
        <Grid
          item
          md={1}
          mt={-1}
          display={{ xs: "none", md: "flex" }}
          justifyContent="center"
        >
          <Checkbox
            checked={checkboxes["checkboxId75"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId75"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId75"]: newCheckedState,
              });
              updateCheckboxState("checkboxId75", newCheckedState);
            }}
          />
        </Grid>
      </Grid>
      <Grid container mt={-1}>
        <Grid item xs={6} md={3}>
          <Paper style={{ backgroundColor: PgColors.redpg[400], height: 35 }}>
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Loop flikflak
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={2} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId55"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId55"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId55"]: newCheckedState,
              });
              updateCheckboxState("checkboxId55", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={2} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId56"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId56"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId56"]: newCheckedState,
              });
              updateCheckboxState("checkboxId56", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={2} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId57"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId57"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId57"]: newCheckedState,
              });
              updateCheckboxState("checkboxId57", newCheckedState);
            }}
          />
        </Grid>
        <Grid item md={3} display={{ xs: "none", md: "block" }}>
          <Paper style={{ backgroundColor: PgColors.redpg[200], height: 35 }}>
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Loopsprong 180º
            </Typography>
          </Paper>
        </Grid>
        <Grid
          item
          md={1}
          mt={-1}
          display={{ xs: "none", md: "flex" }}
          justifyContent="center"
        >
          <Checkbox
            checked={checkboxes["checkboxId76"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId76"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId76"]: newCheckedState,
              });
              updateCheckboxState("checkboxId76", newCheckedState);
            }}
          />
        </Grid>
        <Grid
          item
          md={1}
          mt={-1}
          display={{ xs: "none", md: "flex" }}
          justifyContent="center"
        >
          <Checkbox
            checked={checkboxes["checkboxId77"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId77"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId77"]: newCheckedState,
              });
              updateCheckboxState("checkboxId77", newCheckedState);
            }}
          />
        </Grid>
        <Grid
          item
          md={1}
          mt={-1}
          display={{ xs: "none", md: "flex" }}
          justifyContent="center"
        >
          <Checkbox
            checked={checkboxes["checkboxId78"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId78"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId78"]: newCheckedState,
              });
              updateCheckboxState("checkboxId78", newCheckedState);
            }}
          />
        </Grid>
      </Grid>
      <Grid container mt={-1}>
        <Grid item xs={6} md={3}>
          <Paper style={{ backgroundColor: PgColors.redpg[400], height: 35 }}>
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Overslag
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={2} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId58"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId58"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId58"]: newCheckedState,
              });
              updateCheckboxState("checkboxId58", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={2} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId59"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId59"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId59"]: newCheckedState,
              });
              updateCheckboxState("checkboxId59", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={2} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId60"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId60"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId60"]: newCheckedState,
              });
              updateCheckboxState("checkboxId60", newCheckedState);
            }}
          />
        </Grid>
        <Grid item md={3} display={{ xs: "none", md: "block" }}>
          <Paper style={{ backgroundColor: PgColors.redpg[200], height: 35 }}>
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Kattesprong ½
            </Typography>
          </Paper>
        </Grid>
        <Grid
          item
          md={1}
          mt={-1}
          display={{ xs: "none", md: "flex" }}
          justifyContent="center"
        >
          <Checkbox
            checked={checkboxes["checkboxId79"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId79"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId79"]: newCheckedState,
              });
              updateCheckboxState("checkboxId79", newCheckedState);
            }}
          />
        </Grid>
        <Grid
          item
          md={1}
          mt={-1}
          display={{ xs: "none", md: "flex" }}
          justifyContent="center"
        >
          <Checkbox
            checked={checkboxes["checkboxId80"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId80"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId80"]: newCheckedState,
              });
              updateCheckboxState("checkboxId80", newCheckedState);
            }}
          />
        </Grid>
        <Grid
          item
          md={1}
          mt={-1}
          display={{ xs: "none", md: "flex" }}
          justifyContent="center"
        >
          <Checkbox
            checked={checkboxes["checkboxId81"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId81"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId81"]: newCheckedState,
              });
              updateCheckboxState("checkboxId81", newCheckedState);
            }}
          />
        </Grid>
      </Grid>
      <Grid container mt={-1}>
        <Grid item xs={6} md={3}>
          <Paper style={{ backgroundColor: PgColors.redpg[400], height: 35 }}>
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Stutrol
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={2} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId61"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId61"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId61"]: newCheckedState,
              });
              updateCheckboxState("checkboxId61", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={2} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId62"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId62"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId62"]: newCheckedState,
              });
              updateCheckboxState("checkboxId62", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={2} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId63"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId63"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId63"]: newCheckedState,
              });
              updateCheckboxState("checkboxId63", newCheckedState);
            }}
          />
        </Grid>
        <Grid item md={3} display={{ xs: "none", md: "block" }}>
          <Paper style={{ backgroundColor: PgColors.redpg[400], height: 35 }}>
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Wisselspagaat
            </Typography>
          </Paper>
        </Grid>
        <Grid
          item
          md={1}
          mt={-1}
          display={{ xs: "none", md: "flex" }}
          justifyContent="center"
        >
          <Checkbox
            checked={checkboxes["checkboxId82"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId82"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId82"]: newCheckedState,
              });
              updateCheckboxState("checkboxId82", newCheckedState);
            }}
          />
        </Grid>
        <Grid
          item
          md={1}
          mt={-1}
          display={{ xs: "none", md: "flex" }}
          justifyContent="center"
        >
          <Checkbox
            checked={checkboxes["checkboxId83"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId83"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId83"]: newCheckedState,
              });
              updateCheckboxState("checkboxId83", newCheckedState);
            }}
          />
        </Grid>
        <Grid
          item
          md={1}
          mt={-1}
          display={{ xs: "none", md: "flex" }}
          justifyContent="center"
        >
          <Checkbox
            checked={checkboxes["checkboxId84"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId84"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId84"]: newCheckedState,
              });
              updateCheckboxState("checkboxId84", newCheckedState);
            }}
          />
        </Grid>
      </Grid>
      <Grid container sx={{ mt: { xs: 1, md: -1 } }}>
        <Grid item xs={6} md={3}>
          <Typography
            variant="subtitle1"
            style={{ color: PgColors.redpg[500] }}
            sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
            align="left"
          >
            Op en Afsprong
          </Typography>
        </Grid>
        <Grid item xs={2} md={1} display="flex" justifyContent="center">
          <CheckIcon sx={{ color: PgColors.redpg[500] }} />
        </Grid>
        <Grid item xs={2} md={1} display="flex" justifyContent="center">
          <ThumbUpIcon sx={{ color: PgColors.redpg[500] }} />
        </Grid>
        <Grid item xs={2} display="flex" md={1} justifyContent="center">
          <EmojiEventsIcon sx={{ color: PgColors.redpg[500] }} />
        </Grid>
        <Grid item md={3} display={{ xs: "none", md: "block" }}>
          <Paper style={{ backgroundColor: PgColors.redpg[400], height: 35 }}>
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Pirouette 1½
            </Typography>
          </Paper>
        </Grid>
        <Grid
          item
          md={1}
          mt={-1}
          display={{ xs: "none", md: "flex" }}
          justifyContent="center"
        >
          <Checkbox
            checked={checkboxes["checkboxId85"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId85"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId85"]: newCheckedState,
              });
              updateCheckboxState("checkboxId85", newCheckedState);
            }}
          />
        </Grid>
        <Grid
          item
          md={1}
          mt={-1}
          display={{ xs: "none", md: "flex" }}
          justifyContent="center"
        >
          <Checkbox
            checked={checkboxes["checkboxId86"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId86"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId86"]: newCheckedState,
              });
              updateCheckboxState("checkboxId86", newCheckedState);
            }}
          />
        </Grid>
        <Grid
          item
          md={1}
          mt={-1}
          display={{ xs: "none", md: "flex" }}
          justifyContent="center"
        >
          <Checkbox
            checked={checkboxes["checkboxId87"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId87"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId87"]: newCheckedState,
              });
              updateCheckboxState("checkboxId87", newCheckedState);
            }}
          />
        </Grid>
      </Grid>
      <Grid container sx={{ mt: { xs: 0, md: -1 } }}>
        <Grid item xs={6} md={3}>
          <Paper style={{ backgroundColor: PgColors.redpg[50], height: 35 }}>
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              TA-opsprong
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={2} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId64"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId64"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId64"]: newCheckedState,
              });
              updateCheckboxState("checkboxId64", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={2} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId65"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId65"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId65"]: newCheckedState,
              });
              updateCheckboxState("checkboxId65", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={2} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId66"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId66"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId66"]: newCheckedState,
              });
              updateCheckboxState("checkboxId66", newCheckedState);
            }}
          />
        </Grid>
        <Grid item md={3} display={{ xs: "none", md: "block" }}>
          <Paper style={{ backgroundColor: PgColors.redpg[400], height: 35 }}>
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Hurkpirouette
            </Typography>
          </Paper>
        </Grid>
        <Grid
          item
          md={1}
          mt={-1}
          display={{ xs: "none", md: "flex" }}
          justifyContent="center"
        >
          <Checkbox
            checked={checkboxes["checkboxId88"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId88"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId88"]: newCheckedState,
              });
              updateCheckboxState("checkboxId88", newCheckedState);
            }}
          />
        </Grid>
        <Grid
          item
          md={1}
          mt={-1}
          display={{ xs: "none", md: "flex" }}
          justifyContent="center"
        >
          <Checkbox
            checked={checkboxes["checkboxId89"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId89"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId89"]: newCheckedState,
              });
              updateCheckboxState("checkboxId89", newCheckedState);
            }}
          />
        </Grid>
        <Grid
          item
          md={1}
          mt={-1}
          display={{ xs: "none", md: "flex" }}
          justifyContent="center"
        >
          <Checkbox
            checked={checkboxes["checkboxId90"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId90"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId90"]: newCheckedState,
              });
              updateCheckboxState("checkboxId90", newCheckedState);
            }}
          />
        </Grid>
      </Grid>
      <Grid container mt={-1}>
        <Grid item xs={6} md={3}>
          <Paper style={{ backgroundColor: PgColors.redpg[50], height: 35 }}>
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              TA-afsprong
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={2} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId67"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId67"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId67"]: newCheckedState,
              });
              updateCheckboxState("checkboxId67", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={2} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId68"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId68"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId68"]: newCheckedState,
              });
              updateCheckboxState("checkboxId68", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={2} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId69"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId69"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId69"]: newCheckedState,
              });
              updateCheckboxState("checkboxId69", newCheckedState);
            }}
          />
        </Grid>
        <Grid item md={3} display={{ xs: "none", md: "block" }}>
          <Paper style={{ backgroundColor: PgColors.redpg[400], height: 35 }}>
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Hurksprong ½
            </Typography>
          </Paper>
        </Grid>
        <Grid
          item
          md={1}
          mt={-1}
          display={{ xs: "none", md: "flex" }}
          justifyContent="center"
        >
          <Checkbox
            checked={checkboxes["checkboxId91"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId91"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId91"]: newCheckedState,
              });
              updateCheckboxState("checkboxId91", newCheckedState);
            }}
          />
        </Grid>
        <Grid
          item
          md={1}
          mt={-1}
          display={{ xs: "none", md: "flex" }}
          justifyContent="center"
        >
          <Checkbox
            checked={checkboxes["checkboxId92"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId92"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId92"]: newCheckedState,
              });
              updateCheckboxState("checkboxId92", newCheckedState);
            }}
          />
        </Grid>
        <Grid
          item
          md={1}
          mt={-1}
          display={{ xs: "none", md: "flex" }}
          justifyContent="center"
        >
          <Checkbox
            checked={checkboxes["checkboxId93"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId93"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId93"]: newCheckedState,
              });
              updateCheckboxState("checkboxId93", newCheckedState);
            }}
          />
        </Grid>
      </Grid>
      <Grid container mt={-1}>
        <Grid item xs={6} md={3}>
          <Paper style={{ backgroundColor: PgColors.redpg[100], height: 35 }}>
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              A-opsprong
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={2} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId94"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId94"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId94"]: newCheckedState,
              });
              updateCheckboxState("checkboxId94", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={2} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId95"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId95"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId95"]: newCheckedState,
              });
              updateCheckboxState("checkboxId95", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={2} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId96"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId96"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId96"]: newCheckedState,
              });
              updateCheckboxState("checkboxId96", newCheckedState);
            }}
          />
        </Grid>
      </Grid>
      <Grid container mt={-1}>
        <Grid item xs={6} md={3}>
          <Paper style={{ backgroundColor: PgColors.redpg[100], height: 35 }}>
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              A-afsprong
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={2} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId97"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId97"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId97"]: newCheckedState,
              });
              updateCheckboxState("checkboxId97", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={2} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId98"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId98"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId98"]: newCheckedState,
              });
              updateCheckboxState("checkboxId98", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={2} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId99"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId99"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId99"]: newCheckedState,
              });
              updateCheckboxState("checkboxId99", newCheckedState);
            }}
          />
        </Grid>
      </Grid>
      <Grid container mt={-1}>
        <Grid item xs={6} md={3}>
          <Paper style={{ backgroundColor: PgColors.redpg[200], height: 35 }}>
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              B-opsprong
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={2} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId100"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId100"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId100"]: newCheckedState,
              });
              updateCheckboxState("checkboxId100", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={2} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId101"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId101"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId101"]: newCheckedState,
              });
              updateCheckboxState("checkboxId101", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={2} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId102"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId102"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId102"]: newCheckedState,
              });
              updateCheckboxState("checkboxId102", newCheckedState);
            }}
          />
        </Grid>
      </Grid>
      <Grid container mt={-1}>
        <Grid item xs={6} md={3}>
          <Paper style={{ backgroundColor: PgColors.redpg[200], height: 35 }}>
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              B-afsprong
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={2} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId103"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId103"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId103"]: newCheckedState,
              });
              updateCheckboxState("checkboxId103", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={2} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId104"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId104"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId104"]: newCheckedState,
              });
              updateCheckboxState("checkboxId104", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={2} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId105"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId105"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId105"]: newCheckedState,
              });
              updateCheckboxState("checkboxId105", newCheckedState);
            }}
          />
        </Grid>
      </Grid>
      <Grid container mt={2}>
        <Grid item xs={6} display={{ xs: "flex", md: "none" }}>
          <Typography
            variant="subtitle1"
            style={{ color: PgColors.redpg[500] }}
            sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
            align="left"
          >
            Gymnastisch
          </Typography>
        </Grid>
        <Grid
          item
          xs={2}
          display={{ xs: "flex", md: "none" }}
          justifyContent="center"
        >
          <CheckIcon sx={{ color: PgColors.redpg[500] }} />
        </Grid>
        <Grid
          item
          xs={2}
          display={{ xs: "flex", md: "none" }}
          justifyContent="center"
        >
          <ThumbUpIcon sx={{ color: PgColors.redpg[500] }} />
        </Grid>
        <Grid
          item
          xs={2}
          display={{ xs: "flex", md: "none" }}
          justifyContent="center"
        >
          <EmojiEventsIcon sx={{ color: PgColors.redpg[500] }} />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={6} display={{ xs: "block", md: "none" }}>
          <Paper style={{ backgroundColor: PgColors.redpg[50], height: 35 }}>
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Loopsprong 135º 
            </Typography>
          </Paper>
        </Grid>
        <Grid
          item
          xs={2}
          mt={-1}
          display={{ xs: "block", md: "none" }}
          justifyContent="center"
        >
          <Checkbox
            checked={checkboxes["checkboxId4"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId4"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId4"]: newCheckedState,
              });
              updateCheckboxState("checkboxId4", newCheckedState);
            }}
          />
        </Grid>
        <Grid
          item
          xs={2}
          mt={-1}
          display={{ xs: "block", md: "none" }}
          justifyContent="center"
        >
          <Checkbox
            checked={checkboxes["checkboxId5"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId5"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId5"]: newCheckedState,
              });
              updateCheckboxState("checkboxId5", newCheckedState);
            }}
          />
        </Grid>
        <Grid
          item
          xs={1}
          mt={-1}
          display={{ xs: "block", md: "none" }}
          justifyContent="center"
        >
          <Checkbox
            checked={checkboxes["checkboxId6"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId6"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId6"]: newCheckedState,
              });
              updateCheckboxState("checkboxId6", newCheckedState);
            }}
          />
        </Grid>
      </Grid>
      <Grid container mt={-1}>
        <Grid item xs={6} display={{ xs: "block", md: "none" }}>
          <Paper style={{ backgroundColor: PgColors.redpg[50], height: 35 }}>
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Fouetté draai
            </Typography>
          </Paper>
        </Grid>
        <Grid
          item
          xs={2}
          mt={-1}
          display={{ xs: "flex", md: "none" }}
          justifyContent="center"
        >
          <Checkbox
            checked={checkboxes["checkboxId10"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId10"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId3"]: newCheckedState,
              });
              updateCheckboxState("checkboxId10", newCheckedState);
            }}
          />
        </Grid>
        <Grid
          item
          xs={2}
          mt={-1}
          display={{ xs: "flex", md: "none" }}
          justifyContent="center"
        >
          <Checkbox
            checked={checkboxes["checkboxId11"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId11"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId11"]: newCheckedState,
              });
              updateCheckboxState("checkboxId11", newCheckedState);
            }}
          />
        </Grid>
        <Grid
          item
          xs={2}
          mt={-1}
          display={{ xs: "flex", md: "none" }}
          justifyContent="center"
        >
          <Checkbox
            checked={checkboxes["checkboxId12"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId12"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId12"]: newCheckedState,
              });
              updateCheckboxState("checkboxId12", newCheckedState);
            }}
          />
        </Grid>
      </Grid>
      <Grid container mt={-1}>
        <Grid item xs={6} display={{ xs: "block", md: "none" }}>
          <Paper style={{ backgroundColor: PgColors.redpg[50], height: 35 }}>
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Spagaatsprong 135º
            </Typography>
          </Paper>
        </Grid>
        <Grid
          item
          xs={2}
          mt={-1}
          display={{ xs: "flex", md: "none" }}
          justifyContent="center"
        >
          <Checkbox
            checked={checkboxes["checkboxId16"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId16"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId16"]: newCheckedState,
              });
              updateCheckboxState("checkboxId16", newCheckedState);
            }}
          />
        </Grid>
        <Grid
          item
          xs={2}
          mt={-1}
          display={{ xs: "flex", md: "none" }}
          justifyContent="center"
        >
          <Checkbox
            checked={checkboxes["checkboxId17"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId17"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId17"]: newCheckedState,
              });
              updateCheckboxState("checkboxId17", newCheckedState);
            }}
          />
        </Grid>
        <Grid
          item
          xs={2}
          mt={-1}
          display={{ xs: "flex", md: "none" }}
          justifyContent="center"
        >
          <Checkbox
            checked={checkboxes["checkboxId18"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId18"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId18"]: newCheckedState,
              });
              updateCheckboxState("checkboxId18", newCheckedState);
            }}
          />
        </Grid>
      </Grid>
      <Grid container mt={-1}>
        <Grid item xs={6} display={{ xs: "block", md: "none" }}>
          <Paper style={{ backgroundColor: PgColors.redpg[50], height: 35 }}>
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Sisonne 135º 
            </Typography>
          </Paper>
        </Grid>
        <Grid
          item
          xs={2}
          mt={-1}
          display={{ xs: "flex", md: "none" }}
          justifyContent="center"
        >
          <Checkbox
            checked={checkboxes["checkboxId22"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId22"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId22"]: newCheckedState,
              });
              updateCheckboxState("checkboxId22", newCheckedState);
            }}
          />
        </Grid>
        <Grid
          item
          xs={2}
          mt={-1}
          display={{ xs: "flex", md: "none" }}
          justifyContent="center"
        >
          <Checkbox
            checked={checkboxes["checkboxId23"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId23"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId23"]: newCheckedState,
              });
              updateCheckboxState("checkboxId23", newCheckedState);
            }}
          />
        </Grid>
        <Grid
          item
          xs={2}
          mt={-1}
          display={{ xs: "flex", md: "none" }}
          justifyContent="center"
        >
          <Checkbox
            checked={checkboxes["checkboxId24"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId24"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId24"]: newCheckedState,
              });
              updateCheckboxState("checkboxId24", newCheckedState);
            }}
          />
        </Grid>
      </Grid>
      <Grid container mt={-1}>
        <Grid item xs={6} display={{ xs: "block", md: "none" }}>
          <Paper style={{ backgroundColor: PgColors.redpg[50], height: 35 }}>
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Kattesprong
            </Typography>
          </Paper>
        </Grid>
        <Grid
          item
          xs={2}
          mt={-1}
          display={{ xs: "flex", md: "none" }}
          justifyContent="center"
        >
          <Checkbox
            checked={checkboxes["checkboxId28"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId28"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId28"]: newCheckedState,
              });
              updateCheckboxState("checkboxId28", newCheckedState);
            }}
          />
        </Grid>
        <Grid
          item
          xs={2}
          mt={-1}
          display={{ xs: "flex", md: "none" }}
          justifyContent="center"
        >
          <Checkbox
            checked={checkboxes["checkboxId29"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId29"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId29"]: newCheckedState,
              });
              updateCheckboxState("checkboxId29", newCheckedState);
            }}
          />
        </Grid>
        <Grid
          item
          xs={2}
          mt={-1}
          display={{ xs: "flex", md: "none" }}
          justifyContent="center"
        >
          <Checkbox
            checked={checkboxes["checkboxId30"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId30"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId30"]: newCheckedState,
              });
              updateCheckboxState("checkboxId30", newCheckedState);
            }}
          />
        </Grid>
      </Grid>
      <Grid container mt={-1}>
        <Grid item xs={6} display={{ xs: "block", md: "none" }}>
          <Paper style={{ backgroundColor: PgColors.redpg[50], height: 35 }}>
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Streksprong
            </Typography>
          </Paper>
        </Grid>
        <Grid
          item
          xs={2}
          mt={-1}
          display={{ xs: "flex", md: "none" }}
          justifyContent="center"
        >
          <Checkbox
            checked={checkboxes["checkboxId34"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId34"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId34"]: newCheckedState,
              });
              updateCheckboxState("checkboxId34", newCheckedState);
            }}
          />
        </Grid>
        <Grid
          item
          xs={2}
          mt={-1}
          display={{ xs: "flex", md: "none" }}
          justifyContent="center"
        >
          <Checkbox
            checked={checkboxes["checkboxId35"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId35"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId35"]: newCheckedState,
              });
              updateCheckboxState("checkboxId35", newCheckedState);
            }}
          />
        </Grid>
        <Grid
          item
          xs={2}
          mt={-1}
          display={{ xs: "flex", md: "none" }}
          justifyContent="center"
        >
          <Checkbox
            checked={checkboxes["checkboxId36"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId36"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId36"]: newCheckedState,
              });
              updateCheckboxState("checkboxId36", newCheckedState);
            }}
          />
        </Grid>
      </Grid>
      <Grid container mt={-1}>
        <Grid item xs={6} display={{ xs: "block", md: "none" }}>
          <Paper style={{ backgroundColor: PgColors.redpg[100], height: 35 }}>
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Hurksprong
            </Typography>
          </Paper>
        </Grid>
        <Grid
          item
          xs={2}
          mt={-1}
          display={{ xs: "flex", md: "none" }}
          justifyContent="center"
        >
          <Checkbox
            checked={checkboxes["checkboxId40"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId40"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId40"]: newCheckedState,
              });
              updateCheckboxState("checkboxId40", newCheckedState);
            }}
          />
        </Grid>
        <Grid
          item
          xs={2}
          mt={-1}
          display={{ xs: "flex", md: "none" }}
          justifyContent="center"
        >
          <Checkbox
            checked={checkboxes["checkboxId41"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId41"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId41"]: newCheckedState,
              });
              updateCheckboxState("checkboxId41", newCheckedState);
            }}
          />
        </Grid>
        <Grid
          item
          xs={2}
          mt={-1}
          display={{ xs: "flex", md: "none" }}
          justifyContent="center"
        >
          <Checkbox
            checked={checkboxes["checkboxId42"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId42"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId42"]: newCheckedState,
              });
              updateCheckboxState("checkboxId42", newCheckedState);
            }}
          />
        </Grid>
      </Grid>
      <Grid container mt={-1}>
        <Grid item xs={6} display={{ xs: "block", md: "none" }}>
          <Paper style={{ backgroundColor: PgColors.redpg[100], height: 35 }}>
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              1/1 pirouette
            </Typography>
          </Paper>
        </Grid>
        <Grid
          item
          xs={2}
          mt={-1}
          display={{ xs: "flex", md: "none" }}
          justifyContent="center"
        >
          <Checkbox
            checked={checkboxes["checkboxId46"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId46"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId46"]: newCheckedState,
              });
              updateCheckboxState("checkboxId46", newCheckedState);
            }}
          />
        </Grid>
        <Grid
          item
          xs={2}
          mt={-1}
          display={{ xs: "flex", md: "none" }}
          justifyContent="center"
        >
          <Checkbox
            checked={checkboxes["checkboxId47"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId47"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId47"]: newCheckedState,
              });
              updateCheckboxState("checkboxId47", newCheckedState);
            }}
          />
        </Grid>
        <Grid
          item
          xs={2}
          mt={-1}
          display={{ xs: "flex", md: "none" }}
          justifyContent="center"
        >
          <Checkbox
            checked={checkboxes["checkboxId48"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId48"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId48"]: newCheckedState,
              });
              updateCheckboxState("checkboxId48", newCheckedState);
            }}
          />
        </Grid>
      </Grid>
      <Grid container mt={-1}>
        <Grid item xs={6} display={{ xs: "block", md: "none" }}>
          <Paper style={{ backgroundColor: PgColors.redpg[200], height: 35 }}>
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Spagaatsprong 180º
            </Typography>
          </Paper>
        </Grid>
        <Grid
          item
          xs={2}
          mt={-1}
          display={{ xs: "flex", md: "none" }}
          justifyContent="center"
        >
          <Checkbox
            checked={checkboxes["checkboxId70"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId70"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId70"]: newCheckedState,
              });
              updateCheckboxState("checkboxId70", newCheckedState);
            }}
          />
        </Grid>
        <Grid
          item
          xs={2}
          mt={-1}
          display={{ xs: "flex", md: "none" }}
          justifyContent="center"
        >
          <Checkbox
            checked={checkboxes["checkboxId71"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId71"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId71"]: newCheckedState,
              });
              updateCheckboxState("checkboxId71", newCheckedState);
            }}
          />
        </Grid>
        <Grid
          item
          xs={2}
          mt={-1}
          display={{ xs: "flex", md: "none" }}
          justifyContent="center"
        >
          <Checkbox
            checked={checkboxes["checkboxId72"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId72"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId72"]: newCheckedState,
              });
              updateCheckboxState("checkboxId72", newCheckedState);
            }}
          />
        </Grid>
      </Grid>
      <Grid container mt={-1}>
        <Grid item xs={6} display={{ xs: "block", md: "none" }}>
          <Paper style={{ backgroundColor: PgColors.redpg[200], height: 35 }}>
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Sisonne 180º
            </Typography>
          </Paper>
        </Grid>
        <Grid
          item
          xs={2}
          mt={-1}
          display={{ xs: "flex", md: "none" }}
          justifyContent="center"
        >
          <Checkbox
            checked={checkboxes["checkboxId73"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId73"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId73"]: newCheckedState,
              });
              updateCheckboxState("checkboxId73", newCheckedState);
            }}
          />
        </Grid>
        <Grid
          item
          xs={2}
          mt={-1}
          display={{ xs: "flex", md: "none" }}
          justifyContent="center"
        >
          <Checkbox
            checked={checkboxes["checkboxId74"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId74"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId74"]: newCheckedState,
              });
              updateCheckboxState("checkboxId74", newCheckedState);
            }}
          />
        </Grid>
        <Grid
          item
          xs={2}
          mt={-1}
          display={{ xs: "flex", md: "none" }}
          justifyContent="center"
        >
          <Checkbox
            checked={checkboxes["checkboxId75"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId75"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId75"]: newCheckedState,
              });
              updateCheckboxState("checkboxId75", newCheckedState);
            }}
          />
        </Grid>
      </Grid>
      <Grid container mt={-1}>
        <Grid item xs={6} display={{ xs: "block", md: "none" }}>
          <Paper style={{ backgroundColor: PgColors.redpg[200], height: 35 }}>
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Loopsprong 180º
            </Typography>
          </Paper>
        </Grid>
        <Grid
          item
          xs={2}
          mt={-1}
          display={{ xs: "flex", md: "none" }}
          justifyContent="center"
        >
          <Checkbox
            checked={checkboxes["checkboxId76"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId76"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId76"]: newCheckedState,
              });
              updateCheckboxState("checkboxId76", newCheckedState);
            }}
          />
        </Grid>
        <Grid
          item
          xs={2}
          mt={-1}
          display={{ xs: "flex", md: "none" }}
          justifyContent="center"
        >
          <Checkbox
            checked={checkboxes["checkboxId77"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId77"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId77"]: newCheckedState,
              });
              updateCheckboxState("checkboxId77", newCheckedState);
            }}
          />
        </Grid>
        <Grid
          item
          xs={2}
          mt={-1}
          display={{ xs: "flex", md: "none" }}
          justifyContent="center"
        >
          <Checkbox
            checked={checkboxes["checkboxId78"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId78"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId78"]: newCheckedState,
              });
              updateCheckboxState("checkboxId78", newCheckedState);
            }}
          />
        </Grid>
      </Grid>
      <Grid container mt={-1}>
        <Grid item xs={6} display={{ xs: "block", md: "none" }}>
          <Paper style={{ backgroundColor: PgColors.redpg[200], height: 35 }}>
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Kattesprong ½
            </Typography>
          </Paper>
        </Grid>
        <Grid
          item
          xs={2}
          mt={-1}
          display={{ xs: "flex", md: "none" }}
          justifyContent="center"
        >
          <Checkbox
            checked={checkboxes["checkboxId79"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId79"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId79"]: newCheckedState,
              });
              updateCheckboxState("checkboxId79", newCheckedState);
            }}
          />
        </Grid>
        <Grid
          item
          xs={2}
          mt={-1}
          display={{ xs: "flex", md: "none" }}
          justifyContent="center"
        >
          <Checkbox
            checked={checkboxes["checkboxId80"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId80"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId80"]: newCheckedState,
              });
              updateCheckboxState("checkboxId80", newCheckedState);
            }}
          />
        </Grid>
        <Grid
          item
          xs={2}
          mt={-1}
          display={{ xs: "flex", md: "none" }}
          justifyContent="center"
        >
          <Checkbox
            checked={checkboxes["checkboxId81"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId81"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId81"]: newCheckedState,
              });
              updateCheckboxState("checkboxId81", newCheckedState);
            }}
          />
        </Grid>
      </Grid>
      <Grid container mt={-1}>
        <Grid item xs={6} display={{ xs: "block", md: "none" }}>
          <Paper style={{ backgroundColor: PgColors.redpg[400], height: 35 }}>
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Wisselspagaat
            </Typography>
          </Paper>
        </Grid>
        <Grid
          item
          xs={2}
          mt={-1}
          display={{ xs: "flex", md: "none" }}
          justifyContent="center"
        >
          <Checkbox
            checked={checkboxes["checkboxId82"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId82"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId82"]: newCheckedState,
              });
              updateCheckboxState("checkboxId82", newCheckedState);
            }}
          />
        </Grid>
        <Grid
          item
          xs={2}
          mt={-1}
          display={{ xs: "flex", md: "none" }}
          justifyContent="center"
        >
          <Checkbox
            checked={checkboxes["checkboxId83"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId83"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId83"]: newCheckedState,
              });
              updateCheckboxState("checkboxId83", newCheckedState);
            }}
          />
        </Grid>
        <Grid
          item
          xs={2}
          mt={-1}
          display={{ xs: "flex", md: "none" }}
          justifyContent="center"
        >
          <Checkbox
            checked={checkboxes["checkboxId84"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId84"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId84"]: newCheckedState,
              });
              updateCheckboxState("checkboxId84", newCheckedState);
            }}
          />
        </Grid>
      </Grid>
      <Grid container mt={-1}>
        <Grid item xs={6} display={{ xs: "block", md: "none" }}>
          <Paper style={{ backgroundColor: PgColors.redpg[400], height: 35 }}>
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Pirouette 1½
            </Typography>
          </Paper>
        </Grid>
        <Grid
          item
          xs={2}
          mt={-1}
          display={{ xs: "flex", md: "none" }}
          justifyContent="center"
        >
          <Checkbox
            checked={checkboxes["checkboxId85"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId85"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId85"]: newCheckedState,
              });
              updateCheckboxState("checkboxId85", newCheckedState);
            }}
          />
        </Grid>
        <Grid
          item
          xs={2}
          mt={-1}
          display={{ xs: "flex", md: "none" }}
          justifyContent="center"
        >
          <Checkbox
            checked={checkboxes["checkboxId86"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId86"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId86"]: newCheckedState,
              });
              updateCheckboxState("checkboxId86", newCheckedState);
            }}
          />
        </Grid>
        <Grid
          item
          xs={2}
          mt={-1}
          display={{ xs: "flex", md: "none" }}
          justifyContent="center"
        >
          <Checkbox
            checked={checkboxes["checkboxId87"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId87"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId87"]: newCheckedState,
              });
              updateCheckboxState("checkboxId87", newCheckedState);
            }}
          />
        </Grid>
      </Grid>
      <Grid container mt={-1}>
        <Grid item xs={6} display={{ xs: "block", md: "none" }}>
          <Paper style={{ backgroundColor: PgColors.redpg[400], height: 35 }}>
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Hurkpirouette
            </Typography>
          </Paper>
        </Grid>
        <Grid
          item
          xs={2}
          mt={-1}
          display={{ xs: "flex", md: "none" }}
          justifyContent="center"
        >
          <Checkbox
            checked={checkboxes["checkboxId88"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId88"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId88"]: newCheckedState,
              });
              updateCheckboxState("checkboxId88", newCheckedState);
            }}
          />
        </Grid>
        <Grid
          item
          xs={2}
          mt={-1}
          display={{ xs: "flex", md: "none" }}
          justifyContent="center"
        >
          <Checkbox
            checked={checkboxes["checkboxId89"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId89"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId89"]: newCheckedState,
              });
              updateCheckboxState("checkboxId89", newCheckedState);
            }}
          />
        </Grid>
        <Grid
          item
          xs={2}
          mt={-1}
          display={{ xs: "flex", md: "none" }}
          justifyContent="center"
        >
          <Checkbox
            checked={checkboxes["checkboxId90"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId90"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId90"]: newCheckedState,
              });
              updateCheckboxState("checkboxId90", newCheckedState);
            }}
          />
        </Grid>
      </Grid>
      <Grid container mt={-1}>
        <Grid item xs={6} display={{ xs: "block", md: "none" }}>
          <Paper style={{ backgroundColor: PgColors.redpg[400], height: 35 }}>
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Hurksprong ½
            </Typography>
          </Paper>
        </Grid>
        <Grid
          item
          xs={2}
          mt={-1}
          display={{ xs: "flex", md: "none" }}
          justifyContent="center"
        >
          <Checkbox
            checked={checkboxes["checkboxId91"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId91"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId91"]: newCheckedState,
              });
              updateCheckboxState("checkboxId91", newCheckedState);
            }}
          />
        </Grid>
        <Grid
          item
          xs={2}
          mt={-1}
          display={{ xs: "flex", md: "none" }}
          justifyContent="center"
        >
          <Checkbox
            checked={checkboxes["checkboxId92"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId92"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId92"]: newCheckedState,
              });
              updateCheckboxState("checkboxId92", newCheckedState);
            }}
          />
        </Grid>
        <Grid
          item
          xs={2}
          mt={-1}
          display={{ xs: "flex", md: "none" }}
          justifyContent="center"
        >
          <Checkbox
            checked={checkboxes["checkboxId93"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 35,
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId93"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId93"]: newCheckedState,
              });
              updateCheckboxState("checkboxId93", newCheckedState);
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};
