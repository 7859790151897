import React, { useState, useEffect } from "react";
import {
  collection,
  query,
  onSnapshot,
  updateDoc,
  doc,
  deleteDoc,
  setDoc,
} from "firebase/firestore";
import {
  Grid,
  Container,
  Typography,
  Paper,
  Button,
  Checkbox,
} from "@mui/material";
import { useAuthContext } from "../../../../hooks/useAuthContext";
import { useParams } from "react-router-dom";
import { db } from "../../../../firebase/config";
import { PgColors } from "../../../../configs/colors";
import CheckIcon from "@mui/icons-material/Check";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";

type CheckboxState = {
  [key: string]: boolean;
};

export const HalfIn = () => {
  const { user } = useAuthContext();
  const { id } = useParams();
  const [checkboxes, setCheckboxes] = useState<CheckboxState>({});

  const updateCheckboxState = async (checkboxId: any, isChecked: boolean) => {
    await setDoc(
      doc(db, `Skills/Vault/HalfIn/${id}/checkboxStates/${checkboxId}`),
      {
        isChecked,
      }
    );
  };

  useEffect(() => {
    const ref = query(
      collection(db, `Skills/Vault/HalfIn/${id}/checkboxStates`)
    );

    const unsub = onSnapshot(ref, (snapshot) => {
      const checkboxStates: any = [];
      snapshot.docs.forEach((doc) => {
        checkboxStates[doc.id] = doc.data().isChecked;
      });
      setCheckboxes(checkboxStates);
    });
    return () => unsub();
  }, []);

  return (
    <>
      <Grid container mt={2}>
        <Grid item xs={9} md={6}>
          <Typography
            variant="subtitle1"
            style={{ color: PgColors.redpg[500] }}
            sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
            align="left"
          >
            Fysieke Voorbereiding
          </Typography>
        </Grid>
        <Grid item xs={1} md={1} display="flex" justifyContent="center">
          <CheckIcon
            sx={{ color: PgColors.redpg[500], fontSize: { xs: 16, md: 20 } }}
          />
        </Grid>
        <Grid item xs={1} md={1} display="flex" justifyContent="center">
          <ThumbUpIcon
            sx={{ color: PgColors.redpg[500], fontSize: { xs: 16, md: 20 } }}
          />
        </Grid>
        <Grid item xs={1} md={1} display="flex" justifyContent="center">
          <EmojiEventsIcon
            sx={{ color: PgColors.redpg[500], fontSize: { xs: 16, md: 20 } }}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={9} md={6}>
          <Paper
            style={{ backgroundColor: PgColors.bluepg[100] }}
            sx={{ height: { xs: 40, sm: 25, md: 60, lg: 35 } }}
          >
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Kaatsen over 4 planken achter elkaar - 5hh | 10hh | 15hh
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId40"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId40"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId40"]: newCheckedState,
              });
              updateCheckboxState("checkboxId40", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId41"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId41"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId41"]: newCheckedState,
              });
              updateCheckboxState("checkboxId41", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId42"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId42"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId42"]: newCheckedState,
              });
              updateCheckboxState("checkboxId42", newCheckedState);
            }}
          />
        </Grid>
      </Grid>
      <Grid container sx={{ mt: { xs: 0.5, sm: 0, md: 0.5, lg: -0.5 } }}>
        <Grid item xs={9} md={6}>
          <Paper
            style={{ backgroundColor: PgColors.bluepg[100] }}
            sx={{ height: { xs: 85, sm: 45, md: 85, lg: 110 } }}
          >
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Ruglig tussen twee blokken, armen laag. Zwaai armen op en draai
              met lijf een 1/2 draai en terug - 10hh | 20hh | 30hh
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId44"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId44"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId44"]: newCheckedState,
              });
              updateCheckboxState("checkboxId44", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId45"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId45"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId45"]: newCheckedState,
              });
              updateCheckboxState("checkboxId45", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId46"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId46"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId46"]: newCheckedState,
              });
              updateCheckboxState("checkboxId46", newCheckedState);
            }}
          />
        </Grid>
      </Grid>
      <Grid container sx={{ mt: { xs: 0.5, sm: 0.8, md: 0.5, lg: 0.8 } }}>
        <Grid item xs={9} md={6}>
          <Paper
            style={{ backgroundColor: PgColors.bluepg[100] }}
            sx={{ height: { xs: 85, sm: 45, md: 85, lg: 110 } }}
          >
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Staan met rug tegen muur en armen langs oren met gewicthjes in
              handen. Schouders uitduwen - 10hh | 15hh | 20hh
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId47"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId47"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId47"]: newCheckedState,
              });
              updateCheckboxState("checkboxId47", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId48"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId48"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId48"]: newCheckedState,
              });
              updateCheckboxState("checkboxId48", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId49"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId49"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId49"]: newCheckedState,
              });
              updateCheckboxState("checkboxId49", newCheckedState);
            }}
          />
        </Grid>
      </Grid>
      <Grid container sx={{ mt: { xs: 0.5, sm: 0.8, md: 0.5, lg: 0.8 } }}>
        <Grid item xs={9} md={6}>
          <Paper
            style={{ backgroundColor: PgColors.bluepg[100] }}
            sx={{ height: { xs: 85, sm: 45, md: 85, lg: 110 } }}
          >
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Liggen op plank, handen houden lage kant vast en heupen liggen op
              de rand van hoge kant. Heupen openen - 10hh | 15hh | 20hh
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId50"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId50"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId50"]: newCheckedState,
              });
              updateCheckboxState("checkboxId50", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId51"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId51"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId51"]: newCheckedState,
              });
              updateCheckboxState("checkboxId51", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId52"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId52"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId52"]: newCheckedState,
              });
              updateCheckboxState("checkboxId52", newCheckedState);
            }}
          />
        </Grid>
      </Grid>
      <Grid container sx={{ mt: { xs: 0.5, sm: 0.8, md: 0.5, lg: 0.8 } }}>
        <Grid item xs={9} md={6}>
          <Paper
            style={{ backgroundColor: PgColors.bluepg[200] }}
            sx={{ height: { xs: 40, sm: 25, md: 35, lg: 35 } }}
          >
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Handstand hiptip tot op 5cm - 5hh | 10hh | 15hh
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId59"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId59"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId59"]: newCheckedState,
              });
              updateCheckboxState("checkboxId59", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId60"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId60"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId60"]: newCheckedState,
              });
              updateCheckboxState("checkboxId60", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId61"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId61"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId61"]: newCheckedState,
              });
              updateCheckboxState("checkboxId61", newCheckedState);
            }}
          />
        </Grid>
      </Grid>
      <Grid container sx={{ mt: { xs: 0.5, sm: 0, md: -0.8, lg: -0.5 } }}>
        <Grid item xs={9} md={6}>
          <Paper
            style={{ backgroundColor: PgColors.bluepg[200] }}
            sx={{ height: { xs: 65, sm: 45, md: 60, lg: 70 } }}
          >
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Handstand op lange klos tegen muur, 1/2 draai heen en terug - 3hh
              | 5hh | 8hh
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId53"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId53"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId53"]: newCheckedState,
              });
              updateCheckboxState("checkboxId53", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId54"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId54"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId54"]: newCheckedState,
              });
              updateCheckboxState("checkboxId54", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId55"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId55"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId55"]: newCheckedState,
              });
              updateCheckboxState("checkboxId55", newCheckedState);
            }}
          />
        </Grid>
      </Grid>

      <Grid container sx={{ mt: { xs: 0.5, sm: 0.8, md: 0.5, lg: 0.8 } }}>
        <Grid item xs={9} md={6}>
          <Paper
            style={{ backgroundColor: PgColors.bluepg[300] }}
            sx={{ height: { xs: 40, sm: 25, md: 35, lg: 35 } }}
          >
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Handstand hiptip met 1/2 draai - 5hh | 8hh | 10hh
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId62"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId62"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId62"]: newCheckedState,
              });
              updateCheckboxState("checkboxId62", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId63"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId63"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId63"]: newCheckedState,
              });
              updateCheckboxState("checkboxId63", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId64"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId64"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId64"]: newCheckedState,
              });
              updateCheckboxState("checkboxId64", newCheckedState);
            }}
          />
        </Grid>
      </Grid>

      <Grid container sx={{ mt: { xs: 0.5, sm: 0, md: -0.8, lg: -0.5 } }}>
        <Grid item xs={9} md={6}>
          <Paper
            style={{ backgroundColor: PgColors.bluepg[300] }}
            sx={{ height: { xs: 65, sm: 45, md: 60, lg: 70 } }}
          >
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Springen tot op 2 20cm airtracks, 1/2 draai af springen met
              stick-it landing - 5hh | 10hh | 15hh
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId68"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId68"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId68"]: newCheckedState,
              });
              updateCheckboxState("checkboxId68", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId69"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId69"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId69"]: newCheckedState,
              });
              updateCheckboxState("checkboxId69", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId70"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId70"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId70"]: newCheckedState,
              });
              updateCheckboxState("checkboxId70", newCheckedState);
            }}
          />
        </Grid>
      </Grid>

      <Grid container mt={2}>
        <Grid item xs={9} md={6}>
          <Typography
            variant="subtitle1"
            style={{ color: PgColors.redpg[500] }}
            sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
            align="left"
          >
            Half in - half uit Drills
          </Typography>
        </Grid>
        <Grid item xs={1} md={1} display="flex" justifyContent="center">
          <CheckIcon
            sx={{ color: PgColors.redpg[500], fontSize: { xs: 16, md: 20 } }}
          />
        </Grid>
        <Grid item xs={1} md={1} display="flex" justifyContent="center">
          <ThumbUpIcon
            sx={{ color: PgColors.redpg[500], fontSize: { xs: 16, md: 20 } }}
          />
        </Grid>
        <Grid item xs={1} md={1} display="flex" justifyContent="center">
          <EmojiEventsIcon
            sx={{ color: PgColors.redpg[500], fontSize: { xs: 16, md: 20 } }}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={9} md={6}>
          <Paper
            style={{ backgroundColor: PgColors.bluepg[100] }}
            sx={{ height: { xs: 65, sm: 45, md: 85, lg: 70 } }}
          >
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Half in tot op dikke mat - tot buiklig | 1/2 uit tot rug (met
              schouders open) | 1/1 uit tot buiklig (met schouders open)
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId1"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId1"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId1"]: newCheckedState,
              });
              updateCheckboxState("checkboxId1", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId2"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId2"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId2"]: newCheckedState,
              });
              updateCheckboxState("checkboxId2", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId3"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId3"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId3"]: newCheckedState,
              });
              updateCheckboxState("checkboxId3", newCheckedState);
            }}
          />
        </Grid>
      </Grid>
      <Grid container sx={{ mt: { xs: 0.5, sm: 0.8, md: 0.5, lg: 0.8 } }}>
        <Grid item xs={9} md={6}>
          <Paper
            style={{ backgroundColor: PgColors.bluepg[100] }}
            sx={{ height: { xs: 65, sm: 45, md: 60, lg: 70 } }}
          >
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Half in in over airtrack rol tot lig op ploffers - tot buiklig |
              met half uit tot ruglig | met heel uit tot buiklig
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId16"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId16"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId16"]: newCheckedState,
              });
              updateCheckboxState("checkboxId16", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId17"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId17"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId17"]: newCheckedState,
              });
              updateCheckboxState("checkboxId17", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId18"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId18"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId18"]: newCheckedState,
              });
              updateCheckboxState("checkboxId18", newCheckedState);
            }}
          />
        </Grid>
      </Grid>
      <Grid container sx={{ mt: { xs: 0.5, sm: 0.8, md: 0.5, lg: 0.8 } }}>
        <Grid item xs={9} md={6}>
          <Paper
            style={{ backgroundColor: PgColors.bluepg[100] }}
            sx={{ height: { xs: 110, sm: 65, md: 115, lg: 140 } }}
          >
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Voorhup tot springen met voeten voor op 3 ploffers, landen in
              langzit met armen voor - vanuit aanloop | vanuit mini-aanloop |
              vanuit halve kniezit met mini-aanloop
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId4"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId4"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId4"]: newCheckedState,
              });
              updateCheckboxState("checkboxId4", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId5"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId5"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId5"]: newCheckedState,
              });
              updateCheckboxState("checkboxId5", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId6"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId6"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId6"]: newCheckedState,
              });
              updateCheckboxState("checkboxId6", newCheckedState);
            }}
          />
        </Grid>
      </Grid>

      <Grid container sx={{ mt: { xs: 0.5, sm: 0.8, md: 0.5, lg: 0.8 } }}>
        <Grid item xs={9} md={6}>
          <Paper
            style={{ backgroundColor: PgColors.bluepg[200] }}
            sx={{ height: { xs: 65, sm: 45, md: 60, lg: 70 } }}
          >
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Half in in op pegasus met verhoging - tot buiklig | half uit tot
              ruglig | heel uit tot buiklig
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId10"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId10"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId10"]: newCheckedState,
              });
              updateCheckboxState("checkboxId10", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId11"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId11"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId11"]: newCheckedState,
              });
              updateCheckboxState("checkboxId11", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId12"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId12"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId12"]: newCheckedState,
              });
              updateCheckboxState("checkboxId12", newCheckedState);
            }}
          />
        </Grid>
      </Grid>
      <Grid container sx={{ mt: { xs: 0.5, sm: 0.8, md: 0.5, lg: 0.8 } }}>
        <Grid item xs={9} md={6}>
          <Paper
            style={{ backgroundColor: PgColors.bluepg[300] }}
            sx={{ height: { xs: 65, sm: 45, md: 85, lg: 70 } }}
          >
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Half in / half uit pegasus bij kuil - tot op ploffers | tot op
              dikke mat | tot op 2 dikke matten
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId13"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId13"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId13"]: newCheckedState,
              });
              updateCheckboxState("checkboxId13", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId14"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId14"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId14"]: newCheckedState,
              });
              updateCheckboxState("checkboxId14", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId15"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId15"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId15"]: newCheckedState,
              });
              updateCheckboxState("checkboxId15", newCheckedState);
            }}
          />
        </Grid>
      </Grid>

      <Grid
        container
        sx={{
          mt: { xs: 0.5, sm: 0.8, md: 0.5, lg: 0.8 },
          mb: { xs: 4, lg: 2 },
        }}
      >
        <Grid item xs={9} md={6}>
          <Paper
            style={{ backgroundColor: PgColors.bluepg[400] }}
            sx={{ height: { xs: 65, sm: 45, md: 60, lg: 70 } }}
          >
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Half in / half uit wedstrijd situatie - met plankoline | met plank
              | met plank en stick-it landing
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId34"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId34"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId34"]: newCheckedState,
              });
              updateCheckboxState("checkboxId34", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId35"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId35"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId35"]: newCheckedState,
              });
              updateCheckboxState("checkboxId35", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId36"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId36"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId36"]: newCheckedState,
              });
              updateCheckboxState("checkboxId36", newCheckedState);
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};
