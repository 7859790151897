import React, { useState, useEffect } from "react";
import { collection, query, onSnapshot, orderBy } from "firebase/firestore";
import {
  Grid,
  Container,
  Typography,
  Paper,
  Button,
  Box,
  CardMedia,
} from "@mui/material";
import { useAuthContext } from "../../../../hooks/useAuthContext";
import { useParams } from "react-router-dom";
import { db } from "../../../../firebase/config";
import { PgColors } from "../../../../configs/colors";

export const VisualizationPage = () => {
  const { authIsReady, user } = useAuthContext();

  const { id } = useParams();

  return (
    <>
      <Grid container mt={2}>
        <Grid item xs={12}>
          <Paper
            style={{ backgroundColor: PgColors.redpg[400] }}
            sx={{ height: { xs: 45, md: 75 } }}
          >
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, sm: 14, md: 16, lg: 20 } }}
              align="center"
            >
              "Mental will is a muscle that needs exercises, just like the
              muscles of the body."
            </Typography>

            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, sm: 14, md: 16, lg: 20 } }}
              align="center"
            >
              - Lynn Jennings
            </Typography>
          </Paper>
        </Grid>
      </Grid>
      <Grid container mt={2}>
        <Grid item xs={12} mt={1}>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
            }}
            align="left"
          >
            Visualiseren is al je zintuigen gebruiken om een ervaring te
            (her)beleven in je hoofd. Tijdens het visualeren ben je ook
            daadwerkelijk neurologische paden aan het maken in je brein, zoals
            je ook doet als je aan het trainen bent. Je brein weet niet het
            verschil tussen de twee!
          </Typography>
        </Grid>
        <Grid item xs={12} mt={1}>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
            }}
            align="left"
          >
            Er zijn verschillende types van visualeren. Het ene type zal beter
            voor je werken dan het andere type.
          </Typography>
        </Grid>

        <Grid item xs={12} mt={2}>
          <Typography
            variant="subtitle1"
            style={{ color: PgColors.bluepg[400] }}
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
              fontWeight: { md: 500 },
            }}
            align="left"
          >
            Visueel
          </Typography>
        </Grid>
        <Grid item xs={12} mt={0}>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
            }}
            align="left"
          >
            Veel mensen zijn visuele leerlingen. Zij leren nieuwe concepten en
            kunnen stof het beste onthouden door het te zien. Visuele
            leerllingen hebben ondder andere de volgende karakteristieken:
          </Typography>
        </Grid>
        <Grid item xs={12} mt={1}>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
            }}
            align="left"
          >
            - Je leert het beste door te lezen of iets te zien.
          </Typography>
        </Grid>
        <Grid item xs={12} mt={0}>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
            }}
            align="left"
          >
            - Je kan makkelijk detaills herinneren zoals iemands ogen of
            kleding.
          </Typography>
        </Grid>
        <Grid item xs={12} mt={0}>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
            }}
            align="left"
          >
            - Je legt iemand iets uit gebaseerd op visuele aanwijzingen,
            bijvoorbeeld: "ga rechts bij het gebouw met de rode deur".
          </Typography>
        </Grid>
        <Grid item xs={12} mt={1}>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
            }}
            align="left"
          >
            Als je visueel leren het fijnste vind, probeer dan in je hoofd aan
            beelden te denken die vooral visueel zijn. Als je bijvoorbeeld aan
            het nadenken bent over de handplaatsing van je tsuki, beeld je dan
            twee kruisen in op de plek waar je je handen wilt neerzetten.
          </Typography>
        </Grid>

        <Grid item xs={12} mt={2}>
          <Typography
            variant="subtitle1"
            style={{ color: PgColors.bluepg[400] }}
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
              fontWeight: { md: 500 },
            }}
            align="left"
          >
            Auditief
          </Typography>
        </Grid>
        <Grid item xs={12} mt={0}>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
            }}
            align="left"
          >
            Auditiefe leerlingen gebruiken geluid as de belangrijkste factor
            wanneer ze iets willen herinneren.Auditiefe leerlingen hebben onder
            andere de volgende karakteristieken:
          </Typography>
        </Grid>
        <Grid item xs={12} mt={1}>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
            }}
            align="left"
          >
            - Je leert het makkelijkste door te luisteren.
          </Typography>
        </Grid>
        <Grid item xs={12} mt={0}>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
            }}
            align="left"
          >
            - Je kan makkelijk onthouden wat iemand precies heeft gezegd.
          </Typography>
        </Grid>
        <Grid item xs={12} mt={0}>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
            }}
            align="left"
          >
            - Wanneer je naar muziek luister heb je maar een paar tonen nodig om
            het liedjes te herkennen.
          </Typography>
        </Grid>
        <Grid item xs={12} mt={1}>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
            }}
            align="left"
          >
            Als auditief leren fijn vind, probeer dan geluid te gebruiken
            tijdens het visualiseren. Wanneer je bijvoorbeeld je acro serie op
            vloer probeert te visualeren probeer dan het ritme van de serie toe
            te voegen.
          </Typography>
        </Grid>
        <Grid item xs={12} mt={2}>
          <Typography
            variant="subtitle1"
            style={{ color: PgColors.bluepg[400] }}
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
              fontWeight: { md: 500 },
            }}
            align="left"
          >
            Fysiek
          </Typography>
        </Grid>
        <Grid item xs={12} mt={0}>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
            }}
            align="left"
          >
            Fysieke leerlingen maken met name gebruik van spier bewegingen en
            'muscle memory'. Karakteristieken zijn onder andere:
          </Typography>
        </Grid>
        <Grid item xs={12} mt={1}>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
            }}
            align="left"
          >
            - Je leert het beste door te doen en de beweging te ervaren. Alleen
            uitleg is vaak niet voldoende om het te begrijpen.
          </Typography>
        </Grid>
        <Grid item xs={12} mt={0}>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
            }}
            align="left"
          >
            - Je krijgt meer zelfvertrouwen als je een skill vaak kan doen om
            'muscle memory' op te bouwen.
          </Typography>
        </Grid>
        <Grid item xs={12} mt={0}>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
            }}
            align="left"
          >
            - Beelden en woorden laten je vooral denken aan hoe de beweging
            'voelt'.
          </Typography>
        </Grid>
        <Grid item xs={12} mt={1}>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
            }}
            align="left"
          >
            Als je fysiek leren het fijnste vind probeer dan tijdens het
            visualiseren te bedenken hoe het voelt om een skill of oefening te
            doen.
          </Typography>
        </Grid>

        <Grid item xs={12} mt={2}>
          <Typography
            variant="subtitle1"
            style={{ color: PgColors.bluepg[400] }}
            sx={{
              fontSize: { xs: 16, md: 20, lg: 24 },
              fontWeight: { md: 500 },
            }}
            align="left"
          >
            Opdracht 10
          </Typography>
        </Grid>
      </Grid>

      <Grid container mt={2} mb={2}>
        <Grid item xs={12} mt={1}>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
            }}
            align="left"
          >
            Kies een skill uit die je aan het oefeninen bent. Probeer deze te
            visualiseren met het type dat jij het fijnste vind. Probeer deze
            skill voor, tijdens en na een trianing te visualiseren.
          </Typography>
        </Grid>

        <Grid item xs={12} mt={1}>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: 10, md: 12, lg: 16 },
            }}
            align="left"
          >
            Als je dit steeds makkelijker vind, probeer dan je gehele oefening
            te visualiseren. Dit kan je dan ook zelfs bij een wedstrijd
            gebruiken.
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};
