import { createTheme } from "@mui/material/styles";
import { ThemeOptions } from "@mui/material/styles";
import { PgColors } from "./colors";

const fontFamilyHeader = "Aleo, serif";
const fontFamilyBody = "Poppins, sans-serif";

export const theme: ThemeOptions = createTheme({
  pgColors: PgColors,
  palette: {
    primary: {
      main: "#7f8581",
    },
    secondary: {
      main: "#C39E98",
      light: "#dbb3ac",
    },
    info: {
      main: "#f1fff6",
    },
    warning: {
      main: "#2F5E6D",
    },
  },
  typography: {
    fontFamily: fontFamilyBody,
    allVariants: {
      fontFamily: fontFamilyBody,
    },
    h1: {
      fontFamily: fontFamilyHeader,
    },
    h2: {
      fontFamily: fontFamilyHeader,
    },
    h3: {
      fontFamily: fontFamilyHeader,
    },
    h4: {
      fontFamily: fontFamilyHeader,
    },
    h5: {
      fontFamily: fontFamilyHeader,
    },
    h6: {
      fontFamily: fontFamilyHeader,
    },
    button: {
      fontFamily: fontFamilyBody,
    },
    body2: {
      fontFamily: fontFamilyBody,
    },
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontFamily: fontFamilyHeader,
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: ` h3 {
            font-family: 'Aleo, serif';
            color: #f0c5be;
        }`,
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          color: "#ffffff",
        },
      },
    },
  },
});
