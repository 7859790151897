import React, { useState, useEffect } from "react";
import {
  collection,
  query,
  onSnapshot,
  updateDoc,
  doc,
  deleteDoc,
  setDoc,
} from "firebase/firestore";
import {
  Grid,
  Container,
  Typography,
  Paper,
  Button,
  Checkbox,
} from "@mui/material";
import { useAuthContext } from "../../../../hooks/useAuthContext";
import { useParams } from "react-router-dom";
import { db } from "../../../../firebase/config";
import { PgColors } from "../../../../configs/colors";
import CheckIcon from "@mui/icons-material/Check";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";

type CheckboxState = {
  [key: string]: boolean;
};

export const Yurchenko = () => {
  const { user } = useAuthContext();
  const [checkboxes, setCheckboxes] = useState<CheckboxState>({});

  const updateCheckboxState = async (checkboxId: any, isChecked: boolean) => {
    await setDoc(
      doc(
        db,
        `Skills/Vault/Yurchenko/${user.uid}/checkboxStates/${checkboxId}`
      ),
      {
        isChecked,
      }
    );
  };

  useEffect(() => {
    const ref = query(
      collection(db, `Skills/Vault/Yurchenko/${user.uid}/checkboxStates`)
    );

    const unsub = onSnapshot(ref, (snapshot) => {
      const checkboxStates: any = [];
      snapshot.docs.forEach((doc) => {
        checkboxStates[doc.id] = doc.data().isChecked;
      });
      setCheckboxes(checkboxStates);
    });
    return () => unsub();
  }, []);

  return (
    <>
      <Grid container mt={2}>
        <Grid item xs={9} md={6}>
          <Typography
            variant="subtitle1"
            style={{ color: PgColors.redpg[500] }}
            sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
            align="left"
          >
            Fysieke Voorbereiding
          </Typography>
        </Grid>
        <Grid item xs={1} md={1} display="flex" justifyContent="center">
          <CheckIcon
            sx={{ color: PgColors.redpg[500], fontSize: { xs: 16, md: 20 } }}
          />
        </Grid>
        <Grid item xs={1} md={1} display="flex" justifyContent="center">
          <ThumbUpIcon
            sx={{ color: PgColors.redpg[500], fontSize: { xs: 16, md: 20 } }}
          />
        </Grid>
        <Grid item xs={1} md={1} display="flex" justifyContent="center">
          <EmojiEventsIcon
            sx={{ color: PgColors.redpg[500], fontSize: { xs: 16, md: 20 } }}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={9} md={6}>
          <Paper
            style={{ backgroundColor: PgColors.bluepg[100] }}
            sx={{ height: { xs: 65, sm: 45, md: 60, lg: 70 } }}
          >
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Steunen tegen laagste kant blok met bolle bovenrug, actief knieën
              heffen - 30sec | 45sec | 1min
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId58"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId58"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId58"]: newCheckedState,
              });
              updateCheckboxState("checkboxId58", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId59"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId59"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId59"]: newCheckedState,
              });
              updateCheckboxState("checkboxId59", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId60"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId60"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId60"]: newCheckedState,
              });
              updateCheckboxState("checkboxId60", newCheckedState);
            }}
          />
        </Grid>
      </Grid>
      <Grid container sx={{ mt: { xs: 0.5, sm: 0.8, md: 0.5, lg: 0.8 } }}>
        <Grid item xs={9} md={6}>
          <Paper
            style={{ backgroundColor: PgColors.bluepg[100] }}
            sx={{ height: { xs: 65, sm: 45, md: 60, lg: 70 } }}
          >
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Halve kniezit met armen langs oren, standspagaat en terug - 10hh |
              15hh | 20hh
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId61"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId61"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId61"]: newCheckedState,
              });
              updateCheckboxState("checkboxId61", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId62"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId62"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId62"]: newCheckedState,
              });
              updateCheckboxState("checkboxId62", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId63"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId63"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId63"]: newCheckedState,
              });
              updateCheckboxState("checkboxId63", newCheckedState);
            }}
          />
        </Grid>
      </Grid>
      <Grid container sx={{ mt: { xs: 0.5, sm: 0.8, md: 0.5, lg: 0.8 } }}>
        <Grid item xs={9} md={6}>
          <Paper
            style={{ backgroundColor: PgColors.bluepg[100] }}
            sx={{ height: { xs: 65, sm: 45, md: 60, lg: 70 } }}
          >
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Arabier uit stand tot op 20cm mat/verhoging met overdreven bolle
              houding - 10hh | 15hh | 20hh
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId40"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId40"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId40"]: newCheckedState,
              });
              updateCheckboxState("checkboxId40", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId41"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId41"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId41"]: newCheckedState,
              });
              updateCheckboxState("checkboxId41", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId42"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId42"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId42"]: newCheckedState,
              });
              updateCheckboxState("checkboxId42", newCheckedState);
            }}
          />
        </Grid>
      </Grid>
      <Grid container sx={{ mt: { xs: 0.5, sm: 0.8, md: 0.5, lg: 0.8 } }}>
        <Grid item xs={9} md={6}>
          <Paper
            style={{ backgroundColor: PgColors.bluepg[100] }}
            sx={{ height: { xs: 85, sm: 65, md: 85, lg: 110 } }}
          >
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Staan bij wandrek met armen langs de oren en elastiek vasthouden,
              hol naar achter bewegen en terug naar bol met armen hoog - 10hh |
              15hh | 20hh
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId52"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId52"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId52"]: newCheckedState,
              });
              updateCheckboxState("checkboxId52", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId53"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId53"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId53"]: newCheckedState,
              });
              updateCheckboxState("checkboxId53", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId54"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId54"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId54"]: newCheckedState,
              });
              updateCheckboxState("checkboxId54", newCheckedState);
            }}
          />
        </Grid>
      </Grid>
      <Grid container sx={{ mt: { xs: 0.5, sm: 0.8, md: 0.5, lg: 0.8 } }}>
        <Grid item xs={9} md={6}>
          <Paper
            style={{ backgroundColor: PgColors.bluepg[100] }}
            sx={{ height: { xs: 85, sm: 65, md: 85, lg: 110 } }}
          >
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Squatzit tegen airtrackrol, bovenrug is op rol en armen zijn langs
              orgen, bouncen/blocken met handen tegen muur/pegasus aan - 10hh |
              15hh | 20hh
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId55"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId55"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId55"]: newCheckedState,
              });
              updateCheckboxState("checkboxId55", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId56"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId56"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId56"]: newCheckedState,
              });
              updateCheckboxState("checkboxId56", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId57"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId57"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId57"]: newCheckedState,
              });
              updateCheckboxState("checkboxId57", newCheckedState);
            }}
          />
        </Grid>
      </Grid>
      <Grid container sx={{ mt: { xs: 0.5, sm: 0.8, md: 0.5, lg: 0.8 } }}>
        <Grid item xs={9} md={6}>
          <Paper
            style={{ backgroundColor: PgColors.bluepg[100] }}
            sx={{ height: { xs: 85, sm: 65, md: 85, lg: 110 } }}
          >
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Liggen op vloer met schouders net op rand (met gewicht op heupen),
              heupen tillen en armen naar de grond toe - 10hh | 15hh | 20hh
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId55"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId55"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId55"]: newCheckedState,
              });
              updateCheckboxState("checkboxId55", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId56"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId56"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId56"]: newCheckedState,
              });
              updateCheckboxState("checkboxId56", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId57"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId57"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId57"]: newCheckedState,
              });
              updateCheckboxState("checkboxId57", newCheckedState);
            }}
          />
        </Grid>
      </Grid>
      <Grid container sx={{ mt: { xs: 0.5, sm: 0.8, md: 0.5, lg: 0.8 } }}>
        <Grid item xs={9} md={6}>
          <Paper
            style={{ backgroundColor: PgColors.bluepg[200] }}
            sx={{ height: { xs: 45, sm: 25, md: 60, lg: 70 } }}
          >
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              3 hinkels, arabier tot "C" op verhoging van 20cm - 5hh | 10hh |
              15hh
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId10"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId10"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId10"]: newCheckedState,
              });
              updateCheckboxState("checkboxId10", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId11"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId11"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId11"]: newCheckedState,
              });
              updateCheckboxState("checkboxId11", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId12"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId12"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId12"]: newCheckedState,
              });
              updateCheckboxState("checkboxId12", newCheckedState);
            }}
          />
        </Grid>
      </Grid>
      <Grid container sx={{ mt: { xs: 0.5, sm: 0, md: 0.5, lg: 0.8 } }}>
        <Grid item xs={9} md={6}>
          <Paper
            style={{ backgroundColor: PgColors.bluepg[200] }}
            sx={{ height: { xs: 85, sm: 65, md: 85, lg: 110 } }}
          >
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              In flikflak staan tegen pegasus aan, handen van 5cm matje aflopen
              en weer terug met uitgeduwde schouders - 10hh | 15hh | 20hh
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId64"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId64"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId64"]: newCheckedState,
              });
              updateCheckboxState("checkboxId64", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId65"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId65"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId65"]: newCheckedState,
              });
              updateCheckboxState("checkboxId65", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId66"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId66"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId66"]: newCheckedState,
              });
              updateCheckboxState("checkboxId66", newCheckedState);
            }}
          />
        </Grid>
      </Grid>
      <Grid container sx={{ mt: { xs: 0.5, sm: 0.8, md: 0.5, lg: 0.7 } }}>
        <Grid item xs={9} md={6}>
          <Paper
            style={{ backgroundColor: PgColors.bluepg[300] }}
            sx={{ height: { xs: 65, sm: 45, md: 60, lg: 70 } }}
          >
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Flikflak omhoog op schuin vlak tot holle handstand en terug rollen
              - 3hh | 5hh | 7hh
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId46"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId46"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId46"]: newCheckedState,
              });
              updateCheckboxState("checkboxId46", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId47"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId47"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId47"]: newCheckedState,
              });
              updateCheckboxState("checkboxId47", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId48"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId48"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId48"]: newCheckedState,
              });
              updateCheckboxState("checkboxId48", newCheckedState);
            }}
          />
        </Grid>
      </Grid>
      <Grid container sx={{ mt: { xs: 0.5, sm: 0.8, md: 0.5, lg: 0.7 } }}>
        <Grid item xs={9} md={6}>
          <Paper
            style={{ backgroundColor: PgColors.bluepg[300] }}
            sx={{ height: { xs: 65, sm: 45, md: 85, lg: 70 } }}
          >
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Vanaf stapel van 3 dikke matten, spring in trampo, til heupen
              actief op tot flikflak op stapel - 5hh | 10hh | 15hh
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId49"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId49"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId49"]: newCheckedState,
              });
              updateCheckboxState("checkboxId49", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId50"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId50"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId50"]: newCheckedState,
              });
              updateCheckboxState("checkboxId50", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId51"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId51"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId51"]: newCheckedState,
              });
              updateCheckboxState("checkboxId51", newCheckedState);
            }}
          />
        </Grid>
      </Grid>

      <Grid container mt={2}>
        <Grid item xs={9} md={6}>
          <Typography
            variant="subtitle1"
            style={{ color: PgColors.redpg[500] }}
            sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
            align="left"
          >
            Yurchenko Drills
          </Typography>
        </Grid>
        <Grid item xs={1} md={1} display="flex" justifyContent="center">
          <CheckIcon
            sx={{ color: PgColors.redpg[500], fontSize: { xs: 16, md: 20 } }}
          />
        </Grid>
        <Grid item xs={1} md={1} display="flex" justifyContent="center">
          <ThumbUpIcon
            sx={{ color: PgColors.redpg[500], fontSize: { xs: 16, md: 20 } }}
          />
        </Grid>
        <Grid item xs={1} md={1} display="flex" justifyContent="center">
          <EmojiEventsIcon
            sx={{ color: PgColors.redpg[500], fontSize: { xs: 16, md: 20 } }}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={9} md={6}>
          <Paper
            style={{ backgroundColor: PgColors.bluepg[100] }}
            sx={{ height: { xs: 65, sm: 45, md: 85, lg: 70 } }}
          >
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Radslag vanuit kniezit, T-handen, arabier-landing - gelijke hoogte
              | tot op 5cm verhoging | tot op 10cm verhoging
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId1"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId1"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId1"]: newCheckedState,
              });
              updateCheckboxState("checkboxId1", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId2"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId2"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId2"]: newCheckedState,
              });
              updateCheckboxState("checkboxId2", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId3"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId3"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId3"]: newCheckedState,
              });
              updateCheckboxState("checkboxId3", newCheckedState);
            }}
          />
        </Grid>
      </Grid>
      <Grid container sx={{ mt: { xs: 0.5, sm: 0.8, md: 0.5, lg: 0.8 } }}>
        <Grid item xs={9} md={6}>
          <Paper
            style={{ backgroundColor: PgColors.bluepg[100] }}
            sx={{ height: { xs: 65, sm: 45, md: 60, lg: 70 } }}
          >
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Voorhup, radslag T-handen foamblok, arabier landing - tot op vloer
              | tot op 10cm | tot op 20cm
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId4"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId4"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId4"]: newCheckedState,
              });
              updateCheckboxState("checkboxId4", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId5"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId5"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId5"]: newCheckedState,
              });
              updateCheckboxState("checkboxId5", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId6"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId6"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId6"]: newCheckedState,
              });
              updateCheckboxState("checkboxId6", newCheckedState);
            }}
          />
        </Grid>
      </Grid>

      <Grid container sx={{ mt: { xs: 0.5, sm: 0.8, md: 0.5, lg: 0.8 } }}>
        <Grid item xs={9} md={6}>
          <Paper
            style={{ backgroundColor: PgColors.bluepg[100] }}
            sx={{ height: { xs: 85, sm: 45, md: 85, lg: 110 } }}
          >
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Arabier met handen over over foamblok, foamblok pakken, tot kaat
              "C-houding" tegen schuine mat - 3hh | 5hh | 7hh
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId43"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId43"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId43"]: newCheckedState,
              });
              updateCheckboxState("checkboxId43", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId44"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId44"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId44"]: newCheckedState,
              });
              updateCheckboxState("checkboxId44", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId45"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId45"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId45"]: newCheckedState,
              });
              updateCheckboxState("checkboxId45", newCheckedState);
            }}
          />
        </Grid>
      </Grid>
      <Grid container sx={{ mt: { xs: 0.5, sm: 0.8, md: 0.5, lg: 0.8 } }}>
        <Grid item xs={9} md={6}>
          <Paper
            style={{ backgroundColor: PgColors.bluepg[100] }}
            sx={{ height: { xs: 110, sm: 65, md: 115, lg: 110 } }}
          >
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Voorhup, arabier kaats tot op 20cm, kaats, opstrekken armen hoog
              en heupen op tot ruglig op plofblok - met 20cm mat | met 20cm
              airtrack | met plank
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId7"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId7"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId7"]: newCheckedState,
              });
              updateCheckboxState("checkboxId7", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId8"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId8"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId8"]: newCheckedState,
              });
              updateCheckboxState("checkboxId8", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId9"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId9"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId9"]: newCheckedState,
              });
              updateCheckboxState("checkboxId9", newCheckedState);
            }}
          />
        </Grid>
      </Grid>

      <Grid container sx={{ mt: { xs: 0.5, sm: 0.8, md: 0.5, lg: 0.8 } }}>
        <Grid item xs={9} md={6}>
          <Paper
            style={{ backgroundColor: PgColors.bluepg[200] }}
            sx={{ height: { xs: 85, sm: 65, md: 85, lg: 110 } }}
          >
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Voorhup arabier kaats flikflak bij tumblingbaan - handen op 1x
              20cm airtrack | handen op 2x 20cm airtrack | handen op 2x 20cm
              aitrack + 20cm mat
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId13"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId13"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId13"]: newCheckedState,
              });
              updateCheckboxState("checkboxId13", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId14"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId14"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId14"]: newCheckedState,
              });
              updateCheckboxState("checkboxId14", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId15"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId15"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId15"]: newCheckedState,
              });
              updateCheckboxState("checkboxId15", newCheckedState);
            }}
          />
        </Grid>
      </Grid>
      <Grid container sx={{ mt: { xs: 0.5, sm: 0.8, md: 0.5, lg: 0.8 } }}>
        <Grid item xs={9} md={6}>
          <Paper
            style={{ backgroundColor: PgColors.bluepg[200] }}
            sx={{ height: { xs: 65, sm: 45, md: 60, lg: 70 } }}
          >
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Arabier kaats tot ruglig/kaars tot op verhoging - 80cm | 100/110cm
              | 125/135cm
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId16"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId16"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId16"]: newCheckedState,
              });
              updateCheckboxState("checkboxId16", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId17"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId17"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId17"]: newCheckedState,
              });
              updateCheckboxState("checkboxId17", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId18"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId18"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId18"]: newCheckedState,
              });
              updateCheckboxState("checkboxId18", newCheckedState);
            }}
          />
        </Grid>
      </Grid>
      <Grid container sx={{ mt: { xs: 0.5, sm: 0.8, md: 0.5, lg: 0.8 } }}>
        <Grid item xs={9} md={6}>
          <Paper
            style={{ backgroundColor: PgColors.bluepg[200] }}
            sx={{ height: { xs: 65, sm: 45, md: 60, lg: 70 } }}
          >
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Yurchenko tot stand op hoog vlak - 100/110 cm met vang | 125/135cm
              met vang | 125/135cm alleen
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId19"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId19"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId19"]: newCheckedState,
              });
              updateCheckboxState("checkboxId19", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId20"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId20"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId20"]: newCheckedState,
              });
              updateCheckboxState("checkboxId20", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId21"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId21"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId21"]: newCheckedState,
              });
              updateCheckboxState("checkboxId21", newCheckedState);
            }}
          />
        </Grid>
      </Grid>
      <Grid container sx={{ mt: { xs: 0.5, sm: 0.8, md: 0.5, lg: 0.8 } }}>
        <Grid item xs={9} md={6}>
          <Paper
            style={{ backgroundColor: PgColors.bluepg[300] }}
            sx={{ height: { xs: 65, sm: 45, md: 60, lg: 70 } }}
          >
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Yurchenko pegasus tot stand met verhoging - 100/110cm met vang |
              125/135cm met vang | 125/135cm alleen
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId22"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId22"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId22"]: newCheckedState,
              });
              updateCheckboxState("checkboxId22", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId23"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId23"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId23"]: newCheckedState,
              });
              updateCheckboxState("checkboxId23", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId24"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId24"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId24"]: newCheckedState,
              });
              updateCheckboxState("checkboxId24", newCheckedState);
            }}
          />
        </Grid>
      </Grid>
      <Grid container sx={{ mt: { xs: 0.5, sm: 0.8, md: 0.5, lg: 0.8 } }}>
        <Grid item xs={9} md={6}>
          <Paper
            style={{ backgroundColor: PgColors.bluepg[300] }}
            sx={{ height: { xs: 65, sm: 45, md: 60, lg: 70 } }}
          >
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Yurchenko met verhoging - tot ruglig | tot kaars gehoekt | tot
              kaars met gestrekte heupen
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId25"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId25"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId25"]: newCheckedState,
              });
              updateCheckboxState("checkboxId25", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId26"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId26"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId26"]: newCheckedState,
              });
              updateCheckboxState("checkboxId26", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId27"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId27"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId27"]: newCheckedState,
              });
              updateCheckboxState("checkboxId27", newCheckedState);
            }}
          />
        </Grid>
      </Grid>
      <Grid container sx={{ mt: { xs: 0.5, sm: 0.8, md: 0.5, lg: 0.8 } }}>
        <Grid item xs={9} md={6}>
          <Paper
            style={{ backgroundColor: PgColors.bluepg[300] }}
            sx={{ height: { xs: 40, sm: 25, md: 60, lg: 70 } }}
          >
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Yurchenko, salto naar valkuil - met vang | met begeleiding |
              alleen
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId28"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId28"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId28"]: newCheckedState,
              });
              updateCheckboxState("checkboxId28", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId29"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId29"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId29"]: newCheckedState,
              });
              updateCheckboxState("checkboxId29", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId30"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId30"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId30"]: newCheckedState,
              });
              updateCheckboxState("checkboxId30", newCheckedState);
            }}
          />
        </Grid>
      </Grid>
      <Grid container sx={{ mt: { xs: 0.5, sm: 0, md: 0.5, lg: 0.8 } }}>
        <Grid item xs={9} md={6}>
          <Paper
            style={{ backgroundColor: PgColors.bluepg[400] }}
            sx={{ height: { xs: 65, sm: 45, md: 60, lg: 70 } }}
          >
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Yurchenko naar valkuil alleen - tot op plofmatten | tot oop dikke
              mat | tot op 2 dikke matten
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId31"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId31"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId31"]: newCheckedState,
              });
              updateCheckboxState("checkboxId31", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId32"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId32"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId32"]: newCheckedState,
              });
              updateCheckboxState("checkboxId32", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId33"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId33"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId33"]: newCheckedState,
              });
              updateCheckboxState("checkboxId33", newCheckedState);
            }}
          />
        </Grid>
      </Grid>
      <Grid
        container
        sx={{ mt: { xs: 0.5, sm: 0.8, md: 0.5, lg: 0.8 }, mb: { xs: 4 } }}
      >
        <Grid item xs={9} md={6}>
          <Paper
            style={{ backgroundColor: PgColors.bluepg[400] }}
            sx={{ height: { xs: 40, sm: 25, md: 60, lg: 70 } }}
          >
            <Typography
              variant="subtitle1"
              style={{ color: "white" }}
              sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
              align="center"
            >
              Yurchenko wedstrijd situatie - met vang | met begeleiding | alleen
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId34"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId34"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId34"]: newCheckedState,
              });
              updateCheckboxState("checkboxId34", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId35"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId35"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId35"]: newCheckedState,
              });
              updateCheckboxState("checkboxId35", newCheckedState);
            }}
          />
        </Grid>
        <Grid item xs={1} md={1} mt={-1} display="flex" justifyContent="center">
          <Checkbox
            checked={checkboxes["checkboxId36"] || false}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 20, md: 35 },
                color: PgColors.bluepg[200],
              },
            }}
            onClick={() => {
              const newCheckedState = !checkboxes["checkboxId36"];
              setCheckboxes({
                ...checkboxes,
                ["checkboxId36"]: newCheckedState,
              });
              updateCheckboxState("checkboxId36", newCheckedState);
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};
