import React, { useState, useEffect } from "react";
import {
  collection,
  addDoc,
  serverTimestamp,
  query,
  onSnapshot,
  updateDoc,
  doc,
  deleteDoc,
} from "firebase/firestore";
import { Grid, Typography, Container, TextField, Button } from "@mui/material";
import { useAuthContext } from "../../hooks/useAuthContext";
import { db } from "../../firebase/config";
import { PgColors } from "../../configs/colors";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import AddIcon from "@mui/icons-material/Add";
import { format } from "date-fns";

const locales = ["en-us", "en-gb", "zh-cn", "de"];

type LocaleKey = (typeof locales)[number];

interface AdditionalField {
  title: string;
  content: string;
}

type Training = {
  date: Date | null;
  period: string;
  title: string;
  content: string;
  additionalFields: AdditionalField[];
};

export const AddSeason = () => {
  const [name, setName] = useState("");
  const [group, setGroup] = useState("");
  const [date1, setDate1] = useState<Date | null>(null);
  const [period1, setPeriod1] = useState("");
  const [title1, setTitle1] = useState("");
  const [content1, setContent1] = useState("");
  const { user } = useAuthContext();
  const [locale, setLocale] = React.useState<LocaleKey>("en-gb");
  const [trainings, setTrainings] = useState<Training[]>([
    {
      date: null,
      period: "",
      title: "",
      content: "",
      additionalFields: [{ title: "", content: "" }],
    },
  ]);

  const handleAddTraining = () => {
    setTrainings([
      ...trainings,
      {
        date: null,
        period: "",
        title: "",
        content: "",
        additionalFields: [{ title: "", content: "" }],
      },
    ]);
  };

  const handleAddAdditionalFields = (index: number) => {
    const updatedTrainings = [...trainings];
    updatedTrainings[index].additionalFields.push({ title: "", content: "" });
    setTrainings(updatedTrainings);
  };

  const handleAdditionalFieldsChange = (
    trainingIndex: number,
    additionalIndex: number,
    field: keyof AdditionalField, // Specify the property being changed
    value: string
  ) => {
    const updatedTrainings = [...trainings];
    updatedTrainings[trainingIndex].additionalFields[additionalIndex][field] =
      value;
    setTrainings(updatedTrainings);
  };

  const handleTrainingChange = (
    index: number,
    field: keyof Training, // Specify the property being changed
    value: any
  ) => {
    const updatedTrainings = [...trainings];
    updatedTrainings[index][field] = value;
    setTrainings(updatedTrainings);
  };

  //   const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
  //     e.preventDefault();

  //     const ref = collection(db, `SeasonsByCoach/${user.uid}/Seasons`);

  //     await addDoc(ref, {
  //       name: name,
  //       group: group,
  //       date1: date1 ? format(date1, "dd-MM-yyyy") : null,
  //       period1: period1,
  //       title1: title1,
  //       content1: content1,
  //       uid: user.uid,
  //       createdAt: serverTimestamp(),
  //     });
  //     setName("");
  //     setGroup("");
  //     setDate1(null);
  //     setPeriod1("");
  //     setTitle1("");
  //     setContent1("");
  //   };
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const ref = collection(db, `SeasonsByCoach/${user.uid}/Seasons`);

    const formattedTrainings = trainings.map((training) => {
      const formattedAdditionalFields = training.additionalFields.map(
        (field) => ({
          title: field.title,
          content: field.content,
        })
      );

      return {
        date: training.date ? format(training.date, "dd-MM-yyyy") : null,
        period: training.period,
        title: training.title,
        content: training.content,
        additionalFields: formattedAdditionalFields,
      };
    });

    await addDoc(ref, {
      name: name,
      group: group,
      trainings: formattedTrainings,
      uid: user.uid,
      createdAt: serverTimestamp(),
    });

    // Reset form fields and trainings
    setName("");
    setGroup("");
    setTrainings([
      {
        date: null,
        period: "",
        title: "",
        content: "",
        additionalFields: [{ title: "", content: "" }],
      },
    ]);
  };

  return (
    <>
      <Container>
        <form onSubmit={handleSubmit}>
          <Grid container>
            <Grid item xs={12} md={6} marginTop={6}>
              <Typography
                variant="h6"
                style={{ color: PgColors.redpg[200], fontWeight: "bold" }}
              >
                Jaarplan
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} marginTop={4}>
              <Typography
                variant="h6"
                style={{ color: PgColors.redpg[200], fontWeight: "bold" }}
              >
                Groep
              </Typography>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12} md={6} marginTop={1}>
              <Typography
                variant="subtitle1"
                style={{ color: "#000000", fontWeight: "300", fontSize: 14 }}
              >
                Voer een naam in om het jaarplan te omschrijven. De naam zal
                zichtbaar zijn.
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} marginTop={1}>
              <Typography
                variant="subtitle1"
                style={{ color: "#000000", fontWeight: "300", fontSize: 14 }}
              >
                Voer de naam van de groep in waar het jaarplan voor is.
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={5}>
              <TextField
                label="Naam"
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                fullWidth
                value={name}
                onChange={(event) => setName(event.target.value)}
              />
            </Grid>
            <Grid item md={1}></Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Groep Naam"
                style={{
                  color: PgColors.bluepg[200],
                }}
                variant="outlined"
                fullWidth
                value={group}
                onChange={(event) => setGroup(event.target.value)}
              />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item marginTop={4}>
              <Typography
                variant="h6"
                style={{ color: PgColors.redpg[200], fontWeight: "bold" }}
              >
                Trainingen
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item md={6} marginTop={1}>
              <Typography
                variant="subtitle1"
                style={{
                  color: "#000000",
                  fontWeight: "500",
                  fontSize: 14,
                }}
              >
                Voer hier de inhoud van een training in:
              </Typography>
            </Grid>
            <Grid item md={4} marginTop={1}>
              <Typography
                variant="subtitle1"
                style={{
                  color: PgColors.redpg[500],
                  fontWeight: "300",
                  fontSize: 14,
                }}
              >
                Voeg nieuw WU/Toestel/etc. toe aan deze training
              </Typography>
            </Grid>
          </Grid>

          {trainings.map((training: Training, trainingIndex: number) => (
            <Grid container spacing={2} key={trainingIndex}>
              <Grid item md={3}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    value={
                      training.date ? format(training.date, "yyyy-MM-dd") : null
                    }
                    onChange={(newDateStr) => {
                      const newDate = newDateStr ? new Date(newDateStr) : null;
                      handleTrainingChange(trainingIndex, "date", newDate);
                    }}
                    format="DD/MM/YYYY"
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item md={3}>
                <TextField
                  label="Periode"
                  style={{
                    color: PgColors.bluepg[200],
                  }}
                  variant="outlined"
                  fullWidth
                  value={training.period}
                  onChange={(event) =>
                    handleTrainingChange(
                      trainingIndex,
                      "period",
                      event.target.value
                    )
                  }
                />
              </Grid>

              <Grid item md={1}>
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: PgColors.bluepg[200],
                  }}
                  size="small"
                  sx={{ height: 25 }}
                  onClick={() => handleAddAdditionalFields(trainingIndex)}
                >
                  <AddIcon />
                </Button>
              </Grid>
              <Grid item md={3}>
                {training.additionalFields.map(
                  (
                    additionalField: { title: string; content: string },
                    additionalIndex: number
                  ) => (
                    <div key={additionalIndex}>
                      <TextField
                        label={`WU/Toestel/etc ${additionalIndex + 1}`}
                        style={{ color: PgColors.bluepg[200] }}
                        variant="outlined"
                        fullWidth
                        value={additionalField.title}
                        onChange={(event) =>
                          handleAdditionalFieldsChange(
                            trainingIndex,
                            additionalIndex,
                            "title",
                            event.target.value
                          )
                        }
                      />
                      <TextField
                        label={`Inhoud ${additionalIndex + 1}`}
                        style={{ color: PgColors.bluepg[200] }}
                        variant="outlined"
                        fullWidth
                        multiline
                        minRows={3}
                        value={additionalField.content}
                        onChange={(event) =>
                          handleAdditionalFieldsChange(
                            trainingIndex,
                            additionalIndex,
                            "content",
                            event.target.value
                          )
                        }
                      />
                    </div>
                  )
                )}
              </Grid>
            </Grid>
          ))}

          <Grid container>
            <Grid item md={4} xs={12} mt={2}>
              <Button
                style={{
                  backgroundColor: PgColors.bluepg[200],
                }}
                variant="contained"
                size="large"
                sx={{ height: 55 }}
                onClick={handleAddTraining}
              >
                <Typography variant="subtitle1">
                  Voeg nieuwe training toe aan jaarplan
                </Typography>
              </Button>
            </Grid>
          </Grid>
          <Grid container mt={4} justifyContent="end" display="flex">
            <Grid item md={4} xs={12} justifyContent="end" display="flex">
              <Button
                style={{
                  backgroundColor: PgColors.redpg[200],
                }}
                variant="contained"
                type="submit"
                size="large"
                sx={{ height: 55 }}
              >
                <Typography variant="subtitle1">Voeg jaarplan toe</Typography>
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </>
  );
};
