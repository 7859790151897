import React, { useState, useEffect } from "react";
import {
  collection,
  query,
  onSnapshot,
  updateDoc,
  doc,
  deleteDoc,
} from "firebase/firestore";
import { db } from "../../../../firebase/config";
import {
  Container,
  Grid,
  Typography,
  Checkbox,
  Paper,
  TextField,
  Button,
  Modal,
  Toolbar,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Box,
  Drawer,
  Divider,
} from "@mui/material";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { useAuthContext } from "../../../../hooks/useAuthContext";
import { useParams } from "react-router-dom";
import { PgColors } from "../../../../configs/colors";

export const GoalsList = ({ goals }: any, { path }: any) => {
  const { id } = useParams();

  const toggleComplete = async (goal: any) => {
    await updateDoc(doc(db, `Goals/${id}/userGoals/${goal.id}`), {
      completed: !goal.completed,
    });
  };

  const handleDelete = async (goal: any) => {
    const ref = doc(db, `Goals/${id}/userGoals/${goal.id}`);
    await deleteDoc(ref);
  };

  return (
    <>
      <Container>
        {goals.map((goal: any) => (
          <>
            <Grid
              container
              key={goal.id}
              spacing={2}
              alignItems="center"
              display={{ xs: "none", md: "flex" }}
            >
              <Grid item md={6}>
                <Typography
                  variant="subtitle1"
                  style={{
                    color: PgColors.bluepg[200],
                    fontSize: 20,
                    fontWeight: 450,
                  }}
                >
                  {goal.goal}
                </Typography>
              </Grid>
              <Grid item md={2}>
                <Typography
                  variant="subtitle1"
                  style={{
                    color: PgColors.bluepg[200],
                    fontSize: 20,
                    fontWeight: 450,
                  }}
                >
                  {goal.apparatus}
                </Typography>
              </Grid>
              <Grid item md={2}>
                <Typography
                  variant="subtitle1"
                  style={{
                    color: PgColors.bluepg[200],
                    fontSize: 20,
                    fontWeight: 450,
                  }}
                >
                  {goal.date}
                </Typography>
              </Grid>
              <Grid item md={1}>
                <Checkbox
                  checked={goal.completed}
                  sx={{
                    "& .MuiSvgIcon-root": {
                      fontSize: 36,
                      color: PgColors.bluepg[200],
                    },
                  }}
                  onClick={() => toggleComplete(goal)}
                />
              </Grid>
              <Grid item md={1}>
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: PgColors.bluepg[200],
                  }}
                  onClick={() => handleDelete(goal)}
                >
                  <DeleteOutlinedIcon />
                </Button>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              alignItems="center"
              display={{ xs: "flex", md: "none" }}
            >
              <Grid item xs={3}>
                <Typography
                  variant="subtitle1"
                  style={{ color: PgColors.redpg[500] }}
                  sx={{ fontSize: { xs: 14, md: 16, lg: 20 } }}
                  align="left"
                >
                  Toestel:
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography
                  variant="subtitle1"
                  style={{
                    color: PgColors.bluepg[200],
                  }}
                  sx={{ fontSize: { xs: 14, md: 16, lg: 20 } }}
                  align="left"
                >
                  {goal.apparatus}
                </Typography>
              </Grid>
              <Grid item xs={3} mt={-2}>
                <Typography
                  variant="subtitle1"
                  style={{ color: PgColors.redpg[500] }}
                  sx={{ fontSize: { xs: 14, md: 16, lg: 20 } }}
                  align="left"
                >
                  Doel:
                </Typography>
              </Grid>
              <Grid item xs={9} mt={-2}>
                <Typography
                  variant="subtitle1"
                  style={{
                    color: PgColors.bluepg[200],
                  }}
                  sx={{ fontSize: { xs: 14, md: 16, lg: 20 } }}
                >
                  {goal.goal}
                </Typography>
              </Grid>

              <Grid item xs={3} mt={-2}>
                <Typography
                  variant="subtitle1"
                  style={{ color: PgColors.redpg[500] }}
                  sx={{ fontSize: { xs: 14, md: 16, lg: 20 } }}
                  align="left"
                >
                  Datum:
                </Typography>
              </Grid>

              <Grid
                item
                xs={6}
                mt={-2}
                sx={{
                  marginRight: { xs: -2, sm: 0 },
                }}
              >
                <Typography
                  variant="subtitle1"
                  style={{
                    color: PgColors.bluepg[200],
                  }}
                  sx={{ fontSize: { xs: 14, md: 16, lg: 20 } }}
                >
                  {goal.date}
                </Typography>
              </Grid>
              <Grid
                item
                xs={1}
                mt={-2}
                display="flex"
                sx={{
                  marginRight: { xs: 2, sm: 2, md: 1 },
                }}
              >
                <Checkbox
                  checked={goal.completed}
                  sx={{
                    "& .MuiSvgIcon-root": {
                      fontSize: 30,
                      color: PgColors.bluepg[200],
                    },
                  }}
                  onClick={() => toggleComplete(goal)}
                />
              </Grid>

              <Grid item xs={1} mb={2}>
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: PgColors.bluepg[200],
                  }}
                  sx={{ minWidth: 20 }}
                  onClick={() => handleDelete(goal)}
                >
                  <DeleteOutlinedIcon />
                </Button>
              </Grid>
            </Grid>
            <Divider></Divider>
          </>
        ))}
      </Container>
    </>
  );
};
