import React, { useEffect, useState } from "react";
import {
  Grid,
  Container,
  Typography,
  Paper,
  TextField,
  Button,
  ButtonGroup,
  Box,
  Drawer,
  Divider,
} from "@mui/material";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { useAuthContext } from "../../../hooks/useAuthContext";
import { db } from "../../../firebase/config";
import { useParams } from "react-router-dom";
import { Name2 } from "./Name2";
import { Goals2Page } from "./Goals/Goals2Page";
import { SkillTracker2Page } from "./SkillTracker/SkillTracker2Page";
import { Competition2Page } from "./Competition/Competition2Page";
import { DScore2Page } from "./D-Score/DScore2Page";
import { ChecklistPage2 } from "./ChecklistGymnast/ChecklistPage2";
import {
  collection,
  query,
  onSnapshot,
  where,
  orderBy,
  addDoc,
  serverTimestamp,
  doc,
  setDoc,
  onSnapshotsInSync,
} from "firebase/firestore";
import { PgColors } from "../../../configs/colors";
import { SkillsPage } from "./SkillsGymnast/SkillsPage";
import { WorkbookPage } from "./WorkbookGymnast/WorkbookPage";

export const Gymnast2Page = () => {
  const { user } = useAuthContext();
  const { id } = useParams();
  const [gymnast, setGymnast] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [active, setActive] = useState("Goals");
  const [users, setUsers] = useState([]);
  const activeColor = PgColors.bluepg[200];

  if (user !== null) {
    console.log(user.uid);
  }

  return (
    <>
      <Container disableGutters={true}>
        <Box
          sx={{
            mt: { xs: 2, lg: 6 },
            marginLeft: { lg: 36, xl: 16 },
            marginBottom: { xs: 4, md: 8 },
            width: "100%",
          }}
        >
          <Paper
            elevation={2}
            sx={{
              width: { xs: 350, sm: 580, md: 880, lg: 880, xl: 1200 },
              minHeight: { xs: 750, md: 850 },
            }}
          >
            <Container>
              <Name2 path={`users`} />
              <Grid container sx={{ display: { xs: "none", md: "flex" } }}>
                <Grid
                  item
                  sx={{ marginTop: { xs: 0, md: 2 } }}
                  xs={12}
                  justifyContent="center"
                  display="flex"
                >
                  <ButtonGroup variant="text">
                    <Button onClick={() => setActive("Goals")}>
                      <Typography
                        variant="subtitle1"
                        style={{
                          color:
                            active === "Goals"
                              ? activeColor
                              : PgColors.redpg[200],

                          fontWeight: 500,
                          fontSize: 14,
                        }}
                        sx={{
                          fontSize: { xs: 12, md: 14 },
                        }}
                      >
                        Goals
                      </Typography>
                    </Button>
                    <Button onClick={() => setActive("Checklist")}>
                      <Typography
                        variant="subtitle1"
                        style={{
                          color:
                            active === "Checklist"
                              ? activeColor
                              : PgColors.redpg[200],
                          fontWeight: 500,
                          fontSize: 14,
                        }}
                        sx={{
                          fontSize: { xs: 12, md: 14 },
                        }}
                      >
                        Checklist
                      </Typography>
                    </Button>
                    <Button onClick={() => setActive("Skills")}>
                      <Typography
                        variant="subtitle1"
                        style={{
                          color:
                            active === "Skills"
                              ? activeColor
                              : PgColors.redpg[200],
                          fontWeight: 500,
                          fontSize: 14,
                        }}
                        sx={{
                          fontSize: { xs: 12, md: 14 },
                        }}
                      >
                        Skills
                      </Typography>
                    </Button>
                    <Button onClick={() => setActive("Competitions")}>
                      <Typography
                        variant="subtitle1"
                        style={{
                          color:
                            active === "Competitions"
                              ? activeColor
                              : PgColors.redpg[200],
                          fontWeight: 500,
                          fontSize: 14,
                        }}
                        sx={{
                          fontSize: { xs: 12, md: 14 },
                        }}
                      >
                        Competition Scores
                      </Typography>
                    </Button>
                    <Button onClick={() => setActive("D-scores")}>
                      <Typography
                        variant="subtitle1"
                        style={{
                          color:
                            active === "D-scores"
                              ? activeColor
                              : PgColors.redpg[200],
                          fontWeight: 500,
                        }}
                        sx={{
                          fontSize: { xs: 12, md: 14 },
                        }}
                      >
                        D-scores
                      </Typography>
                    </Button>
                    <Button onClick={() => setActive("Workbook")}>
                      <Typography
                        variant="subtitle1"
                        style={{
                          color:
                            active === "Workbook"
                              ? activeColor
                              : PgColors.redpg[200],
                          fontWeight: 500,
                        }}
                        sx={{
                          fontSize: { xs: 12, md: 14 },
                        }}
                      >
                        Workbook
                      </Typography>
                    </Button>
                  </ButtonGroup>
                </Grid>
              </Grid>
              <Grid container sx={{ display: { xs: "flex", md: "none" } }}>
                <Grid
                  item
                  sx={{ marginTop: { xs: 0, md: 2 } }}
                  xs={12}
                  justifyContent="center"
                  display="flex"
                >
                  <ButtonGroup variant="text">
                    <Button onClick={() => setActive("Goals")}>
                      <Typography
                        variant="subtitle1"
                        style={{
                          color:
                            active === "Goals"
                              ? activeColor
                              : PgColors.redpg[200],

                          fontWeight: 500,
                        }}
                        sx={{
                          fontSize: { xs: 12, md: 14 },
                        }}
                      >
                        Goals
                      </Typography>
                    </Button>
                    <Button onClick={() => setActive("Checklist")}>
                      <Typography
                        variant="subtitle1"
                        style={{
                          color:
                            active === "Checklist"
                              ? activeColor
                              : PgColors.redpg[200],
                          fontWeight: 500,
                        }}
                        sx={{
                          fontSize: { xs: 12, md: 14 },
                        }}
                      >
                        Checklist
                      </Typography>
                    </Button>
                    <Button onClick={() => setActive("Skills")}>
                      <Typography
                        variant="subtitle1"
                        style={{
                          color:
                            active === "Skills"
                              ? activeColor
                              : PgColors.redpg[200],
                          fontWeight: 500,
                        }}
                        sx={{
                          fontSize: { xs: 12, md: 14 },
                        }}
                      >
                        Skills
                      </Typography>
                    </Button>
                  </ButtonGroup>
                </Grid>
              </Grid>
              <Grid container sx={{ display: { xs: "flex", md: "none" } }}>
                <Grid
                  item
                  sx={{ marginTop: { xs: 0, md: 2 } }}
                  xs={12}
                  justifyContent="center"
                  display="flex"
                >
                  <ButtonGroup variant="text">
                    <Button onClick={() => setActive("Competitions")}>
                      <Typography
                        variant="subtitle1"
                        style={{
                          color:
                            active === "Competitions"
                              ? activeColor
                              : PgColors.redpg[200],
                          fontWeight: 500,
                        }}
                        sx={{
                          fontSize: { xs: 12, md: 14 },
                        }}
                      >
                        Competition Scores
                      </Typography>
                    </Button>
                    <Button onClick={() => setActive("D-scores")}>
                      <Typography
                        variant="subtitle1"
                        style={{
                          color:
                            active === "D-scores"
                              ? activeColor
                              : PgColors.redpg[200],
                          fontWeight: 500,
                        }}
                        sx={{
                          fontSize: { xs: 12, md: 14 },
                        }}
                      >
                        D-scores
                      </Typography>
                    </Button>
                    <Button onClick={() => setActive("Workbook")}>
                      <Typography
                        variant="subtitle1"
                        style={{
                          color:
                            active === "Workbook"
                              ? activeColor
                              : PgColors.redpg[200],
                          fontWeight: 500,
                        }}
                        sx={{
                          fontSize: { xs: 12, md: 14 },
                        }}
                      >
                        Workbook
                      </Typography>
                    </Button>
                  </ButtonGroup>
                </Grid>
              </Grid>
              <Divider></Divider>
              {active === "Goals" && <Goals2Page />}
              {active === "Checklist" && <ChecklistPage2 />}
              {active === "Skills" && <SkillsPage />}
              {active === "Competitions" && <Competition2Page />}
              {active === "D-scores" && <DScore2Page />}
              {active === "Workbook" && <WorkbookPage />}
            </Container>
          </Paper>
        </Box>
      </Container>
    </>
  );
};
