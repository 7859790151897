import React, { useState, useEffect } from "react";
import { collection, query, onSnapshot, orderBy } from "firebase/firestore";
import {
  Grid,
  Container,
  Typography,
  Paper,
  Button,
  ButtonGroup,
} from "@mui/material";
import { useAuthContext } from "../../../../hooks/useAuthContext";
import { useParams } from "react-router-dom";
import { db } from "../../../../firebase/config";
import { PgColors } from "../../../../configs/colors";
import { Tsuki } from "./Tsuki";
import { Yurchenko } from "./Yurchenko";
import { KasamatsuVault } from "./KasamatsuVault";
import { HalfIn } from "./HalfIn";

export const VaultSkills = () => {
  const [active, setActive] = useState("1/2 in");
  const activeColor = PgColors.bluepg[400];

  return (
    <>
      <Grid container mt={2}>
        <Grid item xs={12} display="flex" justifyContent="center">
          <ButtonGroup variant="contained">
            <Button
              style={{
                backgroundColor:
                  active === "1/2 in" ? activeColor : PgColors.bluepg[200],
              }}
              variant="contained"
              onClick={() => setActive("1/2 in")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 10, md: 14 } }}
              >
                1/2 in - 1/2 uit
              </Typography>
            </Button>
            <Button
              style={{
                backgroundColor:
                  active === "Tsuki" ? activeColor : PgColors.bluepg[200],
              }}
              variant="contained"
              onClick={() => setActive("Tsuki")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 10, md: 14 } }}
              >
                Tsukahara
              </Typography>
            </Button>
            <Button
              style={{
                backgroundColor:
                  active === "Kasamatsu" ? activeColor : PgColors.bluepg[200],
              }}
              variant="contained"
              onClick={() => setActive("Kasamatsu")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 10, md: 14 } }}
              >
                Kasamatsu
              </Typography>
            </Button>
            <Button
              style={{
                backgroundColor:
                  active === "Yurchenko" ? activeColor : PgColors.bluepg[200],
              }}
              variant="contained"
              onClick={() => setActive("Yurchenko")}
            >
              <Typography
                style={{ fontWeight: "semibold" }}
                sx={{ fontSize: { xs: 10, md: 14 } }}
              >
                Yurchenko
              </Typography>
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid>
      <Grid container>{active === "1/2 in" && <HalfIn />}</Grid>
      <Grid container>{active === "Tsuki" && <Tsuki />}</Grid>
      <Grid container>{active === "Kasamatsu" && <KasamatsuVault />}</Grid>
      <Grid container>{active === "Yurchenko" && <Yurchenko />}</Grid>
    </>
  );
};
