import React, { useState, useEffect } from "react";
import { Grid, Container } from "@mui/material";
import { Sup7Intro } from "./Sup7Intro";
import { Sup7Bars } from "./Sup7Bars";
import { Sup7Beam } from "./Sup7Beam";
import { Sup7Floor } from "./Sup7Floor";
import { Sup7Vault } from "./Sup7Vault";

export const Sup7 = () => {
  return (
    <>
      <Container>
        <Sup7Intro />
        <Sup7Vault />
        <Sup7Bars />
        <Sup7Beam />
        <Sup7Floor />
      </Container>
    </>
  );
};
