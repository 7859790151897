import React, { useState, useEffect } from "react";
import {
  collection,
  query,
  onSnapshot,
  orderBy,
  updateDoc,
  doc,
  deleteDoc,
} from "firebase/firestore";
import { db } from "../../firebase/config";
import { Grid, Container, Typography, Divider, Button } from "@mui/material";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useNavigate } from "react-router-dom";

export const AllSeasons = () => {
  const { authIsReady, user } = useAuthContext();
  const [seasonsList, setSeasonsList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (authIsReady && user) {
      const ref = query(
        collection(db, `SeasonsByCoach/${user.uid}/Seasons`),
        orderBy("createdAt", "desc")
      );

      const unsub = onSnapshot(ref, (snapshot) => {
        let results: any = [];
        snapshot.docs.forEach((doc) => {
          results.push({ ...doc.data(), id: doc.id });
        });
        setSeasonsList(results);
      });

      return () => unsub();
    }
  }, [authIsReady, user]);

  const handleEvent = (
    params: any,
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    navigate(`SeasonsByCoach/${user.uid}/Seasons/${params.id}`);
  };

  if (!authIsReady) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <Container>
        <Grid container>
          <Grid item marginTop={6}>
            <Typography
              variant="h6"
              style={{ color: "#6B3239", fontWeight: "bold" }}
            >
              Alle Jaarplannen:
            </Typography>
          </Grid>
        </Grid>
        {seasonsList.map((season: any) => (
          <>
            <Grid container mt={2}>
              <Grid item>
                <Button
                  variant="text"
                  onClick={(e) => navigate(`${season.id}`)}
                >
                  <Typography
                    variant="subtitle1"
                    style={{
                      color: "#000000",
                      fontWeight: "400",
                      fontSize: 20,
                    }}
                  >
                    {season.name}
                  </Typography>
                </Button>
              </Grid>
            </Grid>
            {/* const Component =  */}
            <Divider></Divider>
          </>
        ))}
      </Container>
    </>
  );
};
