import React, { useState } from "react";
import {
  Grid,
  Container,
  Typography,
  Paper,
  TextField,
  Button,
  AppBar,
  Toolbar,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Box,
  Drawer,
} from "@mui/material";
import { VerplichteOefenstof } from "./VerplichteOefenstof";
import { Elementen } from "./Elementen";
import { KeuzeOefenstof } from "./KeuzeOefenstof";
import { PgColors } from "../../configs/colors";

export const Regulations = () => {
  const [active, setActive] = useState("KO");

  return (
    <>
      <Container disableGutters={true}>
        <Grid
          container
          display="flex"
          spacing={2}
          sx={{ marginLeft: { xs: 0, lg: 36 }, marginTop: { xs: 2, md: 10 } }}
        >
          <Grid item>
            <Button
              style={{
                backgroundColor: PgColors.bluepg[200],
              }}
              variant="contained"
              onClick={() => setActive("KO")}
            >
              <Typography style={{ fontWeight: "semibold" }}>
                Keuze Oefenstof
              </Typography>
            </Button>
          </Grid>
          <Grid item>
            <Button
              style={{
                backgroundColor: PgColors.bluepg[200],
              }}
              variant="contained"
              onClick={() => setActive("VO")}
            >
              <Typography style={{ fontWeight: "semibold" }}>
                Middenbouw Oefenstof
              </Typography>
            </Button>
          </Grid>
          <Grid item>
            <Button
              style={{
                backgroundColor: PgColors.bluepg[200],
              }}
              variant="contained"
              onClick={() => setActive("El")}
            >
              <Typography style={{ fontWeight: "semibold" }}>
                Elementen
              </Typography>
            </Button>
          </Grid>
        </Grid>

        <Grid
          item
          display="flex"
          justifyContent="center"
          xs={12}
          marginTop={4}
          marginBottom={4}
          sx={{ marginLeft: { lg: 36 } }}
        >
          <Paper
            elevation={2}
            sx={{
              minWidth: { xs: 350, sm: 600, md: 880, lg: 850, xl: 1200 },
              maxWidth: { xs: 590, sm: 890, md: 1100 },
              minHeight: { xs: 850 },
            }}
          >
            <Container>{active === "KO" && <KeuzeOefenstof />}</Container>
            <Container>{active === "VO" && <VerplichteOefenstof />}</Container>

            <Container>{active === "El" && <Elementen />}</Container>
          </Paper>
        </Grid>
      </Container>
    </>
  );
};
