import React, { useState, useEffect } from "react";
import {
  Grid,
  Container,
  Typography,
  Divider,
  Button,
  Paper,
} from "@mui/material";
import { PgColors } from "../../../configs/colors";

export const Sup1Floor = () => {
  return (
    <>
      <Grid container mt={2} marginLeft={2}>
        <Grid item>
          <Typography variant="h6" sx={{ color: PgColors.redpg[500] }}>
            Vloer
          </Typography>
        </Grid>
      </Grid>
      <Grid container marginLeft={2}>
        <Grid item>
          <Typography
            variant="subtitle1"
            sx={{
              color: PgColors.redpg[500],
              fontSize: { xs: 12, sm: 14, md: 16 },
            }}
            align="left"
          >
            *Met diagonale dempingsmat
          </Typography>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item mt={0} xs={12}>
          <Paper
            elevation={2}
            sx={{ backgroundColor: PgColors.bluepg[100], width: "100%" }}
          >
            <Container>
              <Grid
                container
                marginTop={0}
                sx={{ borderBottom: 1, borderColor: PgColors.bluepg[200] }}
                display={{ xs: "none", md: "flex" }}
              >
                <Grid
                  item
                  md={1}
                  display={{ xs: "none", md: "flex" }}
                  sx={{ marginRight: { md: -4, lg: -8 } }}
                ></Grid>
                <Grid item xs={11} md={5}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontWeight: 500,
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    Samenstellingselement
                  </Typography>
                </Grid>
                <Grid item xs={1} md={1} sx={{ pl: { md: 2 } }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontWeight: 500,
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    D
                  </Typography>
                </Grid>

                <Grid item md={5} display={{ xs: "none", md: "flex" }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontWeight: 500,
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    Bonus
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{
                    mr: { md: -4 },
                  }}
                  display={{ xs: "none", md: "flex" }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontWeight: 500,
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    D
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                container
                marginTop={1}
                sx={{ borderBottom: 1, borderColor: PgColors.bluepg[200] }}
                display={{ xs: "none", md: "flex" }}
              >
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{ marginRight: { md: -4, lg: -8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    1
                  </Typography>
                </Grid>
                <Grid item xs={10} md={5}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    Danspassage samengesteld uit 2 versch sprongen, direct of
                    indirect verb, beide sprongen met beenspreiding min B
                  </Typography>
                </Grid>
                <Grid item xs={1} md={1} sx={{ pl: { md: 2 } }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    0.50
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={5}
                  display={{ xs: "none", md: "flex" }}
                  sx={{ pr: { md: 2 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    SE 1 uitgevoerd met wisselspagaat ½ / Johnson ½ (1.304) of
                    kadet (1.202) of kadet ½ draai (1.302)
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={1}
                  display={{ xs: "none", md: "flex" }}
                  sx={{ mr: { md: -4 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    0.30
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                container
                marginTop={1}
                sx={{ borderBottom: 1, borderColor: PgColors.bluepg[200] }}
                display={{ xs: "none", md: "flex" }}
              >
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{ marginRight: { md: -4, lg: -8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    2
                  </Typography>
                </Grid>
                <Grid item xs={10} md={5}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    Arabier - flikflak met salto ao min B
                  </Typography>
                </Grid>
                <Grid item xs={1} md={1} sx={{ pl: { md: 2 } }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    0.50
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={5}
                  sx={{ pr: { md: 2 } }}
                  display={{ xs: "none", md: "flex" }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    Ipv SE 2: Arabier - (flikflak -) temposatlo - salto ao min B
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={1}
                  display={{ xs: "none", md: "flex" }}
                  sx={{ mr: { md: -4 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    0.30
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                container
                marginTop={1}
                sx={{ borderBottom: 1, borderColor: PgColors.bluepg[200] }}
                display={{ xs: "none", md: "flex" }}
              >
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{ marginRight: { md: -4, lg: -8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    3
                  </Typography>
                </Grid>
                <Grid item xs={10} md={5}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    Acrolijn met overslag en salto vo min B óf acrolijn van min
                    3 elementen met overslag en salto vo
                  </Typography>
                </Grid>
                <Grid item xs={1} md={1} sx={{ pl: { md: 2 } }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    0.50
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={5}
                  sx={{ pr: { md: 2 } }}
                  display={{ xs: "none", md: "flex" }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    SE 3 met punch salto vo
                  </Typography>
                </Grid>
                <Grid item md={1} sx={{ mr: { md: -4 } }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    0.30
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                container
                marginTop={1}
                sx={{ borderBottom: 1, borderColor: PgColors.bluepg[200] }}
                display={{ xs: "none", md: "flex" }}
              >
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{ marginRight: { md: -4, lg: -8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    4
                  </Typography>
                </Grid>
                <Grid item xs={10} md={5}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    Aerial in verb met een element min A
                  </Typography>
                </Grid>
                <Grid item xs={1} md={1} sx={{ pl: { md: 2 } }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    0.50
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={5}
                  display={{ xs: "none", md: "flex" }}
                  sx={{ pr: { md: 2 } }}
                ></Grid>
                <Grid
                  item
                  md={1}
                  display={{ xs: "none", md: "flex" }}
                  sx={{ mr: { md: -4 } }}
                ></Grid>
              </Grid>

              <Grid
                container
                marginTop={1}
                sx={{ borderBottom: 1, borderColor: PgColors.bluepg[200] }}
                display={{ xs: "none", md: "flex" }}
              >
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{ marginRight: { md: -4, lg: -8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    5
                  </Typography>
                </Grid>
                <Grid item xs={10} md={5}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    Stut tot handstand met open 1/1 draai
                  </Typography>
                </Grid>
                <Grid item xs={1} md={1} sx={{ pl: { md: 2 } }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    0.50
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={5}
                  display={{ xs: "none", md: "flex" }}
                  sx={{ pr: { md: 2 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    SE 5: Na het breiken van de handstand na de 1/1 draai wordt
                    teruggerold
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={1}
                  display={{ xs: "none", md: "flex" }}
                  sx={{ mr: { md: -4 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    0.30
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                container
                marginTop={1}
                sx={{ borderBottom: 1, borderColor: PgColors.bluepg[200] }}
                display={{ xs: "none", md: "flex" }}
              >
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{ marginRight: { md: -4, lg: -8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    6
                  </Typography>
                </Grid>
                <Grid item xs={10} md={5}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    Een pirouette min B (max. afwijking 45º)
                  </Typography>
                </Grid>
                <Grid item xs={1} md={1} sx={{ pl: { md: 2 } }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    0.50
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={5}
                  display={{ xs: "none", md: "flex" }}
                  sx={{ pr: { md: 2 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    SE 6: Een 2e pirouette min B uit een verschillende lijn
                    (max. afwijking 45º)
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={1}
                  display={{ xs: "none", md: "flex" }}
                  sx={{ mr: { md: -4 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    0.30
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                container
                marginTop={1}
                display={{ xs: "none", md: "flex" }}
              >
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{ marginRight: { md: -4, lg: -8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    7
                  </Typography>
                </Grid>
                <Grid item xs={10} md={5}></Grid>
                <Grid item xs={1}></Grid>
                <Grid
                  item
                  md={5}
                  display={{ xs: "none", md: "flex" }}
                  sx={{ pr: { md: 2 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    Eén acrolijn met een salto min C
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={1}
                  display={{ xs: "none", md: "flex" }}
                  sx={{ mr: { md: -4 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    0.30
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                mt={2}
                display={{ xs: "flex", md: "none" }}
                sx={{ borderBottom: 1, borderColor: PgColors.bluepg[200] }}
              >
                <Grid item xs={11} md={4} sx={{ marginRight: { md: 10 } }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontWeight: 500,
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    Samenstellingselement
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontWeight: 500,
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    D
                  </Typography>
                </Grid>
              </Grid>
              <Grid container mt={1} display={{ xs: "flex", md: "none" }}>
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{ marginRight: { md: -4, lg: -8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    1
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={10}
                  sx={{ paddingRight: { md: 0 }, marginRight: { md: 8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    Danspassage samengesteld uit 2 versch sprongen, direct of
                    indirect verb, beide sprongen met beenspreiding min B
                  </Typography>
                </Grid>
                <Grid item xs={1} md={1} sx={{ marginRight: { md: 0 } }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    0.50
                  </Typography>
                </Grid>
              </Grid>
              <Grid container mt={1} display={{ xs: "flex", md: "none" }}>
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{ marginRight: { md: -4, lg: -8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    2
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={10}
                  sx={{ paddingRight: { md: 0 }, marginRight: { md: 8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    Arabier - flikflak met salto ao min B
                  </Typography>
                </Grid>
                <Grid item xs={1} md={1} sx={{ marginRight: { md: 0 } }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    0.50
                  </Typography>
                </Grid>
              </Grid>
              <Grid container mt={1} display={{ xs: "flex", md: "none" }}>
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{ marginRight: { md: -4, lg: -8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    3
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={10}
                  sx={{ paddingRight: { md: 0 }, marginRight: { md: 8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    Acrolijn met overslag en salto vo min B óf acrolijn van min
                    3 elementen met overslag en salto vo
                  </Typography>
                </Grid>
                <Grid item xs={1} md={1} sx={{ marginRight: { md: 0 } }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    0.50
                  </Typography>
                </Grid>
              </Grid>
              <Grid container mt={1} display={{ xs: "flex", md: "none" }}>
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{ marginRight: { md: -4, lg: -8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    4
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={10}
                  sx={{ paddingRight: { md: 0 }, marginRight: { md: 8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    Aerial in verb met een element min A
                  </Typography>
                </Grid>
                <Grid item xs={1} md={1} sx={{ marginRight: { md: 0 } }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    0.50
                  </Typography>
                </Grid>
              </Grid>
              <Grid container mt={1} display={{ xs: "flex", md: "none" }}>
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{ marginRight: { md: -4, lg: -8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    5
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={10}
                  sx={{ paddingRight: { md: 0 }, marginRight: { md: 8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    Stut tot handstand met open 1/1 draai
                  </Typography>
                </Grid>
                <Grid item xs={1} md={1} sx={{ marginRight: { md: 0 } }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    0.50
                  </Typography>
                </Grid>
              </Grid>
              <Grid container mt={1} display={{ xs: "flex", md: "none" }}>
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{ marginRight: { md: -4, lg: -8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    6
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={10}
                  sx={{ paddingRight: { md: 0 }, marginRight: { md: 8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    Een pirouette min B (max. afwijking 45º)
                  </Typography>
                </Grid>
                <Grid item xs={1} md={1} sx={{ marginRight: { md: 0 } }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    0.50
                  </Typography>
                </Grid>
              </Grid>
              <Grid container mt={1} display={{ xs: "flex", md: "none" }}>
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{ marginRight: { md: -4, lg: -8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    7
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={10}
                  sx={{ paddingRight: { md: 0 }, marginRight: { md: 8 } }}
                ></Grid>
                <Grid item xs={1} md={1} sx={{ marginRight: { md: 0 } }}></Grid>
              </Grid>

              <Grid
                container
                mt={2}
                display={{ xs: "flex", md: "none" }}
                sx={{ borderBottom: 1, borderColor: PgColors.bluepg[200] }}
              >
                <Grid item xs={11} md={4} sx={{ marginRight: { md: 10 } }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontWeight: 500,
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    Bonus
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontWeight: 500,
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    D
                  </Typography>
                </Grid>
              </Grid>

              <Grid container mt={1} display={{ xs: "flex", md: "none" }}>
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{ marginRight: { md: -4, lg: -8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    1
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={10}
                  sx={{ paddingRight: { md: 0 }, marginRight: { md: 8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    SE 1 uitgevoerd met wisselspagaat ½ / Johnson ½ (1.304) of
                    kadet (1.202) of kadet ½ draai (1.302)
                  </Typography>
                </Grid>
                <Grid item xs={1} md={1} sx={{ marginRight: { md: 0 } }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    0.30
                  </Typography>
                </Grid>
              </Grid>
              <Grid container mt={1} display={{ xs: "flex", md: "none" }}>
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{ marginRight: { md: -4, lg: -8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    2
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={10}
                  sx={{ paddingRight: { md: 0 }, marginRight: { md: 8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    Ipv SE 2: Arabier - (flikflak -) temposatlo - salto ao min B
                  </Typography>
                </Grid>
                <Grid item xs={1} md={1} sx={{ marginRight: { md: 0 } }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    0.30
                  </Typography>
                </Grid>
              </Grid>
              <Grid container mt={1} display={{ xs: "flex", md: "none" }}>
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{ marginRight: { md: -4, lg: -8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    3
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={10}
                  sx={{ paddingRight: { md: 0 }, marginRight: { md: 8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    SE 3 met punch salto vo
                  </Typography>
                </Grid>
                <Grid item xs={1} md={1} sx={{ marginRight: { md: 0 } }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    0.30
                  </Typography>
                </Grid>
              </Grid>
              <Grid container mt={1} display={{ xs: "flex", md: "none" }}>
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{ marginRight: { md: -4, lg: -8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    4
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={10}
                  sx={{ paddingRight: { md: 0 }, marginRight: { md: 8 } }}
                ></Grid>
                <Grid item xs={1} md={1} sx={{ marginRight: { md: 0 } }}></Grid>
              </Grid>
              <Grid container mt={1} display={{ xs: "flex", md: "none" }}>
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{ marginRight: { md: -4, lg: -8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    5
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={10}
                  sx={{ paddingRight: { md: 0 }, marginRight: { md: 8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    SE 5: Na het breiken van de handstand na de 1/1 draai wordt
                    teruggerold
                  </Typography>
                </Grid>
                <Grid item xs={1} md={1} sx={{ marginRight: { md: 0 } }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    0.30
                  </Typography>
                </Grid>
              </Grid>
              <Grid container mt={1} display={{ xs: "flex", md: "none" }}>
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{ marginRight: { md: -4, lg: -8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    6
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={10}
                  sx={{ paddingRight: { md: 0 }, marginRight: { md: 8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    SE 6: Een 2e pirouette min B uit een verschillende lijn
                    (max. afwijking 45º)
                  </Typography>
                </Grid>
                <Grid item xs={1} md={1} sx={{ marginRight: { md: 0 } }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    0.30
                  </Typography>
                </Grid>
              </Grid>
              <Grid container mt={1} display={{ xs: "flex", md: "none" }}>
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{ marginRight: { md: -4, lg: -8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    7
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={10}
                  sx={{ paddingRight: { md: 0 }, marginRight: { md: 8 } }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    Eén acrolijn met een salto min C
                  </Typography>
                </Grid>
                <Grid item xs={1} md={1} sx={{ marginRight: { md: 0 } }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    0.30
                  </Typography>
                </Grid>
              </Grid>
            </Container>
          </Paper>
        </Grid>
      </Grid>
      <Grid container marginLeft={2}>
        <Grid item>
          <Typography
            variant="subtitle1"
            sx={{
              color: PgColors.redpg[500],
              fontSize: { xs: 12, sm: 14, md: 16 },
            }}
            align="left"
          >
            *Bonus 7 mag in SE 2 of SE 3 geturnd wordn of mag als extra acrolijn
            worden geturnd.
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};
