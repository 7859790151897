import React, { useState, useEffect, FC } from "react";
import {
  Grid,
  Container,
  Typography,
  Divider,
  Button,
  Paper,
} from "@mui/material";
import { PgColors } from "../../../configs/colors";

type TextProps = {
  text: string;
};

export const ContentPaper: FC<TextProps> = ({ text }) => {
  return (
    <Typography
      variant="subtitle1"
      sx={{ color: PgColors.white, fontSize: { xs: 12, sm: 14, md: 16 } }}
      align="left"
    >
      {text}
    </Typography>
  );
};

export const TitlePaper: FC<TextProps> = ({ text }) => {
  return (
    <Typography
      variant="subtitle1"
      sx={{
        color: PgColors.redpg[500],
        fontWeight: 500,
        fontSize: { xs: 12, sm: 14, md: 16 },
      }}
      align="left"
    >
      {text}
    </Typography>
  );
};

export const Content: FC<TextProps> = ({ text }) => {
  return (
    <Typography
      variant="subtitle1"
      sx={{ color: PgColors.redpg[500], fontSize: { xs: 12, sm: 14, md: 16 } }}
      align="left"
    >
      {text}
    </Typography>
  );
};

export const SupEVW = () => {
  return (
    <>
      <Grid container mt={2} marginLeft={2}>
        <Grid item>
          <Typography variant="h6" style={{ color: PgColors.redpg[500] }}>
            Verbindingswaarde
          </Typography>
        </Grid>
      </Grid>

      <Grid container display="flex" justifyContent="center" mb={4}>
        <Grid item xs={12}>
          <Paper
            elevation={2}
            style={{ backgroundColor: PgColors.bluepg[100], width: "100%" }}
          >
            <Container>
              <Grid container>
                <Grid item xs={8}>
                  <TitlePaper text={"Brug"} />
                </Grid>
                <Grid item xs={2}>
                  <TitlePaper text={"0.10"} />
                </Grid>
                <Grid item xs={2}>
                  <TitlePaper text={"0.20"} />
                </Grid>
              </Grid>
              <Divider></Divider>
              <Grid container mb={2}>
                <Grid item xs={8}>
                  <ContentPaper text={"Dezelfde of versch elementen"} />
                </Grid>
                <Grid item xs={2}>
                  <ContentPaper text={"A + A"} />
                </Grid>
                <Grid item xs={2}>
                  <ContentPaper text={"A + B of hoger"} />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12}>
                  <TitlePaper
                    text={
                      "De volgorde waarin de elementen in verb worden geturnd is vrij."
                    }
                  />
                </Grid>
              </Grid>

              <Divider></Divider>
              <Grid container>
                <Grid item xs={8}>
                  <TitlePaper text={"Balk"} />
                </Grid>
                <Grid item xs={2}>
                  <TitlePaper text={"0.10"} />
                </Grid>
                <Grid item xs={2}>
                  <TitlePaper text={"0.20"} />
                </Grid>
              </Grid>
              <Divider></Divider>
              <Grid container>
                <Grid item xs={8} sx={{ paddingRight: { xs: 2 } }}>
                  <ContentPaper
                    text={
                      "Acro met of zonder vlucht - mogen dezeflde elmenten zijn"
                    }
                  />
                </Grid>
                <Grid item xs={2}>
                  <ContentPaper text={"A + A"} />
                </Grid>
                <Grid item xs={2}>
                  <ContentPaper text={"A + B of hoger"} />
                </Grid>
              </Grid>

              <Grid container mb={2}>
                <Grid item xs={8}>
                  <ContentPaper text={"Dans - 2 versch elementen"} />
                </Grid>
                <Grid item xs={2}>
                  <ContentPaper text={"A + A"} />
                </Grid>
                <Grid item xs={2}>
                  <ContentPaper text={"A + B of hoger"} />
                </Grid>
              </Grid>

              <Grid container mb={2}>
                <Grid item xs={8}>
                  <ContentPaper text={"Mix"} />
                </Grid>
                <Grid item xs={2}>
                  <ContentPaper text={"A + A"} />
                </Grid>
                <Grid item xs={2}>
                  <ContentPaper text={"A + B of hoger"} />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12}>
                  <TitlePaper
                    text={
                      "De volgorde waarin de elementen in verb worden geturnd is vrij."
                    }
                  />
                </Grid>
              </Grid>
              <Divider></Divider>
              <Grid container>
                <Grid item xs={8}>
                  <TitlePaper text={"Vloer"} />
                </Grid>
                <Grid item xs={2}>
                  <TitlePaper text={"0.10"} />
                </Grid>
                <Grid item xs={2}>
                  <TitlePaper text={"0.20"} />
                </Grid>
              </Grid>
              <Divider></Divider>

              <Grid container mb={2}>
                <Grid item xs={8}></Grid>
                <Grid item xs={2}></Grid>
                <Grid item xs={2}></Grid>
              </Grid>
              <Grid container mb={2}>
                <Grid item xs={8}>
                  <ContentPaper
                    text={"Acro - 2 elementen met vlucht wv min 1 salto"}
                  />
                </Grid>
                <Grid item xs={2}>
                  <ContentPaper text={"A + A"} />
                </Grid>
                <Grid item xs={2}>
                  <ContentPaper text={"A + B of hoger"} />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={8}>
                  <ContentPaper text={"Dans - max 2 versch elementen"} />
                </Grid>
                <Grid item xs={2}>
                  <ContentPaper text={"A + A"} />
                </Grid>
                <Grid item xs={2}>
                  <ContentPaper text={"A + B of hoger"} />
                </Grid>
              </Grid>

              <Grid container mb={2}>
                <Grid item xs={12}>
                  <ContentPaper
                    text={
                      "In een verb bestaande uit meer dan 2 direct vebonden gymn sprongen of draaien kan slechts voor de eerste verbinding VW worden toegekend."
                    }
                  />
                </Grid>
              </Grid>

              <Grid container mb={2}>
                <Grid item xs={12}>
                  <TitlePaper
                    text={
                      "De volgorde waarin de elementen in verb worden geturnd is vrij."
                    }
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={8}>
                  <ContentPaper
                    text={"Mix uitgevoerd in aangegeven volgorde"}
                  />
                </Grid>
                <Grid item xs={4} pr={2}>
                  <ContentPaper
                    text={"A-acro vlucht of hoger + A-dans of hoger"}
                  />
                </Grid>
              </Grid>
            </Container>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};
