import React, { useState, useEffect } from "react";
import {
  collection,
  query,
  onSnapshot,
  updateDoc,
  doc,
  deleteDoc,
} from "firebase/firestore";
import {
  Grid,
  Container,
  Typography,
  Divider,
  Button,
  Paper,
} from "@mui/material";
import { PgColors } from "../../../configs/colors";
import { BorderBottom } from "@mui/icons-material";

export const Sup7Vault = () => {
  return (
    <>
      <Grid container mt={2} marginLeft={2}>
        <Grid item>
          <Typography variant="h6" style={{ color: PgColors.redpg[500] }}>
            Sprong
          </Typography>
        </Grid>
      </Grid>
      <Grid container marginLeft={2}>
        <Grid item>
          <Typography
            variant="subtitle1"
            sx={{
              color: PgColors.redpg[500],
              fontSize: { xs: 12, sm: 14, md: 16 },
            }}
            align="left"
          >
            *Er moeten 2 sprongen uitgevoerd worden. Het eindcijfer is het
            gemiddelde van de 2 scores van de sprongen.
          </Typography>
        </Grid>
      </Grid>
      <Grid container marginLeft={2}>
        <Grid item>
          <Typography
            variant="subtitle1"
            sx={{
              color: PgColors.redpg[500],
              fontSize: { xs: 12, sm: 14, md: 16 },
            }}
            align="left"
          >
            *Indien sprongen uit 2 versch groepen komen, wordt een bonus van
            0.50 punt opgeteld bij het eindcijfer.
          </Typography>
        </Grid>
      </Grid>
      <Grid container marginLeft={2} mt={2}>
        <Grid item>
          <Typography
            variant="subtitle1"
            sx={{
              color: PgColors.redpg[500],
              fontSize: { xs: 12, sm: 14, md: 16 },
            }}
            align="left"
          >
            Sprongsituatie: Plankoline - verhoogd vlak 0.90m
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item mt={0} xs={12}>
          <Paper
            elevation={2}
            style={{ backgroundColor: PgColors.bluepg[100], width: "100%" }}
          >
            <Container>
              <Grid
                container
                marginTop={1}
                sx={{ borderBottom: 1, borderColor: PgColors.bluepg[200] }}
                display={{ xs: "none", md: "flex" }}
              >
                <Grid item xs={10} md={3}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontWeight: 500,
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    Groep 1
                  </Typography>
                </Grid>

                <Grid item xs={2} md={1}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontWeight: 500,
                      fontSize: { xs: 12, sm: 14, md: 16 },
                      marginLeft: { xs: 4 },
                    }}
                    align="left"
                  >
                    D
                  </Typography>
                </Grid>
                <Grid item md={3} display={{ xs: "none", md: "flex" }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontWeight: 500,
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    Groep 2
                  </Typography>
                </Grid>
                <Grid item md={1} display={{ xs: "none", md: "flex" }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontWeight: 500,
                      fontSize: { xs: 12, sm: 14, md: 16 },
                      marginLeft: { md: 4 },
                    }}
                    align="left"
                  >
                    D
                  </Typography>
                </Grid>
                <Grid item md={3} display={{ xs: "none", md: "flex" }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontWeight: 500,
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    Groep 3
                  </Typography>
                </Grid>
                <Grid item md={1} display={{ xs: "none", md: "flex" }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontWeight: 500,
                      fontSize: { xs: 12, sm: 14, md: 16 },
                      marginLeft: { md: 4 },
                    }}
                    align="left"
                  >
                    D
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                container
                marginTop={0}
                sx={{ borderBottom: 1, borderColor: PgColors.bluepg[200] }}
                display={{ xs: "none", md: "flex" }}
              >
                <Grid item xs={10} md={3}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.white,
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    1a. Handstandoverslag tot ruglig
                  </Typography>
                </Grid>
                <Grid item xs={2} md={1}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.white,
                      fontSize: { xs: 12, sm: 14, md: 16 },
                      marginLeft: { xs: 4 },
                    }}
                    align="left"
                  >
                    4.5
                  </Typography>
                </Grid>
                <Grid item md={3} display={{ xs: "none", md: "flex" }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.white,
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    2a. Arabier tot kniezit of stand
                  </Typography>
                </Grid>
                <Grid item md={1} display={{ xs: "none", md: "flex" }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.white,
                      fontSize: { xs: 12, sm: 14, md: 16 },
                      marginLeft: { md: 4 },
                    }}
                    align="left"
                  >
                    4.5
                  </Typography>
                </Grid>
                <Grid item md={3} display={{ xs: "none", md: "flex" }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.white,
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    3a. Streksprong op - rol vo tot hurkzit
                  </Typography>
                </Grid>
                <Grid item md={1} display={{ xs: "none", md: "flex" }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.white,
                      fontSize: { xs: 12, sm: 14, md: 16 },
                      marginLeft: { md: 4 },
                    }}
                    align="left"
                  >
                    3.0
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                container
                marginTop={0}
                sx={{ borderBottom: 1, borderColor: PgColors.bluepg[200] }}
                display={{ xs: "none", md: "flex" }}
              >
                <Grid item xs={10} md={3}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.white,
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    1b. Handstand overslag tot stand
                  </Typography>
                </Grid>
                <Grid item xs={2} md={1}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.white,
                      fontSize: { xs: 12, sm: 14, md: 16 },
                      marginLeft: { xs: 4 },
                    }}
                    align="left"
                  >
                    5.0
                  </Typography>
                </Grid>
                <Grid item md={3} display={{ xs: "none", md: "flex" }}></Grid>
                <Grid item md={1} display={{ xs: "none", md: "flex" }}></Grid>
                <Grid item md={3} display={{ xs: "none", md: "flex" }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.white,
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    3b. Zweefrol via hurkzit tot stand
                  </Typography>
                </Grid>
                <Grid item md={1} display={{ xs: "none", md: "flex" }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.white,
                      fontSize: { xs: 12, sm: 14, md: 16 },
                      marginLeft: { md: 4 },
                    }}
                    align="left"
                  >
                    3.5
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                container
                marginTop={0}
                display={{ xs: "none", md: "flex" }}
                sx={{ borderBottom: 1, borderColor: PgColors.bluepg[200] }}
              >
                <Grid item xs={10} md={3}></Grid>
                <Grid item xs={2} md={1}></Grid>
                <Grid item md={3} display={{ xs: "none", md: "flex" }}></Grid>
                <Grid item md={1} display={{ xs: "none", md: "flex" }}></Grid>
                <Grid item md={3} display={{ xs: "none", md: "flex" }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.white,
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    3c. Salto vo tot ruglig
                  </Typography>
                </Grid>
                <Grid item md={1} display={{ xs: "none", md: "flex" }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.white,
                      fontSize: { xs: 12, sm: 14, md: 16 },
                      marginLeft: { md: 4 },
                    }}
                    align="left"
                  >
                    4.0
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                marginTop={0}
                display={{ xs: "none", md: "flex" }}
              >
                <Grid item xs={10} md={3}></Grid>
                <Grid item xs={2} md={1}></Grid>
                <Grid item md={3} display={{ xs: "none", md: "flex" }}></Grid>
                <Grid item md={1} display={{ xs: "none", md: "flex" }}></Grid>
                <Grid item md={3} display={{ xs: "none", md: "flex" }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.white,
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    3d. Salto vo gehurkt tot stand
                  </Typography>
                </Grid>
                <Grid item md={1} display={{ xs: "none", md: "flex" }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.white,
                      fontSize: { xs: 12, sm: 14, md: 16 },
                      marginLeft: { md: 4 },
                    }}
                    align="left"
                  >
                    4.5
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                container
                marginTop={1}
                display={{ xs: "flex", md: "none" }}
                sx={{ borderBottom: 1, borderColor: PgColors.bluepg[200] }}
              >
                <Grid item xs={10} md={3}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontWeight: 500,
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    Groep 1
                  </Typography>
                </Grid>
                <Grid item xs={2} md={1}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontWeight: 500,
                      fontSize: { xs: 12, sm: 14, md: 16 },
                      marginLeft: { xs: 4 },
                    }}
                    align="left"
                  >
                    D
                  </Typography>
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={10} display={{ xs: "flex", md: "none" }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.white,
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    1a. Handstandoverslag tot ruglig
                  </Typography>
                </Grid>
                <Grid item xs={2} display={{ xs: "flex", md: "none" }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.white,
                      fontSize: { xs: 12, sm: 14, md: 16 },
                      marginLeft: { xs: 4 },
                    }}
                    align="left"
                  >
                    4.5
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={10} display={{ xs: "flex", md: "none" }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.white,
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    1b. Handstandoverslag tot stand
                  </Typography>
                </Grid>
                <Grid item xs={2} display={{ xs: "flex", md: "none" }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.white,
                      fontSize: { xs: 12, sm: 14, md: 16 },
                      marginLeft: { xs: 4 },
                    }}
                    align="left"
                  >
                    5.0
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                container
                marginTop={1}
                display={{ xs: "flex", md: "none" }}
                sx={{ borderBottom: 1, borderColor: PgColors.bluepg[200] }}
              >
                <Grid item xs={10} md={3}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontWeight: 500,
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    Groep 2
                  </Typography>
                </Grid>
                <Grid item xs={2} md={1}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontWeight: 500,
                      fontSize: { xs: 12, sm: 14, md: 16 },
                      marginLeft: { xs: 4 },
                    }}
                    align="left"
                  >
                    D
                  </Typography>
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={10} display={{ xs: "flex", md: "none" }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.white,
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    2a. Arabier tot kniezit of stand
                  </Typography>
                </Grid>
                <Grid item xs={2} display={{ xs: "flex", md: "none" }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.white,
                      fontSize: { xs: 12, sm: 14, md: 16 },
                      marginLeft: { xs: 4 },
                    }}
                    align="left"
                  >
                    4.5
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={10} display={{ xs: "flex", md: "none" }}></Grid>
                <Grid item xs={2} display={{ xs: "flex", md: "none" }}></Grid>
              </Grid>
              <Grid
                container
                marginTop={1}
                display={{ xs: "flex", md: "none" }}
                sx={{ borderBottom: 1, borderColor: PgColors.bluepg[200] }}
              >
                <Grid item xs={10} md={3}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontWeight: 500,
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    Groep 3
                  </Typography>
                </Grid>
                <Grid item xs={2} md={1}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.redpg[500],
                      fontWeight: 500,
                      fontSize: { xs: 12, sm: 14, md: 16 },
                      marginLeft: { xs: 4 },
                    }}
                    align="left"
                  >
                    D
                  </Typography>
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={10} display={{ xs: "flex", md: "none" }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.white,
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    3a. Streksprong op - rol vo tot hurkzit
                  </Typography>
                </Grid>
                <Grid item xs={2} display={{ xs: "flex", md: "none" }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.white,
                      fontSize: { xs: 12, sm: 14, md: 16 },
                      marginLeft: { xs: 4 },
                    }}
                    align="left"
                  >
                    3.0
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={10} display={{ xs: "flex", md: "none" }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.white,
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    3b. Zweefrol via hurkzit tot stand
                  </Typography>
                </Grid>
                <Grid item xs={2} display={{ xs: "flex", md: "none" }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.white,
                      fontSize: { xs: 12, sm: 14, md: 16 },
                      marginLeft: { xs: 4 },
                    }}
                    align="left"
                  >
                    3.5
                  </Typography>
                </Grid>
                <Grid item xs={10} display={{ xs: "flex", md: "none" }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.white,
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    3c. Salto vo tot ruglig
                  </Typography>
                </Grid>
                <Grid item xs={2} display={{ xs: "flex", md: "none" }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.white,
                      fontSize: { xs: 12, sm: 14, md: 16 },
                      marginLeft: { xs: 4 },
                    }}
                    align="left"
                  >
                    4.0
                  </Typography>
                </Grid>
                <Grid item xs={10} display={{ xs: "flex", md: "none" }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.white,
                      fontSize: { xs: 12, sm: 14, md: 16 },
                    }}
                    align="left"
                  >
                    3d. Salto vo gehurkt tot stand
                  </Typography>
                </Grid>
                <Grid item xs={2} display={{ xs: "flex", md: "none" }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: PgColors.white,
                      fontSize: { xs: 12, sm: 14, md: 16 },
                      marginLeft: { xs: 4 },
                    }}
                    align="left"
                  >
                    4.5
                  </Typography>
                </Grid>
              </Grid>
            </Container>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};
