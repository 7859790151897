import React, { useState, useEffect } from "react";
import {
  collection,
  query,
  onSnapshot,
  updateDoc,
  doc,
  deleteDoc,
  setDoc,
} from "firebase/firestore";
import { db } from "../../../../firebase/config";
import {
  Container,
  Grid,
  Typography,
  Checkbox,
  Paper,
  TextField,
  Button,
  Modal,
  Box,
  Drawer,
  Divider,
  alertClasses,
} from "@mui/material";
import { useAuthContext } from "../../../../hooks/useAuthContext";
import { useParams } from "react-router-dom";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import AddIcon from "@mui/icons-material/Add";
import { PgColors } from "../../../../configs/colors";

export const EditDScore2 = ({ path }: any) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [currentData, setCurrentData] = useState<any>(null);
  const [date, setDate] = useState("");
  const [ageCategory, setAgeCategory] = useState("");
  const [level, setLevel] = useState("");
  const [division, setDivision] = useState("");
  const [apparatus, setApparatus] = useState("");
  const [routine, setRoutine] = useState("");
  const [mw, setMw] = useState("");
  const [se, setSe] = useState("");
  const [vw, setVw] = useState("");
  const [dScore, setDScore] = useState("");
  const [acroTA, setAcroTA] = useState("");
  const [acroA, setAcroA] = useState("");
  const [acroB, setAcroB] = useState("");
  const [acroC, setAcroC] = useState("");
  const [acroD, setAcroD] = useState("");
  const [acroE, setAcroE] = useState("");
  const [acroF, setAcroF] = useState("");
  const [dansTA, setDansTA] = useState("");
  const [dansA, setDansA] = useState("");
  const [dansB, setDansB] = useState("");
  const [dansC, setDansC] = useState("");
  const [dansD, setDansD] = useState("");
  const [dansE, setDansE] = useState("");
  const [dansF, setDansF] = useState("");
  const [overTA, setOverTA] = useState("");
  const [overA, setOverA] = useState("");
  const [overB, setOverB] = useState("");
  const [overC, setOverC] = useState("");
  const [overD, setOverD] = useState("");
  const [overE, setOverE] = useState("");
  const [overF, setOverF] = useState("");
  const [nAftrek, setNAftrek] = useState("");

  useEffect(() => {
    if (open) {
      const unsubscribe = onSnapshot(doc(db, path), (snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.data();
          setCurrentData(data);
          // Set the initial values for TextFields
          setDate(data.date || "");
          setAgeCategory(data.ageCategory || "");
          setLevel(data.level || "");
          setDivision(data.division || "");
          setApparatus(data.apparatus || "");
          setRoutine(data.routine || "");
          setMw(data.mw || "");
          setSe(data.se || "");
          setVw(data.vw || "");
          setDScore(data.dScore || "");
          setAcroTA(data.acroTA || "");
          setAcroA(data.acroA || "");
          setAcroB(data.acroB || "");
          setAcroC(data.acroC || "");
          setAcroD(data.acroD || "");
          setAcroE(data.acroE || "");
          setDansTA(data.dansTA || "");
          setDansA(data.dansA || "");
          setDansB(data.dansB || "");
          setDansC(data.dansC || "");
          setDansD(data.dansD || "");
          setDansE(data.dansE || "");
          setOverTA(data.overTA || "");
          setOverA(data.overA || "");
          setOverB(data.overB || "");
          setOverC(data.overC || "");
          setOverD(data.overD || "");
          setOverE(data.overE || "");
          setNAftrek(data.nAftrek || "");
        }
      });

      return () => unsubscribe();
    }
  }, [open, path]);

  const handleUpdate = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    try {
      if (date) {
        await updateDoc(doc(db, path), {
          date: date,
        });
      }
      if (ageCategory) {
        await updateDoc(doc(db, path), {
          ageCategory: ageCategory,
        });
      }
      if (level) {
        await updateDoc(doc(db, path), {
          level: level,
        });
      }
      if (division) {
        await updateDoc(doc(db, path), {
          division: division,
        });
      }
      if (apparatus) {
        await updateDoc(doc(db, path), {
          apparatus: apparatus,
        });
      }
      if (routine) {
        await updateDoc(doc(db, path), {
          routine: routine,
        });
      }
      if (mw) {
        await updateDoc(doc(db, path), {
          mw: mw,
        });
      }
      if (se) {
        await updateDoc(doc(db, path), {
          se: se,
        });
      }
      if (vw) {
        await updateDoc(doc(db, path), {
          vw: vw,
        });
      }
      if (dScore) {
        await updateDoc(doc(db, path), {
          dScore: dScore,
        });
      }
      if (acroTA) {
        await updateDoc(doc(db, path), {
          acroTA: acroTA,
        });
      }
      if (acroA) {
        await updateDoc(doc(db, path), {
          acroA: acroA,
        });
      }
      if (acroB) {
        await updateDoc(doc(db, path), {
          acroB: acroB,
        });
      }
      if (acroC) {
        await updateDoc(doc(db, path), {
          acroC: acroC,
        });
      }
      if (acroD) {
        await updateDoc(doc(db, path), {
          acroD: acroD,
        });
      }
      if (acroE) {
        await updateDoc(doc(db, path), {
          acroE: acroE,
        });
      }
      if (dansTA) {
        await updateDoc(doc(db, path), {
          dansTA: dansTA,
        });
      }
      if (dansA) {
        await updateDoc(doc(db, path), {
          dansA: dansA,
        });
      }
      if (dansB) {
        await updateDoc(doc(db, path), {
          dansB: dansB,
        });
      }
      if (dansC) {
        await updateDoc(doc(db, path), {
          dansC: dansC,
        });
      }
      if (dansD) {
        await updateDoc(doc(db, path), {
          dansD: dansD,
        });
      }
      if (dansE) {
        await updateDoc(doc(db, path), {
          dansE: dansE,
        });
      }
      if (overTA) {
        await updateDoc(doc(db, path), {
          overTA: overTA,
        });
      }
      if (overA) {
        await updateDoc(doc(db, path), {
          overA: overA,
        });
      }
      if (overB) {
        await updateDoc(doc(db, path), {
          overB: overB,
        });
      }
      if (overC) {
        await updateDoc(doc(db, path), {
          overC: overC,
        });
      }
      if (overD) {
        await updateDoc(doc(db, path), {
          overD: overD,
        });
      }
      if (overE) {
        await updateDoc(doc(db, path), {
          overE: overE,
        });
      }
      if (nAftrek) {
        await updateDoc(doc(db, path), {
          nAftrek: nAftrek,
        });
      }

      setDate("");
      setAgeCategory("");
      setLevel("");
      setDivision("");
      setApparatus("");
      setRoutine("");
      setSe("");
      setMw("");
      setVw("");
      setDScore("");
      setAcroTA("");
      setAcroA("");
      setAcroB("");
      setAcroC("");
      setAcroD("");
      setAcroE("");
      setAcroF("");
      setDansTA("");
      setDansA("");
      setDansB("");
      setDansC("");
      setDansD("");
      setDansE("");
      setDansF("");
      setOverTA("");
      setOverA("");
      setOverB("");
      setOverC("");
      setOverD("");
      setOverE("");
      setOverF("");
      setNAftrek("");
      setOpen(false);
    } catch (err) {
      alert(err);
    }
  };

  return (
    <>
      <Button
        variant="contained"
        sx={{ heigth: 35 }}
        onClick={handleOpen}
        style={{
          backgroundColor: PgColors.bluepg[200],
        }}
      >
        <EditOutlinedIcon />
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        style={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
        }}
      >
        <Box
          sx={{
            marginTop: { xs: 1, md: 8 },
            width: { xs: 350, sm: 580, md: 880, xl: 1500 },

            maxHeight: { xs: 700, sm: 700, md: 500, lg: 400 },
            bgcolor: "background.paper",
            border: "4px solid #9f5b5a",
            boxShadow: 24,
            borderRadius: 5,
            p: 4,
            overflow: "auto",
          }}
        >
          <form>
            <Grid container>
              <Grid item xs={10} md={11}></Grid>
              <Grid
                item
                xs={2}
                md={1}
                mb={1}
                display="flex"
                justifyContent="flex-end"
              >
                <Button
                  variant="contained"
                  sx={{ heigth: 35 }}
                  onClick={handleClose}
                  style={{
                    backgroundColor: PgColors.redpg[200],
                  }}
                >
                  <CloseOutlinedIcon />
                </Button>
              </Grid>
              <Grid item xs={4} md={2}>
                <TextField
                  label="Datum"
                  style={{
                    color: PgColors.bluepg[200],
                  }}
                  variant="outlined"
                  fullWidth
                  value={date}
                  onChange={(event) => setDate(event.target.value)}
                />
              </Grid>
              <Grid item xs={4} md={3}>
                <TextField
                  label="Leeftijd Categorie"
                  style={{
                    color: PgColors.bluepg[200],
                  }}
                  variant="outlined"
                  fullWidth
                  value={ageCategory}
                  onChange={(event) => setAgeCategory(event.target.value)}
                />
              </Grid>
              <Grid item xs={4} md={2}>
                <TextField
                  label="Niveau"
                  style={{
                    color: PgColors.bluepg[200],
                  }}
                  variant="outlined"
                  fullWidth
                  value={level}
                  onChange={(event) => setLevel(event.target.value)}
                />
              </Grid>
              <Grid item xs={6} md={2}>
                <TextField
                  label="Divisie"
                  style={{
                    color: PgColors.bluepg[200],
                  }}
                  variant="outlined"
                  fullWidth
                  value={division}
                  onChange={(event) => setDivision(event.target.value)}
                />
              </Grid>

              <Grid item xs={6} md={3}>
                <TextField
                  label="Toestel"
                  style={{
                    color: PgColors.bluepg[200],
                  }}
                  variant="outlined"
                  fullWidth
                  value={apparatus}
                  onChange={(event) => setApparatus(event.target.value)}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <TextField
                  label="Oefening"
                  style={{
                    color: PgColors.bluepg[200],
                  }}
                  variant="outlined"
                  multiline
                  rows={3}
                  fullWidth
                  value={routine}
                  onChange={(event) => setRoutine(event.target.value)}
                />
              </Grid>
            </Grid>
            <Grid container display={{ xs: "none", md: "flex" }}>
              <Grid item md={1}>
                <Paper
                  style={{ backgroundColor: PgColors.redpg[200], height: 35 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white", fontSize: 20 }}
                    align="center"
                  >
                    SE
                  </Typography>
                </Paper>
              </Grid>
              <Grid item md={1}>
                <Paper
                  style={{ backgroundColor: PgColors.redpg[200], height: 35 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white", fontSize: 20 }}
                    align="center"
                  >
                    1
                  </Typography>
                </Paper>
              </Grid>
              <Grid item md={1}>
                <Paper
                  style={{ backgroundColor: PgColors.redpg[200], height: 35 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white", fontSize: 20 }}
                    align="center"
                  >
                    2
                  </Typography>
                </Paper>
              </Grid>
              <Grid item md={1}>
                <Paper
                  style={{ backgroundColor: PgColors.redpg[200], height: 35 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white", fontSize: 20 }}
                    align="center"
                  >
                    3
                  </Typography>
                </Paper>
              </Grid>
              <Grid item md={1}>
                <Paper
                  style={{ backgroundColor: PgColors.redpg[200], height: 35 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white", fontSize: 20 }}
                    align="center"
                  >
                    4
                  </Typography>
                </Paper>
              </Grid>
              <Grid item md={1}></Grid>
              <Grid item md={1}>
                <Paper
                  style={{ backgroundColor: PgColors.redpg[200], height: 35 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white", fontSize: 20 }}
                    align="center"
                  >
                    TA
                  </Typography>
                </Paper>
              </Grid>
              <Grid item md={1}>
                <Paper
                  style={{ backgroundColor: PgColors.redpg[200], height: 35 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white", fontSize: 20 }}
                    align="center"
                  >
                    A
                  </Typography>
                </Paper>
              </Grid>
              <Grid item md={1}>
                <Paper
                  style={{ backgroundColor: PgColors.redpg[200], height: 35 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white", fontSize: 20 }}
                    align="center"
                  >
                    B
                  </Typography>
                </Paper>
              </Grid>
              <Grid item md={1}>
                <Paper
                  style={{ backgroundColor: PgColors.redpg[200], height: 35 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white", fontSize: 20 }}
                    align="center"
                  >
                    C
                  </Typography>
                </Paper>
              </Grid>
              <Grid item md={1}>
                <Paper
                  style={{ backgroundColor: PgColors.redpg[200], height: 35 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white", fontSize: 20 }}
                    align="center"
                  >
                    D
                  </Typography>
                </Paper>
              </Grid>
              <Grid item md={1}>
                <Paper
                  style={{ backgroundColor: PgColors.redpg[200], height: 35 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white", fontSize: 20 }}
                    align="center"
                  >
                    E
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
            <Grid container display={{ xs: "none", md: "flex" }}>
              <Grid item md={1}>
                <Paper
                  style={{ backgroundColor: PgColors.redpg[200], height: 55 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white", fontSize: 20 }}
                    align="center"
                  >
                    MW
                  </Typography>
                </Paper>
              </Grid>
              <Grid item md={1}>
                <TextField
                  style={{
                    color: PgColors.bluepg[200],
                  }}
                  variant="outlined"
                  fullWidth
                  value={mw}
                  onChange={(event) => setMw(event.target.value)}
                />
              </Grid>
              <Grid item md={1}>
                <Paper
                  style={{ backgroundColor: PgColors.redpg[200], height: 55 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white", fontSize: 20 }}
                    align="center"
                  >
                    SE
                  </Typography>
                </Paper>
              </Grid>
              <Grid item md={1}>
                <TextField
                  style={{
                    color: PgColors.bluepg[200],
                  }}
                  variant="outlined"
                  fullWidth
                  value={se}
                  onChange={(event) => setSe(event.target.value)}
                />
              </Grid>
              <Grid item md={1}></Grid>
              <Grid item md={1}>
                <Paper
                  style={{ backgroundColor: PgColors.redpg[200], height: 55 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white", fontSize: 20 }}
                    align="center"
                  >
                    Acro
                  </Typography>
                </Paper>
              </Grid>
              <Grid item md={1}>
                <TextField
                  style={{
                    color: PgColors.bluepg[200],
                  }}
                  variant="outlined"
                  fullWidth
                  value={acroTA}
                  onChange={(event) => setAcroTA(event.target.value)}
                />
              </Grid>
              <Grid item md={1}>
                <TextField
                  style={{
                    color: PgColors.bluepg[200],
                  }}
                  variant="outlined"
                  fullWidth
                  value={acroA}
                  onChange={(event) => setAcroA(event.target.value)}
                />
              </Grid>
              <Grid item md={1}>
                <TextField
                  style={{
                    color: PgColors.bluepg[200],
                  }}
                  variant="outlined"
                  fullWidth
                  value={acroB}
                  onChange={(event) => setAcroB(event.target.value)}
                />
              </Grid>
              <Grid item md={1}>
                <TextField
                  style={{
                    color: PgColors.bluepg[200],
                  }}
                  variant="outlined"
                  fullWidth
                  value={acroC}
                  onChange={(event) => setAcroC(event.target.value)}
                />
              </Grid>
              <Grid item md={1}>
                <TextField
                  style={{
                    color: PgColors.bluepg[200],
                  }}
                  variant="outlined"
                  fullWidth
                  value={acroD}
                  onChange={(event) => setAcroD(event.target.value)}
                />
              </Grid>
              <Grid item md={1}>
                <TextField
                  style={{
                    color: PgColors.bluepg[200],
                  }}
                  variant="outlined"
                  fullWidth
                  value={acroE}
                  onChange={(event) => setAcroE(event.target.value)}
                />
              </Grid>
            </Grid>
            <Grid container display={{ xs: "none", md: "flex" }}>
              <Grid item md={1}>
                <Paper
                  style={{ backgroundColor: PgColors.redpg[200], height: 55 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white", fontSize: 20 }}
                    align="center"
                  >
                    VW
                  </Typography>
                </Paper>
              </Grid>
              <Grid item md={1}>
                <TextField
                  style={{
                    color: PgColors.bluepg[200],
                  }}
                  variant="outlined"
                  fullWidth
                  value={vw}
                  onChange={(event) => setVw(event.target.value)}
                />
              </Grid>
              <Grid item md={1}>
                <Paper
                  style={{ backgroundColor: PgColors.redpg[200], height: 55 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white", fontSize: 20 }}
                    align="center"
                  >
                    D Score
                  </Typography>
                </Paper>
              </Grid>
              <Grid item md={1}>
                <TextField
                  style={{
                    color: PgColors.bluepg[200],
                  }}
                  variant="outlined"
                  fullWidth
                  value={dScore}
                  onChange={(event) => setDScore(event.target.value)}
                />
              </Grid>
              <Grid item md={1}></Grid>
              <Grid item md={1}>
                <Paper
                  style={{ backgroundColor: PgColors.redpg[200], height: 55 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white", fontSize: 20 }}
                    align="center"
                  >
                    Dans
                  </Typography>
                </Paper>
              </Grid>
              <Grid item md={1}>
                <TextField
                  style={{
                    color: PgColors.bluepg[200],
                  }}
                  variant="outlined"
                  fullWidth
                  value={dansTA}
                  onChange={(event) => setDansTA(event.target.value)}
                />
              </Grid>
              <Grid item md={1}>
                <TextField
                  style={{
                    color: PgColors.bluepg[200],
                  }}
                  variant="outlined"
                  fullWidth
                  value={dansA}
                  onChange={(event) => setDansA(event.target.value)}
                />
              </Grid>
              <Grid item md={1}>
                <TextField
                  style={{
                    color: PgColors.bluepg[200],
                  }}
                  variant="outlined"
                  fullWidth
                  value={dansB}
                  onChange={(event) => setDansB(event.target.value)}
                />
              </Grid>
              <Grid item md={1}>
                <TextField
                  style={{
                    color: PgColors.bluepg[200],
                  }}
                  variant="outlined"
                  fullWidth
                  value={dansC}
                  onChange={(event) => setDansC(event.target.value)}
                />
              </Grid>
              <Grid item md={1}>
                <TextField
                  style={{
                    color: PgColors.bluepg[200],
                  }}
                  variant="outlined"
                  fullWidth
                  value={dansD}
                  onChange={(event) => setDansD(event.target.value)}
                />
              </Grid>
              <Grid item md={1}>
                <TextField
                  style={{
                    color: PgColors.bluepg[200],
                  }}
                  variant="outlined"
                  fullWidth
                  value={dansE}
                  onChange={(event) => setDansE(event.target.value)}
                />
              </Grid>
            </Grid>
            <Grid container display={{ xs: "none", md: "flex" }}>
              <Grid item md={1}>
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: PgColors.bluepg[200],
                  }}
                  size="large"
                  onClick={(
                    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
                  ) => handleUpdate(e)}
                  sx={{ height: 55, width: 92 }}
                >
                  <AddIcon />
                </Button>
              </Grid>
              <Grid item md={1}></Grid>
              <Grid item md={1}>
                <Paper
                  style={{ backgroundColor: PgColors.redpg[200], height: 55 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white", fontSize: 20 }}
                    align="center"
                  >
                    Neutraal
                  </Typography>
                </Paper>
              </Grid>
              <Grid item md={1}>
                <TextField
                  style={{
                    color: PgColors.bluepg[200],
                  }}
                  variant="outlined"
                  fullWidth
                  value={nAftrek}
                  onChange={(event) => setNAftrek(event.target.value)}
                />
              </Grid>
              <Grid item md={1}></Grid>
              <Grid item md={1}>
                <Paper
                  style={{ backgroundColor: PgColors.redpg[200], height: 55 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white", fontSize: 20 }}
                    align="center"
                  >
                    Over
                  </Typography>
                </Paper>
              </Grid>
              <Grid item md={1}>
                <TextField
                  style={{
                    color: PgColors.bluepg[200],
                  }}
                  variant="outlined"
                  fullWidth
                  value={overTA}
                  onChange={(event) => setOverTA(event.target.value)}
                />
              </Grid>
              <Grid item md={1}>
                <TextField
                  style={{
                    color: PgColors.bluepg[200],
                  }}
                  variant="outlined"
                  fullWidth
                  value={overA}
                  onChange={(event) => setOverA(event.target.value)}
                />
              </Grid>
              <Grid item md={1}>
                <TextField
                  style={{
                    color: PgColors.bluepg[200],
                  }}
                  variant="outlined"
                  fullWidth
                  value={overB}
                  onChange={(event) => setOverB(event.target.value)}
                />
              </Grid>
              <Grid item md={1}>
                <TextField
                  style={{
                    color: PgColors.bluepg[200],
                  }}
                  variant="outlined"
                  fullWidth
                  value={overC}
                  onChange={(event) => setOverC(event.target.value)}
                />
              </Grid>
              <Grid item md={1}>
                <TextField
                  style={{
                    color: PgColors.bluepg[200],
                  }}
                  variant="outlined"
                  fullWidth
                  value={overD}
                  onChange={(event) => setOverD(event.target.value)}
                />
              </Grid>
              <Grid item md={1}>
                <TextField
                  style={{
                    color: PgColors.bluepg[200],
                  }}
                  variant="outlined"
                  fullWidth
                  value={overE}
                  onChange={(event) => setOverE(event.target.value)}
                />
              </Grid>
            </Grid>
            <Grid container display={{ xs: "flex", md: "none" }}>
              <Grid item xs={12}>
                <Paper
                  style={{ backgroundColor: PgColors.redpg[200], height: 25 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white" }}
                    sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                    align="center"
                  >
                    Acro
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
            <Grid container display={{ xs: "flex", md: "none" }}>
              <Grid item xs={2}>
                <TextField
                  style={{
                    color: PgColors.bluepg[200],
                  }}
                  label="TA"
                  variant="outlined"
                  fullWidth
                  value={acroTA}
                  onChange={(event) => setAcroTA(event.target.value)}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  style={{
                    color: PgColors.bluepg[200],
                  }}
                  label="A"
                  variant="outlined"
                  fullWidth
                  value={acroA}
                  onChange={(event) => setAcroA(event.target.value)}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  style={{
                    color: PgColors.bluepg[200],
                  }}
                  label="B"
                  variant="outlined"
                  fullWidth
                  value={acroB}
                  onChange={(event) => setAcroB(event.target.value)}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  style={{
                    color: PgColors.bluepg[200],
                  }}
                  label="C"
                  variant="outlined"
                  fullWidth
                  value={acroC}
                  onChange={(event) => setAcroC(event.target.value)}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  style={{
                    color: PgColors.bluepg[200],
                  }}
                  label="D"
                  variant="outlined"
                  fullWidth
                  value={acroD}
                  onChange={(event) => setAcroD(event.target.value)}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  style={{
                    color: PgColors.bluepg[200],
                  }}
                  label="E"
                  variant="outlined"
                  fullWidth
                  value={acroE}
                  onChange={(event) => setAcroE(event.target.value)}
                />
              </Grid>
            </Grid>
            <Grid container display={{ xs: "flex", md: "none" }}>
              <Grid item xs={12}>
                <Paper
                  style={{ backgroundColor: PgColors.redpg[200], height: 25 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white" }}
                    sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                    align="center"
                  >
                    Dans
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
            <Grid container display={{ xs: "flex", md: "none" }}>
              <Grid item xs={2}>
                <TextField
                  style={{
                    color: PgColors.bluepg[200],
                  }}
                  label="TA"
                  variant="outlined"
                  fullWidth
                  value={dansTA}
                  onChange={(event) => setDansTA(event.target.value)}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  style={{
                    color: PgColors.bluepg[200],
                  }}
                  label="A"
                  variant="outlined"
                  fullWidth
                  value={dansA}
                  onChange={(event) => setDansA(event.target.value)}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  style={{
                    color: PgColors.bluepg[200],
                  }}
                  label="B"
                  variant="outlined"
                  fullWidth
                  value={dansB}
                  onChange={(event) => setDansB(event.target.value)}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  style={{
                    color: PgColors.bluepg[200],
                  }}
                  label="C"
                  variant="outlined"
                  fullWidth
                  value={dansC}
                  onChange={(event) => setDansC(event.target.value)}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  style={{
                    color: PgColors.bluepg[200],
                  }}
                  label="D"
                  variant="outlined"
                  fullWidth
                  value={dansD}
                  onChange={(event) => setDansD(event.target.value)}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  style={{
                    color: PgColors.bluepg[200],
                  }}
                  label="E"
                  variant="outlined"
                  fullWidth
                  value={dansE}
                  onChange={(event) => setDansE(event.target.value)}
                />
              </Grid>
            </Grid>
            <Grid container display={{ xs: "flex", md: "none" }}>
              <Grid item xs={12}>
                <Paper
                  style={{ backgroundColor: PgColors.redpg[200], height: 25 }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white" }}
                    sx={{ fontSize: { xs: 12, md: 16, lg: 20 } }}
                    align="center"
                  >
                    Over
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
            <Grid container display={{ xs: "flex", md: "none" }}>
              <Grid item xs={2}>
                <TextField
                  style={{
                    color: PgColors.bluepg[200],
                  }}
                  label="TA"
                  variant="outlined"
                  fullWidth
                  value={overTA}
                  onChange={(event) => setOverTA(event.target.value)}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  style={{
                    color: PgColors.bluepg[200],
                  }}
                  label="A"
                  variant="outlined"
                  fullWidth
                  value={overA}
                  onChange={(event) => setOverA(event.target.value)}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  style={{
                    color: PgColors.bluepg[200],
                  }}
                  label="B"
                  variant="outlined"
                  fullWidth
                  value={overB}
                  onChange={(event) => setOverB(event.target.value)}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  style={{
                    color: PgColors.bluepg[200],
                  }}
                  label="C"
                  variant="outlined"
                  fullWidth
                  value={overC}
                  onChange={(event) => setOverC(event.target.value)}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  style={{
                    color: PgColors.bluepg[200],
                  }}
                  label="D"
                  variant="outlined"
                  fullWidth
                  value={overD}
                  onChange={(event) => setOverD(event.target.value)}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  style={{
                    color: PgColors.bluepg[200],
                  }}
                  label="E"
                  variant="outlined"
                  fullWidth
                  value={overE}
                  onChange={(event) => setOverE(event.target.value)}
                />
              </Grid>
            </Grid>
            <Grid container display={{ xs: "flex", md: "none" }}>
              <Grid item xs={3}>
                <TextField
                  style={{
                    color: PgColors.bluepg[200],
                  }}
                  label="MW"
                  variant="outlined"
                  fullWidth
                  value={mw}
                  onChange={(event) => setMw(event.target.value)}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  style={{
                    color: PgColors.bluepg[200],
                  }}
                  label="SE"
                  variant="outlined"
                  fullWidth
                  value={se}
                  onChange={(event) => setSe(event.target.value)}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  style={{
                    color: PgColors.bluepg[200],
                  }}
                  label="VW"
                  variant="outlined"
                  fullWidth
                  value={vw}
                  onChange={(event) => setVw(event.target.value)}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  style={{
                    color: PgColors.bluepg[200],
                  }}
                  label="D-score"
                  variant="outlined"
                  fullWidth
                  value={dScore}
                  onChange={(event) => setDScore(event.target.value)}
                />
              </Grid>
            </Grid>
            <Grid container display={{ xs: "flex", md: "none" }}>
              <Grid item xs={3}>
                <TextField
                  style={{
                    color: PgColors.bluepg[200],
                  }}
                  label="Neutraal"
                  variant="outlined"
                  fullWidth
                  value={nAftrek}
                  onChange={(event) => setNAftrek(event.target.value)}
                />
              </Grid>
              <Grid item xs={3}></Grid>
              <Grid item xs={3}></Grid>
              <Grid item xs={3}>
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: PgColors.bluepg[200],
                  }}
                  size="large"
                  onClick={(
                    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
                  ) => handleUpdate(e)}
                  sx={{ height: 55 }}
                >
                  <AddIcon />
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Modal>
    </>
  );
};
