import React, { useEffect, useState } from "react";
import "./App.css";
import { LandingPage } from "./pages/LandingPage";
import { Routes, Route, Navigate, Outlet } from "react-router-dom";
import { Login } from "./pages/auth/Login";
import { Signup } from "./pages/auth/Signup";
import { Home } from "./pages/Home/Home";
import { Profile } from "./pages/Profile";
import { Programs } from "./pages/Programs/Programs";
import { Elements } from "./pages/Elements/Elements";
import { LessonPlans } from "./pages/LessonPlans/LessonPlans";
import { Gymnasts } from "./pages/Gymnasts/Gymnasts";
import { NewGymnasts } from "./pages/Gymnasts/NewGymnasts";
import { GymnastPage } from "./pages/Gymnasts/GymnastPage/GymnastPage";
import { Gymnast2Page } from "./pages/Gymnasts/GymnastPage/Gymnast2Page";
import { Seasons } from "./pages/SeasonPlans/Seasons";
import { Competition } from "./pages/Competition/Competition";
import { useAuthContext } from "./hooks/useAuthContext";
import { DrawerDashboard } from "./components/home/DrawerDashboard";
import { Grid, AppBar, Box, Drawer } from "@mui/material";
import { ProgramComponent } from "./pages/Programs/ProgramDetails";
import { Regulations } from "./pages/Regulations/Regulations";
import { LessonComponent } from "./pages/LessonPlans/LessonDetails";
import { SeasonComponent } from "./pages/SeasonPlans/SeasonDetails";
import { PgColors } from "./configs/colors";
import { Header } from "./components/home/Header";
import { Subscribe } from "./pages/auth/Subscribe";
import { Terms } from "./components/landingPage/Terms";

function App() {
  const { user } = useAuthContext();

  const ParentContent = () => {
    useEffect(() => {
      // Set overflowX to "hidden" on the body element to prevent horizontal overflow
      document.body.style.overflowX = "hidden";
      return () => {
        // Clean up the effect when the component unmounts
        document.body.style.overflowX = "auto"; // Reset to default when the component unmounts
      };
    }, []);

    return (
      <div
        style={{
          height: "100vh",
          width: "100%",
          backgroundImage:
            "linear-gradient(to right, #edf6e9, #edf6e9 65%, #89ACAD 35% )",
          overflowX: "hidden",
        }}
      >
        <Grid container>
          <Grid item display={{ xs: "none", lg: "flex" }}>
            <DrawerDashboard />
          </Grid>
          <Grid item display={{ xs: "flex", lg: "none" }}>
            <Header />
          </Grid>
        </Grid>
        <Grid container display="flex" justifyContent="center">
          <Grid item display="flex">
            <Outlet />
          </Grid>
        </Grid>

        {/* <Grid display={{ xs: "none", md: "flex" }}>
          <div style={{ display: "flex" }}>
            <DrawerDashboard />
            <div
              style={{
                flex: 1, // This makes the Outlet component take up the remaining available space
              }}
            >
              <Grid container justifyContent="center">
                <Outlet />
              </Grid>
            </div>
          </div>
        </Grid>
        <Grid display={{ xs: "flex", md: "none" }}>
          <div>
            <Header />
            <Grid container justifyContent="center">
              <Outlet />
            </Grid>
          </div>
        </Grid> */}
      </div>
    );
  };

  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/login" element={<Login />} />

      <Route path="/subscribe" element={<Subscribe />} />
      <Route path="/terms" element={<Terms />} />

      <Route path="/dashboard" element={<ParentContent />}>
        <Route path="/dashboard/home" element={<Home />} />
        <Route path="/dashboard/profile" element={<Profile />} />
        <Route path="/dashboard/lessonplans" element={<LessonPlans />} />
        <Route path="/dashboard/programs" element={<Programs />} />
        <Route path="/dashboard/elements" element={<Elements />} />
        <Route path="/dashboard/regulations" element={<Regulations />} />
        <Route path="/dashboard/programs/:id" element={<ProgramComponent />} />
        <Route
          path="/dashboard/lessonplans/:id"
          element={<LessonComponent />}
        />
        <Route
          path="/dashboard/seasonplans/:id"
          element={<SeasonComponent />}
        />
        <Route path="/dashboard/gymnasts" element={<Gymnasts />} />
        <Route path="/dashboard/gymnasts/new" element={<NewGymnasts />} />
        <Route path="/dashboard/seasonplans" element={<Seasons />} />
        <Route path="/dashboard/gymnasts/:id" element={<GymnastPage />} />
        <Route
          path={`/dashboard/gymnasts/${user?.uid}`}
          element={<Gymnast2Page />}
        />
        <Route
          path="/dashboard/gymnasts/competition"
          element={<Competition />}
        />
      </Route>
    </Routes>
  );
}

export default App;
